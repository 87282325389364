// Actions
import { onStaffChange } from "../../../redux/pages/staffProfilePage/staffProfilePageActions";

// Components
import StyledMenu from "../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

function StaffActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();

  // Props
  const {
    // States
    showStaffActionMenu,
    // Set States
    setShowStaffActionMenu,
    // Functions
    displayStaffActionDialog,
  } = props;

  // Redux Store
  const selfId = useSelector((state) => state.staff.id);

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowStaffActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "Profile":
        // Update Redux Store
        dispatch(onStaffChange(selfId));
        // Navigate
        navigate("/staffProfile");
        break;
      case "Logout":
        displayStaffActionDialog("Logout");
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showStaffActionMenu}
      open={Boolean(showStaffActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Profile")}>
        <AssignmentIndRoundedIcon />
        {t("個人檔案")}
      </MenuItem>
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Logout")}>
        <LogoutRoundedIcon />
        {t("登出")}
      </MenuItem>
    </StyledMenu>
  );
}

export default StaffActionMenu;
