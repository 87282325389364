export const dayOptions = [
  { value: 1, textCh: "1日", textEn: "1st" },
  { value: 2, textCh: "2日", textEn: "2nd" },
  { value: 3, textCh: "3日", textEn: "3rd" },
  { value: 4, textCh: "4日", textEn: "4th" },
  { value: 5, textCh: "5日", textEn: "5th" },
  { value: 6, textCh: "6日", textEn: "6th" },
  { value: 7, textCh: "7日", textEn: "7th" },
  { value: 8, textCh: "8日", textEn: "8th" },
  { value: 9, textCh: "9日", textEn: "9th" },
  { value: 10, textCh: "10日", textEn: "10th" },
  { value: 11, textCh: "11日", textEn: "11th" },
  { value: 12, textCh: "12日", textEn: "12th" },
  { value: 13, textCh: "13日", textEn: "13th" },
  { value: 14, textCh: "14日", textEn: "14th" },
  { value: 15, textCh: "15日", textEn: "15th" },
  { value: 16, textCh: "16日", textEn: "16th" },
  { value: 17, textCh: "17日", textEn: "17th" },
  { value: 18, textCh: "18日", textEn: "18th" },
  { value: 19, textCh: "19日", textEn: "19th" },
  { value: 20, textCh: "20日", textEn: "20th" },
  { value: 21, textCh: "21日", textEn: "21st" },
  { value: 22, textCh: "22日", textEn: "22nd" },
  { value: 23, textCh: "23日", textEn: "23rd" },
  { value: 24, textCh: "24日", textEn: "24th" },
  { value: 25, textCh: "25日", textEn: "25th" },
  { value: 26, textCh: "26日", textEn: "26th" },
  { value: 27, textCh: "27日", textEn: "27th" },
  { value: 28, textCh: "28日", textEn: "28th" },
  { value: 29, textCh: "29日", textEn: "29th" },
  { value: 30, textCh: "30日", textEn: "30th" },
  { value: 31, textCh: "31日", textEn: "31st" },
];

export const monthOptions = [
  { value: 1, textCh: "1月", textEn: "Jan" },
  { value: 2, textCh: "2月", textEn: "Feb" },
  { value: 3, textCh: "3月", textEn: "Mar" },
  { value: 4, textCh: "4月", textEn: "Apr" },
  { value: 5, textCh: "5月", textEn: "May" },
  { value: 6, textCh: "6月", textEn: "Jun" },
  { value: 7, textCh: "7月", textEn: "Jul" },
  { value: 8, textCh: "8月", textEn: "Aug" },
  { value: 9, textCh: "9月", textEn: "Sep" },
  { value: 10, textCh: "10月", textEn: "Oct" },
  { value: 11, textCh: "11月", textEn: "Nov" },
  { value: 12, textCh: "12月", textEn: "Dec" },
];

export const quantityOptions = [
  { value: 1, textCh: "1天", textEn: "1 Day" },
  { value: 2, textCh: "2天", textEn: "2 Days" },
  { value: 3, textCh: "3天", textEn: "3 Days" },
  { value: 4, textCh: "4天", textEn: "4 Days" },
  { value: 5, textCh: "5天", textEn: "5 Days" },
  { value: 6, textCh: "6天", textEn: "6 Days" },
  { value: 7, textCh: "7天", textEn: "7 Days" },
  { value: 8, textCh: "8天", textEn: "8 Days" },
  { value: 9, textCh: "9天", textEn: "9 Days" },
  { value: 10, textCh: "10天", textEn: "10 Days" },
  { value: 11, textCh: "11天", textEn: "11 Days" },
  { value: 12, textCh: "12天", textEn: "12 Days" },
  { value: 13, textCh: "13天", textEn: "13 Days" },
  { value: 14, textCh: "14天", textEn: "14 Days" },
  { value: 15, textCh: "15天", textEn: "15 Days" },
  { value: 16, textCh: "16天", textEn: "16 Days" },
  { value: 17, textCh: "17天", textEn: "17 Days" },
  { value: 18, textCh: "18天", textEn: "18 Days" },
  { value: 19, textCh: "19天", textEn: "19 Days" },
  { value: 20, textCh: "20天", textEn: "20 Days" },
  { value: 21, textCh: "21天", textEn: "21 Days" },
  { value: 22, textCh: "22天", textEn: "22 Days" },
  { value: 23, textCh: "23天", textEn: "23 Days" },
  { value: 24, textCh: "24天", textEn: "24 Days" },
  { value: 25, textCh: "25天", textEn: "25 Days" },
  { value: 26, textCh: "26天", textEn: "26 Days" },
  { value: 27, textCh: "27天", textEn: "27 Days" },
  { value: 28, textCh: "28天", textEn: "28 Days" },
  { value: 29, textCh: "29天", textEn: "29 Days" },
  { value: 30, textCh: "30天", textEn: "30 Days" },
];
