// Configs
import apisConfig from "../configs/apisConfig";

export const getAllApplicationQuestionsByApplicationIdOrApplicationTypeIdFetch =
  async (token, applicationId, applicationTypeId) => {
    try {
      const queryRoute =
        "/applicationQuestion/getAllApplicationQuestionsByApplicationIdOrApplicationTypeId";

      const reqBody = {
        applicationId,
        applicationTypeId,
        isWeb: true,
      };

      const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...reqBody,
        }),
      });

      const results = await fetchRes.json();

      return results;
    } catch (err) {
      console.log(err.message);
    }
  };
