// Components
// Boxes
import CaptionBox from "./captionBox";

// Custom Hooks
import { useLanguage } from "../../../../customHooks/getLanguage";

// Material UI
// Components
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

function QuestionBoxContentLocation(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // Events
    onLocationFieldClicked,
    // States
    item,
  } = props;

  return (
    <Grid item xs={12}>
      <TextField
        error={item.isError}
        fullWidth
        helperText={item.errorText}
        InputProps={{
          readOnly: true,
        }}
        label={item[t("edited_question_text_ch")]}
        margin="dense"
        name={item[t("edited_question_text_ch")]}
        onClick={() => onLocationFieldClicked(item.answerValue)}
        value={item[t("answerTextCh")]}
        variant="standard"
      />
      {item[t("captionStrCh")] && (
        <CaptionBox
          // States
          captionStr={item[t("captionStrCh")]}
        />
      )}
    </Grid>
  );
}

export default QuestionBoxContentLocation;
