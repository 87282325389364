// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Spacing Boxes
import SpacingBox from "../../boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  createWorkingHourFetch,
  editWorkingHourFetch,
  getWorkingHourDetailsByIdFetch,
} from "../../../fetches/workingHourFetches";
import { getWorkingHourFormOptionsFetch } from "../../../fetches/formOptionFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function WorkingHourActionModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
    // States
    workingHourId,
  } = props;

  // Redux Store
  const token = useSelector((state) => state.staff.token);
  const workingPeriodId = useSelector(
    (state) => state.workingPeriodsAndPlacesPage.workingPeriodId
  );

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [workingHourActionDialogText, setWorkingHourActionDialogText] =
    useState("");
  const [workingHourActionDialogType, setWorkingHourActionDialogType] =
    useState(null);
  const [showWorkingHourActionDialog, setShowWorkingHourActionDialog] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [startWeekdayField, setStartWeekdayField] = useState(null);
  const [endWeekdayField, setEndWeekdayField] = useState(null);
  // Options
  const [weekdayOptions, setWeekdayOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Switches
  const [isAlternate, setIsAlternate] = useState(false);
  const [isLongWeek, setIsLongWeek] = useState(false);
  // Time Fields
  const [afterLunchTime, setAfterLunchTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [lunchTime, setLunchTime] = useState(null);
  const [startTime, setStartTime] = useState(null);

  // Hanlde States
  const afterLunchTimeVar = afterLunchTime ? new Date(afterLunchTime) : null;
  const endTimeVar = endTime ? new Date(endTime) : null;
  const lunchTimeVar = lunchTime ? new Date(lunchTime) : null;
  const startTimeVar = startTime ? new Date(startTime) : null;

  // Events
  // Events - Dialogs
  const onWorkingHourActionDialogCanceled = () => {
    // Set States
    setShowWorkingHourActionDialog(false);
  };

  const onWorkingHourActionDialogConfirmed = () => {
    switch (workingHourActionDialogType) {
      case "CreateWorkingHour":
        createWorkingHour();
        break;
      case "EditWorkingHour":
        editWorkingHour();
        break;
      default:
        break;
    }

    // Set States
    setShowWorkingHourActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "afterLunchTime":
        setAfterLunchTime(value);
        break;
      case "endTime":
        setEndTime(value);
        break;
      case "isAlternate":
        setIsAlternate(value);
        break;
      case "isLongWeek":
        setIsLongWeek(value);
        break;
      case "lunchTime":
        setLunchTime(value);
        break;
      case "startTime":
        setStartTime(value);
        break;
      case "startWeekdayField":
        setStartWeekdayField(value);
        break;
      case "endWeekdayField":
        setEndWeekdayField(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!startTime) {
      addToErrorFields("startTime", t("請先填寫 工作時間"));
      isError = true;
    }

    if (!endTime) {
      addToErrorFields("endTime", t("請先填寫 工作時間"));
      isError = true;
    }

    if (lunchTime && !afterLunchTime) {
      addToErrorFields("afterLunchTime", t("請先填寫 午飯時間"));
      isError = true;
    }

    if (afterLunchTime && !lunchTime) {
      addToErrorFields("lunchTime", t("請先填寫 午飯時間"));
      isError = true;
    }

    if (!startWeekdayField) {
      addToErrorFields("startWeekdayField", t("請先填寫 工作日"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayWorkingHourActionDialog(
      workingHourId ? "EditWorkingHour" : "CreateWorkingHour"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displayWorkingHourActionDialog = (workingHourActionType) => {
    // Set States
    setWorkingHourActionDialogType(workingHourActionType);

    switch (workingHourActionType) {
      case "CreateWorkingHour":
        setWorkingHourActionDialogText(t("確認要新增 工作時間 嗎？"));
        break;
      case "EditWorkingHour":
        setWorkingHourActionDialogText(t("確認要編輯 工作時間 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowWorkingHourActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createWorkingHour = async () => {
    const results = await createWorkingHourFetch(
      token,
      workingPeriodId,
      startWeekdayField ? startWeekdayField.id : null,
      endWeekdayField ? endWeekdayField.id : null,
      startTime,
      lunchTime,
      afterLunchTime,
      endTime,
      isAlternate,
      isLongWeek
    );

    if (results.success) {
      onModalClosed();
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editWorkingHour = async () => {
    const results = await editWorkingHourFetch(
      token,
      workingHourId,
      startWeekdayField ? startWeekdayField.id : null,
      endWeekdayField ? endWeekdayField.id : null,
      startTime,
      lunchTime,
      afterLunchTime,
      endTime,
      isAlternate,
      isLongWeek
    );

    if (results.success) {
      onModalClosed();
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  // Functions = Queries
  const getWorkingHourDetailsById = async () => {
    const results = await getWorkingHourDetailsByIdFetch(token, workingHourId);

    if (results.workingHourDetails) {
      const {
        start_time,
        lunch_time,
        after_lunch_time,
        end_time,
        is_alternate_week,
        is_long_week,
        start_week_day_id,
        end_week_day_id,
      } = results.workingHourDetails;

      // Set States
      setStartTime(start_time);
      setLunchTime(lunch_time);
      setAfterLunchTime(after_lunch_time);
      setEndTime(end_time);
      setIsAlternate(is_alternate_week);
      setIsLongWeek(is_long_week ? is_long_week : false);
      setStartWeekdayField(
        start_week_day_id
          ? weekdayOptions.find((item) => item.id === start_week_day_id)
          : null
      );
      setEndWeekdayField(
        end_week_day_id
          ? weekdayOptions.find((item) => item.id === end_week_day_id)
          : null
      );
    }

    clearErrorFields();
  };

  const getWorkingHourFormOptions = async () => {
    const results = await getWorkingHourFormOptionsFetch(token);

    // Set States
    setWeekdayOptions(results.weekdays ? results.weekdays : []);
  };

  // Life Cycle
  useEffect(() => {
    getWorkingHourFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && weekdayOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [weekdayOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (workingHourId) {
        getWorkingHourDetailsById();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, workingHourId]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onWorkingHourActionDialogCanceled}
        onDialogConfirmed={onWorkingHourActionDialogConfirmed}
        // States
        dialogText={workingHourActionDialogText}
        showDialog={showWorkingHourActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {workingHourId ? t("編輯工作時間") : t("新增工作時間")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid className={classes.formAutoCompleteContainer} item xs={5}>
            {weekdayOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option[t("weekday_name_ch_full")]}
                onChange={(event, value) =>
                  onInputFieldChange("startWeekdayField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={weekdayOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("startWeekdayField")}
                    helperText={getErrorFieldMessage("startWeekdayField")}
                    label={t("工作日")}
                    variant="standard"
                  />
                )}
                value={startWeekdayField}
              />
            )}
          </Grid>
          <Grid className={classes.formTwinInputContainerText} item xs={2}>
            <Typography align={"left"} variant="body1">
              {t("至")}
            </Typography>
          </Grid>
          <Grid className={classes.formAutoCompleteContainer} item xs={5}>
            {weekdayOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option[t("weekday_name_ch_full")]}
                onChange={(event, value) =>
                  onInputFieldChange("endWeekdayField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={weekdayOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("endWeekdayField")}
                    helperText={getErrorFieldMessage("endWeekdayField")}
                    label={t("工作日")}
                    variant="standard"
                  />
                )}
                value={endWeekdayField}
              />
            )}
          </Grid>
          <Grid className={classes.formAutoCompleteContainer} item xs={5}>
            <TimePicker
              label={t("工作時間")}
              onChange={(value) => onInputFieldChange("startTime", value)}
              slotProps={{
                textField: {
                  error: checkIsFieldError("startTime"),
                  helperText: getErrorFieldMessage("startTime"),
                },
              }}
              value={startTimeVar}
            />
          </Grid>
          <Grid className={classes.formTwinInputContainerText} item xs={2}>
            <Typography align={"left"} variant="body1">
              {t("至")}
            </Typography>
          </Grid>
          <Grid className={classes.formAutoCompleteContainer} item xs={5}>
            <TimePicker
              label={t("工作時間")}
              onChange={(value) => onInputFieldChange("endTime", value)}
              slotProps={{
                textField: {
                  error: checkIsFieldError("endTime"),
                  helperText: getErrorFieldMessage("endTime"),
                },
              }}
              value={endTimeVar}
            />
          </Grid>
          <Grid item xs={12}>
            <SpacingBox
              // Render
              height={stylesConfig.spacingBoxMarginBottomSmall}
            />
          </Grid>
          <Grid className={classes.formAutoCompleteContainer} item xs={5}>
            <TimePicker
              label={t("午飯時間")}
              onChange={(value) => onInputFieldChange("lunchTime", value)}
              slotProps={{
                textField: {
                  error: checkIsFieldError("lunchTime"),
                  helperText: getErrorFieldMessage("lunchTime"),
                },
              }}
              value={lunchTimeVar}
            />
          </Grid>
          <Grid className={classes.formTwinInputContainerText} item xs={2}>
            <Typography align={"left"} variant="body1">
              {t("至")}
            </Typography>
          </Grid>
          <Grid className={classes.formAutoCompleteContainer} item xs={5}>
            <TimePicker
              label={t("午飯時間")}
              onChange={(value) => onInputFieldChange("afterLunchTime", value)}
              slotProps={{
                textField: {
                  error: checkIsFieldError("afterLunchTime"),
                  helperText: getErrorFieldMessage("afterLunchTime"),
                },
              }}
              value={afterLunchTimeVar}
            />
          </Grid>
          <Grid item xs={12} className={classes.formSwitchContainer}>
            <Typography align={"left"} variant="body1">
              {t("長短週")}
            </Typography>
            <Switch
              checked={isAlternate}
              onChange={() => onInputFieldChange("isAlternate", !isAlternate)}
            />
          </Grid>
          <Grid item xs={12} className={classes.formSwitchContainer}>
            <Typography align={"left"} variant="body1">
              {t("本週為長週")}
            </Typography>
            <Switch
              checked={isLongWeek}
              disabled={!isAlternate}
              onChange={() => onInputFieldChange("isLongWeek", !isLongWeek)}
            />
          </Grid>
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              {t("確定")}
            </Button>
          </Grid>
          {/* Alert */}
          {shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default WorkingHourActionModal;
