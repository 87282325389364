const initialState = {
  brandId: null,
  brandItem: null,
  dayOffTypeId: null,
  dayOffTypeItem: null,
  departmentId: null,
  divisionId: null,
  divisionType: null,
  formSubmitCount: 0,
  shopId: null,
  tabItem: "DayOffTypes",
  typeOfDayOffTypes: "Current",
};

export const dayOffTypesPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_DAY_OFF_TYPES_PAGE_BRAND_CHANGE":
      const { brandId } = action;
      return {
        ...state,
        brandId,
      };
    case "ON_DAY_OFF_TYPES_PAGE_BRAND_ITEM_CHANGE":
      const { brandItem } = action;
      return {
        ...state,
        brandItem,
      };
    case "ON_DAY_OFF_TYPES_PAGE_DAY_OFF_TYPE_CHANGE":
      const { dayOffTypeId } = action;
      return {
        ...state,
        dayOffTypeId,
      };
    case "ON_DAY_OFF_TYPES_PAGE_DAY_OFF_TYPE_ITEM_CHANGE":
      const { dayOffTypeItem } = action;
      return {
        ...state,
        dayOffTypeItem,
      };
    case "ON_DAY_OFF_TYPES_PAGE_DEPARTMENT_CHANGE":
      const { departmentId } = action;
      return {
        ...state,
        departmentId,
      };
    case "ON_DAY_OFF_TYPES_PAGE_DIVISION_CHANGE":
      const { divisionId, divisionType } = action;
      return {
        ...state,
        divisionId,
        divisionType,
      };
    case "ON_DAY_OFF_TYPES_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_DAY_OFF_TYPES_PAGE_SHOP_CHANGE":
      const { shopId } = action;
      return {
        ...state,
        shopId,
      };
    case "ON_DAY_OFF_TYPES_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    case "ON_DAY_OFF_TYPES_PAGE_TYPE_OF_DAY_OFF_TYPES_CHANGE":
      const { typeOfDayOffTypes } = action;
      return {
        ...state,
        typeOfDayOffTypes,
      };
    default:
      return state;
  }
};
