// Components
// Boxes
import QuestionBoxContentDate from "./questionBoxContentDate";
import QuestionBoxContentFile from "./questionBoxContentFile";
import QuestionBoxContentLocation from "./questionBoxContentLocation";
import QuestionBoxContentOption from "./questionBoxContentOption";
import QuestionBoxContentText from "./questionBoxContentText";
import QuestionBoxContentTime from "./questionBoxContentTime";

function QuestionBoxContainer(props) {
  // Props
  const {
    // Events
    onDateTimeInputFieldChange,
    onFileFieldClicked,
    onInputFieldKeyPressed,
    onLocationFieldClicked,
    onOptionInputFieldChange,
    onTextInputFieldChange,
    // States
    groupIndex,
    index,
    item,
  } = props;

  // Handle States
  const shouldDisplay = item.shouldDisplay;

  return shouldDisplay ? (
    <>
      {/* 選項 */}
      {item.application_question_type_id === 1 && (
        <QuestionBoxContentOption
          // Events
          onInputFieldKeyPressed={onInputFieldKeyPressed}
          onOptionInputFieldChange={onOptionInputFieldChange}
          // States
          groupIndex={groupIndex}
          index={index}
          item={item}
        />
      )}
      {/* 文字 */}
      {item.application_question_type_id === 2 && (
        <QuestionBoxContentText
          // Events
          onTextInputFieldChange={onTextInputFieldChange}
          // States
          groupIndex={groupIndex}
          index={index}
          item={item}
        />
      )}
      {/* 日期 */}
      {item.application_question_type_id === 3 && (
        <QuestionBoxContentDate
          // Events
          onInputFieldKeyPressed={onInputFieldKeyPressed}
          onDateTimeInputFieldChange={onDateTimeInputFieldChange}
          // States
          groupIndex={groupIndex}
          index={index}
          item={item}
        />
      )}
      {/* 時間 */}
      {item.application_question_type_id === 4 && (
        <QuestionBoxContentTime
          // Events
          onInputFieldKeyPressed={onInputFieldKeyPressed}
          onDateTimeInputFieldChange={onDateTimeInputFieldChange}
          // States
          groupIndex={groupIndex}
          index={index}
          item={item}
        />
      )}
      {/* 檔案 */}
      {item.application_question_type_id === 5 && (
        <QuestionBoxContentFile
          // Events
          onFileFieldClicked={onFileFieldClicked}
          // States
          item={item}
        />
      )}
      {/* 位置 */}
      {item.application_question_type_id === 6 && (
        <QuestionBoxContentLocation
          // Events
          onLocationFieldClicked={onLocationFieldClicked}
          // States
          item={item}
        />
      )}
    </>
  ) : null;
}

export default QuestionBoxContainer;
