// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import DeptAndShopBoxesContainer from "../components/boxes/salariesPage/deptAndShopBoxesContainer";
import SettingTypeBoxContainer from "../components/boxes/salariesPage/settingTypeBoxContainer";
// Forms
import SalaryCountSettingForm from "../components/forms/salariesPage/salaryCountSettingForm";
// Headers
import SalariesPageHeader from "../components/headers/salariesPage/salariesPageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";
// Tables
import SalarySummaryTableContainer from "../components/tables/salariesPage/salarySummaryTableContainer";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function SalariesPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const tabItem = useSelector((state) => state.salariesPage.tabItem);

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("Salaries"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <SalariesPageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          {tabItem === "Salaries" && <DeptAndShopBoxesContainer />}
          {tabItem === "Settings" && <SettingTypeBoxContainer />}
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          {tabItem === "Salaries" && (
            <>
              <SalarySummaryTableContainer />
              <SpacingBox
                // Render
                height={stylesConfig.spacingBoxMarginBottom}
              />
            </>
          )}
          {tabItem === "Settings" && (
            <>
              <SalaryCountSettingForm />
              <SpacingBox
                // Render
                height={stylesConfig.spacingBoxMarginBottom}
              />
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default SalariesPage;
