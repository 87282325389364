// Actions
import {
  onFormSubmitCountIncrease,
  onShopChange,
} from "../../../redux/pages/deptsAndShopsPage/deptsAndShopsPageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getShopDetailFormOptionsFetch } from "../../../fetches/formOptionFetches";
import {
  createShopFetch,
  editShopFetch,
  getShopDetailsByIdFetch,
} from "../../../fetches/shopFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ShopDetailForm() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const brandId = useSelector((state) => state.deptsAndShopsPage.brandId);
  const shopId = useSelector((state) => state.deptsAndShopsPage.shopId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [shopDetailActionDialogText, setShopDetailActionDialogText] =
    useState("");
  const [shopDetailActionDialogType, setShopDetailActionDialogType] =
    useState(null);
  const [showShopDetailActionDialog, setShowShopDetailActionDialog] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [areaField, setAreaField] = useState(null);
  const [brandField, setBrandField] = useState(null);
  const [districtField, setDistrictField] = useState(null);
  const [headStaffField, setHeadStaffField] = useState([]);
  // Options
  const [areaOptions, setAreaOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [headStaffOptions, setHeadStaffOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Switches
  const [isItemActive, setIsItemActive] = useState(true);
  // Text Fields
  const [addressCh, setAddressCh] = useState("");
  const [addressEn, setAddressEn] = useState("");
  const [code, setCode] = useState("");
  const [nameCh, setNameCh] = useState("");
  const [nameEn, setNameEn] = useState("");

  // Events
  // Events - Dialogs
  const onShopDetailActionDialogCanceled = () => {
    // Set States
    setShowShopDetailActionDialog(false);
  };

  const onShopDetailActionDialogConfirmed = () => {
    switch (shopDetailActionDialogType) {
      case "CreateShop":
        createShop();
        break;
      case "EditShop":
        editShop();
        break;
      default:
        break;
    }

    // Set States
    setShowShopDetailActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "addressCh":
        setAddressCh(value);
        break;
      case "addressEn":
        setAddressEn(value);
        break;
      case "areaField":
        setAreaField(value);
        setDistrictField(
          value
            ? districtOptions.find((item) => item.area_id === value.id)
            : null
        );

        removeErrorField("districtField");

        break;
      case "code":
        setCode(value);
        break;
      case "districtField":
        setDistrictField(value);
        break;
      case "headStaffField":
        setHeadStaffField(value);
        break;
      case "isItemActive":
        setIsItemActive(value);
        break;
      case "nameCh":
        setNameCh(value);
        break;
      case "nameEn":
        setNameEn(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!brandField) {
      addToErrorFields("brandField", t("請先填寫 品牌"));
      isError = true;
    }

    if (!code) {
      addToErrorFields("code", t("請先填寫 店舖代號"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayShopDetailActionDialog(shopId ? "EditShop" : "CreateShop");
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const clearForm = () => {
    // Set States
    setBrandField(
      brandId ? brandOptions.find((item) => item.id === brandId) : null
    );
    setCode("");
    setNameCh("");
    setNameEn("");
    setAddressCh("");
    setAddressEn("");
    setIsItemActive(true);
    setDistrictField(null);
    setAreaField(null);

    setHeadStaffOptions([]);
    setHeadStaffField([]);

    clearErrorFields();
  };

  const displayShopDetailActionDialog = (shopDetailActionType) => {
    // Set States
    setShopDetailActionDialogType(shopDetailActionType);

    switch (shopDetailActionType) {
      case "CreateShop":
        setShopDetailActionDialogText(t("確認要新增 店舖 嗎？"));
        break;
      case "EditShop":
        setShopDetailActionDialogText(t("確認要編輯 店舖資料 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowShopDetailActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createShop = async () => {
    const results = await createShopFetch(
      token,
      brandField ? brandField.id : null,
      code,
      nameCh,
      nameEn,
      districtField ? districtField.id : null,
      addressCh,
      addressEn,
      isItemActive
    );

    if (results.success) {
      showFormAlert("success", t("成功提交"));

      // Update Redux Store
      dispatch(onShopChange(results.shopId));
      dispatch(onFormSubmitCountIncrease());
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 店舖 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editShop = async () => {
    if (shopId) {
      const results = await editShopFetch(
        token,
        shopId,
        brandField ? brandField.id : null,
        code,
        nameCh,
        nameEn,
        headStaffField[0] ? headStaffField.map((item) => item.id) : null,
        districtField ? districtField.id : null,
        addressCh,
        addressEn,
        isItemActive
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else if (results.isDuplicate) {
        showFormAlert("warning", t("此 店舖 已存在"));
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getShopDetailFormOptions = async () => {
    const results = await getShopDetailFormOptionsFetch(token);

    // Set States
    setAreaOptions(results.areas ? results.areas : []);
    setBrandOptions(results.brands ? results.brands : []);
    setDistrictOptions(results.districts ? results.districts : []);
  };

  const getShopDetailsById = async () => {
    const results = await getShopDetailsByIdFetch(token, shopId);

    if (results.shopDetails) {
      const {
        brand_id,
        shop_code,
        shop_name_ch,
        shop_name_en,
        address_ch,
        address_en,
        is_active,
        district_id,
        area_id,
      } = results.shopDetails;

      // Set States
      setBrandField(brandOptions.find((item) => item.id === brand_id));
      setCode(shop_code ? shop_code : "");
      setNameCh(shop_name_ch ? shop_name_ch : "");
      setNameEn(shop_name_en ? shop_name_en : "");
      setAddressCh(address_ch ? address_ch : "");
      setAddressEn(address_en ? address_en : "");
      setIsItemActive(is_active);
      setDistrictField(
        district_id
          ? districtOptions.find((item) => item.id === district_id)
          : null
      );
      setAreaField(
        area_id ? areaOptions.find((item) => item.id === area_id) : null
      );
    } else {
      // Set States
      setBrandField(null);
      setCode("");
      setNameCh("");
      setNameEn("");
      setDistrictField(null);
      setAddressCh("");
      setAddressEn("");
      setIsItemActive(true);
      setAreaField(null);
    }

    if (results.shopDetails && results.staffs) {
      // Set States
      setHeadStaffOptions(results.staffs);

      const headStaffsArr = results.staffs.filter(
        (item) => item.isTeamHead === true
      );

      setHeadStaffField(headStaffsArr);
    } else {
      // Set States
      setHeadStaffOptions([]);
      setHeadStaffField([]);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    getShopDetailFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && areaOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [areaOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (shopId) {
        getShopDetailsById();
      } else {
        clearForm();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, shopId]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onShopDetailActionDialogCanceled}
        onDialogConfirmed={onShopDetailActionDialogConfirmed}
        // States
        dialogText={shopDetailActionDialogText}
        showDialog={showShopDetailActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("店舖資料")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={6}>
          {brandOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option.brand_name_en_full}
              onChange={(event, value) =>
                onInputFieldChange("brandField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={brandOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("brandField")}
                  helperText={getErrorFieldMessage("brandField")}
                  label={t("品牌")}
                  variant="standard"
                />
              )}
              value={brandField}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("code")}
            fullWidth
            helperText={getErrorFieldMessage("code")}
            label={t("店舖代號")}
            margin="dense"
            name="code"
            onChange={(event) => onInputFieldChange("code", event.target.value)}
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={code}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("nameCh")}
            fullWidth
            helperText={getErrorFieldMessage("nameCh")}
            label={t("中文名稱")}
            margin="dense"
            name="nameCh"
            onChange={(event) =>
              onInputFieldChange("nameCh", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={nameCh}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("nameEn")}
            fullWidth
            helperText={getErrorFieldMessage("nameEn")}
            label={t("英文名稱")}
            margin="dense"
            name="nameEn"
            onChange={(event) =>
              onInputFieldChange("nameEn", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            required
            value={nameEn}
            variant="standard"
          />
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {headStaffField && headStaffOptions && (
            <Autocomplete
              disabled={!shopId}
              disablePortal
              getOptionLabel={(option) =>
                `${option.staff_code} - ${option.full_name_en}`
              }
              multiple
              noOptionsText={t("未有相關員工")}
              onChange={(event, value) =>
                onInputFieldChange("headStaffField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={headStaffOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("headStaffField")}
                  helperText={getErrorFieldMessage("headStaffField")}
                  label={t("店舖主管")}
                  variant="standard"
                />
              )}
              value={headStaffField}
            />
          )}
        </Grid>
        <Grid item xs={6} className={classes.formSwitchContainer}>
          <Typography align={"left"} variant="body1">
            {t("有效")}
          </Typography>
          <Switch
            checked={isItemActive}
            onChange={() => onInputFieldChange("isItemActive", !isItemActive)}
          />
        </Grid>
      </Grid>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("地址")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {areaOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option[t("area_name_ch")]}
              onChange={(event, value) =>
                onInputFieldChange("areaField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={areaOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("areaField")}
                  helperText={getErrorFieldMessage("areaField")}
                  label={t("區域")}
                  variant="standard"
                />
              )}
              value={areaField}
            />
          )}
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {areaOptions &&
            (areaField && districtOptions ? (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option[t("district_name_ch")]}
                onChange={(event, value) =>
                  onInputFieldChange("districtField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={districtOptions.filter(
                  (option) => option.area_id === areaField.id
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("districtField")}
                    helperText={getErrorFieldMessage("districtField")}
                    label={t("地區")}
                    variant="standard"
                  />
                )}
                value={districtField}
              />
            ) : (
              <Autocomplete
                disabled
                disablePortal
                options={[]}
                renderInput={(params) => (
                  <TextField {...params} label={t("地區")} variant="standard" />
                )}
                value={null}
              />
            ))}
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={checkIsFieldError("addressCh")}
            fullWidth
            helperText={getErrorFieldMessage("addressCh")}
            label={t("中文地址")}
            margin="dense"
            name="addressCh"
            onChange={(event) =>
              onInputFieldChange("addressCh", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={addressCh}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={checkIsFieldError("addressEn")}
            fullWidth
            helperText={getErrorFieldMessage("addressEn")}
            label={t("英文地址")}
            margin="dense"
            name="addressEn"
            onChange={(event) =>
              onInputFieldChange("addressEn", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={addressEn}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <Button
            onClick={onSubmitBtnClicked}
            variant="contained"
            sx={formSubmitBtnStyles}
          >
            {shopId ? t("修改") : t("新增")}
          </Button>
        </Grid>
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default ShopDetailForm;
