// Configs
import apisConfig from "../configs/apisConfig";

export const createEducationToFilesFetch = async (
  token,
  educationId,
  files
) => {
  try {
    const queryRoute = `/educationToFile/createEducationToFiles/${educationId}`;

    const reqBody = new FormData();

    if (files && files[0]) {
      for (let item of files) {
        reqBody.append("files", item);
      }
    }

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: reqBody,
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteEducationToFileFetch = async (token, fileId) => {
  try {
    const queryRoute = `/educationToFile/deleteEducationToFile/${fileId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllFilesByEducationIdFetch = async (token, educationId) => {
  try {
    const queryRoute = `/educationToFile/getAllFilesByEducationId/${educationId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
