// Actions
import {
  onBrandChange as onAttendancesPageBrandChange,
  onDepartmentChange as onAttendancesPageDepartmentChange,
  onDivisionChange as onAttendancesPageDivisionChange,
  onShopChange as onAttendancesPageShopChange,
  onTabItemChange as onAttendancesPageTabItemChange,
} from "../../../redux/pages/attendancesPage/attendancesPageActions";
import {
  onBrandChange as onDayOffTypesPageBrandChange,
  onDepartmentChange as onDayOffTypesPageDepartmentChange,
  onDivisionChange as onDayOffTypesPageDivisionChange,
  onShopChange as onDayOffTypesPageShopChange,
  onTabItemChange as onDayOffTypesPageTabItemChange,
} from "../../../redux/pages/dayOffTypesPage/dayOffTypesPageActions";
import { onTypeOfContentChange as onDeptsAndShopsPageTypeOfContentChange } from "../../../redux/pages/deptsAndShopsPage/deptsAndShopsPageActions";
import {
  onBrandChange as onRostersPageBrandChange,
  onDepartmentChange as onRostersPageDepartmentChange,
  onDivisionChange as onRostersPageDivisionChange,
  onShopChange as onRostersPageShopChange,
  onTypeOfContentChange as onRostersPageTypeOfContentChange,
  onTypeOfWorkingPeriodsAndShiftsChange as onRostersPageTypeOfWorkingPeriodsAndShiftsChange,
} from "../../../redux/pages/rostersPage/rostersPageActions";
import {
  onBrandChange as onSalariesPageBrandChange,
  onDepartmentChange as onSalariesPageDepartmentChange,
  onDivisionChange as onSalariesPageDivisionChange,
  onShopChange as onSalariesPageShopChange,
  onTabItemChange as onSalariesPageTabItemChange,
} from "../../../redux/pages/salariesPage/salariesPageActions";

// Components
import StyledMenu from "../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import EventRoundedIcon from "@mui/icons-material/EventRounded";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

function DeptAndShopActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();

  // Props
  const {
    // States
    showDeptAndShopActionMenu,
    // Set States
    setShowDeptAndShopActionMenu,
    // Functions
    displayDeptAndShopActionDialog,
  } = props;

  // Redux Store
  const brandId = useSelector((state) => state.deptsAndShopsPage.brandId);
  const departmentId = useSelector(
    (state) => state.deptsAndShopsPage.departmentId
  );
  const divisionId = useSelector((state) => state.deptsAndShopsPage.divisionId);
  const divisionType = useSelector(
    (state) => state.deptsAndShopsPage.divisionType
  );
  const shopId = useSelector((state) => state.deptsAndShopsPage.shopId);
  const typeOfContent = useSelector(
    (state) => state.deptsAndShopsPage.typeOfContent
  );
  const typeOfDeptsAndShops = useSelector(
    (state) => state.deptsAndShopsPage.typeOfDeptsAndShops
  );

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowDeptAndShopActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "Add":
        // Navigate
        navigate("/createStaff", {
          state: { departmentId, divisionId, divisionType, shopId },
        });
        break;
      case "Attendances":
        // Update Redux Store
        dispatch(onAttendancesPageBrandChange(brandId));
        dispatch(onAttendancesPageDivisionChange(divisionId, divisionType));
        dispatch(onAttendancesPageTabItemChange("AttendanceRecords"));

        if (divisionType === "Departments") {
          dispatch(onAttendancesPageDepartmentChange(departmentId));
        } else {
          dispatch(onAttendancesPageShopChange(shopId));
        }

        // Navigate
        navigate("/attendances");
        break;
      case "DayOffTypes":
        // Update Redux Store
        dispatch(onDayOffTypesPageBrandChange(brandId));
        dispatch(onDayOffTypesPageDivisionChange(divisionId, divisionType));
        dispatch(onDayOffTypesPageTabItemChange("DayOffQuotas"));

        if (divisionType === "Departments") {
          dispatch(onDayOffTypesPageDepartmentChange(departmentId));
        } else {
          dispatch(onDayOffTypesPageShopChange(shopId));
        }

        // Navigate
        navigate("/dayOffTypes");
        break;
      case "Delete":
        displayDeptAndShopActionDialog(
          divisionType === "Departments" ? "DeleteDepartment" : "DeleteShop"
        );
        break;
      case "Edit":
        // Update Redux Store
        dispatch(onDeptsAndShopsPageTypeOfContentChange("DeptAndShopDetail"));
        break;
      case "Restore":
        displayDeptAndShopActionDialog(
          divisionType === "Departments" ? "RestoreDepartment" : "RestoreShop"
        );
        break;
      case "Rosters":
        // Update Redux Store
        dispatch(onRostersPageBrandChange(brandId));
        dispatch(onRostersPageDivisionChange(divisionId, divisionType));
        dispatch(onRostersPageTypeOfContentChange("WorkingPeriods"));
        dispatch(onRostersPageTypeOfWorkingPeriodsAndShiftsChange("Current"));

        if (divisionType === "Departments") {
          dispatch(onRostersPageDepartmentChange(departmentId));
        } else {
          dispatch(onRostersPageShopChange(shopId));
        }

        // Navigate
        navigate("/rosters");
        break;
      case "Salaries":
        // Update Redux Store
        dispatch(onSalariesPageBrandChange(brandId));
        dispatch(onSalariesPageDivisionChange(divisionId, divisionType));
        dispatch(onSalariesPageTabItemChange("Salaries"));

        if (divisionType === "Departments") {
          dispatch(onSalariesPageDepartmentChange(departmentId));
        } else {
          dispatch(onSalariesPageShopChange(shopId));
        }

        // Navigate
        navigate("/salaries");
        break;
      case "StaffList":
        // Update Redux Store
        dispatch(onDeptsAndShopsPageTypeOfContentChange("StaffList"));
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showDeptAndShopActionMenu}
      open={Boolean(showDeptAndShopActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Add")}>
        <AddRoundedIcon />
        {t("新增員工")}
      </MenuItem>
      {typeOfContent === "StaffList" ? (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Edit")}>
          <EditRoundedIcon />
          {t("編輯")}
        </MenuItem>
      ) : (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("StaffList")}>
          <FormatListBulletedRoundedIcon />
          {t("員工列表")}
        </MenuItem>
      )}
      {typeOfDeptsAndShops === "Current" && (
        <MenuItem
          disableRipple
          onClick={() => onMenuItemClicked("Attendances")}
        >
          <AccessTimeRoundedIcon />
          {t("出勤紀錄s")}
        </MenuItem>
      )}
      {typeOfDeptsAndShops === "Current" && (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Rosters")}>
          <DateRangeRoundedIcon />
          {t("更表s")}
        </MenuItem>
      )}
      {typeOfDeptsAndShops === "Current" && (
        <MenuItem
          disableRipple
          onClick={() => onMenuItemClicked("DayOffTypes")}
        >
          <EventRoundedIcon />
          {t("假期管理s")}
        </MenuItem>
      )}
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Salaries")}>
        <AttachMoneyRoundedIcon />
        {t("薪酬s")}
      </MenuItem>
      {typeOfDeptsAndShops === "Current" ? (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Delete")}>
          <DeleteRoundedIcon />
          {t("刪除")}
        </MenuItem>
      ) : (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Restore")}>
          <RestoreRoundedIcon />
          {t("還原")}
        </MenuItem>
      )}
    </StyledMenu>
  );
}

export default DeptAndShopActionMenu;
