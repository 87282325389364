const today = new Date();
const currentMonth = new Date(today.getFullYear(), today.getMonth(), 1);

const initialState = {
  applicationId: null,
  applicationTypeId: null,
  conditionApplicationOrApprovalTypeValue: null,
  conditionApplicationOrApprovalTypeItem: null,
  conditionApplicationStatusTypeId: null,
  conditionApplicationStatusTypeItem: null,
  conditionApplicationTypeId: null,
  conditionApplicationTypeItem: null,
  conditionCandidateStatusTypeId: null,
  conditionCandidateStatusTypeItem: null,
  formSubmitCount: 0,
  procedureApplicationProcedureId: null,
  procedureApplicationProcedureItem: null,
  procedureApplicationTypeId: null,
  procedureApplicationTypeItem: null,
  recordMonth: currentMonth.toString(),
  tabItem: "ApplicationRecords",
};

export const applicationsPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_APPLICATIONS_PAGE_APPLICATION_CHANGE":
      const { applicationId, applicationTypeId } = action;
      return {
        ...state,
        applicationId,
        applicationTypeId,
      };
    case "ON_APPLICATIONS_PAGE_CONDITION_APPLICATION_OR_APPROVAL_TYPE_CHANGE":
      const { conditionApplicationOrApprovalTypeValue } = action;
      return {
        ...state,
        conditionApplicationOrApprovalTypeValue,
      };
    case "ON_APPLICATIONS_PAGE_CONDITION_APPLICATION_OR_APPROVAL_TYPE_ITEM_CHANGE":
      const { conditionApplicationOrApprovalTypeItem } = action;
      return {
        ...state,
        conditionApplicationOrApprovalTypeItem,
      };
    case "ON_APPLICATIONS_PAGE_CONDITION_APPLICATION_STATUS_TYPE_CHANGE":
      const { conditionApplicationStatusTypeId } = action;
      return {
        ...state,
        conditionApplicationStatusTypeId,
      };
    case "ON_APPLICATIONS_PAGE_CONDITION_APPLICATION_STATUS_TYPE_ITEM_CHANGE":
      const { conditionApplicationStatusTypeItem } = action;
      return {
        ...state,
        conditionApplicationStatusTypeItem,
      };
    case "ON_APPLICATIONS_PAGE_CONDITION_APPLICATION_TYPE_CHANGE":
      const { conditionApplicationTypeId } = action;
      return {
        ...state,
        conditionApplicationTypeId,
      };
    case "ON_APPLICATIONS_PAGE_CONDITION_APPLICATION_TYPE_ITEM_CHANGE":
      const { conditionApplicationTypeItem } = action;
      return {
        ...state,
        conditionApplicationTypeItem,
      };
    case "ON_APPLICATIONS_PAGE_CONDITION_CANDIDATE_STATUS_TYPE_CHANGE":
      const { conditionCandidateStatusTypeId } = action;
      return {
        ...state,
        conditionCandidateStatusTypeId,
      };
    case "ON_APPLICATIONS_PAGE_CONDITION_CANDIDATE_STATUS_TYPE_ITEM_CHANGE":
      const { conditionCandidateStatusTypeItem } = action;
      return {
        ...state,
        conditionCandidateStatusTypeItem,
      };
    case "ON_APPLICATIONS_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_APPLICATIONS_PAGE_PROCEDURE_APPLICATION_PROCEDURE_CHANGE":
      const { procedureApplicationProcedureId } = action;
      return {
        ...state,
        procedureApplicationProcedureId,
      };
    case "ON_APPLICATIONS_PAGE_PROCEDURE_APPLICATION_PROCEDURE_ITEM_CHANGE":
      const { procedureApplicationProcedureItem } = action;
      return {
        ...state,
        procedureApplicationProcedureItem,
      };
    case "ON_APPLICATIONS_PAGE_PROCEDURE_APPLICATION_TYPE_CHANGE":
      const { procedureApplicationTypeId } = action;
      return {
        ...state,
        procedureApplicationTypeId,
      };
    case "ON_APPLICATIONS_PAGE_PROCEDURE_APPLICATION_TYPE_ITEM_CHANGE":
      const { procedureApplicationTypeItem } = action;
      return {
        ...state,
        procedureApplicationTypeItem,
      };
    case "ON_APPLICATIONS_PAGE_RECORD_MONTH_CHANGE":
      const { recordMonth } = action;
      return {
        ...state,
        recordMonth: recordMonth.toString(),
      };
    case "ON_APPLICATIONS_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    default:
      return state;
  }
};
