// Actions
import {
  onBrandChange,
  onCompanyChange,
  onFormSubmitCountIncrease,
} from "../../../redux/pages/compsAndBrandsPage/compsAndBrandsPageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  createBrandFetch,
  editBrandFetch,
  getBrandDetailsByIdFetch,
} from "../../../fetches/brandFetches";
import {
  createCompanyFetch,
  editCompanyFetch,
  getCompanyDetailsByIdFetch,
} from "../../../fetches/companyFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function CompAndBrandDetailForm() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const brandId = useSelector((state) => state.compsAndBrandsPage.brandId);
  const companyId = useSelector((state) => state.compsAndBrandsPage.companyId);
  const tabItem = useSelector((state) => state.compsAndBrandsPage.tabItem);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [
    compAndBrandDetailActionDialogText,
    setCompAndBrandDetailActionDialogText,
  ] = useState("");
  const [
    compAndBrandDetailActionDialogType,
    setCompAndBrandDetailActionDialogType,
  ] = useState(null);
  const [
    showCompAndBrandDetailActionDialog,
    setShowCompAndBrandDetailActionDialog,
  ] = useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Switches
  const [isItemActive, setIsItemActive] = useState(true);
  // Text Fields
  const [nameCh, setNameCh] = useState("");
  const [nameEnShort, setNameEnShort] = useState("");
  const [nameEnFull, setNameEnFull] = useState("");

  // Events
  // Events - Dialogs
  const onCompAndBrandDetailActionDialogCanceled = () => {
    // Set States
    setShowCompAndBrandDetailActionDialog(false);
  };

  const onCompAndBrandDetailActionDialogConfirmed = () => {
    switch (compAndBrandDetailActionDialogType) {
      case "CreateBrand":
        createBrand();
        break;
      case "CreateCompany":
        createCompany();
        break;
      case "EditBrand":
        editBrand();
        break;
      case "EditCompany":
        editCompany();
        break;
      default:
        break;
    }

    if (tabItem === "Companies") {
      if (companyId) {
        editCompany();
      } else {
        createCompany();
      }
    } else {
      if (brandId) {
        editBrand();
      } else {
        createBrand();
      }
    }

    // Set States
    setShowCompAndBrandDetailActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "isItemActive":
        setIsItemActive(value);
        break;
      case "nameCh":
        setNameCh(value);
        break;
      case "nameEnShort":
        setNameEnShort(value);
        break;
      case "nameEnFull":
        setNameEnFull(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!nameEnFull) {
      addToErrorFields("nameEnFull", t("請先填寫 英文全名"));
      isError = true;
    }

    if (!nameEnShort) {
      addToErrorFields("nameEnShort", t("請先填寫 英文簡稱"));
      isError = true;
    }

    if (isError) {
      return;
    }

    // Set States
    if (tabItem === "Companies") {
      displayCompAndBrandDetailActionDialog(
        companyId ? "EditCompany" : "CreateCompany"
      );
    } else {
      displayCompAndBrandDetailActionDialog(
        brandId ? "EditBrand" : "CreateBrand"
      );
    }
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const clearForm = () => {
    // Set States
    setNameCh("");
    setNameEnFull("");
    setNameEnShort("");
    setIsItemActive(true);

    clearErrorFields();
  };

  const displayCompAndBrandDetailActionDialog = (
    compAndBrandDetailActionType
  ) => {
    // Set States
    setCompAndBrandDetailActionDialogType(compAndBrandDetailActionType);

    switch (compAndBrandDetailActionType) {
      case "CreateBrand":
        setCompAndBrandDetailActionDialogText(t("確認要新增 品牌 嗎？"));
        break;
      case "CreateCompany":
        setCompAndBrandDetailActionDialogText(t("確認要新增 公司 嗎？"));
        break;
      case "EditBrand":
        setCompAndBrandDetailActionDialogText(t("確認要編輯 品牌資料 嗎？"));
        break;
      case "EditCompany":
        setCompAndBrandDetailActionDialogText(t("確認要編輯 公司資料 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowCompAndBrandDetailActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createBrand = async () => {
    const results = await createBrandFetch(
      token,
      nameCh,
      nameEnFull,
      nameEnShort,
      isItemActive
    );

    if (results.success) {
      showFormAlert("success", t("成功提交"));

      // Update Redux Store
      dispatch(onBrandChange(results.brandId));
      dispatch(onFormSubmitCountIncrease());
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 品牌 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const createCompany = async () => {
    const results = await createCompanyFetch(
      token,
      nameCh,
      nameEnFull,
      nameEnShort,
      isItemActive
    );

    if (results.success) {
      showFormAlert("success", t("成功提交"));

      // Update Redux Store
      dispatch(onCompanyChange(results.companyId));
      dispatch(onFormSubmitCountIncrease());
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 公司 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editBrand = async () => {
    const results = await editBrandFetch(
      token,
      brandId,
      nameCh,
      nameEnFull,
      nameEnShort,
      isItemActive
    );

    if (results.success) {
      showFormAlert("success", t("成功提交"));

      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 品牌 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editCompany = async () => {
    const results = await editCompanyFetch(
      token,
      companyId,
      nameCh,
      nameEnFull,
      nameEnShort,
      isItemActive
    );

    if (results.success) {
      showFormAlert("success", t("成功提交"));

      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 公司 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  // Functions - Queries
  const getBrandDetailsById = async () => {
    const results = await getBrandDetailsByIdFetch(token, brandId);

    if (results.brandDetails) {
      const {
        brand_name_ch,
        brand_name_en_full,
        brand_name_en_short,
        is_active,
      } = results.brandDetails;

      // Set States
      setNameCh(brand_name_ch ? brand_name_ch : "");
      setNameEnFull(brand_name_en_full ? brand_name_en_full : "");
      setNameEnShort(brand_name_en_short ? brand_name_en_short : "");
      setIsItemActive(is_active);
    } else {
      // Set States
      setNameCh("");
      setNameEnFull("");
      setNameEnShort("");
      setIsItemActive(true);
    }

    clearErrorFields();
  };

  const getCompanyDetailsById = async () => {
    const results = await getCompanyDetailsByIdFetch(token, companyId);

    if (results.companyDetails) {
      const {
        company_name_ch,
        company_name_en_full,
        company_name_en_short,
        is_active,
      } = results.companyDetails;

      // Set States
      setNameCh(company_name_ch ? company_name_ch : "");
      setNameEnFull(company_name_en_full ? company_name_en_full : "");
      setNameEnShort(company_name_en_short ? company_name_en_short : "");
      setIsItemActive(is_active);
    } else {
      // Set States
      setNameCh("");
      setNameEnFull("");
      setNameEnShort("");
      setIsItemActive(true);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    if (tabItem) {
      if (tabItem === "Companies") {
        if (companyId) {
          getCompanyDetailsById();
        } else {
          clearForm();
        }
      } else {
        if (brandId) {
          getBrandDetailsById();
        } else {
          clearForm();
        }
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [brandId, companyId, tabItem]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onCompAndBrandDetailActionDialogCanceled}
        onDialogConfirmed={onCompAndBrandDetailActionDialogConfirmed}
        // States
        dialogText={compAndBrandDetailActionDialogText}
        showDialog={showCompAndBrandDetailActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {`${tabItem === "Companies" ? t("公司") : t("品牌")}${t(" 資料i")}`}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("nameCh")}
            fullWidth
            helperText={getErrorFieldMessage("nameCh")}
            label={t("中文名稱")}
            margin="dense"
            name="nameCh"
            onChange={(event) =>
              onInputFieldChange("nameCh", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={nameCh}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("nameEnShort")}
            fullWidth
            helperText={getErrorFieldMessage("nameEnShort")}
            label={t("英文簡稱")}
            margin="dense"
            name="nameEnShort"
            onChange={(event) =>
              onInputFieldChange("nameEnShort", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            required
            value={nameEnShort}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("nameEnFull")}
            fullWidth
            helperText={getErrorFieldMessage("nameEnFull")}
            label={t("英文全名")}
            margin="dense"
            name="nameEnFull"
            onChange={(event) =>
              onInputFieldChange("nameEnFull", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={nameEnFull}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6} className={classes.formSwitchContainer}>
          <Typography align={"left"} variant="body1">
            {t("有效")}
          </Typography>
          <Switch
            checked={isItemActive}
            onChange={() => onInputFieldChange("isItemActive", !isItemActive)}
          />
        </Grid>
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          {tabItem && (
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              {tabItem === "Companies"
                ? companyId
                  ? t("修改")
                  : t("新增")
                : brandId
                ? t("修改")
                : t("新增")}
            </Button>
          )}
        </Grid>
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default CompAndBrandDetailForm;
