// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

// React
import { useState } from "react";

// Styles
import { useStandardTableStyles } from "../../../styles/componentStyles/tableStyles/standardTableStyles";

function RosterBox(props) {
  // Styles
  const classes = useStandardTableStyles();

  // Props
  const {
    // States
    shopId,
    editedStaffToDays,
    item,
    selectedStaffToDays,
    staffId,
    // Events
    onItemRightClicked,
    onMouseDown,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // States
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Tooltip
      title={
        item.actual_start_time && item.actual_end_time
          ? `${item.actual_start_time} - ${item.actual_end_time}`
          : ""
      }
    >
      <div
        className={
          selectedStaffToDays.some(
            (selectedStaffToDayItem) =>
              selectedStaffToDayItem.staffId === staffId &&
              selectedStaffToDayItem.dayId === item.day_id
          )
            ? classes.selectedCellBoxTiny
            : isHovered
            ? classes.hoveredCellBoxTiny
            : classes.cellBoxTiny
        }
        onContextMenu={(event) => {
          event.preventDefault();
          onItemRightClicked(staffId, item.day_id, event.currentTarget);
        }}
        onMouseDown={(event) =>
          onMouseDown(
            staffId,
            item.day_id,
            item.is_before_date_join,
            item.is_after_date_resign,
            event
          )
        }
        onMouseEnter={() => {
          onMouseEnterItem(
            staffId,
            item.day_id,
            item.is_before_date_join,
            item.is_after_date_resign,
            item.working_period_id,
            item.shift_id,
            item.day_off_type_id
          );
          // Set States
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          onMouseLeaveItem();
          // Set States
          setIsHovered(false);
        }}
      >
        <Typography
          align={"center"}
          color={
            selectedStaffToDays.some(
              (selectedStaffToDayItem) =>
                selectedStaffToDayItem.staffId === staffId &&
                selectedStaffToDayItem.dayId === item.day_id
            )
              ? stylesConfig.whiteTextColor
              : editedStaffToDays.some(
                  (editedStaffToDayItem) =>
                    editedStaffToDayItem.staffId === staffId &&
                    editedStaffToDayItem.dayId === item.day_id
                )
              ? stylesConfig.mainTextColor
              : item.working_period_code || item.shift_code
              ? null
              : item.is_day_off
              ? stylesConfig.redTextColor
              : stylesConfig.greyTextColor
          }
          variant="body1"
        >
          {item.is_before_date_join
            ? "-/-"
            : item.is_after_date_resign
            ? "-/-"
            : item.working_period_code
            ? item.working_period_code
            : item.shift_code
            ? item.shop_id !== shopId
              ? item.shop_code
              : item.shift_code
            : item.day_off_type_name_en_short
            ? item.day_off_type_name_en_short
            : "X"}
        </Typography>
      </div>
    </Tooltip>
  );
}

export default RosterBox;
