// Configs
import apisConfig from "../configs/apisConfig";

export const createPermissionGroupToStaffFetch = async (
  token,
  permissionGroupId,
  staffIdsArr
) => {
  try {
    const queryRoute = "/permissionGroupToStaff/createPermissionGroupToStaff";

    const reqBody = {
      permissionGroupId,
      staffIdsArr,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deletePermissionGroupToStaffFetch = async (
  token,
  permissionGroupId,
  staffIdsArr
) => {
  try {
    const queryRoute = "/permissionGroupToStaff/deletePermissionGroupToStaff";

    const reqBody = {
      permissionGroupId,
      staffIdsArr,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
