// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Forms
import CreateStaffFormContainer from "../components/forms/createStaffPage/createStaffFormContainer";
// Headers
import CreateStaffPageHeader from "../components/headers/createStaffPage/createStaffPageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch } from "react-redux";

// React-Router
import { useLocation } from "react-router-dom";

function CreateStaffPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();
  // Router
  const location = useLocation();

  // Params
  const candidateIdParam = location.state ? location.state.candidateId : null;
  const departmentIdParam = location.state ? location.state.departmentId : null;
  const divisionIdParam = location.state ? location.state.divisionId : null;
  const divisionTypeParam = location.state ? location.state.divisionType : null;
  const shopIdParam = location.state ? location.state.shopId : null;

  // Life Cycle
  useEffect(() => {
    dispatch(enterPage("CreateStaff"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <CreateStaffPageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <CreateStaffFormContainer
            // States
            candidateIdParam={candidateIdParam}
            departmentIdParam={departmentIdParam}
            divisionIdParam={divisionIdParam}
            divisionTypeParam={divisionTypeParam}
            shopIdParam={shopIdParam}
          />
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </Container>
  );
}

export default CreateStaffPage;
