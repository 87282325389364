// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  createBusinessHourFetch,
  editBusinessHourFetch,
  getBusinessHourDetailsByIdFetch,
} from "../../../fetches/businessHourFetches";
import { getShopBusinessHourFormOptionsFetch } from "../../../fetches/formOptionFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ShopBusinessHourActionModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
    // States
    businessHourId,
  } = props;

  // Redux Store
  const shopId = useSelector((state) => state.deptsAndShopsPage.shopId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [shopBusinessActionDialogText, setShopBusinessActionDialogText] =
    useState("");
  const [shopBusinessActionDialogType, setShopBusinessActionDialogType] =
    useState(null);
  const [showShopBusinessActionDialog, setShowShopBusinessActionDialog] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [startWeekdayField, setStartWeekdayField] = useState(null);
  const [endWeekdayField, setEndWeekdayField] = useState(null);
  // Options
  const [weekdayOptions, setWeekdayOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Switches
  const [includePublicHolidays, setIncludePublicHolidays] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  // Time Fields
  const [endTime, setEndTime] = useState(null);
  const [startTime, setStartTime] = useState(null);

  // Handle States
  const endTimeVar = endTime ? new Date(endTime) : null;
  const startTimeVar = startTime ? new Date(startTime) : null;

  // Events
  // Events - Dialogs
  const onShopBusinessActionDialogCanceled = () => {
    // Set States
    setShowShopBusinessActionDialog(false);
  };

  const onShopBusinessActionDialogConfirmed = () => {
    switch (shopBusinessActionDialogType) {
      case "CreateBusinessHour":
        createBusinessHour();
        break;
      case "EditBusinessHour":
        editBusinessHour();
        break;
      default:
        break;
    }

    // Set States
    setShowShopBusinessActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "endTime":
        setEndTime(value);
        break;
      case "includePublicHolidays":
        setIncludePublicHolidays(value);
        break;
      case "isClosed":
        setIsClosed(value);
        break;
      case "startTime":
        setStartTime(value);
        break;
      case "startWeekdayField":
        setStartWeekdayField(value);
        break;
      case "endWeekdayField":
        setEndWeekdayField(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!isClosed) {
      if (!startTime) {
        addToErrorFields("startTime", t("請先填寫 營業時間"));
        isError = true;
      }

      if (!endTime) {
        addToErrorFields("endTime", t("請先填寫 營業時間"));
        isError = true;
      }
    }

    if (!startWeekdayField) {
      addToErrorFields("startWeekdayField", t("請先填寫 工作日"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayShopBusinessActionDialog(
      businessHourId ? "EditBusinessHour" : "CreateBusinessHour"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displayShopBusinessActionDialog = (shopBusinessActionType) => {
    // Set States
    setShopBusinessActionDialogType(shopBusinessActionType);

    switch (shopBusinessActionType) {
      case "CreateBusinessHour":
        setShopBusinessActionDialogText(t("確認要新增 營業時間 嗎？"));
        break;
      case "EditBusinessHour":
        setShopBusinessActionDialogText(t("確認要編輯 營業時間 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowShopBusinessActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createBusinessHour = async () => {
    const results = await createBusinessHourFetch(
      token,
      shopId,
      startWeekdayField ? startWeekdayField.id : null,
      endWeekdayField ? endWeekdayField.id : null,
      startTime,
      endTime,
      includePublicHolidays,
      isClosed
    );

    if (results.success) {
      onModalClosed();
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editBusinessHour = async () => {
    const results = await editBusinessHourFetch(
      token,
      businessHourId,
      startWeekdayField ? startWeekdayField.id : null,
      endWeekdayField ? endWeekdayField.id : null,
      startTime,
      endTime,
      includePublicHolidays,
      isClosed
    );

    if (results.success) {
      onModalClosed();
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  // Functions = Queries
  const getBusinessHourDetailsById = async () => {
    const results = await getBusinessHourDetailsByIdFetch(
      token,
      businessHourId
    );

    if (results.businessHourDetails) {
      const {
        start_time,
        end_time,
        include_public_holidays,
        is_closed,
        start_week_day_id,
        end_week_day_id,
      } = results.businessHourDetails;

      // Set States
      setStartTime(start_time);
      setEndTime(end_time);
      setIncludePublicHolidays(include_public_holidays);
      setIsClosed(is_closed);
      setStartWeekdayField(
        start_week_day_id
          ? weekdayOptions.find((item) => item.id === start_week_day_id)
          : null
      );
      setEndWeekdayField(
        end_week_day_id
          ? weekdayOptions.find((item) => item.id === end_week_day_id)
          : null
      );
    }

    clearErrorFields();
  };

  const getShopBusinessHourFormOptions = async () => {
    const results = await getShopBusinessHourFormOptionsFetch(token);

    // Set States
    setWeekdayOptions(results.weekdays ? results.weekdays : []);
  };

  // Life Cycle
  useEffect(() => {
    getShopBusinessHourFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && weekdayOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [weekdayOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (businessHourId) {
        getBusinessHourDetailsById();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, businessHourId]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onShopBusinessActionDialogCanceled}
        onDialogConfirmed={onShopBusinessActionDialogConfirmed}
        // States
        dialogText={shopBusinessActionDialogText}
        showDialog={showShopBusinessActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {businessHourId ? t("編輯營業時間") : t("新增營業時間")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid className={classes.formAutoCompleteContainer} item xs={5}>
            {weekdayOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option.weekday_name_ch_full}
                onChange={(event, value) =>
                  onInputFieldChange("startWeekdayField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={weekdayOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("startWeekdayField")}
                    helperText={getErrorFieldMessage("startWeekdayField")}
                    label={t("工作日")}
                    variant="standard"
                  />
                )}
                value={startWeekdayField}
              />
            )}
          </Grid>
          <Grid className={classes.formTwinInputContainerText} item xs={2}>
            <Typography align={"left"} variant="body1">
              {t("至")}
            </Typography>
          </Grid>
          <Grid className={classes.formAutoCompleteContainer} item xs={5}>
            {weekdayOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option.weekday_name_ch_full}
                onChange={(event, value) =>
                  onInputFieldChange("endWeekdayField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={weekdayOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("endWeekdayField")}
                    helperText={getErrorFieldMessage("endWeekdayField")}
                    label={t("工作日")}
                    variant="standard"
                  />
                )}
                value={endWeekdayField}
              />
            )}
          </Grid>
          <Grid className={classes.formAutoCompleteContainer} item xs={5}>
            <TimePicker
              disabled={isClosed}
              label={t("營業時間")}
              onChange={(value) => onInputFieldChange("startTime", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("startTime")}
                  helperText={getErrorFieldMessage("startTime")}
                />
              )}
              slotProps={{
                textField: {
                  error: checkIsFieldError("startTime"),
                  helperText: getErrorFieldMessage("startTime"),
                },
              }}
              value={startTimeVar}
            />
          </Grid>
          <Grid className={classes.formTwinInputContainerText} item xs={2}>
            <Typography align={"left"} variant="body1">
              {t("至")}
            </Typography>
          </Grid>
          <Grid className={classes.formAutoCompleteContainer} item xs={5}>
            <TimePicker
              disabled={isClosed}
              label={t("營業時間")}
              onChange={(value) => onInputFieldChange("endTime", value)}
              slotProps={{
                textField: {
                  error: checkIsFieldError("endTime"),
                  helperText: getErrorFieldMessage("endTime"),
                },
              }}
              value={endTimeVar}
            />
          </Grid>
          <Grid className={classes.formSwitchContainer} item xs={12}>
            <Typography align={"left"} variant="body1">
              {t("包括公眾假期")}
            </Typography>
            <Switch
              checked={includePublicHolidays}
              onChange={() =>
                onInputFieldChange(
                  "includePublicHolidays",
                  !includePublicHolidays
                )
              }
            />
          </Grid>
          <Grid className={classes.formSwitchContainer} item xs={12}>
            <Typography align={"left"} variant="body1">
              {t("休息")}
            </Typography>
            <Switch
              checked={isClosed}
              onChange={() => onInputFieldChange("isClosed", !isClosed)}
            />
          </Grid>
          <Grid className={classes.formSubmitBtnContainer} item xs={12}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              {t("確定")}
            </Button>
          </Grid>
          {/* Alert */}
          {shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default ShopBusinessHourActionModal;
