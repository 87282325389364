// Components
import StyledMenu from "../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

function WorkingPlaceWiFiActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // States
    showWorkingPlaceWiFiActionMenu,
    // Set States
    setShowWorkingPlaceWiFiActionMenu,
    // Functions
    displayWorkingPlaceWiFiActionDialog,
    displayWorkingPlaceWiFiActionModal,
  } = props;

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowWorkingPlaceWiFiActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "Delete":
        displayWorkingPlaceWiFiActionDialog("DeleteWorkingPlaceWiFi");
        break;
      case "Edit":
        displayWorkingPlaceWiFiActionModal();
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showWorkingPlaceWiFiActionMenu}
      open={Boolean(showWorkingPlaceWiFiActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Edit")}>
        <EditRoundedIcon />
        {t("編輯")}
      </MenuItem>
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Delete")}>
        <DeleteRoundedIcon />
        {t("刪除")}
      </MenuItem>
    </StyledMenu>
  );
}

export default WorkingPlaceWiFiActionMenu;
