// Components
import StyledMenu from "../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";

// React-Redux
import { useSelector } from "react-redux";

function ApplicationActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // States
    showApplicationActionMenu,
    // Set States
    setShowApplicationActionMenu,
    // Functions
    displayApplicationActionDialog,
  } = props;

  // Redux Store
  const applicationOrApprovalTypeValue = useSelector(
    (state) => state.applicationsPage.conditionApplicationOrApprovalTypeValue
  );

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowApplicationActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "Approve":
        displayApplicationActionDialog("ApproveApplication");
        break;
      case "Cancel":
        displayApplicationActionDialog("CancelApplication");
        break;
      case "Reject":
        displayApplicationActionDialog("RejectApplication");
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showApplicationActionMenu}
      open={Boolean(showApplicationActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      {applicationOrApprovalTypeValue === "Applications" && (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Cancel")}>
          <DeleteRoundedIcon />
          {t("取消")}
        </MenuItem>
      )}
      {applicationOrApprovalTypeValue === "Approvals" && (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Approve")}>
          <DoneRoundedIcon />
          {t("批准")}
        </MenuItem>
      )}
      {applicationOrApprovalTypeValue === "Approvals" && (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Reject")}>
          <ClearRoundedIcon />
          {t("駁回")}
        </MenuItem>
      )}
    </StyledMenu>
  );
}

export default ApplicationActionMenu;
