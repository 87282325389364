// Actions
import {
  onDepartmentChange,
  onFormSubmitCountIncrease,
} from "../../../redux/pages/deptsAndShopsPage/deptsAndShopsPageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  createDepartmentFetch,
  editDepartmentFetch,
  getDepartmentDetailsByIdFetch,
} from "../../../fetches/departmentFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function DeptDetailForm() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const departmentId = useSelector(
    (state) => state.deptsAndShopsPage.departmentId
  );
  const divisionId = useSelector((state) => state.deptsAndShopsPage.divisionId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [deptDetailActionDialogText, setDeptDetailActionDialogText] =
    useState("");
  const [deptDetailActionDialogType, setDeptDetailActionDialogType] =
    useState(null);
  const [showDeptDetailAction, setShowDeptDetailAction] = useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [headStaffField, setHeadStaffField] = useState([]);
  // Options
  const [headStaffOptions, setHeadStaffOptions] = useState([]);
  // Switches
  const [isItemActive, setIsItemActive] = useState(true);
  // Text Fields
  const [nameCh, setNameCh] = useState("");
  const [nameEnShort, setNameEnShort] = useState("");
  const [nameEnFull, setNameEnFull] = useState("");

  // Events
  // Events - Dialogs
  const onDeptDetailActionCanceled = () => {
    // Set States
    setShowDeptDetailAction(false);
  };

  const onDeptDetailActionConfirmed = () => {
    switch (deptDetailActionDialogType) {
      case "CreateDepartment":
        createDepartment();
        break;
      case "EditDepartment":
        editDepartment();
        break;
      default:
        break;
    }

    // Set States
    setShowDeptDetailAction(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "headStaffField":
        setHeadStaffField(value);
        break;
      case "isItemActive":
        setIsItemActive(value);
        break;
      case "nameCh":
        setNameCh(value);
        break;
      case "nameEnShort":
        setNameEnShort(value);
        break;
      case "nameEnFull":
        setNameEnFull(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!nameEnFull) {
      addToErrorFields("nameEnFull", t("請先填寫 英文全名"));
      isError = true;
    }

    if (!nameEnShort) {
      addToErrorFields("nameEnShort", t("請先填寫 英文簡稱"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayDeptDetailActionDialog(
      departmentId ? "EditDepartment" : "CreateDepartment"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const clearForm = () => {
    // Set States
    setNameCh("");
    setNameEnFull("");
    setNameEnShort("");
    setIsItemActive(true);
    setHeadStaffOptions([]);
    setHeadStaffField([]);

    clearErrorFields();
  };

  const displayDeptDetailActionDialog = (deptDetailActionType) => {
    // Set States
    setDeptDetailActionDialogType(deptDetailActionType);
    switch (deptDetailActionType) {
      case "CreateDepartment":
        setDeptDetailActionDialogText(t("確認要新增 部門 嗎？"));
        break;
      case "EditDepartment":
        setDeptDetailActionDialogText(t("確認要編輯 部門資料 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowDeptDetailAction(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createDepartment = async () => {
    const results = await createDepartmentFetch(
      token,
      nameCh,
      nameEnFull,
      nameEnShort,
      isItemActive,
      divisionId
    );

    if (results.success) {
      showFormAlert("success", t("成功提交"));

      // Update Redux Store
      dispatch(onDepartmentChange(results.departmentId));
      dispatch(onFormSubmitCountIncrease());
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 部門 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editDepartment = async () => {
    if (departmentId) {
      const results = await editDepartmentFetch(
        token,
        departmentId,
        nameCh,
        nameEnFull,
        nameEnShort,
        headStaffField[0] ? headStaffField.map((item) => item.id) : null,
        isItemActive
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else if (results.isDuplicate) {
        showFormAlert("warning", t("此 部門 已存在"));
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getDepartmentDetailsById = async () => {
    const results = await getDepartmentDetailsByIdFetch(token, departmentId);

    if (results.departmentDetails) {
      const {
        department_name_ch,
        department_name_en_full,
        department_name_en_short,
        is_active,
      } = results.departmentDetails;

      // Set States
      setNameCh(department_name_ch ? department_name_ch : "");
      setNameEnFull(department_name_en_full ? department_name_en_full : null);
      setNameEnShort(
        department_name_en_short ? department_name_en_short : null
      );
      setIsItemActive(is_active);
    } else {
      // Set States
      setNameCh("");
      setNameEnFull("");
      setNameEnShort("");
      setIsItemActive(true);
    }

    if (results.departmentDetails && results.staffs) {
      // Set States
      setHeadStaffOptions(results.staffs);

      const headStaffsArr = results.staffs.filter(
        (item) => item.isTeamHead === true
      );

      setHeadStaffField(headStaffsArr);
    } else {
      // Set States
      setHeadStaffOptions([]);
      setHeadStaffField([]);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    if (departmentId) {
      getDepartmentDetailsById();
    } else {
      clearForm();
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [departmentId]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onDeptDetailActionCanceled}
        onDialogConfirmed={onDeptDetailActionConfirmed}
        // States
        dialogText={deptDetailActionDialogText}
        showDialog={showDeptDetailAction}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("部門資料")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("nameCh")}
            fullWidth
            helperText={getErrorFieldMessage("nameCh")}
            label={t("中文名稱")}
            margin="dense"
            name="nameCh"
            onChange={(event) =>
              onInputFieldChange("nameCh", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={nameCh}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("nameEnShort")}
            fullWidth
            helperText={getErrorFieldMessage("nameEnShort")}
            label={t("英文簡稱")}
            margin="dense"
            name="nameEnShort"
            onChange={(event) =>
              onInputFieldChange("nameEnShort", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            required
            value={nameEnShort}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={checkIsFieldError("nameEnFull")}
            fullWidth
            helperText={getErrorFieldMessage("nameEnFull")}
            label={t("英文全名")}
            margin="dense"
            name="nameEnFull"
            onChange={(event) =>
              onInputFieldChange("nameEnFull", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={nameEnFull}
            variant="standard"
          />
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {headStaffField && headStaffOptions && (
            <Autocomplete
              disabled={!departmentId}
              disablePortal
              getOptionLabel={(option) =>
                `${option.staff_code} - ${option.full_name_en}`
              }
              multiple
              noOptionsText={t("未有相關員工")}
              onChange={(event, value) =>
                onInputFieldChange("headStaffField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={headStaffOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("headStaffField")}
                  helperText={getErrorFieldMessage("headStaffField")}
                  label={t("部門主管")}
                  variant="standard"
                />
              )}
              value={headStaffField}
            />
          )}
        </Grid>
        <Grid item xs={6} className={classes.formSwitchContainer}>
          <Typography align={"left"} variant="body1">
            {t("有效")}
          </Typography>
          <Switch
            checked={isItemActive}
            onChange={() => onInputFieldChange("isItemActive", !isItemActive)}
          />
        </Grid>
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <Button
            onClick={onSubmitBtnClicked}
            variant="contained"
            sx={formSubmitBtnStyles}
          >
            {departmentId ? t("修改") : t("新增")}
          </Button>
        </Grid>
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default DeptDetailForm;
