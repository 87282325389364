// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/rostersPage/rostersPageActions";

// Components
// Boxes
import WorkingPeriodAndShiftAndDayOffTypeBoxesContainer from "./workingPeriodAndShiftAndDayOffTypeBoxesContainer";
// Spacing Boxes
import SpacingBox from "../spacing/spacingBox";
// Tables
import RosterTableContainer from "../../tables/rostersPage/rosterTableContainer";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Fetches
import { createOrEditRosterFetch } from "../../../fetches/rosterFetches";

// React
import { useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function ContentContainer() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const token = useSelector((state) => state.staff.token);

  // States
  // Hover
  const [hoveredDayOffTypeId, setHoveredDayOffTypeId] = useState(null);
  const [hoveredShiftId, setHoveredShiftId] = useState(null);
  const [hoveredWorkingPeriodId, setHoveredWorkingPeriodId] = useState(null);
  // Select Mode
  const [editedStaffToDays, setEditedStaffToDays] = useState([]);
  const [selectedStaffToDays, setSelectedStaffToDays] = useState([]);

  // Functions
  // Functions - Mutations
  const createOrEditRoster = async (typeOfOption, optionId) => {
    const results = await createOrEditRosterFetch(
      token,
      selectedStaffToDays,
      typeOfOption,
      optionId
    );

    if (results.success) {
      // Set States
      setSelectedStaffToDays([]);
      setEditedStaffToDays((currentState) => [
        ...currentState,
        ...selectedStaffToDays,
      ]);

      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());
    }
  };

  return (
    <>
      <WorkingPeriodAndShiftAndDayOffTypeBoxesContainer
        // States
        hoveredDayOffTypeId={hoveredDayOffTypeId}
        hoveredShiftId={hoveredShiftId}
        hoveredWorkingPeriodId={hoveredWorkingPeriodId}
        selectedStaffToDays={selectedStaffToDays}
        // Functions
        createOrEditRoster={createOrEditRoster}
      />
      <SpacingBox
        // Render
        height={stylesConfig.spacingBoxMarginBottom}
      />
      <RosterTableContainer
        // States
        editedStaffToDays={editedStaffToDays}
        selectedStaffToDays={selectedStaffToDays}
        // Set States
        setEditedStaffToDays={setEditedStaffToDays}
        setHoveredDayOffTypeId={setHoveredDayOffTypeId}
        setHoveredShiftId={setHoveredShiftId}
        setHoveredWorkingPeriodId={setHoveredWorkingPeriodId}
        setSelectedStaffToDays={setSelectedStaffToDays}
        // Functions
        createOrEditRoster={createOrEditRoster}
      />
      <SpacingBox
        // Render
        height={stylesConfig.spacingBoxMarginBottom}
      />
    </>
  );
}

export default ContentContainer;
