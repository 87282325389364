export const dayOptions = [
  { value: 1, textCh: "1日", textEn: "1 Day" },
  { value: 2, textCh: "2日", textEn: "2 Days" },
  { value: 3, textCh: "3日", textEn: "3 Days" },
  { value: 4, textCh: "4日", textEn: "4 Days" },
  { value: 5, textCh: "5日", textEn: "5 Days" },
  { value: 6, textCh: "6日", textEn: "6 Days" },
  { value: 7, textCh: "7日", textEn: "7 Days" },
  { value: 8, textCh: "8日", textEn: "8 Days" },
  { value: 9, textCh: "9日", textEn: "9 Days" },
  { value: 10, textCh: "10日", textEn: "10 Days" },
  { value: 11, textCh: "11日", textEn: "11 Days" },
  { value: 12, textCh: "12日", textEn: "12 Days" },
  { value: 13, textCh: "13日", textEn: "13 Days" },
  { value: 14, textCh: "14日", textEn: "14 Days" },
  { value: 15, textCh: "15日", textEn: "15 Days" },
  { value: 16, textCh: "16日", textEn: "16 Days" },
  { value: 17, textCh: "17日", textEn: "17 Days" },
  { value: 18, textCh: "18日", textEn: "18 Days" },
  { value: 19, textCh: "19日", textEn: "19 Days" },
  { value: 20, textCh: "20日", textEn: "20 Days" },
  { value: 21, textCh: "21日", textEn: "21 Days" },
  { value: 22, textCh: "22日", textEn: "22 Days" },
  { value: 23, textCh: "23日", textEn: "23 Days" },
  { value: 24, textCh: "24日", textEn: "24 Days" },
  { value: 25, textCh: "25日", textEn: "25 Days" },
  { value: 26, textCh: "26日", textEn: "26 Days" },
  { value: 27, textCh: "27日", textEn: "27 Days" },
  { value: 28, textCh: "28日", textEn: "28 Days" },
  { value: 29, textCh: "29日", textEn: "29 Days" },
  { value: 30, textCh: "30日", textEn: "30 Days" },
];

export const hourOptions = [
  { value: 1, textCh: "1小時", textEn: "1 Hour" },
  { value: 2, textCh: "2小時", textEn: "2 Hours" },
  { value: 3, textCh: "3小時", textEn: "3 Hours" },
  { value: 4, textCh: "4小時", textEn: "4 Hours" },
  { value: 5, textCh: "5小時", textEn: "5 Hours" },
  { value: 6, textCh: "6小時", textEn: "6 Hours" },
  { value: 7, textCh: "7小時", textEn: "7 Hours" },
  { value: 8, textCh: "8小時", textEn: "8 Hours" },
  { value: 9, textCh: "9小時", textEn: "9 Hours" },
  { value: 10, textCh: "10小時", textEn: "10 Hours" },
  { value: 11, textCh: "11小時", textEn: "11 Hours" },
  { value: 12, textCh: "12小時", textEn: "12 Hours" },
];

export const seniorityOptions = [
  { value: 0, textCh: "未滿1年", textEn: "Under 1 Year" },
  { value: 1, textCh: "已滿1年", textEn: "Over 1 Year" },
  { value: 2, textCh: "已滿2年", textEn: "Over 2 Years" },
  { value: 3, textCh: "已滿3年", textEn: "Over 3 Years" },
  { value: 4, textCh: "已滿4年", textEn: "Over 4 Years" },
  { value: 5, textCh: "已滿5年", textEn: "Over 5 Years" },
  { value: 6, textCh: "已滿6年", textEn: "Over 6 Years" },
  { value: 7, textCh: "已滿7年", textEn: "Over 7 Years" },
  { value: 8, textCh: "已滿8年", textEn: "Over 8 Years" },
  { value: 9, textCh: "已滿9年", textEn: "Over 9 Years" },
  { value: 10, textCh: "已滿10年", textEn: "Over 10 Years" },
  { value: 11, textCh: "已滿11年", textEn: "Over 11 Years" },
  { value: 12, textCh: "已滿12年", textEn: "Over 12 Years" },
  { value: 13, textCh: "已滿13年", textEn: "Over 13 Years" },
  { value: 14, textCh: "已滿14年", textEn: "Over 14 Years" },
  { value: 15, textCh: "已滿15年", textEn: "Over 15 Years" },
  { value: 16, textCh: "已滿16年", textEn: "Over 16 Years" },
  { value: 17, textCh: "已滿17年", textEn: "Over 17 Years" },
  { value: 18, textCh: "已滿18年", textEn: "Over 18 Years" },
  { value: 19, textCh: "已滿19年", textEn: "Over 19 Years" },
  { value: 20, textCh: "已滿20年", textEn: "Over 20 Years" },
];
