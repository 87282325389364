// Configs
import apisConfig from "../configs/apisConfig";

export const getSearchSuggestionByKeywordFetch = async (
  token,
  paramsArr,
  keyword
) => {
  try {
    const queryRoute = "/search/getSearchSuggestionByKeyword";

    const reqBody = {
      paramsArr,
      keyword,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getStaffSuggestionForApplicationProcedureByKeywordFetch = async (
  token,
  applicationProcedureId,
  keyword
) => {
  try {
    const queryRoute = `/search/getStaffSuggestionForApplicationProcedureByKeyword/${applicationProcedureId}`;

    const reqBody = {
      keyword,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getStaffSuggestionForAttendanceRuleByKeywordFetch = async (
  token,
  keyword
) => {
  try {
    const queryRoute = "/search/getStaffSuggestionForAttendanceRuleByKeyword";

    const reqBody = {
      keyword,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getStaffSuggestionForPermissionGroupByKeywordFetch = async (
  token,
  permissionGroupId,
  keyword
) => {
  try {
    const queryRoute = `/search/getStaffSuggestionForPermissionGroupByKeyword/${permissionGroupId}`;

    const reqBody = {
      keyword,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getStaffSuggestionForRosterByKeywordFetch = async (
  token,
  keyword,
  exceptionStaffIdsArr
) => {
  try {
    const queryRoute = "/search/getStaffSuggestionForRosterByKeyword";

    const reqBody = {
      keyword,
      exceptionStaffIdsArr,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getStaffSuggestionForWorkingPeriodByKeywordFetch = async (
  token,
  workingPeriodId,
  keyword
) => {
  try {
    const queryRoute = `/search/getStaffSuggestionForWorkingPeriodByKeyword/${workingPeriodId}`;

    const reqBody = {
      keyword,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getStaffSuggestionForWorkingPlaceByKeywordFetch = async (
  token,
  workingPlaceId,
  keyword
) => {
  try {
    const queryRoute = `/search/getStaffSuggestionForWorkingPlaceByKeyword/${workingPlaceId}`;

    const reqBody = {
      keyword,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
