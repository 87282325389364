// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

// Styles
import { useStandardTableStyles } from "../../../styles/componentStyles/tableStyles/standardTableStyles";

function AttendanceRecordBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardTableStyles();

  // Props
  const {
    // States
    item,
    staffId,
    // Events
    onItemClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  return (
    <Tooltip
      title={
        item.is_before_date_join
          ? t("未入職")
          : item.is_after_date_resign
          ? t("已離職")
          : item.roster_id
          ? item.is_day_off
            ? item[t("day_off_type_name_ch")]
            : !item.is_attendance_required
            ? t("無需簽到")
            : item.is_exception
            ? t("已豁免")
            : item.is_on_time === false && item.is_location_correct === false
            ? `${item.time_difference > 0 ? t("遲到") : t("早退")} ${Math.abs(
                item.time_difference
              )} ${t("分鐘s / 地點錯誤")}`
            : item.is_on_time === false
            ? `${item.time_difference > 0 ? t("遲到") : t("早退")} ${Math.abs(
                item.time_difference
              )} ${t("分鐘s")}`
            : item.is_location_correct === false
            ? t("地點錯誤")
            : item.is_on_time
            ? ""
            : t("欠簽到紀錄")
          : ""
      }
    >
      <div
        className={classes.cellBoxSmall}
        onClick={() => onItemClicked(staffId, item.day_id)}
        onMouseEnter={() => {
          onMouseEnterItem(staffId, item.day_id);
        }}
        onMouseLeave={() => {
          onMouseLeaveItem();
        }}
      >
        <Typography
          align={"center"}
          color={
            item.roster_id
              ? item.is_day_off
                ? stylesConfig.greyTextColor
                : item.is_attendance_required &&
                  !item.is_exception &&
                  (item.is_on_time === false ||
                    item.is_location_correct === false)
                ? stylesConfig.redTextColor
                : item.time_attend || !item.is_attendance_required
                ? null
                : stylesConfig.redTextColor
              : null
          }
          variant="body1"
        >
          {item.is_before_date_join
            ? "-/-"
            : item.is_after_date_resign
            ? "-/-"
            : item.day_off_type_name_en_short
            ? item.day_off_type_name_en_short
            : item.time_attend
            ? item.time_attend
            : "X"}
        </Typography>
      </div>
    </Tooltip>
  );
}

export default AttendanceRecordBox;
