// Actions
import {
  onFormSubmitCountIncrease,
  onProcedureApplicationProcedureChange,
} from "../../../redux/pages/applicationsPage/applicationsPageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Forms
import ApplicationProcedureDetailFormContent from "./applicationProcedureDetailFormContent";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  createApplicationProcedureFetch,
  editApplicationProcedureFetch,
  getApplicationProcedureDetailsByIdFetch,
} from "../../../fetches/applicationProcedureFetches";
import { getApplicationProcedureDetailFormOptionsFetch } from "../../../fetches/formOptionFetches";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function ApplicationProcedureDetailFormContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const applicationProcedureId = useSelector(
    (state) => state.applicationsPage.procedureApplicationProcedureId
  );
  const applicationTypeId = useSelector(
    (state) => state.applicationsPage.procedureApplicationTypeId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [
    applicationProcedureDetailActionDialogText,
    setApplicationProcedureDetailActionDialogText,
  ] = useState("");
  const [
    applicationProcedureDetailActionDialogType,
    setApplicationProcedureDetailActionDialogType,
  ] = useState(null);
  const [
    showApplicationProcedureDetailAction,
    setShowApplicationProcedureDetailAction,
  ] = useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [applicationDeptTypeField, setApplicationDeptTypeField] =
    useState(null);
  const [applicationStaffTypeField, setApplicationStaffTypeField] =
    useState(null);
  const [departmentField, setDepartmentField] = useState([]);
  const [divisionField, setDivisionField] = useState([]);
  const [shopField, setShopField] = useState([]);
  const [titleField, setTitleField] = useState([]);
  // Options
  const [applicationDeptTypeOptions, setApplicationDeptTypeOptions] = useState(
    []
  );
  const [applicationStaffTypeOptions, setApplicationStaffTypeOptions] =
    useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [shopOptions, setShopOptions] = useState([]);
  const [titleOptions, setTitleOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Text Fields
  const [nameCh, setNameCh] = useState("");
  const [nameEn, setNameEn] = useState("");

  // Events
  // Events - Dialogs
  const onApplicationProcedureDetailActionCanceled = () => {
    // Set States
    setShowApplicationProcedureDetailAction(false);
  };

  const onApplicationProcedureDetailActionConfirmed = () => {
    switch (applicationProcedureDetailActionDialogType) {
      case "CreateApplicationProcedure":
        createApplicationProcedure();
        break;
      case "EditApplicationProcedure":
        editApplicationProcedure();
        break;
      default:
        break;
    }

    // Set States
    setShowApplicationProcedureDetailAction(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "applicationDeptTypeField":
        setApplicationDeptTypeField(value);

        // !所有部門 / 店舖 || 所屬部門 / 店舖 || 指定部門 / 店舖
        if (
          !["All", "Affiliated", "Specific Dept"].includes(
            value.application_dept_type_name_en
          )
        ) {
          setApplicationStaffTypeField(null);

          removeErrorField("applicationStaffTypeField");
        }

        // !指定部門 / 店舖
        if (value.application_dept_type_name_en !== "Specific Dept") {
          setDepartmentField([]);
          setShopField([]);

          removeErrorField("departmentField");
          removeErrorField("shopField");
        }

        // !指定職位
        if (value.application_dept_type_name_en !== "Specific Title") {
          setTitleField([]);

          removeErrorField("titleField");
        }

        break;
      case "applicationStaffTypeField":
        setApplicationStaffTypeField(value);

        // !指定職位
        if (value.application_staff_type_name_en !== "Specific Title") {
          setTitleField([]);

          removeErrorField("titleField");
        }

        break;
      case "departmentField":
        setDepartmentField(value);
        break;
      case "divisionField":
        setDivisionField(value);
        break;
      case "nameCh":
        setNameCh(value);
        break;
      case "nameEn":
        setNameEn(value);
        break;
      case "shopField":
        setShopField(value);
        break;
      case "titleField":
        setTitleField(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!applicationDeptTypeField) {
      addToErrorFields(
        "applicationDeptTypeField",
        t("請先填寫 部門 / 店鋪類型")
      );
      isError = true;
    }

    // 所有部門 / 店舖 || 所屬部門 / 店舖 || 指定部門 / 店舖
    if (
      ["All", "Affiliated", "Specific Dept"].includes(
        applicationDeptTypeField.application_dept_type_name_en
      )
    ) {
      if (!applicationStaffTypeField) {
        addToErrorFields("applicationStaffTypeField", t("請先填寫 員工類型"));
        isError = true;
      }
    }

    if (!divisionField[0]) {
      addToErrorFields("divisionField", t("請先填寫 套用到"));
      isError = true;
    }

    // 指定部門 / 店舖
    if (
      applicationDeptTypeField.application_dept_type_name_en === "Specific Dept"
    ) {
      if (!departmentField[0] && !shopField[0]) {
        addToErrorFields("departmentField", t("請先填寫 部門 / 店鋪"));
        addToErrorFields("shopField", t("請先填寫 部門 / 店鋪"));
        isError = true;
      }
    }

    // 指定職位
    if (
      applicationDeptTypeField.application_dept_type_name_en ===
        "Specific Title" ||
      (applicationStaffTypeField &&
        applicationStaffTypeField.application_staff_type_name_en ===
          "Specific Title")
    ) {
      if (!titleField[0]) {
        addToErrorFields("titleFiel", t("請先填寫 職位"));
        isError = true;
      }
    }

    if (!nameCh) {
      addToErrorFields("nameCh", t("請先填寫 中文名稱"));
      isError = true;
    }

    if (!nameEn) {
      addToErrorFields("nameEN", t("請先填寫 英文名稱"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayApplicationProcedureDetailActionDialog(
      applicationProcedureId
        ? "EditApplicationProcedure"
        : "CreateApplicationProcedure"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const clearForm = () => {
    // Set States
    setNameCh("");
    setNameEn("");
    setApplicationDeptTypeField(null);
    setApplicationStaffTypeField(null);
    setDepartmentField([]);
    setDivisionField([]);
    setShopField([]);
    setTitleField([]);

    clearErrorFields();
  };

  const displayApplicationProcedureDetailActionDialog = (
    applicationProcedureDetailActionType
  ) => {
    // Set States
    setApplicationProcedureDetailActionDialogType(
      applicationProcedureDetailActionType
    );
    switch (applicationProcedureDetailActionType) {
      case "CreateApplicationProcedure":
        setApplicationProcedureDetailActionDialogText(
          t("確認要新增 程序 嗎？")
        );
        break;
      case "EditApplicationProcedure":
        setApplicationProcedureDetailActionDialogText(
          t("確認要編輯 程序資料 嗎？")
        );
        break;
      default:
        break;
    }

    // Set States
    setShowApplicationProcedureDetailAction(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createApplicationProcedure = async () => {
    const results = await createApplicationProcedureFetch(
      token,
      applicationTypeId,
      nameCh,
      nameEn,
      applicationDeptTypeField ? applicationDeptTypeField.id : null,
      applicationStaffTypeField ? applicationStaffTypeField.id : null,
      departmentField[0] ? departmentField.map((item) => item.id) : null,
      divisionField[0] ? divisionField.map((item) => item.id) : null,
      shopField[0] ? shopField.map((item) => item.id) : null,
      titleField[0] ? titleField.map((item) => item.id) : null
    );

    if (results.success) {
      showFormAlert("success", t("成功提交"));

      // Update Redux Store
      dispatch(
        onProcedureApplicationProcedureChange(results.applicationProcedureId)
      );
      dispatch(onFormSubmitCountIncrease());
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 程序 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editApplicationProcedure = async () => {
    if (applicationProcedureId) {
      const results = await editApplicationProcedureFetch(
        token,
        applicationProcedureId,
        applicationTypeId,
        nameCh,
        nameEn,
        applicationDeptTypeField ? applicationDeptTypeField.id : null,
        applicationStaffTypeField ? applicationStaffTypeField.id : null,
        departmentField[0] ? departmentField.map((item) => item.id) : null,
        divisionField[0] ? divisionField.map((item) => item.id) : null,
        shopField[0] ? shopField.map((item) => item.id) : null,
        titleField[0] ? titleField.map((item) => item.id) : null
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else if (results.isDuplicate) {
        showFormAlert("warning", t("此 程序 已存在"));
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getApplicationProcedureDetailFormOptions = async () => {
    const results = await getApplicationProcedureDetailFormOptionsFetch(token);

    // Set States
    setApplicationDeptTypeOptions(
      results.applicationDeptTypes ? results.applicationDeptTypes : []
    );
    setApplicationStaffTypeOptions(
      results.applicationStaffTypes ? results.applicationStaffTypes : []
    );
    setDepartmentOptions(results.departments ? results.departments : []);
    setDivisionOptions(results.divisions ? results.divisions : []);
    setShopOptions(results.shops ? results.shops : []);
    setTitleOptions(results.titles ? results.titles : []);
  };

  const getApplicationProcedureDetailsById = async () => {
    const results = await getApplicationProcedureDetailsByIdFetch(
      token,
      applicationProcedureId
    );

    if (results.applicationProcedureDetails) {
      const {
        application_procedure_name_ch,
        application_procedure_name_en,
        application_dept_type_id,
        application_staff_type_id,
        dependencies,
      } = results.applicationProcedureDetails;

      const { departments, divisions, shops, titles } = dependencies;

      // Set States
      setNameCh(
        application_procedure_name_ch ? application_procedure_name_ch : ""
      );
      setNameEn(
        application_procedure_name_en ? application_procedure_name_en : ""
      );

      setApplicationDeptTypeField(
        application_dept_type_id
          ? applicationDeptTypeOptions.find(
              (item) => item.id === application_dept_type_id
            )
          : null
      );

      setApplicationStaffTypeField(
        application_staff_type_id
          ? applicationStaffTypeOptions.find(
              (item) => item.id === application_staff_type_id
            )
          : null
      );

      if (departments) {
        let departmentsArr = [];

        for (let departmentItem of departments) {
          if (departmentOptions.find((item) => item.id === departmentItem.id)) {
            departmentsArr.push(
              departmentOptions.find((item) => item.id === departmentItem.id)
            );
          }
        }

        setDepartmentField(departmentsArr);
      } else {
        setDepartmentField([]);
      }

      if (divisions) {
        let divisionsArr = [];

        for (let divisionItem of divisions) {
          if (divisionOptions.find((item) => item.id === divisionItem.id)) {
            divisionsArr.push(
              divisionOptions.find((item) => item.id === divisionItem.id)
            );
          }
        }

        setDivisionField(divisionsArr);
      } else {
        setDivisionField([]);
      }

      if (shops) {
        let shopsArr = [];

        for (let shopItem of shops) {
          if (shopOptions.find((item) => item.id === shopItem.id)) {
            shopsArr.push(shopOptions.find((item) => item.id === shopItem.id));
          }
        }

        setShopField(shopsArr);
      } else {
        setShopField([]);
      }

      if (titles) {
        let titlesArr = [];

        for (let titleItem of titles) {
          if (titleOptions.find((item) => item.id === titleItem.id)) {
            titlesArr.push(
              titleOptions.find((item) => item.id === titleItem.id)
            );
          }
        }

        setTitleField(titlesArr);
      } else {
        setTitleField([]);
      }
    } else {
      // Set States
      setNameCh("");
      setNameEn("");
      setApplicationDeptTypeField(null);
      setApplicationStaffTypeField(null);
      setDepartmentField([]);
      setDivisionField([]);
      setShopField([]);
      setTitleField([]);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    getApplicationProcedureDetailFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && applicationDeptTypeOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [applicationDeptTypeOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (applicationProcedureId) {
        getApplicationProcedureDetailsById();
      } else {
        clearForm();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, applicationProcedureId]);

  return (
    <>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onApplicationProcedureDetailActionCanceled}
        onDialogConfirmed={onApplicationProcedureDetailActionConfirmed}
        // States
        dialogText={applicationProcedureDetailActionDialogText}
        showDialog={showApplicationProcedureDetailAction}
      />
      <ApplicationProcedureDetailFormContent
        // States
        applicationDeptTypeField={applicationDeptTypeField}
        applicationDeptTypeOptions={applicationDeptTypeOptions}
        applicationProcedureId={applicationProcedureId}
        applicationStaffTypeField={applicationStaffTypeField}
        applicationStaffTypeOptions={applicationStaffTypeOptions}
        departmentField={departmentField}
        departmentOptions={departmentOptions}
        divisionField={divisionField}
        divisionOptions={divisionOptions}
        formAlertText={formAlertText}
        formAlertType={formAlertType}
        nameCh={nameCh}
        nameEn={nameEn}
        shopField={shopField}
        shopOptions={shopOptions}
        shouldShowFormAlert={shouldShowFormAlert}
        titleField={titleField}
        titleOptions={titleOptions}
        // Events
        onInputFieldChange={onInputFieldChange}
        onInputFieldKeyPressed={onInputFieldKeyPressed}
        onSubmitBtnClicked={onSubmitBtnClicked}
        // Functions
        checkIsFieldError={checkIsFieldError}
        getErrorFieldMessage={getErrorFieldMessage}
      />
    </>
  );
}

export default ApplicationProcedureDetailFormContainer;
