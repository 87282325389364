// Actions
import {
  onActiveStepDecrease,
  onActiveStepIncrease,
  onActiveStepReset,
} from "../../../redux/pages/createStaffPage/createStaffPageActions";
import {
  onStaffChange,
  onTabItemChange,
} from "../../../redux/pages/staffProfilePage/staffProfilePageActions";

// Components
// Forms
import CreateStaffFormBasicInfoContent from "./createStaffFormBasicInfoContent";
import CreateStaffFormContactAndAddressContent from "./createStaffFormContactAndAddressContent";
import CreateStaffFormContractContent from "./createStaffFormContractContent";
import CreateStaffFormRelocationContent from "./createStaffFormRelocationContent";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getCandidateInfoByIdForCreateStaffFormFetch } from "../../../fetches/candidateFetches";
import { getCreateStaffFormOptionsFetch } from "../../../fetches/formOptionFetches";
import { createRelocationFetch } from "../../../fetches/relocationFetches";
import {
  createStaffFetch,
  deleteStaffFetch,
} from "../../../fetches/staffFetches";
import { createStaffContractFetch } from "../../../fetches/staffContractFetches";

// Helper Functions
import { getMonthStartDate } from "../../../helperFunctions/getDate";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

function CreateStaffFormContainer(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();

  // Props
  const {
    // States
    candidateIdParam,
    departmentIdParam,
    divisionIdParam,
    divisionTypeParam,
    shopIdParam,
  } = props;

  // Redux Store
  const activeStep = useSelector((state) => state.createStaffPage.activeStep);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Date Fields
  // Date Fields - Staff Info
  const [dateBirth, setDateBirth] = useState(null);
  // Date Fields - Relocation Detail
  const [relocationDate, setRelocationDate] = useState(null);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Options Fields
  // Options Fields - Contract Detail
  const [salaryCountTypeField, setSalaryCountTypeField] = useState(null);
  const [staffContractTypeField, setStaffContractTypeField] = useState(null);
  // Options Fields - Staff Info
  const [areaField, setAreaField] = useState(null);
  const [districtField, setDistrictField] = useState(null);
  const [genderTypeField, setGenderTypeField] = useState(null);
  // Options Fields - Relocation Detail
  const [companyField, setCompanyField] = useState(null);
  const [departmentField, setDepartmentField] = useState(null);
  const [divisionField, setDivisionField] = useState(null);
  const [probationLengthField, setProbationLengthField] = useState(null);
  const [relocationTypeField, setRelocationTypeField] = useState(null);
  const [shopField, setShopField] = useState([]);
  const [titleField, setTitleField] = useState(null);
  // Options
  // Options - Contract Detail
  const [salaryCountTypeOptions, setSalaryCountTypeOptions] = useState([]);
  const [staffContractTypeOptions, setStaffContractTypeOptions] = useState([]);
  // Options - Staff Info
  const [areaOptions, setAreaOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [genderTypeOptions, setGenderTypeOptions] = useState([]);
  // Options - Relocation Detail
  const [companyOptions, setCompanyOptions] = useState([]);
  const [contractTypeOptions, setContractTypeOptions] = useState(null);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [relocationTypeOptions, setRelocationTypeOptions] = useState([]);
  const [shopOptions, setShopOptions] = useState([]);
  const [titleOptions, setTitleOptions] = useState([]);
  // Render
  const [availableRelocationTypeIdsArr, setAvailableRelocationTypeIdsArr] =
    useState([]);
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Switches
  // Switches - Contract Detail
  const [isCommission, setIsCommission] = useState(true);
  const [isLunchIncluded, setIsLunchIncluded] = useState(true);
  // Text Fields
  // Text Fields - Contract Detail
  const [fixedSalary, setFixedSalary] = useState("");
  // Text Fields - Staff Info
  const [addressCh, setAddressCh] = useState("");
  const [addressEn, setAddressEn] = useState("");
  const [alias, setAlias] = useState("");
  const [email, setEmail] = useState("");
  const [hkid, setHkid] = useState("");
  const [nameCh, setNameCh] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [staffCode, setStaffCode] = useState("");

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "addressCh":
        setAddressCh(value);
        break;
      case "addressEn":
        setAddressEn(value);
        break;
      case "alias":
        setAlias(value);
        break;
      case "areaField":
        setAreaField(value);
        setDistrictField(
          districtOptions.find((item) => item.area_id === value.id)
        );

        removeErrorField("districtField");

        break;
      case "companyField":
        setCompanyField(value);
        break;
      case "dateBirth":
        setDateBirth(value);
        break;
      case "departmentField":
        setDepartmentField(value);
        break;
      case "districtField":
        setDistrictField(value);
        break;
      case "divisionField":
        setDivisionField(value);

        if (value.division_type_name_en !== "Departments") {
          setDepartmentField(null);

          removeErrorField("departmentField");
        } else if (value.division_type_name_en !== "Shops") {
          setShopField([]);

          removeErrorField("shopField");
        }

        setTitleField(
          titleOptions.find((item) => item.division_id === value.id)
        );

        removeErrorField("titleField");

        break;
      case "email":
        setEmail(value);
        break;
      case "fixedSalary":
        setFixedSalary(value);
        break;
      case "genderTypeField":
        setGenderTypeField(value);
        break;
      case "isCommission":
        setIsCommission(value);
        break;
      case "isLunchIncluded":
        setIsLunchIncluded(value);
        break;
      case "hkid":
        setHkid(value);
        break;
      case "nameCh":
        setNameCh(value);
        break;
      case "nameEn":
        setNameEn(value);
        break;
      case "phoneNum":
        setPhoneNum(value);
        break;
      case "probationLengthField":
        setProbationLengthField(value);
        break;
      case "relocationDate":
        setRelocationDate(value);
        break;
      case "relocationTypeField":
        setRelocationTypeField(value);
        break;
      case "salaryCountTypeField":
        setSalaryCountTypeField(value);
        break;
      case "shopField":
        setShopField(value);
        break;
      case "staffCode":
        setStaffCode(value);
        break;
      case "staffContractTypeField":
        setStaffContractTypeField(value);
        break;
      case "titleField":
        setTitleField(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      switch (activeStep) {
        case 0:
          onCreateStaffFormBasicInfoContentSubmitBtnClicked();
          break;
        case 1:
          onCreateStaffFormContactAndAddressContentSubmitBtnClicked();
          break;
        case 2:
          CreateStaffFormRelocationContent();
          break;
        case 3:
          break;
        default:
          break;
      }
    }
  };

  // Events - Forms
  const onCreateStaffFormBasicInfoContentSubmitBtnClicked = () => {
    let isError = false;

    if (!dateBirth) {
      addToErrorFields("dateBirth", t("請先填寫 出生日期"));
      isError = true;
    }

    if (!genderTypeField) {
      addToErrorFields("genderTypeField", t("請先填寫 性別"));
      isError = true;
    }

    if (!hkid) {
      addToErrorFields("hkid", t("請先填寫 身分證編號"));
      isError = true;
    }

    if (!nameEn) {
      addToErrorFields("nameEn", t("請先填寫 英文全名"));
      isError = true;
    }

    if (!staffCode) {
      addToErrorFields("staffCode", t("請先填寫 員工編號"));
      isError = true;
    }

    if (isError) {
      return;
    }

    // Update Redux Store
    dispatch(onActiveStepIncrease());
  };

  const onCreateStaffFormContactAndAddressContentSubmitBtnClicked = () => {
    // Update Redux Store
    dispatch(onActiveStepIncrease());
  };

  const onCreateStaffFormContractContentSubmitBtnClicked = () => {
    let isError = false;

    if (!fixedSalary) {
      addToErrorFields("fixedSalary", t("請先填寫 固定工資"));
      isError = true;
    } else {
      if (isNaN(fixedSalary)) {
        addToErrorFields("fixedSalary", t("固定工資 必須為數字"));
        isError = true;
      }
    }

    if (!salaryCountTypeField) {
      addToErrorFields("salaryCountTypeField", t("請先填寫 工資單位"));
      isError = true;
    }

    if (!staffContractTypeField) {
      addToErrorFields("staffContractTypeField", t("請先填寫 合約類型"));
      isError = true;
    }

    if (isError) {
      return;
    }

    createStaff();
  };

  const onCreateStaffFormRelocationContentSubmitBtnClicked = () => {
    let isError = false;

    if (!companyField) {
      addToErrorFields("companyField", t("請先填寫 公司"));
      isError = true;
    }

    if (!divisionField) {
      addToErrorFields("divisionField", t("請先填寫 類別"));
      isError = true;
    } else {
      if (divisionField.division_type_name_en === "Departments") {
        if (!departmentField) {
          addToErrorFields("departmentField", t("請先填寫 部門"));
          isError = true;
        }
      } else {
        if (!shopField[0]) {
          addToErrorFields("shopField", t("請先填寫 店舖"));
          isError = true;
        }
      }
    }

    if (!probationLengthField) {
      addToErrorFields("probationLengthField", t("請先填寫 試用期"));
      isError = true;
    }

    if (!relocationDate) {
      addToErrorFields("relocationDate", t("請先填寫 生效日期"));
      isError = true;
    }

    if (!relocationTypeField) {
      addToErrorFields("relocationTypeField", t("請先填寫 調動類型"));
      isError = true;
    }

    if (!titleField) {
      addToErrorFields("titleField", t("請先填寫 職位"));
      isError = true;
    }

    if (isError) {
      return;
    }

    // Update Redux Store
    dispatch(onActiveStepIncrease());
  };

  const onStepBackBtnClicked = () => {
    if (activeStep !== 0) {
      // Update Redux Store
      dispatch(onActiveStepDecrease());
    }
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const setDefaultOptions = () => {
    // Set States
    setDepartmentField(
      divisionTypeParam &&
        divisionTypeParam === "Departments" &&
        departmentIdParam
        ? departmentOptions.find((item) => item.id === departmentIdParam)
        : null
    );
    setDivisionField(
      divisionIdParam
        ? divisionOptions.find((item) => item.id === divisionIdParam)
        : null
    );
    setShopField(
      divisionTypeParam && divisionTypeParam === "Shops" && shopIdParam
        ? [shopOptions.find((item) => item.id === shopIdParam)]
        : []
    );
    setRelocationDate(new Date());
    setRelocationTypeField(
      availableRelocationTypeIdsArr
        ? relocationTypeOptions.filter((item) =>
            availableRelocationTypeIdsArr.includes(item.id)
          )[0]
        : relocationTypeOptions[0]
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createRelocation = async (staffId) => {
    const results = await createRelocationFetch(
      token,
      relocationTypeField ? relocationTypeField.id : null,
      staffId,
      relocationDate,
      companyField ? companyField.id : null,
      departmentField ? departmentField.id : null,
      divisionField ? divisionField.id : null,
      shopField[0] ? shopField.map((item) => item.id) : null,
      titleField ? titleField.id : null
    );

    if (results.success) {
      createStaffContract(staffId);
    } else {
      showFormAlert("error", t("未能提交"));

      deleteStaff(staffId);
    }
  };

  const createStaff = async () => {
    const results = await createStaffFetch(
      token,
      hkid,
      staffCode,
      genderTypeField ? genderTypeField.id : null,
      nameCh,
      nameEn,
      alias,
      relocationDate,
      1,
      probationLengthField ? probationLengthField.value : null,
      dateBirth,
      email,
      phoneNum,
      districtField ? districtField.id : null,
      addressCh,
      addressEn,
      candidateIdParam ? candidateIdParam : null
    );

    if (results.success) {
      const staffId = results.staffId;

      createRelocation(staffId);
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 員工編號 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const createStaffContract = async (staffId) => {
    const effectiveMonth = getMonthStartDate(relocationDate);

    const results = await createStaffContractFetch(
      token,
      staffContractTypeField ? staffContractTypeField.id : null,
      salaryCountTypeField ? salaryCountTypeField.id : null,
      staffId,
      fixedSalary,
      effectiveMonth,
      isCommission,
      isLunchIncluded
    );

    if (results.success) {
      // Update Redux Store
      dispatch(onStaffChange(staffId));
      dispatch(onTabItemChange("BasicInfo"));

      // Navigate
      navigate("/staffProfile");
    } else {
      if (results.validatetionFailed) {
        showFormAlert("warning", results.message);
      } else {
        showFormAlert("error", t("未能提交"));
      }

      deleteStaff(staffId);
    }
  };

  const deleteStaff = async (staffId) => {
    await deleteStaffFetch(token, staffId);
  };

  // Functions - Queries
  const getCandidateInfoByIdForCreateStaffForm = async () => {
    const results = await getCandidateInfoByIdForCreateStaffFormFetch(
      token,
      candidateIdParam
    );

    if (results.candidateInfo) {
      const {
        hkid,
        gender_type_id,
        full_name_ch,
        full_name_en,
        alias,
        date_birth,
        email,
        phone_num,
        area_id,
        district_id,
        address_ch,
        address_en,
        relocation_date,
        division_id,
        title_id,
        staff_contract_type_id,
        salary_count_type_id,
        fixed_salary,
        is_commission,
        is_lunch_included,
      } = results.candidateInfo;

      // Set States
      setHkid(hkid ? hkid : "");
      setGenderTypeField(
        gender_type_id
          ? genderTypeOptions.find((item) => item.id === gender_type_id)
          : null
      );
      setNameCh(full_name_ch ? full_name_ch : "");
      setNameEn(full_name_en ? full_name_en : "");
      setAlias(alias ? alias : "");
      setDateBirth(date_birth ? date_birth : null);
      setEmail(email ? email : "");
      setPhoneNum(phone_num ? phone_num : "");
      setAreaField(
        area_id ? areaOptions.find((item) => item.id === area_id) : null
      );
      setDistrictField(
        district_id
          ? districtOptions.find((item) => item.id === district_id)
          : null
      );
      setAddressCh(address_ch ? address_ch : "");
      setAddressEn(address_en ? address_en : "");
      setRelocationDate(relocation_date ? relocation_date : null);
      setDivisionField(
        division_id
          ? divisionOptions.find((item) => item.id === division_id)
          : null
      );
      setTitleField(
        title_id ? titleOptions.find((item) => item.id === title_id) : null
      );
      setStaffContractTypeField(
        staff_contract_type_id
          ? staffContractTypeOptions.find(
              (item) => item.id === staff_contract_type_id
            )
          : null
      );
      setSalaryCountTypeField(
        salary_count_type_id
          ? salaryCountTypeOptions.find(
              (item) => item.id === salary_count_type_id
            )
          : null
      );
      setFixedSalary(fixed_salary ? fixed_salary : "");
      setIsCommission(is_commission);
      setIsLunchIncluded(is_lunch_included);
      setRelocationTypeField(
        availableRelocationTypeIdsArr
          ? relocationTypeOptions.filter((item) =>
              availableRelocationTypeIdsArr.includes(item.id)
            )[0]
          : relocationTypeOptions[0]
      );
    }
  };

  const getCreateStaffFormOptions = async () => {
    const results = await getCreateStaffFormOptionsFetch(token);

    // Set States
    setAreaOptions(results.areas ? results.areas : []);
    setAvailableRelocationTypeIdsArr(
      results.availableRelocationTypeIdsArr
        ? results.availableRelocationTypeIdsArr
        : []
    );
    setCompanyOptions(results.companies ? results.companies : []);
    setContractTypeOptions(results.contractTypes ? results.contractTypes : []);
    setDepartmentOptions(results.departments ? results.departments : []);
    setDistrictOptions(results.districts ? results.districts : []);
    setDivisionOptions(results.divisions ? results.divisions : []);
    setGenderTypeOptions(results.genderTypes ? results.genderTypes : []);
    setRelocationTypeOptions(
      results.relocationTypes ? results.relocationTypes : []
    );
    setSalaryCountTypeOptions(
      results.salaryCountTypes ? results.salaryCountTypes : []
    );
    setShopOptions(results.shops ? results.shops : []);
    setStaffContractTypeOptions(
      results.staffContractTypes ? results.staffContractTypes : []
    );
    setTitleOptions(results.titles ? results.titles : []);
  };

  // Life Cycle
  useEffect(() => {
    getCreateStaffFormOptions();

    // Update Redux Store
    dispatch(onActiveStepReset());
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && areaOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [areaOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (candidateIdParam) {
        getCandidateInfoByIdForCreateStaffForm();
      } else {
        setDefaultOptions();
      }
    }
  }, [isOptionsRetrieved]);

  useEffect(() => {
    // Set States
    setShouldShowFormAlert(false);
  }, [activeStep]);

  return (
    <>
      {activeStep === 0 && (
        <CreateStaffFormBasicInfoContent
          // States
          alias={alias}
          dateBirth={dateBirth}
          genderTypeField={genderTypeField}
          genderTypeOptions={genderTypeOptions}
          hkid={hkid}
          nameCh={nameCh}
          nameEn={nameEn}
          staffCode={staffCode}
          // Events
          onInputFieldChange={onInputFieldChange}
          onInputFieldKeyPressed={onInputFieldKeyPressed}
          onNextStepBtnClicked={
            onCreateStaffFormBasicInfoContentSubmitBtnClicked
          }
          // Functions
          checkIsFieldError={checkIsFieldError}
          getErrorFieldMessage={getErrorFieldMessage}
        />
      )}
      {activeStep === 1 && (
        <CreateStaffFormContactAndAddressContent
          // States
          addressCh={addressCh}
          addressEn={addressEn}
          areaField={areaField}
          areaOptions={areaOptions}
          districtField={districtField}
          districtOptions={districtOptions}
          email={email}
          phoneNum={phoneNum}
          // Events
          onInputFieldChange={onInputFieldChange}
          onInputFieldKeyPressed={onInputFieldKeyPressed}
          onNextStepBtnClicked={
            onCreateStaffFormContactAndAddressContentSubmitBtnClicked
          }
          onStepBackBtnClicked={onStepBackBtnClicked}
          // Functions
          checkIsFieldError={checkIsFieldError}
          getErrorFieldMessage={getErrorFieldMessage}
        />
      )}
      {activeStep === 2 && (
        <CreateStaffFormRelocationContent
          // States
          availableRelocationTypeIdsArr={availableRelocationTypeIdsArr}
          companyField={companyField}
          companyOptions={companyOptions}
          contractTypeOptions={contractTypeOptions}
          departmentField={departmentField}
          departmentOptions={departmentOptions}
          divisionField={divisionField}
          divisionOptions={divisionOptions}
          probationLengthField={probationLengthField}
          relocationDate={relocationDate}
          relocationTypeField={relocationTypeField}
          relocationTypeOptions={relocationTypeOptions}
          shopField={shopField}
          shopOptions={shopOptions}
          titleField={titleField}
          titleOptions={titleOptions}
          // Set States
          setProbationLengthField={setProbationLengthField}
          // Events
          onInputFieldChange={onInputFieldChange}
          onInputFieldKeyPressed={onInputFieldKeyPressed}
          onNextStepBtnClicked={
            onCreateStaffFormRelocationContentSubmitBtnClicked
          }
          onStepBackBtnClicked={onStepBackBtnClicked}
          // Functions
          checkIsFieldError={checkIsFieldError}
          getErrorFieldMessage={getErrorFieldMessage}
        />
      )}
      {activeStep === 3 && (
        <CreateStaffFormContractContent
          // States
          effectiveMonth={relocationDate}
          fixedSalary={fixedSalary}
          formAlertText={formAlertText}
          formAlertType={formAlertType}
          isCommission={isCommission}
          isLunchIncluded={isLunchIncluded}
          salaryCountTypeField={salaryCountTypeField}
          salaryCountTypeOptions={salaryCountTypeOptions}
          shouldShowFormAlert={shouldShowFormAlert}
          staffContractTypeField={staffContractTypeField}
          staffContractTypeOptions={staffContractTypeOptions}
          // Events
          onInputFieldChange={onInputFieldChange}
          onInputFieldKeyPressed={onInputFieldKeyPressed}
          onStepBackBtnClicked={onStepBackBtnClicked}
          onSubmitBtnClicked={onCreateStaffFormContractContentSubmitBtnClicked}
          // Functions
          checkIsFieldError={checkIsFieldError}
          getErrorFieldMessage={getErrorFieldMessage}
        />
      )}
    </>
  );
}

export default CreateStaffFormContainer;
