// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardTableStyles } from "../../../styles/componentStyles/tableStyles/standardTableStyles";

function SalaryFieldBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardTableStyles();

  // Props
  const {
    // Events
    onItemClicked,
    // States
    hoveredSalaryFieldId,
    item,
  } = props;

  return (
    <div
      onClick={() => {
        onItemClicked(item.isEditable, item.textCh, item.textEn, item.type);
      }}
    >
      <Typography
        align={"center"}
        className={
          hoveredSalaryFieldId === item.id
            ? classes.selectedCellBoxLarge
            : item.isEditable
            ? classes.cellBoxLarge
            : classes.cellBoxLargeWithoutPointer
        }
        color={
          hoveredSalaryFieldId === item.id ? stylesConfig.whiteTextColor : null
        }
        variant="body1"
      >
        {item[t("textCh")]}
      </Typography>
    </div>
  );
}

export default SalaryFieldBox;
