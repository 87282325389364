export const getPagePath = (titleType) => {
  let path;

  switch (titleType) {
    case "Applications":
      path = "/applications";
      break;
    case "Attendances":
      path = "/attendances";
      break;
    case "CandidateProfile":
      path = "/candidateProfile";
      break;
    case "CompsAndBrands":
      path = "/compsAndBrands";
      break;
    case "CreateStaff":
      path = "/createStaff";
      break;
    case "Dashboard":
      path = "/dashboard";
      break;
    case "DayOffTypes":
      path = "/dayOffTypes";
      break;
    case "DeptsAndShops":
      path = "/deptsAndShops";
      break;
    case "Permissions":
      path = "/permissions";
      break;
    case "Rosters":
      path = "/rosters";
      break;
    case "Salaries":
      path = "/salaries";
      break;
    case "StaffProfile":
      path = "/staffProfile";
      break;
    case "Titles":
      path = "/titles";
      break;
    case "WorkingPeriodsAndPlaces":
      path = "/workingPeriodsAndPlaces";
      break;
    default:
      path = "/";
      break;
  }

  return path;
};
