// Configs
import keyConfig from "../../../configs/keyConfig";
import mapViewConfig from "../../../configs/mapViewConfig";
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getLocationDetailsByIdFetch } from "../../../fetches/locationFetches";

// Material UI
// Components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React Google Maps
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  mapViewContainerStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function MapViewModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // React Google Mpas
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: keyConfig.googleMapKey,
    language: mapViewConfig.language,
  });
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // States
    locationId,
  } = props;

  // Redux Store
  const token = useSelector((state) => state.staff.token);

  // States
  const [locationDetails, setLocationDetails] = useState(null);

  // Handle States
  const location = locationDetails
    ? {
        lat: Number(locationDetails.latitude),
        lng: Number(locationDetails.longitude),
      }
    : null;

  // Functions
  // Functions - Normal
  const clearData = () => {
    // Set States
    setLocationDetails(null);
  };

  // Functions - Queries
  const getLocationDetails = async () => {
    const results = await getLocationDetailsByIdFetch(token, locationId);

    // Set States
    setLocationDetails(
      results.locationDetails ? results.locationDetails : null
    );
  };

  // Life Cycle
  useEffect(() => {
    if (locationId) {
      getLocationDetails();
    } else {
      clearData();
    }
  }, [locationId]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Title */}
      {locationDetails && (
        <div className={classes.titleContainer}>
          <div className={classes.titleTextContainer}>
            <Typography align={"left"} variant="h6">
              {locationDetails.address}
            </Typography>
          </div>
        </div>
      )}

      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid
            className={classes.formAutoCompleteStickyContainer}
            item
            xs={12}
          >
            {location && isLoaded ? (
              <GoogleMap
                center={location}
                mapContainerStyle={mapViewContainerStyles}
                zoom={mapViewConfig.zoom}
              >
                <MarkerF key={1} position={location} />
              </GoogleMap>
            ) : (
              <div className={classes.emptyDataContainer}>
                <Typography align={"center"} color={stylesConfig.greyTextColor}>
                  {t("載入中...")}
                </Typography>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default MapViewModal;
