// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardTableStyles } from "../../../styles/componentStyles/tableStyles/standardTableStyles";

function StaffToDayOffTypeBox(props) {
  // Styles
  const classes = useStandardTableStyles();

  // Props
  const {
    // States
    item,
    staffId,
    // Events
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  return (
    <div
      className={classes.cellBoxMediumWithoutPointer}
      onMouseEnter={() => {
        onMouseEnterItem(staffId, item.day_off_type_id);
      }}
      onMouseLeave={() => {
        onMouseLeaveItem();
      }}
    >
      <Typography
        align={"center"}
        color={
          item.day_off_quota && item.day_off_quota >= 0.01
            ? null
            : stylesConfig.greyTextColor
        }
        variant="body1"
      >
        {!item.is_no_limit
          ? item.day_off_quota && item.day_off_quota >= 0.01
            ? item.day_off_quota
            : 0
          : "-/-"}
      </Typography>
    </div>
  );
}

export default StaffToDayOffTypeBox;
