// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import StaffContractBoxesContainer from "../components/boxes/staffProfilePage/staffContractBoxesContainer";
import StaffIntroBoxesContainer from "../components/boxes/staffProfilePage/staffIntroBoxesContainer";
import StaffRelocationBoxesContainer from "../components/boxes/staffProfilePage/staffRelocationBoxesContainer";
// Forms
import RelocationDetailFormContainer from "../components/forms/staffProfilePage/staffRelocations/relocationDetailFormContainer";
import StaffContractDetailForm from "../components/forms/staffProfilePage/staffContracts/staffContractDetailForm";
import StaffInfoFormContainer from "../components/forms/staffProfilePage/staffInfo/staffInfoFormContainer";
// Headers
import StaffProfilePageHeader from "../components/headers/staffProfilePage/staffProfilePageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function StaffProfilePage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const tabItem = useSelector((state) => state.staffProfilePage.tabItem);

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("StaffProfile"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <StaffProfilePageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          <StaffIntroBoxesContainer />
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
          {tabItem === "Relocations" && (
            <>
              <StaffRelocationBoxesContainer />
              <SpacingBox
                // Render
                height={stylesConfig.spacingBoxMarginBottom}
              />
            </>
          )}
          {tabItem === "SalaryContracts" && (
            <>
              <StaffContractBoxesContainer />
              <SpacingBox
                // Render
                height={stylesConfig.spacingBoxMarginBottom}
              />
            </>
          )}
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          {/* 概要 */}
          {tabItem === "BasicInfo" && <StaffInfoFormContainer />}
          {/* 調動 */}
          {tabItem === "Relocations" && <RelocationDetailFormContainer />}
          {/*  薪金合約 */}
          {tabItem === "SalaryContracts" && <StaffContractDetailForm />}
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default StaffProfilePage;
