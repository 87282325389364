// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Consts
import {
  amountOfTimeOptions,
  numberOfTimeOptions,
} from "../../../consts/attendanceRuleDetailFormContentConsts";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  createAttendanceRuleFetch,
  editAttendanceRuleFetch,
  getAttendanceRuleDetailsByIdFetch,
} from "../../../fetches/attendanceRuleFetches";
import { getAttendanceRuleDetailFormOptionsFetch } from "../../../fetches/formOptionFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function AttendanceRuleActionModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
    // States
    attendanceRuleId,
  } = props;

  // Redux Store
  const ruleAttendanceTypeId = useSelector(
    (state) => state.attendancesPage.ruleAttendanceTypeId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [attendanceRuleActionDialogText, setAttendanceRuleActionDialogText] =
    useState("");
  const [attendanceRuleActionDialogType, setAttendanceRuleActionDialogType] =
    useState(null);
  const [showAttendanceRuleActionDialog, setShowAttendanceRuleActionDialog] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [amountOfTimeField, setAmountOfTimeField] = useState(null);
  const [attendanceRuleTypeField, setAttendanceRuleTypeField] = useState(null);
  const [attendanceTypeField, setAttendanceTypeField] = useState(null);
  const [divisionField, setDivisionField] = useState([]);
  const [numOfTimeField, setNumOfTimeField] = useState(null);
  // Options
  const [attendanceRuleTypeOptions, setAttendanceRuleTypeOptions] = useState(
    []
  );
  const [attendanceTypeOptions, setAttendanceTypeOptions] = useState([]);
  const [divisionOptions, setDivisionOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);

  // Events
  // Events - Dialogs
  const onAttendanceRuleActionDialogCanceled = () => {
    // Set States
    setShowAttendanceRuleActionDialog(false);
  };

  const onAttendanceRuleActionDialogConfirmed = () => {
    switch (attendanceRuleActionDialogType) {
      case "CreateAttendanceRule":
        createAttendanceRule();
        break;
      case "EditAttendanceRule":
        editAttendanceRule();
        break;
      default:
        break;
    }

    // Set States
    setShowAttendanceRuleActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "amountOfTimeField":
        setAmountOfTimeField(value);
        break;
      case "attendanceRuleTypeField":
        setAttendanceRuleTypeField(value);

        // 總時數
        if (value.attendance_rule_type_name_en === "Total Amount of Time") {
          setNumOfTimeField(null);

          removeErrorField("numOfTimeField");
        }

        break;
      case "attendanceTypeField":
        setAttendanceTypeField(value);
        break;
      case "divisionField":
        setDivisionField(value);
        break;
      case "numOfTimeField":
        setNumOfTimeField(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!amountOfTimeField) {
      addToErrorFields("amountOfTimeField", t("請先填寫 時數"));
      isError = true;
    }

    if (!attendanceRuleTypeField) {
      addToErrorFields("attendanceRuleTypeField", t("請先填寫 規則類別"));
      isError = true;
    }

    // 總次數, 連續次數
    if (
      ["Total Number of Times", "Consecutive Number of Times"].includes(
        attendanceRuleTypeField.attendance_rule_type_name_en
      )
    ) {
      if (!numOfTimeField) {
        addToErrorFields("numOfTimeField", t("請先填寫 次數"));
        isError = true;
      }
    }

    if (!attendanceTypeField) {
      addToErrorFields("attendanceTypeField", t("請先填寫 簽到類別s"));
      isError = true;
    }

    if (!divisionField[0]) {
      addToErrorFields("divisionField", t("請先填寫 套用到s"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayAttendanceRuleActionDialog(
      attendanceRuleId ? "EditAttendanceRule" : "CreateAttendanceRule"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displayAttendanceRuleActionDialog = (attendanceRuleActionType) => {
    // Set States
    setAttendanceRuleActionDialogType(attendanceRuleActionType);

    switch (attendanceRuleActionType) {
      case "CreateAttendanceRule":
        setAttendanceRuleActionDialogText(t("確認要新增 簽到規則 嗎？"));
        break;
      case "EditAttendanceRule":
        setAttendanceRuleActionDialogText(t("確認要編輯 簽到規則 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowAttendanceRuleActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createAttendanceRule = async () => {
    const results = await createAttendanceRuleFetch(
      token,
      attendanceRuleTypeField ? attendanceRuleTypeField.id : null,
      attendanceTypeField ? attendanceTypeField.id : null,
      numOfTimeField ? numOfTimeField.value : null,
      amountOfTimeField ? amountOfTimeField.value : null,
      divisionField[0] ? divisionField.map((item) => item.id) : null
    );

    if (results.success) {
      onModalClosed();
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 規則 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editAttendanceRule = async () => {
    const results = await editAttendanceRuleFetch(
      token,
      attendanceRuleId,
      attendanceRuleTypeField ? attendanceRuleTypeField.id : null,
      attendanceTypeField ? attendanceTypeField.id : null,
      numOfTimeField ? numOfTimeField.value : null,
      amountOfTimeField ? amountOfTimeField.value : null,
      divisionField[0] ? divisionField.map((item) => item.id) : null
    );

    if (results.success) {
      onModalClosed();
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 規則 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  // Functions = Queries
  const getAttendanceRuleDetailFormOptions = async () => {
    const results = await getAttendanceRuleDetailFormOptionsFetch(token);

    // Set States
    setAttendanceRuleTypeOptions(
      results.attendanceRuleTypes ? results.attendanceRuleTypes : []
    );
    setAttendanceTypeOptions(
      results.attendanceTypes ? results.attendanceTypes : []
    );
    setDivisionOptions(results.divisions ? results.divisions : []);

    if (results.attendanceTypes) {
      setAttendanceTypeField(
        results.attendanceTypes.find((item) => item.id === ruleAttendanceTypeId)
      );
    }
  };

  const getAttendanceRuleDetailsById = async () => {
    const results = await getAttendanceRuleDetailsByIdFetch(
      token,
      attendanceRuleId
    );

    if (results.attendanceRuleDetails) {
      const {
        attendance_rule_type_id,
        attendance_type_id,
        num_of_time,
        amount_of_time,
        divisions,
      } = results.attendanceRuleDetails;

      // Set States
      setAttendanceRuleTypeField(
        attendance_rule_type_id
          ? attendanceRuleTypeOptions.find(
              (item) => item.id === attendance_rule_type_id
            )
          : null
      );
      setAttendanceTypeField(
        attendance_type_id
          ? attendanceTypeOptions.find((item) => item.id === attendance_type_id)
          : null
      );
      setNumOfTimeField(
        num_of_time
          ? numberOfTimeOptions.find((item) => item.value === num_of_time)
          : null
      );
      setAmountOfTimeField(
        amount_of_time
          ? amountOfTimeOptions.find((item) => item.value === amount_of_time)
          : null
      );

      if (divisions) {
        let divisionsArr = [];

        for (let divisionItem of divisions) {
          if (divisionOptions.find((item) => item.id === divisionItem.id)) {
            divisionsArr.push(
              divisionOptions.find((item) => item.id === divisionItem.id)
            );
          }
        }

        setDivisionField(divisionsArr);
      } else {
        setDivisionField([]);
      }
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    getAttendanceRuleDetailFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && divisionOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [divisionOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (attendanceRuleId) {
        getAttendanceRuleDetailsById();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, attendanceRuleId]);

  return (
    <div className={classes.modalContainerSmall}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onAttendanceRuleActionDialogCanceled}
        onDialogConfirmed={onAttendanceRuleActionDialogConfirmed}
        // States
        dialogText={attendanceRuleActionDialogText}
        showDialog={showAttendanceRuleActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {attendanceRuleId ? t("編輯簽到規則") : t("新增簽到規則")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid className={classes.formAutoCompleteContainer} item xs={6}>
            {attendanceTypeOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) =>
                  option[t("attendance_type_name_ch")]
                }
                onChange={(event, value) =>
                  onInputFieldChange("attendanceTypeField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={attendanceTypeOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("attendanceTypeField")}
                    helperText={getErrorFieldMessage("attendanceTypeField")}
                    label={t("簽到類別")}
                    variant="standard"
                  />
                )}
                value={attendanceTypeField}
              />
            )}
          </Grid>
          <Grid className={classes.formAutoCompleteContainer} item xs={6}>
            {attendanceRuleTypeOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) =>
                  option[t("attendance_rule_type_name_ch")]
                }
                onChange={(event, value) =>
                  onInputFieldChange("attendanceRuleTypeField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={attendanceRuleTypeOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("attendanceRuleTypeField")}
                    helperText={getErrorFieldMessage("attendanceRuleTypeField")}
                    label={t("規則類別")}
                    variant="standard"
                  />
                )}
                value={attendanceRuleTypeField}
              />
            )}
          </Grid>
          <Grid className={classes.formAutoCompleteContainer} item xs={12}>
            {divisionField && divisionOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option[t("division_name_ch")]}
                multiple
                onChange={(event, value) =>
                  onInputFieldChange("divisionField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={divisionOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("divisionField")}
                    helperText={getErrorFieldMessage("divisionField")}
                    label={t("套用到")}
                    variant="standard"
                  />
                )}
                value={divisionField}
              />
            )}
          </Grid>
        </Grid>
      </div>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("計算方式")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          {attendanceRuleTypeField &&
            ["Total Number of Times", "Consecutive Number of Times"].includes(
              attendanceRuleTypeField.attendance_rule_type_name_en
            ) && (
              <Grid className={classes.formAutoCompleteContainer} item xs={6}>
                <Autocomplete
                  disablePortal
                  getOptionLabel={(option) => option[t("textCh")]}
                  onChange={(event, value) =>
                    onInputFieldChange("numOfTimeField", value)
                  }
                  onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                  options={numberOfTimeOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={checkIsFieldError("numOfTimeField")}
                      helperText={getErrorFieldMessage("numOfTimeField")}
                      label={
                        attendanceRuleTypeField.attendance_rule_type_name_en ===
                        "Total Number of Times"
                          ? t("總次數達s")
                          : attendanceRuleTypeField.attendance_rule_type_name_en ===
                            "Consecutive Number of Times"
                          ? t("連續次數達s")
                          : t("次數s")
                      }
                      variant="standard"
                    />
                  )}
                  value={numOfTimeField}
                />
              </Grid>
            )}
          <Grid className={classes.formAutoCompleteContainer} item xs={6}>
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option[t("textCh")]}
              onChange={(event, value) =>
                onInputFieldChange("amountOfTimeField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={amountOfTimeOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("amountOfTimeField")}
                  helperText={getErrorFieldMessage("amountOfTimeField")}
                  label={
                    attendanceRuleTypeField
                      ? attendanceRuleTypeField.attendance_rule_type_name_en ===
                        "Total Amount of Time"
                        ? t("總時數達s")
                        : [
                            "Total Number of Times",
                            "Consecutive Number of Times",
                          ].includes(
                            attendanceRuleTypeField.attendance_rule_type_name_en
                          )
                        ? t("每次時數達")
                        : t("時數")
                      : t("時數")
                  }
                  variant="standard"
                />
              )}
              value={amountOfTimeField}
            />
          </Grid>
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              {t("確定")}
            </Button>
          </Grid>
          {/* Alert */}
          {shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default AttendanceRuleActionModal;
