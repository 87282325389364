// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useHorizontalItemBoxStyles } from "../../../styles/componentStyles/boxStyles/horizontalItemBoxStyles";

function WorkingHourBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useHorizontalItemBoxStyles();

  // Props
  const {
    // States
    hoveredItemId,
    hoveredWorkingPeriodId,
    item,
    typeOfContent,
    workingHourId,
  } = props;

  // Handle States
  const isHovered = hoveredItemId && hoveredItemId === workingHourId;
  const isWorkingPeriodHovered =
    typeOfContent === "WorkingPeriods" &&
    hoveredWorkingPeriodId === workingHourId;

  return (
    <div className={classes.itemContentSubContainerWithUpperMargin}>
      <Typography
        align={"left"}
        color={
          isWorkingPeriodHovered
            ? stylesConfig.whiteTextColor
            : isHovered
            ? stylesConfig.mainTextColor
            : null
        }
        variant="body2"
      >
        {item[t("start_weekday_name_ch_full")]}
        {item[t("end_weekday_name_ch_full")]
          ? ` ${t("至")} ${item[t("end_weekday_name_ch_full")]}`
          : ""}
      </Typography>
      <Typography
        align={"left"}
        color={
          isWorkingPeriodHovered
            ? stylesConfig.whiteTextColor
            : stylesConfig.greyTextColor
        }
        variant="body2"
      >
        {`${item.start_time} ${t("至")} ${item.end_time}`}
      </Typography>
    </div>
  );
}

export default WorkingHourBox;
