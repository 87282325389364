// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function StaffStatisticsBox(props) {
  // Hooks
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    item,
  } = props;

  return (
    <div className={classes.itemContainer}>
      <div className={classes.itemContentContainer}>
        <div className={classes.itemContentSubContainer}>
          <Typography align={"left"} variant="body1">
            {item.title}
          </Typography>
        </div>
      </div>
      <Typography
        align={"left"}
        color={stylesConfig.greyTextColor}
        variant="body2"
      >
        {`${item.value} ${item.unit}`}
      </Typography>
    </div>
  );
}

export default StaffStatisticsBox;
