// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import CandidateEducationBoxesContainer from "../components/boxes/candidateProfilePage/candidateEducationBoxesContainer";
import CandidateIntroBoxesContainer from "../components/boxes/candidateProfilePage/candidateIntroBoxesContainer";
import CandidateOfferBoxesContainer from "../components/boxes/candidateProfilePage/candidateOfferBoxesContainer";
import CandidateSkillBoxesContainer from "../components/boxes/candidateProfilePage/candidateSkillBoxesContainer";
import CandidateWorkExperienceBoxesContainer from "../components/boxes/candidateProfilePage/candidateWorkExperienceBoxesContainer";
// Forms
import CandidateInfoFormContainer from "../components/forms/candidateProfilePage/candidateInfoFormContainer";
// Headers
import CandidateProfilePageHeader from "../components/headers/candidateProfilePage/candidateProfilePageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch } from "react-redux";

function CandidateProfilePage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("CandidateProfile"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <CandidateProfilePageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          <CandidateIntroBoxesContainer />
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
          <CandidateOfferBoxesContainer />
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={8}>
              <CandidateInfoFormContainer />
              <SpacingBox
                // Render
                height={stylesConfig.spacingBoxMarginBottom}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CandidateSkillBoxesContainer />
              <SpacingBox
                // Render
                height={stylesConfig.spacingBoxMarginBottom}
              />
              <CandidateWorkExperienceBoxesContainer />
              <SpacingBox
                // Render
                height={stylesConfig.spacingBoxMarginBottom}
              />
              <CandidateEducationBoxesContainer />
              <SpacingBox
                // Render
                height={stylesConfig.spacingBoxMarginBottom}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default CandidateProfilePage;
