// Configs
import apisConfig from "../configs/apisConfig";

export const candidateTurnDownOfferFetch = async (token, candidateId) => {
  try {
    const queryRoute = `/candidate/candidateTurnDownOffer/${candidateId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editCandidateFetch = async (
  token,
  candidateId,
  hkid,
  genderTypeId,
  fullNameCh,
  fullNameEn,
  alias,
  dateBirth,
  educationLevelId,
  email,
  phoneNum,
  districtId,
  addressCh,
  addressEn,
  titleApplied,
  expecteSsalary,
  dateAvailable
) => {
  try {
    const queryRoute = `/candidate/editCandidate/${candidateId}`;

    const reqBody = {
      hkid,
      genderTypeId,
      fullNameCh,
      fullNameEn,
      alias,
      dateBirth,
      educationLevelId,
      email,
      phoneNum,
      districtId,
      addressCh,
      addressEn,
      titleApplied,
      expecteSsalary,
      dateAvailable,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteCandidatePhotoFetch = async (token, candidateId) => {
  try {
    const queryRoute = `/candidate/deleteCandidatePhoto/${candidateId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editCandidatePhotoFetch = async (token, candidateId, photo) => {
  try {
    const queryRoute = `/candidate/editCandidatePhoto/${candidateId}`;

    const reqBody = new FormData();

    if (photo) {
      reqBody.append("files", photo);
    }

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: reqBody,
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllCandidatesWithPaginationFetch = async (
  token,
  candidateStatusTypeId,
  startDate,
  endDate,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute = "/candidate/getAllCandidatesWithPagination";

    const reqBody = {
      candidateStatusTypeId,
      startDate,
      endDate,
      pageNum,
      limitNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getCandidateBasicInfoByIdFetch = async (token, candidateId) => {
  try {
    const queryRoute = `/candidate/getCandidateBasicInfoById/${candidateId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getCandidateDetailsByIdFetch = async (token, candidateId) => {
  try {
    const queryRoute = `/candidate/getCandidateDetailsById/${candidateId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getCandidateInfoByIdForCreateStaffFormFetch = async (
  token,
  candidateId
) => {
  try {
    const queryRoute = `/candidate/getCandidateInfoByIdForCreateStaffForm/${candidateId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const offerCandidateFetch = async (
  token,
  candidateId,
  staffContractTypeId,
  salaryCountTypeId,
  titleId,
  fixedSalary,
  onboardDate,
  isCommission,
  isLunchIncluded
) => {
  try {
    const queryRoute = `/candidate/offerCandidate/${candidateId}`;

    const reqBody = {
      staffContractTypeId,
      salaryCountTypeId,
      titleId,
      fixedSalary,
      onboardDate,
      isCommission,
      isLunchIncluded,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const putCandidateToWaitingListFetch = async (token, candidateId) => {
  try {
    const queryRoute = `/candidate/putCandidateToWaitingList/${candidateId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const rejectCandidateFetch = async (token, candidateId) => {
  try {
    const queryRoute = `/candidate/rejectCandidate/${candidateId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
