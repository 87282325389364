// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// Icons
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function DayOffTypeRuleBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    dayOffTypeCountTypeNameCh,
    dayOffTypeCountTypeNameEn,
    dayOffTypeRuleTypeNameEn,
    hoveredItemId,
    item,
    language,
    // Events
    onItemClicked,
    onItemRightClicked,
    onItemShowMenuBtnClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isHovered = hoveredItemId && hoveredItemId === item.id;

  return (
    <div
      className={
        isHovered ? classes.hoveredItemContainer : classes.itemContainer
      }
      onMouseEnter={() => onMouseEnterItem(item.id)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div
        className={classes.itemContentContainer}
        onClick={() => onItemClicked(item.id)}
        onContextMenu={(event) => {
          event.preventDefault();
          onItemRightClicked(item.id, event.currentTarget);
        }}
      >
        <div className={classes.itemContentSubContainer}>
          <Typography
            align={"left"}
            color={isHovered ? stylesConfig.mainTextColor : null}
            variant="body1"
          >
            {dayOffTypeRuleTypeNameEn === "Fixed"
              ? t("固定數量")
              : dayOffTypeRuleTypeNameEn === "Seniority"
              ? item.rule_type_num !== 0
                ? `${t("已滿")} ${item.rule_type_num} ${t("年s")}`
                : t("未滿 1 年")
              : dayOffTypeRuleTypeNameEn === "From Overtime"
              ? t("由超時補上")
              : dayOffTypeRuleTypeNameEn === "Birthday Month"
              ? t("生日月份")
              : ""}
          </Typography>
          <Typography
            align={"left"}
            color={stylesConfig.greyTextColor}
            variant="body2"
          >
            {dayOffTypeRuleTypeNameEn === "From Overtime"
              ? `${t("每every")} ${item.quota_num} ${t("小時補上 1 日")}`
              : `${
                  language === "zh-hk"
                    ? dayOffTypeCountTypeNameCh
                    : dayOffTypeCountTypeNameEn
                } ${item.quota_num} ${t("日s")}`}
          </Typography>
        </div>
      </div>
      <Stack direction="row" spacing={1}>
        <IconButton
          onClick={(event) =>
            onItemShowMenuBtnClicked(item.id, event.currentTarget)
          }
        >
          <MenuRoundedIcon />
        </IconButton>
      </Stack>
    </div>
  );
}

export default DayOffTypeRuleBox;
