// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function ApplicationBoxContentApplicant(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    isHovered,
    isSelected,
    item,
  } = props;

  return (
    <>
      <div className={classes.itemContentSubContainer}>
        <Typography
          align={"left"}
          color={
            isSelected
              ? stylesConfig.whiteTextColor
              : isHovered
              ? stylesConfig.mainTextColor
              : null
          }
          variant="body1"
        >
          {`${t("程序")} - ${item[t("applicationProcedureNameCh")]}`}
        </Typography>
        <Typography
          align={"left"}
          color={
            isSelected
              ? stylesConfig.whiteTextColor
              : stylesConfig.greyTextColor
          }
          variant="body2"
        >
          {item.time_apply} {t("申請d")}
        </Typography>
      </div>
      <div className={classes.itemContentSubContainer}>
        <Typography
          align={"left"}
          color={
            isSelected
              ? stylesConfig.whiteTextColor
              : isHovered
              ? stylesConfig.mainTextColor
              : null
          }
          variant="body1"
        >
          {item[t("application_type_name_ch")]}
          {item.answerText &&
            item.answerText[t("main_answer_text_ch")] &&
            " - "}
          {item.answerText &&
            item.answerText[t("main_answer_text_ch")] &&
            item.answerText[t("main_answer_text_ch")]}
        </Typography>
        <Typography
          align={"left"}
          color={
            isSelected
              ? stylesConfig.whiteTextColor
              : stylesConfig.greyTextColor
          }
          variant="body2"
        >
          {item.answerText &&
            item.answerText[t("sub_answer_text_ch_1")] &&
            item.answerText[t("sub_answer_text_ch_1")]}
          {item.answerText &&
            item.answerText[t("sub_answer_text_ch_1")] &&
            item.answerText[t("sub_answer_text_ch_2")] &&
            " - "}
          {item.answerText &&
            item.answerText[t("sub_answer_text_ch_2")] &&
            item.answerText[t("sub_answer_text_ch_2")]}
        </Typography>
      </div>
      <div className={classes.itemContentSubContainer}>
        <Typography
          align={"left"}
          color={
            isSelected
              ? stylesConfig.whiteTextColor
              : isHovered
              ? stylesConfig.mainTextColor
              : null
          }
          variant="body1"
        >
          {item[t("application_status_type_name_ch")]}
        </Typography>
        <Typography
          align={"left"}
          color={
            isSelected
              ? stylesConfig.whiteTextColor
              : stylesConfig.greyTextColor
          }
          variant="body2"
        >
          {item.staff_code && `${item.staff_code} - ${item.full_name_en}`}
        </Typography>
      </div>
    </>
  );
}

export default ApplicationBoxContentApplicant;
