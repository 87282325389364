// Actions
import {
  onWorkingPlaceChange,
  onWorkingPlaceItemChange,
  onWorkingPlaceTypeChange,
  onWorkingPlaceTypeItemChange,
} from "../../../redux/pages/workingPeriodsAndPlacesPage/workingPeriodsAndPlacesPageActions";

// Components
// Boxes
import WorkingPlaceBox from "./workingPlaceBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import WorkingPlaceActionMenu from "../../menus/workingPeriodsAndPlacesPage/workingPlaceActionMenu";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteOrRestoreWorkingPlaceFetch,
  editWorkingPlaceSetIsDefaultFetch,
  getAllWorkingPlacesByWorkingPlaceTypeIdFetch,
} from "../../../fetches/workingPlaceFetches";
import { getAllWorkingPlaceTypesFetch } from "../../../fetches/workingPlaceTypeFetches";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function WorkingPlaceBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.workingPeriodsAndPlacesPage.formSubmitCount
  );
  const token = useSelector((state) => state.staff.token);
  const typeOfWorkingPeriodsAndPlaces = useSelector(
    (state) => state.workingPeriodsAndPlacesPage.typeOfWorkingPeriodsAndPlaces
  );
  const workingPlaceId = useSelector(
    (state) => state.workingPeriodsAndPlacesPage.workingPlaceId
  );
  const workingPlaceTypeId = useSelector(
    (state) => state.workingPeriodsAndPlacesPage.workingPlaceTypeId
  );
  const workingPlaceTypeItem = useSelector(
    (state) => state.workingPeriodsAndPlacesPage.workingPlaceTypeItem
  );

  // States
  // Data
  const [workingPlaces, setWorkingPlaces] = useState(null);
  const [workingPlaceTypes, setWorkingPlaceTypes] = useState(null);
  // Dialog
  const [workingPlaceActionDialogText, setWorkingPlaceActionDialogText] =
    useState("");
  const [workingPlaceActionDialogType, setWorkingPlaceActionDialogType] =
    useState(null);
  const [showWorkingPlaceActionDialog, setShowWorkingPlaceActionDialog] =
    useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showWorkingPlaceActionMenu, setShowWorkingPlaceActionMenu] =
    useState(null);
  // Render
  const [selectedWorkingPlace, setSelectedWorkingPlace] = useState(null);

  // Handle States
  const currentItemId = workingPlaceId;
  const itemsArr = workingPlaces;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onWorkingPlaceChange(itemId));
  };

  const onItemRightClicked = (item, currentTarget) => {
    onItemClicked(item.id);
    // Set States
    setSelectedWorkingPlace(item);
    setShowWorkingPlaceActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (item, currentTarget) => {
    onItemClicked(item.id);
    // Set States
    setSelectedWorkingPlace(item);
    setShowWorkingPlaceActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onWorkingPlaceActionDialogCanceled = () => {
    // Set States
    setShowWorkingPlaceActionDialog(false);
  };

  const onWorkingPlaceActionDialogConfirmed = () => {
    switch (workingPlaceActionDialogType) {
      // 刪除
      case "DeleteWorkingPlace":
        deleteOrRestoreWorkingPlace();
        break;
      // 設為一般地點
      case "RemoveWorkingPlaceDefault":
        editWorkingPlaceSetIsDefault();
        break;
      // 還原
      case "RestoreWorkingPlace":
        deleteOrRestoreWorkingPlace();
        break;
      // 設為預設地點
      case "SetWorkingPlaceDefault":
        editWorkingPlaceSetIsDefault();
        break;
      default:
        break;
    }

    // Set States
    setShowWorkingPlaceActionDialog(false);
  };

  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "workingPlaceType":
        dispatch(onWorkingPlaceTypeChange(value.id));
        break;
      default:
        break;
    }
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Update Redux Store
    dispatch(onWorkingPlaceChange(null));
  };

  // Functions
  // Functions - Normal
  const displayWorkingPlaceActionDialog = (workingPlaceActionType) => {
    const workingPlaceNameEnShort =
      selectedWorkingPlace.working_place_name_en_short;

    // Set States
    setWorkingPlaceActionDialogType(workingPlaceActionType);

    switch (workingPlaceActionType) {
      // 刪除
      case "DeleteWorkingPlace":
        setWorkingPlaceActionDialogText(t("確認要刪除 工作地點 嗎？"));
        break;
      // 設為一般地點
      case "RemoveWorkingPlaceDefault":
        setWorkingPlaceActionDialogText(
          `${t("確認要將")} ${workingPlaceNameEnShort} ${t("設為一般地點嗎？")}`
        );
        break;
      // 還原
      case "RestoreWorkingPlace":
        setWorkingPlaceActionDialogText(t("確認要還原 工作地點 嗎？"));
        break;
      // 設為預設地點
      case "SetWorkingPlaceDefault":
        setWorkingPlaceActionDialogText(
          `${t("確認要將")} ${workingPlaceNameEnShort} ${t("設為預設地點嗎？")}`
        );
        break;
      default:
        break;
    }

    // Set States
    setShowWorkingPlaceActionDialog(true);
  };

  // Functions - Mutations
  const deleteOrRestoreWorkingPlace = async () => {
    const results = await deleteOrRestoreWorkingPlaceFetch(
      token,
      currentItemId,
      typeOfWorkingPeriodsAndPlaces
    );

    if (results.success) {
      getAllWorkingPlacesByWorkingPlaceTypeId(true);
    }
  };

  const editWorkingPlaceSetIsDefault = async () => {
    const results = await editWorkingPlaceSetIsDefaultFetch(
      token,
      currentItemId,
      !selectedWorkingPlace.is_default
    );

    if (results.success) {
      getAllWorkingPlacesByWorkingPlaceTypeId(false);
    }
  };

  // Functions = Queries
  const getAllWorkingPlacesByWorkingPlaceTypeId = async (
    shoulddUpdateCurrentWorkingPlaceId
  ) => {
    const results = await getAllWorkingPlacesByWorkingPlaceTypeIdFetch(
      token,
      workingPlaceTypeId,
      typeOfWorkingPeriodsAndPlaces
    );

    // Set States
    setWorkingPlaces(results.workingPlaces ? results.workingPlaces : null);

    // Update Redux Store
    if (
      results.workingPlaces &&
      (shoulddUpdateCurrentWorkingPlaceId ||
        !results.workingPlaces.some((item) => item.id === workingPlaceId))
    ) {
      dispatch(
        onWorkingPlaceChange(
          results.workingPlaces ? results.workingPlaces[0].id : null
        )
      );
    }
  };

  const getAllWorkingPlaceTypes = async () => {
    const results = await getAllWorkingPlaceTypesFetch(token);

    // Set States
    setWorkingPlaceTypes(
      results.workingPlaceTypes ? results.workingPlaceTypes : null
    );

    // Update Redux Store
    if (results.workingPlaceTypes && !workingPlaceTypeId) {
      dispatch(onWorkingPlaceTypeChange(results.workingPlaceTypes[0].id));
    }
  };

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    if (workingPlaces && workingPlaceId) {
      dispatch(
        onWorkingPlaceItemChange(
          workingPlaces.find((item) => item.id === workingPlaceId)
        )
      );
    }
  }, [workingPlaces, workingPlaceId]);

  useEffect(() => {
    // Update Redux Store
    if (workingPlaceTypes && workingPlaceTypeId) {
      dispatch(
        onWorkingPlaceTypeItemChange(
          workingPlaceTypes.find((item) => item.id === workingPlaceTypeId)
        )
      );
    }
  }, [workingPlaceTypes, workingPlaceTypeId]);

  useEffect(() => {
    getAllWorkingPlaceTypes();
  }, [formSubmitCount]);

  useEffect(() => {
    if (workingPlaceTypeId) {
      getAllWorkingPlacesByWorkingPlaceTypeId(currentItemId ? false : true);
    }
  }, [workingPlaceTypeId, formSubmitCount, typeOfWorkingPeriodsAndPlaces]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onWorkingPlaceActionDialogCanceled}
        onDialogConfirmed={onWorkingPlaceActionDialogConfirmed}
        // States
        dialogText={workingPlaceActionDialogText}
        showDialog={showWorkingPlaceActionDialog}
      />
      {/* Menu */}
      <WorkingPlaceActionMenu
        // States
        selectedWorkingPlace={selectedWorkingPlace}
        showWorkingPlaceActionMenu={showWorkingPlaceActionMenu}
        // Set States
        setSelectedWorkingPlace={setSelectedWorkingPlace}
        setShowWorkingPlaceActionMenu={setShowWorkingPlaceActionMenu}
        setWorkingPlaceActionDialogType={setWorkingPlaceActionDialogType}
        // Fucntions
        displayWorkingPlaceActionDialog={displayWorkingPlaceActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("工作地點類別")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      {workingPlaceTypes && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) => option[t("working_place_type_name_ch")]}
            onChange={(event, value) =>
              onInputFieldChange("workingPlaceType", value)
            }
            options={workingPlaceTypes}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("工作地點類別")}
                variant="outlined"
              />
            )}
            value={workingPlaceTypeItem}
          />
        </div>
      )}
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("工作地點s")}
          </Typography>
          <Tooltip placement="right" title={t("新增工作地點")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <WorkingPlaceBox
            key={item.id}
            // States
            currentItemId={currentItemId}
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有工作地點資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default WorkingPlaceBoxesContainer;
