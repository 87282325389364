// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function SalaryBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    hoveredItemId,
    item,
    // Events
    onInputFieldChange,
    onInputFieldFocused,
    onInputFieldKeyPressed,
    onInputFieldUnfocused,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isHovered = hoveredItemId && hoveredItemId === item.id;

  return (
    <div
      className={
        isHovered ? classes.hoveredItemContainer : classes.itemContainer
      }
      onMouseEnter={() => onMouseEnterItem(item.id, item.type_en)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div className={classes.itemContentContainer}>
        <div className={classes.itemContentSubContainer}>
          <Typography
            align={"left"}
            color={isHovered ? stylesConfig.mainTextColor : null}
            variant="body1"
          >
            {`${item.staff_code} - ${
              item.alias ? item.alias : item.full_name_en
            }`}
          </Typography>
          <Typography
            align={"left"}
            color={stylesConfig.greyTextColor}
            variant="body2"
          >
            {item[t("title_name_ch")]}
          </Typography>
        </div>
        <div>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            margin="dense"
            name="commission"
            onBlur={() => onInputFieldUnfocused(item.id)}
            onChange={(event) =>
              onInputFieldChange(item.id, event.target.value)
            }
            onFocus={() => onInputFieldFocused(item.id)}
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={item.editedCommission}
            variant="standard"
          />
        </div>
      </div>
    </div>
  );
}

export default SalaryBox;
