// Configs
import apisConfig from "../configs/apisConfig";

export const createAttendanceRuleFetch = async (
  token,
  attendanceRuleTypeId,
  attendanceTypeId,
  numOfTime,
  amountOfTime,
  divisionIdsArr
) => {
  try {
    const queryRoute = "/attendanceRule/createAttendanceRule";

    const reqBody = {
      attendanceRuleTypeId,
      attendanceTypeId,
      numOfTime,
      amountOfTime,
      divisionIdsArr,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteAttendanceRuleFetch = async (token, attendanceRuleId) => {
  try {
    const queryRoute = `/attendanceRule/deleteAttendanceRule/${attendanceRuleId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editAttendanceRuleFetch = async (
  token,
  attendanceRuleId,
  attendanceRuleTypeId,
  attendanceTypeId,
  numOfTime,
  amountOfTime,
  divisionIdsArr
) => {
  try {
    const queryRoute = `/attendanceRule/editAttendanceRule/${attendanceRuleId}`;

    const reqBody = {
      attendanceRuleTypeId,
      attendanceTypeId,
      numOfTime,
      amountOfTime,
      divisionIdsArr,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllAttendanceRulesByAttendanceTypeIdFetch = async (
  token,
  attendanceTypeId
) => {
  try {
    const queryRoute = `/attendanceRule/getAllAttendanceRulesByAttendanceTypeId/${attendanceTypeId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAttendanceRuleDetailsByIdFetch = async (
  token,
  attendanceRuleId
) => {
  try {
    const queryRoute = `/attendanceRule/getAttendanceRuleDetailsById/${attendanceRuleId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
