// Components
// Boxes
import FileBox from "../../boxes/applicationsPage/fileViewModal/fileBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getFilesByIdsArrFetch } from "../../../fetches/fileFetches";

// Helper Functions
import { displayFileSizeText } from "../../../helperFunctions/file";
import { openFileInNewTab } from "../../../helperFunctions/file";

// Material UI
// Components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function FileViewModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // States
    fileIdsArr,
    modalTitle,
  } = props;

  // Redux Store
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [files, setFiles] = useState(null);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);

  // Handle States
  const itemsArr = files;

  // Events
  // Events - Boxes
  const onItemClicked = (itemPath) => {
    openFileInNewTab(itemPath);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Functions - Queries
  const getFilesByIdsArr = async () => {
    const results = await getFilesByIdsArrFetch(token, fileIdsArr);

    if (results.files) {
      for (let item of results.files) {
        item.file_size_text = displayFileSizeText(item.file_size);
      }
    }

    // Set States
    setFiles(results.files ? results.files : null);
  };

  // Life Cycle
  useEffect(() => {
    if (fileIdsArr && fileIdsArr[0]) {
      getFilesByIdsArr();
    }
  }, [fileIdsArr]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {modalTitle}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid item xs={12}>
            {/* List */}
            {itemsArr ? (
              itemsArr.map((item, index) => (
                <FileBox
                  key={`${item.type_en}-${item.id}`}
                  // States
                  hoveredItemId={hoveredItemId}
                  index={index}
                  item={item}
                  // Events
                  onItemClicked={onItemClicked}
                  onMouseEnterItem={onMouseEnterItem}
                  onMouseLeaveItem={onMouseLeaveItem}
                />
              ))
            ) : (
              <div className={classes.emptyDataContainer}>
                <Typography align={"center"} color={stylesConfig.greyTextColor}>
                  {t("未有相關檔案")}
                </Typography>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default FileViewModal;
