// Custom Hooks
import { useLanguage } from "../../../../customHooks/getLanguage";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function DayOffTypeDetailFormUpperContent(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // States
    dayOffTypeCountTypeField,
    dayOffTypeCountTypeOptions,
    dayOffTypePaidTypeField,
    dayOffTypePaidTypeOptions,
    dayOffTypePayoutTypeField,
    dayOffTypePayoutTypeOptions,
    dayOffTypeRuleTypeField,
    dayOffTypeRuleTypeOptions,
    divisionField,
    divisionOptions,
    isItemActive,
    nameCh,
    nameEnFull,
    nameEnShort,
    // Events
    onInputFieldChange,
    onInputFieldKeyPressed,
    // Functions
    checkIsFieldError,
    getErrorFieldMessage,
  } = props;

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("假期種類資料")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("nameCh")}
            fullWidth
            helperText={getErrorFieldMessage("nameCh")}
            label={t("中文名稱")}
            margin="dense"
            name="nameCh"
            onChange={(event) =>
              onInputFieldChange("nameCh", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={nameCh}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("nameEnShort")}
            fullWidth
            helperText={getErrorFieldMessage("nameEnShort")}
            label={t("英文簡稱")}
            margin="dense"
            name="nameEnShort"
            onChange={(event) =>
              onInputFieldChange("nameEnShort", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            required
            value={nameEnShort}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("nameEnFull")}
            fullWidth
            helperText={getErrorFieldMessage("nameEnFull")}
            label={t("英文全名")}
            margin="dense"
            name="nameEnFull"
            onChange={(event) =>
              onInputFieldChange("nameEnFull", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={nameEnFull}
            variant="standard"
          />
        </Grid>
        <Grid className={classes.formSwitchContainer} item xs={6}>
          <Typography align={"left"} variant="body1">
            {t("有效")}
          </Typography>
          <Switch
            checked={isItemActive}
            onChange={() => onInputFieldChange("isItemActive", !isItemActive)}
          />
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={12}>
          {divisionField && divisionOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option[t("division_name_ch")]}
              multiple
              onChange={(event, value) =>
                onInputFieldChange("divisionField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={divisionOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("divisionField")}
                  helperText={getErrorFieldMessage("divisionField")}
                  label={t("套用到")}
                  variant="standard"
                />
              )}
              value={divisionField}
            />
          )}
        </Grid>
      </Grid>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("計算方式")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {dayOffTypeRuleTypeOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) =>
                option[t("day_off_type_rule_type_name_ch")]
              }
              onChange={(event, value) =>
                onInputFieldChange("dayOffTypeRuleTypeField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={dayOffTypeRuleTypeOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("dayOffTypeRuleTypeField")}
                  helperText={getErrorFieldMessage("dayOffTypeRuleTypeField")}
                  label={t("計算方式r")}
                  variant="standard"
                />
              )}
              value={dayOffTypeRuleTypeField}
            />
          )}
        </Grid>
        {dayOffTypeRuleTypeField &&
          dayOffTypeRuleTypeField.day_off_type_rule_type_name_en !==
            "No Limit" && (
            <Grid className={classes.formAutoCompleteContainer} item xs={6}>
              {dayOffTypeCountTypeOptions && (
                <Autocomplete
                  disablePortal
                  getOptionLabel={(option) =>
                    option[t("day_off_type_count_type_name_ch")]
                  }
                  onChange={(event, value) =>
                    onInputFieldChange("dayOffTypeCountTypeField", value)
                  }
                  onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                  options={dayOffTypeCountTypeOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={checkIsFieldError("dayOffTypeCountTypeField")}
                      helperText={getErrorFieldMessage(
                        "dayOffTypeCountTypeField"
                      )}
                      label={t("計算單位")}
                      variant="standard"
                    />
                  )}
                  value={dayOffTypeCountTypeField}
                />
              )}
            </Grid>
          )}
        {dayOffTypeRuleTypeField &&
          dayOffTypeRuleTypeField.day_off_type_rule_type_name_en !==
            "No Limit" && (
            <Grid className={classes.formAutoCompleteContainer} item xs={6}>
              {dayOffTypePayoutTypeOptions && (
                <Autocomplete
                  disablePortal
                  getOptionLabel={(option) =>
                    option[t("day_off_type_payout_type_name_ch")]
                  }
                  onChange={(event, value) =>
                    onInputFieldChange("dayOffTypePayoutTypeField", value)
                  }
                  onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                  options={dayOffTypePayoutTypeOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={checkIsFieldError("dayOffTypePayoutTypeField")}
                      helperText={getErrorFieldMessage(
                        "dayOffTypePayoutTypeField"
                      )}
                      label={t("發放方式")}
                      variant="standard"
                    />
                  )}
                  value={dayOffTypePayoutTypeField}
                />
              )}
            </Grid>
          )}
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {dayOffTypePaidTypeOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) =>
                option[t("day_off_type_paid_type_name_ch")]
              }
              onChange={(event, value) =>
                onInputFieldChange("dayOffTypePaidTypeField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={dayOffTypePaidTypeOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("dayOffTypePaidTypeField")}
                  helperText={getErrorFieldMessage("dayOffTypePaidTypeField")}
                  label={t("薪金p")}
                  variant="standard"
                />
              )}
              value={dayOffTypePaidTypeField}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default DayOffTypeDetailFormUpperContent;
