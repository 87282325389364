// Components
// Dialogs
import AlertDialog from "../../../dialogs/alertDialog";
// Modals
import ArrangementActionModalContent from "./arrangementActionModalContent";

// Custom Hooks
import { useLanguage } from "../../../../customHooks/getLanguage";

// Fetches
import {
  createArrangementFetch,
  editArrangementFetch,
  getArrangementDetailsByIdFetch,
} from "../../../../fetches/arrangementFetches";
import { getAllShiftsByShopIdFetch } from "../../../../fetches/shiftFetches";
import { getAllWorkingPeriodsFetch } from "../../../../fetches/workingPeriodFetches";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ArrangementActionModalContainer(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
    // Functions
    increaseArrangemntFormSubmitCount,
    // States
    arrangementId,
    dayId,
  } = props;

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [arrangementActionDialogText, setArrangementActionDialogText] =
    useState("");
  const [arrangementActionDialogType, setArrangementActionDialogType] =
    useState(null);
  const [showArrangementActionDialog, setShowArrangementActionDialog] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [shiftField, setShiftField] = useState(null);
  const [workingPeriodField, setWorkingPeriodField] = useState(null);
  // Options
  const [shiftOptions, setShiftOptions] = useState(null);
  const [workingPeriodOptions, setWorkingPeriodOptions] = useState(null);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Select Fields
  const [afterLunchTimeHour, setAfterLunchTimeHour] = useState(0);
  const [afterLunchTimeMinute, setAfterLunchTimeMinute] = useState(0);
  const [afterLunchTimeType, setAfterLunchTimeType] = useState("ActualTime");
  const [endTimeHour, setEndTimeHour] = useState(0);
  const [endTimeMinute, setEndTimeMinute] = useState(0);
  const [endTimeType, setEndTimeType] = useState("ActualTime");
  const [lunchTimeHour, setLunchTimeHour] = useState(0);
  const [lunchTimeMinute, setLunchTimeMinute] = useState(0);
  const [lunchTimeType, setLunchTimeType] = useState("ActualTime");
  const [shiftType, setShiftType] = useState("All");
  const [shopType, setShopType] = useState("All");
  const [startTimeHour, setStartTimeHour] = useState(0);
  const [startTimeMinute, setStartTimeMinute] = useState(0);
  const [startTimeType, setStartTimeType] = useState("ActualTime");
  const [workingPeriodType, setWorkingPeriodType] = useState("All");
  // Time Fields
  const [afterLunchTime, setAfterLunchTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [lunchTime, setLunchTime] = useState(null);
  const [startTime, setStartTime] = useState(null);

  // Redux Store
  const divisionType = useSelector((state) => state.rostersPage.divisionType);
  const shopId = useSelector((state) => state.rostersPage.shopId);
  const token = useSelector((state) => state.staff.token);

  // Events
  // Events - Dialogs
  const onArrangementActionDialogCanceled = () => {
    // Set States
    setShowArrangementActionDialog(false);
  };

  const onArrangementActionDialogConfirmed = () => {
    switch (arrangementActionDialogType) {
      case "CreateArrangement":
        createArrangement();
        break;
      case "EditArrangement":
        editArrangement();
        break;
      default:
        break;
    }

    // Set States
    setShowArrangementActionDialog(false);

    increaseArrangemntFormSubmitCount();
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "afterLunchTime":
        setAfterLunchTime(value);
        break;
      case "afterLunchTimeHour":
        setAfterLunchTimeHour(value);
        break;
      case "afterLunchTimeMinute":
        setAfterLunchTimeMinute(value);
        break;
      case "afterLunchTimeType":
        setAfterLunchTimeType(value);
        break;
      case "endTime":
        setEndTime(value);
        break;
      case "endTimeHour":
        setEndTimeHour(value);
        break;
      case "endTimeMinute":
        setEndTimeMinute(value);
        break;
      case "endTimeType":
        setEndTimeType(value);
        break;
      case "lunchTime":
        setLunchTime(value);
        break;
      case "lunchTimeHour":
        setLunchTimeHour(value);
        break;
      case "lunchTimeMinute":
        setLunchTimeMinute(value);
        break;
      case "lunchTimeType":
        setLunchTimeType(value);
        break;
      case "shiftField":
        setShiftField(value);
        break;
      case "shiftType":
        setShiftType(value);
        break;
      case "shopType":
        setShopType(value);
        break;
      case "startTime":
        setStartTime(value);

        break;
      case "startTimeHour":
        setStartTimeHour(value);
        break;
      case "startTimeMinute":
        setStartTimeMinute(value);
        break;
      case "startTimeType":
        setStartTimeType(value);
        break;
      case "workingPeriodField":
        setWorkingPeriodField(value);
        break;
      case "workingPeriodType":
        setWorkingPeriodType(value);
        break;
      default:
        break;
    }

    clearErrorFields();
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (shiftType === "Single" && !shiftField) {
      addToErrorFields("shiftField", t("請先填寫 值更"));
      isError = true;
    }

    if (workingPeriodType === "Single" && !workingPeriodField) {
      addToErrorFields("workingPeriodField", t("請先填寫 工作時段"));
      isError = true;
    }

    if (
      ((startTimeType === "ActualTime" && !startTime) ||
        (["Early", "Delay"].includes(startTimeType) &&
          startTimeHour === 0 &&
          startTimeMinute === 0)) &&
      ((lunchTimeType === "ActualTime" && !lunchTime) ||
        (["Early", "Delay"].includes(lunchTimeType) &&
          lunchTimeHour === 0 &&
          lunchTimeMinute === 0)) &&
      ((afterLunchTimeType === "ActualTime" && !afterLunchTime) ||
        (["Early", "Delay"].includes(afterLunchTimeType) &&
          afterLunchTimeHour === 0 &&
          afterLunchTimeMinute === 0)) &&
      ((endTimeType === "ActualTime" && !endTime) ||
        (["Early", "Delay"].includes(endTimeType) &&
          endTimeHour === 0 &&
          endTimeMinute === 0))
    ) {
      if (startTimeType === "ActualTime") {
        addToErrorFields("startTime", t("請先填寫 開始時間 或 結束時間"));
      } else {
        addToErrorFields("startTimeHour", t("請先填寫 開始時間 或 結束時間"));
        addToErrorFields("startTimeMinute", t("請先填寫 開始時間 或 結束時間"));
      }

      if (lunchTimeType === "ActualTime") {
        addToErrorFields("lunchTime", t("請先填寫 開始時間 或 結束時間"));
      } else {
        addToErrorFields("lunchTimeHour", t("請先填寫 開始時間 或 結束時間"));
        addToErrorFields("lunchTimeMinute", t("請先填寫 開始時間 或 結束時間"));
      }

      if (afterLunchTimeType === "ActualTime") {
        addToErrorFields("afterLunchTime", t("請先填寫 開始時間 或 結束時間"));
      } else {
        addToErrorFields(
          "afterLunchTimeHour",
          t("請先填寫 開始時間 或 結束時間")
        );
        addToErrorFields(
          "afterLunchTimeMinute",
          t("請先填寫 開始時間 或 結束時間")
        );
      }

      if (endTimeType === "ActualTime") {
        addToErrorFields("endTime", t("請先填寫 開始時間 或 結束時間"));
      } else {
        addToErrorFields("endTimeHour", t("請先填寫 開始時間 或 結束時間"));
        addToErrorFields("endTimeMinute", t("請先填寫 開始時間 或 結束時間"));
      }

      isError = true;
    }

    if (isError) {
      return;
    }

    displayArrangementActionDialog(
      arrangementId ? "EditArrangement" : "CreateArrangement"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displayArrangementActionDialog = (arrangementActionType) => {
    // Set States
    setArrangementActionDialogType(arrangementActionType);

    switch (arrangementActionType) {
      case "CreateArrangement":
        setArrangementActionDialogText(t("確認要新增 特別安排 嗎？"));
        break;
      case "EditArrangement":
        setArrangementActionDialogText(t("確認要編輯 特別安排 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowArrangementActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createArrangement = async () => {
    const results = await createArrangementFetch(
      token,
      dayId,
      divisionType,
      workingPeriodType,
      workingPeriodField ? workingPeriodField.id : null,
      shopType,
      shopId,
      shiftType,
      shiftField ? shiftField.id : null,
      startTimeType,
      startTimeHour,
      startTimeMinute,
      startTime,
      lunchTimeType,
      lunchTimeHour,
      lunchTimeMinute,
      lunchTime,
      afterLunchTimeType,
      afterLunchTimeHour,
      afterLunchTimeMinute,
      afterLunchTime,
      endTimeType,
      endTimeHour,
      endTimeMinute,
      endTime
    );

    if (results.success) {
      onModalClosed();
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("已有相關 安排"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editArrangement = async () => {
    const results = await editArrangementFetch(
      token,
      arrangementId,
      dayId,
      divisionType,
      workingPeriodType,
      workingPeriodField ? workingPeriodField.id : null,
      shopType,
      shopId,
      shiftType,
      shiftField ? shiftField.id : null,
      startTimeType,
      startTimeHour,
      startTimeMinute,
      startTime,
      lunchTimeType,
      lunchTimeHour,
      lunchTimeMinute,
      lunchTime,
      afterLunchTimeType,
      afterLunchTimeHour,
      afterLunchTimeMinute,
      afterLunchTime,
      endTimeType,
      endTimeHour,
      endTimeMinute,
      endTime
    );

    if (results.success) {
      onModalClosed();
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("已有相關 安排"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  // Functions - Queries
  const getAllShifts = async () => {
    const results = await getAllShiftsByShopIdFetch(token, shopId, "Current");

    // Set States
    setShiftOptions(results.shifts ? results.shifts : null);
  };

  const getAllWorkingPeriods = async () => {
    const results = await getAllWorkingPeriodsFetch(token, "Current");

    // Set States
    setWorkingPeriodOptions(
      results.workingPeriods ? results.workingPeriods : null
    );
  };

  const getArrangementDetailsById = async () => {
    const results = await getArrangementDetailsByIdFetch(token, arrangementId);

    if (results.arrangementDetails) {
      const {
        start_time,
        lunch_time,
        after_lunch_time,
        end_time,
        start_time_difference,
        lunch_time_difference,
        after_lunch_time_difference,
        end_time_difference,
        working_period_id,
        shop_id,
        shift_id,
      } = results.arrangementDetails;

      // Set States
      setStartTimeType(
        start_time || !start_time_difference
          ? "ActualTime"
          : start_time_difference > 0
          ? "Delay"
          : "Early"
      );
      setLunchTimeType(
        lunch_time || !lunch_time_difference
          ? "ActualTime"
          : lunch_time_difference > 0
          ? "Delay"
          : "Early"
      );
      setAfterLunchTimeType(
        after_lunch_time || !after_lunch_time_difference
          ? "ActualTime"
          : after_lunch_time_difference > 0
          ? "Delay"
          : "Early"
      );
      setEndTimeType(
        end_time || !end_time_difference
          ? "ActualTime"
          : end_time_difference > 0
          ? "Delay"
          : "Early"
      );
      setStartTime(start_time ? start_time : null);
      setLunchTime(lunch_time ? lunch_time : null);
      setAfterLunchTime(after_lunch_time ? after_lunch_time : null);
      setEndTime(end_time ? end_time : null);
      setStartTimeHour(
        start_time_difference
          ? Math.floor(Math.abs(start_time_difference) / 60)
          : 0
      );
      setStartTimeMinute(
        start_time_difference ? Math.abs(start_time_difference) % 60 : 0
      );
      setLunchTimeHour(
        lunch_time_difference
          ? Math.floor(Math.abs(lunch_time_difference) / 60)
          : 0
      );
      setLunchTimeMinute(
        lunch_time_difference ? Math.abs(lunch_time_difference) % 60 : 0
      );
      setAfterLunchTimeHour(
        after_lunch_time_difference
          ? Math.floor(Math.abs(after_lunch_time_difference) / 60)
          : 0
      );
      setAfterLunchTimeMinute(
        after_lunch_time_difference
          ? Math.abs(after_lunch_time_difference) % 60
          : 0
      );
      setEndTimeHour(
        end_time_difference ? Math.floor(Math.abs(end_time_difference) / 60) : 0
      );
      setEndTimeMinute(
        end_time_difference ? Math.abs(end_time_difference) % 60 : 0
      );
      setWorkingPeriodType(working_period_id ? "Single" : "All");
      setWorkingPeriodField(
        working_period_id
          ? workingPeriodOptions.find((item) => item.id === working_period_id)
          : null
      );
      setShopType(shop_id ? "Single" : "All");
      setShiftType(shift_id ? "Single" : "All");
      setShiftField(
        shift_id ? shiftOptions.find((item) => item.id === shift_id) : null
      );
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    if (divisionType === "Departments") {
      getAllWorkingPeriods();
    }
  }, [divisionType]);

  useEffect(() => {
    if (divisionType === "Shops") {
      if (shopId) {
        getAllShifts();
      }
    }
  }, [divisionType, shopId]);

  useEffect(() => {
    // Set States
    if (
      !isOptionsRetrieved &&
      ((divisionType === "Departments" && workingPeriodOptions) ||
        (divisionType === "Shops" && shiftOptions))
    ) {
      setIsOptionsRetrieved(true);
    }
  }, [shiftOptions, workingPeriodOptions]);

  useEffect(() => {
    if (arrangementId && isOptionsRetrieved) {
      getArrangementDetailsById();
    }
  }, [arrangementId, isOptionsRetrieved]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onArrangementActionDialogCanceled}
        onDialogConfirmed={onArrangementActionDialogConfirmed}
        // States
        dialogText={arrangementActionDialogText}
        showDialog={showArrangementActionDialog}
      />
      <ArrangementActionModalContent
        // Events
        onInputFieldChange={onInputFieldChange}
        onInputFieldKeyPressed={onInputFieldKeyPressed}
        onSubmitBtnClicked={onSubmitBtnClicked}
        // Functions
        checkIsFieldError={checkIsFieldError}
        getErrorFieldMessage={getErrorFieldMessage}
        // States
        afterLunchTime={afterLunchTime}
        afterLunchTimeHour={afterLunchTimeHour}
        afterLunchTimeMinute={afterLunchTimeMinute}
        afterLunchTimeType={afterLunchTimeType}
        divisionType={divisionType}
        endTime={endTime}
        endTimeHour={endTimeHour}
        endTimeMinute={endTimeMinute}
        endTimeType={endTimeType}
        formAlertText={formAlertText}
        formAlertType={formAlertType}
        lunchTime={lunchTime}
        lunchTimeHour={lunchTimeHour}
        lunchTimeMinute={lunchTimeMinute}
        lunchTimeType={lunchTimeType}
        shiftField={shiftField}
        shiftOptions={shiftOptions}
        shiftType={shiftType}
        shopType={shopType}
        shouldShowFormAlert={shouldShowFormAlert}
        startTime={startTime}
        startTimeHour={startTimeHour}
        startTimeMinute={startTimeMinute}
        startTimeType={startTimeType}
        workingPeriodField={workingPeriodField}
        workingPeriodOptions={workingPeriodOptions}
        workingPeriodType={workingPeriodType}
      />
    </div>
  );
}

export default ArrangementActionModalContainer;
