// Actions
import { onNumOfStaffChartActiveIndexChange } from "../../../redux/pages/dashboardPage/dashboardPageActions";

// Components
// Charts
import PieChartActiveShapeDark from "../shared/pieChartActiveShapeDark";
import PieChartActiveShapeLight from "../shared/pieChartActiveShapeLight";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Recharts
import { PieChart, Pie, ResponsiveContainer } from "recharts";

function NumOfStaffChart(props) {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Props
  const { itemsArr } = props;

  // Redux Store
  const activeIndex = useSelector(
    (state) => state.dashboardPage.numOfStaffChartActiveIndex
  );
  const themeMode = useSelector((state) => state.theme.themeMode);

  // Handle States
  if (itemsArr && itemsArr[0]) {
    for (let item of itemsArr) {
      item.name = item.group_name;
      item.value = parseInt(item.num_of_staffs);
    }
  }

  // Events
  // Events - Chart
  const onPieEnter = (_, index) => {
    // Update Redux Store
    dispatch(onNumOfStaffChartActiveIndexChange(index));
  };

  return (
    <ResponsiveContainer
      width={stylesConfig.pieChartContainerWidth}
      height={stylesConfig.pieChartContainerHeight}
    >
      <PieChart
        width={stylesConfig.pieChartWidth}
        height={stylesConfig.pieChartHeight}
      >
        <Pie
          // States
          activeIndex={activeIndex}
          activeShape={
            themeMode === "light"
              ? PieChartActiveShapeLight
              : PieChartActiveShapeDark
          }
          cx="50%"
          cy="50%"
          data={itemsArr}
          dataKey="value"
          fill={stylesConfig.mainTextColor}
          innerRadius={stylesConfig.pieChartInnerRadius}
          outerRadius={stylesConfig.pieChartOuterRadius}
          stroke={
            themeMode === "light"
              ? stylesConfig.contentBackgroundColorLight
              : stylesConfig.contentBackgroundColorDark
          }
          // Events
          onMouseEnter={onPieEnter}
        />
      </PieChart>
    </ResponsiveContainer>
  );
}

export default NumOfStaffChart;
