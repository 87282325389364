// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function CandidateInfoFormContactAndAddressContent(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // States
    addressCh,
    addressEn,
    areaField,
    areaOptions,
    districtField,
    districtOptions,
    email,
    phoneNum,
    // Events
    onInputFieldChange,
    onInputFieldKeyPressed,
    // Functions
    checkIsFieldError,
    getErrorFieldMessage,
  } = props;

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("聯絡方式")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={12} lg={6}>
          <TextField
            error={checkIsFieldError("phoneNum")}
            fullWidth
            helperText={getErrorFieldMessage("phoneNum")}
            label={t("電話號碼")}
            margin="dense"
            name="phoneNum"
            onChange={(event) =>
              onInputFieldChange("phoneNum", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={phoneNum}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            error={checkIsFieldError("email")}
            fullWidth
            helperText={getErrorFieldMessage("email")}
            label={t("電郵地址")}
            margin="dense"
            name="email"
            onChange={(event) =>
              onInputFieldChange("email", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={email}
            variant="standard"
          />
        </Grid>
      </Grid>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("住宅地址")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid className={classes.formAutoCompleteContainer} item xs={12} lg={6}>
          {areaOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option[t("area_name_ch")]}
              onChange={(event, value) =>
                onInputFieldChange("areaField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={areaOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("areaField")}
                  helperText={getErrorFieldMessage("areaField")}
                  label={t("區域")}
                  variant="standard"
                />
              )}
              value={areaField}
            />
          )}
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={12} lg={6}>
          {areaField && districtOptions ? (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option[t("district_name_ch")]}
              onChange={(event, value) =>
                onInputFieldChange("districtField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={districtOptions.filter(
                (option) => option.area_id === areaField.id
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("districtField")}
                  helperText={getErrorFieldMessage("districtField")}
                  label={t("地區")}
                  variant="standard"
                />
              )}
              value={districtField}
            />
          ) : (
            <Autocomplete
              disabled
              disablePortal
              options={[]}
              renderInput={(params) => (
                <TextField {...params} label={t("地區")} variant="standard" />
              )}
              value={null}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            error={checkIsFieldError("addressCh")}
            fullWidth
            helperText={getErrorFieldMessage("addressCh")}
            label={t("中文地址")}
            margin="dense"
            name="addressCh"
            onChange={(event) =>
              onInputFieldChange("addressCh", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={addressCh}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            error={checkIsFieldError("addressEn")}
            fullWidth
            helperText={getErrorFieldMessage("addressEn")}
            label={t("英文地址")}
            margin="dense"
            name="addressEn"
            onChange={(event) =>
              onInputFieldChange("addressEn", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={addressEn}
            variant="standard"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default CandidateInfoFormContactAndAddressContent;
