// Actions
import { staffLogin } from "../../../../redux/shared/staff/staffActions";
import {
  onFormSubmitCountIncrease,
  onStaffChange,
} from "../../../../redux/pages/staffProfilePage/staffProfilePageActions";

// Components
// Dialogs
import AlertDialog from "../../../dialogs/alertDialog";
// Forms
import StaffInfoFormBasicInfoContent from "./staffInfoFormBasicInfoContent";
import StaffInfoFormContactAndAddressContent from "./staffInfoFormContactAndAddressContent";
// Spacing Boxes
import SpacingBox from "../../../boxes/spacing/spacingBox";

// Configs
import keyConfig from "../../../../configs/keyConfig";
import stylesConfig from "../../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../../customHooks/getLanguage";

// Fetches
import { getStaffDetailFormOptionsFetch } from "../../../../fetches/formOptionFetches";
import {
  editStaffFetch,
  getStaffDetailsByIdFetch,
} from "../../../../fetches/staffFetches";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function StaffInfoFormContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const staffId = useSelector((state) => state.staffProfilePage.staffId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Date Fields
  const [dateBirth, setDateBirth] = useState(null);
  const [dateJoin, setDateJoin] = useState(null);
  const [dateResign, setDateResign] = useState(null);
  // Dialog
  const [staffInfoActionDialogText, setStaffInfoActionDialogText] =
    useState("");
  const [staffInfoActionDialogType, setStaffInfoActionDialogType] =
    useState(null);
  const [showStaffInfoActionDialog, setShowStaffInfoActionDialog] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Options Fields
  const [areaField, setAreaField] = useState(null);
  const [districtField, setDistrictField] = useState(null);
  const [genderTypeField, setGenderTypeField] = useState(null);
  // Options
  const [areaOptions, setAreaOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [genderTypeOptions, setGenderTypeOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Text Fields
  const [addressCh, setAddressCh] = useState("");
  const [addressEn, setAddressEn] = useState("");
  const [alias, setAlias] = useState("");
  const [email, setEmail] = useState("");
  const [hkid, setHkid] = useState("");
  const [nameCh, setNameCh] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [staffCode, setStaffCode] = useState("");

  // Events
  // Events - Dialogs
  const onStaffInfoActionDialogCanceled = () => {
    // Set States
    setShowStaffInfoActionDialog(false);
  };

  const onStaffInfoActionDialogConfirmed = () => {
    // Set States
    setShowStaffInfoActionDialog(false);

    switch (staffInfoActionDialogType) {
      case "EditStaff":
        editStaff();
        break;
      default:
        break;
    }
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "addressCh":
        setAddressCh(value);
        break;
      case "addressEn":
        setAddressEn(value);
        break;
      case "alias":
        setAlias(value);
        break;
      case "areaField":
        setAreaField(value);
        setDistrictField(
          districtOptions.find((item) => item.area_id === value.id)
        );

        removeErrorField("districtField");

        break;
      case "dateBirth":
        setDateBirth(value);
        break;
      case "dateJoin":
        setDateJoin(value);
        break;
      case "dateResign":
        setDateResign(value);
        break;
      case "districtField":
        setDistrictField(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "genderTypeField":
        setGenderTypeField(value);
        break;
      case "hkid":
        setHkid(value);
        break;
      case "nameCh":
        setNameCh(value);
        break;
      case "nameEn":
        setNameEn(value);
        break;
      case "phoneNum":
        setPhoneNum(value);
        break;
      case "staffCode":
        setStaffCode(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!dateBirth) {
      addToErrorFields("dateBirth", t("請先填寫 出生日期"));
      isError = true;
    }

    if (!genderTypeField) {
      addToErrorFields("genderTypeField", t("請先填寫 性別"));
      isError = true;
    }

    if (!hkid) {
      addToErrorFields("hkid", t("請先填寫 身分證編號"));
      isError = true;
    }

    if (!nameEn) {
      addToErrorFields("nameEn", t("請先填寫 英文全名"));
      isError = true;
    }

    if (!staffCode) {
      addToErrorFields("staffCode", t("請先填寫 員工編號"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayStaffInfoActionDialog(staffId ? "EditStaff" : "CreateStaff");
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const clearForm = () => {
    // Set States
    setHkid("");
    setStaffCode("");
    setGenderTypeField(null);
    setNameCh("");
    setNameEn("");
    setAlias("");
    setDateJoin(null);
    setDateResign(null);
    setDateBirth(null);
    setEmail("");
    setPhoneNum("");
    setDistrictField(null);
    setAddressCh("");
    setAddressEn("");
    setAreaField(null);

    clearErrorFields();
  };

  const displayStaffInfoActionDialog = (staffInfoActionType) => {
    // Set States
    setStaffInfoActionDialogType(staffInfoActionType);

    switch (staffInfoActionType) {
      case "CreateStaff":
        setStaffInfoActionDialogText(t("確認要新增 員工 嗎？"));
        break;
      case "EditStaff":
        setStaffInfoActionDialogText(t("確認要編輯 員工資料 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowStaffInfoActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  const updateStaffInfo = (staff, token) => {
    // Update Redux Store
    dispatch(onStaffChange(staff.id));
    dispatch(
      staffLogin(
        token,
        staff.alias,
        staff.dept_name_display,
        staff.full_name_en,
        staff.id
      )
    );

    // Local Storage
    localStorage.setItem(keyConfig.tokenKey, token);
  };

  // Functions - Mutations
  const editStaff = async () => {
    if (staffId) {
      const results = await editStaffFetch(
        token,
        staffId,
        hkid,
        staffCode,
        genderTypeField ? genderTypeField.id : null,
        nameCh,
        nameEn,
        alias,
        dateBirth,
        email,
        phoneNum,
        districtField ? districtField.id : null,
        addressCh,
        addressEn
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        if (results.token && results.staff) {
          const { staff, token } = results;

          updateStaffInfo(staff, token);
        }

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else if (results.isDuplicate) {
        showFormAlert("warning", t("此 員工編號 已存在"));
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getStaffDetailFormOptions = async () => {
    const results = await getStaffDetailFormOptionsFetch(token);

    // Set States
    setAreaOptions(results.areas ? results.areas : []);
    setDistrictOptions(results.districts ? results.districts : []);
    setGenderTypeOptions(results.genderTypes ? results.genderTypes : []);
  };

  const getStaffDetailsById = async () => {
    const results = await getStaffDetailsByIdFetch(token, staffId);

    if (results.staffDetails) {
      const {
        hkid,
        staff_code,
        gender_type_id,
        full_name_ch,
        full_name_en,
        alias,
        date_join,
        date_resign,
        date_birth,
        email,
        phone_num,
        district_id,
        address_ch,
        address_en,
        area_id,
      } = results.staffDetails;

      // Set States
      setHkid(hkid ? hkid : "");
      setStaffCode(staff_code ? staff_code : "");
      setGenderTypeField(
        gender_type_id
          ? genderTypeOptions.find((item) => item.id === gender_type_id)
          : null
      );
      setNameCh(full_name_ch ? full_name_ch : "");
      setNameEn(full_name_en ? full_name_en : "");
      setAlias(alias ? alias : "");
      setDateJoin(date_join ? date_join : null);
      setDateResign(date_resign ? date_resign : null);
      setDateBirth(date_birth ? date_birth : null);
      setEmail(email ? email : null);
      setPhoneNum(phone_num ? phone_num : "");
      setDistrictField(
        district_id
          ? districtOptions.find((item) => item.id === district_id)
          : null
      );
      setAddressCh(address_ch ? address_ch : "");
      setAddressEn(address_en ? address_en : "");
      setAreaField(
        area_id ? areaOptions.find((item) => item.id === area_id) : null
      );
    } else {
      // Set States
      setHkid("");
      setStaffCode("");
      setGenderTypeField(null);
      setNameCh("");
      setNameEn("");
      setAlias("");
      setDateJoin(null);
      setDateResign(null);
      setDateBirth(null);
      setEmail("");
      setPhoneNum("");
      setDistrictField(null);
      setAddressCh("");
      setAddressEn("");
      setAreaField(null);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    getStaffDetailFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && areaOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [areaOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (staffId) {
        getStaffDetailsById();
      } else {
        clearForm();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, staffId]);

  useEffect(() => {
    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());

    getStaffDetailsById();
  }, [token]);

  return (
    <>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onStaffInfoActionDialogCanceled}
        onDialogConfirmed={onStaffInfoActionDialogConfirmed}
        // States
        dialogText={staffInfoActionDialogText}
        showDialog={showStaffInfoActionDialog}
      />
      <StaffInfoFormBasicInfoContent
        // States
        alias={alias}
        dateBirth={dateBirth}
        dateJoin={dateJoin}
        dateResign={dateResign}
        genderTypeField={genderTypeField}
        genderTypeOptions={genderTypeOptions}
        hkid={hkid}
        nameCh={nameCh}
        nameEn={nameEn}
        staffCode={staffCode}
        // Events
        onInputFieldChange={onInputFieldChange}
        onInputFieldKeyPressed={onInputFieldKeyPressed}
        // Functions
        checkIsFieldError={checkIsFieldError}
        getErrorFieldMessage={getErrorFieldMessage}
      />
      <SpacingBox
        // Render
        height={stylesConfig.spacingBoxMarginBottom}
      />
      <StaffInfoFormContactAndAddressContent
        // States
        addressCh={addressCh}
        addressEn={addressEn}
        areaField={areaField}
        areaOptions={areaOptions}
        districtField={districtField}
        districtOptions={districtOptions}
        email={email}
        formAlertText={formAlertText}
        formAlertType={formAlertType}
        phoneNum={phoneNum}
        shouldShowFormAlert={shouldShowFormAlert}
        // Events
        onInputFieldChange={onInputFieldChange}
        onInputFieldKeyPressed={onInputFieldKeyPressed}
        onSubmitBtnClicked={onSubmitBtnClicked}
        // Functions
        checkIsFieldError={checkIsFieldError}
        getErrorFieldMessage={getErrorFieldMessage}
      />
    </>
  );
}

export default StaffInfoFormContainer;
