export const minutesToHoursAndMinutes = (timeInMinutes) => {
  const hours = Math.floor(Math.abs(timeInMinutes) / 60);
  const minutes = Math.abs(timeInMinutes) % 60;

  const hourDisplayStrCh = hours > 0 ? `${hours}小時` : "";
  const hourDisplayStrEn =
    hours > 0 ? (hours > 1 ? `${hours} Hours` : `${hours} Hour`) : "";
  const spacing = hours > 0 && minutes > 0 ? " " : "";
  const minuteDisplayStrCh = minutes > 0 ? `${minutes}分鐘` : "";
  const minuteDisplayStrEn =
    minutes > 0 ? (minutes > 1 ? `${minutes} Mins` : `${minutes} Min`) : "";

  return {
    timeStrCh: hourDisplayStrCh + spacing + minuteDisplayStrCh,
    timeStrEn: hourDisplayStrEn + spacing + minuteDisplayStrEn,
  };
};
