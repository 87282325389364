// Configs
import stylesConfig from "../../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../../customHooks/getLanguage";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// Icons
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

// Styles
import { useStandardItemBoxStyles } from "../../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function AttendanceBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    hoveredItemId,
    item,
    rosterDetails,
    // Events
    onItemClicked,
    onItemShowMenuBtnClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isHovered = hoveredItemId && hoveredItemId === item.id;

  return (
    <div
      className={
        isHovered ? classes.hoveredItemContainer : classes.itemContainer
      }
      onMouseEnter={() => onMouseEnterItem(item.id)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div
        className={classes.itemContentContainer}
        onClick={(event) => onItemClicked(item.id, item, event.currentTarget)}
        onContextMenu={(event) => {
          event.preventDefault();
          onItemClicked(item.id, item, event.currentTarget);
        }}
      >
        <div className={classes.itemContentSubContainer}>
          <Typography
            align={"left"}
            color={isHovered ? stylesConfig.mainTextColor : null}
            variant="body1"
          >
            {item.shop_code
              ? `${item.shop_code} - `
              : item.working_place_name_en_short
              ? `${item.working_place_name_en_short} - `
              : `${item.address} - `}
            :{item.time_attend}
          </Typography>
          <Typography
            align={"left"}
            color={stylesConfig.greyTextColor}
            variant="body2"
          >
            {"("}
            {rosterDetails.roster_time &&
            (rosterDetails.shop_code ||
              rosterDetails.working_place_name_en_short ||
              rosterDetails.address)
              ? rosterDetails.shop_code
                ? `${rosterDetails.shop_code} - `
                : rosterDetails.working_place_name_en_short
                ? `${rosterDetails.working_place_name_en_short} - `
                : `${rosterDetails.address} - `
              : null}
            {!rosterDetails.is_attendance_required
              ? t("無需簽到")
              : rosterDetails.roster_time
              ? `${rosterDetails.roster_time})`
              : `${t("不適用")})`}
          </Typography>
        </div>
        <div className={classes.itemContentSubContainer}>
          {item.is_exception ||
          (item.is_on_time && item.is_location_correct) ? (
            <Typography
              align={"left"}
              color={isHovered ? stylesConfig.mainTextColor : null}
              variant="body1"
            >
              {item.is_exception ? t("已豁免") : t("正常")}
            </Typography>
          ) : (
            <Typography
              align={"left"}
              color={stylesConfig.redTextColor}
              variant="body1"
            >
              {!item.is_on_time &&
                `${item.time_difference > 0 ? t("遲到") : t("早退")} ${
                  item.time_difference ? Math.abs(item.time_difference) : 0
                } ${t("分鐘s")}`}
              {!item.is_on_time && !item.is_location_correct && " / "}
              {!item.is_location_correct && t("地點錯誤")}
            </Typography>
          )}
          {item.is_for_count && (
            <Typography
              align={"left"}
              color={stylesConfig.greyTextColor}
              variant="body2"
            >
              {t("用作考勤")}
            </Typography>
          )}
        </div>
      </div>
      <Stack direction="row" spacing={1}>
        <IconButton
          onClick={(event) =>
            onItemShowMenuBtnClicked(item.id, item, event.currentTarget)
          }
        >
          <MenuRoundedIcon />
        </IconButton>
      </Stack>
    </div>
  );
}

export default AttendanceBox;
