// Pages
import ApplicationsPage from "./pages/applicationsPage";
import AttendancesPage from "./pages/attendancesPage";
import CandidateProfilePage from "./pages/candidateProfilePage";
import CompsAndBrandsPage from "./pages/compsAndBrandsPage";
import CreateStaffPage from "./pages/createStaffPage";
import DashboardPage from "./pages/dashboardPage";
import DayOffTypesPage from "./pages/dayOffTypesPage";
import DeptsAndShopsPage from "./pages/deptsAndShopsPage";
import LoginPage from "./pages/loginPage";
import PermissionsPage from "./pages/permissionsPage";
import RostersPage from "./pages/rostersPage";
import SalariesPage from "./pages/salariesPage";
import StaffProfilePage from "./pages/staffProfilePage";
import TitlesPage from "./pages/titlesPage";
import WorkingPeriodsAndPlacesPage from "./pages/workingPeriodsAndPlacesPage";

// React-Redux
import { useSelector } from "react-redux";

// React-Router
import { Navigate, Route, Routes } from "react-router-dom";

function Router() {
  // Redux Store
  const token = useSelector((state) => state.staff.token);

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route
        path="/applications"
        element={token ? <ApplicationsPage /> : <Navigate to="/" />}
      />
      <Route
        path="/attendances"
        element={token ? <AttendancesPage /> : <Navigate to="/" />}
      />
      <Route
        path="/compsAndBrands"
        element={token ? <CompsAndBrandsPage /> : <Navigate to="/" />}
      />
      <Route
        path="/candidateProfile"
        element={token ? <CandidateProfilePage /> : <Navigate to="/" />}
      />
      <Route
        path="/createStaff"
        element={token ? <CreateStaffPage /> : <Navigate to="/" />}
      />
      <Route
        path="/dashboard"
        element={token ? <DashboardPage /> : <Navigate to="/" />}
      />
      <Route
        path="/dayOffTypes"
        element={token ? <DayOffTypesPage /> : <Navigate to="/" />}
      />
      <Route
        path="/deptsAndShops"
        element={token ? <DeptsAndShopsPage /> : <Navigate to="/" />}
      />
      <Route
        path="/permissions"
        element={token ? <PermissionsPage /> : <Navigate to="/" />}
      />
      <Route
        path="/rosters"
        element={token ? <RostersPage /> : <Navigate to="/" />}
      />
      <Route
        path="/salaries"
        element={token ? <SalariesPage /> : <Navigate to="/" />}
      />
      <Route
        path="/staffProfile"
        element={token ? <StaffProfilePage /> : <Navigate to="/" />}
      />
      <Route
        path="/titles"
        element={token ? <TitlesPage /> : <Navigate to="/" />}
      />
      <Route
        path="/workingPeriodsAndPlaces"
        element={token ? <WorkingPeriodsAndPlacesPage /> : <Navigate to="/" />}
      />
    </Routes>
  );
}

export default Router;
