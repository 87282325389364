// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  createSkillFetch,
  editSkillFetch,
  getSkillDetailsByIdFetch,
} from "../../../fetches/skillFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function CandidateSkillActionModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
    // Functions
    displayFileViewModal,
    // States
    skillId,
  } = props;

  // Redux Store
  const candidateId = useSelector(
    (state) => state.candidateProfilePage.candidateId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [candidateSkillActionDialogText, setCandidateSkillActionDialogText] =
    useState("");
  const [candidateSkillActionDialogType, setCandidateSkillActionDialogType] =
    useState(null);
  const [showCandidateSkillActionDialog, setShowCandidateSkillActionDialog] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Text Fields
  const [skillDescription, setSkillDescription] = useState("");
  const [skillName, setSkillName] = useState("");

  // Events
  // Events - Dialogs
  const onCandidateSkillActionDialogCanceled = () => {
    // Set States
    setShowCandidateSkillActionDialog(false);
  };

  const onCandidateSkillActionDialogConfirmed = () => {
    switch (candidateSkillActionDialogType) {
      case "CreateCandidateSkill":
        createSkill();
        break;
      case "EditCandidateSkill":
        editSkill();
        break;
      default:
        break;
    }

    // Set States
    setShowCandidateSkillActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "skillDescription":
        setSkillDescription(value);
        break;
      case "skillName":
        setSkillName(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onShowFilesBtnClicked = () => {
    displayFileViewModal();
  };

  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!skillName) {
      addToErrorFields("skillName", t("請先填寫 技能名稱"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayCandidateSkillActionDialog(
      skillId ? "EditCandidateSkill" : "CreateCandidateSkill"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displayCandidateSkillActionDialog = (candidateSkillActionType) => {
    // Set States
    setCandidateSkillActionDialogType(candidateSkillActionType);

    switch (candidateSkillActionType) {
      case "CreateCandidateSkill":
        setCandidateSkillActionDialogText(t("確認要新增 技能 嗎？"));
        break;
      case "EditCandidateSkill":
        setCandidateSkillActionDialogText(t("確認要編輯 技能 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowCandidateSkillActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createSkill = async () => {
    const results = await createSkillFetch(
      token,
      candidateId,
      skillName,
      skillDescription
    );

    if (results.success) {
      onModalClosed();
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editSkill = async () => {
    const results = await editSkillFetch(
      token,
      skillId,
      skillName,
      skillDescription
    );

    if (results.success) {
      onModalClosed();
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  // Functions = Queries
  const getSkillDetailsById = async () => {
    const results = await getSkillDetailsByIdFetch(token, skillId);

    if (results.skillDetails) {
      const { skill_name, skill_description } = results.skillDetails;

      // Set States
      setSkillName(skill_name ? skill_name : "");
      setSkillDescription(skill_description ? skill_description : "");
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    if (skillId) {
      getSkillDetailsById();
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [skillId]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onCandidateSkillActionDialogCanceled}
        onDialogConfirmed={onCandidateSkillActionDialogConfirmed}
        // States
        dialogText={candidateSkillActionDialogText}
        showDialog={showCandidateSkillActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {skillId ? t("編輯技能") : t("新增技能")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <TextField
              autoFocus
              error={checkIsFieldError("skillName")}
              fullWidth
              helperText={getErrorFieldMessage("skillName")}
              label={t("技能名稱")}
              margin="dense"
              name="skillName"
              onChange={(event) =>
                onInputFieldChange("skillName", event.target.value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              value={skillName}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoFocus
              error={checkIsFieldError("skillDescription")}
              fullWidth
              helperText={getErrorFieldMessage("skillDescription")}
              label={t("描述 (可不填)")}
              margin="dense"
              multiline
              name="skillDescription"
              onChange={(event) =>
                onInputFieldChange("skillDescription", event.target.value)
              }
              value={skillDescription}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              {t("確定")}
            </Button>
          </Grid>
          {/* Alert */}
          {shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onShowFilesBtnClicked}
              variant="text"
              sx={formSubmitBtnStyles}
            >
              {t("查看證明文件")}
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default CandidateSkillActionModal;
