// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/rostersPage/rostersPageActions";

// Components
// Dialogs
import ConfirmDialog from "../../dialogs/confirmDialog";
// Spacing Boxes
import SpacingBox from "../../boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  createShiftFetch,
  editShiftFetch,
  getShiftDetailsByIdFetch,
} from "../../../fetches/shiftFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ShiftActionModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Props
  const {
    // States
    shiftId,
    // Events
    onModalClosed,
  } = props;

  // Redux Store
  const shopId = useSelector((state) => state.rostersPage.shopId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [shiftActionDialogOptionValue, setShiftActionDialogOptionValue] =
    useState(null);
  const [shiftActionDialogTitle, setShiftActionDialogTitle] = useState("");
  const [shiftActionDialogType, setShiftActionDialogType] = useState(null);
  const [showShiftActionDialog, setShowShiftActionDialog] = useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Time Fields
  const [afterLunchTime, setAfterLunchTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [lunchTime, setLunchTime] = useState(null);
  const [startTime, setStartTime] = useState(null);
  // Text Fields
  const [shiftCode, setShiftCode] = useState("");

  // Hanlde States
  const afterLunchTimeVar = afterLunchTime ? new Date(afterLunchTime) : null;
  const endTimeVar = endTime ? new Date(endTime) : null;
  const lunchTimeVar = lunchTime ? new Date(lunchTime) : null;
  const startTimeVar = startTime ? new Date(startTime) : null;

  // Events
  // Events - Dialogs
  const onShiftActionDialogCanceled = () => {
    // Set States
    setShowShiftActionDialog(false);
  };

  const onShiftActionDialogConfirmed = () => {
    if (shiftActionDialogOptionValue) {
      switch (shiftActionDialogType) {
        case "CreateShift":
          createShift(shiftActionDialogOptionValue === t("所有店舖"));
          break;
        case "EditShift":
          editShift(shiftActionDialogOptionValue === t("所有店舖"));
          break;
        default:
          break;
      }

      // Set States
      setShowShiftActionDialog(false);

      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());
    }
  };

  const onShiftActionDialogOptionChanged = (value) => {
    // Set States
    setShiftActionDialogOptionValue(value);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "afterLunchTime":
        setAfterLunchTime(value);
        break;
      case "endTime":
        setEndTime(value);
        break;
      case "lunchTime":
        setLunchTime(value);
        break;
      case "shiftCode":
        setShiftCode(value);
        break;
      case "startTime":
        setStartTime(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!startTime) {
      addToErrorFields("startTime", t("請先填寫 工作時間"));
      isError = true;
    }

    if (!endTime) {
      addToErrorFields("endTime", t("請先填寫 工作時間"));
      isError = true;
    }

    if (lunchTime && !afterLunchTime) {
      addToErrorFields("afterLunchTime", t("請先填寫 午飯時間"));
      isError = true;
    }

    if (afterLunchTime && !lunchTime) {
      addToErrorFields("lunchTime", t("請先填寫 午飯時間"));
      isError = true;
    }

    if (!shiftCode) {
      addToErrorFields("shiftCode", t("請先填寫 值更代號"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayShiftActionDialog(shiftId ? "EditShift" : "CreateShift");
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displayShiftActionDialog = (shiftActionType) => {
    // Set States
    setShiftActionDialogType(shiftActionType);

    switch (shiftActionType) {
      case "CreateShift":
        setShiftActionDialogTitle(t("確認要新增 值更 嗎？"));
        break;
      case "EditShift":
        setShiftActionDialogTitle(t("確認要編輯 值更 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowShiftActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createShift = async (shouldApplyToAllShops) => {
    const results = await createShiftFetch(
      token,
      shopId,
      shiftCode,
      startTime,
      lunchTime,
      afterLunchTime,
      endTime,
      shouldApplyToAllShops
    );

    if (results.success) {
      onModalClosed();
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editShift = async (shouldApplyToAllShops) => {
    const results = await editShiftFetch(
      token,
      shiftId,
      shiftCode,
      startTime,
      lunchTime,
      afterLunchTime,
      endTime,
      shouldApplyToAllShops
    );

    if (results.success) {
      onModalClosed();
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  // Functions = Queries
  const getShiftDetailsById = async () => {
    const results = await getShiftDetailsByIdFetch(token, shiftId);

    if (results.shiftDetails) {
      const {
        shift_code,
        default_start_time,
        default_lunch_time,
        default_after_lunch_time,
        default_end_time,
      } = results.shiftDetails;

      // Set States
      setShiftCode(shift_code);
      setStartTime(default_start_time);
      setLunchTime(default_lunch_time);
      setAfterLunchTime(default_after_lunch_time);
      setEndTime(default_end_time);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    if (shiftId) {
      getShiftDetailsById();
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [shiftId]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Dialog */}
      <ConfirmDialog
        // Events
        onDialogClosed={onShiftActionDialogCanceled}
        onDialogConfirmed={onShiftActionDialogConfirmed}
        onOptionChange={onShiftActionDialogOptionChanged}
        // States
        dialogTitle={shiftActionDialogTitle}
        options={[t("所有店舖"), t("只限此店舖")]}
        optionValue={shiftActionDialogOptionValue}
        showDialog={showShiftActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {shiftId ? "編輯值更" : "新增值更"}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid className={classes.formAutoCompleteContainer} item xs={12}>
            <TextField
              autoFocus
              error={checkIsFieldError("shiftCode")}
              fullWidth
              helperText={getErrorFieldMessage("shiftCode")}
              label={t("值更代號")}
              margin="dense"
              name="shiftCode"
              onChange={(event) =>
                onInputFieldChange("shiftCode", event.target.value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              required
              value={shiftCode}
              variant="standard"
            />
          </Grid>
          <Grid className={classes.formAutoCompleteContainer} item xs={5}>
            <TimePicker
              label={t("工作時間")}
              onChange={(value) => onInputFieldChange("startTime", value)}
              slotProps={{
                textField: {
                  error: checkIsFieldError("startTime"),
                  helperText: getErrorFieldMessage("startTime"),
                },
              }}
              value={startTimeVar}
            />
          </Grid>
          <Grid className={classes.formTwinInputContainerText} item xs={2}>
            <Typography align={"left"} variant="body1">
              {t("至")}
            </Typography>
          </Grid>
          <Grid className={classes.formAutoCompleteContainer} item xs={5}>
            <TimePicker
              label={t("工作時間")}
              onChange={(value) => onInputFieldChange("endTime", value)}
              slotProps={{
                textField: {
                  error: checkIsFieldError("endTime"),
                  helperText: getErrorFieldMessage("endTime"),
                },
              }}
              value={endTimeVar}
            />
          </Grid>
          <Grid item xs={12}>
            <SpacingBox
              // Render
              height={stylesConfig.spacingBoxMarginBottomSmall}
            />
          </Grid>
          <Grid className={classes.formAutoCompleteContainer} item xs={5}>
            <TimePicker
              label={t("午飯時間")}
              onChange={(value) => onInputFieldChange("lunchTime", value)}
              slotProps={{
                textField: {
                  error: checkIsFieldError("lunchTime"),
                  helperText: getErrorFieldMessage("lunchTime"),
                },
              }}
              value={lunchTimeVar}
            />
          </Grid>
          <Grid className={classes.formTwinInputContainerText} item xs={2}>
            <Typography align={"left"} variant="body1">
              {t("至")}
            </Typography>
          </Grid>
          <Grid className={classes.formAutoCompleteContainer} item xs={5}>
            <TimePicker
              label={t("午飯時間")}
              onChange={(value) => onInputFieldChange("afterLunchTime", value)}
              slotProps={{
                textField: {
                  error: checkIsFieldError("afterLunchTime"),
                  helperText: getErrorFieldMessage("afterLunchTime"),
                },
              }}
              value={afterLunchTimeVar}
            />
          </Grid>
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              {t("確定")}
            </Button>
          </Grid>
          {/* Alert */}
          {shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default ShiftActionModal;
