// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/attendancesPage/attendancesPageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getAttendanceTypeDetailFormOptionsFetch } from "../../../fetches/formOptionFetches";
import {
  editAttendanceTypeFetch,
  getAttendanceTypeDetailsByIdFetch,
} from "../../../fetches/attendanceTypeFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function AttendanceTypeDetailForm() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const ruleAttendanceTypeId = useSelector(
    (state) => state.attendancesPage.ruleAttendanceTypeId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [
    attendanceTypeDetailActionDialogText,
    setAttendanceTypeDetailActionDialogText,
  ] = useState("");
  const [
    attendanceTypeDetailActionDialogType,
    setAttendanceTypeDetailActionDialogType,
  ] = useState(null);
  const [
    showAttendanceTypeDetailActionDialog,
    setShowAttendanceTypeDetailActionDialog,
  ] = useState(false);
  // Option Fields
  const [divisionField, setDivisionField] = useState([]);
  // Options
  const [divisionOptions, setDivisionOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);

  // Events
  // Events - Dialogs
  const onAttendanceTypeDetailActionDialogCanceled = () => {
    // Set States
    setShowAttendanceTypeDetailActionDialog(false);
  };

  const onAttendanceTypeDetailActionDialogConfirmed = () => {
    switch (attendanceTypeDetailActionDialogType) {
      case "EditAttendanceType":
        editAttendanceType();
        break;
      default:
        break;
    }

    // Set States
    setShowAttendanceTypeDetailActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "divisionField":
        setDivisionField(value);
        break;
      default:
        break;
    }
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    displayAttendanceTypeDetailActionDialog("EditAttendanceType");
  };

  // Functions
  // Functions - Normal
  const clearForm = () => {
    // Set States
    setDivisionField([]);
  };

  const displayAttendanceTypeDetailActionDialog = (
    attendanceTypeDetailActionType
  ) => {
    // Set States
    setAttendanceTypeDetailActionDialogType(attendanceTypeDetailActionType);

    switch (attendanceTypeDetailActionType) {
      case "EditAttendanceType":
        setAttendanceTypeDetailActionDialogText(t("確認要編輯 規則資料 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowAttendanceTypeDetailActionDialog(true);
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const editAttendanceType = async () => {
    if (ruleAttendanceTypeId) {
      const results = await editAttendanceTypeFetch(
        token,
        ruleAttendanceTypeId,
        divisionField[0] ? divisionField.map((item) => item.id) : null
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else if (results.isDuplicate) {
        showFormAlert("warning", t("此 規則 已存在"));
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getAttendanceTypeDetailFormOptions = async () => {
    const results = await getAttendanceTypeDetailFormOptionsFetch(token);

    // Set States
    setDivisionOptions(results.divisions ? results.divisions : []);
  };

  const getAttendanceTypeDetailsById = async () => {
    const results = await getAttendanceTypeDetailsByIdFetch(
      token,
      ruleAttendanceTypeId
    );

    if (results.attendanceTypeDetails) {
      const { divisions } = results.attendanceTypeDetails;

      // Set States
      if (divisions) {
        let divisionsArr = [];

        for (let divisionItem of divisions) {
          if (divisionOptions.find((item) => item.id === divisionItem.id)) {
            divisionsArr.push(
              divisionOptions.find((item) => item.id === divisionItem.id)
            );
          }
        }

        setDivisionField(divisionsArr);
      } else {
        setDivisionField([]);
      }
    } else {
      // Set States
      setDivisionField([]);
    }
  };

  // Life Cycle
  useEffect(() => {
    getAttendanceTypeDetailFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && divisionOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [divisionOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (ruleAttendanceTypeId) {
        getAttendanceTypeDetailsById();
      } else {
        clearForm();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, ruleAttendanceTypeId]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onAttendanceTypeDetailActionDialogCanceled}
        onDialogConfirmed={onAttendanceTypeDetailActionDialogConfirmed}
        // States
        dialogText={attendanceTypeDetailActionDialogText}
        showDialog={showAttendanceTypeDetailActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("規則設定s")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid className={classes.formAutoCompleteContainer} item xs={12}>
          {divisionField && divisionOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option[t("division_name_ch")]}
              multiple
              onChange={(event, value) =>
                onInputFieldChange("divisionField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={divisionOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("需要簽到")}
                  variant="standard"
                />
              )}
              value={divisionField}
            />
          )}
        </Grid>
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <Button
            onClick={onSubmitBtnClicked}
            variant="contained"
            sx={formSubmitBtnStyles}
          >
            {t("修改")}
          </Button>
        </Grid>
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default AttendanceTypeDetailForm;
