const initialState = {
  formSubmitCount: 0,
  permissionId: null,
  permissionItem: null,
  permissionGroupId: null,
  permissionGroupItem: null,
  permissionTypeId: null,
  permissionTypeItem: null,
  tabItem: "Permissions",
};

export const permissionsPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_PERMISSIONS_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_PERMISSIONS_PAGE_PERMISSION_CHANGE":
      const { permissionId } = action;
      return {
        ...state,
        permissionId,
      };
    case "ON_PERMISSIONS_PAGE_PERMISSION_ITEM_CHANGE":
      const { permissionItem } = action;
      return {
        ...state,
        permissionItem,
      };
    case "ON_PERMISSIONS_PAGE_PERMISSION_GROUP_CHANGE":
      const { permissionGroupId } = action;
      return {
        ...state,
        permissionGroupId,
      };
    case "ON_PERMISSIONS_PAGE_PERMISSION_GROUP_ITEM_CHANGE":
      const { permissionGroupItem } = action;
      return {
        ...state,
        permissionGroupItem,
      };
    case "ON_PERMISSIONS_PAGE_PERMISSION_TYPE_CHANGE":
      const { permissionTypeId } = action;
      return {
        ...state,
        permissionTypeId,
      };
    case "ON_PERMISSIONS_PAGE_PERMISSION_TYPE_ITEM_CHANGE":
      const { permissionTypeItem } = action;
      return {
        ...state,
        permissionTypeItem,
      };
    case "ON_PERMISSIONS_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    default:
      return state;
  }
};
