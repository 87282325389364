export function onActiveStepDecrease() {
  return {
    type: "ON_CREATE_STAFF_PAGE_ACTIVE_STEP_DECREASE",
  };
}

export function onActiveStepIncrease() {
  return {
    type: "ON_CREATE_STAFF_PAGE_ACTIVE_STEP_INCREASE",
  };
}
export function onActiveStepReset() {
  return {
    type: "ON_CREATE_STAFF_PAGE_ACTIVE_STEP_RESET",
  };
}
