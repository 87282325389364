export const searchOptions = [
  { field: "All", value: "全部" },
  { field: "Brand", value: "品牌" },
  { field: "Company", value: "公司" },
  { field: "Department", value: "部門" },
  { field: "Shop", value: "店鋪" },
  { field: "Staff", value: "員工" },
  { field: "Candidate", value: "應徵者" },
  { field: "Title", value: "職位" },
];
