// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import ConditionBoxesContainer from "../components/boxes/dashboardPage/conditionBoxesContainer";
import StaffBoxesContainer from "../components/boxes/dashboardPage/staffBoxesContainer";
import StaffStatisticsBoxesContainer from "../components/boxes/dashboardPage/staffStatisticsBoxesContainer";
// Charts
import ContentContainer from "../components/boxes/dashboardPage/contentContainer";
// Headers
import DashboardPageHeader from "../components/headers/dashboardPage/dashboardPageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function DashboardPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const tabItem = useSelector((state) => state.dashboardPage.tabItem);

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("Dashboard"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <DashboardPageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        {tabItem === "Summary" && (
          <>
            <Grid item xs={12} md={6} lg={4}>
              <StaffBoxesContainer
                // States
                typeOfStaffs={"Onboarding"}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <StaffBoxesContainer
                // States
                typeOfStaffs={"Resignation"}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <StaffBoxesContainer
                // States
                typeOfStaffs={"PassingProbation"}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <StaffBoxesContainer
                // States
                typeOfStaffs={"Birthday"}
              />
            </Grid>
          </>
        )}
        {tabItem === "Statistics" && (
          <>
            <Grid item xs={12} md={4} lg={3}>
              <StaffStatisticsBoxesContainer />
              <SpacingBox
                // Render
                height={stylesConfig.spacingBoxMarginBottom}
              />
              <ConditionBoxesContainer />
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <ContentContainer />
            </Grid>
          </>
        )}
      </Grid>
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
    </Container>
  );
}

export default DashboardPage;
