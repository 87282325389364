// Components
// Boxes
import StaffStatisticsBox from "./staffStatisticsBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Fetches
import { getStaffStatisticsFetch } from "../../../fetches/staffStatisticsFetches";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function StaffStatisticsBoxesContainer() {
  // Hooks
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const statisticsTypeValue = useSelector(
    (state) => state.dashboardPage.conditionStatisticsTypeValue
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [staffStatistics, setStaffStatistics] = useState(null);

  // Handle States
  const itemsArr = staffStatistics;

  // Functions
  // Functions = Queries
  const getStaffStatistics = async () => {
    const results = await getStaffStatisticsFetch(token, statisticsTypeValue);

    // Set States
    setStaffStatistics(
      results.staffStatistics ? results.staffStatistics : null
    );
  };

  // Life Cycle
  useEffect(() => {
    if (statisticsTypeValue) {
      getStaffStatistics();
    }
  }, [statisticsTypeValue]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            現有員工
          </Typography>
        </div>
      </div>
      {/* Content */}
      {itemsArr ? (
        itemsArr.map((item, index) => (
          <StaffStatisticsBox
            key={index}
            // States
            item={item}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            未有員工資料
          </Typography>
        </div>
      )}
    </div>
  );
}

export default StaffStatisticsBoxesContainer;
