// Configs
import apisConfig from "../configs/apisConfig";

export const createWorkingPeriodFetch = async (
  token,
  workingPeriodCode,
  isDefault,
  isActive
) => {
  try {
    const queryRoute = "/workingPeriod/createWorkingPeriod";

    const reqBody = {
      workingPeriodCode,
      isDefault,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteOrRestoreWorkingPeriodFetch = async (
  token,
  workingPeriodId,
  typeOfWorkingPeriods
) => {
  try {
    const queryRoute = `/workingPeriod/deleteOrRestoreWorkingPeriod/${workingPeriodId}`;

    const reqBody = { typeOfWorkingPeriods };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editWorkingPeriodFetch = async (
  token,
  workingPeriodId,
  workingPeriodCode,
  isDefault,
  isActive
) => {
  try {
    const queryRoute = `/workingPeriod/editWorkingPeriod/${workingPeriodId}`;

    const reqBody = {
      workingPeriodCode,
      isDefault,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editWorkingPeriodSetIsDefaultFetch = async (
  token,
  workingPeriodId,
  isDefault
) => {
  try {
    const queryRoute = `/workingPeriod/editWorkingPeriodSetIsDefault/${workingPeriodId}`;

    const reqBody = {
      isDefault,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllWorkingPeriodsFetch = async (
  token,
  typeOfWorkingPeriods
) => {
  try {
    const queryRoute = "/workingPeriod/getAllWorkingPeriods";

    const reqBody = {
      typeOfWorkingPeriods,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllWorkingPeriodsWithWorkingHoursFetch = async (
  token,
  typeOfWorkingPeriods
) => {
  try {
    const queryRoute = "/workingPeriod/getAllWorkingPeriodsWithWorkingHours";

    const reqBody = {
      typeOfWorkingPeriods,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getWorkingPeriodDetailsByIdFetch = async (
  token,
  workingPeriodId
) => {
  try {
    const queryRoute = `/workingPeriod/getWorkingPeriodDetailsById/${workingPeriodId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
