// Actions
import {
  onPermissionChange,
  onPermissionItemChange,
  onPermissionGroupChange,
  onPermissionGroupItemChange,
  onPermissionTypeChange,
  onPermissionTypeItemChange,
} from "../../../redux/pages/permissionsPage/permissionsPageActions";

// Components
// Boxes
import PermissionGroupBox from "./permissionGroupBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import PermissionGroupActionMenu from "../../menus/permissionsPage/permissionGroupActionMenu";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deletePermissionGroupFetch,
  getAllPermissionGroupsByPermissionIdFetch,
} from "../../../fetches/permissionGroupFetches";
import { getAllPermissionsByPermissionTypeIdFetch } from "../../../fetches/permissionFetches";
import { getAllPermissionTypesFetch } from "../../../fetches/permissionTypeFetches";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function PermissionGroupBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.permissionsPage.formSubmitCount
  );
  const language = useSelector((state) => state.language.language);
  const permissionId = useSelector(
    (state) => state.permissionsPage.permissionId
  );
  const permissionItem = useSelector(
    (state) => state.permissionsPage.permissionItem
  );
  const permissionGroupId = useSelector(
    (state) => state.permissionsPage.permissionGroupId
  );
  const permissionTypeId = useSelector(
    (state) => state.permissionsPage.permissionTypeId
  );
  const permissionTypeItem = useSelector(
    (state) => state.permissionsPage.permissionTypeItem
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [permissionGroups, setPermissionGroups] = useState(null);
  const [permissionTypes, setPermissionTypes] = useState(null);
  const [permissions, setPermissions] = useState(null);
  // Dialog
  const [permissionGroupActionDialogText, setPermissionGroupActionDialogText] =
    useState("");
  const [permissionGroupActionDialogType, setPermissionGroupActionDialogType] =
    useState(null);
  const [showPermissionGroupActionDialog, setShowPermissionGroupActionDialog] =
    useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showPermissionGroupActionMenu, setShowPermissionGroupActionMenu] =
    useState(null);

  // Handle States
  const currentItemId = permissionGroupId;
  const itemsArr = permissionGroups;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onPermissionGroupChange(itemId));
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowPermissionGroupActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowPermissionGroupActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onPermissionGroupActionDialogCanceled = () => {
    // Set States
    setShowPermissionGroupActionDialog(false);
  };

  const onPermissionGroupActionDialogConfirmed = () => {
    switch (permissionGroupActionDialogType) {
      case "DeletePermissionGroup":
        deletePermissionGroup();
        break;
      default:
        break;
    }

    // Set States
    setShowPermissionGroupActionDialog(false);
  };

  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "permission":
        dispatch(onPermissionChange(value.id));
        break;
      // Update Redux Store
      case "permissionType":
        dispatch(onPermissionTypeChange(value.id));
        break;
      default:
        break;
    }
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Update Redux Store
    dispatch(onPermissionGroupChange(null));
  };

  // Functions
  // Functions - Normal
  const displayPermissionGroupActionDialog = (permissionGroupActionType) => {
    // Set States
    setPermissionGroupActionDialogType(permissionGroupActionType);

    switch (permissionGroupActionType) {
      case "DeletePermissionGroup":
        setPermissionGroupActionDialogText(t("確認要刪除 p群組 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowPermissionGroupActionDialog(true);
  };

  // Functions - Mutations
  const deletePermissionGroup = async () => {
    const results = await deletePermissionGroupFetch(token, currentItemId);

    if (results.success) {
      getAllPermissionGroups(true);
    }
  };

  // Functions = Queries
  const getAllPermissionGroups = async (
    shoulddUpdateCurrentPermissionGroupId
  ) => {
    const results = await getAllPermissionGroupsByPermissionIdFetch(
      token,
      permissionId
    );

    // Set States
    setPermissionGroups(
      results.permissionGroups ? results.permissionGroups : null
    );

    // Update Redux Store
    if (
      results.permissionGroups &&
      (shoulddUpdateCurrentPermissionGroupId ||
        !results.permissionGroups.some((item) => item.id === permissionGroupId))
    ) {
      dispatch(onPermissionGroupChange(results.permissionGroups[0].id));
    }
  };

  const getAllPermissionsByPermissionTypeId = async (
    shoulddUpdateCurrentPermissionId
  ) => {
    const results = await getAllPermissionsByPermissionTypeIdFetch(
      token,
      permissionTypeId
    );

    // Set States
    setPermissions(results.permissions ? results.permissions : null);

    // Update Redux Store
    if (
      results.permissions &&
      (shoulddUpdateCurrentPermissionId ||
        !results.permissions.some((item) => item.id === permissionId))
    ) {
      dispatch(onPermissionChange(results.permissions[0].id));
    }
  };

  const getAllPermissionTypes = async () => {
    const results = await getAllPermissionTypesFetch(token);

    // Set States
    setPermissionTypes(
      results.permissionTypes ? results.permissionTypes : null
    );

    // Update Redux Store
    if (results.permissionTypes && !permissionTypeId) {
      dispatch(onPermissionTypeChange(results.permissionTypes[0].id));
    }
  };

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    if (permissionTypes && permissionTypeId) {
      dispatch(
        onPermissionTypeItemChange(
          permissionTypes.find((item) => item.id === permissionTypeId)
        )
      );
    }
  }, [permissionTypes, permissionTypeId]);

  useEffect(() => {
    // Update Redux Store
    if (permissions && permissionId) {
      dispatch(
        onPermissionItemChange(
          permissions.find((item) => item.id === permissionId)
        )
      );
    }
  }, [permissions, permissionId]);

  useEffect(() => {
    // Update Redux Store
    if (permissionGroups && permissionGroupId) {
      dispatch(
        onPermissionGroupItemChange(
          permissionGroups.find((item) => item.id === permissionGroupId)
        )
      );
    }
  }, [permissionGroups, permissionGroupId]);

  useEffect(() => {
    getAllPermissionTypes();
  }, [formSubmitCount]);

  useEffect(() => {
    if (permissionTypeId) {
      getAllPermissionsByPermissionTypeId(permissionId ? false : true);
    }
  }, [permissionTypeId, formSubmitCount]);

  useEffect(() => {
    if (permissionId) {
      getAllPermissionGroups(permissionGroupId ? false : true);
    }
  }, [permissionId, formSubmitCount]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onPermissionGroupActionDialogCanceled}
        onDialogConfirmed={onPermissionGroupActionDialogConfirmed}
        // States
        dialogText={permissionGroupActionDialogText}
        showDialog={showPermissionGroupActionDialog}
      />
      {/* Menu */}
      <PermissionGroupActionMenu
        // States
        showPermissionGroupActionMenu={showPermissionGroupActionMenu}
        // Set States
        setShowPermissionGroupActionMenu={setShowPermissionGroupActionMenu}
        // Fucntions
        displayPermissionGroupActionDialog={displayPermissionGroupActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("權限s")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      {permissionTypes && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) => option[t("permission_type_name_ch")]}
            onChange={(event, value) =>
              onInputFieldChange("permissionType", value)
            }
            options={permissionTypes}
            renderInput={(params) => (
              <TextField {...params} label={t("系統")} variant="outlined" />
            )}
            value={permissionTypeItem}
          />
        </div>
      )}
      {permissions && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) => option[t("permission_name_ch")]}
            onChange={(event, value) => onInputFieldChange("permission", value)}
            options={permissions}
            renderInput={(params) => (
              <TextField {...params} label={t("權限")} variant="outlined" />
            )}
            value={permissionItem}
          />
        </div>
      )}
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("p群組s")}
          </Typography>
          <Tooltip placement="right" title={t("新增p群組")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item, index) => (
          <PermissionGroupBox
            key={item.id}
            // States
            currentItemId={currentItemId}
            hoveredItemId={hoveredItemId}
            index={index}
            item={item}
            language={language}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有p群組資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default PermissionGroupBoxesContainer;
