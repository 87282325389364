export function onBrandChange(brandId) {
  return {
    type: "ON_DAY_OFF_TYPES_PAGE_BRAND_CHANGE",
    brandId,
  };
}

export function onBrandItemChange(brandItem) {
  return {
    type: "ON_DAY_OFF_TYPES_PAGE_BRAND_ITEM_CHANGE",
    brandItem,
  };
}

export function onDayOffTypeChange(dayOffTypeId) {
  return {
    type: "ON_DAY_OFF_TYPES_PAGE_DAY_OFF_TYPE_CHANGE",
    dayOffTypeId,
  };
}

export function onDayOffTypeItemChange(dayOffTypeItem) {
  return {
    type: "ON_DAY_OFF_TYPES_PAGE_DAY_OFF_TYPE_ITEM_CHANGE",
    dayOffTypeItem,
  };
}

export function onDepartmentChange(departmentId) {
  return {
    type: "ON_DAY_OFF_TYPES_PAGE_DEPARTMENT_CHANGE",
    departmentId,
  };
}

export function onDivisionChange(divisionId, divisionType) {
  return {
    type: "ON_DAY_OFF_TYPES_PAGE_DIVISION_CHANGE",
    divisionId,
    divisionType,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_DAY_OFF_TYPES_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onShopChange(shopId) {
  return {
    type: "ON_DAY_OFF_TYPES_PAGE_SHOP_CHANGE",
    shopId,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_DAY_OFF_TYPES_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}

export function onTypeOfDayOffTypesChange(typeOfDayOffTypes) {
  return {
    type: "ON_DAY_OFF_TYPES_PAGE_TYPE_OF_DAY_OFF_TYPES_CHANGE",
    typeOfDayOffTypes,
  };
}
