// Actions
import {
  onCandidateChange as onCandidateProfilePageCandidateChange,
  onTabItemChange as onCandidateProfilePageTabItemChange,
} from "../../redux/pages/candidateProfilePage/candidateProfilePageActions";
import {
  onBrandChange as onCompsAndBrandsPageBrandChange,
  onCompanyChange as onCompsAndBrandsPageCompanyChange,
  onTabItemChange as onCompsAndBrandsPageTabItemChange,
  onTypeOfCompsAndBrandsChange as onCompsAndBrandsPageTypeOfCompsAndBrandsChange,
} from "../../redux/pages/compsAndBrandsPage/compsAndBrandsPageActions";
import {
  onBrandChange as onDeptsAndShopsPageBrandChange,
  onDepartmentChange as onDeptsAndShopsPageDepartmentChange,
  onDivisionChange as onDeptsAndShopsPageDivisionChange,
  onShopChange as onDeptsAndShopsPageShopChange,
  onTypeOfContentChange as onDeptsAndShopsPageTypeOfContentChange,
  onTypeOfDeptsAndShopsChange as onDeptsAndShopsPageTypeOfDeptsAndShopsChange,
  onTypeOfStaffsChange as onDeptsAndShopsPageTypeOfStaffsChange,
} from "../../redux/pages/deptsAndShopsPage/deptsAndShopsPageActions";
import { showDrawer } from "../../redux/shared/drawer/drawerActions";
import { onLanguageChange } from "../../redux/shared/language/languageActions";
import { onThemeChange } from "../../redux/shared/theme/themeActions";
import { staffLogout } from "../../redux/shared/staff/staffActions";
import {
  onTabItemChange as onStaffProfilePageTabItemChange,
  onStaffChange as onStaffProfilePageStaffChange,
} from "../../redux/pages/staffProfilePage/staffProfilePageActions";
import {
  onDivisionChange as onTitlesPageDivisionChange,
  onTitleChange as onTitlesPageTitleChange,
  onTypeOfTitlesChange as onTitlesPageTypeOfTitlesChange,
} from "../../redux/pages/titlesPage/titlesPageActions";

// Components
// Dialogs
import AlertDialog from "../dialogs/alertDialog";
// Menus
import StaffActionMenu from "../menus/appBar/staffActionMenu";
// Modals
import ModalContainer from "../modals/modalContainer";
import SearchActionModal from "../modals/search/searchActionModal";

// Configs
import keyConfig from "../../configs/keyConfig";
import stylesConfig from "../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../customHooks/getLanguage";

// Helper Functions
import { getChangeLanguageItem } from "../../helperFunctions/getLanguageText";
import { getPagePath } from "../../helperFunctions/getPagePath";
import { getPageTitleText } from "../../helperFunctions/getPageTitleText";

// Material UI
// Components
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import Brightness4RoundedIcon from "@mui/icons-material/Brightness4Rounded";
import Brightness7RoundedIcon from "@mui/icons-material/Brightness7Rounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import TranslateRoundedIcon from "@mui/icons-material/TranslateRounded";

// React
import { useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import {
  appBarContainerStyles,
  showMenuBtnStyles,
  useAppBarStyles,
} from "../../styles/componentStyles/appBarStyles/appBarStyles";

function TopAppBar() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useAppBarStyles();

  // Redux Store
  const alias = useSelector((state) => state.staff.alias);
  const deptNameDisplay = useSelector((state) => state.staff.deptNameDisplay);
  const fullNameEn = useSelector((state) => state.staff.fullNameEn);
  const language = useSelector((state) => state.language.language);
  const previousPage = useSelector((state) => state.location.previousPage);
  const themeMode = useSelector((state) => state.theme.themeMode);

  // States
  // Dialog
  const [staffActionDialogText, setStaffActionDialogText] = useState("");
  const [staffActionDialogType, setStaffActionDialogType] = useState(null);
  const [showStaffActionDialog, setShowStaffActionDialog] = useState(false);
  // Menu
  const [showStaffActionMenu, setShowStaffActionMenu] = useState(null);
  // Modals
  const [showSearchActionModal, setShowSearchActionModal] = useState(false);

  // Handle States
  const changeLanguageItem = getChangeLanguageItem(language);
  const previousPageTitleText = t(getPageTitleText(previousPage));

  // Events
  // Events - Btns
  const onBackBtnClicked = () => {
    const path = getPagePath(previousPage);

    // Navigate
    navigate(path);
  };

  const onChangeLanguageBtnClicked = () => {
    // Update Redux Store
    dispatch(onLanguageChange(changeLanguageItem.value));

    storeLanguageToStorage(changeLanguageItem.value);
  };

  const onSearchBtnClicked = () => {
    displaySearchActionModal();
  };

  const onShowDrawerBtnClicked = () => {
    // Update Redux Store
    dispatch(showDrawer("left"));
  };

  const onToggleThemeBtnClicked = () => {
    const themeModeVar = themeMode === "light" ? "dark" : "light";

    // Update Redux Store
    dispatch(onThemeChange(themeModeVar));

    storeThemeModeToStorage(themeModeVar);
  };

  // Events - Dialogs
  const onStaffActionDialogCanceled = () => {
    // Set States
    setShowStaffActionDialog(false);
  };

  const onStaffActionDialogConfirmed = () => {
    switch (staffActionDialogType) {
      case "Logout":
        // Update Redux Store
        dispatch(staffLogout());

        // Local Storage
        localStorage.removeItem(keyConfig.tokenKey);
        break;
      default:
        break;
    }

    // Set States
    setShowStaffActionDialog(false);
  };

  // Events - Menu
  const onShowStaffActionMenuBtnClicked = (currentTarget) => {
    // Set States
    setShowStaffActionMenu(currentTarget);
  };

  // Events - Modal
  const onSearchActionModalClosed = () => {
    // Set States
    setShowSearchActionModal(false);
  };

  const onSearchActionModalItemClicked = (
    itemId,
    itemType,
    metaDataCategoryId,
    metaDataId,
    metaDataType
  ) => {
    switch (itemType) {
      case "Brand":
        // Update Redux Store
        dispatch(onCompsAndBrandsPageBrandChange(itemId));
        dispatch(onCompsAndBrandsPageTabItemChange("Brands"));
        dispatch(onCompsAndBrandsPageTypeOfCompsAndBrandsChange("Current"));

        navigate("/compsAndBrands");
        break;
      case "Candidate":
        dispatch(onCandidateProfilePageCandidateChange(itemId));
        dispatch(onCandidateProfilePageTabItemChange("BasicInfo"));

        navigate("/candidateProfile");
        break;
      case "Company":
        // Update Redux Store
        dispatch(onCompsAndBrandsPageCompanyChange(itemId));
        dispatch(onCompsAndBrandsPageTabItemChange("Companies"));
        dispatch(onCompsAndBrandsPageTypeOfCompsAndBrandsChange("Current"));

        navigate("/compsAndBrands");
        break;
      case "Department":
        // Update Redux Store
        dispatch(onDeptsAndShopsPageDepartmentChange(itemId));
        dispatch(onDeptsAndShopsPageDivisionChange(metaDataId, metaDataType));
        dispatch(onDeptsAndShopsPageTypeOfContentChange("StaffList"));
        dispatch(onDeptsAndShopsPageTypeOfDeptsAndShopsChange("Current"));
        dispatch(onDeptsAndShopsPageTypeOfStaffsChange("Current"));

        navigate("/deptsAndShops");
        break;
      case "Shop":
        // Update Redux Store
        dispatch(onDeptsAndShopsPageBrandChange(metaDataCategoryId));
        dispatch(onDeptsAndShopsPageDivisionChange(metaDataId, metaDataType));
        dispatch(onDeptsAndShopsPageShopChange(itemId));
        dispatch(onDeptsAndShopsPageTypeOfContentChange("StaffList"));
        dispatch(onDeptsAndShopsPageTypeOfDeptsAndShopsChange("Current"));
        dispatch(onDeptsAndShopsPageTypeOfStaffsChange("Current"));

        navigate("/deptsAndShops");
        break;
      case "Staff":
        // Update Redux Store
        dispatch(onStaffProfilePageTabItemChange("BasicInfo"));
        dispatch(onStaffProfilePageStaffChange(itemId));

        navigate("/staffProfile");
        break;
      case "Title":
        // Update Redux Store
        dispatch(onTitlesPageDivisionChange(metaDataId));
        dispatch(onTitlesPageTitleChange(itemId));
        dispatch(onTitlesPageTypeOfTitlesChange("Current"));

        navigate("/titles");
        break;
      default:
        break;
    }

    onSearchActionModalClosed();
  };

  // Functions
  // Functions - Normal
  const displayStaffActionDialog = (staffActionType) => {
    // Set States
    setStaffActionDialogType(staffActionType);

    switch (staffActionType) {
      case "Logout":
        setStaffActionDialogText(t("確認要 登出 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowStaffActionDialog(true);
  };

  const displaySearchActionModal = () => {
    // Set States
    setShowSearchActionModal(true);
  };

  const storeLanguageToStorage = (languageVar) => {
    // Local Storage
    localStorage.setItem(keyConfig.languageKey, languageVar);
  };

  const storeThemeModeToStorage = (themeModeVar) => {
    // Local Storage
    localStorage.setItem(keyConfig.themeKey, themeModeVar);
  };

  return (
    <AppBar
      sx={{
        ...appBarContainerStyles,
        backgroundColor:
          themeMode === "light"
            ? stylesConfig.appBarContainerBackgroundColorLight
            : stylesConfig.appBarContainerBackgroundColorDark,
      }}
    >
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onStaffActionDialogCanceled}
        onDialogConfirmed={onStaffActionDialogConfirmed}
        // States
        dialogText={staffActionDialogText}
        showDialog={showStaffActionDialog}
      />
      {/* Menu */}
      <StaffActionMenu
        // States
        showStaffActionMenu={showStaffActionMenu}
        // Set States
        setShowStaffActionMenu={setShowStaffActionMenu}
        // Functions
        displayStaffActionDialog={displayStaffActionDialog}
      />
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onSearchActionModalClosed}
        // States
        showModal={showSearchActionModal}
      >
        <SearchActionModal
          // Events
          onItemClicked={onSearchActionModalItemClicked}
          // States
          exceptionIdsArr={null}
          paramsArr={["All"]}
        />
      </ModalContainer>
      <Toolbar className={classes.contentContainer} disableGutters>
        <div className={classes.rightContainer}>
          <Tooltip sx={showMenuBtnStyles} title={t("顯示選單")}>
            <IconButton onClick={onShowDrawerBtnClicked}>
              <MenuRoundedIcon />
            </IconButton>
          </Tooltip>
          {previousPage ? (
            <Button
              onClick={onBackBtnClicked}
              startIcon={<ArrowBackIosRoundedIcon />}
              variant="text"
            >
              {previousPageTitleText}
            </Button>
          ) : (
            <div className={classes.spaceHolder} />
          )}
        </div>
        <div className={classes.rightContainer}>
          <div className={classes.searchBtnContainer}>
            <Button
              onClick={onSearchBtnClicked}
              startIcon={<SearchRoundedIcon />}
              variant="text"
            >
              {t("搜尋")}
            </Button>
          </div>
          <div className={classes.searchBtnContainer}>
            <Tooltip
              title={`${t("Change to ")}${changeLanguageItem.description}`}
            >
              <Button
                onClick={onChangeLanguageBtnClicked}
                startIcon={<TranslateRoundedIcon />}
                variant="text"
              >
                {changeLanguageItem.text}
              </Button>
            </Tooltip>
          </div>
          <div className={classes.searchBtnContainer}>
            <Tooltip
              title={
                themeMode === "light"
                  ? t("設換至夜間模式")
                  : t("設換至日間模式")
              }
            >
              <IconButton onClick={onToggleThemeBtnClicked}>
                {themeMode === "light" ? (
                  <Brightness4RoundedIcon />
                ) : (
                  <Brightness7RoundedIcon />
                )}
              </IconButton>
            </Tooltip>
          </div>
          <div
            className={classes.selfInfoContainer}
            onClick={(event) =>
              onShowStaffActionMenuBtnClicked(event.currentTarget)
            }
          >
            <div className={classes.selfInfoContentContainer}>
              {alias ? (
                <Typography align={"center"} sx={{ color: "primary.main" }}>
                  {alias}
                </Typography>
              ) : (
                <Typography align={"center"} sx={{ color: "primary.main" }}>
                  {fullNameEn}
                </Typography>
              )}
              {deptNameDisplay && (
                <Typography
                  align={"center"}
                  sx={{ color: stylesConfig.greyTextColor }}
                >
                  {deptNameDisplay}
                </Typography>
              )}
            </div>
            <IconButton>
              <KeyboardArrowDownRoundedIcon />
            </IconButton>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default TopAppBar;
