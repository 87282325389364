// Actions
import {
  onFormSubmitCountIncrease,
  onRelocationChange,
} from "../../../../redux/pages/staffProfilePage/staffProfilePageActions";

// Components
// Dialogs
import AlertDialog from "../../../dialogs/alertDialog";
// Forms
import RelocationDetailFormContent from "./relocationDetailFormContent";

// Custom Hooks
import { useLanguage } from "../../../../customHooks/getLanguage";

// Fetches
import { getRelocationDetailFormOptionsFetch } from "../../../../fetches/formOptionFetches";
import {
  createRelocationFetch,
  deleteRelocationFetch,
  editRelocationFetch,
  getRelocationDetailsByIdFetch,
  getStaffCurrentRelocationDetailsByStaffIdFetch,
} from "../../../../fetches/relocationFetches";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function RelocationDetailFormContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const relocationId = useSelector(
    (state) => state.staffProfilePage.relocationId
  );
  const staffId = useSelector((state) => state.staffProfilePage.staffId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Date Fields
  const [relocationDate, setRelocationDate] = useState(null);
  // Dialog
  const [relocationActionDialogText, setRelocationActionDialogText] =
    useState("");
  const [relocationActionDialogType, setRelocationActionDialogType] =
    useState(null);
  const [showRelocationActionDialog, setShowRelocationActionDialog] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [companyField, setCompanyField] = useState(null);
  const [departmentField, setDepartmentField] = useState(null);
  const [divisionField, setDivisionField] = useState(null);
  const [relocationTypeField, setRelocationTypeField] = useState(null);
  const [shopField, setShopField] = useState([]);
  const [titleField, setTitleField] = useState(null);
  // Options
  const [companyOptions, setCompanyOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [relocationTypeOptions, setRelocationTypeOptions] = useState([]);
  const [shopOptions, setShopOptions] = useState([]);
  const [titleOptions, setTitleOptions] = useState([]);
  // Render
  const [availableRelocationTypeIdsArr, setAvailableRelocationTypeIdsArr] =
    useState([]);
  const [isOnlyRelocation, setIsOnlyRelocation] = useState(false);
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  const [isRelocationEditable, setIsRelocationEditable] = useState(false);

  // Events
  // Events - Dialogs
  const onRelocationActionDialogCanceled = () => {
    // Set States
    setShouldShowFormAlert(false);
    setShowRelocationActionDialog(false);
  };

  const onRelocationActionDialogConfirmed = () => {
    switch (relocationActionDialogType) {
      case "CreateRelocation":
        createRelocation();
        break;
      case "DeleteRelocation":
        deleteRelocation();
        break;
      case "DeleteRelocationAndStaff":
        deleteRelocation();
        break;
      case "EditRelocation":
        editRelocation();
        break;
      default:
        break;
    }

    // Set States
    setShowRelocationActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "companyField":
        setCompanyField(value);
        break;
      case "departmentField":
        setDepartmentField(value);
        break;
      case "divisionField":
        setDivisionField(value);

        if (value.division_type_name_en !== "Departments") {
          setDepartmentField(null);

          removeErrorField("departmentField");
        } else if (value.division_type_name_en !== "Shops") {
          setShopField([]);

          removeErrorField("shopField");
        }

        setTitleField(
          titleOptions.find((item) => item.division_id === value.id)
        );

        removeErrorField("titleField");

        break;
      case "relocationDate":
        setRelocationDate(value);
        break;
      case "relocationTypeField":
        setRelocationTypeField(value);
        break;
      case "shopField":
        setShopField(value);
        break;
      case "titleField":
        setTitleField(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onDeleteBtnClicked = () => {
    displayRelocationActionDialog(
      isOnlyRelocation ? "DeleteRelocationAndStaff" : "DeleteRelocation"
    );
  };

  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!companyField) {
      addToErrorFields("companyField", t("請先填寫 公司"));
      isError = true;
    }

    if (!divisionField) {
      addToErrorFields("divisionField", t("請先填寫 類別"));
      isError = true;
    } else {
      if (divisionField.division_type_name_en === "Departments") {
        if (!departmentField) {
          addToErrorFields("departmentField", t("請先填寫 部門"));
          isError = true;
        }
      } else {
        if (!shopField[0]) {
          addToErrorFields("shopField", t("請先填寫 店舖"));
          isError = true;
        }
      }
    }

    if (!relocationDate) {
      addToErrorFields("relocationDate", t("請先填寫 生效日期"));
      isError = true;
    }

    if (!relocationTypeField) {
      addToErrorFields("relocationTypeField", t("請先填寫 調動類型"));
      isError = true;
    }

    if (!titleField) {
      addToErrorFields("titleField", t("請先填寫 職位"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayRelocationActionDialog(
      relocationId ? "EditRelocation" : "CreateRelocation"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displayRelocationActionDialog = (relocationActionType) => {
    // Set States
    setRelocationActionDialogType(relocationActionType);

    switch (relocationActionType) {
      case "CreateRelocation":
        setRelocationActionDialogText(t("確認要新增 調動 嗎？"));
        break;
      case "DeleteRelocation":
        setRelocationActionDialogText(t("確認要取消 調動 嗎？"));
        break;
      case "DeleteRelocationAndStaff":
        setRelocationActionDialogText(t("確認要取消 調動 並刪除 員工 嗎？"));
        break;
      case "EditRelocation":
        setRelocationActionDialogText(t("確認要編輯 調動資料 嗎？"));
        break;
      default:
        break;
    }

    setShowRelocationActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createRelocation = async () => {
    if (staffId) {
      const results = await createRelocationFetch(
        token,
        relocationTypeField ? relocationTypeField.id : null,
        staffId,
        relocationDate,
        companyField ? companyField.id : null,
        departmentField ? departmentField.id : null,
        divisionField ? divisionField.id : null,
        shopField[0] ? shopField.map((item) => item.id) : null,
        titleField ? titleField.id : null
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  const deleteRelocation = async () => {
    if (relocationId) {
      const results = await deleteRelocationFetch(token, relocationId);

      if (results.success) {
        // Update Redux Store
        dispatch(onRelocationChange(null));
        dispatch(onFormSubmitCountIncrease());
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  const editRelocation = async () => {
    if (relocationId) {
      const results = await editRelocationFetch(
        token,
        relocationId,
        relocationTypeField ? relocationTypeField.id : null,
        relocationDate,
        companyField ? companyField.id : null,
        departmentField ? departmentField.id : null,
        divisionField ? divisionField.id : null,
        shopField[0] ? shopField.map((item) => item.id) : null,
        titleField ? titleField.id : null
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  const setDefaultOptions = () => {
    if (!relocationId) {
      // Set States
      setRelocationTypeField(
        availableRelocationTypeIdsArr
          ? relocationTypeOptions.filter((item) =>
              availableRelocationTypeIdsArr.includes(item.id)
            )[0]
          : relocationTypeOptions[0]
      );
    }
  };

  // Functions - Queries
  const getRelocationDetailFormOptions = async () => {
    const results = await getRelocationDetailFormOptionsFetch(
      token,
      staffId,
      relocationId
    );

    // Set States
    setAvailableRelocationTypeIdsArr(
      results.availableRelocationTypeIdsArr
        ? results.availableRelocationTypeIdsArr
        : []
    );
    setCompanyOptions(results.companies ? results.companies : []);
    setDepartmentOptions(results.departments ? results.departments : []);
    setDivisionOptions(results.divisions ? results.divisions : []);
    setRelocationTypeOptions(
      results.relocationTypes ? results.relocationTypes : []
    );
    setShopOptions(results.shops ? results.shops : []);
    setTitleOptions(results.titles ? results.titles : []);
  };

  const getRelocationDetailsById = async () => {
    const results = await getRelocationDetailsByIdFetch(token, relocationId);

    if (results.relocationDetails) {
      const {
        relocation_date,
        relocation_type_id,
        dependencies,
        isEditable,
        isOnlyRelocation,
      } = results.relocationDetails;

      const { companies, departments, divisions, shops, titles } = dependencies;

      // Set States
      setCompanyField(
        companies
          ? companyOptions.find((item) => item.id === companies[0].id)
          : null
      );
      setDepartmentField(
        departments
          ? departmentOptions.find((item) => item.id === departments[0].id)
          : null
      );
      setDivisionField(
        divisions
          ? divisionOptions.find((item) => item.id === divisions[0].id)
          : null
      );
      setRelocationDate(relocation_date ? relocation_date : null);
      setRelocationTypeField(
        relocation_type_id
          ? relocationTypeOptions.find((item) => item.id === relocation_type_id)
          : null
      );

      if (shops) {
        let shopsArr = [];

        for (let shopItem of shops) {
          if (shopOptions.find((item) => item.id === shopItem.id)) {
            shopsArr.push(shopOptions.find((item) => item.id === shopItem.id));
          }
        }

        setShopField(shopsArr);
      } else {
        setShopField([]);
      }

      setTitleField(
        titles ? titleOptions.find((item) => item.id === titles[0].id) : null
      );

      setIsOnlyRelocation(isOnlyRelocation);
      setIsRelocationEditable(isEditable);
    } else {
      // Set States
      setCompanyField(null);
      setDepartmentField(null);
      setDivisionField(null);
      setRelocationTypeField(null);
      setShopField(null);
      setTitleField(null);

      setIsOnlyRelocation(false);
      setIsRelocationEditable(false);
    }

    clearErrorFields();
  };

  const getStaffCurrentRelocationDetailsByStaffId = async () => {
    const results = await getStaffCurrentRelocationDetailsByStaffIdFetch(
      token,
      staffId
    );

    if (results.relocationDetails) {
      const { dependencies } = results.relocationDetails;

      const { companies, departments, divisions, shops, titles } = dependencies;

      // Set States
      setCompanyField(
        companies
          ? companyOptions.find((item) => item.id === companies[0].id)
          : null
      );
      setDepartmentField(
        departments
          ? departmentOptions.find((item) => item.id === departments[0].id)
          : null
      );
      setDivisionField(
        divisions
          ? divisionOptions.find((item) => item.id === divisions[0].id)
          : null
      );

      if (shops) {
        let shopsArr = [];

        for (let shopItem of shops) {
          if (shopOptions.find((item) => item.id === shopItem.id)) {
            shopsArr.push(shopOptions.find((item) => item.id === shopItem.id));
          }
        }

        setShopField(shopsArr);
      } else {
        setShopField([]);
      }

      setTitleField(
        titles ? titleOptions.find((item) => item.id === titles[0].id) : null
      );
    } else {
      // Set States
      setCompanyField(null);
      setDepartmentField(null);
      setDivisionField(null);
      setShopField(null);
      setTitleField(null);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    if (staffId) {
      getRelocationDetailFormOptions();
    }
  }, [staffId, relocationId]);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && companyOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [companyOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (relocationId) {
        getRelocationDetailsById();
      } else {
        // Set States
        setIsRelocationEditable(true);
        setRelocationDate(new Date());

        if (staffId) {
          getStaffCurrentRelocationDetailsByStaffId();
        }
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, relocationId, staffId]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      setDefaultOptions();
    }
  }, [isOptionsRetrieved, availableRelocationTypeIdsArr]);

  return (
    <>
      {/* Dialogs */}
      <AlertDialog
        // Events
        onDialogClosed={onRelocationActionDialogCanceled}
        onDialogConfirmed={onRelocationActionDialogConfirmed}
        // States
        dialogText={relocationActionDialogText}
        showDialog={showRelocationActionDialog}
      />
      <RelocationDetailFormContent
        // States
        availableRelocationTypeIdsArr={availableRelocationTypeIdsArr}
        companyField={companyField}
        companyOptions={companyOptions}
        departmentField={departmentField}
        departmentOptions={departmentOptions}
        divisionField={divisionField}
        divisionOptions={divisionOptions}
        formAlertText={formAlertText}
        formAlertType={formAlertType}
        isRelocationEditable={isRelocationEditable}
        relocationDate={relocationDate}
        relocationId={relocationId}
        relocationTypeField={relocationTypeField}
        relocationTypeOptions={relocationTypeOptions}
        shopField={shopField}
        shopOptions={shopOptions}
        shouldShowFormAlert={shouldShowFormAlert}
        titleField={titleField}
        titleOptions={titleOptions}
        // Events
        onDeleteBtnClicked={onDeleteBtnClicked}
        onInputFieldChange={onInputFieldChange}
        onInputFieldKeyPressed={onInputFieldKeyPressed}
        onSubmitBtnClicked={onSubmitBtnClicked}
        // Functions
        checkIsFieldError={checkIsFieldError}
        getErrorFieldMessage={getErrorFieldMessage}
      />
    </>
  );
}

export default RelocationDetailFormContainer;
