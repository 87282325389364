// Actions
import {
  onDivisionChange,
  onTypeOfWorkingPeriodsAndShiftsChange,
} from "../../../redux/pages/rostersPage/rostersPageActions";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getAllDivisionsFetch } from "../../../fetches/divisionFetches";

// Material UI
// Components
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  selectBoxContainerStyles,
  useStandardListPageStyles,
} from "../../../styles/pageStyles/standardListPageStyles";

function RostersPageHeader() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardListPageStyles();

  // Redux Store
  const divisionId = useSelector((state) => state.rostersPage.divisionId);
  const token = useSelector((state) => state.staff.token);
  const typeOfContent = useSelector((state) => state.rostersPage.typeOfContent);
  const typeOfWorkingPeriodsAndShifts = useSelector(
    (state) => state.rostersPage.typeOfWorkingPeriodsAndShifts
  );

  // States
  // Data
  const [divisions, setDivisions] = useState(null);

  // Events
  // Events - Select Box
  const onTypeOfWorkingPeriodsAndShiftsSelectBoxValueChanged = (value) => {
    // Update Redux Store
    dispatch(onTypeOfWorkingPeriodsAndShiftsChange(value));
  };

  // Events - Tabs
  const onTabClicked = (event, value) => {
    const divisionType = divisions.find(
      (item) => item.id === value
    ).division_type_name_en;

    // Update Redux Store
    dispatch(onDivisionChange(value, divisionType));
  };

  // Functions
  // Functions - Queries
  const getAllDivisions = async () => {
    const results = await getAllDivisionsFetch(token);

    // Set States
    setDivisions(results.divisions ? results.divisions : null);

    // Update Redux Store
    if (results.divisions && !divisionId) {
      dispatch(
        onDivisionChange(
          results.divisions[0].id,
          results.divisions[0].division_type_name_en
        )
      );
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllDivisions();
  }, []);

  return (
    <Box className={classes.divisionsContainer}>
      {/* Tabs */}
      <div className={classes.tabsContainer}>
        {divisionId && (
          <Tabs value={divisionId} onChange={onTabClicked}>
            {divisions &&
              divisions.map((divisionItem) => (
                <Tab
                  disableRipple
                  key={divisionItem.id}
                  label={
                    <Typography variant="h6" align={"center"} gutterBottom>
                      {divisionItem[t("division_name_ch")]}
                    </Typography>
                  }
                  value={divisionItem.id}
                />
              ))}
          </Tabs>
        )}
      </div>
      {/* Select Boxes */}
      {["WorkingPeriods", "Shifts"].includes(typeOfContent) && (
        <div>
          <FormControl variant="standard" sx={selectBoxContainerStyles}>
            <Select
              value={typeOfWorkingPeriodsAndShifts}
              onChange={(event) =>
                onTypeOfWorkingPeriodsAndShiftsSelectBoxValueChanged(
                  event.target.value
                )
              }
            >
              <MenuItem value={"Current"}>
                {`${t("顯示有效")}${
                  typeOfContent === "WorkingPeriods"
                    ? t(" 工作時段s")
                    : t(" 值更s")
                }`}
              </MenuItem>
              <MenuItem value={"Deleted"}>
                {`${t("顯示已刪除")}${
                  typeOfContent === "WorkingPeriods"
                    ? t(" 工作時段s")
                    : t(" 值更s")
                }`}
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      )}
    </Box>
  );
}

export default RostersPageHeader;
