// Components
// Tables
import DayOffQuotaTable from "./dayOffQuotaTable";

// Configs\
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getAllStaffToDayOffTypesAndDayOffQuotasByDepartmentOrShopIdFetch } from "../../../fetches/staffToDayOffTypeFetches";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function DayOffQuotaTableContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const departmentId = useSelector(
    (state) => state.dayOffTypesPage.departmentId
  );
  const divisionType = useSelector(
    (state) => state.dayOffTypesPage.divisionType
  );
  const shopId = useSelector((state) => state.dayOffTypesPage.shopId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [dayOffTypes, setDayOffTypes] = useState(null);
  const [staffWithDayOffQuotas, setStaffWithDayOffQuotas] = useState(null);

  // Functions

  // Functions - Queries
  const getAllStaffToDayOffTypesAndDayOffQuotasByDepartmentOrShopId =
    async () => {
      const results =
        await getAllStaffToDayOffTypesAndDayOffQuotasByDepartmentOrShopIdFetch(
          token,
          divisionType === "Departments" ? departmentId : shopId,
          divisionType
        );

      // Set States
      setDayOffTypes(results.dayOffTypes ? results.dayOffTypes : null);
      setStaffWithDayOffQuotas(
        results.staffWithDayOffQuotas ? results.staffWithDayOffQuotas : null
      );
    };

  // Life Cycle
  useEffect(() => {
    if (
      (divisionType === "Departments" && departmentId) ||
      (divisionType === "Shops" && shopId)
    ) {
      getAllStaffToDayOffTypesAndDayOffQuotasByDepartmentOrShopId();
    }
  }, [departmentId, divisionType, shopId]);

  return (
    <div className={classes.contentBoxWithMaxWidth}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("剩餘假期數量s")}
          </Typography>
        </div>
      </div>
      {dayOffTypes && staffWithDayOffQuotas ? (
        // Table
        <DayOffQuotaTable
          // States
          dayOffTypes={dayOffTypes}
          staffWithDayOffQuotas={staffWithDayOffQuotas}
        />
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有剩餘假期數量資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default DayOffQuotaTableContainer;
