// Actions
import {
  onStaffChange,
  onTabItemChange,
} from "../../../redux/pages/staffProfilePage/staffProfilePageActions";

// Components
// Boxes
import CandidateIntroApplicationInfoBox from "./candidateIntroApplicationInfoBox";
import CandidateIntroBasicInfoBox from "./candidateIntroBasicInfoBox";
import CandidateIntroProfilePhotoBox from "./candidateIntroProfilePhotoBox";
import CandidateIntroStatusInfoBox from "./candidateIntroStatusInfoBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Configs
import fileConfig from "../../../configs/fileConfig";
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteCandidatePhotoFetch,
  editCandidatePhotoFetch,
  getCandidateBasicInfoByIdFetch,
} from "../../../fetches/candidateFetches";

// Helper Functions
import { checkFileSize } from "../../../helperFunctions/file";
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";
import { monthsToYearsAndMonths } from "../../../helperFunctions/monthsToYearsAndMonths";
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function CandidateIntroBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const candidateId = useSelector(
    (state) => state.candidateProfilePage.candidateId
  );
  const formSubmitCount = useSelector(
    (state) => state.candidateProfilePage.formSubmitCount
  );
  const language = useSelector((state) => state.language.language);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [candidateBasicInfo, setCandidateBasicInfo] = useState(null);
  const [staffId, setStaffId] = useState(null);
  // Dialog
  const [candidateActionDialogText, setCandidateActionDialogText] =
    useState("");
  const [candidateActionDialogType, setCandidateActionDialogType] =
    useState(null);
  const [showCandidateActionDialog, setShowCandidateActionDialog] =
    useState(false);

  // Handle States
  const item = candidateBasicInfo;

  // Events
  // Events - Dialogs
  const onCandidateActionDialogCanceled = () => {
    // Set States
    setShowCandidateActionDialog(false);
  };

  const onCandidateActionDialogConfirmed = () => {
    switch (candidateActionDialogType) {
      case "DeleteCandidatePhoto":
        deleteCandidatePhoto();
        break;
      default:
        break;
    }

    // Set States
    setShowCandidateActionDialog(false);
  };

  // Events - Fields
  const onFileUploaded = (value) => {
    // Check File Size
    const maxSize = fileConfig.applicationMaxFileUploadSize;
    const isFileSizeWithinLimit = checkFileSize([value], maxSize, "Mb");

    if (!isFileSizeWithinLimit) {
      displayCandidateActionDialog("ExceedMaxFileUploadSize", maxSize);
      return;
    }

    editCandidatePhoto(value);
  };

  // Events - Title
  const onDeletePhotoBtnClicked = () => {
    displayCandidateActionDialog("DeleteCandidatePhoto");
  };

  const onViewStaffBtnClicked = () => {
    if (staffId) {
      // Update Redux Store
      dispatch(onStaffChange(staffId));
      dispatch(onTabItemChange("BasicInfo"));

      // Navigate
      navigate("/staffProfile");
    }
  };

  // Functions
  // Functions - Normal
  const displayCandidateActionDialog = (fileActionType, alertValue) => {
    // Set States
    setCandidateActionDialogType(fileActionType);

    switch (fileActionType) {
      case "DeleteCandidatePhoto":
        setCandidateActionDialogText(t("確認要刪除 照片 嗎？"));
        break;
      case "ExceedMaxFileUploadSize":
        setCandidateActionDialogText(`${t("檔案不可超過")} ${alertValue} Mb`);
        break;
      case "UploadFail":
        setCandidateActionDialogText(t("未能上載，請再試一次"));
        break;
      default:
        break;
    }

    // Set States
    setShowCandidateActionDialog(true);
  };

  // Functions - Mutations
  const deleteCandidatePhoto = async () => {
    const results = await deleteCandidatePhotoFetch(token, candidateId);

    if (results.success) {
      getCandidateBasicInfoById();
    } else {
      displayCandidateActionDialog("UploadFail");
    }
  };

  const editCandidatePhoto = async (photo) => {
    const results = await editCandidatePhotoFetch(token, candidateId, photo);

    if (results.success) {
      getCandidateBasicInfoById();
    } else {
      displayCandidateActionDialog("UploadFail");
    }
  };

  // Functions - Queries
  const getCandidateBasicInfoById = async () => {
    const results = await getCandidateBasicInfoByIdFetch(token, candidateId);

    if (results.candidateBasicInfo) {
      results.candidateBasicInfo.expected_salary = `$ ${separateComma(
        Number(results.candidateBasicInfo.expected_salary).toFixed()
      )}`;

      results.candidateBasicInfo.date_applied = moment(
        results.candidateBasicInfo.date_applied
      ).format(t("MM月 DD日"));

      results.candidateBasicInfo.date_available = moment(
        results.candidateBasicInfo.date_available
      ).format(t("MM月 DD日"));
    }

    if (results.candidateBasicInfo.totalWorkExperinece) {
      results.candidateBasicInfo.totalWorkExperinece = `${t(
        "工作經驗"
      )} - ${monthsToYearsAndMonths(
        language,
        results.candidateBasicInfo.totalWorkExperinece
      )}`;
    }

    // Set States
    setCandidateBasicInfo(
      results.candidateBasicInfo ? results.candidateBasicInfo : null
    );
    setStaffId(results.staffId ? results.staffId : null);
  };

  // Life Cycle
  useEffect(() => {
    if (candidateId) {
      getCandidateBasicInfoById();
    }
  }, [formSubmitCount, candidateId]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onCandidateActionDialogCanceled}
        onDialogConfirmed={onCandidateActionDialogConfirmed}
        // States
        dialogText={candidateActionDialogText}
        showDialog={showCandidateActionDialog}
      />
      {/* Content */}
      {item && (
        <CandidateIntroProfilePhotoBox
          // Events
          onDeletePhotoBtnClicked={onDeletePhotoBtnClicked}
          onFileUploaded={onFileUploaded}
          // States
          item={item}
        />
      )}
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("應徵者")}
          </Typography>
          {staffId && (
            <Tooltip placement="right" title={t("查看員工資料")}>
              <IconButton color={"primary"} onClick={onViewStaffBtnClicked}>
                <PersonRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      {/* Content */}
      {item ? (
        <>
          <CandidateIntroBasicInfoBox
            // States
            item={item}
          />
          <CandidateIntroApplicationInfoBox
            // States
            item={item}
          />
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有應徵者資料")}
          </Typography>
        </div>
      )}
      {item && (
        <>
          {/* Title */}
          <div className={classes.titleContainer}>
            <div className={classes.titleTextContainer}>
              <Typography variant="h6" align={"left"}>
                {t("狀態")}
              </Typography>
            </div>
          </div>
          <CandidateIntroStatusInfoBox
            // States
            item={item}
          />
        </>
      )}
    </div>
  );
}

export default CandidateIntroBoxesContainer;
