// Actions
import { onStaffChange } from "../../../redux/pages/staffProfilePage/staffProfilePageActions";

// Components
// Boxes
import StaffBox from "./staffBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import StaffActionMenu from "../../menus/dashboardPage/staffActionMenu";
// Modals
import StaffActionModal from "../../modals/deptsAndShopsPage/staffActionModal/staffActionModal";
import ModalContainer from "../../modals/modalContainer";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteRelocationFetch,
  getRelocationIdByStaffIdAndRelocationTypeIdsArrFetch,
} from "../../../fetches/relocationFetches";
import {
  getAllStaffsBirthdayCurrentMonthWithPaginationFetch,
  getAllStaffsPassingProbationWithPaginationFetch,
  getAllStaffsWithRecentRelocationsWithPaginationFetch,
} from "../../../fetches/staffFetches";

// Helper Functions
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";
import { getTypeOfStaffsText } from "../../../helperFunctions/getTypeOfStaffsText";

// Material UI
// Components
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNum;

function StaffBoxesContainer(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // States
    typeOfStaffs,
  } = props;

  // Redux Store
  const language = useSelector((state) => state.language.language);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [staffs, setStaffs] = useState(null);
  // Dialog
  const [staffActionDialogText, setStaffActionDialogText] = useState("");
  const [staffActionDialogType, setStaffActionDialogType] = useState(null);
  const [showStaffActionDialog, setShowStaffActionDialog] = useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Modals
  const [showStaffActionModal, setShowStaffActionModal] = useState(false);
  const [staffActionModalType, setStaffActionModalType] = useState("");
  // Menu
  const [showStaffActionMenu, setShowStaffActionMenu] = useState(null);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  // Render
  const [selectedStaff, setSelectedStaff] = useState(null);

  // Handle States
  const itemsArr = staffs;
  const title = getTypeOfStaffsText(typeOfStaffs);

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onStaffChange(itemId));
    // Navigate
    navigate("/staffProfile");
  };

  const onItemRightClicked = (item, currentTarget) => {
    // Set States
    setSelectedStaff(item);
    setShowStaffActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onStaffActionDialogCanceled = () => {
    // Set States
    setShowStaffActionDialog(false);
  };

  const onStaffActionDialogConfirmed = async () => {
    let targetRelocationIdResults = null;

    switch (staffActionDialogType) {
      // 取消入職
      case "CancelStaffOnboard":
        // Get Target Relocation Id
        targetRelocationIdResults =
          await getRelocationIdByStaffIdAndRelocationTypeIdsArr([1, 3]);

        if (targetRelocationIdResults) {
          const targetRelocationId = targetRelocationIdResults;

          // Delete Relocation
          await deleteRelocation(targetRelocationId);
        }

        break;
      // 取消離職
      case "CancelStaffResign":
        // Get Target Relocation Id
        targetRelocationIdResults =
          await getRelocationIdByStaffIdAndRelocationTypeIdsArr([2]);

        if (targetRelocationIdResults) {
          const targetRelocationId = targetRelocationIdResults;

          // Delete Relocation
          await deleteRelocation(targetRelocationId);
        }

        break;
      default:
        break;
    }

    // Set States
    setShowStaffActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onStaffActionModalClosed = () => {
    getAllStaffs();
    // Set States
    setShowStaffActionModal(false);
  };

  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Functions
  // Functions - Normal
  const displayStaffActionDialog = (staffActionType) => {
    const fullNameEn = selectedStaff.full_name_en;
    const staffCode = selectedStaff.staff_code;

    // Set States
    setStaffActionDialogType(staffActionType);

    switch (staffActionType) {
      // 取消入職
      case "CancelStaffOnboard":
        setStaffActionDialogText(
          `${t("確認要取消")} ${staffCode} - ${fullNameEn} ${t(
            "入職 並 刪除 員工嗎？"
          )}`
        );
        break;
      // 取消離職
      case "CancelStaffResign":
        setStaffActionDialogText(
          `${t("確認要取消")} ${staffCode} - ${fullNameEn} ${t(
            "離職 並 刪除 員工嗎？"
          )}`
        );
        break;
      default:
        break;
    }

    // Set States
    setShowStaffActionDialog(true);
  };

  const displayStaffActionModal = (typeOfModal) => {
    // Set States
    setStaffActionModalType(typeOfModal);
    setShowStaffActionModal(true);
  };

  const getAllStaffs = () => {
    switch (typeOfStaffs) {
      case "Birthday":
        getAllStaffsBirthdayCurrentMonthWithPagination();
        break;
      case "Onboarding":
        getAllStaffsWithRecentRelocationsWithPagination();
        break;
      case "PassingProbation":
        getAllStaffsPassingProbationWithPagination();
        break;
      case "Resignation":
        getAllStaffsWithRecentRelocationsWithPagination();
        break;
      default:
        break;
    }
  };

  // Functions - Queries
  const getAllStaffsBirthdayCurrentMonthWithPagination = async () => {
    const results = await getAllStaffsBirthdayCurrentMonthWithPaginationFetch(
      token,
      pageNum,
      limitNum
    );

    if (results.staffs) {
      for (let item of results.staffs) {
        item.date_birth = item.date_birth
          ? moment(item.date_birth).format(t("MM月 DD日"))
          : null;
      }
    }

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setStaffs(results.staffs ? results.staffs : null);

    if (results.pageCount && !results.staffs) {
      setPageNum(results.pageCount);
    }
  };

  const getAllStaffsPassingProbationWithPagination = async () => {
    const results = await getAllStaffsPassingProbationWithPaginationFetch(
      token,
      pageNum,
      limitNum
    );

    if (results.staffs) {
      for (let item of results.staffs) {
        item.probation_date = item.probation_date
          ? moment(item.probation_date).format(t("MM月 DD日"))
          : null;
      }
    }

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setStaffs(results.staffs ? results.staffs : null);

    if (results.pageCount && !results.staffs) {
      setPageNum(results.pageCount);
    }
  };

  const getAllStaffsWithRecentRelocationsWithPagination = async () => {
    const results = await getAllStaffsWithRecentRelocationsWithPaginationFetch(
      token,
      typeOfStaffs,
      pageNum,
      limitNum
    );

    if (results.staffs) {
      for (let item of results.staffs) {
        item.relocation_date = item.relocation_date
          ? moment(item.relocation_date).format(t("MM月 DD日"))
          : null;
      }
    }

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setStaffs(results.staffs ? results.staffs : null);

    if (results.pageCount && !results.staffs) {
      setPageNum(results.pageCount);
    }
  };

  const getRelocationIdByStaffIdAndRelocationTypeIdsArr = async (
    relocationTypeId
  ) => {
    const results = await getRelocationIdByStaffIdAndRelocationTypeIdsArrFetch(
      token,
      selectedStaff.id,
      relocationTypeId
    );

    if (results.relocationId) {
      return results.relocationId;
    } else {
      return null;
    }
  };

  // Functions - Mutations
  const deleteRelocation = async (relocationId) => {
    if (relocationId) {
      const results = await deleteRelocationFetch(token, relocationId);

      if (results.success) {
        getAllStaffs();
      }
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllStaffs();
  }, [pageNum, language]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Dialogs */}
      <AlertDialog
        // Events
        onDialogClosed={onStaffActionDialogCanceled}
        onDialogConfirmed={onStaffActionDialogConfirmed}
        // States
        dialogText={staffActionDialogText}
        showDialog={showStaffActionDialog}
      />
      {/* Menu */}
      {selectedStaff && (
        <StaffActionMenu
          // States
          selectedStaff={selectedStaff}
          showStaffActionMenu={showStaffActionMenu}
          // Set States
          setShowStaffActionMenu={setShowStaffActionMenu}
          // Functions
          displayStaffActionDialog={displayStaffActionDialog}
          displayStaffActionModal={displayStaffActionModal}
        />
      )}
      {/* Modal */}
      {selectedStaff && (
        <ModalContainer
          // Events
          onModalClosed={onStaffActionModalClosed}
          // States
          showModal={showStaffActionModal}
        >
          <StaffActionModal
            // Events
            onModalClosed={onStaffActionModalClosed}
            // States
            staffId={selectedStaff.id}
            staffActionModalType={staffActionModalType}
          />
        </ModalContainer>
      )}
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t(title)}
          </Typography>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item) => (
            <StaffBox
              key={item.id}
              // States
              hoveredItemId={hoveredItemId}
              item={item}
              typeOfStaffs={typeOfStaffs}
              // Events
              onItemClicked={onItemClicked}
              onItemRightClicked={onItemRightClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
          <Stack className={classes.paginationStack} spacing={2}>
            <Pagination
              color={"primary"}
              count={pageCount}
              onChange={(event, page) => onPageChange(page)}
              page={pageNum}
            />
          </Stack>
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有員工資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default StaffBoxesContainer;
