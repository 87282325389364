export function onAbnormalDateChange(abnormalDate) {
  return {
    type: "ON_ATTENDANCES_PAGE_ABNORMAL_DATE_CHANGE",
    abnormalDate,
  };
}

export function onAttendanceRuleChange(attendanceRuleId) {
  return {
    type: "ON_ATTENDANCES_PAGE_ATTENDANCE_RULE_CHANGE",
    attendanceRuleId,
  };
}

export function onAttendanceTypeChange(attendanceTypeId) {
  return {
    type: "ON_ATTENDANCES_PAGE_ATTENDANCE_TYPE_CHANGE",
    attendanceTypeId,
  };
}

export function onBrandChange(brandId) {
  return {
    type: "ON_ATTENDANCES_PAGE_BRAND_CHANGE",
    brandId,
  };
}

export function onBrandItemChange(brandItem) {
  return {
    type: "ON_ATTENDANCES_PAGE_BRAND_ITEM_CHANGE",
    brandItem,
  };
}

export function onConditionAttendanceTypeChange(conditionAttendanceTypeId) {
  return {
    type: "ON_ATTENDANCES_PAGE_CONDITION_ATTENDANCE_TYPE_CHANGE",
    conditionAttendanceTypeId,
  };
}

export function onConditionAttendanceTypeItemChange(
  conditionAttendanceTypeItem
) {
  return {
    type: "ON_ATTENDANCES_PAGE_CONDITION_ATTENDANCE_TYPE_ITEM_CHANGE",
    conditionAttendanceTypeItem,
  };
}

export function onConditionLocationTypeChange(conditionLocationTypeValue) {
  return {
    type: "ON_ATTENDANCES_PAGE_CONDITION_LOCATION_TYPE_CHANGE",
    conditionLocationTypeValue,
  };
}

export function onConditionLocationTypeItemChange(conditionLocationTypeItem) {
  return {
    type: "ON_ATTENDANCES_PAGE_CONDITION_LOCATION_TYPE_ITEM_CHANGE",
    conditionLocationTypeItem,
  };
}

export function onConditionShopChange(conditionShopId) {
  return {
    type: "ON_ATTENDANCES_PAGE_CONDITION_SHOP_CHANGE",
    conditionShopId,
  };
}

export function onConditionShopItemChange(conditionShopItem) {
  return {
    type: "ON_ATTENDANCES_PAGE_CONDITION_SHOP_ITEM_CHANGE",
    conditionShopItem,
  };
}

export function onConditionWorkingPlaceChange(conditionWorkingPlaceId) {
  return {
    type: "ON_ATTENDANCES_PAGE_CONDITION_WORKING_PLACE_CHANGE",
    conditionWorkingPlaceId,
  };
}

export function onConditionWorkingPlaceItemChange(conditionWorkingPlaceItem) {
  return {
    type: "ON_ATTENDANCES_PAGE_CONDITION_WORKING_PLACE_ITEM_CHANGE",
    conditionWorkingPlaceItem,
  };
}

export function onDepartmentChange(departmentId) {
  return {
    type: "ON_ATTENDANCES_PAGE_DEPARTMENT_CHANGE",
    departmentId,
  };
}

export function onDivisionChange(divisionId, divisionType) {
  return {
    type: "ON_ATTENDANCES_PAGE_DIVISION_CHANGE",
    divisionId,
    divisionType,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_ATTENDANCES_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onMonthChange(month) {
  return {
    type: "ON_ATTENDANCES_PAGE_MONTH_CHANGE",
    month,
  };
}

export function onRuleAttendanceTypeChange(ruleAttendanceTypeId) {
  return {
    type: "ON_ATTENDANCES_PAGE_RULE_ATTENDANCE_TYPE_CHANGE",
    ruleAttendanceTypeId,
  };
}

export function onShopChange(shopId) {
  return {
    type: "ON_ATTENDANCES_PAGE_SHOP_CHANGE",
    shopId,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_ATTENDANCES_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}
