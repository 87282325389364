export const monthOptions = [
  { value: 1, text: "1個月" },
  { value: 2, text: "2個月" },
  { value: 3, text: "3個月" },
  { value: 4, text: "4個月" },
  { value: 5, text: "5個月" },
  { value: 6, text: "6個月" },
  { value: 7, text: "7個月" },
  { value: 8, text: "8個月" },
  { value: 9, text: "9個月" },
  { value: 10, text: "10個月" },
  { value: 11, text: "11個月" },
  { value: 12, text: "12個月" },
];
