// Configs
import apisConfig from "../configs/apisConfig";

export const createShopFetch = async (
  token,
  brandId,
  shopCode,
  shopNameCh,
  shopNameEn,
  districtId,
  addressCh,
  addressEn,
  isActive
) => {
  try {
    const queryRoute = "/shop/createShop";

    const reqBody = {
      brandId,
      shopCode,
      shopNameCh,
      shopNameEn,
      districtId,
      addressCh,
      addressEn,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteOrRestoreShopFetch = async (token, shopId, typeOfShops) => {
  try {
    const queryRoute = `/shop/deleteOrRestoreShop/${shopId}`;

    const reqBody = { typeOfShops };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editShopFetch = async (
  token,
  shopId,
  brandId,
  shopCode,
  shopNameCh,
  shopNameEn,
  headStaffIdsArr,
  districtId,
  addressCh,
  addressEn,
  isActive
) => {
  try {
    const queryRoute = `/shop/editShop/${shopId}`;

    const reqBody = {
      brandId,
      shopCode,
      shopNameCh,
      shopNameEn,
      headStaffIdsArr,
      districtId,
      addressCh,
      addressEn,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllShopsFetch = async (token, typeOfShops) => {
  try {
    const queryRoute = "/shop/getAllShops";

    const reqBody = {
      typeOfShops,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllShopsByBrandIdFetch = async (
  token,
  brandId,
  typeOfShops
) => {
  try {
    const queryRoute = `/shop/getAllShopsByBrandId/${brandId}`;

    const reqBody = {
      typeOfShops,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getShopDetailsByIdFetch = async (token, shopId) => {
  try {
    const queryRoute = `/shop/getShopDetailsById/${shopId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
