// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

// Styles
import { useStandardTableStyles } from "../../../styles/componentStyles/tableStyles/standardTableStyles";

function DayBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardTableStyles();

  // Props
  const {
    // States
    hoveredDayId,
    hoveredDayItemId,
    item,
    // Events
    onItemClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  return (
    <Tooltip
      title={item[t("holiday_name_ch")] ? item[t("holiday_name_ch")] : ""}
    >
      <div
        className={classes.fieldBoxWithPointer}
        onClick={() => onItemClicked(item.id, item.arrangement_id)}
        onContextMenu={(event) => {
          event.preventDefault();
          onItemClicked(item.id, item.arrangement_id);
        }}
        onMouseEnter={() => onMouseEnterItem(item.id)}
        onMouseLeave={onMouseLeaveItem}
      >
        <Typography
          align={"center"}
          className={classes.cellBoxTiny}
          color={
            hoveredDayItemId === item.id
              ? stylesConfig.mainTextColor
              : item.holiday_id
              ? stylesConfig.redTextColor
              : null
          }
          variant="body1"
        >
          {item.arrangement_id ? "." : ""}
        </Typography>
        <Typography
          align={"center"}
          className={
            hoveredDayId === item.id
              ? classes.selectedCellBoxTiny
              : classes.cellBoxTiny
          }
          color={
            hoveredDayItemId === item.id
              ? stylesConfig.mainTextColor
              : hoveredDayId === item.id
              ? stylesConfig.whiteTextColor
              : item.holiday_id
              ? stylesConfig.redTextColor
              : null
          }
          variant="body1"
        >
          {item.date}
        </Typography>
        <Typography
          align={"center"}
          className={classes.cellBoxTiny}
          color={
            item.holiday_id
              ? stylesConfig.redTextColor
              : stylesConfig.greyTextColor
          }
          variant="body2"
        >
          ({item[t("weekday_name_ch_short")][0]})
        </Typography>
      </div>
    </Tooltip>
  );
}

export default DayBox;
