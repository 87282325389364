// Actions
import { onApplicationChange } from "../../../redux/pages/applicationsPage/applicationsPageActions";
import { onBadgeUpdateCountIncrease } from "../../../redux/shared/badge/badgeActions";

// Components
// Boxes
import ApplicationBox from "./applicationBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import ApplicationActionMenu from "../../menus/applicationsPage/applicationActionMenu";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  approveApplicationFetch,
  cancelApplicationFetch,
  getAllApplicationsWithPaginationFetch,
  rejectApplicationFetch,
} from "../../../fetches/applicationFetches";

// Helper Functions
import {
  getMonthEndDate,
  getMonthStartDate,
} from "../../../helperFunctions/getDate";
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";

// Material UI
// Components
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNum;

function ApplicationBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const applicationId = useSelector(
    (state) => state.applicationsPage.applicationId
  );
  const applicationOrApprovalTypeValue = useSelector(
    (state) => state.applicationsPage.conditionApplicationOrApprovalTypeValue
  );
  const applicationStatusTypeId = useSelector(
    (state) => state.applicationsPage.conditionApplicationStatusTypeId
  );
  const applicationTypeId = useSelector(
    (state) => state.applicationsPage.conditionApplicationTypeId
  );
  const formSubmitCount = useSelector(
    (state) => state.applicationsPage.formSubmitCount
  );
  const language = useSelector((state) => state.language.language);
  const recordMonth = useSelector(
    (state) => state.applicationsPage.recordMonth
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [applications, setApplications] = useState(null);
  // Dialog
  const [applicationActionDialogText, setApplicationActionDialogText] =
    useState("");
  const [applicationActionDialogType, setApplicationActionDialogType] =
    useState(null);
  const [showApplicationActionDialog, setShowApplicationActionDialog] =
    useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showApplicationActionMenu, setShowApplicationActionMenu] =
    useState(null);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);

  // Handle States
  const currentItemId = applicationId;
  const isMenuAvailable =
    applicationStatusTypeId && applicationStatusTypeId === 1;
  const itemsArr = applications;
  const recordMonthVar = new Date(recordMonth);

  // Events
  // Events - Box
  const onItemClicked = (itemId, itemTypeId) => {
    // Update Redux Store
    dispatch(onApplicationChange(itemId, itemTypeId));
  };

  const onItemRightClicked = (itemId, itemTypeId, currentTarget) => {
    onItemClicked(itemId, itemTypeId);
    // Set States
    setShowApplicationActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, itemTypeId, currentTarget) => {
    onItemClicked(itemId, itemTypeId);
    // Set States
    setShowApplicationActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onApplicationActionDialogCanceled = () => {
    // Set States
    setShowApplicationActionDialog(false);
  };

  const onApplicationActionDialogConfirmed = () => {
    switch (applicationActionDialogType) {
      case "ApproveApplication":
        approveApplication();
        break;
      case "CancelApplication":
        cancelApplication();
        break;
      case "RejectApplication":
        rejectApplication();
        break;
      default:
        break;
    }

    // Set States
    setShowApplicationActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Functions
  // Functions - Normal
  const displayApplicationActionDialog = (applicationActionType) => {
    // Set States
    setApplicationActionDialogType(applicationActionType);

    switch (applicationActionType) {
      case "ApproveApplication":
        setApplicationActionDialogText(t("確認要批准 申請 嗎？"));
        break;
      case "CancelApplication":
        setApplicationActionDialogText(t("確認要取消 申請 嗎？"));
        break;
      case "RejectApplication":
        setApplicationActionDialogText(t("確認要駁回 申請 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowApplicationActionDialog(true);
  };

  // Functions - Mutations
  const approveApplication = async () => {
    const results = await approveApplicationFetch(token, applicationId);

    if (results.success) {
      getAllApplicationsWithPagination();
    }
  };

  const cancelApplication = async () => {
    const results = await cancelApplicationFetch(token, applicationId);

    if (results.success) {
      getAllApplicationsWithPagination();
    }
  };

  const rejectApplication = async () => {
    const results = await rejectApplicationFetch(token, applicationId);

    if (results.success) {
      getAllApplicationsWithPagination();
    }
  };

  // Functions - Queries
  const getAllApplicationsWithPagination = async () => {
    const startDate = getMonthStartDate(recordMonthVar);
    const endDate = getMonthEndDate(recordMonthVar);

    const results = await getAllApplicationsWithPaginationFetch(
      token,
      applicationOrApprovalTypeValue,
      applicationTypeId !== "dummy" ? applicationTypeId : null,
      applicationStatusTypeId,
      startDate,
      endDate,
      pageNum,
      limitNum
    );

    if (results.applications) {
      for (let item of results.applications) {
        item.time_apply = item.time_apply
          ? moment(item.time_apply).format(t("MM月 DD日"))
          : null;

        if (item.answerText) {
          const {
            main_answer_type,
            main_answer_text_ch,
            main_answer_text_en,
            sub_answer_type_1,
            sub_answer_text_ch_1,
            sub_answer_text_en_1,
            sub_answer_type_2,
            sub_answer_text_ch_2,
            sub_answer_text_en_2,
          } = item.answerText;

          if (main_answer_type) {
            if (main_answer_type === "date") {
              item.answerText.main_answer_text_ch = moment(
                main_answer_text_ch
              ).format(t("MM月 DD日"));
              item.answerText.main_answer_text_en = moment(
                main_answer_text_en
              ).format(t("MM月 DD日"));
            }

            if (main_answer_type === "time") {
              item.answerText.main_answer_text_ch = moment(
                main_answer_text_ch
              ).format(t("H:mm"));
              item.answerText.main_answer_text_en = moment(
                main_answer_text_en
              ).format(t("H:mm"));
            }
          }

          if (sub_answer_type_1) {
            if (sub_answer_type_1 === "date") {
              item.answerText.sub_answer_text_ch_1 = moment(
                sub_answer_text_ch_1
              ).format(t("MM月 DD日"));
              item.answerText.sub_answer_text_en_1 = moment(
                sub_answer_text_en_1
              ).format(t("MM月 DD日"));
            }

            if (sub_answer_type_1 === "time") {
              item.answerText.sub_answer_text_ch_1 = moment(
                sub_answer_text_ch_1
              ).format(t("H:mm"));
              item.answerText.sub_answer_text_en_1 = moment(
                sub_answer_text_en_1
              ).format(t("H:mm"));
            }
          }

          if (sub_answer_type_2) {
            if (sub_answer_type_2 === "date") {
              item.answerText.sub_answer_text_ch_2 = moment(
                sub_answer_text_ch_2
              ).format(t("MM月 DD日"));
              item.answerText.sub_answer_text_en_2 = moment(
                sub_answer_text_en_2
              ).format(t("MM月 DD日"));
            }

            if (sub_answer_type_2 === "time") {
              item.answerText.sub_answer_text_ch_2 = moment(
                sub_answer_text_ch_2
              ).format(t("H:mm"));
              item.answerText.sub_answer_text_en_2 = moment(
                sub_answer_text_en_2
              ).format(t("H:mm"));
            }
          }
        }
      }
    }

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setApplications(results.applications ? results.applications : null);

    if (results.pageCount && !results.applications) {
      setPageNum(results.pageCount);
    }

    // Update Redux Store
    if (results.applications) {
      if (
        !(
          applicationId &&
          results.applications.some((item) => item.id === applicationId)
        )
      ) {
        dispatch(
          onApplicationChange(
            results.applications[0].id,
            results.applications[0].application_type_id
          )
        );
      }

      dispatch(onBadgeUpdateCountIncrease());
    } else {
      dispatch(onApplicationChange(null));
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllApplicationsWithPagination();
  }, [
    applicationOrApprovalTypeValue,
    applicationTypeId,
    applicationStatusTypeId,
    recordMonth,
    pageNum,
    formSubmitCount,
    language,
  ]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onApplicationActionDialogCanceled}
        onDialogConfirmed={onApplicationActionDialogConfirmed}
        // States
        dialogText={applicationActionDialogText}
        showDialog={showApplicationActionDialog}
      />
      {/* Menu */}
      <ApplicationActionMenu
        // States
        showApplicationActionMenu={showApplicationActionMenu}
        // Set States
        setShowApplicationActionMenu={setShowApplicationActionMenu}
        // Fucntions
        displayApplicationActionDialog={displayApplicationActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("申請紀錄s")}
          </Typography>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item) => (
            <ApplicationBox
              key={item.id}
              // States
              boxType={applicationOrApprovalTypeValue}
              currentItemId={currentItemId}
              hoveredItemId={hoveredItemId}
              isMenuAvailable={isMenuAvailable}
              item={item}
              // Events
              onItemClicked={onItemClicked}
              onItemRightClicked={onItemRightClicked}
              onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
          <Stack className={classes.paginationStack} spacing={2}>
            <Pagination
              color={"primary"}
              count={pageCount}
              onChange={(event, page) => onPageChange(page)}
              page={pageNum}
            />
          </Stack>
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有申請資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default ApplicationBoxesContainer;
