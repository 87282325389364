export const languageEnUs = {
  // Data
  answerTextCh: "answerTextEn",
  application_dept_type_name_ch: "application_dept_type_name_en",
  application_procedure_name_ch: "application_procedure_name_en",
  applicationProcedureNameCh: "applicationProcedureNameEn",
  application_staff_type_name_ch: "application_staff_type_name_en",
  application_status_type_name_ch: "application_status_type_name_en",
  application_type_name_ch: "application_type_name_en",
  area_name_ch: "area_name_en_full",
  attendance_rule_type_name_ch: "attendance_rule_type_name_en",
  attendance_type_name_ch: "attendance_type_name_en",
  candidate_status_type_name_ch: "candidate_status_type_name_en",
  captionStrCh: "captionStrEn",
  company_name_ch: "company_name_en_full",
  contact_type_name_ch: "contact_type_name_en",
  day_off_type_count_type_name_ch: "day_off_type_count_type_name_en",
  day_off_type_name_ch: "day_off_type_name_en_full",
  day_off_type_paid_type_name_ch: "day_off_type_paid_type_name_en",
  day_off_type_payout_type_name_ch: "day_off_type_payout_type_name_en",
  day_off_type_request_type_name_ch: "day_off_type_request_type_name_en",
  day_off_type_rule_type_name_ch: "day_off_type_rule_type_name_en",
  day_off_type_settle_type_name_ch: "day_off_type_settle_type_name_en",
  degree_name_ch: "degree_name_en",
  department_name_ch: "department_name_en_full",
  district_name_ch: "district_name_en",
  division_name_ch: "division_name_en",
  edited_question_text_ch: "edited_question_text_en",
  education_level_name_ch: "education_level_name_en",
  end_weekday_name_ch_full: "end_weekday_name_en_short",
  gender_type_name_ch: "gender_type_name_en_full",
  group_title_text_ch: "group_title_text_en",
  holiday_name_ch: "holiday_name_en",
  main_answer_text_ch: "main_answer_text_en",
  messageCh: "messageEn",
  option_text_ch: "option_text_en",
  permission_dept_type_name_ch: "permission_dept_type_name_en",
  permission_name_ch: "permission_name_en",
  permission_staff_type_name_ch: "permission_staff_type_name_en",
  permission_type_name_ch: "permission_type_name_en",
  question_text_ch: "question_text_en",
  relocation_type_name_ch: "relocation_type_name_en",
  salary_count_type_name_ch: "salary_count_type_name_en",
  shop_name_ch: "shop_name_en",
  staff_contract_type_name_ch: "staff_contract_type_name_en",
  staff_status_type_name_ch: "staff_status_type_name_en",
  start_weekday_name_ch_full: "start_weekday_name_en_short",
  sub_answer_text_ch_1: "sub_answer_text_en_1",
  sub_answer_text_ch_2: "sub_answer_text_en_2",
  sub_name_ch: "sub_name_en",
  textCh: "textEn",
  timeStrCh: "timeStrEn",
  title_name_ch: "title_name_en_full",
  type_ch: "type_en",
  weekday_name_ch_full: "weekday_name_en_full",
  weekday_name_ch_short: "weekday_name_en_short",
  working_place_name_ch: "working_place_name_en_full",
  working_place_type_name_ch: "working_place_type_name_en",
  // Moment
  "a h:mm": "h:mm a",
  "H:mm": "H:mm",
  "MM月 DD日": "DD MMM",
  "YYYY年 MM月": "MMM YYYY",
  "YYYY年 MM月 DD日": "DD MMM YYYY",
  // Text
  本月生日: "Birthday",
  即將入職: "Onboarding",
  即將通過試用期: "Passing Probation",
  即將離職: "Resignation",
  搜尋: "Search",
  "確認要 登出 嗎？": "Confirm to Logout?",
  "Change to ": "設換至",
  顯示選單: "Show Menu",
  設換至夜間模式: "Change to Dark Theme",
  設換至日間模式: "Change to Light Theme",
  確認要取消: "Confirm to Cancel",
  全部s: "All",
  品牌s: "Brands",
  公司s: "Companies",
  部門s: "Departments",
  店鋪s: "Shops",
  員工s: "Staffs",
  應徵者s: "Candidates",
  職位s: "Titles",
  "入職 並 刪除 員工嗎？": "'s Onboard and Delete Staff?",
  "離職 並 刪除 員工嗎？": "'s Resignation?",
  未有員工資料: "No Staff Data",
  "申請 / 流程s": "Applications",
  出勤紀錄s: "Attendances",
  "公司 / 品牌s": "Companies & Brands",
  主頁: "Dashboard",
  假期管理s: "Day Offs",
  "部門 / 店鋪s": "Departments & Shops",
  權限s: "Permissions",
  更表s: "Rosters",
  薪酬s: "Salaries",
  "工作時段 / 地點s": "Working Periods & Places",
  應徵者概覧: "Candidate Profile",
  新增員工: "Create Staff",
  員工概覧: "Staff Profile",
  統計: "Statistics",
  概要: "Summary",
  登出: "Logout",
  個人檔案: "Profile",
  取消入職: "Cancel Onboard",
  取消離職: "Cancel Resign",
  重新入職: "Rejoin",
  設為離職: "Resign",
  未能提交: "Unable to Submit",
  確認要將: "Confirm to set",
  設為: "to ",
  "嗎？": "?",
  確定: "Submit",
  "生效日期 (日/月/年)": "Effective Date (DD/MM/YY)",
  請先填寫: "Please fill in",
  " 日期": " Date",
  離職: "Resign",
  關鍵字: "Keyword",
  搜尋範圍: "Search Field",
  未有相關資料: "No Related Data",
  顯示有效: "Show Active",
  顯示已刪除: "Show Inactive",
  " 部門s": " Departments",
  " 店鋪s": " Shops",
  顯示現職員工s: "Show Incumbent Staffs",
  顯示已離職員工s: "Show Resigned Staffs",
  "確認要刪除 部門 嗎？": "Confirm to Delete Department?",
  "確認要刪除 店鋪 嗎？": "Confirm to Delete Shop?",
  "確認要還原 部門 嗎？": "Confirm to Restore Department?",
  "確認要還原 店鋪 嗎？": "Confirm to Restore Shop?",
  品牌: "Brand",
  " 部門": " Department",
  " 店鋪": " Shop",
  新增: "Create",
  顯示: "Show",
  " 部門資料": " Department Info",
  " 店鋪資料": " Shop Info",
  " 員工列表": " Staff List",
  未有: "No",
  " 資料": " Data",
  "確認要取消 入職 並 刪除 員工嗎？":
    "Confirm to Cancel Onboard and Delete Staff?",
  "確認要取消 離職 嗎？": "Confirm to cancel Resignation?",
  "確認要 移除 主管嗎？": "Confirm to Remove Department Head?",
  "確認要 設為 主管嗎？": "Confirm to Set to Department Head?",
  員工列表: "Staff List",
  取消: "Cancel",
  確認: "Confirm",
  移除主管: "Remove Department Head",
  設為主管: " Set Department Head",
  部門主管: "Department Head",
  店鋪主管: "Shop Manager",
  "請先填寫 英文全名": "Please fill in English Full Name",
  "請先填寫 英文簡稱": "Please fill in English Short Name",
  "確認要新增 部門 嗎？": "Confirm to Create Department?",
  "確認要編輯 部門資料 嗎？": "Confirm to Edit Department?",
  成功提交: "Submitted",
  "此 部門 已存在": "This Department Already exists",
  部門資料: "Department Info",
  中文名稱: "Chinese Name",
  英文簡稱: "English Name (Short)",
  英文全名: "English Name (Full)",
  有效: "Is Active",
  修改: "Edit",
  "請先填寫 品牌": "Please fill in Brand",
  "請先填寫 店舖代號": "Please fill in Shop Code",
  "確認要新增 店舖 嗎？": "Confirm to Create Shop?",
  "確認要編輯 店舖資料 嗎？": "Confirm to Edit Shop Info?",
  "此 店舖 已存在": "This SHop Already Exists",
  店舖資料: "Shop Info",
  店舖代號: "Shop Code",
  未有相關員工: "No Related Staff",
  地址: "Address",
  區域: "Area",
  地區: "District",
  中文地址: "Chinese Address",
  英文地址: "English Address",
  "確認要刪除 Wi-Fi 嗎？": "Confirm to Delete Wi-Fi?",
  編輯: "Edit",
  刪除: "Delete",
  "新增 Wi-Fi": "Create Wi-Fi",
  "未有 Wi-Fi 資料": "No Wi-Fi Data",
  "IP 地址": "IP Address",
  "請先填寫 IP 地址": "Please fill in IP Address",
  "確認要新增 Wi-Fi 嗎？": "Confirm to Create Wi-Fi?",
  "確認要編輯 Wi-Fi 嗎？": "Confirm to Edit Wi-Fi?",
  "編輯 Wi-Fi": "Edit Wi-Fi",
  "確認要刪除 營業時間 嗎？": "Confirm to Delete Business Hour",
  營業時間s: "Business Hours",
  新增營業時間: "Create Business Hour",
  未有營業時間資料: "No Business Hour Data",
  至: "to",
  " 及 公眾假期": " & Public Holidays",
  休息: "Closed",
  "請先填寫 營業時間": "Please fill in Business Hour",
  "請先填寫 工作日": "Please fill in Weekday",
  "確認要新增 營業時間 嗎？": "Confirm to Create Business Hour?",
  "確認要編輯 營業時間 嗎？": "Confirm to Edit Business Hour?",
  編輯營業時間: "Edit Business Hour",
  工作日: "Weekday",
  營業時間: "Business Hour",
  包括公眾假期: "Include Public Holidays",
  "確認要刪除 聯絡方式 嗎？": "Confirm to Delete Contact?",
  聯絡方式s: "Contacts",
  新增聯絡方式: "Create Contact",
  未有聯絡方式資料: "No Contact Data",
  " (後備)": " (Backup)",
  "請先填寫 聯絡號碼": "Please fill in Contact Num",
  "請先填寫 聯絡類別": "Please fill in Contact Type",
  "確認要新增 聯絡方式 嗎？": "Confirm to Create Contact?",
  "確認要編輯 聯絡方式 嗎？": "Confirm to Edit Contact?",
  編輯聯絡方式: "Edit Contact",
  後備: "Backup",
  聯絡號碼: "Contact Num",
  聯絡類別: "Contact Type",
  工作時段s: "Working Periods",
  工作地點s: "Working Locations",
  "確認要刪除 工作時段 嗎？": "Confirm to Delete Working Period?",
  "設為一般時段嗎？": "to Normal Period?",
  "確認要還原 工作時段 嗎？": "Confirm to Restore Working Period?",
  "設為預設時段嗎？": "to Default Period",
  新增工作時段: "Create Working Periods",
  未有工作時段資料: "No Working Period Data",
  設為一般時段: "Set to Normal Period?",
  設為預設時段: "Set to Default Period?",
  還原: "Restore",
  預設時段: "Default Period",
  一般時段: "Normal Period",
  "請先填寫 工作時段代號": "Please fill in Working Period Code",
  "確認要新增 工作時段 嗎？": "Confirm to Create Working Period?",
  "確認要編輯 工作時段資料 嗎？": "Confirm to Edit Working Period?",
  "此 工作時段 已存在": "This Working Period Already Exists",
  工作時段資料: "Working Period Info",
  工作時段代號: "Working Period Code",
  "確認要刪除 工作時間 嗎？": "Confirm to Delete Working Hour?",
  工作時間s: "Working Hours",
  新增工作時間: "Create Working Hour",
  未有工作時間資料: "No Working Hour Data",
  長短週: "Alternate Week",
  一般: "Normal",
  本週為長週: "This week is Long Week",
  本週為短週: "This week is Short Week",
  "請先填寫 工作時間": "Please fill in Working Hour",
  "請先填寫 午飯時間": "Please fill in Lunch Hour",
  "確認要新增 工作時間 嗎？": "Confirm to Create Working Hour?",
  "確認要編輯 工作時間 嗎？": "Confirm to Edit Working Hour?",
  編輯工作時間: "Edit Working Hour",
  工作時間: "Working Hour",
  午飯時間: "Lunch Hour",
  "確認要從新增 員工 到 工作時段 嗎？":
    "Confirm to Add Staff to Working Period?",
  "確認要從 工作時段 刪除 員工 嗎？":
    "Confirm to Remove Staff from Working Period?",
  所有: "All",
  已選擇: "Selected",
  " 員工s": " Staffs",
  移除: "Remove",
  "預設時段 不能 新增員工": "Cannot Add Staff to Default Period",
  "確認要刪除 工作地點 嗎？": "Confirm to Delete Working Place?",
  "設為一般地點嗎？": "to Normal Place?",
  "確認要還原 工作地點 嗎？": "Confirm to Restore Working Place?",
  "設為預設地點嗎？": "to Default Place?",
  工作地點類別: "Working Place Type",
  新增工作地點: "Create Working Place",
  未有工作地點資料: "No Working Place Data",
  設為一般地點: "Set to Normal Place",
  設為預設地點: "Set to Default Place",
  預設地點: "Default Place",
  一般地點: "Normal Place",
  "請先填寫 中文名稱": "Please fill in Chinese Name",
  "請先填寫 工作地點類別": "Please fill in Working Place Type",
  "確認要新增 工作地點 嗎？": "Confirm to Create Working Place?",
  "確認要編輯 工作地點資料 嗎？": "Confirm to Edit Working Place?",
  "此 工作地點 已存在": "This Working Place Already Exists",
  工作地點資料: "Working Place Info",
  "確認要從新增 員工 到 工作地點 嗎？":
    "Confirm to Add Staff to Working Place?",
  "確認要從 工作地點 刪除 員工 嗎？":
    "Confirm to Remove Staff from Working Place?",
  "預設地點 不能 新增員工": "Cannot Add Staff to Default Place",
  清除已選擇員工: "Clear Selected Staffs",
  "顯示 ": "Show ",
  調動s: "Relocations",
  薪金合約s: "Salary Contracts",
  員工: "Staff",
  查看應徵資料: "View Candidate Info",
  試用期: "Probation",
  " 個月s": " Months",
  "請先填寫 出生日期": "Please fill in Date of Birth",
  "請先填寫 性別": "Please fill in Gender",
  "請先填寫 身分證編號": "Please fill in HKID",
  "請先填寫 員工編號": "Please fill in Staff Code",
  "確認要新增 員工 嗎？": "Confirm to Create Staff?",
  "確認要編輯 員工資料 嗎？": "Confirm to Edit Staff?",
  "此 員工編號 已存在": "This Staff Code Already Exists",
  基本資料: "Basic Info",
  員工編號: "Staff Code",
  別名: "Alias",
  性別: "Gender",
  身分證編號: "HKID",
  "出生日期 (日/月/年)": "Date of Birth (DD/MM/YY)",
  "入職日期 (日/月/年)": "Date Onboard (DD/MM/YY)",
  "離職日期 (日/月/年)": "Date Resigned (DD/MM/YY)",
  電話號碼: "Phone Num",
  電郵地址: "Email",
  住宅地址: "Address",
  "確認要取消 調動 嗎？": "Confirm to Cancel Relocation?",
  "確認要取消 調動 並刪除 員工 嗎？":
    "Confirm to Cancel Relocation and Delete Staff?",
  調動紀錄s: "Relocations",
  新增調動: "Create Relocation",
  未有調動紀錄: "No Relocation Data",
  "請先填寫 公司": "Please fill in Company",
  "請先填寫 類別": "Please fill in Division",
  "請先填寫 部門": "Please fill in Department",
  "請先填寫 店舖": "Please fill in Shop",
  "請先填寫 生效日期": "Please fill in Effective Date",
  "請先填寫 調動類型": "Please fill in Relocation Type",
  "請先填寫 職位": "Please fill in Title",
  "確認要新增 調動 嗎？": "Confirm to Create Relocation?",
  "確認要編輯 調動資料 嗎？": "Confirm to Edit Relocation?",
  調動資料: "Relocation Info",
  新增調動資料: "Create Relocation Info",
  調動類型: "Relocation Type",
  公司: "Company",
  類別: "Division",
  部門: "Department",
  店舖: "Shop",
  職位: "Title",
  取消調動: "Cancel Relocation",
  "確認要取消 薪金合約 嗎？": "Confirm to Cancel Contract?",
  薪金合約紀錄s: "Salary Contracts",
  新增薪金合約: "Create Salary Contract",
  未有薪金合約紀錄: "No Salary Contract Data",
  "請先填寫 生效月份": "Please fill in Effective Month",
  "請先填寫 固定工資": "Please fill in Fixed Salary",
  "固定工資 必須為數字": "Fixed Salary must be Number",
  "請先填寫 工資單位": "Please fill in Salary Count Type",
  "請先填寫 合約類型": "Please fill in Contract Type",
  "確認要新增 員工合約 嗎？": "Confirm to Create Contract?",
  "確認要取消 員工合約 嗎？": "Confirm to Cancel Contract?",
  "確認要編輯 員工合約 嗎？": "Confirm to Edit Contract?",
  員工合約資料: "Contract Info",
  合約類型: "Contract Type",
  工資單位: "Salary Count Type",
  固定工資: "Fixed Salary",
  有佣金: "With Commission",
  午飯計入工時: "Is Lunch Hour Included",
  "生效月份 (月/年)": "Effective Month (MM/YY)",
  取消員工合約: "Cancel Contract",
  概要s: "Summaries",
  異常紀錄s: "Abnormal Records",
  規則s: "Rules",
  返回本日: "Back to Today",
  "日期 (日/月/年)": "Date (DD/MM/YY)",
  返回本月: "Back to Current Month",
  "月份 (月/年)": "Month (MM/YY)",
  未有出勤紀錄資料: "No Attendance Data",
  未入職: "Not Joined",
  已離職: "Resigned",
  無需簽到: "Attendance Not Required",
  已豁免: "Exempted",
  遲到: "Late",
  早退: "Early Leave",
  "分鐘s / 地點錯誤": "Mins / Wrong Location",
  分鐘s: "Mins",
  地點錯誤: "Wrong Location",
  欠簽到紀錄: "No Attendance Record",
  遲到次數s: "Num of Late Arrives",
  遲到時數s: "Hours of Late Arrives",
  早退次數s: "Num of Early Leaves",
  早退時數s: "Hours of Early Leaves",
  假期s: "Day Offs",
  已上班日數s: "Num of Working Days",
  曠工日數s: "Num of Absent Days",
  "(分鐘)": "(Min)",
  "(不含公眾假期)": "(Not Include Public Holidays)",
  "(公眾假期)": "(Public Holidays)",
  未有概要資料: "No Summary Data",
  簽到類別: "Attendance Type",
  地點類別: "Location Type",
  工作地點: "Working Location",
  全部地點s: "All",
  店舖s: "Shops",
  自訂地點s: "Custom Locations",
  "確認要取消豁免 簽到紀錄 嗎？":
    "Confirm to Cancel Exempting Attendance Record?",
  "確認要豁免 簽到紀錄 嗎？": "Confirm to Exempt Attendance Record?",
  異常紀錄: "Abnormal Records",
  已豁免紀錄: "Exempted Records",
  " 紀錄": " Records",
  豁免: "Exempt",
  取消豁免: "Cancel Exemption",
  未有簽到紀錄資料: "No Attendance Record Data",
  不適用: "N/A",
  簽到類別s: "Attendance Types",
  未有簽到規則資料: "No Attendance Type Data",
  "確認要編輯 規則資料 嗎？": "Confirm to Edit Attendance Rules?",
  "此 規則 已存在": "This Rule Already Exists",
  規則設定s: "Rule Settings",
  需要簽到: "Attendance Required",
  "確認要刪除 簽到規則 嗎？": "Confirm to Delete Attendance Rule?",
  簽到規則s: "Attendance Rules",
  新增簽到規則: "Create Attendance Rule",
  "連續 ": "",
  次sC: "Times Consecutively",
  次s: "Times",
  總時數: "Total Hours",
  總次數: "Total",
  "請先填寫 時數": "Please fill in Amount of Time",
  "請先填寫 規則類別": "Please fill in Attendance Rule Type",
  "請先填寫 次數": "Please fill in Num of Times",
  "請先填寫 簽到類別s": "Please fill in Attendance types",
  "請先填寫 套用到s": "Please fill in Applicable Divisions",
  "確認要新增 簽到規則 嗎？": "Confirm to Create Attendance Rule?",
  "確認要編輯 簽到規則 嗎？": "Confirm to Edit Attendance Rule?",
  編輯簽到規則: "Edit Attendance Rule",
  規則類別: "Attendance Rule Type",
  套用到s: "Applicable Divisions",
  計算方式: "Computation",
  總次數達s: "Total Number of Times",
  連續次數達s: "Consecutive Number of Times",
  次數s: "Number of Times",
  總時數達s: "Total Amount of Hours",
  每次時數達: "Amount of Hours Each Time",
  時數s: "Amount of Hours",
  "確認要豁免此 簽到紀錄？": "Confirm to Exempt Attendance Record?",
  "確認要將此 簽到紀錄 用作考勤？":
    "Confirm to use this Record for Attendance?",
  詳細簽到紀錄s: "Attendance Records",
  正常: "Normal",
  用作考勤: "For Attendance",
  "確認要從新增 員工 到 無需簽到名單 嗎？":
    "Confirm to Add Staff to Attendance Exemption List?",
  "確認要從 無需簽到名單 刪除 員工 嗎？":
    "Confirm to Remove Staff from Attendace Exemption List?",
  無需簽到員工: "Attendance Exemption List",
  新增員工A: "Add Staff",
  " 工作時段s": " Working Periods",
  " 值更s": " Shifts",
  值更s: "Shifts",
  假期種類s: "Day Off Types",
  "確認要刪除 值更 嗎？": "Confirm to Delete Shift?",
  "確認要還原 值更 嗎？": "Confirm to Restore Shift?",
  新增值更: "Create Shift",
  "未有 ": "No ",
  "請先填寫 值更代號": "Please fill in Shift Code",
  "確認要新增 值更 嗎？": "Confirm to Create Shift?",
  "確認要編輯 值更 嗎？": "Confirm to Edit Shift?",
  編輯值更: "Edit Shift",
  值更代號: "Shift Code",
  所有店舖: "All Shops",
  只限此店舖: "This Shop Only",
  "未能編更, 編更錯誤": "Unable to Edit Roster",
  "確認要 快速編更 嗎？ 快速編更 將覆蓋現有更表。":
    "Confirm to Quick Edit and Overwrite Current Roster?",
  快速編更: "Quick Edit",
  清除已選擇紀錄: "Clear Selected Rosters",
  未有更表資料: "No Roster Data",
  員工未入職: "Staff Not Joined",
  員工已離職: "Staff Resigned",
  "確認要刪除 安排 嗎？": "Confirm to Delete Arrangement?",
  延遲: "Delay",
  提早: "Earlier",
  特別安排s: "Arrangements",
  新增安排: "Create Arrangement",
  所有工作時段: "All Working Periods",
  所有值更: "All Shifts",
  工作: "Work",
  午飯: "Lunch",
  "開始 - ": "Start - ",
  "結束 - ": "End - ",
  無安排: "No Arrangement",
  "請先填寫 值更": "Please fill i  Shift",
  "請先填寫 工作時段": "Please fill in Working Period",
  "請先填寫 開始時間 或 結束時間": "Please fill in Start Time or End Time",
  "確認要新增 特別安排 嗎？": "Confirm to Create Arrangement?",
  "確認要編輯 特別安排 嗎？": "Confirm to Edit Arrangement?",
  "已有相關 安排": "Arrangement Already Exists",
  新增特別安排: "Create Arrangement",
  套用到: "Applied to",
  特定工作時段: "Custom Working Period",
  所有店鋪: "All Shops",
  此店鋪: "This Shop",
  特定值更: "Custom Shift",
  工作時段: "Working Period",
  值更: "Shift",
  開始時間: "Start Time",
  確實時間: "Actual Time",
  結束時間: "End Time",
  申請紀錄s: "Applications",
  審批流程s: "Procedures",
  入職申請s: "Job Applications",
  "申請 / 審批s": "Applications / Approvals",
  申請類別: "Application Type",
  申請狀態: "Application Status",
  "確認要批准 申請 嗎？": "Confirm to Approve Application?",
  "確認要取消 申請 嗎？": "Confirm to Cancel Application?",
  "確認要修改 申請 嗎？": "Confirm to Edit Application?",
  "確認要駁回 申請 嗎？": "Confirm to Reject Application?",
  未有申請資料: "No Application Data",
  批准: "Approve",
  駁回: "Reject",
  程序: "Procedure",
  申請d: "Applied",
  提交錯誤: "Unable to Submit",
  不能更改此項目: "Cannot Change this Field",
  未有申請內容: "No Application Details",
  未有相關檔案: "No File",
  "載入中...": "Loading...",
  "(日/月/年)": "(DD/MM/YY)",
  "確認要刪除 程序 嗎？": "Confirm to Delete Procedure?",
  程序s: "Procedures",
  新增程序: "Create Procedure",
  未有程序資料: "No Procedure Data",
  指定員工s: "Specific Staffs",
  "請先填寫 部門 / 店鋪類型": "Please fill in Department / Shop Type",
  "請先填寫 員工類型": "Please fill in Staff Type",
  "請先填寫 部門 / 店鋪": "Please fill in Department / Shop",
  "請先填寫 英文名稱": "Please fill in English Name",
  "確認要新增 程序 嗎？": "Confirm to Create Procedure?",
  "確認要編輯 程序資料 嗎？": "Confirm to Edit Procedure?",
  "此 程序 已存在": "This Procedure Already Exists",
  程序資料: "Procedure Info",
  英文名稱: "English Name",
  "部門 / 店鋪類型": "Department / Shop type",
  員工類型: "Staff type",
  未有相關類別: "No Related Type",
  未有相關部門: "No Related Department",
  未有相關職位: "No Related Title",
  "確認要從新增 員工 到 程序 嗎？": "Confirm to Add Staff to Procedure?",
  "確認要從 程序 刪除 員工 嗎？": "Confirm to Remove Staff from Procedure?",
  應徵狀態: "Application Status",
  "確認應徵者 婉拒 錄取嗎？": "Confirm Candidate to Turn Down Offer?",
  "確認要將 應徵者 設為候補嗎？": "Confirm to Put Candidate to Waiting List?",
  "確認要 駁回 應徵者嗎？": "Confirm to Reject Candidate?",
  工作經驗: "Work Experience",
  未有工作經驗: "No Work Experience",
  錄取: "Offer",
  設為候補: "Put to Waiting List",
  應徵者婉拒: "Candidate Turn Down",
  正式録取: "Officially Admit",
  未有入職申請: "No Job Application Data",
  歲: "Years Old",
  可上班: "Available",
  "請先填寫 入職日期": "Please fill in Onboard Date",
  "確認要編輯 薪資待遇 嗎？": "Confirm to Edit Offer?",
  "確認要 錄取 應徵者嗎？": "Confirm to Offer Candidate?",
  編輯薪資待遇: "Edit Offer",
  新增薪資待遇: "Create Offer",
  "聯絡方式 / 住宅地址": "Contact / Address",
  薪金合約: "Package",
  "請先填寫 試用期": "Please fill in Probation Length",
  下一步: "Next Step",
  上一步: "Previous Step",
  聯絡方式: "Contact",
  "確認要刪除 照片 嗎？": "Confirm to Delete Photo?",
  檔案不可超過: "File cannot Exceed",
  "未能上載，請再試一次": "Unable to Upload",
  應徵者: "Candidate",
  查看員工資料: "View Staff Info",
  未有應徵者資料: "No Candidate Data",
  狀態: "Status",
  照片: "Photo",
  上載照片: "Upload Photo",
  刪除照片: "Delete Photo",
  期望薪金: "Expected Salary",
  薪資待遇: "Package",
  未有薪資待遇資料: "No Package Data",
  入職: "Onboard",
  無佣金: "Without Commission",
  "確認要編輯 應徵者資料 嗎？": "Confirm to Edit Candidate Info?",
  "請先填寫 別名": "Please fil in Alias",
  "請先填寫 教育程度": "Please fil in Education Level",
  "請先填寫 電話號碼": "Please fil in Phone Num",
  "請先填寫 英文地址": "Please fil in English Address",
  "請先填寫 申請職位": "Please fil in Applied Position",
  "請先填寫 期望工資": "Please fil in Expected Salary",
  "期望工資 必須為數字": "Expected Salary must be Number",
  "請先填寫 最快可入職日期": "Please fil in Date Available",
  教育程度: "Education Level",
  申請詳情: "Application Details",
  申請職位: "Applied Position",
  期望工資: "Expected Salary",
  "最快可入職日期 (日/月/年)": "Date Available (DD/MM/YY)",
  "確認要刪除 技能 嗎？": "Confirm to Delete Skill?",
  技能s: "Skills",
  技能: "Skill",
  新增技能: "Create Skill",
  未有技能資料: "No Skill Data",
  未有描述: "No Description",
  "請先填寫 技能名稱": "Please fill in Skill Name",
  "確認要新增 技能 嗎？": "Confirm to Create Skill?",
  "確認要編輯 技能 嗎？": "Confirm to Edit Skill?",
  編輯技能: "Edit Skill",
  技能名稱: "Skill Name",
  "描述 (可不填)": "Description (Can be Blank)",
  查看證明文件: "View Documentary Proofs",
  "未能刪除，請再試一次": "Unable to Delete",
  "確認要刪除 證明文件 嗎？": "Confirm to Delete Documentary Proof?",
  證明文件s: "Documentary Proofs",
  未有證明文件: "No Documentary Proof",
  上載證明文件: "Upload Documentary Proof",
  檔案: "File",
  開啟: "Open",
  "確認要刪除 工作經驗 嗎？": "Confirm to Delete Work Experience?",
  工作經驗s: "Work Experiences",
  新增工作經驗: "Create Work Experience",
  未有工作經驗資料: "No Work Experience Data",
  " 至今": " Until Now",
  "未滿 1 個月": "Within 1 Month",
  "請先填寫 公司名稱": "Please fill in Company Name",
  "請先填寫 開始月份": "Please fill in Start Month",
  "請先填寫 工資": "Please fill in Salary",
  "工資 必須為數字": "Salary must be Number",
  "確認要新增 工作經驗 嗎？": "Confirm to Create Work Experience?",
  "確認要編輯 工作經驗 嗎？": "Confirm to Edit Work Experience?",
  編輯工作經驗: "Edit Work Experience",
  公司名稱: "Company Name",
  "開始月份 (月/年)": "Start Month (MM/YY)",
  "結束月份 (月/年) (如仍在職，可不填)":
    "End Month (MM/YY) (Can be Blank if Still Working)",
  "工作內容 (可不填)": "Job Description (Can be Blank)",
  與應徵工作有關: "Related to Applied Position",
  工資: "Salary",
  "確認要刪除 教育 嗎？": "Confirm to Delete Education?",
  教育s: "Educations",
  教育: "Education",
  新增教育: "Create Education",
  未有教育資料: "No Education Data",
  "請先填寫 機構名稱": "Please fill in Institute Name",
  "請先填寫 學位": "Please fill in Degree",
  "確認要新增 教育 嗎？": "Confirm to Create Education?",
  "確認要編輯 教育 嗎？": "Confirm to Edit Education?",
  編輯教育: "Edit Education",
  機構名稱: "Institute Name",
  學位: "Degree",
  學位名稱: "Degree Name",
  "結束月份 (月/年) (如仍在學，可不填)":
    "End Month (MM/YY) (Can be Blank if Still Studying)",
  " 公司s": " Companies",
  " 品牌s": " Brands",
  "確認要刪除 品牌 嗎？": "Confirm to Delete Brand?",
  "確認要刪除 公司 嗎？": "Confirm to Delete Company?",
  "確認要還原 品牌 嗎？": "Confirm to Restore Brand?",
  "確認要還原 公司 嗎？": "Confirm to Restore Company?",
  "確認要新增 品牌 嗎？": "Confirm to Create Brand?",
  "確認要新增 公司 嗎？": "Confirm to Create Company?",
  "確認要編輯 品牌資料 嗎？": "Confirm to Edit Brand Info?",
  "確認要編輯 公司資料 嗎？": "Confirm to Edit Company Info?",
  "此 品牌 已存在": "This Brand Already Exist",
  "此 公司 已存在": "This Company Already Exist",
  " 資料i": " Info",
  顯示有效職位: "Show Active Titles",
  顯示已刪除職位: "Show Inactive Titles",
  "確認要刪除 職位 嗎？": "Confirm to Delete Title?",
  "確認要還原 職位 嗎？": "Confirm to Restore Title?",
  新增職位: "Create Title",
  未有職位資料: "No Title Data",
  "確認要新增 職位 嗎？": "Confirm to Create Title?",
  "確認要編輯 職位資料 嗎？": "Confirm to Edit Title?",
  "此 職位 已存在": "This Title Already Exist",
  職位資料: "Title Info",
  剩餘數量s: "Quotas",
  顯示有效假期種類: "Show Active Day Off types",
  顯示已刪除假期種類: "Show Inactive Day Off types",
  "確認要刪除 假期種類 嗎？": "Confirm to Delete Day Off Type?",
  "確認要還原 假期種類 嗎？": "Confirm to Restore Day Off Type?",
  新增假期種類: "Create Day Off Type",
  未有假期種類資料: "No Day Off Type Data",
  "請先填寫 計算方式": "Please fill in Rule Type",
  "請先填寫 計算單位": "Please fill in  Count Type",
  "請先填寫 薪金": "Please fill in Paid Type",
  "請先填寫 發放方式": "Please fill in  Payout Type",
  "請先填寫 最多累積數量": "Please fill in  Max Accumulative Num",
  "請先填寫 申請方式": "Please fill in  Request Type",
  "確認要新增 假期種類 嗎？": "Confirm to Create Day Off Type?",
  "確認要編輯 假期種類資料 嗎？": "Confirm to Edit Day Off Type?",
  "此 假期種類 已存在": "This Day Off Type Already Exist",
  假期種類資料: "Day Off Type Info",
  計算方式r: "Rule Type",
  計算單位: "Count type",
  發放方式: "Payout Type",
  薪金p: "Paid Type",
  結算方式: "Settlement",
  可累積: "Accumulative",
  最多累積數量: "Max Accumulative Number",
  結算方式s: "Settlement Type",
  每年結算月份: "Settlement Month (per Year)",
  每月結算日期: "Settlement Date (per Month)",
  申請與審批s: "Application And Approval",
  申請方式: "Request Type",
  員工可自行申請: "Can be Applied by Staff",
  試用期內可申請: "Available within Probation",
  "確認要刪除 假期規則 嗎？": "Confirm to Delete Day Of Type Rule?",
  假期規則s: "Day Off Type Rules",
  新增假期規則: "Create Day Off Type Rule",
  未有假期規則資料: "No Day Off Type Rule Data",
  固定數量: "Fixed Number",
  已滿: "Over",
  "未滿 1 年": "Under 1 Year",
  年s: "Years",
  由超時補上: "From Overtime",
  生日月份: "Birthday Month",
  每every: "Every",
  "小時補上 1 日": "Hours for 1 Day",
  日s: "Days",
  "請先填寫 年資": "Please fill in Seniority",
  "請先填寫 小時": "Please fill in Hours",
  "請先填寫 假期數量": "Please fill in Number",
  "確認要新增 假期規則 嗎？": "Confirm to Create Day Off Type Rule?",
  "確認要編輯 假期規則 嗎？": "Confirm to Edit Day Off Type Rule?",
  "已有相關 假期規則": "This Rule Already Exist",
  編輯假期規則: "Edit Day Off Type Rule",
  年資: "Seniority",
  "小時 (每特定小時補上 1 日)": "Hours (Every X Hours for 1 Day)",
  假期數量: "Day Off Number",
  剩餘假期數量s: "Quotas",
  未有剩餘假期數量資料: "No Quota Data",
  設定s: "Settings",
  設定類別: "Setting Type",
  "請先填寫 月薪計算方式": "Please fill in Monthly Salary Count Type",
  "確認要編輯 薪金計算設定 嗎？":
    "Confirm to Edit Salary Computation Settings?",
  薪金計算設定s: "Salary Computation Settings",
  月薪計算方式: "Monthly Salary Count Type",
  "試用期內，公眾假期有薪": "Is Public Holiday Paid within Probation",
  "確認要刪除 p群組 嗎？": "Confirm to Delete Permission Group?",
  系統: "System",
  權限: "Permission",
  p群組s: "Permission Groups",
  p群組: "Permission Group",
  新增p群組: "Create Permission Group",
  未有p群組資料: "No Permission Group Data",
  指定員工: "Specific Staff",
  "確認要從新增 員工 到 群組 嗎？": "Confirm to Add Staff to Permission Group?",
  "確認要從 群組 刪除 員工 嗎？":
    "Confirm to Remove Staff from Permission Group?",
  "確認要新增 群組 嗎？": "Confirm to Create Permission Group?",
  "確認要編輯 群組資料 嗎？": "Confirm to Edit Permission Group Info?",
  "此 群組 已存在": "This Permission Group Already Exist",
  群組資料: "Permission Group Info",
  未有相關店鋪: "No Related Shop",
  店鋪: "Shop",
  必須為數字: "must be Number",
  "確認要修改 員工 ": "Confirm to Edit Staff's ",
  "請先填寫 英文全名 或 員工編號":
    "Please fill in English Full Name / Staff Code",
  "請先填寫 密碼": "Please fill in Password",
  未有權限: "Permission Denied",
  未能登入: "Unable to Login",
  "英文全名 或 員工編號": "English Full Name / Staff Code",
  密碼: "Password",
  登入: "Login",
};
