// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/rostersPage/rostersPageActions";
import { onStaffChange } from "../../../redux/pages/staffProfilePage/staffProfilePageActions";

// Components
// Menus
import StaffToDayActionMenu from "../../menus/rostersPage/staffToDayActionMenu";
// Modals
import ArrangementActionModalContainer from "../../modals/rostersPage/arrangementActionModal/arrangementActionModalContainer";
import ArrangementListModal from "../../modals/rostersPage/arrangementListModal";
import ModalContainer from "../../modals/modalContainer";
// Snackbars
import AlertSnackbar from "../../snackbar/alertSnackbar";
// Tables
import DayBox from "./dayBox";
import StaffBox from "./staffBox";
import RosterBox from "./rosterBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import {
  tableContainerStyles,
  useStandardTableStyles,
} from "../../../styles/componentStyles/tableStyles/standardTableStyles";

function RosterTable(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useStandardTableStyles();

  // Props
  const {
    // States
    dayOffTypes,
    days,
    editedStaffToDays,
    hoveredDayId,
    hoveredStaffId,
    isSelectMode,
    selectedStaffToDays,
    shifts,
    staffWithRosters,
    workingPeriods,
    // Set States
    setHoveredDayId,
    setHoveredDayOffTypeId,
    setHoveredShiftId,
    setHoveredStaffId,
    setHoveredWorkingPeriodId,
    setIsSelectMode,
    setSelectedStaffToDays,
    // Events
    onAddStaffBtnClicked,
    // Functions
    createOrEditRoster,
  } = props;

  // Redux Store
  const divisionType = useSelector((state) => state.rostersPage.divisionType);
  const shopId = useSelector((state) => state.rostersPage.shopId);
  const shopItem = useSelector((state) => state.rostersPage.shopItem);
  const themeMode = useSelector((state) => state.theme.themeMode);

  // States
  // Data
  const [arrangementId, setArrangementId] = useState(null);
  const [dayId, setDayId] = useState(null);
  // Hover
  const [hoveredDayItemId, setHoveredDayItemId] = useState(null);
  const [hoveredStaffItemId, setHoveredStaffItemId] = useState(null);
  // Menu
  const [showStaffToDayActionMenu, setShowStaffToDayActionMenu] =
    useState(null);
  // Modals
  const [showArrangementActionModal, setShowArrangementActionModal] =
    useState(false);
  const [showArrangementListModal, setShowArrangementListModal] =
    useState(false);
  // Render
  const [arrangementFormSubmitCount, setArrangementFormSubmitCount] =
    useState(0);
  // Snackbar
  const [showStaffToDayActionSnackbar, setShowStaffToDayActionSnackbar] =
    useState(false);
  const [staffToDayActionSnackbarText, setStaffToDayActionSnackbarText] =
    useState("");
  const [staffToDayActionSnackbarType, setStaffToDayActionSnackbarType] =
    useState("success");

  // Events
  // Events - Box
  const onItemRightClicked = (staffId, dayId, currentTarget) => {
    // Set States
    if (selectedStaffToDays.length <= 1) {
      setSelectedStaffToDays([{ staffId, dayId }]);
    }

    // Set States
    setShowStaffToDayActionMenu(currentTarget);
  };

  const onMouseDown = (
    staffId,
    dayId,
    isBeforeDateJoin,
    isAfterDateResign,
    event
  ) => {
    if (event.button === 0) {
      if (isBeforeDateJoin) {
        displayStaffToDayActionSnackBar("DayBeforeDateJoin");
        return;
      } else if (isAfterDateResign) {
        displayStaffToDayActionSnackBar("DayAfterDateResign");
        return;
      }

      // Set States
      setIsSelectMode(true);

      if (staffId && dayId) {
        // Set States
        setSelectedStaffToDays((currentState) =>
          currentState.some(
            (item) => item.staffId === staffId && item.dayId === dayId
          )
            ? currentState.filter(
                (item) => item.staffId !== staffId || item.dayId !== dayId
              )
            : [...currentState, { staffId, dayId }]
        );
      }
    }
  };

  const onStaffItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onStaffChange(itemId));
    // Navigate
    navigate("/staffProfile");
  };

  // Events - Day Boxes
  const onDayBoxClicked = (itemId, itemArrangementid) => {
    // Set States
    setDayId(itemId);

    if (itemArrangementid) {
      displayArrangementListModal();
    } else {
      displayArrangementActionModal();
    }
  };

  // Events - Hover
  const onMouseEnterDayItem = (itemId) => {
    // Set States
    setHoveredDayItemId(itemId);
  };

  const onMouseEnterItem = (
    staffId,
    dayId,
    isBeforeDateJoin,
    isAfterDateResign,
    workingPeriodId,
    shiftId,
    dayOffTypeId
  ) => {
    // Set States
    setHoveredDayId(dayId);
    setHoveredDayOffTypeId(dayOffTypeId ? dayOffTypeId : null);
    setHoveredShiftId(shiftId ? shiftId : null);
    setHoveredStaffId(staffId);
    setHoveredWorkingPeriodId(workingPeriodId ? workingPeriodId : null);

    if (isSelectMode) {
      if (isBeforeDateJoin) {
        displayStaffToDayActionSnackBar("DayBeforeDateJoin");
        return;
      } else if (isAfterDateResign) {
        displayStaffToDayActionSnackBar("DayAfterDateResign");
        return;
      }

      // Set States
      setSelectedStaffToDays((currentState) =>
        currentState.some(
          (item) => item.staffId === staffId && item.dayId === dayId
        )
          ? currentState.filter(
              (item) => item.staffId !== staffId || item.dayId !== dayId
            )
          : [...currentState, { staffId, dayId }]
      );
    }
  };

  const onMouseEnterStaffItem = (itemId) => {
    // Set States
    setHoveredStaffItemId(itemId);
  };

  const onMouseLeaveDayItem = () => {
    // Set States
    setHoveredDayItemId(null);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredDayId(null);
    setHoveredDayOffTypeId(null);
    setHoveredShiftId(null);
    setHoveredStaffId(null);
    setHoveredWorkingPeriodId(null);
  };

  const onMouseLeaveStaffItem = () => {
    // Set States
    setHoveredStaffItemId(null);
  };

  // Events - Modal
  const onArrangementActionModalClosed = () => {
    // Set States
    setArrangementId(null);
    setShowArrangementActionModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  const onArrangementListModalClosed = () => {
    // Set States
    setShowArrangementListModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  // Events - Snackbar
  const onStaffToDayActionSnackbarClosed = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    // Set States
    setShowStaffToDayActionSnackbar(false);
  };

  // Functions
  // Functions - Normal
  const displayArrangementActionModal = () => {
    // Set States
    setShowArrangementActionModal(true);
  };

  const displayArrangementListModal = () => {
    // Set States
    setShowArrangementListModal(true);
  };

  const displayStaffToDayActionSnackBar = (staffToDayActionType) => {
    switch (staffToDayActionType) {
      case "DayBeforeDateJoin":
        setStaffToDayActionSnackbarText(t("員工未入職"));
        setStaffToDayActionSnackbarType("warning");
        break;
      case "DayAfterDateResign":
        setStaffToDayActionSnackbarText(t("員工已離職"));
        setStaffToDayActionSnackbarType("warning");
        break;
      default:
        break;
    }

    // Set States
    setShowStaffToDayActionSnackbar(true);
  };

  const increaseArrangemntFormSubmitCount = () => {
    // Set States
    setArrangementFormSubmitCount((currentState) => currentState + 1);
  };

  return (
    <TableContainer sx={tableContainerStyles}>
      {/* Menu */}
      <StaffToDayActionMenu
        // States
        divisionType={divisionType}
        dayOffTypes={dayOffTypes}
        shifts={shifts}
        showStaffToDayActionMenu={showStaffToDayActionMenu}
        workingPeriods={workingPeriods}
        // Set States
        setShowStaffToDayActionMenu={setShowStaffToDayActionMenu}
        // Functions
        createOrEditRoster={createOrEditRoster}
      />
      {/* Modals */}
      <ModalContainer
        // Events
        onModalClosed={onArrangementActionModalClosed}
        // States
        showModal={showArrangementActionModal}
      >
        <ArrangementActionModalContainer
          // Events
          onModalClosed={onArrangementActionModalClosed}
          // Functions
          increaseArrangemntFormSubmitCount={increaseArrangemntFormSubmitCount}
          // States
          arrangementId={arrangementId}
          dayId={dayId}
        />
      </ModalContainer>
      <ModalContainer
        // Events
        onModalClosed={onArrangementListModalClosed}
        // States
        showModal={showArrangementListModal}
      >
        <ArrangementListModal
          // Functions
          displayArrangementActionModal={displayArrangementActionModal}
          // Set States
          setArrangementId={setArrangementId}
          // States
          arrangementFormSubmitCount={arrangementFormSubmitCount}
          arrangementId={arrangementId}
          dayId={dayId}
        />
      </ModalContainer>
      {/* Snackbar */}
      <AlertSnackbar
        // Constants
        horizontalPosition={"left"}
        verticalPosition={"bottom"}
        // Events
        onSnackbarClosed={onStaffToDayActionSnackbarClosed}
        // States
        showSnackbar={showStaffToDayActionSnackbar}
        snackbarText={staffToDayActionSnackbarText}
        snackbarType={staffToDayActionSnackbarType}
      />
      <Table stickyHeader>
        <TableHead>
          {days && (
            <TableRow>
              <TableCell className={classes.stickyColumnHeader}>
                {divisionType === "Shops" && (
                  <Button
                    onClick={onAddStaffBtnClicked}
                    startIcon={<AddRoundedIcon />}
                    variant="text"
                  >
                    {t("新增員工A")}
                  </Button>
                )}
              </TableCell>
              {days.map((dayItem) => (
                <TableCell
                  align="center"
                  key={dayItem.id}
                  sx={{
                    backgroundColor:
                      themeMode === "light"
                        ? stylesConfig.contentBackgroundColorLight
                        : stylesConfig.contentBackgroundColorDark,
                  }}
                >
                  <DayBox
                    // States
                    hoveredDayId={hoveredDayId}
                    hoveredDayItemId={hoveredDayItemId}
                    item={dayItem}
                    // Events
                    onItemClicked={onDayBoxClicked}
                    onMouseEnterItem={onMouseEnterDayItem}
                    onMouseLeaveItem={onMouseLeaveDayItem}
                  />
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        {staffWithRosters && (
          <TableBody>
            {staffWithRosters.map((staffItem) => (
              <TableRow key={staffItem.id}>
                <TableCell className={classes.stickyColumn} scope="row">
                  <StaffBox
                    // States
                    divisionType={divisionType}
                    hoveredStaffId={hoveredStaffId}
                    hoveredStaffItemId={hoveredStaffItemId}
                    item={staffItem}
                    shopItem={shopItem}
                    // Events
                    onItemClicked={onStaffItemClicked}
                    onMouseEnterItem={onMouseEnterStaffItem}
                    onMouseLeaveItem={onMouseLeaveStaffItem}
                  />
                </TableCell>
                {staffItem.staffToDays.map((staffToDayItem) => (
                  <TableCell
                    align="center"
                    key={staffToDayItem.day_id}
                    scope="row"
                  >
                    <RosterBox
                      // States
                      shopId={shopId}
                      editedStaffToDays={editedStaffToDays}
                      item={staffToDayItem}
                      selectedStaffToDays={selectedStaffToDays}
                      staffId={staffItem.id}
                      // Events
                      onItemRightClicked={onItemRightClicked}
                      onMouseDown={onMouseDown}
                      onMouseEnterItem={onMouseEnterItem}
                      onMouseLeaveItem={onMouseLeaveItem}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}

export default RosterTable;
