export const amountOfTimeOptions = [
  { value: 0, textCh: "0分鐘", textEn: "0 Min" },
  { value: 5, textCh: "5分鐘", textEn: "5 Mins" },
  { value: 10, textCh: "10分鐘", textEn: "10 Mins" },
  { value: 15, textCh: "15分鐘", textEn: "15 Mins" },
  { value: 20, textCh: "20分鐘", textEn: "20 Mins" },
  { value: 25, textCh: "25分鐘", textEn: "25 Mins" },
  { value: 30, textCh: "30分鐘", textEn: "30 Mins" },
  { value: 35, textCh: "35分鐘", textEn: "35 Mins" },
  { value: 40, textCh: "40分鐘", textEn: "40 Mins" },
  { value: 45, textCh: "45分鐘", textEn: "45 Mins" },
  { value: 50, textCh: "50分鐘", textEn: "50 Mins" },
  { value: 55, textCh: "55分鐘", textEn: "55 Mins" },
  { value: 60, textCh: "60分鐘", textEn: "60 Mins" },
  { value: 65, textCh: "65分鐘", textEn: "65 Mins" },
  { value: 70, textCh: "70分鐘", textEn: "70 Mins" },
  { value: 75, textCh: "75分鐘", textEn: "75 Mins" },
  { value: 80, textCh: "80分鐘", textEn: "80 Mins" },
  { value: 85, textCh: "85分鐘", textEn: "85 Mins" },
  { value: 90, textCh: "90分鐘", textEn: "90 Mins" },
  { value: 95, textCh: "95分鐘", textEn: "95 Mins" },
  { value: 100, textCh: "100分鐘", textEn: "100 Mins" },
];

export const numberOfTimeOptions = [
  { value: 1, textCh: "1次", textEn: "1 Time" },
  { value: 2, textCh: "2次", textEn: "2 Times" },
  { value: 3, textCh: "3次", textEn: "3 Times" },
  { value: 4, textCh: "4次", textEn: "4 Times" },
  { value: 5, textCh: "5次", textEn: "5 Times" },
  { value: 6, textCh: "6次", textEn: "6 Times" },
  { value: 7, textCh: "7次", textEn: "7 Times" },
  { value: 8, textCh: "8次", textEn: "8 Times" },
  { value: 9, textCh: "9次", textEn: "9 Times" },
  { value: 10, textCh: "10次", textEn: "10 Times" },
  { value: 11, textCh: "11次", textEn: "11 Times" },
  { value: 12, textCh: "12次", textEn: "12 Times" },
  { value: 13, textCh: "13次", textEn: "13 Times" },
  { value: 14, textCh: "14次", textEn: "14 Times" },
  { value: 15, textCh: "15次", textEn: "15 Times" },
  { value: 16, textCh: "16次", textEn: "16 Times" },
  { value: 17, textCh: "17次", textEn: "17 Times" },
  { value: 18, textCh: "18次", textEn: "18 Times" },
  { value: 19, textCh: "19次", textEn: "19 Times" },
  { value: 20, textCh: "20次", textEn: "20 Times" },
];
