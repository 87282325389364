// Actions
import {
  onCandidateChange,
  onTabItemChange,
} from "../../../redux/pages/candidateProfilePage/candidateProfilePageActions";

// Components
// Boxes
import CandidateBox from "./candidateBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import CandidateActionMenu from "../../menus/applicationsPage/candidateActionMenu";
// Modals
import CandidateOfferActionModal from "../../modals/candidateProfilePage/candidateOfferActionModal";
import ModalContainer from "../../modals/modalContainer";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  candidateTurnDownOfferFetch,
  getAllCandidatesWithPaginationFetch,
  putCandidateToWaitingListFetch,
  rejectCandidateFetch,
} from "../../../fetches/candidateFetches";

// Helper Functions
import {
  getMonthEndDate,
  getMonthStartDate,
} from "../../../helperFunctions/getDate";
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";
import { monthsToYearsAndMonths } from "../../../helperFunctions/monthsToYearsAndMonths";
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNum;

function CandidateBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const candidateStatusTypeId = useSelector(
    (state) => state.applicationsPage.conditionCandidateStatusTypeId
  );
  const formSubmitCount = useSelector(
    (state) => state.applicationsPage.formSubmitCount
  );
  const language = useSelector((state) => state.language.language);
  const recordMonth = useSelector(
    (state) => state.applicationsPage.recordMonth
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [candidates, setCandidates] = useState(null);
  // Dialog
  const [candidateActionDialogText, setCandidateActionDialogText] =
    useState("");
  const [candidateActionDialogType, setCandidateActionDialogType] =
    useState(null);
  const [showCandidateActionDialog, setShowCandidateActionDialog] =
    useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showCandidateActionMenu, setShowCandidateActionMenu] = useState(null);
  // Modals
  const [showCandidateOfferActionModal, setShowCandidateOfferActionModal] =
    useState(false);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  // Render
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  // Handle States
  const itemsArr = candidates;
  const recordMonthVar = new Date(recordMonth);

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onCandidateChange(itemId));
    dispatch(onTabItemChange("BasicInfo"));

    // Navigate
    navigate("/candidateProfile");
  };

  const onItemRightClicked = (item, currentTarget) => {
    // Set States
    setSelectedCandidate(item);
    setShowCandidateActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (item, currentTarget) => {
    // Set States
    setSelectedCandidate(item);
    setShowCandidateActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onCandidateActionDialogCanceled = () => {
    // Set States
    setShowCandidateActionDialog(false);
  };

  const onCandidateActionDialogConfirmed = () => {
    switch (candidateActionDialogType) {
      // 應徵者婉拒
      case "CandidateTurnDownOffer":
        candidateTurnDownOffer();
        break;
      // 設為候補
      case "PutCandidateToWaitingList":
        putCandidateToWaitingList();
        break;
      // 駁回
      case "RejectCandidate":
        rejectCandidate();
        break;
      default:
        break;
    }

    // Set States
    setShowCandidateActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onCandidateOfferActionModalClosed = () => {
    getAllCandidatesWithPagination();
    // Set States
    setShowCandidateOfferActionModal(false);
  };

  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Functions
  // Functions - Normal
  const displayCandidateActionDialog = (candidateActionType) => {
    // Set States
    setCandidateActionDialogType(candidateActionType);

    switch (candidateActionType) {
      // 應徵者婉拒
      case "CandidateTurnDownOffer":
        setCandidateActionDialogText(t("確認應徵者 婉拒 錄取嗎？"));
        break;
      // 設為候補
      case "PutCandidateToWaitingList":
        setCandidateActionDialogText(t("確認要將 應徵者 設為候補嗎？"));
        break;
      // 駁回
      case "RejectCandidate":
        setCandidateActionDialogText(t("確認要 駁回 應徵者嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowCandidateActionDialog(true);
  };

  const displayCandidateOfferActionModal = () => {
    // Set States
    setShowCandidateOfferActionModal(true);
  };

  const navigateToCreateStaffPage = () => {
    // Navigate
    navigate("/createStaff", {
      state: { candidateId: selectedCandidate.id },
    });
  };

  // Functions - Queries
  const getAllCandidatesWithPagination = async () => {
    const startDate = getMonthStartDate(recordMonthVar);
    const endDate = getMonthEndDate(recordMonthVar);

    const results = await getAllCandidatesWithPaginationFetch(
      token,
      candidateStatusTypeId,
      startDate,
      endDate,
      pageNum,
      limitNum
    );

    if (results.candidates) {
      for (let item of results.candidates) {
        item.expected_salary = item.expected_salary
          ? `$ ${separateComma(Number(item.expected_salary).toFixed())}`
          : null;

        item.date_applied = item.date_applied
          ? moment(item.date_applied).format(t("MM月 DD日"))
          : null;

        item.date_available = item.date_available
          ? moment(item.date_available).format(t("MM月 DD日"))
          : null;

        item.totalWorkExperinece = item.totalWorkExperinece
          ? `${t("工作經驗")} - ${monthsToYearsAndMonths(
              language,
              item.totalWorkExperinece
            )}`
          : t("未有工作經驗");
      }
    }

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setCandidates(results.candidates ? results.candidates : null);

    if (results.pageCount && !results.candidates) {
      setPageNum(results.pageCount);
    }
  };

  // Functions - Mutations
  const candidateTurnDownOffer = async () => {
    if (selectedCandidate) {
      const results = await candidateTurnDownOfferFetch(
        token,
        selectedCandidate.id
      );

      if (results.success) {
        getAllCandidatesWithPagination();
      }
    }
  };

  const putCandidateToWaitingList = async () => {
    if (selectedCandidate) {
      const results = await putCandidateToWaitingListFetch(
        token,
        selectedCandidate.id
      );

      if (results.success) {
        getAllCandidatesWithPagination();
      }
    }
  };

  const rejectCandidate = async () => {
    if (selectedCandidate) {
      const results = await rejectCandidateFetch(token, selectedCandidate.id);

      if (results.success) {
        getAllCandidatesWithPagination();
      }
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllCandidatesWithPagination();
  }, [candidateStatusTypeId, recordMonth, pageNum, formSubmitCount, language]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Dialogs */}
      <AlertDialog
        // Events
        onDialogClosed={onCandidateActionDialogCanceled}
        onDialogConfirmed={onCandidateActionDialogConfirmed}
        // States
        dialogText={candidateActionDialogText}
        showDialog={showCandidateActionDialog}
      />
      {/* Menu */}
      {selectedCandidate && (
        <CandidateActionMenu
          // States
          selectedCandidate={selectedCandidate}
          showCandidateActionMenu={showCandidateActionMenu}
          // Set States
          setShowCandidateActionMenu={setShowCandidateActionMenu}
          // Functions
          displayCandidateActionDialog={displayCandidateActionDialog}
          displayCandidateOfferActionModal={displayCandidateOfferActionModal}
          navigateToCreateStaffPage={navigateToCreateStaffPage}
        />
      )}
      {/* Modal */}
      {selectedCandidate && (
        <ModalContainer
          // Events
          onModalClosed={onCandidateOfferActionModalClosed}
          // States
          showModal={showCandidateOfferActionModal}
        >
          <CandidateOfferActionModal
            // Events
            onModalClosed={onCandidateOfferActionModalClosed}
            // States
            candidateId={selectedCandidate.id}
            offerId={null}
          />
        </ModalContainer>
      )}
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("入職申請")}
          </Typography>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item) => (
            <CandidateBox
              key={item.id}
              // States
              hoveredItemId={hoveredItemId}
              item={item}
              // Events
              onItemClicked={onItemClicked}
              onItemRightClicked={onItemRightClicked}
              onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
          <Stack className={classes.paginationStack} spacing={2}>
            <Pagination
              color={"primary"}
              count={pageCount}
              onChange={(event, page) => onPageChange(page)}
              page={pageNum}
            />
          </Stack>
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有入職申請")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default CandidateBoxesContainer;
