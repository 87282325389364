// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/candidateProfilePage/candidateProfilePageActions";

// Components
// Boxes
import CandidateOfferBasicInfoBox from "./candidateOfferBasicInfoBox";
import CandidateOfferOnboardInfoBox from "./candidateOfferOnboardInfoBox";
// Modals
import CandidateOfferActionModal from "../../modals/candidateProfilePage/candidateOfferActionModal";
import ModalContainer from "../../modals/modalContainer";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getOfferDetailsByCandidateIdFetch } from "../../../fetches/offerFetches";

// Helper Functions
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import EditRoundedIcon from "@mui/icons-material/EditRounded";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function CandidateOfferBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const candidateId = useSelector(
    (state) => state.candidateProfilePage.candidateId
  );
  const formSubmitCount = useSelector(
    (state) => state.candidateProfilePage.formSubmitCount
  );
  const language = useSelector((state) => state.language.language);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [candidateOfferDetails, setCandidateOfferDetails] = useState(null);
  // Modals
  const [showCandidateOfferActionModal, setShowCandidateOfferActionModal] =
    useState(false);

  // Handle States
  const item = candidateOfferDetails;

  // Events
  // Events - Modal
  const onCandidateOfferActionModalClosed = () => {
    // Set States
    setShowCandidateOfferActionModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  // Events - Title
  const onEditItemBtnClicked = () => {
    displayCandidateOfferActionModal();
  };

  // Functions
  // Functions - Normal
  const displayCandidateOfferActionModal = () => {
    // Set States
    setShowCandidateOfferActionModal(true);
  };

  // Functions = Queries
  const getOfferDetailsByCandidateId = async () => {
    const results = await getOfferDetailsByCandidateIdFetch(token, candidateId);

    if (results.offerDetails) {
      results.offerDetails.fixed_salary = `$ ${separateComma(
        Number(results.offerDetails.fixed_salary).toFixed()
      )}`;

      results.offerDetails.onboard_date = moment(
        results.offerDetails.onboard_date
      ).format(t("MM月 DD日"));
    }

    // Set States
    setCandidateOfferDetails(
      results.offerDetails ? results.offerDetails : null
    );
  };

  // Life Cycle
  useEffect(() => {
    if (candidateId) {
      getOfferDetailsByCandidateId();
    }
  }, [formSubmitCount, candidateId]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Modal */}
      {item && (
        <ModalContainer
          // Events
          onModalClosed={onCandidateOfferActionModalClosed}
          // States
          showModal={showCandidateOfferActionModal}
        >
          <CandidateOfferActionModal
            // Events
            onModalClosed={onCandidateOfferActionModalClosed}
            // States
            candidateId={candidateId}
            offerId={item.id}
          />
        </ModalContainer>
      )}
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("薪資待遇")}
          </Typography>
          {item && (
            <Tooltip placement="right" title={t("編輯薪資待遇")}>
              <IconButton color={"primary"} onClick={onEditItemBtnClicked}>
                <EditRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      {/* Content */}
      {item ? (
        <>
          <CandidateOfferBasicInfoBox
            // States
            item={item}
          />
          <CandidateOfferOnboardInfoBox
            // States
            item={item}
          />
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有薪資待遇資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default CandidateOfferBoxesContainer;
