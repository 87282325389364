// Actions
import {
  onAbnormalDateChange,
  onAttendanceTypeChange,
  onTabItemChange,
} from "../../../redux/pages/attendancesPage/attendancesPageActions";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getAllAttendanceTypesFetch } from "../../../fetches/attendanceTypeFetches";

// Material UI
// Components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
// Icons
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  selectBoxContainerStyles,
  useStandardListPageStyles,
} from "../../../styles/pageStyles/standardListPageStyles";

// Consts
const today = new Date();

function AttendancesPageHeader() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardListPageStyles();

  // Redux Store
  const abnormalDate = useSelector(
    (state) => state.attendancesPage.abnormalDate
  );
  const attendanceTypeId = useSelector(
    (state) => state.attendancesPage.attendanceTypeId
  );
  const tabItem = useSelector((state) => state.attendancesPage.tabItem);
  const token = useSelector((state) => state.staff.token);

  // States
  const [attendanceTypes, setAttendanceTypes] = useState(null);

  // Handle States
  const abnormalDateVar = abnormalDate ? new Date(abnormalDate) : null;

  const isAbnormalDateVarEqualsToday =
    abnormalDateVar.getFullYear() === today.getFullYear() &&
    abnormalDateVar.getMonth() === today.getMonth() &&
    abnormalDateVar.getDate() === today.getDate();

  // Events
  // Events - Fields
  const onDateBackAndForwardBtnClicked = (actionType) => {
    const newDateVar =
      actionType === "back"
        ? new Date(
            abnormalDateVar.getFullYear(),
            abnormalDateVar.getMonth(),
            abnormalDateVar.getDate() - 1
          )
        : new Date(
            abnormalDateVar.getFullYear(),
            abnormalDateVar.getMonth(),
            abnormalDateVar.getDate() + 1
          );

    // Update Redux Store
    dispatch(onAbnormalDateChange(newDateVar.toString()));
  };

  const onAbnormalRecordsDateChange = (value) => {
    // Update Redux Store
    dispatch(onAbnormalDateChange(value.toString()));
  };

  // Events - Select Box
  const onAttendanceTypeSelectBoxValueChanged = (value) => {
    // Update Redux Store
    dispatch(onAttendanceTypeChange(value));
  };

  // Events - Tabs
  const onTabClicked = (event, value) => {
    // Update Redux Store
    dispatch(onTabItemChange(value));
  };

  // Events - Title
  const onReturnToTodayBtnClicked = () => {
    // Update Redux Store
    dispatch(onAbnormalDateChange(today.toString()));
  };

  // Functions
  // Functions - Queries
  const getAllAttendanceTypes = async () => {
    const results = await getAllAttendanceTypesFetch(token);

    // Set States
    setAttendanceTypes(
      results.attendanceTypes ? results.attendanceTypes : null
    );

    // Update Redux Store
    if (results.attendanceTypes && !attendanceTypeId) {
      dispatch(onAttendanceTypeChange(results.attendanceTypes[0].id));
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllAttendanceTypes();
  }, []);

  return (
    <Box className={classes.divisionsContainer}>
      {/* Tabs */}
      <div className={classes.tabsContainer}>
        {tabItem && (
          <Tabs
            scrollButtons={false}
            value={tabItem}
            variant="scrollable"
            onChange={onTabClicked}
          >
            <Tab
              disableRipple
              key={"AttendanceRecords"}
              label={
                <Typography variant="h6" align={"center"} gutterBottom>
                  {t("出勤紀錄s")}
                </Typography>
              }
              value={"AttendanceRecords"}
            />
            <Tab
              disableRipple
              key={"AttendanceSummaries"}
              label={
                <Typography variant="h6" align={"center"} gutterBottom>
                  {t("概要s")}
                </Typography>
              }
              value={"AttendanceSummaries"}
            />
            <Tab
              disableRipple
              key={"AbnormalRecords"}
              label={
                <Typography variant="h6" align={"center"} gutterBottom>
                  {t("異常紀錄s")}
                </Typography>
              }
              value={"AbnormalRecords"}
            />
            <Tab
              disableRipple
              key={"AttendanceRules"}
              label={
                <Typography variant="h6" align={"center"} gutterBottom>
                  {t("規則s")}
                </Typography>
              }
              value={"AttendanceRules"}
            />
          </Tabs>
        )}
      </div>
      {/* Select Boxes */}
      {tabItem === "AttendanceRecords" && attendanceTypeId && (
        <div>
          <FormControl variant="standard" sx={selectBoxContainerStyles}>
            <Select
              value={attendanceTypeId}
              onChange={(event) =>
                onAttendanceTypeSelectBoxValueChanged(event.target.value)
              }
            >
              {attendanceTypes &&
                attendanceTypes.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item[t("attendance_type_name_ch")]}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      )}
      {tabItem === "AbnormalRecords" && (
        <div className={classes.tabsContainer}>
          {!isAbnormalDateVarEqualsToday && (
            <div className={classes.formTwinIconBtnContainerLeft}>
              <Button
                onClick={onReturnToTodayBtnClicked}
                startIcon={<RestoreRoundedIcon />}
                variant="text"
              >
                {t("返回本日")}
              </Button>
            </div>
          )}
          <DatePicker
            format="dd/MM/yyyy"
            label={t("日期 (日/月/年)")}
            onChange={onAbnormalRecordsDateChange}
            slotProps={{ textField: { variant: "standard" } }}
            value={abnormalDateVar}
            views={["year", "month", "day"]}
          />
          <div className={classes.formTwinIconBtnContainerRight}>
            <IconButton onClick={() => onDateBackAndForwardBtnClicked("back")}>
              <ArrowBackIosRoundedIcon />
            </IconButton>
            <IconButton
              onClick={() => onDateBackAndForwardBtnClicked("forward")}
            >
              <ArrowForwardIosRoundedIcon />
            </IconButton>
          </div>
        </div>
      )}
    </Box>
  );
}

export default AttendancesPageHeader;
