export const getTypeOfStaffsText = (typeOfStaffs) => {
  let titleText;

  switch (typeOfStaffs) {
    case "Birthday":
      titleText = "本月生日";
      break;
    case "Onboarding":
      titleText = "即將入職";
      break;
    case "PassingProbation":
      titleText = "即將通過試用期";
      break;
    case "Resignation":
      titleText = "即將離職";
      break;
    default:
      titleText = "";
      break;
  }

  return titleText;
};
