// Actions
import {
  onFormSubmitCountIncrease,
  onContractChange,
} from "../../../redux/pages/staffProfilePage/staffProfilePageActions";

// Components
// Boxes
import StaffContractBox from "./staffContractBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteStaffContractFetch,
  getAllStaffContractsByStaffIdFetch,
} from "../../../fetches/staffContractFetches";

// Helper Functions
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function StaffContractBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const tabItem = useSelector((state) => state.staffProfilePage.tabItem);
  const formSubmitCount = useSelector(
    (state) => state.staffProfilePage.formSubmitCount
  );
  const contractId = useSelector((state) => state.staffProfilePage.contractId);
  const language = useSelector((state) => state.language.language);
  const staffId = useSelector((state) => state.staffProfilePage.staffId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [staffContracts, setStaffContracts] = useState(null);
  // Dialog
  const [staffContractActionDialogText, setStaffContractActionDialogText] =
    useState("");
  const [staffContractActionDialogType, setStaffContractActionDialogType] =
    useState(null);
  const [showStaffContractActionDialog, setShowStaffContractActionDialog] =
    useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);

  // Handle States
  const currentItemId = contractId;
  const itemsArr = staffContracts;

  // Events
  // Events - Box
  const onDeleteItemBtnClicked = (itemId) => {
    onItemClicked(itemId);
    displayStaffContractActionDialog("CancelContract");
  };

  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onContractChange(itemId));
  };

  // Events - Dialogs
  const onStaffContractActionDialogCanceled = () => {
    // Set States
    setShowStaffContractActionDialog(false);
  };

  const onStaffContractActionDialogConfirmed = () => {
    switch (staffContractActionDialogType) {
      case "CancelContract":
        deleteStaffContract();
        break;
      default:
        break;
    }

    // Set States
    setShowStaffContractActionDialog(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Update Redux Store
    dispatch(onContractChange(null));
  };

  // Functions
  // Functions - Normal
  const displayStaffContractActionDialog = (staffContractActionType) => {
    // Set States
    setStaffContractActionDialogType(staffContractActionType);

    switch (staffContractActionType) {
      case "CancelContract":
        setStaffContractActionDialogText(t("確認要取消 薪金合約 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowStaffContractActionDialog(true);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  // Functions - Mutations
  const deleteStaffContract = async () => {
    if (currentItemId) {
      const results = await deleteStaffContractFetch(token, currentItemId);

      if (results.success) {
        getAllStaffContractsByStaffId(true);
      }
    }
  };

  // Functions = Queries
  const getAllStaffContractsByStaffId = async (
    shoulddUpdateCurrentContractId
  ) => {
    const results = await getAllStaffContractsByStaffIdFetch(token, staffId);

    // Format Date
    if (results.staffContracts) {
      for (let item of results.staffContracts) {
        item.effective_month = moment(item.effective_month).format(
          t("YYYY年 MM月")
        );

        item.fixed_salary = item.fixed_salary
          ? `$ ${separateComma(Number(item.fixed_salary).toFixed())}`
          : null;
      }
    }

    // Set States
    setStaffContracts(results.staffContracts ? results.staffContracts : null);

    // Update Redux Store
    if (results.staffContracts) {
      if (
        shoulddUpdateCurrentContractId ||
        (currentItemId &&
          !results.staffContracts.some((item) => item.id === currentItemId))
      ) {
        dispatch(onContractChange(results.staffContracts[0].id));
      }
    } else {
      dispatch(onContractChange(null));
    }
  };

  // Life Cycle
  useEffect(() => {
    if (tabItem === "SalaryContracts" && staffId) {
      getAllStaffContractsByStaffId(currentItemId ? false : true);
    }
  }, [tabItem, formSubmitCount, staffId]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onStaffContractActionDialogCanceled}
        onDialogConfirmed={onStaffContractActionDialogConfirmed}
        // States
        dialogText={staffContractActionDialogText}
        showDialog={showStaffContractActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("薪金合約紀錄s")}
          </Typography>
          <Tooltip placement="right" title={t("新增薪金合約")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <StaffContractBox
            key={item.id}
            // States
            currentItemId={currentItemId}
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onDeleteItemBtnClicked={onDeleteItemBtnClicked}
            onItemClicked={onItemClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有薪金合約紀錄")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default StaffContractBoxesContainer;
