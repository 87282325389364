// Components
// Boxes
import CaptionBox from "./captionBox";

// Custom Hooks
import { useLanguage } from "../../../../customHooks/getLanguage";

// Material UI
// Components
import Grid from "@mui/material/Grid";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function QuestionBoxContentTime(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onDateTimeInputFieldChange,
    onInputFieldKeyPressed,
    // States
    groupIndex,
    index,
    item,
  } = props;

  // Handle State
  const answerValueVar = item.answerValue ? new Date(item.answerValue) : null;

  return (
    <Grid className={classes.formDatePickerContainer} item xs={12}>
      <TimePicker
        label={item[t("edited_question_text_ch")]}
        onChange={(value) =>
          onDateTimeInputFieldChange(groupIndex, index, value)
        }
        onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
        readOnly={!item.is_editable}
        slotProps={{
          textField: {
            error: item.isError,
            helperText: item.errorText,
          },
        }}
        value={answerValueVar}
      />
      {item[t("captionStrCh")] && (
        <CaptionBox
          // States
          captionStr={item[t("captionStrCh")]}
        />
      )}
    </Grid>
  );
}

export default QuestionBoxContentTime;
