export const constructQuestionText = (
  language,
  questionText,
  questionTypeId,
  isRequired,
  maxNumOfAnswer
) => {
  switch (language) {
    case "en-us": {
      let extraText = isRequired ? "" : " (If Applicable";

      if (questionTypeId === 5 && maxNumOfAnswer > 1) {
        extraText += extraText !== "" ? " / " : " (";
        extraText += `${maxNumOfAnswer} Max`;
      }

      return extraText !== "" ? `${questionText}${extraText})` : questionText;
    }
    case "zh-hk": {
      let extraText = isRequired ? "" : " (如適用";

      if (questionTypeId === 5 && maxNumOfAnswer > 1) {
        extraText += extraText !== "" ? " / " : " (";
        extraText += `最多${maxNumOfAnswer}項`;
      }

      return extraText !== "" ? `${questionText}${extraText})` : questionText;
    }
    default:
      return "";
  }
};
