// Components
import StyledMenu from "../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

function ShopBusinessHourActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // States
    showShopBusinessHourActionMenu,
    // Set States
    setShowShopBusinessHourActionMenu,
    // Functions
    displayShopBusinessHourActionDialog,
    displayShopBusinessHourActionModal,
  } = props;

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowShopBusinessHourActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "Delete":
        displayShopBusinessHourActionDialog("DeleteBusinessHour");
        break;
      case "Edit":
        displayShopBusinessHourActionModal();
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showShopBusinessHourActionMenu}
      open={Boolean(showShopBusinessHourActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Edit")}>
        <EditRoundedIcon />
        {t("編輯")}
      </MenuItem>
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Delete")}>
        <DeleteRoundedIcon />
        {t("刪除")}
      </MenuItem>
    </StyledMenu>
  );
}

export default ShopBusinessHourActionMenu;
