// Actions
import {
  onConditionCandidateStatusTypeChange,
  onConditionCandidateStatusTypeItemChange,
} from "../../../redux/pages/applicationsPage/applicationsPageActions";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getAllCandidateStatusTypesFetch } from "../../../fetches/candidateStatusTypeFetches";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function CandidateConditionBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const candidateStatusTypeId = useSelector(
    (state) => state.applicationsPage.conditionCandidateStatusTypeId
  );
  const candidateStatusTypeItem = useSelector(
    (state) => state.applicationsPage.conditionCandidateStatusTypeItem
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [candidateStatusTypes, setCandidateStatusTypes] = useState(null);

  // Events
  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "candidateStatusType":
        dispatch(onConditionCandidateStatusTypeChange(value.id));
        break;
      default:
        break;
    }
  };

  // Functions
  // Functions - Queries
  const getAllCandidateStatusTypes = async () => {
    const results = await getAllCandidateStatusTypesFetch(token);

    // Set States
    setCandidateStatusTypes(
      results.candidateStatusTypes ? results.candidateStatusTypes : null
    );

    // Update Redux Store
    if (results.candidateStatusTypes && !candidateStatusTypeId) {
      dispatch(
        onConditionCandidateStatusTypeChange(results.candidateStatusTypes[0].id)
      );
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllCandidateStatusTypes();
  }, []);

  useEffect(() => {
    // Update Redux Store
    if (candidateStatusTypes && candidateStatusTypeId) {
      dispatch(
        onConditionCandidateStatusTypeItemChange(
          candidateStatusTypes.find((item) => item.id === candidateStatusTypeId)
        )
      );
    }
  }, [candidateStatusTypes, candidateStatusTypeId]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("應徵狀態")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      {candidateStatusTypes && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) =>
              option[t("candidate_status_type_name_ch")]
            }
            onChange={(event, value) =>
              onInputFieldChange("candidateStatusType", value)
            }
            options={candidateStatusTypes}
            renderInput={(params) => (
              <TextField {...params} label={t("應徵狀態")} variant="outlined" />
            )}
            value={candidateStatusTypeItem}
          />
        </div>
      )}
    </div>
  );
}

export default CandidateConditionBoxesContainer;
