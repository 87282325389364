// Configs
import apisConfig from "../configs/apisConfig";

export const editOfferFetch = async (
  token,
  offerId,
  staffContractTypeId,
  salaryCountTypeId,
  titleId,
  fixedSalary,
  onboardDate,
  isCommission,
  isLunchIncluded
) => {
  try {
    const queryRoute = `/offer/editOffer/${offerId}`;

    const reqBody = {
      staffContractTypeId,
      salaryCountTypeId,
      titleId,
      fixedSalary,
      onboardDate,
      isCommission,
      isLunchIncluded,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getOfferDetailsByCandidateIdFetch = async (token, candidateId) => {
  try {
    const queryRoute = `/offer/getOfferDetailsByCandidateId/${candidateId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getOfferDetailsByIdFetch = async (token, offerId) => {
  try {
    const queryRoute = `/offer/getOfferDetailsById/${offerId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getOfferSuggestionsByCandidateIdFetch = async (
  token,
  candidateId
) => {
  try {
    const queryRoute = `/offer/getOfferSuggestionsByCandidateId/${candidateId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
