export function onDivisionChange(divisionId) {
  return {
    type: "ON_TITLES_PAGE_DIVISION_CHANGE",
    divisionId,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_TITLES_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onTitleChange(titleId) {
  return {
    type: "ON_TITLES_PAGE_TITLE_CHANGE",
    titleId,
  };
}

export function onTypeOfTitlesChange(typeOfTitles) {
  return {
    type: "ON_TITLES_PAGE_TYPE_OF_TITLES_CHANGE",
    typeOfTitles,
  };
}
