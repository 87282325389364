export const languageZhHk = {
  // Data
  answerTextCh: "answerTextCh",
  application_dept_type_name_ch: "application_dept_type_name_ch",
  application_procedure_name_ch: "application_procedure_name_ch",
  applicationProcedureNameCh: "applicationProcedureNameCh",
  application_staff_type_name_ch: "application_staff_type_name_ch",
  application_status_type_name_ch: "application_status_type_name_ch",
  application_type_name_ch: "application_type_name_ch",
  area_name_ch: "area_name_ch",
  attendance_rule_type_name_ch: "attendance_rule_type_name_ch",
  attendance_type_name_ch: "attendance_type_name_ch",
  candidate_status_type_name_ch: "candidate_status_type_name_ch",
  captionStrCh: "captionStrCh",
  company_name_ch: "company_name_ch",
  contact_type_name_ch: "contact_type_name_ch",
  day_off_type_count_type_name_ch: "day_off_type_count_type_name_ch",
  day_off_type_name_ch: "day_off_type_name_ch",
  day_off_type_paid_type_name_ch: "day_off_type_paid_type_name_ch",
  day_off_type_payout_type_name_ch: "day_off_type_payout_type_name_ch",
  day_off_type_request_type_name_ch: "day_off_type_request_type_name_ch",
  day_off_type_rule_type_name_ch: "day_off_type_rule_type_name_ch",
  day_off_type_settle_type_name_ch: "day_off_type_settle_type_name_ch",
  degree_name_ch: "degree_name_ch",
  department_name_ch: "department_name_ch",
  district_name_ch: "district_name_ch",
  division_name_ch: "division_name_ch",
  edited_question_text_ch: "edited_question_text_ch",
  education_level_name_ch: "education_level_name_ch",
  end_weekday_name_ch_full: "end_weekday_name_ch_full",
  gender_type_name_ch: "gender_type_name_ch",
  group_title_text_ch: "group_title_text_ch",
  holiday_name_ch: "holiday_name_ch",
  main_answer_text_ch: "main_answer_text_ch",
  messageCh: "messageCh",
  option_text_ch: "option_text_ch",
  permission_dept_type_name_ch: "permission_dept_type_name_ch",
  permission_name_ch: "permission_name_ch",
  permission_staff_type_name_ch: "permission_staff_type_name_ch",
  permission_type_name_ch: "permission_type_name_ch",
  question_text_ch: "question_text_ch",
  relocation_type_name_ch: "relocation_type_name_ch",
  salary_count_type_name_ch: "salary_count_type_name_ch",
  shop_name_ch: "shop_name_ch",
  staff_contract_type_name_ch: "staff_contract_type_name_ch",
  staff_status_type_name_ch: "staff_status_type_name_ch",
  start_weekday_name_ch_full: "start_weekday_name_ch_full",
  sub_answer_text_ch_1: "sub_answer_text_ch_1",
  sub_answer_text_ch_2: "sub_answer_text_ch_2",
  sub_name_ch: "sub_name_ch",
  textCh: "textCh",
  timeStrCh: "timeStrCh",
  title_name_ch: "title_name_ch",
  type_ch: "type_ch",
  weekday_name_ch_full: "weekday_name_ch_full",
  weekday_name_ch_short: "weekday_name_ch_short",
  working_place_name_ch: "working_place_name_ch",
  working_place_type_name_ch: "working_place_type_name_ch",
  // Moment
  "a h:mm": "a h:mm",
  "H:mm": "H:mm",
  "MM月 DD日": "MM月 DD日",
  "YYYY年 MM月": "YYYY年 MM月",
  "YYYY年 MM月 DD日": "YYYY年 MM月 DD日",
  // Text
  本月生日: "本月生日",
  即將入職: "即將入職",
  即將通過試用期: "即將通過試用期",
  即將離職: "即將離職",
  搜尋: "搜尋",
  "確認要 登出 嗎？": "確認要 登出 嗎？",
  "Change to ": "Change to ",
  顯示選單: "顯示選單",
  設換至夜間模式: "設換至夜間模式",
  設換至日間模式: "設換至日間模式",
  確認要取消: "確認要取消",
  全部s: "全部",
  品牌s: "品牌",
  公司s: "公司",
  部門s: "部門",
  店鋪s: "店鋪",
  員工s: "員工",
  應徵者s: "應徵者",
  職位s: "職位",
  "入職 並 刪除 員工嗎？": "入職 並 刪除 員工嗎？",
  "離職 並 刪除 員工嗎？": "離職 並 刪除 員工嗎？",
  未有員工資料: "未有員工資料",
  "申請 / 流程s": "申請 / 流程",
  出勤紀錄s: "出勤紀錄",
  "公司 / 品牌s": "公司 / 品牌",
  主頁: "主頁",
  假期管理s: "假期管理",
  "部門 / 店鋪s": "部門 / 店鋪",
  權限s: "權限",
  更表s: "更表",
  薪酬s: "薪酬",
  "工作時段 / 地點s": "工作時段 / 地點",
  應徵者概覧: "應徵者概覧",
  新增員工: "新增員工",
  員工概覧: "員工概覧",
  統計: "統計",
  概要: "概要",
  登出: "登出",
  個人檔案: "個人檔案",
  取消入職: "取消入職",
  取消離職: "取消離職",
  重新入職: "重新入職",
  設為離職: "設為離職",
  未能提交: "未能提交",
  確認要將: "確認要將",
  設為: "設為",
  "嗎？": "嗎？",
  確定: "確定",
  "生效日期 (日/月/年)": "生效日期 (日/月/年)",
  請先填寫: "請先填寫",
  " 日期": "日期",
  離職: "離職",
  關鍵字: "關鍵字",
  搜尋範圍: "搜尋範圍",
  未有相關資料: "未有相關資料",
  顯示有效: "顯示有效",
  顯示已刪除: "顯示已刪除",
  " 部門s": "部門",
  " 店鋪s": "店鋪",
  顯示現職員工s: "顯示現職員工",
  顯示已離職員工s: "顯示已離職員工",
  "確認要刪除 部門 嗎？": "確認要刪除 部門 嗎？",
  "確認要刪除 店鋪 嗎？": "確認要刪除 店鋪 嗎？",
  "確認要還原 部門 嗎？": "確認要還原 部門 嗎？",
  "確認要還原 店鋪 嗎？": "確認要還原 店鋪 嗎？",
  品牌: "品牌",
  " 部門": "部門",
  " 店鋪": "店鋪",
  新增: "新增",
  顯示: "顯示",
  " 部門資料": "部門資料",
  " 店鋪資料": "店鋪資料",
  " 員工列表": "員工列表",
  未有: "未有",
  " 資料": "資料",
  "確認要取消 入職 並 刪除 員工嗎？": "確認要取消 入職 並 刪除 員工嗎？",
  "確認要取消 離職 嗎？": "確認要取消 離職 嗎？",
  "確認要 移除 主管嗎？": "確認要 移除 主管嗎？",
  "確認要 設為 主管嗎？": "確認要 設為 主管嗎？",
  員工列表: "員工列表",
  取消: "取消",
  確認: "確認",
  移除主管: "移除主管",
  設為主管: " 設為主管",
  部門主管: "部門主管",
  店鋪主管: "店鋪主管",
  "請先填寫 英文全名": "請先填寫 英文全名",
  "請先填寫 英文簡稱": "請先填寫 英文簡稱",
  "確認要新增 部門 嗎？": "確認要新增 部門 嗎？",
  "確認要編輯 部門資料 嗎？": "確認要編輯 部門資料 嗎？",
  成功提交: "成功提交",
  "此 部門 已存在": "此 部門 已存在",
  部門資料: "部門資料",
  中文名稱: "中文名稱",
  英文簡稱: "英文簡稱",
  英文全名: "英文全名",
  有效: "有效",
  修改: "修改",
  "請先填寫 品牌": "請先填寫 品牌",
  "請先填寫 店舖代號": "請先填寫 店舖代號",
  "確認要新增 店舖 嗎？": "確認要新增 店舖 嗎？",
  "確認要編輯 店舖資料 嗎？": "確認要編輯 店舖資料 嗎？",
  "此 店舖 已存在": "此 店舖 已存在",
  店舖資料: "店舖資料",
  店舖代號: "店舖代號",
  未有相關員工: "未有相關員工",
  地址: "地址",
  區域: "區域",
  地區: "地區",
  中文地址: "中文地址",
  英文地址: "英文地址",
  "確認要刪除 Wi-Fi 嗎？": "確認要刪除 Wi-Fi 嗎？",
  編輯: "編輯",
  刪除: "刪除",
  "新增 Wi-Fi": "新增 Wi-Fi",
  "未有 Wi-Fi 資料": "未有 Wi-Fi 資料",
  "IP 地址": "IP 地址",
  "請先填寫 IP 地址": "請先填寫 IP 地址",
  "確認要新增 Wi-Fi 嗎？": "確認要新增 Wi-Fi 嗎？",
  "確認要編輯 Wi-Fi 嗎？": "確認要編輯 Wi-Fi 嗎？",
  "編輯 Wi-Fi": "編輯 Wi-Fi",
  "確認要刪除 營業時間 嗎？": "確認要刪除 營業時間 嗎？",
  營業時間s: "營業時間",
  新增營業時間: "新增營業時間",
  未有營業時間資料: "未有營業時間資料",
  至: "至",
  " 及 公眾假期": " 及 公眾假期",
  休息: "休息",
  "請先填寫 營業時間": "請先填寫 營業時間",
  "請先填寫 工作日": "請先填寫 工作日",
  "確認要新增 營業時間 嗎？": "確認要新增 營業時間 嗎？",
  "確認要編輯 營業時間 嗎？": "確認要編輯 營業時間 嗎？",
  編輯營業時間: "編輯營業時間",
  工作日: "工作日",
  營業時間: "營業時間",
  包括公眾假期: "包括公眾假期",
  "確認要刪除 聯絡方式 嗎？": "確認要刪除 聯絡方式 嗎？",
  聯絡方式s: "聯絡方式",
  新增聯絡方式: "新增聯絡方式",
  未有聯絡方式資料: "未有聯絡方式資料",
  " (後備)": " (後備)",
  "請先填寫 聯絡號碼": "請先填寫 聯絡號碼",
  "請先填寫 聯絡類別": "請先填寫 聯絡類別",
  "確認要新增 聯絡方式 嗎？": "確認要新增 聯絡方式 嗎？",
  "確認要編輯 聯絡方式 嗎？": "確認要編輯 聯絡方式 嗎？",
  編輯聯絡方式: "編輯聯絡方式",
  後備: "後備",
  聯絡號碼: "聯絡號碼",
  聯絡類別: "聯絡類別",
  工作時段s: "工作時段",
  工作地點s: "工作地點",
  "確認要刪除 工作時段 嗎？": "確認要刪除 工作時段 嗎？",
  "設為一般時段嗎？": "設為一般時段嗎？",
  "確認要還原 工作時段 嗎？": "確認要還原 工作時段 嗎？",
  "設為預設時段嗎？": "設為預設時段嗎？",
  新增工作時段: "新增工作時段",
  未有工作時段資料: "未有工作時段資料",
  設為一般時段: "設為一般時段",
  設為預設時段: "設為預設時段",
  還原: "還原",
  預設時段: "預設時段",
  一般時段: "一般時段",
  "請先填寫 工作時段代號": "請先填寫 工作時段代號",
  "確認要新增 工作時段 嗎？": "確認要新增 工作時段 嗎？",
  "確認要編輯 工作時段資料 嗎？": "確認要編輯 工作時段資料 嗎？",
  "此 工作時段 已存在": "此 工作時段 已存在",
  工作時段資料: "工作時段資料",
  工作時段代號: "工作時段代號",
  "確認要刪除 工作時間 嗎？": "確認要刪除 工作時間 嗎？",
  工作時間s: "工作時間",
  新增工作時間: "新增工作時間",
  未有工作時間資料: "未有工作時間資料",
  長短週: "長短週",
  一般: "一般",
  本週為長週: "本週為長週",
  本週為短週: "本週為短週",
  "請先填寫 工作時間": "請先填寫 工作時間",
  "請先填寫 午飯時間": "請先填寫 午飯時間",
  "確認要新增 工作時間 嗎？": "確認要新增 工作時間 嗎？",
  "確認要編輯 工作時間 嗎？": "確認要編輯 工作時間 嗎？",
  編輯工作時間: "編輯工作時間",
  工作時間: "工作時間",
  午飯時間: "午飯時間",
  "確認要從新增 員工 到 工作時段 嗎？": "確認要從新增 員工 到 工作時段 嗎？",
  "確認要從 工作時段 刪除 員工 嗎？": "確認要從 工作時段 刪除 員工 嗎？",
  所有: "所有",
  已選擇: "已選擇",
  " 員工s": "員工",
  移除: "移除",
  "預設時段 不能 新增員工": "預設時段 不能 新增員工",
  "確認要刪除 工作地點 嗎？": "確認要刪除 工作地點 嗎？",
  "設為一般地點嗎？": "設為一般地點嗎？",
  "確認要還原 工作地點 嗎？": "確認要還原 工作地點 嗎？",
  "設為預設地點嗎？": "設為預設地點嗎？",
  工作地點類別: "工作地點類別",
  新增工作地點: "新增工作地點",
  未有工作地點資料: "未有工作地點資料",
  設為一般地點: "設為一般地點",
  設為預設地點: "設為預設地點",
  預設地點: "預設地點",
  一般地點: "一般地點",
  "請先填寫 中文名稱": "請先填寫 中文名稱",
  "請先填寫 工作地點類別": "請先填寫 工作地點類別",
  "確認要新增 工作地點 嗎？": "確認要新增 工作地點 嗎？",
  "確認要編輯 工作地點資料 嗎？": "確認要編輯 工作地點資料 嗎？",
  "此 工作地點 已存在": "此 工作地點 已存在",
  工作地點資料: "工作地點資料",
  "確認要從新增 員工 到 工作地點 嗎？": "確認要從新增 員工 到 工作地點 嗎？",
  "確認要從 工作地點 刪除 員工 嗎？": "確認要從 工作地點 刪除 員工 嗎？",
  "預設地點 不能 新增員工": "預設地點 不能 新增員工",
  清除已選擇員工: "清除已選擇員工",
  "顯示 ": "顯示",
  調動s: "調動",
  薪金合約s: "薪金合約",
  員工: "員工",
  查看應徵資料: "查看應徵資料",
  試用期: "試用期",
  " 個月s": "個月",
  "請先填寫 出生日期": "請先填寫 出生日期",
  "請先填寫 性別": "請先填寫 性別",
  "請先填寫 身分證編號": "請先填寫 身分證編號",
  "請先填寫 員工編號": "請先填寫 員工編號",
  "確認要新增 員工 嗎？": "確認要新增 員工 嗎？",
  "確認要編輯 員工資料 嗎？": "確認要編輯 員工資料 嗎？",
  "此 員工編號 已存在": "此 員工編號 已存在",
  基本資料: "基本資料",
  員工編號: "員工編號",
  別名: "別名",
  性別: "性別",
  身分證編號: "身分證編號",
  "出生日期 (日/月/年)": "出生日期 (日/月/年)",
  "入職日期 (日/月/年)": "入職日期 (日/月/年)",
  "離職日期 (日/月/年)": "離職日期 (日/月/年)",
  電話號碼: "電話號碼",
  電郵地址: "電郵地址",
  住宅地址: "住宅地址",
  "確認要取消 調動 嗎？": "確認要取消 調動 嗎？",
  "確認要取消 調動 並刪除 員工 嗎？": "確認要取消 調動 並刪除 員工 嗎？",
  調動紀錄s: "調動紀錄",
  新增調動: "新增調動",
  未有調動紀錄: "未有調動紀錄",
  "請先填寫 公司": "請先填寫 公司",
  "請先填寫 類別": "請先填寫 類別",
  "請先填寫 部門": "請先填寫 部門",
  "請先填寫 店舖": "請先填寫 店舖",
  "請先填寫 生效日期": "請先填寫 生效日期",
  "請先填寫 調動類型": "請先填寫 調動類型",
  "請先填寫 職位": "請先填寫 職位",
  "確認要新增 調動 嗎？": "確認要新增 調動 嗎？",
  "確認要編輯 調動資料 嗎？": "確認要編輯 調動資料 嗎？",
  調動資料: "調動資料",
  新增調動資料: "新增調動資料",
  調動類型: "調動類型",
  公司: "公司",
  類別: "類別",
  部門: "部門",
  店舖: "店舖",
  職位: "職位",
  取消調動: "取消調動",
  "確認要取消 薪金合約 嗎？": "確認要取消 薪金合約 嗎？",
  薪金合約紀錄s: "薪金合約紀錄",
  新增薪金合約: "新增薪金合約",
  未有薪金合約紀錄: "未有薪金合約紀錄",
  "請先填寫 生效月份": "請先填寫 生效月份",
  "請先填寫 固定工資": "請先填寫 固定工資",
  "固定工資 必須為數字": "固定工資 必須為數字",
  "請先填寫 工資單位": "請先填寫 工資單位",
  "請先填寫 合約類型": "請先填寫 合約類型",
  "確認要新增 員工合約 嗎？": "確認要新增 員工合約 嗎？",
  "確認要取消 員工合約 嗎？": "確認要取消 員工合約 嗎？",
  "確認要編輯 員工合約 嗎？": "確認要編輯 員工合約 嗎？",
  員工合約資料: "員工合約資料",
  合約類型: "合約類型",
  工資單位: "工資單位",
  固定工資: "固定工資",
  有佣金: "有佣金",
  午飯計入工時: "午飯計入工時",
  "生效月份 (月/年)": "生效月份 (月/年)",
  取消員工合約: "取消員工合約",
  概要s: "概要",
  異常紀錄s: "異常紀錄",
  規則s: "規則",
  返回本日: "返回本日",
  "日期 (日/月/年)": "日期 (日/月/年)",
  返回本月: "返回本月",
  "月份 (月/年)": "月份 (月/年)",
  未有出勤紀錄資料: "未有出勤紀錄資料",
  未入職: "未入職",
  已離職: "已離職",
  無需簽到: "無需簽到",
  已豁免: "已豁免",
  遲到: "遲到",
  早退: "早退",
  "分鐘s / 地點錯誤": "分鐘 / 地點錯誤",
  分鐘s: "分鐘",
  地點錯誤: "地點錯誤",
  欠簽到紀錄: "欠簽到紀錄",
  遲到次數s: "遲到次數",
  遲到時數s: "遲到時數",
  早退次數s: "早退次數",
  早退時數s: "早退時數",
  假期s: "假期",
  已上班日數s: "已上班日數",
  曠工日數s: "曠工日數",
  "(分鐘)": "(分鐘)",
  "(不含公眾假期)": "(不含公眾假期)",
  "(公眾假期)": "(公眾假期)",
  未有概要資料: "未有概要資料",
  簽到類別: "簽到類別",
  地點類別: "地點類別",
  工作地點: "工作地點",
  全部地點s: "全部地點",
  店舖s: "店舖",
  自訂地點s: "自訂地點",
  "確認要取消豁免 簽到紀錄 嗎？": "確認要取消豁免 簽到紀錄 嗎？",
  "確認要豁免 簽到紀錄 嗎？": "確認要豁免 簽到紀錄 嗎？",
  異常紀錄: "異常紀錄",
  已豁免紀錄: "已豁免紀錄",
  " 紀錄": "紀錄",
  豁免: "豁免",
  取消豁免: "取消豁免",
  未有簽到紀錄資料: "未有簽到紀錄資料",
  不適用: "不適用",
  簽到類別s: "簽到類別",
  未有簽到規則資料: "未有簽到規則資料",
  "確認要編輯 規則資料 嗎？": "確認要編輯 規則資料 嗎？",
  "此 規則 已存在": "此 規則 已存在",
  規則設定s: "規則設定",
  需要簽到: "需要簽到",
  "確認要刪除 簽到規則 嗎？": "確認要刪除 簽到規則 嗎？",
  簽到規則s: "簽到規則",
  新增簽到規則: "新增簽到規則",
  "連續 ": "連續 ",
  次sC: "次",
  次s: "次",
  總時數: "總時數",
  總次數: "總次數",
  "請先填寫 時數": "請先填寫 時數",
  "請先填寫 規則類別": "請先填寫 規則類別",
  "請先填寫 次數": "請先填寫 次數",
  "請先填寫 簽到類別": "請先填寫 簽到類別",
  "請先填寫 套用到": "請先填寫 套用到",
  "確認要新增 簽到規則 嗎？": "確認要新增 簽到規則 嗎？",
  "確認要編輯 簽到規則 嗎？": "確認要編輯 簽到規則 嗎？",
  編輯簽到規則: "編輯簽到規則",
  規則類別: "規則類別",
  套用到s: "套用到",
  計算方式: "計算方式",
  總次數達s: "總次數達",
  連續次數達s: "連續次數達",
  次數s: "次數",
  總時數達s: "總時數達",
  每次時數達: "每次時數達",
  時數s: "時數",
  "確認要豁免此 簽到紀錄？": "確認要豁免此 簽到紀錄？",
  "確認要將此 簽到紀錄 用作考勤？": "確認要將此 簽到紀錄 用作考勤？",
  詳細簽到紀錄s: "詳細簽到紀錄",
  正常: "正常",
  用作考勤: "用作考勤",
  "確認要從新增 員工 到 無需簽到名單 嗎？":
    "確認要從新增 員工 到 無需簽到名單 嗎？",
  "確認要從 無需簽到名單 刪除 員工 嗎？":
    "確認要從 無需簽到名單 刪除 員工 嗎？",
  無需簽到員工: "無需簽到員工",
  新增員工A: "新增員工",
  " 工作時段s": "工作時段",
  " 值更s": "值更",
  值更s: "值更",
  假期種類s: "假期種類",
  "確認要刪除 值更 嗎？": "確認要刪除 值更 嗎？",
  "確認要還原 值更 嗎？": "確認要還原 值更 嗎？",
  新增值更: "新增值更",
  "未有 ": "未有",
  "請先填寫 值更代號": "請先填寫 值更代號",
  "確認要新增 值更 嗎？": "確認要新增 值更 嗎？",
  "確認要編輯 值更 嗎？": "確認要編輯 值更 嗎？",
  編輯值更: "編輯值更",
  值更代號: "值更代號",
  所有店舖: "所有店舖",
  只限此店舖: "只限此店舖",
  "未能編更, 編更錯誤": "未能編更, 編更錯誤",
  "確認要 快速編更 嗎？ 快速編更 將覆蓋現有更表。":
    "確認要 快速編更 嗎？ 快速編更 將覆蓋現有更表。",
  快速編更: "快速編更",
  清除已選擇紀錄: "清除已選擇紀錄",
  未有更表資料: "未有更表資料",
  員工未入職: "員工未入職",
  員工已離職: "員工已離職",
  "確認要刪除 安排 嗎？": "確認要刪除 安排 嗎？",
  延遲: "延遲",
  提早: "提早",
  特別安排s: "特別安排",
  新增安排: "新增安排",
  所有工作時段: "所有工作時段",
  所有值更: "所有值更",
  工作: "工作",
  午飯: "午飯",
  "開始 - ": "開始 - ",
  "結束 - ": "結束 - ",
  無安排: "無安排",
  "請先填寫 值更": "請先填寫 值更",
  "請先填寫 工作時段": "請先填寫 工作時段",
  "請先填寫 開始時間 或 結束時間": "請先填寫 開始時間 或 結束時間",
  "確認要新增 特別安排 嗎？": "確認要新增 特別安排 嗎？",
  "確認要編輯 特別安排 嗎？": "確認要編輯 特別安排 嗎？",
  "已有相關 安排": "已有相關 安排",
  新增特別安排: "新增特別安排",
  套用到: "套用到",
  特定工作時段: "特定工作時段",
  所有店鋪: "所有店鋪",
  此店鋪: "此店鋪",
  特定值更: "特定值更",
  工作時段: "工作時段",
  值更: "值更",
  開始時間: "開始時間",
  確實時間: "確實時間",
  結束時間: "結束時間",
  申請紀錄s: "申請紀錄",
  審批流程s: "審批流程",
  入職申請s: "入職申請",
  "申請 / 審批s": "申請 / 審批",
  申請類別: "申請類別",
  申請狀態: "申請狀態",
  "確認要批准 申請 嗎？": "確認要批准 申請 嗎？",
  "確認要取消 申請 嗎？": "確認要取消 申請 嗎？",
  "確認要修改 申請 嗎？": "確認要修改 申請 嗎？",
  "確認要駁回 申請 嗎？": "確認要駁回 申請 嗎？",
  未有申請資料: "未有申請資料",
  批准: "批准",
  駁回: "駁回",
  程序: "程序",
  申請d: "申請",
  提交錯誤: "提交錯誤",
  不能更改此項目: "不能更改此項目",
  未有申請內容: "未有申請內容",
  未有相關檔案: "未有相關檔案",
  "載入中...": "載入中...",
  "(日/月/年)": "(日/月/年)",
  "確認要刪除 程序 嗎？": "確認要刪除 程序 嗎？",
  程序s: "程序",
  新增程序: "新增程序",
  未有程序資料: "未有程序資料",
  指定員工s: "指定員工",
  "請先填寫 部門 / 店鋪類型": "請先填寫 部門 / 店鋪類型",
  "請先填寫 員工類型": "請先填寫 員工類型",
  "請先填寫 部門 / 店鋪": "請先填寫 部門 / 店鋪",
  "請先填寫 英文名稱": "請先填寫 英文名稱",
  "確認要新增 程序 嗎？": "確認要新增 程序 嗎？",
  "確認要編輯 程序資料 嗎？": "確認要編輯 程序資料 嗎？",
  "此 程序 已存在": "此 程序 已存在",
  程序資料: "程序資料",
  英文名稱: "英文名稱",
  "部門 / 店鋪類型": "部門 / 店鋪類型",
  員工類型: "員工類型",
  未有相關類別: "未有相關類別",
  未有相關部門: "未有相關部門",
  未有相關職位: "未有相關職位",
  "確認要從新增 員工 到 程序 嗎？": "確認要從新增 員工 到 程序 嗎？",
  "確認要從 程序 刪除 員工 嗎？": "確認要從 程序 刪除 員工 嗎？",
  應徵狀態: "應徵狀態",
  "確認應徵者 婉拒 錄取嗎？": "確認應徵者 婉拒 錄取嗎？",
  "確認要將 應徵者 設為候補嗎？": "確認要將 應徵者 設為候補嗎？",
  "確認要 駁回 應徵者嗎？": "確認要 駁回 應徵者嗎？",
  工作經驗: "工作經驗",
  未有工作經驗: "未有工作經驗",
  錄取: "錄取",
  設為候補: "設為候補",
  應徵者婉拒: "應徵者婉拒",
  正式録取: "正式録取",
  未有入職申請: "未有入職申請",
  歲: "歲",
  可上班: "可上班",
  "請先填寫 入職日期": "請先填寫 入職日期",
  "確認要編輯 薪資待遇 嗎？": "確認要編輯 薪資待遇 嗎？",
  "確認要 錄取 應徵者嗎？": "確認要 錄取 應徵者嗎？",
  編輯薪資待遇: "編輯薪資待遇",
  新增薪資待遇: "新增薪資待遇",
  "聯絡方式 / 住宅地址": "聯絡方式 / 住宅地址",
  薪金合約: "薪金合約",
  "請先填寫 試用期": "請先填寫 試用期",
  下一步: "下一步",
  上一步: "上一步",
  聯絡方式: "聯絡方式",
  "確認要刪除 照片 嗎？": "確認要刪除 照片 嗎？",
  檔案不可超過: "檔案不可超過",
  "未能上載，請再試一次": "未能上載，請再試一次",
  應徵者: "應徵者",
  查看員工資料: "查看員工資料",
  未有應徵者資料: "未有應徵者資料",
  狀態: "狀態",
  照片: "照片",
  上載照片: "上載照片",
  刪除照片: "刪除照片",
  期望薪金: "期望薪金",
  薪資待遇: "薪資待遇",
  未有薪資待遇資料: "未有薪資待遇資料",
  入職: "入職",
  無佣金: "無佣金",
  "確認要編輯 應徵者資料 嗎？": "確認要編輯 應徵者資料 嗎？",
  "請先填寫 別名": "請先填寫 別名",
  "請先填寫 教育程度": "請先填寫 教育程度",
  "請先填寫 電話號碼": "請先填寫 電話號碼",
  "請先填寫 英文地址": "請先填寫 英文地址",
  "請先填寫 申請職位": "請先填寫 申請職位",
  "請先填寫 期望工資": "請先填寫 期望工資",
  "期望工資 必須為數字": "期望工資 必須為數字",
  "請先填寫 最快可入職日期": "請先填寫 最快可入職日期",
  教育程度: "教育程度",
  申請詳情: "申請詳情",
  申請職位: "申請職位",
  期望工資: "期望工資",
  "最快可入職日期 (日/月/年)": "最快可入職日期 (日/月/年)",
  "確認要刪除 技能 嗎？": "確認要刪除 技能 嗎？",
  技能s: "技能",
  技能: "技能",
  新增技能: "新增技能",
  未有技能資料: "未有技能資料",
  未有描述: "未有描述",
  "請先填寫 技能名稱": "請先填寫 技能名稱",
  "確認要新增 技能 嗎？": "確認要新增 技能 嗎？",
  "確認要編輯 技能 嗎？": "確認要編輯 技能 嗎？",
  編輯技能: "編輯技能",
  技能名稱: "技能名稱",
  "描述 (可不填)": "描述 (可不填)",
  查看證明文件: "查看證明文件",
  "未能刪除，請再試一次": "未能刪除，請再試一次",
  "確認要刪除 證明文件 嗎？": "確認要刪除 證明文件 嗎？",
  證明文件s: "證明文件",
  未有證明文件: "未有證明文件",
  上載證明文件: "上載證明文件",
  檔案: "檔案",
  開啟: "開啟",
  "確認要刪除 工作經驗 嗎？": "確認要刪除 工作經驗 嗎？",
  工作經驗s: "工作經驗",
  新增工作經驗: "新增工作經驗",
  未有工作經驗資料: "未有工作經驗資料",
  " 至今": "至今",
  "未滿 1 個月": "未滿 1 個月",
  "請先填寫 公司名稱": "請先填寫 公司名稱",
  "請先填寫 開始月份": "請先填寫 開始月份",
  "請先填寫 工資": "請先填寫 工資",
  "工資 必須為數字": "工資 必須為數字",
  "確認要新增 工作經驗 嗎？": "確認要新增 工作經驗 嗎？",
  "確認要編輯 工作經驗 嗎？": "確認要編輯 工作經驗 嗎？",
  編輯工作經驗: "編輯工作經驗",
  公司名稱: "公司名稱",
  "開始月份 (月/年)": "開始月份 (月/年)",
  "結束月份 (月/年) (如仍在職，可不填)": "結束月份 (月/年) (如仍在職，可不填)",
  "工作內容 (可不填)": "工作內容 (可不填)",
  與應徵工作有關: "與應徵工作有關",
  工資: "工資",
  "確認要刪除 教育 嗎？": "確認要刪除 教育 嗎？",
  教育s: "教育",
  教育: "教育",
  新增教育: "新增教育",
  未有教育資料: "未有教育資料",
  "請先填寫 機構名稱": "請先填寫 機構名稱",
  "請先填寫 學位": "請先填寫 學位",
  "確認要新增 教育 嗎？": "確認要新增 教育 嗎？",
  "確認要編輯 教育 嗎？": "確認要編輯 教育 嗎？",
  編輯教育: "編輯教育",
  機構名稱: "機構名稱",
  學位: "學位",
  學位名稱: "學位名稱",
  "結束月份 (月/年) (如仍在學，可不填)": "結束月份 (月/年) (如仍在學，可不填)",
  " 公司s": "公司",
  " 品牌s": "品牌",
  "確認要刪除 品牌 嗎？": "確認要刪除 品牌 嗎？",
  "確認要刪除 公司 嗎？": "確認要刪除 公司 嗎？",
  "確認要還原 品牌 嗎？": "確認要還原 品牌 嗎？",
  "確認要還原 公司 嗎？": "確認要還原 公司 嗎？",
  "確認要新增 品牌 嗎？": "確認要新增 品牌 嗎？",
  "確認要新增 公司 嗎？": "確認要新增 公司 嗎？",
  "確認要編輯 品牌資料 嗎？": "確認要編輯 品牌資料 嗎？",
  "確認要編輯 公司資料 嗎？": "確認要編輯 公司資料 嗎？",
  "此 品牌 已存在": "此 品牌 已存在",
  "此 公司 已存在": "此 公司 已存在",
  " 資料i": "資料",
  顯示有效職位: "顯示有效職位",
  顯示已刪除職位: "顯示已刪除職位",
  "確認要刪除 職位 嗎？": "確認要刪除 職位 嗎？",
  "確認要還原 職位 嗎？": "確認要還原 職位 嗎？",
  新增職位: "新增職位",
  未有職位資料: "未有職位資料",
  "確認要新增 職位 嗎？": "確認要新增 職位 嗎？",
  "確認要編輯 職位資料 嗎？": "確認要編輯 職位資料 嗎？",
  "此 職位 已存在": "此 職位 已存在",
  職位資料: "職位資料",
  剩餘數量s: "剩餘數量",
  顯示有效假期種類: "顯示有效假期種類",
  顯示已刪除假期種類: "顯示已刪除假期種類",
  "確認要刪除 假期種類 嗎？": "確認要刪除 假期種類 嗎？",
  "確認要還原 假期種類 嗎？": "確認要還原 假期種類 嗎？",
  新增假期種類: "新增假期種類",
  未有假期種類資料: "未有假期種類資料",
  "請先填寫 計算方式": "請先填寫 計算方式",
  "請先填寫 計算單位": "請先填寫 計算單位",
  "請先填寫 薪金": "請先填寫 薪金",
  "請先填寫 發放方式": "請先填寫 發放方式",
  "請先填寫 最多累積數量": "請先填寫 最多累積數量",
  "請先填寫 申請方式": "請先填寫 申請方式",
  "確認要新增 假期種類 嗎？": "確認要新增 假期種類 嗎？",
  "確認要編輯 假期種類資料 嗎？": "確認要編輯 假期種類資料 嗎？",
  "此 假期種類 已存在": "此 假期種類 已存在",
  假期種類資料: "假期種類資料",
  計算方式r: "計算方式",
  計算單位: "計算單位",
  發放方式: "發放方式",
  薪金p: "薪金",
  結算方式: "結算方式",
  可累積: "可累積",
  最多累積數量: "最多累積數量",
  結算方式s: "結算方式",
  每年結算月份: "每年結算月份",
  每月結算日期: "每月結算日期",
  申請與審批: "申請與審批",
  申請方式: "申請方式",
  員工可自行申請: "員工可自行申請",
  試用期內可申請: "試用期內可申請",
  "確認要刪除 假期規則 嗎？": "確認要刪除 假期規則 嗎？",
  假期規則s: "假期規則",
  新增假期規則: "新增假期規則",
  未有假期規則資料: "未有假期規則資料",
  固定數量: "固定數量",
  已滿: "已滿",
  "未滿 1 年": "未滿 1 年",
  年s: "年",
  由超時補上: "由超時補上",
  生日月份: "生日月份",
  每every: "每",
  "小時補上 1 日": "小時補上 1 日",
  日s: "日",
  "請先填寫 年資": "請先填寫 年資",
  "請先填寫 小時": "請先填寫 小時",
  "請先填寫 假期數量": "請先填寫 假期數量",
  "確認要新增 假期規則 嗎？": "確認要新增 假期規則 嗎？",
  "確認要編輯 假期規則 嗎？": "確認要編輯 假期規則 嗎？",
  "已有相關 假期規則": "已有相關 假期規則",
  編輯假期規則: "編輯假期規則",
  年資: "年資",
  "小時 (每特定小時補上 1 日)": "小時 (每特定小時補上 1 日)",
  假期數量: "假期數量",
  剩餘假期數量s: "剩餘假期數量",
  未有剩餘假期數量資料: "未有剩餘假期數量資料",
  設定s: "設定",
  設定類別: "設定類別",
  "請先填寫 月薪計算方式": "請先填寫 月薪計算方式",
  "確認要編輯 薪金計算設定 嗎？": "確認要編輯 薪金計算設定 嗎？",
  薪金計算設定s: "薪金計算設定",
  月薪計算方式: "月薪計算方式",
  "試用期內，公眾假期有薪": "試用期內，公眾假期有薪",
  "確認要刪除 p群組 嗎？": "確認要刪除 群組 嗎？",
  系統: "系統",
  權限: "權限",
  p群組s: "群組",
  p群組: "群組",
  新增p群組: "新增群組",
  未有p群組資料: "未有群組資料",
  指定員工: "指定員工",
  "確認要從新增 員工 到 群組 嗎？": "確認要從新增 員工 到 群組 嗎？",
  "確認要從 群組 刪除 員工 嗎？": "確認要從 群組 刪除 員工 嗎？",
  "確認要新增 群組 嗎？": "確認要新增 群組 嗎？",
  "確認要編輯 群組資料 嗎？": "確認要編輯 群組資料 嗎？",
  "此 群組 已存在": "此 群組 已存在",
  群組資料: "群組資料",
  未有相關店鋪: "未有相關店鋪",
  店鋪: "店鋪",
  必須為數字: "必須為數字",
  "確認要修改 員工 ": "確認要修改 員工",
  "請先填寫 英文全名 或 員工編號": "請先填寫 英文全名 或 員工編號",
  "請先填寫 密碼": "請先填寫 密碼",
  未有權限: "未有權限",
  未能登入: "未能登入",
  "英文全名 或 員工編號": "英文全名 或 員工編號",
  密碼: "密碼",
  登入: "登入",
};
