// Components
import StyledMenu from "../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";

// React-Redux
import { useSelector } from "react-redux";

function DayOffTypeActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // States
    selectedDayOffType,
    showDayOffTypeActionMenu,
    // Set States
    setDayOffTypeActionDialogType,
    setSelectedDayOffType,
    setShowDayOffTypeActionMenu,
    // Functions
    displayDayOffTypeActionDialog,
  } = props;

  // Redux Store
  const typeOfDayOffTypes = useSelector(
    (state) => state.dayOffTypesPage.typeOfDayOffTypes
  );

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowDayOffTypeActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      // 刪除
      case "Delete":
        // Set States
        setDayOffTypeActionDialogType("DeleteDayOffType");
        setSelectedDayOffType(selectedDayOffType);

        displayDayOffTypeActionDialog("DeleteDayOffType");
        break;
      // 還原
      case "Restore":
        // Set States
        setDayOffTypeActionDialogType("RestoreDayOffType");
        setSelectedDayOffType(selectedDayOffType);

        displayDayOffTypeActionDialog("RestoreDayOffType");
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showDayOffTypeActionMenu}
      open={Boolean(showDayOffTypeActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      {typeOfDayOffTypes === "Current" ? (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Delete")}>
          <DeleteRoundedIcon />
          {t("刪除")}
        </MenuItem>
      ) : (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Restore")}>
          <RestoreRoundedIcon />
          {t("還原")}
        </MenuItem>
      )}
    </StyledMenu>
  );
}

export default DayOffTypeActionMenu;
