// Redux
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

// Reducers
// Pages
import { applicationsPageReducers } from "./pages/applicationsPage/applicationsPageReducers";
import { attendancesPageReducers } from "./pages/attendancesPage/attendancesPageReducers";
import { candidateProfilePageReducers } from "./pages/candidateProfilePage/candidateProfilePageReducers";
import { compsAndBrandsPageReducers } from "./pages/compsAndBrandsPage/compsAndBrandsPageReducers";
import { createStaffPageReducers } from "./pages/createStaffPage/createStaffPageReducers";
import { dashboardPageReducers } from "./pages/dashboardPage/dashboardPageReducers";
import { dayOffTypesPageReducers } from "./pages/dayOffTypesPage/dayOffTypesPageReducers";
import { deptsAndShopsPageReducers } from "./pages/deptsAndShopsPage/deptsAndShopsPageReducers";
import { permissionsPageReducers } from "./pages/permissionsPage/permissionsPageReducers";
import { rostersPageReducers } from "./pages/rostersPage/rostersPageReducers";
import { salariesPageReducers } from "./pages/salariesPage/salariesPageReducers";
import { staffProfilePageReducers } from "./pages/staffProfilePage/staffProfilePageReducers";
import { titlesPageReducers } from "./pages/titlesPage/titlesPageReducers";
import { workingPeriodsAndPlacesPageReducers } from "./pages/workingPeriodsAndPlacesPage/workingPeriodsAndPlacesPageReducers";
// Shared
import { badgeReducers } from "./shared/badge/badgeReducers";
import { drawerReducers } from "./shared/drawer/drawerReducers";
import { languageReducers } from "./shared/language/languageReducers";
import { locationReducers } from "./shared/location/locationReducers";
import { staffReducers } from "./shared/staff/staffReducers";
import { themeReducers } from "./shared/theme/themeReducers";

const rootReducer = combineReducers({
  // Pages
  applicationsPage: applicationsPageReducers,
  attendancesPage: attendancesPageReducers,
  candidateProfilePage: candidateProfilePageReducers,
  compsAndBrandsPage: compsAndBrandsPageReducers,
  createStaffPage: createStaffPageReducers,
  dashboardPage: dashboardPageReducers,
  dayOffTypesPage: dayOffTypesPageReducers,
  deptsAndShopsPage: deptsAndShopsPageReducers,
  permissionsPage: permissionsPageReducers,
  rostersPage: rostersPageReducers,
  salariesPage: salariesPageReducers,
  staffProfilePage: staffProfilePageReducers,
  titlesPage: titlesPageReducers,
  workingPeriodsAndPlacesPage: workingPeriodsAndPlacesPageReducers,
  // Shared
  badge: badgeReducers,
  drawer: drawerReducers,
  language: languageReducers,
  location: locationReducers,
  staff: staffReducers,
  theme: themeReducers,
});

export const store = configureStore({ reducer: rootReducer });
