// Actions
import { onMonthChange } from "../../../redux/pages/salariesPage/salariesPageActions";

// Components
// Backdrop
import LoadingBackdrop from "../../backdrop/loadingBackdrop";
// Modals
import ModalContainer from "../../modals/modalContainer";
import SalaryActionModal from "../../modals/salariesPage/salaryActionModal";
// Tables
import SalarySummaryTable from "./salarySummaryTable";

// Configs
import stylesConfig from "../../../configs/stylesConfig";
import timeoutConfig from "../../../configs/timeoutConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Debounce
import debounce from "lodash.debounce";

// Fetcheså
import { getAllSalarySummariesByDepartmentOrShopIdFetch } from "../../../fetches/salaryFetches";

// Helper Functions
import {
  getMonthEndDate,
  getMonthStartDate,
} from "../../../helperFunctions/getDate";

// Material UI
// Components
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// Icons
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const today = new Date();
const currentMonth = new Date(today.getFullYear(), today.getMonth());

function SalarySummaryTableContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const departmentId = useSelector((state) => state.salariesPage.departmentId);
  const divisionId = useSelector((state) => state.salariesPage.divisionId);
  const divisionType = useSelector((state) => state.salariesPage.divisionType);
  const formSubmitCount = useSelector(
    (state) => state.salariesPage.formSubmitCount
  );
  const language = useSelector((state) => state.language.language);
  const month = useSelector((state) => state.salariesPage.month);
  const shopId = useSelector((state) => state.salariesPage.shopId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [salarySummaryFields, setSalarySummaryFields] = useState(null);
  const [staffWithSalarySummaries, setStaffWithSalarySummaries] =
    useState(null);
  // Modals
  const [salaryActionModalTitleCh, setSalaryActionTitleCh] = useState(false);
  const [salaryActionModalTitleEn, setSalaryActionTitleEn] = useState(false);
  const [salaryActionModalType, setSalaryActionType] = useState(false);
  const [showSalaryActionModal, setShowSalaryActionModal] = useState(false);
  // Redner
  const [isLoading, setIsLoading] = useState(false);

  // Handle States
  const monthVar = month ? new Date(month) : null;

  const isMonthVarEqualsCurrentMonth =
    monthVar.getFullYear() === currentMonth.getFullYear() &&
    monthVar.getMonth() === currentMonth.getMonth();

  // Events
  // Events - Fields
  const onMonthBackAndForwardBtnClicked = (actionType) => {
    const newMonthVar =
      actionType === "back"
        ? new Date(monthVar.getFullYear(), monthVar.getMonth() - 1, 1)
        : new Date(monthVar.getFullYear(), monthVar.getMonth() + 1, 1);

    // Update Redux Store
    dispatch(onMonthChange(newMonthVar.toString()));
  };

  const onSalaryMonthChange = (value) => {
    // Update Redux Store
    dispatch(onMonthChange(value.toString()));
  };

  // Events - Modal
  const onSalaryActionModalClosed = () => {
    // Set States
    setShowSalaryActionModal(false);
  };

  // Events - Title
  const onReturnToCurrentMonthBtnClicked = () => {
    // Update Redux Store
    dispatch(onMonthChange(currentMonth.toString()));
  };

  // Functions
  // Functions - Normal
  const displaySalaryActionModal = (modelTitleCh, modelTitleEn, modelType) => {
    // Set States
    setSalaryActionTitleCh(modelTitleCh);
    setSalaryActionTitleEn(modelTitleEn);
    setSalaryActionType(modelType);
    setShowSalaryActionModal(true);
  };

  const getAllSalarySummariesByDepartmentOrShopIdDebounce = debounce(
    (divisionTypeStr, departmentIdVar, shopIdVar) => {
      getAllSalarySummariesByDepartmentOrShopId(
        divisionTypeStr,
        departmentIdVar,
        shopIdVar
      );
    },
    timeoutConfig.debouceTime
  );

  // Functions - Queries
  const getAllSalarySummariesByDepartmentOrShopId = async (
    divisionTypeStr,
    departmentIdVar,
    shopIdVar
  ) => {
    const startDate = getMonthStartDate(monthVar);
    const endDate = getMonthEndDate(monthVar);

    const results = await getAllSalarySummariesByDepartmentOrShopIdFetch(
      token,
      divisionTypeStr === "Departments" ? departmentIdVar : shopIdVar,
      divisionTypeStr,
      startDate,
      endDate
    );

    // Set States
    setSalarySummaryFields(
      results.salarySummaryFields ? results.salarySummaryFields : null
    );
    setStaffWithSalarySummaries(
      results.staffWithSalarySummaries ? results.staffWithSalarySummaries : null
    );

    setIsLoading(false);
  };

  // Life Cycle
  useEffect(() => {
    if (
      month &&
      ((divisionType === "Departments" && departmentId) ||
        (divisionType === "Shops" && shopId))
    ) {
      // Set States
      setIsLoading(true);

      getAllSalarySummariesByDepartmentOrShopIdDebounce(
        divisionType,
        departmentId,
        shopId
      );
    }
  }, [departmentId, divisionId, formSubmitCount, shopId, month]);

  return (
    <div className={classes.contentBoxWithMaxWidth}>
      {/* Backdrop */}
      <LoadingBackdrop
        // States
        showBackdrop={isLoading}
      />
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onSalaryActionModalClosed}
        // States
        showModal={showSalaryActionModal}
      >
        <SalaryActionModal
          // Events
          onModalClosed={onSalaryActionModalClosed}
          // States
          language={language}
          salaryActionModelTitleCh={salaryActionModalTitleCh}
          salaryActionModelTitleEn={salaryActionModalTitleEn}
          salaryActionModelType={salaryActionModalType}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("概要s")}
          </Typography>
        </div>
        {/* Date Picker */}
        <div className={classes.titleTextContainer}>
          {!isMonthVarEqualsCurrentMonth && (
            <div className={classes.formTwinIconBtnContainerLeft}>
              <Button
                onClick={onReturnToCurrentMonthBtnClicked}
                startIcon={<RestoreRoundedIcon />}
                variant="text"
              >
                {t("返回本月")}
              </Button>
            </div>
          )}
          <div className={classes.formDatePickerContainer}>
            <DatePicker
              format="MM/yyyy"
              label={t("月份 (月/年)")}
              onChange={onSalaryMonthChange}
              slotProps={{ textField: { variant: "standard" } }}
              value={monthVar}
              views={["year", "month"]}
            />
          </div>
          <div className={classes.formTwinIconBtnContainerRight}>
            <IconButton onClick={() => onMonthBackAndForwardBtnClicked("back")}>
              <ArrowBackIosRoundedIcon />
            </IconButton>
            <IconButton
              onClick={() => onMonthBackAndForwardBtnClicked("forward")}
            >
              <ArrowForwardIosRoundedIcon />
            </IconButton>
          </div>
        </div>
      </div>
      {salarySummaryFields && staffWithSalarySummaries ? (
        // Table
        <SalarySummaryTable
          // Functions
          displaySalaryActionModal={displaySalaryActionModal}
          // States
          salarySummaryFields={salarySummaryFields}
          staffWithSalarySummaries={staffWithSalarySummaries}
        />
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有概要資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default SalarySummaryTableContainer;
