// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardListPageStyles } from "../../../styles/pageStyles/standardListPageStyles";

function CreateStaffPageHeader() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardListPageStyles();

  // Redux Store
  const activeStep = useSelector((state) => state.createStaffPage.activeStep);

  return (
    <Box className={classes.divisionsContainer}>
      {/* Stepper */}
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className={classes.stepperContainer}
      >
        <Step>
          <StepLabel>{t("基本資料")}</StepLabel>
        </Step>
        <Step>
          <StepLabel>{t("聯絡方式 / 住宅地址")}</StepLabel>
        </Step>
        <Step>
          <StepLabel>{t("調動資料")}</StepLabel>
        </Step>
        <Step>
          <StepLabel>{t("薪金合約")}</StepLabel>
        </Step>
      </Stepper>
    </Box>
  );
}

export default CreateStaffPageHeader;
