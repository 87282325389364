// Configs
import stylesConfig from "../../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../../customHooks/getLanguage";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// Icons
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

// Styles
import { useStandardItemBoxStyles } from "../../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function ArrangementBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    divisionType,
    hoveredItemId,
    item,
    // Events
    onItemClicked,
    onItemRightClicked,
    onItemShowMenuBtnClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isHovered = hoveredItemId && hoveredItemId === item.id;

  return (
    <>
      <div
        className={
          isHovered
            ? classes.hoveredItemContainerWithoutBorder
            : classes.itemContainer
        }
        onMouseEnter={() => onMouseEnterItem(item.id)}
        onMouseLeave={onMouseLeaveItem}
      >
        <div
          className={classes.itemContentContainer}
          onClick={() => onItemClicked(item.id)}
          onContextMenu={(event) => {
            event.preventDefault();
            onItemRightClicked(item.id, event.currentTarget);
          }}
        >
          <Typography
            align={"left"}
            color={isHovered ? stylesConfig.mainTextColor : null}
            variant="body1"
          >
            {divisionType === "Departments" &&
              (item.working_period_code
                ? item.working_period_code
                : t("所有工作時段"))}
            {divisionType === "Shops" &&
              (item.shop_code ? item.shop_code : t("所有店舖"))}
            {divisionType === "Shops" && " - "}
            {divisionType === "Shops" &&
              (item.shift_code ? item.shift_code : t("所有值更"))}
          </Typography>
        </div>
      </div>
      <div
        className={
          isHovered ? classes.hoveredItemContainer : classes.itemContainer
        }
        onMouseEnter={() => onMouseEnterItem(item.id)}
        onMouseLeave={onMouseLeaveItem}
      >
        <div
          className={classes.itemContentContainer}
          onClick={() => onItemClicked(item.id)}
          onContextMenu={(event) => {
            event.preventDefault();
            onItemRightClicked(item.id, event.currentTarget);
          }}
        >
          <div className={classes.itemContentSubContainer}>
            <Typography
              align={"left"}
              color={isHovered ? stylesConfig.mainTextColor : null}
              variant="body1"
            >
              {t("工作")}
            </Typography>
            <Typography
              align={"left"}
              color={stylesConfig.greyTextColor}
              variant="body2"
            >
              {t("開始 - ")}
              {!item.start_time && !item.start_time_difference && t("無安排")}
              {item.start_time && item.start_time}
              {item.start_time_difference && item.start_time_difference}
            </Typography>
            <Typography
              align={"left"}
              color={stylesConfig.greyTextColor}
              variant="body2"
            >
              {t("結束 - ")}
              {!item.end_time && !item.end_time_difference && t("無安排")}
              {item.end_time && item.end_time}
              {item.end_time_difference && item.end_time_difference}
            </Typography>
          </div>
          <div className={classes.itemContentSubContainer}>
            <Typography
              align={"left"}
              color={isHovered ? stylesConfig.mainTextColor : null}
              variant="body1"
            >
              {t("午飯")}
            </Typography>
            <Typography
              align={"left"}
              color={stylesConfig.greyTextColor}
              variant="body1"
            >
              {t("開始 - ")}
              {!item.lunch_time && !item.lunch_time_difference && t("無安排")}
              {item.lunch_time && item.lunch_time}
              {item.lunch_time_difference && item.lunch_time_difference}
            </Typography>
            <Typography
              align={"left"}
              color={stylesConfig.greyTextColor}
              variant="body1"
            >
              {t("結束 - ")}
              {!item.after_lunch_time &&
                !item.after_lunch_time_difference &&
                t("無安排")}
              {item.after_lunch_time && item.after_lunch_time}
              {item.after_lunch_time_difference &&
                item.after_lunch_time_difference}
            </Typography>
          </div>
        </div>
        <Stack direction="row" spacing={1}>
          <IconButton
            onClick={(event) =>
              onItemShowMenuBtnClicked(item.id, event.currentTarget)
            }
          >
            <MenuRoundedIcon />
          </IconButton>
        </Stack>
      </div>
    </>
  );
}

export default ArrangementBox;
