// Custom Hooks
import { useLanguage } from "../../../../customHooks/getLanguage";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function DayOffTypeDetailFormLowerContent(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // States
    daySettleField,
    dayOptions,
    dayOffTypeCountTypeField,
    dayOffTypeRequestTypeField,
    dayOffTypeRequestTypeOptions,
    dayOffTypeRuleTypeField,
    dayOffTypeSettleTypeField,
    dayOffTypeSettleTypeOptions,
    formAlertText,
    formAlertType,
    isAccumulative,
    isApplicable,
    isAvailableInProbation,
    maxAccumulativeNumField,
    monthSettleField,
    monthOptions,
    quantityOptions,
    shouldShowFormAlert,
    // Events
    onInputFieldChange,
    onInputFieldKeyPressed,
    onSubmitBtnClicked,
    // Functions
    checkIsFieldError,
    getErrorFieldMessage,
  } = props;

  // Redux Store
  const dayOffTypeId = useSelector(
    (state) => state.dayOffTypesPage.dayOffTypeId
  );

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      {dayOffTypeRuleTypeField &&
        dayOffTypeRuleTypeField.day_off_type_rule_type_name_en !==
          "No Limit" && (
          <div className={classes.titleContainer}>
            <div className={classes.titleTextContainer}>
              <Typography variant="h6" align={"left"}>
                {t("結算方式")}
              </Typography>
            </div>
          </div>
        )}
      {/* Form */}
      {dayOffTypeRuleTypeField &&
        dayOffTypeRuleTypeField.day_off_type_rule_type_name_en !==
          "No Limit" && (
          <Grid
            className={classes.formContainer}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            container
            rowSpacing={1}
          >
            <Grid className={classes.formSwitchContainer} item xs={6}>
              <Typography align={"left"} variant="body1">
                {t("可累積")}
              </Typography>
              <Switch
                checked={isAccumulative}
                onChange={() =>
                  onInputFieldChange("isAccumulative", !isAccumulative)
                }
              />
            </Grid>
            {isAccumulative && (
              <Grid className={classes.formAutoCompleteContainer} item xs={6}>
                <Autocomplete
                  disablePortal
                  getOptionLabel={(option) => option[t("textCh")]}
                  onChange={(event, value) =>
                    onInputFieldChange("maxAccumulativeNumField", value)
                  }
                  onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                  options={quantityOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={checkIsFieldError("maxAccumulativeNumField")}
                      helperText={getErrorFieldMessage(
                        "maxAccumulativeNumField"
                      )}
                      label={t("最多累積數量")}
                      variant="standard"
                    />
                  )}
                  value={maxAccumulativeNumField}
                />
              </Grid>
            )}
            {!isAccumulative && (
              <Grid className={classes.formAutoCompleteContainer} item xs={6}>
                {dayOffTypeSettleTypeOptions && (
                  <Autocomplete
                    disablePortal
                    getOptionLabel={(option) =>
                      option[t("day_off_type_settle_type_name_ch")]
                    }
                    onChange={(event, value) =>
                      onInputFieldChange("dayOffTypeSettleTypeField", value)
                    }
                    onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                    options={dayOffTypeSettleTypeOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={checkIsFieldError("dayOffTypeSettleTypeField")}
                        helperText={getErrorFieldMessage(
                          "dayOffTypeSettleTypeField"
                        )}
                        label={t("結算方式s")}
                        variant="standard"
                      />
                    )}
                    value={dayOffTypeSettleTypeField}
                  />
                )}
              </Grid>
            )}
            {!isAccumulative &&
              dayOffTypeSettleTypeField &&
              dayOffTypeSettleTypeField.day_off_type_settle_type_name_en ===
                "Specific Date" &&
              dayOffTypeCountTypeField &&
              dayOffTypeCountTypeField.day_off_type_count_type_name_en ===
                "Annual" && (
                <Grid className={classes.formAutoCompleteContainer} item xs={6}>
                  <Autocomplete
                    disablePortal
                    getOptionLabel={(option) => option[t("textCh")]}
                    onChange={(event, value) =>
                      onInputFieldChange("monthSettleField", value)
                    }
                    onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                    options={monthOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={checkIsFieldError("monthSettleField")}
                        helperText={getErrorFieldMessage("monthSettleField")}
                        label={t("每年結算月份")}
                        variant="standard"
                      />
                    )}
                    value={monthSettleField}
                  />
                </Grid>
              )}
            {!isAccumulative &&
              dayOffTypeSettleTypeField &&
              dayOffTypeSettleTypeField.day_off_type_settle_type_name_en ===
                "Specific Date" && (
                <Grid className={classes.formAutoCompleteContainer} item xs={6}>
                  <Autocomplete
                    disablePortal
                    getOptionLabel={(option) => option[t("textCh")]}
                    onChange={(event, value) =>
                      onInputFieldChange("daySettleField", value)
                    }
                    onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                    options={
                      monthSettleField &&
                      [4, 6, 9, 11].includes(monthSettleField.value)
                        ? dayOptions.filter((item) => item.value <= 30)
                        : monthSettleField && monthSettleField.value === 2
                        ? dayOptions.filter((item) => item.value <= 28)
                        : dayOptions
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={checkIsFieldError("daySettleField")}
                        helperText={getErrorFieldMessage("daySettleField")}
                        label={t("每月結算日期")}
                        variant="standard"
                      />
                    )}
                    value={daySettleField}
                  />
                </Grid>
              )}
          </Grid>
        )}
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("申請與審批")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {dayOffTypeRequestTypeOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) =>
                option[t("day_off_type_request_type_name_ch")]
              }
              onChange={(event, value) =>
                onInputFieldChange("dayOffTypeRequestTypeField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={dayOffTypeRequestTypeOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("dayOffTypeRequestTypeField")}
                  helperText={getErrorFieldMessage(
                    "dayOffTypeRequestTypeField"
                  )}
                  label={t("申請方式")}
                  variant="standard"
                />
              )}
              value={dayOffTypeRequestTypeField}
            />
          )}
        </Grid>
        <Grid className={classes.formSwitchContainer} item xs={6}>
          <Typography align={"left"} variant="body1">
            {t("員工可自行申請")}
          </Typography>
          <Switch
            checked={isApplicable}
            onChange={() => onInputFieldChange("isApplicable", !isApplicable)}
          />
        </Grid>
        {isApplicable && (
          <Grid className={classes.formSwitchContainer} item xs={6}>
            <Typography align={"left"} variant="body1">
              {t("試用期內可申請")}
            </Typography>
            <Switch
              checked={isAvailableInProbation}
              onChange={() =>
                onInputFieldChange(
                  "isAvailableInProbation",
                  !isAvailableInProbation
                )
              }
            />
          </Grid>
        )}
        <Grid className={classes.formSubmitBtnContainer} item xs={12}>
          <Button
            onClick={onSubmitBtnClicked}
            variant="contained"
            sx={formSubmitBtnStyles}
          >
            {dayOffTypeId ? t("修改") : t("新增")}
          </Button>
        </Grid>
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default DayOffTypeDetailFormLowerContent;
