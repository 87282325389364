export function onContractChange(contractId) {
  return {
    type: "ON_STAFF_PROFILE_PAGE_CONTRACT_CHANGE",
    contractId,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_STAFF_PROFILE_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onRelocationChange(relocationId) {
  return {
    type: "ON_STAFF_PROFILE_PAGE_RELOCATION_CHANGE",
    relocationId,
  };
}

export function onStaffChange(staffId) {
  return {
    type: "ON_STAFF_PROFILE_PAGE_STAFF_CHANGE",
    staffId,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_STAFF_PROFILE_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}
