// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function CreateStaffFormBasicInfoContent(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // States
    alias,
    dateBirth,
    genderTypeField,
    genderTypeOptions,
    hkid,
    nameCh,
    nameEn,
    staffCode,
    // Events
    onInputFieldChange,
    onInputFieldKeyPressed,
    onNextStepBtnClicked,
    // Functions
    checkIsFieldError,
    getErrorFieldMessage,
  } = props;

  // Handle States
  const dateBirthVar = dateBirth ? new Date(dateBirth) : null;

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("基本資料")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("staffCode")}
            fullWidth
            helperText={getErrorFieldMessage("staffCode")}
            label={t("員工編號")}
            margin="dense"
            name="staffCode"
            onChange={(event) =>
              onInputFieldChange("staffCode", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={staffCode}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("nameCh")}
            fullWidth
            label={t("中文名稱")}
            helperText={getErrorFieldMessage("nameCh")}
            margin="dense"
            name="nameCh"
            onChange={(event) =>
              onInputFieldChange("nameCh", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={nameCh}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={checkIsFieldError("nameEn")}
            fullWidth
            helperText={getErrorFieldMessage("nameEn")}
            label={t("英文全名")}
            margin="dense"
            name="nameEn"
            onChange={(event) =>
              onInputFieldChange("nameEn", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={nameEn}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("alias")}
            fullWidth
            helperText={getErrorFieldMessage("alias")}
            label={t("別名")}
            margin="dense"
            name="alias"
            onChange={(event) =>
              onInputFieldChange("alias", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={alias}
            variant="standard"
          />
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {genderTypeOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option[t("gender_type_name_ch")]}
              onChange={(event, value) =>
                onInputFieldChange("genderTypeField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={genderTypeOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("genderTypeField")}
                  helperText={getErrorFieldMessage("genderTypeField")}
                  label={t("性別")}
                  variant="standard"
                />
              )}
              value={genderTypeField}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("hkid")}
            fullWidth
            helperText={getErrorFieldMessage("hkid")}
            label={t("身分證編號")}
            margin="dense"
            name="hkid"
            onChange={(event) => onInputFieldChange("hkid", event.target.value)}
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={hkid}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6} className={classes.formDatePickerContainer}>
          <DatePicker
            format="dd/MM/yyyy"
            label={t("出生日期 (日/月/年)")}
            onChange={(value) => onInputFieldChange("dateBirth", value)}
            slotProps={{
              textField: {
                error: checkIsFieldError("dateBirth"),
                helperText: getErrorFieldMessage("dateBirth"),
                variant: "standard",
              },
            }}
            value={dateBirthVar}
            views={["year", "month", "day"]}
          />
        </Grid>
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <Button
            onClick={onNextStepBtnClicked}
            variant="contained"
            sx={formSubmitBtnStyles}
          >
            {t("下一步")}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default CreateStaffFormBasicInfoContent;
