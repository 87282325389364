// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardTableStyles } from "../../../styles/componentStyles/tableStyles/standardTableStyles";

function DayOffTypeBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardTableStyles();

  // Props
  const {
    // States
    hoveredDayOffTypeId,
    item,
  } = props;

  return (
    <>
      <Typography
        align={"center"}
        className={
          hoveredDayOffTypeId === item.id
            ? classes.selectedCellBoxMedium
            : classes.cellBoxMediumWithoutPointer
        }
        color={
          hoveredDayOffTypeId === item.id ? stylesConfig.whiteTextColor : null
        }
        variant="body1"
      >
        {item[t("day_off_type_name_ch")]}
      </Typography>
      <Typography
        align={"center"}
        color={stylesConfig.greyTextColor}
        variant="body2"
      >
        ({item.day_off_type_name_en_short})
      </Typography>
    </>
  );
}

export default DayOffTypeBox;
