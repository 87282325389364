// Configs
import apisConfig from "../configs/apisConfig";

export const createArrangementFetch = async (
  token,
  dayId,
  divisionType,
  workingPeriodType,
  workingPeriodId,
  shopType,
  shopId,
  shiftType,
  shiftId,
  startTimeType,
  startTimeHour,
  startTimeMinute,
  startTimeActual,
  lunchTimeType,
  lunchTimeHour,
  lunchTimeMinute,
  lunchTimeActual,
  afterLunchTimeType,
  afterLunchTimeHour,
  afterLunchTimeMinute,
  afterLunchTimeActual,
  endTimeType,
  endTimeHour,
  endTimeMinute,
  endTimeActual
) => {
  try {
    const queryRoute = "/arrangement/createArrangement";

    const reqBody = {
      dayId,
      divisionType,
      workingPeriodType,
      workingPeriodId,
      shopType,
      shopId,
      shiftType,
      shiftId,
      startTimeType,
      startTimeHour,
      startTimeMinute,
      startTimeActual,
      lunchTimeType,
      lunchTimeHour,
      lunchTimeMinute,
      lunchTimeActual,
      afterLunchTimeType,
      afterLunchTimeHour,
      afterLunchTimeMinute,
      afterLunchTimeActual,
      endTimeType,
      endTimeHour,
      endTimeMinute,
      endTimeActual,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteArrangementFetch = async (token, arrangementId) => {
  try {
    const queryRoute = `/arrangement/deleteArrangement/${arrangementId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editArrangementFetch = async (
  token,
  arrangementId,
  dayId,
  divisionType,
  workingPeriodType,
  workingPeriodId,
  shopType,
  shopId,
  shiftType,
  shiftId,
  startTimeType,
  startTimeHour,
  startTimeMinute,
  startTimeActual,
  lunchTimeType,
  lunchTimeHour,
  lunchTimeMinute,
  lunchTimeActual,
  afterLunchTimeType,
  afterLunchTimeHour,
  afterLunchTimeMinute,
  afterLunchTimeActual,
  endTimeType,
  endTimeHour,
  endTimeMinute,
  endTimeActual
) => {
  try {
    const queryRoute = `/arrangement/editArrangement/${arrangementId}`;

    const reqBody = {
      dayId,
      divisionType,
      workingPeriodType,
      workingPeriodId,
      shopType,
      shopId,
      shiftType,
      shiftId,
      startTimeType,
      startTimeHour,
      startTimeMinute,
      startTimeActual,
      lunchTimeType,
      lunchTimeHour,
      lunchTimeMinute,
      lunchTimeActual,
      afterLunchTimeType,
      afterLunchTimeHour,
      afterLunchTimeMinute,
      afterLunchTimeActual,
      endTimeType,
      endTimeHour,
      endTimeMinute,
      endTimeActual,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllArrangementsByDayIdFetch = async (
  token,
  dayId,
  divisionType,
  shopId
) => {
  try {
    const queryRoute = `/arrangement/getAllArrangementsByDayId/${dayId}`;

    const reqBody = {
      divisionType,
      shopId,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getArrangementDetailsByIdFetch = async (token, arrangementId) => {
  try {
    const queryRoute = `/arrangement/getArrangementDetailsById/${arrangementId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
