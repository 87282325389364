// Actions
import {
  onConditionAttendanceTypeChange,
  onConditionAttendanceTypeItemChange,
  onConditionLocationTypeChange,
  onConditionLocationTypeItemChange,
  onConditionShopChange,
  onConditionShopItemChange,
  onConditionWorkingPlaceChange,
  onConditionWorkingPlaceItemChange,
} from "../../../redux/pages/attendancesPage/attendancesPageActions";

// Consts
import { locationTypeOptions } from "../../../consts/attendanceConditionBoxesContainerConsts";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Dummy Datum
import { attendanceTypeDummyData } from "../../../dummyDatum/attendanceTypeDummyData";
import { shopDummyData } from "../../../dummyDatum/shopDummyData";
import { workingPlaceDummyData } from "../../../dummyDatum/workingPlaceDummyData";

// Fetches
import { getAllAttendanceTypesFetch } from "../../../fetches/attendanceTypeFetches";
import { getAllShopsFetch } from "../../../fetches/shopFetches";
import { getAllWorkingPlacesFetch } from "../../../fetches/workingPlaceFetches";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ConditionBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const attendanceTypeId = useSelector(
    (state) => state.attendancesPage.conditionAttendanceTypeId
  );
  const attendanceTypeItem = useSelector(
    (state) => state.attendancesPage.conditionAttendanceTypeItem
  );
  const locationTypeValue = useSelector(
    (state) => state.attendancesPage.conditionLocationTypeValue
  );
  const locationTypeItem = useSelector(
    (state) => state.attendancesPage.conditionLocationTypeItem
  );
  const shopId = useSelector((state) => state.attendancesPage.conditionShopId);
  const shopItem = useSelector(
    (state) => state.attendancesPage.conditionShopItem
  );
  const token = useSelector((state) => state.staff.token);
  const workingPlaceId = useSelector(
    (state) => state.attendancesPage.conditionWorkingPlaceId
  );
  const workingPlaceItem = useSelector(
    (state) => state.attendancesPage.conditionWorkingPlaceItem
  );

  // States
  // Data
  const [attendanceTypes, setAttendanceTypes] = useState(null);
  const [shops, setShops] = useState(null);
  const [workingPlaces, setWorkingPlaces] = useState(null);

  // Events
  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "attendanceType":
        dispatch(onConditionAttendanceTypeChange(value.id));
        break;
      case "locationType":
        dispatch(onConditionLocationTypeChange(value.value));
        break;
      case "shop":
        dispatch(onConditionShopChange(value.id));
        break;
      case "workingPlace":
        dispatch(onConditionWorkingPlaceChange(value.id));
        break;
      default:
        break;
    }
  };

  // Functions
  // Functions - Normal
  const getAllConditions = () => {
    getAllAttendanceTypes();
    getAllShops();
    getAllWorkingPlaces();
  };

  const setInitialStates = () => {
    // Update Redux Store
    dispatch(onConditionLocationTypeChange(locationTypeOptions[0].value));
  };

  // Functions - Queries
  const getAllAttendanceTypes = async () => {
    const results = await getAllAttendanceTypesFetch(token);

    // Set States
    setAttendanceTypes(
      results.attendanceTypes
        ? [attendanceTypeDummyData, ...results.attendanceTypes]
        : null
    );

    // Update Redux Store
    if (results.attendanceTypes && !attendanceTypeId) {
      dispatch(onConditionAttendanceTypeChange(attendanceTypeDummyData.id));
    }
  };

  const getAllShops = async () => {
    const results = await getAllShopsFetch(token, "Current");

    // Set States
    setShops(results.shops ? [shopDummyData, ...results.shops] : null);

    // Update Redux Store
    if (results.shops && !shopId) {
      dispatch(onConditionShopChange(shopDummyData.id));
    }
  };

  const getAllWorkingPlaces = async () => {
    const results = await getAllWorkingPlacesFetch(token, "Current");

    // Set States
    setWorkingPlaces(
      results.workingPlaces
        ? [workingPlaceDummyData, ...results.workingPlaces]
        : null
    );

    // Update Redux Store
    if (results.workingPlaces && !workingPlaceId) {
      dispatch(onConditionWorkingPlaceChange(workingPlaceDummyData.id));
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllConditions();
    setInitialStates();
  }, []);

  useEffect(() => {
    // Update Redux Store
    if (attendanceTypes && attendanceTypeId) {
      dispatch(
        onConditionAttendanceTypeItemChange(
          attendanceTypes.find((item) => item.id === attendanceTypeId)
        )
      );
    }
  }, [attendanceTypes, attendanceTypeId]);

  useEffect(() => {
    // Update Redux Store
    if (locationTypeValue) {
      dispatch(
        onConditionLocationTypeItemChange(
          locationTypeOptions.find((item) => item.value === locationTypeValue)
        )
      );
    }
  }, [locationTypeValue]);

  useEffect(() => {
    // Update Redux Store
    if (shops && shopId) {
      dispatch(
        onConditionShopItemChange(shops.find((item) => item.id === shopId))
      );
    }
  }, [shops, shopId]);

  useEffect(() => {
    // Update Redux Store
    if (workingPlaces && workingPlaceId) {
      dispatch(
        onConditionWorkingPlaceItemChange(
          workingPlaces.find((item) => item.id === workingPlaceId)
        )
      );
    }
  }, [workingPlaces, workingPlaceId]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("簽到類別")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      {attendanceTypes && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) => option[t("attendance_type_name_ch")]}
            onChange={(event, value) =>
              onInputFieldChange("attendanceType", value)
            }
            options={attendanceTypes}
            renderInput={(params) => (
              <TextField {...params} label={t("簽到類別")} variant="outlined" />
            )}
            value={attendanceTypeItem}
          />
        </div>
      )}
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("地點類別")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      <div className={classes.autoCompleteContainer}>
        <Autocomplete
          disablePortal
          fullWidth
          getOptionLabel={(option) => t(`${option.text}s`)}
          onChange={(event, value) => onInputFieldChange("locationType", value)}
          options={locationTypeOptions}
          renderInput={(params) => (
            <TextField {...params} label={t("地點類別")} variant="outlined" />
          )}
          value={locationTypeItem}
        />
      </div>
      {locationTypeValue && locationTypeValue === "Shops" && (
        <>
          {/* Title */}
          <div className={classes.titleContainer}>
            <div className={classes.titleTextContainer}>
              <Typography variant="h6" align={"left"}>
                {t("店舖")}
              </Typography>
            </div>
          </div>
          {/* Select Box */}
          {shops && (
            <div className={classes.autoCompleteContainer}>
              <Autocomplete
                disablePortal
                fullWidth
                getOptionLabel={(option) =>
                  option.id !== "dummy"
                    ? `${option.shop_code} - ${option[t("shop_name_ch")]}`
                    : option[t("shop_name_ch")]
                }
                onChange={(event, value) => onInputFieldChange("shop", value)}
                options={shops}
                renderInput={(params) => (
                  <TextField {...params} label={t("店舖")} variant="outlined" />
                )}
                value={shopItem}
              />
            </div>
          )}
        </>
      )}
      {locationTypeValue && locationTypeValue === "WorkingPlaces" && (
        <>
          {/* Title */}
          <div className={classes.titleContainer}>
            <div className={classes.titleTextContainer}>
              <Typography variant="h6" align={"left"}>
                {t("工作地點")}
              </Typography>
            </div>
          </div>
          {/* Select Box */}
          {workingPlaces && (
            <div className={classes.autoCompleteContainer}>
              <Autocomplete
                disablePortal
                fullWidth
                getOptionLabel={(option) =>
                  option.id !== "dummy"
                    ? `${option.working_place_name_en_short} - ${
                        option[t("working_place_name_ch")]
                      }`
                    : option[t("working_place_name_ch")]
                }
                onChange={(event, value) =>
                  onInputFieldChange("workingPlace", value)
                }
                options={workingPlaces}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("工作地點")}
                    variant="outlined"
                  />
                )}
                value={workingPlaceItem}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ConditionBoxesContainer;
