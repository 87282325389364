// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import DeptAndShopBoxesContainer from "../components/boxes/deptsAndShopsPage/deptAndShopBoxesContainer";
import ShopBusinessHourBoxesContainer from "../components/boxes/deptsAndShopsPage/shopBusinessHourBoxesContainer";
import ShopContactBoxesContainer from "../components/boxes/deptsAndShopsPage/shopContactBoxesContainer";
import ShopWiFiBoxesContainer from "../components/boxes/deptsAndShopsPage/shopWiFiBoxesContainer";
import StaffBoxesContainer from "../components/boxes/deptsAndShopsPage/staffBoxesContainer";
// Forms
import DeptDetailForm from "../components/forms/deptsAndShopsPage/deptDetailForm";
import ShopDetailForm from "../components/forms/deptsAndShopsPage/shopDetailForm";
// Headers
import DeptsAndShopsPageHeader from "../components/headers/deptsAndShopsPage/deptsAndShopsPageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function DeptsAndShopsPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const divisionType = useSelector(
    (state) => state.deptsAndShopsPage.divisionType
  );
  const typeOfContent = useSelector(
    (state) => state.deptsAndShopsPage.typeOfContent
  );

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("DeptsAndShops"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <DeptsAndShopsPageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          <DeptAndShopBoxesContainer />
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          {typeOfContent === "StaffList" ? (
            <StaffBoxesContainer />
          ) : (
            divisionType === "Departments" && <DeptDetailForm />
          )}
          {(typeOfContent === "StaffList" ||
            divisionType === "Departments") && (
            <SpacingBox
              // Render
              height={stylesConfig.spacingBoxMarginBottom}
            />
          )}
          {typeOfContent === "DeptAndShopDetail" && divisionType === "Shops" && (
            <Grid container spacing={4}>
              <Grid item xs={12} lg={8}>
                <ShopDetailForm />
                <SpacingBox
                  // Render
                  height={stylesConfig.spacingBoxMarginBottom}
                />
                <ShopWiFiBoxesContainer />
                <SpacingBox
                  // Render
                  height={stylesConfig.spacingBoxMarginBottom}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ShopBusinessHourBoxesContainer />
                <SpacingBox
                  // Render
                  height={stylesConfig.spacingBoxMarginBottom}
                />
                <ShopContactBoxesContainer />
                <SpacingBox
                  // Render
                  height={stylesConfig.spacingBoxMarginBottom}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default DeptsAndShopsPage;
