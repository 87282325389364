// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/candidateProfilePage/candidateProfilePageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Forms
import CandidateInfoFormApplicationDetailsContent from "./candidateInfoFormApplicationDetailsContent";
import CandidateInfoFormBasicInfoContent from "./candidateInfoFormBasicInfoContent";
import CandidateInfoFormContactAndAddressContent from "./candidateInfoFormContactAndAddressContent";
// Modals
import CandidateOfferActionModal from "../../modals/candidateProfilePage/candidateOfferActionModal";
import ModalContainer from "../../modals/modalContainer";
// Spacing Boxes
import SpacingBox from "../../boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  candidateTurnDownOfferFetch,
  editCandidateFetch,
  getCandidateDetailsByIdFetch,
  putCandidateToWaitingListFetch,
  rejectCandidateFetch,
} from "../../../fetches/candidateFetches";
import { getCandidateDetailFormOptionsFetch } from "../../../fetches/formOptionFetches";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

function CandidateInfoFormContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();

  // Redux Store
  const candidateId = useSelector(
    (state) => state.candidateProfilePage.candidateId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Date Fields
  const [dateAvailable, setDateAvailable] = useState(null);
  const [dateBirth, setDateBirth] = useState(null);
  // Dialog
  const [candidateInfoActionDialogText, setCandidateInfoActionDialogText] =
    useState("");
  const [candidateInfoActionDialogType, setCandidateInfoActionDialogType] =
    useState(null);
  const [showCandidateInfoActionDialog, setShowCandidateInfoActionDialog] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Modals
  const [showCandidateOfferActionModal, setShowCandidateOfferActionModal] =
    useState(false);
  // Options Fields
  const [areaField, setAreaField] = useState(null);
  const [districtField, setDistrictField] = useState(null);
  const [educationLevelField, setEducationLevelField] = useState(null);
  const [genderTypeField, setGenderTypeField] = useState(null);
  // Options
  const [areaOptions, setAreaOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [educationLevelOptions, setEducationLevelOptions] = useState([]);
  const [genderTypeOptions, setGenderTypeOptions] = useState([]);
  // Render
  const [candidateStatusTypeId, setCandidateStatusTypeId] = useState([]);
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Text Fields
  const [addressCh, setAddressCh] = useState("");
  const [addressEn, setAddressEn] = useState("");
  const [alias, setAlias] = useState("");
  const [email, setEmail] = useState("");
  const [expectedSalary, setExpectedSalary] = useState("");
  const [hkid, setHkid] = useState("");
  const [fullNameCh, setFullNameCh] = useState("");
  const [fullNameEn, setFullNameEn] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [titleApplied, setTitleApplied] = useState("");

  // Events
  // Events - Dialogs
  const onCandidateInfoActionDialogCanceled = () => {
    // Set States
    setShowCandidateInfoActionDialog(false);
  };

  const onCandidateInfoActionDialogConfirmed = () => {
    // Set States
    setShowCandidateInfoActionDialog(false);

    switch (candidateInfoActionDialogType) {
      // 應徵者婉拒
      case "CandidateTurnDownOffer":
        candidateTurnDownOffer();
        break;
      // 修改
      case "EditCandidate":
        editCandidate();
        break;
      // 設為候補
      case "PutCandidateToWaitingList":
        putCandidateToWaitingList();
        break;
      // 駁回
      case "RejectCandidate":
        rejectCandidate();
        break;
      default:
        break;
    }
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "addressCh":
        setAddressCh(value);
        break;
      case "addressEn":
        setAddressEn(value);
        break;
      case "alias":
        setAlias(value);
        break;
      case "areaField":
        setAreaField(value);
        setDistrictField(
          districtOptions.find((item) => item.area_id === value.id)
        );

        removeErrorField("districtField");

        break;
      case "dateAvailable":
        setDateAvailable(value);
        break;
      case "dateBirth":
        setDateBirth(value);
        break;
      case "districtField":
        setDistrictField(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "expectedSalary":
        setExpectedSalary(value);
        break;
      case "educationLevelField":
        setEducationLevelField(value);
        break;
      case "genderTypeField":
        setGenderTypeField(value);
        break;
      case "hkid":
        setHkid(value);
        break;
      case "fullNameCh":
        setFullNameCh(value);
        break;
      case "fullNameEn":
        setFullNameEn(value);
        break;
      case "phoneNum":
        setPhoneNum(value);
        break;
      case "titleApplied":
        setTitleApplied(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked("EditCandidate");
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = (submitActionType) => {
    if (submitActionType === "EditCandidate") {
      if (!validateEditCandidateAction()) {
        return;
      }
    }

    switch (submitActionType) {
      // 錄取
      case "OfferCandidate":
        displayCandidateOfferActionModal();
        break;
      // 正式錄取
      case "OfficallyAdmitCandidate":
        // Navigate
        navigate("/createStaff", {
          state: { candidateId },
        });
        break;
      default:
        displayCandidateInfoActionDialog(submitActionType);
        break;
    }
  };

  // Events - Modal
  const onCandidateOfferActionModalClosed = () => {
    // Set States
    setShowCandidateOfferActionModal(false);

    // Navigate
    navigate("/applications");
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displayCandidateInfoActionDialog = (candidateInfoActionType) => {
    // Set States
    setCandidateInfoActionDialogType(candidateInfoActionType);

    switch (candidateInfoActionType) {
      // 應徵者婉拒
      case "CandidateTurnDownOffer":
        setCandidateInfoActionDialogText(t("確認應徵者 婉拒 錄取嗎？"));
        break;
      // 修改
      case "EditCandidate":
        setCandidateInfoActionDialogText(t("確認要編輯 應徵者資料 嗎？"));
        break;
      // 設為候補
      case "PutCandidateToWaitingList":
        setCandidateInfoActionDialogText(t("確認要將 應徵者 設為候補嗎？"));
        break;
      // 駁回
      case "RejectCandidate":
        setCandidateInfoActionDialogText(t("確認要 駁回 應徵者嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowCandidateInfoActionDialog(true);
  };

  const displayCandidateOfferActionModal = () => {
    // Set States
    setShowCandidateOfferActionModal(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  const validateEditCandidateAction = () => {
    let isError = false;

    if (!alias) {
      addToErrorFields("alias", t("請先填寫 別名"));
      isError = true;
    }

    if (!dateBirth) {
      addToErrorFields("dateBirth", t("請先填寫 出生日期"));
      isError = true;
    }

    if (!educationLevelField) {
      addToErrorFields("educationLevelField", t("請先填寫 教育程度"));
      isError = true;
    }

    if (!genderTypeField) {
      addToErrorFields("genderTypeField", t("請先填寫 性別"));
      isError = true;
    }

    if (!hkid) {
      addToErrorFields("hkid", t("請先填寫 身分證編號"));
      isError = true;
    }

    if (!fullNameEn) {
      addToErrorFields("fullNameEn", t("請先填寫 英文全名"));
      isError = true;
    }

    if (!phoneNum) {
      addToErrorFields("phoneNum", t("請先填寫 電話號碼"));
      isError = true;
    }

    if (!areaField) {
      addToErrorFields("areaField", t("請先填寫 區域"));
      isError = true;
    }

    if (!districtField) {
      addToErrorFields("districtField", t("請先填寫 地區"));
      isError = true;
    }

    if (!addressEn) {
      addToErrorFields("addressEn", t("請先填寫 英文地址"));
      isError = true;
    }

    if (!titleApplied) {
      addToErrorFields("titleApplied", t("請先填寫 申請職位"));
      isError = true;
    }

    if (!expectedSalary) {
      addToErrorFields("expectedSalary", t("請先填寫 期望工資"));
      isError = true;
    } else {
      if (isNaN(expectedSalary)) {
        addToErrorFields("expectedSalary", t("期望工資 必須為數字"));
        isError = true;
      }
    }

    if (!dateAvailable) {
      addToErrorFields("dateAvailable", t("請先填寫 最快可入職日期"));
      isError = true;
    }

    return !isError;
  };

  // Functions - Mutations
  const candidateTurnDownOffer = async () => {
    if (candidateId) {
      const results = await candidateTurnDownOfferFetch(token, candidateId);

      if (results.success) {
        // Navigate
        navigate("/applications");
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  const editCandidate = async () => {
    if (candidateId) {
      const results = await editCandidateFetch(
        token,
        candidateId,
        hkid,
        genderTypeField ? genderTypeField.id : null,
        fullNameCh,
        fullNameEn,
        alias,
        dateBirth,
        educationLevelField ? educationLevelField.id : null,
        email,
        phoneNum,
        districtField ? districtField.id : null,
        addressCh,
        addressEn,
        titleApplied,
        expectedSalary,
        dateAvailable
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  const putCandidateToWaitingList = async () => {
    if (candidateId) {
      const results = await putCandidateToWaitingListFetch(token, candidateId);

      if (results.success) {
        // Navigate
        navigate("/applications");
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  const rejectCandidate = async () => {
    if (candidateId) {
      const results = await rejectCandidateFetch(token, candidateId);

      if (results.success) {
        // Navigate
        navigate("/applications");
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getCandidateDetailsById = async () => {
    const results = await getCandidateDetailsByIdFetch(token, candidateId);

    if (results.candidateDetails) {
      const {
        hkid,
        gender_type_id,
        full_name_ch,
        full_name_en,
        alias,
        date_birth,
        education_level_id,
        email,
        phone_num,
        address_ch,
        address_en,
        title_applied,
        expected_salary,
        date_available,
        candidate_status_type_id,
        district_id,
        area_id,
      } = results.candidateDetails;

      // Set States
      setHkid(hkid ? hkid : "");
      setGenderTypeField(
        gender_type_id
          ? genderTypeOptions.find((item) => item.id === gender_type_id)
          : null
      );
      setFullNameCh(full_name_ch ? full_name_ch : "");
      setFullNameEn(full_name_en ? full_name_en : "");
      setAlias(alias ? alias : "");
      setDateBirth(date_birth ? date_birth : null);
      setEducationLevelField(
        education_level_id
          ? educationLevelOptions.find((item) => item.id === education_level_id)
          : null
      );
      setEmail(email ? email : "");
      setPhoneNum(phone_num ? phone_num : "");
      setAddressCh(address_ch ? address_ch : "");
      setAddressEn(address_en ? address_en : "");
      setTitleApplied(title_applied ? title_applied : "");
      setExpectedSalary(expected_salary ? expected_salary : "");
      setDateAvailable(date_available ? date_available : null);
      setCandidateStatusTypeId(
        candidate_status_type_id ? candidate_status_type_id : null
      );
      setDistrictField(
        district_id
          ? districtOptions.find((item) => item.id === district_id)
          : null
      );
      setAreaField(
        area_id ? areaOptions.find((item) => item.id === area_id) : null
      );
    } else {
      // Set States
      setHkid("");
      setGenderTypeField(null);
      setFullNameCh("");
      setFullNameEn("");
      setAlias("");
      setDateBirth(null);
      setEducationLevelField(null);
      setEmail("");
      setPhoneNum("");
      setAddressCh("");
      setAddressEn("");
      setTitleApplied("");
      setExpectedSalary("");
      setDateAvailable(null);
      setCandidateStatusTypeId(null);
      setDistrictField(null);
      setAreaField(null);
    }

    clearErrorFields();
  };

  const getCandidateDetailFormOptions = async () => {
    const results = await getCandidateDetailFormOptionsFetch(token);

    // Set States
    setAreaOptions(results.areas ? results.areas : []);
    setDistrictOptions(results.districts ? results.districts : []);
    setEducationLevelOptions(
      results.educationLevels ? results.educationLevels : []
    );
    setGenderTypeOptions(results.genderTypes ? results.genderTypes : []);
  };

  // Life Cycle
  useEffect(() => {
    getCandidateDetailFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && areaOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [areaOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (candidateId) {
        getCandidateDetailsById();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, candidateId]);

  return (
    <>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onCandidateInfoActionDialogCanceled}
        onDialogConfirmed={onCandidateInfoActionDialogConfirmed}
        // States
        dialogText={candidateInfoActionDialogText}
        showDialog={showCandidateInfoActionDialog}
      />
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onCandidateOfferActionModalClosed}
        // States
        showModal={showCandidateOfferActionModal}
      >
        <CandidateOfferActionModal
          // Events
          onModalClosed={onCandidateOfferActionModalClosed}
          // States
          candidateId={candidateId}
          offerId={null}
        />
      </ModalContainer>
      <CandidateInfoFormBasicInfoContent
        // States
        alias={alias}
        dateBirth={dateBirth}
        educationLevelField={educationLevelField}
        educationLevelOptions={educationLevelOptions}
        genderTypeField={genderTypeField}
        genderTypeOptions={genderTypeOptions}
        hkid={hkid}
        fullNameCh={fullNameCh}
        fullNameEn={fullNameEn}
        // Events
        onInputFieldChange={onInputFieldChange}
        onInputFieldKeyPressed={onInputFieldKeyPressed}
        // Functions
        checkIsFieldError={checkIsFieldError}
        getErrorFieldMessage={getErrorFieldMessage}
      />
      <SpacingBox
        // Render
        height={stylesConfig.spacingBoxMarginBottom}
      />
      <CandidateInfoFormContactAndAddressContent
        // States
        addressCh={addressCh}
        addressEn={addressEn}
        areaField={areaField}
        areaOptions={areaOptions}
        districtField={districtField}
        districtOptions={districtOptions}
        email={email}
        phoneNum={phoneNum}
        // Events
        onInputFieldChange={onInputFieldChange}
        onInputFieldKeyPressed={onInputFieldKeyPressed}
        // Functions
        checkIsFieldError={checkIsFieldError}
        getErrorFieldMessage={getErrorFieldMessage}
      />
      <SpacingBox
        // Render
        height={stylesConfig.spacingBoxMarginBottom}
      />
      <CandidateInfoFormApplicationDetailsContent
        // States
        candidateStatusTypeId={candidateStatusTypeId}
        dateAvailable={dateAvailable}
        expectedSalary={expectedSalary}
        formAlertText={formAlertText}
        formAlertType={formAlertType}
        shouldShowFormAlert={shouldShowFormAlert}
        titleApplied={titleApplied}
        // Events
        onInputFieldChange={onInputFieldChange}
        onInputFieldKeyPressed={onInputFieldKeyPressed}
        onSubmitBtnClicked={onSubmitBtnClicked}
        // Functions
        checkIsFieldError={checkIsFieldError}
        getErrorFieldMessage={getErrorFieldMessage}
      />
    </>
  );
}

export default CandidateInfoFormContainer;
