// Configs
import apisConfig from "../configs/apisConfig";

export const createWorkingPlaceFetch = async (
  token,
  workingPlaceTypeId,
  workingPlaceNameCh,
  workingPlaceNameEnFull,
  workingPlaceNameEnShort,
  districtId,
  addressCh,
  addressEn,
  isDefault,
  isActive
) => {
  try {
    const queryRoute = "/workingPlace/createWorkingPlace";

    const reqBody = {
      workingPlaceTypeId,
      workingPlaceNameCh,
      workingPlaceNameEnFull,
      workingPlaceNameEnShort,
      districtId,
      addressCh,
      addressEn,
      isDefault,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteOrRestoreWorkingPlaceFetch = async (
  token,
  workingPlaceId,
  typeOfWorkingPlaces
) => {
  try {
    const queryRoute = `/workingPlace/deleteOrRestoreWorkingPlace/${workingPlaceId}`;

    const reqBody = { typeOfWorkingPlaces };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editWorkingPlaceFetch = async (
  token,
  workingPlaceId,
  workingPlaceTypeId,
  workingPlaceNameCh,
  workingPlaceNameEnFull,
  workingPlaceNameEnShort,
  districtId,
  addressCh,
  addressEn,
  isDefault,
  isActive
) => {
  try {
    const queryRoute = `/workingPlace/editWorkingPlace/${workingPlaceId}`;

    const reqBody = {
      workingPlaceTypeId,
      workingPlaceNameCh,
      workingPlaceNameEnFull,
      workingPlaceNameEnShort,
      districtId,
      addressCh,
      addressEn,
      isDefault,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editWorkingPlaceSetIsDefaultFetch = async (
  token,
  workingPlaceId,
  isDefault
) => {
  try {
    const queryRoute = `/workingPlace/editWorkingPlaceSetIsDefault/${workingPlaceId}`;

    const reqBody = {
      isDefault,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllWorkingPlacesFetch = async (token, typeOfWorkingPlaces) => {
  try {
    const queryRoute = "/workingPlace/getAllWorkingPlaces";

    const reqBody = {
      typeOfWorkingPlaces,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllWorkingPlacesByWorkingPlaceTypeIdFetch = async (
  token,
  workingPlaceTypeId,
  typeOfWorkingPlaces
) => {
  try {
    const queryRoute = `/workingPlace/getAllWorkingPlacesByWorkingPlaceTypeId/${workingPlaceTypeId}`;

    const reqBody = {
      typeOfWorkingPlaces,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getWorkingPlaceDetailsByIdFetch = async (
  token,
  workingPlaceId
) => {
  try {
    const queryRoute = `/workingPlace/getWorkingPlaceDetailsById/${workingPlaceId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
