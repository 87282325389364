// Configs
import apisConfig from "../configs/apisConfig";

export const createStaffContractFetch = async (
  token,
  staffContractTypeId,
  salaryCountTypeId,
  staffId,
  fixedSalary,
  effectiveMonth,
  isCommission,
  isLunchIncluded
) => {
  try {
    const queryRoute = "/staffContract/createStaffContract";

    const reqBody = {
      staffContractTypeId,
      salaryCountTypeId,
      staffId,
      fixedSalary,
      effectiveMonth,
      isCommission,
      isLunchIncluded,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteStaffContractFetch = async (token, staffContractId) => {
  try {
    const queryRoute = `/staffContract/deleteStaffContract/${staffContractId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editStaffContractFetch = async (
  token,
  staffContractId,
  staffContractTypeId,
  salaryCountTypeId,
  fixedSalary,
  effectiveMonth,
  isCommission,
  isLunchIncluded
) => {
  try {
    const queryRoute = `/staffContract/editStaffContract/${staffContractId}`;

    const reqBody = {
      staffContractTypeId,
      salaryCountTypeId,
      fixedSalary,
      effectiveMonth,
      isCommission,
      isLunchIncluded,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllStaffContractsByStaffIdFetch = async (token, staffId) => {
  try {
    const queryRoute = `/staffContract/getAllStaffContractsByStaffId/${staffId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getStaffContractDetailsByIdFetch = async (
  token,
  staffContractId
) => {
  try {
    const queryRoute = `/staffContract/getStaffContractDetailsById/${staffContractId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
