// Actions
import { closeDrawer } from "../../redux/shared/drawer/drawerActions";

// Components
// List Item
import LeftDrawerListItem from "./leftDrawerListItem";

// Configs
import stylesConfig from "../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../customHooks/getLanguage";

// Helper Functions
import { getPagePath } from "../../helperFunctions/getPagePath";

// Material UI
// Components
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
// Icons
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import AdminPanelSettingsRoundedIcon from "@mui/icons-material/AdminPanelSettingsRounded";
import ApartmentRoundedIcon from "@mui/icons-material/ApartmentRounded";
import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";
import EventRoundedIcon from "@mui/icons-material/EventRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useLocation, useNavigate } from "react-router-dom";

// Styles
import { dividerStyles } from "../../styles/componentStyles/drawerStyles/leftDrawerStyles";

function LeftDrawerList() {
  // Hooks
  // Languages
  const t = useLanguage();
  // React-Router
  const location = useLocation();
  const navigate = useNavigate();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const applicationBadgeNum = useSelector(
    (state) => state.badge.applicationBadgeNum
  );

  // Events
  // Events - Drawer Items
  const onDrawerItemClicked = (key) => {
    const path = getPagePath(key);

    // Navigate
    navigate(path);

    // Update Redux Store
    dispatch(closeDrawer());
  };

  return (
    <List>
      <ListItem sx={{ height: stylesConfig.leftDrawerHeight }}>
        <ListItemText primary={"HRMS Demo"} />
      </ListItem>
      <Divider sx={dividerStyles} />
      {/* 主頁 */}
      <LeftDrawerListItem
        // Render
        icon={<HomeRoundedIcon />}
        itemKey={"Dashboard"}
        location={location}
        pathnamesArr={["/dashboard"]}
        listItemText={t("主頁")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 部門 / 店鋪 */}
      <LeftDrawerListItem
        // Render
        icon={<GroupsRoundedIcon />}
        itemKey={"DeptsAndShops"}
        location={location}
        pathnamesArr={["/createStaff", "/deptsAndShops", "/staffProfile"]}
        listItemText={t("部門 / 店鋪s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 工作時段 / 地點 */}
      <LeftDrawerListItem
        // Render
        icon={<ListAltRoundedIcon />}
        itemKey={"WorkingPeriodsAndPlaces"}
        location={location}
        pathnamesArr={["/workingPeriodsAndPlaces"]}
        listItemText={t("工作時段 / 地點s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 出勤紀錄 */}
      <LeftDrawerListItem
        // Render
        icon={<AccessTimeRoundedIcon />}
        itemKey={"Attendances"}
        location={location}
        pathnamesArr={["/attendances"]}
        listItemText={t("出勤紀錄s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 更表 */}
      <LeftDrawerListItem
        // Render
        icon={<DateRangeRoundedIcon />}
        itemKey={"Rosters"}
        location={location}
        pathnamesArr={["/rosters"]}
        listItemText={t("更表s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 申請 / 流程 */}
      <LeftDrawerListItem
        // Render
        badgeContent={applicationBadgeNum}
        icon={<InventoryRoundedIcon />}
        itemKey={"Applications"}
        location={location}
        pathnamesArr={["/applications", "/candidateProfile"]}
        listItemText={t("申請 / 流程s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 公司 / 品牌 */}
      <LeftDrawerListItem
        // Render
        icon={<ApartmentRoundedIcon />}
        itemKey={"CompsAndBrands"}
        location={location}
        pathnamesArr={["/compsAndBrands"]}
        listItemText={t("公司 / 品牌s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 職位 */}
      <LeftDrawerListItem
        // Render
        icon={<AssignmentIndRoundedIcon />}
        itemKey={"Titles"}
        location={location}
        pathnamesArr={["/titles"]}
        listItemText={t("職位s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 假期管理 */}
      <LeftDrawerListItem
        // Render
        icon={<EventRoundedIcon />}
        itemKey={"DayOffTypes"}
        location={location}
        pathnamesArr={["/dayOffTypes"]}
        listItemText={t("假期管理s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 薪酬 */}
      <LeftDrawerListItem
        // Render
        icon={<AttachMoneyRoundedIcon />}
        itemKey={"Salaries"}
        location={location}
        pathnamesArr={["/salaries"]}
        listItemText={t("薪酬s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 權限 */}
      <LeftDrawerListItem
        // Render
        icon={<AdminPanelSettingsRoundedIcon />}
        itemKey={"Permissions"}
        location={location}
        pathnamesArr={["/permissions"]}
        listItemText={t("權限s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
    </List>
  );
}

export default LeftDrawerList;
