// Components
import StyledMenu from "../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

function PermissionGroupActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // States
    showPermissionGroupActionMenu,
    // Set States
    setShowPermissionGroupActionMenu,
    // Functions
    displayPermissionGroupActionDialog,
  } = props;

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowPermissionGroupActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "Delete":
        displayPermissionGroupActionDialog("DeletePermissionGroup");
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showPermissionGroupActionMenu}
      open={Boolean(showPermissionGroupActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Delete")}>
        <DeleteRoundedIcon />
        {t("刪除")}
      </MenuItem>
    </StyledMenu>
  );
}

export default PermissionGroupActionMenu;
