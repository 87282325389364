export const getPageTitleText = (titleType) => {
  let titleText;

  switch (titleType) {
    case "Applications":
      titleText = "申請 / 流程s";
      break;
    case "Attendances":
      titleText = "出勤紀錄s";
      break;
    case "CandidateProfile":
      titleText = "應徵者概覧";
      break;
    case "CompsAndBrands":
      titleText = "公司 / 品牌s";
      break;
    case "CreateStaff":
      titleText = "新增員工";
      break;
    case "Dashboard":
      titleText = "主頁";
      break;
    case "DayOffTypes":
      titleText = "假期管理s";
      break;
    case "DeptsAndShops":
      titleText = "部門 / 店鋪s";
      break;
    case "Permissions":
      titleText = "權限s";
      break;
    case "Rosters":
      titleText = "更表s";
      break;
    case "Salaries":
      titleText = "薪酬s";
      break;
    case "StaffProfile":
      titleText = "員工概覧";
      break;
    case "Titles":
      titleText = "職位s";
      break;
    case "WorkingPeriodsAndPlaces":
      titleText = "工作時段 / 地點s";
      break;
    default:
      titleText = "";
      break;
  }

  return titleText;
};
