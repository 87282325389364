export const getMonthEndDate = (month) => {
  const monthVar = new Date(month);

  return new Date(monthVar.getFullYear(), monthVar.getMonth() + 1, 0);
};

export const getMonthStartDate = (month) => {
  const monthVar = new Date(month);

  return new Date(monthVar.getFullYear(), monthVar.getMonth(), 1);
};
