// Actions
import {
  onDayOffTypeChange,
  onDayOffTypeItemChange,
} from "../../../redux/pages/dayOffTypesPage/dayOffTypesPageActions";

// Components
// Boxes
import DayOffTypeBox from "./dayOffTypeBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import DayOffTypeActionMenu from "../../menus/dayOffTypesPage/dayOffTypeActionMenu";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteOrRestoreDayOffTypeFetch,
  getAllDayOffTypesFetch,
} from "../../../fetches/dayOffTypeFetches";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function DayOffTypeBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const dayOffTypeId = useSelector(
    (state) => state.dayOffTypesPage.dayOffTypeId
  );
  const tabItem = useSelector((state) => state.dayOffTypesPage.tabItem);
  const formSubmitCount = useSelector(
    (state) => state.dayOffTypesPage.formSubmitCount
  );
  const token = useSelector((state) => state.staff.token);
  const typeOfDayOffTypes = useSelector(
    (state) => state.dayOffTypesPage.typeOfDayOffTypes
  );

  // States
  // Data
  const [dayOffTypes, setDayOffTypes] = useState(null);
  // Dialog
  const [dayOffTypeActionDialogText, setDayOffTypeActionDialogText] =
    useState("");
  const [dayOffTypeActionDialogType, setDayOffTypeActionDialogType] =
    useState(null);
  const [showDayOffTypeActionDialog, setShowDayOffTypeActionDialog] =
    useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showDayOffTypeActionMenu, setShowDayOffTypeActionMenu] =
    useState(null);
  // Render
  const [selectedDayOffType, setSelectedDayOffType] = useState(null);

  // Handle States
  const currentItemId = dayOffTypeId;
  const itemsArr = dayOffTypes;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onDayOffTypeChange(itemId));
  };

  const onItemRightClicked = (item, currentTarget) => {
    onItemClicked(item.id);
    // Set States
    setSelectedDayOffType(item);
    setShowDayOffTypeActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (item, currentTarget) => {
    onItemClicked(item.id);
    // Set States
    setSelectedDayOffType(item);
    setShowDayOffTypeActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onDayOffTypeActionDialogCanceled = () => {
    // Set States
    setShowDayOffTypeActionDialog(false);
  };

  const onDayOffTypeActionDialogConfirmed = () => {
    switch (dayOffTypeActionDialogType) {
      // 刪除
      case "DeleteDayOffType":
        deleteOrRestoreDayOffType();
        break;
      // 還原
      case "RestoreDayOffType":
        deleteOrRestoreDayOffType();
        break;
      default:
        break;
    }

    // Set States
    setShowDayOffTypeActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Update Redux Store
    dispatch(onDayOffTypeChange(null));
  };

  // Functions
  // Functions - Normal
  const displayDayOffTypeActionDialog = (dayOffTypeActionType) => {
    // Set States
    setDayOffTypeActionDialogType(dayOffTypeActionType);

    switch (dayOffTypeActionType) {
      // 刪除
      case "DeleteDayOffType":
        setDayOffTypeActionDialogText(t("確認要刪除 假期種類 嗎？"));
        break;
      // 還原
      case "RestoreDayOffType":
        setDayOffTypeActionDialogText(t("確認要還原 假期種類 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowDayOffTypeActionDialog(true);
  };

  // Functions - Mutations
  const deleteOrRestoreDayOffType = async () => {
    const results = await deleteOrRestoreDayOffTypeFetch(
      token,
      currentItemId,
      typeOfDayOffTypes
    );

    if (results.success) {
      getAllDayOffTypes(true);
    }
  };

  // Functions
  // Functions - Queries
  const getAllDayOffTypes = async (shoulddUpdateCurrentDayOffTypeId) => {
    const results = await getAllDayOffTypesFetch(token, typeOfDayOffTypes);

    // Set States
    setDayOffTypes(results.dayOffTypes ? results.dayOffTypes : null);

    // Update Redux Store
    if (shoulddUpdateCurrentDayOffTypeId) {
      dispatch(
        onDayOffTypeChange(
          results.dayOffTypes ? results.dayOffTypes[0].id : null
        )
      );
    }
  };

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    if (dayOffTypes && dayOffTypeId) {
      dispatch(
        onDayOffTypeItemChange(
          dayOffTypes.find((item) => item.id === dayOffTypeId)
        )
      );
    }
  }, [dayOffTypes, dayOffTypeId]);

  useEffect(() => {
    if (tabItem === "DayOffTypes") {
      getAllDayOffTypes(dayOffTypeId ? false : true);
    }
  }, [tabItem, formSubmitCount, typeOfDayOffTypes]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onDayOffTypeActionDialogCanceled}
        onDialogConfirmed={onDayOffTypeActionDialogConfirmed}
        // States
        dialogText={dayOffTypeActionDialogText}
        showDialog={showDayOffTypeActionDialog}
      />
      {/* Menu */}
      <DayOffTypeActionMenu
        // States
        selectedDayOffType={selectedDayOffType}
        showDayOffTypeActionMenu={showDayOffTypeActionMenu}
        // Set States
        setDayOffTypeActionDialogType={setDayOffTypeActionDialogType}
        setSelectedDayOffType={setSelectedDayOffType}
        setShowDayOffTypeActionMenu={setShowDayOffTypeActionMenu}
        // Functions
        displayDayOffTypeActionDialog={displayDayOffTypeActionDialog}
      />
      {/* DayOffType */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("假期種類s")}
          </Typography>
          <Tooltip placement="right" title={t("新增假期種類")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <DayOffTypeBox
            key={item.id}
            // States
            currentItemId={currentItemId}
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有假期種類資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default DayOffTypeBoxesContainer;
