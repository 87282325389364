export const hourOptions = [
  { value: 0, textCh: "0小時", textEn: "0 Hour" },
  { value: 1, textCh: "1小時", textEn: "1 Hour" },
  { value: 2, textCh: "2小時", textEn: "2 Hours" },
  { value: 3, textCh: "3小時", textEn: "3 Hours" },
  { value: 4, textCh: "4小時", textEn: "4 Hours" },
  { value: 5, textCh: "5小時", textEn: "5 Hours" },
  { value: 6, textCh: "6小時", textEn: "6 Hours" },
  { value: 7, textCh: "7小時", textEn: "7 Hours" },
  { value: 8, textCh: "8小時", textEn: "8 Hours" },
  { value: 9, textCh: "9小時", textEn: "9 Hours" },
  { value: 10, textCh: "10小時", textEn: "10 Hours" },
  { value: 11, textCh: "11小時", textEn: "11 Hours" },
  { value: 12, textCh: "12小時", textEn: "12 Hours" },
];

export const minuteOptions = [
  { value: 0, textCh: "0分鐘", textEn: "0 Min" },
  { value: 5, textCh: "5分鐘", textEn: "5 Mins" },
  { value: 10, textCh: "10分鐘", textEn: "10 Mins" },
  { value: 15, textCh: "15分鐘", textEn: "15 Mins" },
  { value: 20, textCh: "20分鐘", textEn: "20 Mins" },
  { value: 25, textCh: "25分鐘", textEn: "25 Mins" },
  { value: 30, textCh: "30分鐘", textEn: "30 Mins" },
  { value: 35, textCh: "35分鐘", textEn: "35 Mins" },
  { value: 40, textCh: "40分鐘", textEn: "40 Mins" },
  { value: 45, textCh: "45分鐘", textEn: "45 Mins" },
  { value: 50, textCh: "50分鐘", textEn: "50 Mins" },
  { value: 55, textCh: "55分鐘", textEn: "55 Mins" },
];
