// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardTableStyles } from "../../../styles/componentStyles/tableStyles/standardTableStyles";

function StaffBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardTableStyles();

  // Props
  const {
    // States
    divisionType,
    hoveredStaffId,
    hoveredStaffItemId,
    item,
    shopItem,
    // Events
    onItemClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  return (
    <div
      onClick={() => onItemClicked(item.id)}
      onMouseEnter={() => onMouseEnterItem(item.id)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div
        className={
          hoveredStaffId === item.id
            ? classes.selectedHeadCellBox
            : classes.headCellBox
        }
      >
        <Typography
          align={"left"}
          color={
            hoveredStaffItemId === item.id
              ? stylesConfig.mainTextColor
              : hoveredStaffId === item.id
              ? stylesConfig.whiteTextColor
              : null
          }
          variant="body1"
        >
          {`${item.staff_code} - ${
            item.alias ? item.alias : item.full_name_en
          }`}
        </Typography>
      </div>
      <div className={classes.headCellBox}>
        <Typography
          align={"left"}
          color={
            divisionType === "Departments" ||
            (shopItem && item.dept_name_display.includes(shopItem.shop_code))
              ? stylesConfig.greyTextColor
              : stylesConfig.mainTextColor
          }
          variant="body2"
        >
          {divisionType === "Shops" &&
          shopItem &&
          item.dept_name_display &&
          !item.dept_name_display.includes(shopItem.shop_code)
            ? `${item.dept_name_display} - `
            : null}
          {item[t("title_name_ch")]}
        </Typography>
      </div>
    </div>
  );
}

export default StaffBox;
