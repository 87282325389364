// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardTableStyles } from "../../../styles/componentStyles/tableStyles/standardTableStyles";

function AttendanceSummaryBox(props) {
  // Styles
  const classes = useStandardTableStyles();

  // Props
  const {
    // States
    item,
    staffId,
    // Events
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  return (
    <div
      className={classes.cellBoxLargeWithoutPointer}
      onMouseEnter={() => {
        onMouseEnterItem(staffId, item.id);
      }}
      onMouseLeave={() => {
        onMouseLeaveItem();
      }}
    >
      <Typography
        align={"center"}
        color={
          item.value && item.value >= 0.01 ? null : stylesConfig.greyTextColor
        }
        variant="body1"
      >
        {item.value}
      </Typography>
    </div>
  );
}

export default AttendanceSummaryBox;
