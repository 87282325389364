// Actions
import {
  onFormSubmitCountIncrease,
  onPermissionGroupChange,
} from "../../../redux/pages/permissionsPage/permissionsPageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Forms
import PermissionGroupDetailFormContent from "./permissionGroupDetailFormContent";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  createPermissionGroupFetch,
  editPermissionGroupFetch,
  getPermissionGroupDetailsByIdFetch,
} from "../../../fetches/permissionGroupFetches";
import { getPermissionGroupDetailFormOptionsFetch } from "../../../fetches/formOptionFetches";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function PermissionGroupDetailFormContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const permissionId = useSelector(
    (state) => state.permissionsPage.permissionId
  );
  const permissionGroupId = useSelector(
    (state) => state.permissionsPage.permissionGroupId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [
    permissionGroupDetailActionDialogText,
    setPermissionGroupDetailActionDialogText,
  ] = useState("");
  const [
    permissionGroupDetailActionDialogType,
    setPermissionGroupDetailActionDialogType,
  ] = useState(null);
  const [showPermissionGroupDetailAction, setShowPermissionGroupDetailAction] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [departmentField, setDepartmentField] = useState([]);
  const [divisionField, setDivisionField] = useState([]);
  const [permissionDeptTypeField, setPermissionDeptTypeField] = useState(null);
  const [permissionStaffTypeField, setPermissionStaffTypeField] =
    useState(null);
  const [shopField, setShopField] = useState([]);
  const [titleField, setTitleField] = useState([]);
  // Options
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [permissionDeptTypeOptions, setPermissionDeptTypeOptions] = useState(
    []
  );
  const [permissionStaffTypeOptions, setPermissionStaffTypeOptions] = useState(
    []
  );
  const [shopOptions, setShopOptions] = useState([]);
  const [titleOptions, setTitleOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);

  // Events
  // Events - Dialogs
  const onPermissionGroupDetailActionCanceled = () => {
    // Set States
    setShowPermissionGroupDetailAction(false);
  };

  const onPermissionGroupDetailActionConfirmed = () => {
    switch (permissionGroupDetailActionDialogType) {
      case "CreatePermissionGroup":
        createPermissionGroup();
        break;
      case "EditPermissionGroup":
        editPermissionGroup();
        break;
      default:
        break;
    }

    // Set States
    setShowPermissionGroupDetailAction(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "departmentField":
        setDepartmentField(value);
        break;
      case "divisionField":
        setDivisionField(value);
        break;
      case "permissionDeptTypeField":
        setPermissionDeptTypeField(value);

        // !所有部門 / 店舖 || 指定部門 / 店舖
        if (
          !["All", "Specific Dept"].includes(value.permission_dept_type_name_en)
        ) {
          setPermissionStaffTypeField(null);

          removeErrorField("permissionStaffTypeField");
        }

        // !指定部門 / 店舖
        if (value.permission_dept_type_name_en !== "Specific Dept") {
          setDepartmentField([]);
          setShopField([]);

          removeErrorField("departmentField");
          removeErrorField("shopField");
        }

        // !指定職位
        if (value.permission_dept_type_name_en !== "Specific Title") {
          setTitleField([]);

          removeErrorField("titleField");
        }

        break;
      case "permissionStaffTypeField":
        setPermissionStaffTypeField(value);

        // !指定職位
        if (value.permission_staff_type_name_en !== "Specific Title") {
          setTitleField([]);

          removeErrorField("titleField");
        }

        break;
      case "shopField":
        setShopField(value);
        break;
      case "titleField":
        setTitleField(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!permissionDeptTypeField) {
      addToErrorFields(
        "permissionDeptTypeField",
        t("請先填寫 部門 / 店鋪類型")
      );
      isError = true;
    }

    // 所有部門 / 店舖 || 指定部門 / 店舖
    if (
      ["All", "Specific Dept"].includes(
        permissionDeptTypeField.permission_dept_type_name_en
      )
    ) {
      if (!permissionStaffTypeField) {
        addToErrorFields("permissionStaffTypeField", t("請先填寫 員工類型"));
        isError = true;
      }
    }

    if (!divisionField[0]) {
      addToErrorFields("divisionField", t("請先填寫 套用到"));
      isError = true;
    }

    // 指定部門 / 店舖
    if (
      permissionDeptTypeField.permission_dept_type_name_en === "Specific Dept"
    ) {
      if (!departmentField[0] && !shopField[0]) {
        addToErrorFields("departmentField", t("請先填寫 部門 / 店鋪"));
        addToErrorFields("shopField", t("請先填寫 部門 / 店鋪"));
        isError = true;
      }
    }

    // 指定職位
    if (
      permissionDeptTypeField.permission_dept_type_name_en ===
        "Specific Title" ||
      (permissionStaffTypeField &&
        permissionStaffTypeField.permission_staff_type_name_en ===
          "Specific Title")
    ) {
      if (!titleField[0]) {
        addToErrorFields("titleField", t("請先填寫 職位"));
        isError = true;
      }
    }

    if (isError) {
      return;
    }

    displayPermissionGroupDetailActionDialog(
      permissionGroupId ? "EditPermissionGroup" : "CreatePermissionGroup"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const clearForm = () => {
    // Set States
    setPermissionDeptTypeField(null);
    setPermissionStaffTypeField(null);
    setDepartmentField([]);
    setDivisionField([]);
    setShopField([]);
    setTitleField([]);

    clearErrorFields();
  };

  const displayPermissionGroupDetailActionDialog = (
    permissionGroupDetailActionType
  ) => {
    // Set States
    setPermissionGroupDetailActionDialogType(permissionGroupDetailActionType);
    switch (permissionGroupDetailActionType) {
      case "CreatePermissionGroup":
        setPermissionGroupDetailActionDialogText(t("確認要新增 群組 嗎？"));
        break;
      case "EditPermissionGroup":
        setPermissionGroupDetailActionDialogText(t("確認要編輯 群組資料 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowPermissionGroupDetailAction(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createPermissionGroup = async () => {
    const results = await createPermissionGroupFetch(
      token,
      permissionId,
      permissionDeptTypeField ? permissionDeptTypeField.id : null,
      permissionStaffTypeField ? permissionStaffTypeField.id : null,
      departmentField[0] ? departmentField.map((item) => item.id) : null,
      divisionField[0] ? divisionField.map((item) => item.id) : null,
      shopField[0] ? shopField.map((item) => item.id) : null,
      titleField[0] ? titleField.map((item) => item.id) : null
    );

    if (results.success) {
      showFormAlert("success", t("成功提交"));

      // Update Redux Store
      dispatch(onPermissionGroupChange(results.permissionGroupId));
      dispatch(onFormSubmitCountIncrease());
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editPermissionGroup = async () => {
    if (permissionGroupId) {
      const results = await editPermissionGroupFetch(
        token,
        permissionGroupId,
        permissionDeptTypeField ? permissionDeptTypeField.id : null,
        permissionStaffTypeField ? permissionStaffTypeField.id : null,
        departmentField[0] ? departmentField.map((item) => item.id) : null,
        divisionField[0] ? divisionField.map((item) => item.id) : null,
        shopField[0] ? shopField.map((item) => item.id) : null,
        titleField[0] ? titleField.map((item) => item.id) : null
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else if (results.isDuplicate) {
        showFormAlert("warning", t("此 群組 已存在"));
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getPermissionGroupDetailFormOptions = async () => {
    const results = await getPermissionGroupDetailFormOptionsFetch(token);

    // Set States
    setPermissionDeptTypeOptions(
      results.permissionDeptTypes ? results.permissionDeptTypes : []
    );
    setPermissionStaffTypeOptions(
      results.permissionStaffTypes ? results.permissionStaffTypes : []
    );
    setDepartmentOptions(results.departments ? results.departments : []);
    setDivisionOptions(results.divisions ? results.divisions : []);
    setShopOptions(results.shops ? results.shops : []);
    setTitleOptions(results.titles ? results.titles : []);
  };

  const getPermissionGroupDetailsById = async () => {
    const results = await getPermissionGroupDetailsByIdFetch(
      token,
      permissionGroupId
    );

    if (results.permissionGroupDetails) {
      const {
        permission_dept_type_id,
        permission_staff_type_id,
        dependencies,
      } = results.permissionGroupDetails;

      const { departments, divisions, shops, titles } = dependencies;

      // Set States
      setPermissionDeptTypeField(
        permission_dept_type_id
          ? permissionDeptTypeOptions.find(
              (item) => item.id === permission_dept_type_id
            )
          : null
      );

      setPermissionStaffTypeField(
        permission_staff_type_id
          ? permissionStaffTypeOptions.find(
              (item) => item.id === permission_staff_type_id
            )
          : null
      );

      if (departments) {
        let departmentsArr = [];

        for (let departmentItem of departments) {
          if (departmentOptions.find((item) => item.id === departmentItem.id)) {
            departmentsArr.push(
              departmentOptions.find((item) => item.id === departmentItem.id)
            );
          }
        }

        setDepartmentField(departmentsArr);
      } else {
        setDepartmentField([]);
      }

      if (divisions) {
        let divisionsArr = [];

        for (let divisionItem of divisions) {
          if (divisionOptions.find((item) => item.id === divisionItem.id)) {
            divisionsArr.push(
              divisionOptions.find((item) => item.id === divisionItem.id)
            );
          }
        }

        setDivisionField(divisionsArr);
      } else {
        setDivisionField([]);
      }

      if (shops) {
        let shopsArr = [];

        for (let shopItem of shops) {
          if (shopOptions.find((item) => item.id === shopItem.id)) {
            shopsArr.push(shopOptions.find((item) => item.id === shopItem.id));
          }
        }

        setShopField(shopsArr);
      } else {
        setShopField([]);
      }

      if (titles) {
        let titlesArr = [];

        for (let titleItem of titles) {
          if (titleOptions.find((item) => item.id === titleItem.id)) {
            titlesArr.push(
              titleOptions.find((item) => item.id === titleItem.id)
            );
          }
        }

        setTitleField(titlesArr);
      } else {
        setTitleField([]);
      }
    } else {
      // Set States
      setPermissionDeptTypeField(null);
      setPermissionStaffTypeField(null);
      setDepartmentField([]);
      setDivisionField([]);
      setShopField([]);
      setTitleField([]);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    getPermissionGroupDetailFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && departmentOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [departmentOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (permissionGroupId) {
        getPermissionGroupDetailsById();
      } else {
        clearForm();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, permissionGroupId]);

  return (
    <>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onPermissionGroupDetailActionCanceled}
        onDialogConfirmed={onPermissionGroupDetailActionConfirmed}
        // States
        dialogText={permissionGroupDetailActionDialogText}
        showDialog={showPermissionGroupDetailAction}
      />
      <PermissionGroupDetailFormContent
        // States
        departmentField={departmentField}
        departmentOptions={departmentOptions}
        divisionField={divisionField}
        divisionOptions={divisionOptions}
        formAlertText={formAlertText}
        formAlertType={formAlertType}
        permissionDeptTypeField={permissionDeptTypeField}
        permissionDeptTypeOptions={permissionDeptTypeOptions}
        permissionGroupId={permissionGroupId}
        permissionStaffTypeField={permissionStaffTypeField}
        permissionStaffTypeOptions={permissionStaffTypeOptions}
        shopField={shopField}
        shopOptions={shopOptions}
        shouldShowFormAlert={shouldShowFormAlert}
        titleField={titleField}
        titleOptions={titleOptions}
        // Events
        onInputFieldChange={onInputFieldChange}
        onInputFieldKeyPressed={onInputFieldKeyPressed}
        onSubmitBtnClicked={onSubmitBtnClicked}
        // Fucntions
        checkIsFieldError={checkIsFieldError}
        getErrorFieldMessage={getErrorFieldMessage}
      />
    </>
  );
}

export default PermissionGroupDetailFormContainer;
