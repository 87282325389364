// Actions
import {
  onContractChange,
  onFormSubmitCountIncrease,
} from "../../../../redux/pages/staffProfilePage/staffProfilePageActions";

// Components
// Dialogs
import AlertDialog from "../../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../../customHooks/getLanguage";

// Fetches
import { getStaffContractDetailFormOptionsFetch } from "../../../../fetches/formOptionFetches";
import {
  createStaffContractFetch,
  deleteStaffContractFetch,
  editStaffContractFetch,
  getStaffContractDetailsByIdFetch,
} from "../../../../fetches/staffContractFetches";

// Helper Functions
import { getMonthStartDate } from "../../../../helperFunctions/getDate";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function StaffContractDetailForm() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const contractId = useSelector((state) => state.staffProfilePage.contractId);
  const staffId = useSelector((state) => state.staffProfilePage.staffId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Date Fields
  const [effectiveMonth, setEffectiveDate] = useState(null);
  // Dialog
  const [
    staffContractDetailActionDialogText,
    setStaffContractDetailActionDialogText,
  ] = useState("");
  const [
    staffContractDetailActionDialogType,
    setStaffContractDetailActionDialogType,
  ] = useState(null);
  const [
    showStaffContractDetailActionDialog,
    setShowStaffContractDetailActionDialog,
  ] = useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [salaryCountTypeField, setSalaryCountTypeField] = useState(null);
  const [staffContractTypeField, setStaffContractTypeField] = useState(null);
  // Options
  const [salaryCountTypeOptions, setSalaryCountTypeOptions] = useState([]);
  const [staffContractTypeOptions, setStaffContractTypeOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  const [isStaffContractCancelable, setIsStaffContractCancelable] =
    useState(false);
  // Switches
  const [isCommission, setIsCommission] = useState(true);
  const [isLunchIncluded, setIsLunchIncluded] = useState(true);
  // Text Fields
  const [fixedSalary, setFixedSalary] = useState("");

  // Handle States
  const effectiveMonthVar = effectiveMonth ? new Date(effectiveMonth) : null;

  // Events
  // Events - Dialogs
  const onStaffContractDetailActionDialogCanceled = () => {
    // Set States
    setShowStaffContractDetailActionDialog(false);
  };

  const onStaffContractDetailActionConfirmed = () => {
    switch (staffContractDetailActionDialogType) {
      case "CreateStaffContract":
        createStaffContract();
        break;
      case "DeleteStaffContract":
        deleteStaffContract();
        break;
      case "EditStaffContract":
        editStaffContract();
        break;
      case "UnableToDeleteStaffContract":
        break;
      default:
        break;
    }

    // Set States
    setShowStaffContractDetailActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "effectiveMonth":
        setEffectiveDate(value);
        break;
      case "fixedSalary":
        setFixedSalary(value);
        break;
      case "isCommission":
        setIsCommission(value);
        break;
      case "isLunchIncluded":
        setIsLunchIncluded(value);
        break;
      case "salaryCountTypeField":
        setSalaryCountTypeField(value);
        break;
      case "staffContractTypeField":
        setStaffContractTypeField(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onDeleteBtnClicked = () => {
    displayStaffContractDetailActionDialog(
      isStaffContractCancelable
        ? "DeleteStaffContract"
        : "UnableToDeleteStaffContract"
    );
  };

  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!effectiveMonth) {
      addToErrorFields("effectiveMonth", t("請先填寫 生效月份"));
      isError = true;
    }

    if (!fixedSalary) {
      addToErrorFields("fixedSalary", t("請先填寫 固定工資"));
      isError = true;
    } else {
      if (isNaN(fixedSalary)) {
        addToErrorFields("fixedSalary", t("固定工資 必須為數字"));
        isError = true;
      }
    }

    if (!salaryCountTypeField) {
      addToErrorFields("salaryCountTypeField", t("請先填寫 工資單位"));
      isError = true;
    }

    if (!staffContractTypeField) {
      addToErrorFields("staffContractTypeField", t("請先填寫 合約類型"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayStaffContractDetailActionDialog(
      contractId ? "EditStaffContract" : "CreateStaffContract"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const clearForm = () => {
    // Set States
    setEffectiveDate(null);
    setFixedSalary("");
    setIsCommission(true);
    setIsLunchIncluded(true);
    setSalaryCountTypeField(null);
    setStaffContractTypeField(null);

    clearErrorFields();
  };

  const displayStaffContractDetailActionDialog = (
    staffContractDetailActionType
  ) => {
    // Set States
    setStaffContractDetailActionDialogType(staffContractDetailActionType);
    switch (staffContractDetailActionType) {
      case "CreateStaffContract":
        setStaffContractDetailActionDialogText(t("確認要新增 員工合約 嗎？"));
        break;
      case "DeleteStaffContract":
        setStaffContractDetailActionDialogText(t("確認要取消 員工合約 嗎？"));
        break;
      case "EditStaffContract":
        setStaffContractDetailActionDialogText(t("確認要編輯 員工合約 嗎？"));
        break;
      case "UnableToDeleteStaffContract":
        break;
      default:
        break;
    }

    // Set States
    setShowStaffContractDetailActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createStaffContract = async () => {
    const effectiveMonthStartDate = getMonthStartDate(effectiveMonth);

    const results = await createStaffContractFetch(
      token,
      staffContractTypeField ? staffContractTypeField.id : null,
      salaryCountTypeField ? salaryCountTypeField.id : null,
      staffId,
      fixedSalary,
      effectiveMonthStartDate,
      isCommission,
      isLunchIncluded
    );

    if (results.success) {
      showFormAlert("success", t("成功提交"));

      // Update Redux Store
      dispatch(onContractChange(results.staffContractId));
      dispatch(onFormSubmitCountIncrease());
    } else if (results.validatetionFailed) {
      showFormAlert("warning", results.message);
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const deleteStaffContract = async () => {
    const results = await deleteStaffContractFetch(token, contractId);

    if (results.success) {
      showFormAlert("success", t("成功提交"));

      // Update Redux Store
      dispatch(onContractChange(null));
      dispatch(onFormSubmitCountIncrease());
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editStaffContract = async () => {
    if (contractId) {
      const effectiveMonthStartDate = getMonthStartDate(effectiveMonth);

      const results = await editStaffContractFetch(
        token,
        contractId,
        staffContractTypeField ? staffContractTypeField.id : null,
        salaryCountTypeField ? salaryCountTypeField.id : null,
        fixedSalary,
        effectiveMonthStartDate,
        isCommission,
        isLunchIncluded
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else if (results.validatetionFailed) {
        showFormAlert("warning", results.message);
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getStaffContractDetailsById = async () => {
    const results = await getStaffContractDetailsByIdFetch(token, contractId);

    if (results.staffContractDetails) {
      const {
        effective_month,
        fixed_salary,
        is_commission,
        is_lunch_included,
        salary_count_type_id,
        staff_contract_type_id,
        isCancelable,
      } = results.staffContractDetails;

      // Set States
      setEffectiveDate(effective_month ? effective_month : null);
      setFixedSalary(fixed_salary ? fixed_salary : "");
      setIsCommission(is_commission);
      setIsLunchIncluded(is_lunch_included);
      setSalaryCountTypeField(
        salary_count_type_id
          ? salaryCountTypeOptions.find(
              (item) => item.id === salary_count_type_id
            )
          : null
      );
      setStaffContractTypeField(
        staff_contract_type_id
          ? staffContractTypeOptions.find(
              (item) => item.id === staff_contract_type_id
            )
          : null
      );

      setIsStaffContractCancelable(isCancelable);
    } else {
      // Set States
      setEffectiveDate(null);
      setFixedSalary("");
      setIsCommission(true);
      setIsLunchIncluded(true);
      setSalaryCountTypeField(null);
      setStaffContractTypeField(null);

      setIsStaffContractCancelable(false);
    }

    clearErrorFields();
  };

  const getStaffContractDetailFormOptions = async () => {
    const results = await getStaffContractDetailFormOptionsFetch(token);

    // Set States
    setSalaryCountTypeOptions(
      results.salaryCountTypes ? results.salaryCountTypes : []
    );
    setStaffContractTypeOptions(
      results.staffContractTypes ? results.staffContractTypes : []
    );
  };

  // Life Cycle
  useEffect(() => {
    getStaffContractDetailFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && salaryCountTypeOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [salaryCountTypeOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (contractId) {
        getStaffContractDetailsById();
      } else {
        clearForm();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, contractId]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onStaffContractDetailActionDialogCanceled}
        onDialogConfirmed={onStaffContractDetailActionConfirmed}
        // States
        dialogText={staffContractDetailActionDialogText}
        showDialog={showStaffContractDetailActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("員工合約資料")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {staffContractTypeOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) =>
                option[t("staff_contract_type_name_ch")]
              }
              onChange={(event, value) =>
                onInputFieldChange("staffContractTypeField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={staffContractTypeOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("staffContractTypeField")}
                  helperText={getErrorFieldMessage("staffContractTypeField")}
                  label={t("合約類型")}
                  variant="standard"
                />
              )}
              value={staffContractTypeField}
            />
          )}
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {salaryCountTypeOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) =>
                option[t("salary_count_type_name_ch")]
              }
              onChange={(event, value) =>
                onInputFieldChange("salaryCountTypeField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={salaryCountTypeOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("salaryCountTypeField")}
                  helperText={getErrorFieldMessage("salaryCountTypeField")}
                  label={t("工資單位")}
                  variant="standard"
                />
              )}
              value={salaryCountTypeField}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("fixedSalary")}
            fullWidth
            helperText={getErrorFieldMessage("fixedSalary")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            label={t("固定工資")}
            margin="dense"
            name="fixedSalary"
            onChange={(event) =>
              onInputFieldChange("fixedSalary", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={fixedSalary}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6} className={classes.formSwitchContainer}>
          <Typography align={"left"} variant="body1">
            {t("有佣金")}
          </Typography>
          <Switch
            checked={isCommission}
            onChange={() => onInputFieldChange("isCommission", !isCommission)}
          />
        </Grid>
        <Grid item xs={6} className={classes.formSwitchContainer}>
          <Typography align={"left"} variant="body1">
            {t("午飯計入工時")}
          </Typography>
          <Switch
            checked={isLunchIncluded}
            onChange={() =>
              onInputFieldChange("isLunchIncluded", !isLunchIncluded)
            }
          />
        </Grid>
        <Grid item xs={6} className={classes.formDatePickerContainer}>
          <DatePicker
            format="MM/yyyy"
            label={t("生效月份 (月/年)")}
            onChange={(value) => onInputFieldChange("effectiveMonth", value)}
            slotProps={{
              textField: {
                error: checkIsFieldError("effectiveMonth"),
                helperText: getErrorFieldMessage("effectiveMonth"),
                variant: "standard",
              },
            }}
            value={effectiveMonthVar}
            views={["year", "month"]}
          />
        </Grid>
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <Button
            onClick={onSubmitBtnClicked}
            variant="contained"
            sx={formSubmitBtnStyles}
          >
            {contractId ? t("修改") : t("新增")}
          </Button>
        </Grid>
        {isStaffContractCancelable && contractId && (
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button onClick={onDeleteBtnClicked} sx={formSubmitBtnStyles}>
              {t("取消員工合約")}
            </Button>
          </Grid>
        )}
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default StaffContractDetailForm;
