// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function PermissionGroupStaffBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    checkedItemIdsArr,
    hoveredItemId,
    item,
    // Events
    onItemChecked,
    onItemClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isChecked = checkedItemIdsArr.includes(item.id);
  const isHovered = hoveredItemId && hoveredItemId === item.id;

  return (
    <div
      className={
        isHovered ? classes.hoveredItemContainer : classes.itemContainer
      }
      onMouseEnter={() => onMouseEnterItem(item.id)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div
        className={classes.itemContentContainer}
        onClick={() => onItemClicked(item.id)}
      >
        <div className={classes.itemContentSubContainer}>
          <Typography
            align={"left"}
            color={isHovered ? stylesConfig.mainTextColor : null}
            variant="body1"
          >
            {`${item.staff_code} - ${item.full_name_en}`}
          </Typography>
          <Typography
            align={"left"}
            color={stylesConfig.greyTextColor}
            variant="body2"
          >
            {item.dept_name_display ? `${item.dept_name_display} - ` : null}
            {item[t("title_name_ch")]}
          </Typography>
        </div>
      </div>
      <Stack direction="row" spacing={1}>
        <Checkbox checked={isChecked} onChange={() => onItemChecked(item)} />
      </Stack>
    </div>
  );
}

export default PermissionGroupStaffBox;
