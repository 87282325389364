// Configs
import apisConfig from "../configs/apisConfig";

export const createBusinessHourFetch = async (
  token,
  shopId,
  startWeekdayId,
  endWeekdayId,
  startTime,
  endTime,
  includePublicHolidays,
  isClosed
) => {
  try {
    const queryRoute = "/businessHour/createBusinessHour";

    const reqBody = {
      shopId,
      startWeekdayId,
      endWeekdayId,
      startTime,
      endTime,
      includePublicHolidays,
      isClosed,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteBusinessHourFetch = async (token, businessHourId) => {
  try {
    const queryRoute = `/businessHour/deleteBusinessHour/${businessHourId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editBusinessHourFetch = async (
  token,
  businessHourId,
  startWeekdayId,
  endWeekdayId,
  startTime,
  endTime,
  includePublicHolidays,
  isClosed
) => {
  try {
    const queryRoute = `/businessHour/editBusinessHour/${businessHourId}`;

    const reqBody = {
      startWeekdayId,
      endWeekdayId,
      startTime,
      endTime,
      includePublicHolidays,
      isClosed,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllBusinessHoursByShopIdFetch = async (token, shopId) => {
  try {
    const queryRoute = `/businessHour/getAllBusinessHoursByShopId/${shopId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getBusinessHourDetailsByIdFetch = async (
  token,
  businessHourId
) => {
  try {
    const queryRoute = `/businessHour/getBusinessHourDetailsById/${businessHourId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
