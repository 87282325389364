// Actions
import { onMonthChange } from "../../../redux/pages/attendancesPage/attendancesPageActions";

// Components
// Backdrop
import LoadingBackdrop from "../../backdrop/loadingBackdrop";
// Tables
import AttendanceRecordTable from "./attendanceRecordTable";

// Configs
import stylesConfig from "../../../configs/stylesConfig";
import timeoutConfig from "../../../configs/timeoutConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Debounce
import debounce from "lodash.debounce";

// Fetches
import { getAllAttendancesByDepartmentOrShopIdFetch } from "../../../fetches/attendanceFetches";

// Helper Functions
import {
  getMonthEndDate,
  getMonthStartDate,
} from "../../../helperFunctions/getDate";
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";

// Material UI
// Components
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// Icons
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const today = new Date();
const currentMonth = new Date(today.getFullYear(), today.getMonth());

function AttendanceRecordTableContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const attendanceTypeId = useSelector(
    (state) => state.attendancesPage.attendanceTypeId
  );
  const departmentId = useSelector(
    (state) => state.attendancesPage.departmentId
  );
  const divisionId = useSelector((state) => state.attendancesPage.divisionId);
  const divisionType = useSelector(
    (state) => state.attendancesPage.divisionType
  );
  const formSubmitCount = useSelector(
    (state) => state.attendancesPage.formSubmitCount
  );
  const language = useSelector((state) => state.language.language);
  const month = useSelector((state) => state.attendancesPage.month);
  const shopId = useSelector((state) => state.attendancesPage.shopId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [days, setDays] = useState(null);
  const [staffWithAttendances, setStaffWithAttendances] = useState(null);
  // Redner
  const [isLoading, setIsLoading] = useState(false);

  // Handle States
  const monthVar = month ? new Date(month) : null;

  const isMonthVarEqualsCurrentMonth =
    monthVar.getFullYear() === currentMonth.getFullYear() &&
    monthVar.getMonth() === currentMonth.getMonth();

  // Events
  // Events - Fields
  const onMonthBackAndForwardBtnClicked = (actionType) => {
    const newMonthVar =
      actionType === "back"
        ? new Date(monthVar.getFullYear(), monthVar.getMonth() - 1, 1)
        : new Date(monthVar.getFullYear(), monthVar.getMonth() + 1, 1);

    // Update Redux Store
    dispatch(onMonthChange(newMonthVar.toString()));
  };

  const onAttendanceMonthChange = (value) => {
    // Update Redux Store
    dispatch(onMonthChange(value.toString()));
  };

  // Events - Title
  const onReturnToCurrentMonthBtnClicked = () => {
    // Update Redux Store
    dispatch(onMonthChange(currentMonth.toString()));
  };

  // Functions
  // Functions - Normal
  const getAllAttendancesByDepartmentOrShopIdDebounce = debounce(
    (divisionTypeStr, departmentIdVar, shopIdVar, attendanceTypeIdVar) => {
      getAllAttendancesByDepartmentOrShopId(
        divisionTypeStr,
        departmentIdVar,
        shopIdVar,
        attendanceTypeIdVar
      );
    },
    timeoutConfig.debouceTime
  );

  // Functions - Queries
  const getAllAttendancesByDepartmentOrShopId = async (
    divisionTypeStr,
    departmentIdVar,
    shopIdVar,
    attendanceTypeIdVar
  ) => {
    const startDate = getMonthStartDate(monthVar);
    const endDate = getMonthEndDate(monthVar);

    const results = await getAllAttendancesByDepartmentOrShopIdFetch(
      token,
      divisionTypeStr === "Departments" ? departmentIdVar : shopIdVar,
      divisionTypeStr,
      startDate,
      endDate,
      attendanceTypeIdVar
    );

    if (results.days) {
      for (let item of results.days) {
        item.date = moment(item.date).format("D");
      }
    }

    if (results.staffWithAttendances) {
      for (let staffItem of results.staffWithAttendances) {
        for (let attendanceItem of staffItem.attendances) {
          attendanceItem.time_attend = attendanceItem.time_attend
            ? moment(attendanceItem.time_attend).format("H:mm")
            : null;
        }
      }
    }

    // Set States
    setDays(results.days ? results.days : null);
    setStaffWithAttendances(
      results.staffWithAttendances ? results.staffWithAttendances : null
    );

    setIsLoading(false);
  };

  // Life Cycle
  useEffect(() => {
    if (
      month &&
      ((divisionType === "Departments" && departmentId) ||
        (divisionType === "Shops" && shopId))
    ) {
      // Set States
      setIsLoading(true);

      getAllAttendancesByDepartmentOrShopIdDebounce(
        divisionType,
        departmentId,
        shopId,
        attendanceTypeId
      );
    }
  }, [
    departmentId,
    divisionId,
    formSubmitCount,
    shopId,
    month,
    attendanceTypeId,
  ]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBoxWithMaxWidth}>
      {/* Backdrop */}
      <LoadingBackdrop
        // States
        showBackdrop={isLoading}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("出勤紀錄s")}
          </Typography>
        </div>
        {/* Date Picker */}
        <div className={classes.titleTextContainer}>
          {!isMonthVarEqualsCurrentMonth && (
            <div className={classes.formTwinIconBtnContainerLeft}>
              <Button
                onClick={onReturnToCurrentMonthBtnClicked}
                startIcon={<RestoreRoundedIcon />}
                variant="text"
              >
                {t("返回本月")}
              </Button>
            </div>
          )}
          <div className={classes.formDatePickerContainer}>
            <DatePicker
              format="MM/yyyy"
              label={t("月份 (月/年)")}
              onChange={onAttendanceMonthChange}
              slotProps={{ textField: { variant: "standard" } }}
              value={monthVar}
              views={["year", "month"]}
            />
          </div>
          <div className={classes.formTwinIconBtnContainerRight}>
            <IconButton onClick={() => onMonthBackAndForwardBtnClicked("back")}>
              <ArrowBackIosRoundedIcon />
            </IconButton>
            <IconButton
              onClick={() => onMonthBackAndForwardBtnClicked("forward")}
            >
              <ArrowForwardIosRoundedIcon />
            </IconButton>
          </div>
        </div>
      </div>
      {days && staffWithAttendances ? (
        // Table
        <AttendanceRecordTable
          // States
          days={days}
          staffWithAttendances={staffWithAttendances}
        />
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有出勤紀錄資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default AttendanceRecordTableContainer;
