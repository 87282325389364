// Actions
import {
  onFormSubmitCountIncrease,
  onWorkingPeriodChange,
} from "../../../redux/pages/workingPeriodsAndPlacesPage/workingPeriodsAndPlacesPageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  createWorkingPeriodFetch,
  editWorkingPeriodFetch,
  getWorkingPeriodDetailsByIdFetch,
} from "../../../fetches/workingPeriodFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function WorkingPeriodDetailForm() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const workingPeriodId = useSelector(
    (state) => state.workingPeriodsAndPlacesPage.workingPeriodId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [
    workingPeriodDetailActionDialogText,
    setWorkingPeriodDetailActionDialogText,
  ] = useState("");
  const [
    workingPeriodDetailActionDialogType,
    setWorkingPeriodDetailActionDialogType,
  ] = useState(null);
  const [
    showWorkingPeriodDetailActionDialog,
    setShowWorkingPeriodDetailActionDialog,
  ] = useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Switches
  const [isItemActive, setIsItemActive] = useState(true);
  const [isItemDefault, setIsItemDefault] = useState(false);
  // Text Fields
  const [code, setCode] = useState("");

  // Events
  // Events - Dialogs
  const onWorkingPeriodDetailActionDialogCanceled = () => {
    // Set States
    setShowWorkingPeriodDetailActionDialog(false);
  };

  const onWorkingPeriodDetailActionDialogConfirmed = () => {
    switch (workingPeriodDetailActionDialogType) {
      case "CreateWorkingPeriod":
        createWorkingPeriod();
        break;
      case "EditWorkingPeriod":
        editWorkingPeriod();
        break;
      default:
        break;
    }

    // Set States
    setShowWorkingPeriodDetailActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "code":
        setCode(value);
        break;
      case "isItemActive":
        setIsItemActive(value);
        break;
      case "isItemDefault":
        setIsItemDefault(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!code) {
      addToErrorFields("code", t("請先填寫 工作時段代號"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayWorkingPeriodDetailActionDialog(
      workingPeriodId ? "EditWorkingPeriod" : "CreateWorkingPeriod"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const clearForm = () => {
    // Set States
    setCode("");
    setIsItemDefault(false);
    setIsItemActive(true);

    clearErrorFields();
  };

  const displayWorkingPeriodDetailActionDialog = (
    workingPeriodDetailActionType
  ) => {
    // Set States
    setWorkingPeriodDetailActionDialogType(workingPeriodDetailActionType);

    switch (workingPeriodDetailActionType) {
      case "CreateWorkingPeriod":
        setWorkingPeriodDetailActionDialogText(t("確認要新增 工作時段 嗎？"));
        break;
      case "EditWorkingPeriod":
        setWorkingPeriodDetailActionDialogText(
          t("確認要編輯 工作時段資料 嗎？")
        );
        break;
      default:
        break;
    }

    // Set States
    setShowWorkingPeriodDetailActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createWorkingPeriod = async () => {
    const results = await createWorkingPeriodFetch(
      token,
      code,
      isItemDefault,
      isItemActive
    );

    if (results.success) {
      showFormAlert("success", t("成功提交"));

      // Update Redux Store
      dispatch(onWorkingPeriodChange(results.workingPeriodId));
      dispatch(onFormSubmitCountIncrease());
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 工作時段 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editWorkingPeriod = async () => {
    if (workingPeriodId) {
      const results = await editWorkingPeriodFetch(
        token,
        workingPeriodId,
        code,
        isItemDefault,
        isItemActive
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else if (results.isDuplicate) {
        showFormAlert("warning", t("此 工作時段 已存在"));
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getWorkingPeriodDetailsById = async () => {
    const results = await getWorkingPeriodDetailsByIdFetch(
      token,
      workingPeriodId
    );

    if (results.workingPeriodDetails) {
      const { working_period_code, is_default, is_active } =
        results.workingPeriodDetails;

      // Set States
      setCode(working_period_code ? working_period_code : "");
      setIsItemDefault(is_default);
      setIsItemActive(is_active);
    } else {
      // Set States
      setCode("");
      setIsItemDefault(false);
      setIsItemActive(true);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    if (workingPeriodId) {
      getWorkingPeriodDetailsById();
    } else {
      clearForm();
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [workingPeriodId]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onWorkingPeriodDetailActionDialogCanceled}
        onDialogConfirmed={onWorkingPeriodDetailActionDialogConfirmed}
        // States
        dialogText={workingPeriodDetailActionDialogText}
        showDialog={showWorkingPeriodDetailActionDialog}
      />
      {/* WorkingPeriod */}
      <div className={classes.workingPeriodContainer}>
        <div className={classes.workingPeriodTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("工作時段資料")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={12}>
          <TextField
            error={checkIsFieldError("code")}
            fullWidth
            helperText={getErrorFieldMessage("code")}
            label={t("工作時段代號")}
            margin="dense"
            name="code"
            onChange={(event) => onInputFieldChange("code", event.target.value)}
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={code}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6} className={classes.formSwitchContainer}>
          <Typography align={"left"} variant="body1">
            {t("有效")}
          </Typography>
          <Switch
            checked={isItemActive}
            onChange={() => onInputFieldChange("isItemActive", !isItemActive)}
          />
        </Grid>
        <Grid item xs={6} className={classes.formSwitchContainer}>
          <Typography align={"left"} variant="body1">
            {t("預設時段")}
          </Typography>
          <Switch
            checked={isItemDefault}
            onChange={() => onInputFieldChange("isItemDefault", !isItemDefault)}
          />
        </Grid>
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <Button
            onClick={onSubmitBtnClicked}
            variant="contained"
            sx={formSubmitBtnStyles}
          >
            {workingPeriodId ? t("修改") : t("新增")}
          </Button>
        </Grid>
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default WorkingPeriodDetailForm;
