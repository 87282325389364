// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/candidateProfilePage/candidateProfilePageActions";

// Components
// Boxes
import CandidateEducationBox from "./candidateEducationBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import CandidateEducationActionMenu from "../../menus/candidateProfilePage/candidateEducationActionMenu";
// Modals
import CandidateEducationActionModal from "../../modals/candidateProfilePage/candidateEducationActionModal";
import FileViewModal from "../../modals/candidateProfilePage/fileViewModal";
import ModalContainer from "../../modals/modalContainer";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteEducationFetch,
  getAllEducationsByCandidateIdFetch,
} from "../../../fetches/educationFetches";

// Helper Functions
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";
import { monthsToYearsAndMonths } from "../../../helperFunctions/monthsToYearsAndMonths";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function CandidateEducationBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const candidateId = useSelector(
    (state) => state.candidateProfilePage.candidateId
  );
  const formSubmitCount = useSelector(
    (state) => state.candidateProfilePage.formSubmitCount
  );
  const language = useSelector((state) => state.language.language);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [candidateEducations, setCandidateEducations] = useState(null);
  // Dialog
  const [
    candidateEducationActionDialogText,
    setCandidateEducationActionDialogText,
  ] = useState("");
  const [
    candidateEducationActionDialogType,
    setCandidateEducationActionDialogType,
  ] = useState(null);
  const [
    showCandidateEducationActionDialog,
    setShowCandidateEducationActionDialog,
  ] = useState(false);
  // Hover
  const [currentItemId, setCurrentItemId] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [
    showCandidateEducationActionMenu,
    setShowCandidateEducationActionMenu,
  ] = useState(null);
  // Modals
  const [
    showCandidateEducationActionModal,
    setShowCandidateEducationActionModal,
  ] = useState(false);
  const [showFileViewModal, setShowFileViewModal] = useState(false);

  // Handle States
  const itemsArr = candidateEducations;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Set States
    setCurrentItemId(itemId);
    displayCandidateEducationActionModal();
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setShowCandidateEducationActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setShowCandidateEducationActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onCandidateEducationActionDialogCanceled = () => {
    // Set States
    setShowCandidateEducationActionDialog(false);
  };

  const onCandidateEducationActionDialogConfirmed = () => {
    switch (candidateEducationActionDialogType) {
      case "DeleteCandidateEducation":
        deleteEducation();
        break;
      default:
        break;
    }

    // Set States
    setShowCandidateEducationActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onCandidateEducationActionModalClosed = () => {
    // Set States
    setShowCandidateEducationActionModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  const onFileViewModalClosed = () => {
    // Set States
    setShowFileViewModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Set States
    setCurrentItemId(null);
    displayCandidateEducationActionModal();
  };

  // Functions
  // Functions - Normal
  const displayCandidateEducationActionDialog = (
    candidateEducationActionType
  ) => {
    // Set States
    setCandidateEducationActionDialogType(candidateEducationActionType);

    switch (candidateEducationActionType) {
      case "DeleteCandidateEducation":
        setCandidateEducationActionDialogText(t("確認要刪除 教育 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowCandidateEducationActionDialog(true);
  };

  const displayCandidateEducationActionModal = () => {
    setShowCandidateEducationActionModal(true);
  };

  const displayFileViewModal = () => {
    setShowFileViewModal(true);
  };

  // Functions - Mutations
  const deleteEducation = async () => {
    const results = await deleteEducationFetch(token, currentItemId);

    if (results.success) {
      getAllEducationsByCandidateId();
    }
  };

  // Functions - Queries
  const getAllEducationsByCandidateId = async () => {
    const results = await getAllEducationsByCandidateIdFetch(
      token,
      candidateId
    );

    if (results.educations) {
      for (let item of results.educations) {
        item.start_month = item.start_month
          ? moment(item.start_month).format(t("YYYY年 MM月"))
          : null;

        item.end_month = item.end_month
          ? moment(item.end_month).format(t("YYYY年 MM月"))
          : null;

        item.duration = item.duration
          ? monthsToYearsAndMonths(language, item.duration)
          : "";
      }
    }

    // Set States
    setCandidateEducations(results.educations ? results.educations : null);
  };

  // Life Cycle
  useEffect(() => {
    if (candidateId) {
      getAllEducationsByCandidateId();
    }
  }, [formSubmitCount, candidateId, language]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onCandidateEducationActionDialogCanceled}
        onDialogConfirmed={onCandidateEducationActionDialogConfirmed}
        // States
        dialogText={candidateEducationActionDialogText}
        showDialog={showCandidateEducationActionDialog}
      />
      {/* Menu */}
      <CandidateEducationActionMenu
        // States
        showCandidateEducationActionMenu={showCandidateEducationActionMenu}
        // Set States
        setShowCandidateEducationActionMenu={
          setShowCandidateEducationActionMenu
        }
        // Functions
        displayCandidateEducationActionDialog={
          displayCandidateEducationActionDialog
        }
        displayCandidateEducationActionModal={
          displayCandidateEducationActionModal
        }
      />
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onCandidateEducationActionModalClosed}
        // States
        showModal={showCandidateEducationActionModal}
      >
        <CandidateEducationActionModal
          // Events
          onModalClosed={onCandidateEducationActionModalClosed}
          // Functions
          displayFileViewModal={displayFileViewModal}
          // States
          educationId={currentItemId}
        />
      </ModalContainer>
      <ModalContainer
        // Events
        onModalClosed={onFileViewModalClosed}
        // States
        showModal={showFileViewModal}
      >
        <FileViewModal
          // States
          fieldId={currentItemId}
          fieldType="Education"
          modalTitle={t("教育")}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("教育s")}
          </Typography>
          <Tooltip placement="right" title={t("新增教育")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <CandidateEducationBox
            key={item.id}
            // States
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有教育資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default CandidateEducationBoxesContainer;
