// Configs
import apisConfig from "../configs/apisConfig";

export const createWorkingHourFetch = async (
  token,
  workingPeriodId,
  startWeekdayId,
  endWeekdayId,
  startTime,
  lunchTime,
  afterLunchTime,
  endTime,
  isAlternateWeek,
  isLongWeek
) => {
  try {
    const queryRoute = "/workingHour/createWorkingHour";

    const reqBody = {
      workingPeriodId,
      startWeekdayId,
      endWeekdayId,
      startTime,
      lunchTime,
      afterLunchTime,
      endTime,
      isAlternateWeek,
      isLongWeek,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteWorkingHourFetch = async (token, workingHourId) => {
  try {
    const queryRoute = `/workingHour/deleteWorkingHour/${workingHourId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editWorkingHourFetch = async (
  token,
  workingHourId,
  startWeekdayId,
  endWeekdayId,
  startTime,
  lunchTime,
  afterLunchTime,
  endTime,
  isAlternateWeek,
  isLongWeek
) => {
  try {
    const queryRoute = `/workingHour/editWorkingHour/${workingHourId}`;

    const reqBody = {
      startWeekdayId,
      endWeekdayId,
      startTime,
      lunchTime,
      afterLunchTime,
      endTime,
      isAlternateWeek,
      isLongWeek,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllWorkingHoursByWorkingPeriodIdFetch = async (
  token,
  workingPeriodId
) => {
  try {
    const queryRoute = `/workingHour/getAllWorkingHoursByWorkingPeriodId/${workingPeriodId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getWorkingHourDetailsByIdFetch = async (token, workingHourId) => {
  try {
    const queryRoute = `/workingHour/getWorkingHourDetailsById/${workingHourId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
