// Actions
import {
  onDayOffTypeChange,
  onFormSubmitCountIncrease,
} from "../../../../redux/pages/dayOffTypesPage/dayOffTypesPageActions";

// Components
// Dialogs
import AlertDialog from "../../../dialogs/alertDialog";
// Forms
import DayOffTypeDetailFormLowerContent from "./dayOffTypeDetailFormLowerContent";
import DayOffTypeDetailFormUpperContent from "./dayOffTypeDetailFormUpperContent";
// Spacing Boxes
import SpacingBox from "../../../boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../../../../configs/stylesConfig";

// Consts
import {
  dayOptions,
  monthOptions,
  quantityOptions,
} from "../../../../consts/dayOffTypeDetailFormContainerConsts";

// Custom Hooks
import { useLanguage } from "../../../../customHooks/getLanguage";

// Fetches
import {
  createDayOffTypeFetch,
  editDayOffTypeFetch,
  getDayOffTypeDetailsByIdFetch,
} from "../../../../fetches/dayOffTypeFetches";
import { getDayOffTypeDetailFormOptionsFetch } from "../../../../fetches/formOptionFetches";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function DayOffTypeDetailFormContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const dayOffTypeId = useSelector(
    (state) => state.dayOffTypesPage.dayOffTypeId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [
    dayOffTypeDetailActionDialogText,
    setDayOffTypeDetailActionDialogText,
  ] = useState("");
  const [
    dayOffTypeDetailActionDialogType,
    setDayOffTypeDetailActionDialogType,
  ] = useState(null);
  const [
    showDayOffTypeDetailActionDialog,
    setShowDayOffTypeDetailActionDialog,
  ] = useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [dayOffTypeCountTypeField, setDayOffTypeCountTypeField] =
    useState(null);
  const [dayOffTypePaidTypeField, setDayOffTypePaidTypeField] = useState(null);
  const [dayOffTypePayoutTypeField, setDayOffTypePayoutTypeField] =
    useState(null);
  const [dayOffTypeRequestTypeField, setDayOffTypeRequestTypeField] =
    useState(null);
  const [dayOffTypeRuleTypeField, setDayOffTypeRuleTypeField] = useState(null);
  const [dayOffTypeSettleTypeField, setDayOffTypeSettleTypeField] =
    useState(null);
  const [daySettleField, setDaySettleField] = useState(null);
  const [divisionField, setDivisionField] = useState([]);
  const [maxAccumulativeNumField, setMaxAccumulativeNumField] = useState(null);
  const [monthSettleField, setMonthSettleField] = useState(null);
  // Options
  const [dayOffTypeCountTypeOptions, setDayOffTypeCountTypeOptions] = useState(
    []
  );
  const [dayOffTypePaidTypeOptions, setDayOffTypePaidTypeOptions] = useState(
    []
  );
  const [dayOffTypePayoutTypeOptions, setDayOffTypePayoutTypeOptions] =
    useState([]);
  const [dayOffTypeRequestTypeOptions, setDayOffTypeRequestTypeOptions] =
    useState([]);
  const [dayOffTypeRuleTypeOptions, setDayOffTypeRuleTypeOptions] = useState(
    []
  );
  const [dayOffTypeSettleTypeOptions, setDayOffTypeSettleTypeOptions] =
    useState([]);
  const [divisionOptions, setDivisionOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Switches
  const [isAccumulative, setIsAccumulative] = useState(true);
  const [isApplicable, setIsApplicable] = useState(true);
  const [isAvailableInProbation, setIsAvailableInProbation] = useState(true);
  const [isItemActive, setIsItemActive] = useState(true);
  // Text Fields
  const [nameCh, setNameCh] = useState("");
  const [nameEnShort, setNameEnShort] = useState("");
  const [nameEnFull, setNameEnFull] = useState("");

  // Events
  // Events - Dialogs
  const onDayOffTypeDetailActionDialogCanceled = () => {
    // Set States
    setShowDayOffTypeDetailActionDialog(false);
  };

  const onDayOffTypeDetailActionDialogConfirmed = () => {
    switch (dayOffTypeDetailActionDialogType) {
      case "CreateDayOffType":
        createDayOffType();
        break;
      case "EditDayOffType":
        editDayOffType();
        break;
      default:
        break;
    }

    // Set States
    setShowDayOffTypeDetailActionDialog(false);
  };

  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "dayOffTypeCountTypeField":
        setDayOffTypeCountTypeField(value);

        // !每年
        if (value.day_off_type_count_type_name_en === "Annual") {
          setMonthSettleField(null);

          removeErrorField("monthSettleField");
        }

        break;
      case "dayOffTypePaidTypeField":
        setDayOffTypePaidTypeField(value);
        break;
      case "dayOffTypePayoutTypeField":
        setDayOffTypePayoutTypeField(value);
        break;
      case "dayOffTypeRequestTypeField":
        setDayOffTypeRequestTypeField(value);
        break;
      case "dayOffTypeRuleTypeField":
        setDayOffTypeRuleTypeField(value);

        // 無限制
        if (value.day_off_type_rule_type_name_en === "No Limit") {
          setDayOffTypeCountTypeField(null);
          setDayOffTypePayoutTypeField(null);
          setDayOffTypeSettleTypeField(null);
          setDaySettleField(null);
          setIsAccumulative(false);
          setMaxAccumulativeNumField(null);
          setMonthSettleField(null);

          removeErrorField("dayOffTypeCountTypeField");
          removeErrorField("dayOffTypePayoutTypeField");
          removeErrorField("dayOffTypeSettleTypeField");
          removeErrorField("daySettleField");
          removeErrorField("maxAccumulativeNumField");
          removeErrorField("monthSettleField");
        }

        break;
      case "dayOffTypeSettleTypeField":
        setDayOffTypeSettleTypeField(value);

        // !固定日期
        if (value.day_off_type_settle_type_name_en !== "Specific Date") {
          setDaySettleField(null);
          setMonthSettleField(null);

          removeErrorField("daySettleField");
          removeErrorField("monthSettleField");
        }

        break;
      case "daySettleField":
        setDaySettleField(value);
        break;
      case "divisionField":
        setDivisionField(value);
        break;
      case "isAccumulative":
        setIsAccumulative(value);

        // 可累積 / 不可累積
        if (value === true) {
          setDayOffTypeSettleTypeField(null);
          setDaySettleField(null);
          setMonthSettleField(null);

          removeErrorField("dayOffTypeSettleTypeField");
          removeErrorField("daySettleField");
          removeErrorField("monthSettleField");
        } else if (value === false) {
          setMaxAccumulativeNumField(null);

          removeErrorField("maxAccumulativeNumField");
        }

        break;
      case "isApplicable":
        setIsApplicable(value);

        // 員工不可自行申請
        if (value === false) {
          setIsAvailableInProbation(false);
        }

        break;
      case "isAvailableInProbation":
        setIsAvailableInProbation(value);
        break;
      case "isItemActive":
        setIsItemActive(value);
        break;
      case "maxAccumulativeNumField":
        setMaxAccumulativeNumField(value);
        break;
      case "monthSettleField":
        setMonthSettleField(value);

        if ([4, 6, 9, 11].includes(value.value)) {
          if (daySettleField && daySettleField.value >= 31) {
            setDaySettleField(null);

            removeErrorField("daySettleField");
          }
        } else if (value.value === 2) {
          if (daySettleField && daySettleField.value >= 29) {
            setDaySettleField(null);

            removeErrorField("daySettleField");
          }
        }

        break;
      case "nameCh":
        setNameCh(value);
        break;
      case "nameEnShort":
        setNameEnShort(value);
        break;
      case "nameEnFull":
        setNameEnFull(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!dayOffTypeRuleTypeField) {
      addToErrorFields("dayOffTypeRuleTypeField", t("請先填寫 計算方式"));
      isError = true;
    }

    if (dayOffTypeRuleTypeField.day_off_type_rule_type_name_en !== "No Limit") {
      if (!dayOffTypeCountTypeField) {
        addToErrorFields("dayOffTypeCountTypeField", t("請先填寫 計算單位"));
        isError = true;
      }

      if (!dayOffTypePaidTypeField) {
        addToErrorFields("dayOffTypePaidTypeField", t("請先填寫 薪金"));
        isError = true;
      }

      if (!dayOffTypePayoutTypeField) {
        addToErrorFields("dayOffTypePayoutTypeField", t("請先填寫 發放方式"));
        isError = true;
      }

      if (isAccumulative) {
        if (!maxAccumulativeNumField) {
          addToErrorFields(
            "maxAccumulativeNumField",
            t("請先填寫 最多累積數量")
          );
          isError = true;
        }
      }
    }

    if (!dayOffTypeRequestTypeField) {
      addToErrorFields("dayOffTypeRequestTypeField", t("請先填寫 申請方式"));
      isError = true;
    }

    if (!divisionField[0]) {
      addToErrorFields("divisionField", t("請先填寫 套用到"));
      isError = true;
    }

    if (!nameCh) {
      addToErrorFields("nameCh", t("請先填寫 中文名稱"));
      isError = true;
    }

    if (!nameEnFull) {
      addToErrorFields("nameEnFull", t("請先填寫 英文全名"));
      isError = true;
    }

    if (!nameEnShort) {
      addToErrorFields("nameEnShort", t("請先填寫 英文簡稱"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayDayOffTypeDetailActionDialog(
      dayOffTypeId ? "EditDayOffType" : "CreateDayOffType"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const clearForm = () => {
    // Set States
    setDayOffTypeRuleTypeField(null);
    setDayOffTypeRequestTypeField(null);
    setDayOffTypeCountTypeField(null);
    setDayOffTypePayoutTypeField(null);
    setDayOffTypePaidTypeField(null);
    setDivisionField([]);
    setNameCh("");
    setNameEnFull("");
    setNameEnShort("");
    setIsApplicable(true);
    setIsAvailableInProbation(true);
    setIsAccumulative(true);
    setMaxAccumulativeNumField(null);
    setIsItemActive(true);

    clearErrorFields();
  };

  const displayDayOffTypeDetailActionDialog = (dayOffTypeDetailActionType) => {
    // Set States
    setDayOffTypeDetailActionDialogType(dayOffTypeDetailActionType);

    switch (dayOffTypeDetailActionType) {
      case "CreateDayOffType":
        setDayOffTypeDetailActionDialogText(t("確認要新增 假期種類 嗎？"));
        break;
      case "EditDayOffType":
        setDayOffTypeDetailActionDialogText(t("確認要編輯 假期種類資料 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowDayOffTypeDetailActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createDayOffType = async () => {
    const results = await createDayOffTypeFetch(
      token,
      dayOffTypeRuleTypeField ? dayOffTypeRuleTypeField.id : null,
      dayOffTypeRequestTypeField ? dayOffTypeRequestTypeField.id : null,
      dayOffTypeCountTypeField ? dayOffTypeCountTypeField.id : null,
      dayOffTypePayoutTypeField ? dayOffTypePayoutTypeField.id : null,
      dayOffTypePaidTypeField ? dayOffTypePaidTypeField.id : null,
      nameCh,
      nameEnFull,
      nameEnShort,
      isApplicable,
      isAvailableInProbation,
      isAccumulative,
      maxAccumulativeNumField ? maxAccumulativeNumField.value : null,
      dayOffTypeSettleTypeField ? dayOffTypeSettleTypeField.id : null,
      monthSettleField ? monthSettleField.value : null,
      daySettleField ? daySettleField.value : null,
      isItemActive,
      divisionField[0] ? divisionField.map((item) => item.id) : null
    );

    if (results.success) {
      showFormAlert("success", t("成功提交"));

      // Update Redux Store
      dispatch(onDayOffTypeChange(results.dayOffTypeId));
      dispatch(onFormSubmitCountIncrease());
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 假期種類 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editDayOffType = async () => {
    if (dayOffTypeId) {
      const results = await editDayOffTypeFetch(
        token,
        dayOffTypeId,
        dayOffTypeRuleTypeField ? dayOffTypeRuleTypeField.id : null,
        dayOffTypeRequestTypeField ? dayOffTypeRequestTypeField.id : null,
        dayOffTypeCountTypeField ? dayOffTypeCountTypeField.id : null,
        dayOffTypePayoutTypeField ? dayOffTypePayoutTypeField.id : null,
        dayOffTypePaidTypeField ? dayOffTypePaidTypeField.id : null,
        nameCh,
        nameEnFull,
        nameEnShort,
        isApplicable,
        isAvailableInProbation,
        isAccumulative,
        maxAccumulativeNumField ? maxAccumulativeNumField.value : null,
        dayOffTypeSettleTypeField ? dayOffTypeSettleTypeField.id : null,
        monthSettleField ? monthSettleField.value : null,
        daySettleField ? daySettleField.value : null,
        isItemActive,
        divisionField[0] ? divisionField.map((item) => item.id) : null
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else if (results.isDuplicate) {
        showFormAlert("warning", t("此 假期種類 已存在"));
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getDayOffTypeDetailsById = async () => {
    const results = await getDayOffTypeDetailsByIdFetch(token, dayOffTypeId);

    if (results.dayOffTypeDetails) {
      const {
        day_off_type_rule_type_id,
        day_off_type_request_type_id,
        day_off_type_count_type_id,
        day_off_type_payout_type_id,
        day_off_type_paid_type_id,
        day_off_type_name_ch,
        day_off_type_name_en_full,
        day_off_type_name_en_short,
        is_applicable,
        is_available_in_probation,
        is_accumulative,
        max_accumulative_num,
        day_off_type_settle_type_id,
        month_settle,
        day_settle,
        is_active,
        divisions,
      } = results.dayOffTypeDetails;

      // Set States
      setDayOffTypeRuleTypeField(
        day_off_type_rule_type_id
          ? dayOffTypeRuleTypeOptions.find(
              (item) => item.id === day_off_type_rule_type_id
            )
          : null
      );
      setDayOffTypeRequestTypeField(
        day_off_type_request_type_id
          ? dayOffTypeRequestTypeOptions.find(
              (item) => item.id === day_off_type_request_type_id
            )
          : null
      );
      setDayOffTypeCountTypeField(
        day_off_type_count_type_id
          ? dayOffTypeCountTypeOptions.find(
              (item) => item.id === day_off_type_count_type_id
            )
          : null
      );
      setDayOffTypePayoutTypeField(
        day_off_type_payout_type_id
          ? dayOffTypePayoutTypeOptions.find(
              (item) => item.id === day_off_type_payout_type_id
            )
          : null
      );
      setDayOffTypePaidTypeField(
        day_off_type_paid_type_id
          ? dayOffTypePaidTypeOptions.find(
              (item) => item.id === day_off_type_paid_type_id
            )
          : null
      );
      setNameCh(day_off_type_name_ch ? day_off_type_name_ch : "");
      setNameEnFull(
        day_off_type_name_en_full ? day_off_type_name_en_full : null
      );
      setNameEnShort(
        day_off_type_name_en_short ? day_off_type_name_en_short : null
      );
      setIsApplicable(is_applicable);
      setIsAvailableInProbation(is_available_in_probation);
      setIsAccumulative(is_accumulative);
      setMaxAccumulativeNumField(
        max_accumulative_num
          ? quantityOptions.find((item) => item.value === max_accumulative_num)
          : null
      );
      setDayOffTypeSettleTypeField(
        day_off_type_settle_type_id
          ? dayOffTypeSettleTypeOptions.find(
              (item) => item.id === day_off_type_settle_type_id
            )
          : null
      );
      setMonthSettleField(
        month_settle
          ? monthOptions.find((item) => item.id === monthOptions.value)
          : null
      );
      setDaySettleField(
        day_settle
          ? dayOptions.find((item) => item.id === dayOptions.value)
          : null
      );

      if (divisions) {
        let divisionsArr = [];

        for (let divisionItem of divisions) {
          if (divisionOptions.find((item) => item.id === divisionItem.id)) {
            divisionsArr.push(
              divisionOptions.find((item) => item.id === divisionItem.id)
            );
          }
        }

        setDivisionField(divisionsArr);
      } else {
        setDivisionField([]);
      }

      setIsItemActive(is_active);
    } else {
      // Set States
      setDayOffTypeRuleTypeField(null);
      setDayOffTypeRequestTypeField(null);
      setDayOffTypeCountTypeField(null);
      setDayOffTypePayoutTypeField(null);
      setDayOffTypePaidTypeField(null);
      setNameCh("");
      setNameEnFull("");
      setNameEnShort("");
      setIsApplicable(true);
      setIsAvailableInProbation(true);
      setIsAccumulative(true);
      setMaxAccumulativeNumField(null);
      setDayOffTypeSettleTypeField(null);
      setMonthSettleField(null);
      setDaySettleField(null);
      setDivisionField([]);
      setIsItemActive(true);
    }

    clearErrorFields();
  };

  const getDayOffTypeDetailFormOptions = async () => {
    const results = await getDayOffTypeDetailFormOptionsFetch(token);

    // Set States
    setDayOffTypeCountTypeOptions(
      results.dayOffTypeCountTypes ? results.dayOffTypeCountTypes : []
    );
    setDayOffTypePaidTypeOptions(
      results.dayOffTypePaidTypes ? results.dayOffTypePaidTypes : []
    );
    setDayOffTypePayoutTypeOptions(
      results.dayOffTypePayoutTypes ? results.dayOffTypePayoutTypes : []
    );
    setDayOffTypeRuleTypeOptions(
      results.dayOffTypeRuleTypes ? results.dayOffTypeRuleTypes : []
    );
    setDayOffTypeRequestTypeOptions(
      results.dayOffTypeRequestTypes ? results.dayOffTypeRequestTypes : []
    );
    setDayOffTypeSettleTypeOptions(
      results.dayOffTypeSettleTypes ? results.dayOffTypeSettleTypes : []
    );
    setDivisionOptions(results.divisions ? results.divisions : []);
  };

  // Life Cycle
  useEffect(() => {
    getDayOffTypeDetailFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && dayOffTypeRuleTypeOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [dayOffTypeRuleTypeOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (dayOffTypeId) {
        getDayOffTypeDetailsById();
      } else {
        clearForm();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, dayOffTypeId]);

  return (
    <>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onDayOffTypeDetailActionDialogCanceled}
        onDialogConfirmed={onDayOffTypeDetailActionDialogConfirmed}
        // States
        dialogText={dayOffTypeDetailActionDialogText}
        showDialog={showDayOffTypeDetailActionDialog}
      />
      <DayOffTypeDetailFormUpperContent
        // States
        dayOffTypeCountTypeField={dayOffTypeCountTypeField}
        dayOffTypeCountTypeOptions={dayOffTypeCountTypeOptions}
        dayOffTypePaidTypeField={dayOffTypePaidTypeField}
        dayOffTypePaidTypeOptions={dayOffTypePaidTypeOptions}
        dayOffTypePayoutTypeField={dayOffTypePayoutTypeField}
        dayOffTypePayoutTypeOptions={dayOffTypePayoutTypeOptions}
        dayOffTypeRuleTypeField={dayOffTypeRuleTypeField}
        dayOffTypeRuleTypeOptions={dayOffTypeRuleTypeOptions}
        divisionField={divisionField}
        divisionOptions={divisionOptions}
        isItemActive={isItemActive}
        nameCh={nameCh}
        nameEnFull={nameEnFull}
        nameEnShort={nameEnShort}
        // Events
        onInputFieldChange={onInputFieldChange}
        onInputFieldKeyPressed={onInputFieldKeyPressed}
        // Functions
        checkIsFieldError={checkIsFieldError}
        getErrorFieldMessage={getErrorFieldMessage}
      />
      <SpacingBox
        // Render
        height={stylesConfig.spacingBoxMarginBottom}
      />
      <DayOffTypeDetailFormLowerContent
        // States
        daySettleField={daySettleField}
        dayOptions={dayOptions}
        dayOffTypeCountTypeField={dayOffTypeCountTypeField}
        dayOffTypeRequestTypeField={dayOffTypeRequestTypeField}
        dayOffTypeRequestTypeOptions={dayOffTypeRequestTypeOptions}
        dayOffTypeRuleTypeField={dayOffTypeRuleTypeField}
        dayOffTypeSettleTypeField={dayOffTypeSettleTypeField}
        dayOffTypeSettleTypeOptions={dayOffTypeSettleTypeOptions}
        formAlertText={formAlertText}
        formAlertType={formAlertType}
        isAccumulative={isAccumulative}
        isApplicable={isApplicable}
        isAvailableInProbation={isAvailableInProbation}
        maxAccumulativeNumField={maxAccumulativeNumField}
        monthSettleField={monthSettleField}
        monthOptions={monthOptions}
        quantityOptions={quantityOptions}
        shouldShowFormAlert={shouldShowFormAlert}
        // Events
        onInputFieldChange={onInputFieldChange}
        onInputFieldKeyPressed={onInputFieldKeyPressed}
        onSubmitBtnClicked={onSubmitBtnClicked}
        // Functions
        checkIsFieldError={checkIsFieldError}
        getErrorFieldMessage={getErrorFieldMessage}
      />
    </>
  );
}

export default DayOffTypeDetailFormContainer;
