export function onBrandChange(brandId) {
  return {
    type: "ON_ROSTERS_PAGE_BRAND_CHANGE",
    brandId,
  };
}

export function onBrandItemChange(brandItem) {
  return {
    type: "ON_ROSTERS_PAGE_BRAND_ITEM_CHANGE",
    brandItem,
  };
}

export function onDepartmentChange(departmentId) {
  return {
    type: "ON_ROSTERS_PAGE_DEPARTMENT_CHANGE",
    departmentId,
  };
}

export function onDivisionChange(divisionId, divisionType) {
  return {
    type: "ON_ROSTERS_PAGE_DIVISION_CHANGE",
    divisionId,
    divisionType,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_ROSTERS_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onMonthChange(month) {
  return {
    type: "ON_ROSTERS_PAGE_MONTH_CHANGE",
    month,
  };
}

export function onShopChange(shopId) {
  return {
    type: "ON_ROSTERS_PAGE_SHOP_CHANGE",
    shopId,
  };
}

export function onShopItemChange(shopItem) {
  return {
    type: "ON_ROSTERS_PAGE_SHOP_ITEM_CHANGE",
    shopItem,
  };
}

export function onTypeOfContentChange(typeOfContent) {
  return {
    type: "ON_ROSTERS_PAGE_TYPE_OF_CONTENT_CHANGE",
    typeOfContent,
  };
}

export function onTypeOfWorkingPeriodsAndShiftsChange(
  typeOfWorkingPeriodsAndShifts
) {
  return {
    type: "ON_ROSTERS_PAGE_TYPE_OF_WORKING_PERIODS_AND_SHIFTS_CHANGE",
    typeOfWorkingPeriodsAndShifts,
  };
}
