// Actions
import {
  onBrandChange as onAttendancesPageBrandChange,
  onDepartmentChange as onAttendancesPageDepartmentChange,
  onDivisionChange as onAttendancesPageDivisionChange,
  onShopChange as onAttendancesPageShopChange,
  onTabItemChange as onAttendancesPageTabItemChange,
} from "../../../redux/pages/attendancesPage/attendancesPageActions";
import {
  onBrandChange as onDayOffTypesPageBrandChange,
  onDepartmentChange as onDayOffTypesPageDepartmentChange,
  onDivisionChange as onDayOffTypesPageDivisionChange,
  onShopChange as onDayOffTypesPageShopChange,
  onTabItemChange as onDayOffTypesPageTabItemChange,
} from "../../../redux/pages/dayOffTypesPage/dayOffTypesPageActions";
import {
  onBrandChange as onDeptsAndShopsPageBrandChange,
  onDepartmentChange as onDeptsAndShopsPageDepartmentChange,
  onDivisionChange as onDeptsAndShopsPageDivisionChange,
  onShopChange as onDeptsAndShopsPageShopChange,
  onTypeOfContentChange as onDeptsAndShopsPageTypeOfContentChange,
  onTypeOfDeptsAndShopsChange as onDeptsAndShopsPageTypeOfDeptsAndShopsChange,
  onTypeOfStaffsChange as onDeptsAndShopsPageTypeOfStaffsChange,
} from "../../../redux/pages/deptsAndShopsPage/deptsAndShopsPageActions";
import {
  onBrandChange as onSalariesPageBrandChange,
  onDepartmentChange as onSalariesPageDepartmentChange,
  onDivisionChange as onSalariesPageDivisionChange,
  onShopChange as onSalariesPageShopChange,
  onTabItemChange as onSalariesPageTabItemChange,
} from "../../../redux/pages/salariesPage/salariesPageActions";

// Components
import StyledMenu from "../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import EventRoundedIcon from "@mui/icons-material/EventRounded";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

function DeptAndShopActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();

  // Props
  const {
    // States
    showDeptAndShopActionMenu,
    // Set States
    setShowDeptAndShopActionMenu,
  } = props;

  // Redux Store
  const brandId = useSelector((state) => state.rostersPage.brandId);
  const departmentId = useSelector((state) => state.rostersPage.departmentId);
  const divisionId = useSelector((state) => state.rostersPage.divisionId);
  const divisionType = useSelector((state) => state.rostersPage.divisionType);
  const shopId = useSelector((state) => state.rostersPage.shopId);

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowDeptAndShopActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "Add":
        // Navigate
        navigate("/createStaff", {
          state: { departmentId, divisionId, divisionType, shopId },
        });
        break;
      case "Attendances":
        // Update Redux Store
        dispatch(onAttendancesPageBrandChange(brandId));
        dispatch(onAttendancesPageDivisionChange(divisionId, divisionType));
        dispatch(onAttendancesPageTabItemChange("AttendanceRecords"));

        if (divisionType === "Departments") {
          dispatch(onAttendancesPageDepartmentChange(departmentId));
        } else {
          dispatch(onAttendancesPageShopChange(shopId));
        }

        // Navigate
        navigate("/attendances");
        break;
      case "DayOffTypes":
        // Update Redux Store
        dispatch(onDayOffTypesPageBrandChange(brandId));
        dispatch(onDayOffTypesPageDivisionChange(divisionId, divisionType));
        dispatch(onDayOffTypesPageTabItemChange("DayOffQuotas"));

        if (divisionType === "Departments") {
          dispatch(onDayOffTypesPageDepartmentChange(departmentId));
        } else {
          dispatch(onDayOffTypesPageShopChange(shopId));
        }

        // Navigate
        navigate("/dayOffTypes");
        break;
      case "Edit":
        // Update Redux Store
        dispatch(onDeptsAndShopsPageBrandChange(brandId));
        dispatch(onDeptsAndShopsPageDivisionChange(divisionId, divisionType));
        dispatch(onDeptsAndShopsPageTypeOfContentChange("DeptAndShopDetail"));
        dispatch(onDeptsAndShopsPageTypeOfDeptsAndShopsChange("Current"));
        dispatch(onDeptsAndShopsPageTypeOfStaffsChange("Current"));

        if (divisionType === "Departments") {
          dispatch(onDeptsAndShopsPageDepartmentChange(departmentId));
        } else {
          dispatch(onDeptsAndShopsPageShopChange(shopId));
        }

        // Navigate
        navigate("/deptsAndShops");
        break;
      case "Salaries":
        // Update Redux Store
        dispatch(onSalariesPageBrandChange(brandId));
        dispatch(onSalariesPageDivisionChange(divisionId, divisionType));
        dispatch(onSalariesPageTabItemChange("Salaries"));

        if (divisionType === "Departments") {
          dispatch(onSalariesPageDepartmentChange(departmentId));
        } else {
          dispatch(onSalariesPageShopChange(shopId));
        }

        // Navigate
        navigate("/salaries");
        break;
      case "StaffList":
        // Update Redux Store
        dispatch(onDeptsAndShopsPageBrandChange(brandId));
        dispatch(onDeptsAndShopsPageDivisionChange(divisionId, divisionType));
        dispatch(onDeptsAndShopsPageTypeOfContentChange("StaffList"));
        dispatch(onDeptsAndShopsPageTypeOfDeptsAndShopsChange("Current"));
        dispatch(onDeptsAndShopsPageTypeOfStaffsChange("Current"));

        if (divisionType === "Departments") {
          dispatch(onDeptsAndShopsPageDepartmentChange(departmentId));
        } else {
          dispatch(onDeptsAndShopsPageShopChange(shopId));
        }

        // Navigate
        navigate("/deptsAndShops");
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showDeptAndShopActionMenu}
      open={Boolean(showDeptAndShopActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Add")}>
        <AddRoundedIcon />
        {t("新增員工")}
      </MenuItem>
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Edit")}>
        <EditRoundedIcon />
        {t("編輯")}
      </MenuItem>
      <MenuItem disableRipple onClick={() => onMenuItemClicked("StaffList")}>
        <FormatListBulletedRoundedIcon />
        {t("員工列表")}
      </MenuItem>
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Attendances")}>
        <AccessTimeRoundedIcon />
        {t("出勤紀錄s")}
      </MenuItem>
      <MenuItem disableRipple onClick={() => onMenuItemClicked("DayOffTypes")}>
        <EventRoundedIcon />
        {t("假期管理s")}
      </MenuItem>
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Salaries")}>
        <AttachMoneyRoundedIcon />
        {t("薪酬s")}
      </MenuItem>
    </StyledMenu>
  );
}

export default DeptAndShopActionMenu;
