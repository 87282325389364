// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Helper Functions
import { constructMoneyText } from "../../../helperFunctions/moneyText";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardTableStyles } from "../../../styles/componentStyles/tableStyles/standardTableStyles";

function SalarySummaryBox(props) {
  // Styles
  const classes = useStandardTableStyles();

  // Props
  const {
    // States
    item,
    staffId,
    // Events
    onItemClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const displayText = item.isUnavailable
    ? "-/-"
    : constructMoneyText(item.value);

  return (
    <div
      className={
        item.isEditable
          ? classes.cellBoxLarge
          : classes.cellBoxLargeWithoutPointer
      }
      onClick={() => {
        onItemClicked(item.isEditable, item.textCh, item.textEn, item.type);
      }}
      onMouseEnter={() => {
        onMouseEnterItem(staffId, item.id);
      }}
      onMouseLeave={() => {
        onMouseLeaveItem();
      }}
    >
      <Typography
        align={"center"}
        color={
          item.value && item.value >= 0.01
            ? null
            : item.value < 0
            ? stylesConfig.redTextColor
            : stylesConfig.greyTextColor
        }
        variant="body1"
      >
        {displayText}
      </Typography>
    </div>
  );
}

export default SalarySummaryBox;
