const initialState = {
  brandId: null,
  brandItem: null,
  departmentId: null,
  divisionId: null,
  divisionType: null,
  formSubmitCount: 0,
  shopId: null,
  typeOfContent: "StaffList",
  typeOfDeptsAndShops: "Current",
  typeOfStaffs: "Current",
};

export const deptsAndShopsPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_DEPTS_AND_SHOPS_PAGE_BRAND_CHANGE":
      const { brandId } = action;
      return {
        ...state,
        brandId,
      };
    case "ON_DEPTS_AND_SHOPS_PAGE_BRAND_ITEM_CHANGE":
      const { brandItem } = action;
      return {
        ...state,
        brandItem,
      };
    case "ON_DEPTS_AND_SHOPS_PAGE_DEPARTMENT_CHANGE":
      const { departmentId } = action;
      return {
        ...state,
        departmentId,
      };
    case "ON_DEPTS_AND_SHOPS_PAGE_DIVISION_CHANGE":
      const { divisionId, divisionType } = action;
      return {
        ...state,
        divisionId,
        divisionType,
      };
    case "ON_DEPTS_AND_SHOPS_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_DEPTS_AND_SHOPS_PAGE_SHOP_CHANGE":
      const { shopId } = action;
      return {
        ...state,
        shopId,
      };
    case "ON_DEPTS_AND_SHOPS_PAGE_TYPE_OF_CONTENT_CHANGE":
      const { typeOfContent } = action;
      return {
        ...state,
        typeOfContent,
      };
    case "ON_DEPTS_AND_SHOPS_PAGE_TYPE_OF_DEPTS_AND_SHOPS_CHANGE":
      const { typeOfDeptsAndShops } = action;
      return {
        ...state,
        typeOfDeptsAndShops,
      };
    case "ON_DEPTS_AND_SHOPS_PAGE_TYPE_OF_STAFFS_CHANGE":
      const { typeOfStaffs } = action;
      return {
        ...state,
        typeOfStaffs,
      };
    default:
      return state;
  }
};
