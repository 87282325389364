// Configs
import apisConfig from "../configs/apisConfig";

export const createPermissionGroupFetch = async (
  token,
  permissionId,
  permissionDeptTypeId,
  permissionStaffTypeId,
  departmentIdsArr,
  divisionIdsArr,
  shopIdsArr,
  titleIdsArr
) => {
  try {
    const queryRoute = "/permissionGroup/createPermissionGroup";

    const reqBody = {
      permissionId,
      permissionDeptTypeId,
      permissionStaffTypeId,
      departmentIdsArr,
      divisionIdsArr,
      shopIdsArr,
      titleIdsArr,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deletePermissionGroupFetch = async (token, permissionGroupId) => {
  try {
    const queryRoute = `/permissionGroup/deletePermissionGroup/${permissionGroupId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editPermissionGroupFetch = async (
  token,
  permissionGroupId,
  permissionDeptTypeId,
  permissionStaffTypeId,
  departmentIdsArr,
  divisionIdsArr,
  shopIdsArr,
  titleIdsArr
) => {
  try {
    const queryRoute = `/permissionGroup/editPermissionGroup/${permissionGroupId}`;

    const reqBody = {
      permissionDeptTypeId,
      permissionStaffTypeId,
      departmentIdsArr,
      divisionIdsArr,
      shopIdsArr,
      titleIdsArr,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllPermissionGroupsByPermissionIdFetch = async (
  token,
  permissionId
) => {
  try {
    const queryRoute = `/permissionGroup/getAllPermissionGroupsByPermissionId/${permissionId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getPermissionGroupDetailsByIdFetch = async (
  token,
  permissionGroupId
) => {
  try {
    const queryRoute = `/permissionGroup/getPermissionGroupDetailsById/${permissionGroupId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
