// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import DayOffTypeBoxesContainer from "../components/boxes/dayOffTypesPage/dayOffTypeBoxesContainer";
import DayOffTypeRuleBoxesContainer from "../components/boxes/dayOffTypesPage/dayOffTypeRuleBoxesContainer";
import DeptAndShopBoxesContainer from "../components/boxes/dayOffTypesPage/deptAndShopBoxesContainer";
// Forms
import DayOffTypeDetailFormContainer from "../components/forms/dayOffTypesPage/dayOffTypeDetail/dayOffTypeDetailFormContainer";
// Headers
import DayOffTypesPageHeader from "../components/headers/dayOffTypesPage/dayOffTypesPageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";
// Tables
import DayOffQuotaTableContainer from "../components/tables/dayOffTypesPage/dayOffQuotaTableContainer";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function DayOffTypesPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const tabItem = useSelector((state) => state.dayOffTypesPage.tabItem);

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("DayOffTypes"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <DayOffTypesPageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          {tabItem === "DayOffTypes" ? (
            <DayOffTypeBoxesContainer />
          ) : (
            <DeptAndShopBoxesContainer />
          )}
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          {tabItem === "DayOffTypes" ? (
            <Grid container spacing={4}>
              <Grid item xs={12} lg={8}>
                <DayOffTypeDetailFormContainer />
                <SpacingBox
                  // Render
                  height={stylesConfig.spacingBoxMarginBottom}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <DayOffTypeRuleBoxesContainer />
                <SpacingBox
                  // Render
                  height={stylesConfig.spacingBoxMarginBottom}
                />
              </Grid>
            </Grid>
          ) : (
            <>
              <DayOffQuotaTableContainer />
              <SpacingBox
                // Render
                height={stylesConfig.spacingBoxMarginBottom}
              />
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default DayOffTypesPage;
