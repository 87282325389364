// Configs
import apisConfig from "../configs/apisConfig";

export const createWorkExperienceFetch = async (
  token,
  candidateId,
  staffContractTypeId,
  companyName,
  titleName,
  startMonth,
  endMonth,
  jobDescription,
  isRelatedToJobApplied,
  salary,
  isCommission
) => {
  try {
    const queryRoute = "/workExperience/createWorkExperience";

    const reqBody = {
      candidateId,
      staffContractTypeId,
      companyName,
      titleName,
      startMonth,
      endMonth,
      jobDescription,
      isRelatedToJobApplied,
      salary,
      isCommission,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteWorkExperienceFetch = async (token, workExperienceId) => {
  try {
    const queryRoute = `/workExperience/deleteWorkExperience/${workExperienceId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editWorkExperienceFetch = async (
  token,
  workExperienceId,
  staffContractTypeId,
  companyName,
  titleName,
  startMonth,
  endMonth,
  jobDescription,
  isRelatedToJobApplied,
  salary,
  isCommission
) => {
  try {
    const queryRoute = `/workExperience/editWorkExperience/${workExperienceId}`;

    const reqBody = {
      staffContractTypeId,
      companyName,
      titleName,
      startMonth,
      endMonth,
      jobDescription,
      isRelatedToJobApplied,
      salary,
      isCommission,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllWorkExperiencesByCandidateIdFetch = async (
  token,
  candidateId
) => {
  try {
    const queryRoute = `/workExperience/getAllWorkExperiencesByCandidateId/${candidateId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getWorkExperienceDetailsByIdFetch = async (
  token,
  workExperienceId
) => {
  try {
    const queryRoute = `/workExperience/getWorkExperienceDetailsById/${workExperienceId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
