export function onFormSubmitCountIncrease() {
  return {
    type: "ON_CANDIDATE_PROFILE_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onCandidateChange(candidateId) {
  return {
    type: "ON_CANDIDATE_PROFILE_PAGE_CANDIDATE_CHANGE",
    candidateId,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_CANDIDATE_PROFILE_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}
