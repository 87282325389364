// Components
import StyledMenu from "../../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

function ArrangementActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // States
    showArrangementActionMenu,
    // Set States
    setShowArrangementActionMenu,
    // Functions
    displayArrangementActionDialog,
    displayArrangementActionModal,
  } = props;

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowArrangementActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "Delete":
        displayArrangementActionDialog("DeleteArrangement");
        break;
      case "Edit":
        displayArrangementActionModal();
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showArrangementActionMenu}
      open={Boolean(showArrangementActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Edit")}>
        <EditRoundedIcon />
        {t("編輯")}
      </MenuItem>
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Delete")}>
        <DeleteRoundedIcon />
        {t("刪除")}
      </MenuItem>
    </StyledMenu>
  );
}

export default ArrangementActionMenu;
