// Configs
import apisConfig from "../configs/apisConfig";

export const getAllDivisionsFetch = async (token) => {
  try {
    const queryRoute = "/division/getAllDivisions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
