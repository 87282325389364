// Consts
import {
  hourOptions,
  minuteOptions,
} from "../../../../consts/arrangementActionModalContentConsts";

// Custom Hooks
import { useLanguage } from "../../../../customHooks/getLanguage";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Typography from "@mui/material/Typography";

// Styles
import {
  formSelectBoxContainerStyles,
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ArrangementActionModalContent(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onInputFieldChange,
    onInputFieldKeyPressed,
    onSubmitBtnClicked,
    // Functions
    checkIsFieldError,
    getErrorFieldMessage,
    // States
    afterLunchTime,
    afterLunchTimeHour,
    afterLunchTimeMinute,
    afterLunchTimeType,
    divisionType,
    endTime,
    endTimeHour,
    endTimeMinute,
    endTimeType,
    formAlertText,
    formAlertType,
    lunchTime,
    lunchTimeHour,
    lunchTimeMinute,
    lunchTimeType,
    shiftField,
    shiftOptions,
    shiftType,
    shopType,
    shouldShowFormAlert,
    startTime,
    startTimeHour,
    startTimeMinute,
    startTimeType,
    workingPeriodField,
    workingPeriodOptions,
    workingPeriodType,
  } = props;

  // Hanlde States
  const afterLunchTimeVar = afterLunchTime ? new Date(afterLunchTime) : null;
  const endTimeVar = endTime ? new Date(endTime) : null;
  const lunchTimeVar = lunchTime ? new Date(lunchTime) : null;
  const startTimeVar = startTime ? new Date(startTime) : null;

  return (
    <div>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("新增特別安排")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid className={classes.formSwitchContainer} item xs={12}>
          <Typography align={"left"} variant="body1">
            {t("套用到")}
          </Typography>
          {divisionType === "Departments" ? (
            <FormControl sx={formSelectBoxContainerStyles} variant="standard">
              <Select
                value={workingPeriodType}
                onChange={(event) =>
                  onInputFieldChange("workingPeriodType", event.target.value)
                }
              >
                <MenuItem value={"All"}>{t("所有工作時段")}</MenuItem>
                <MenuItem value={"Single"}>{t("特定工作時段")}</MenuItem>
              </Select>
            </FormControl>
          ) : (
            <div>
              <FormControl sx={formSelectBoxContainerStyles} variant="standard">
                <Select
                  value={shopType}
                  onChange={(event) =>
                    onInputFieldChange("shopType", event.target.value)
                  }
                >
                  <MenuItem value={"All"}>{t("所有店鋪")}</MenuItem>
                  <MenuItem value={"Single"}>{t("此店鋪")}</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={formSelectBoxContainerStyles} variant="standard">
                <Select
                  value={shiftType}
                  onChange={(event) =>
                    onInputFieldChange("shiftType", event.target.value)
                  }
                >
                  <MenuItem value={"All"}>{t("所有值更")}</MenuItem>
                  <MenuItem value={"Single"}>{t("特定值更")}</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
        </Grid>
        {divisionType === "Departments" && workingPeriodType === "Single" && (
          <Grid className={classes.formAutoCompleteContainer} item xs={12}>
            {workingPeriodOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option.working_period_code}
                onChange={(event, value) =>
                  onInputFieldChange("workingPeriodField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={workingPeriodOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("workingPeriodField")}
                    helperText={getErrorFieldMessage("workingPeriodField")}
                    label={t("工作時段")}
                    variant="standard"
                  />
                )}
                value={workingPeriodField}
              />
            )}
          </Grid>
        )}
        {divisionType === "Shops" && shiftType === "Single" && (
          <Grid className={classes.formAutoCompleteContainer} item xs={12}>
            {shiftOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option.shift_code}
                onChange={(event, value) =>
                  onInputFieldChange("shiftField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={shiftOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("shiftField")}
                    helperText={getErrorFieldMessage("shiftField")}
                    label={t("值更")}
                    variant="standard"
                  />
                )}
                value={shiftField}
              />
            )}
          </Grid>
        )}
      </Grid>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("工作時間")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid className={classes.formSwitchContainer} item xs={12}>
          <Typography align={"left"} variant="body1">
            {t("開始時間")}
          </Typography>
        </Grid>
        <Grid className={classes.formSwitchContainer} item xs={12}>
          <FormControl sx={formSelectBoxContainerStyles} variant="standard">
            <Select
              value={startTimeType}
              onChange={(event) =>
                onInputFieldChange("startTimeType", event.target.value)
              }
            >
              <MenuItem value={"ActualTime"}>{t("確實時間")}</MenuItem>
              <MenuItem value={"Early"}>{t("提早")}</MenuItem>
              <MenuItem value={"Delay"}>{t("延遲")}</MenuItem>
            </Select>
          </FormControl>
          {startTimeType === "ActualTime" ? (
            <TimePicker
              label={t("開始時間")}
              onChange={(value) => onInputFieldChange("startTime", value)}
              slotProps={{
                textField: {
                  error: checkIsFieldError("startTime"),
                  helperText: getErrorFieldMessage("startTime"),
                },
              }}
              value={startTimeVar}
            />
          ) : (
            <div>
              <FormControl sx={formSelectBoxContainerStyles} variant="standard">
                <Select
                  value={startTimeHour}
                  error={checkIsFieldError("startTimeHour")}
                  onChange={(event) =>
                    onInputFieldChange("startTimeHour", event.target.value)
                  }
                >
                  {hourOptions.map((item) => (
                    <MenuItem value={item.value}>{item[t("textCh")]}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={formSelectBoxContainerStyles} variant="standard">
                <Select
                  value={startTimeMinute}
                  error={checkIsFieldError("startTimeMinute")}
                  onChange={(event) =>
                    onInputFieldChange("startTimeMinute", event.target.value)
                  }
                >
                  {minuteOptions.map((item) => (
                    <MenuItem value={item.value}>{item[t("textCh")]}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </Grid>
        <Grid className={classes.formSwitchContainer} item xs={12}>
          <Typography align={"left"} variant="body1">
            {t("結束時間")}
          </Typography>
        </Grid>
        <Grid className={classes.formSwitchContainer} item xs={12}>
          <FormControl sx={formSelectBoxContainerStyles} variant="standard">
            <Select
              value={endTimeType}
              onChange={(event) =>
                onInputFieldChange("endTimeType", event.target.value)
              }
            >
              <MenuItem value={"ActualTime"}>{t("確實時間")}</MenuItem>
              <MenuItem value={"Early"}>{t("提早")}</MenuItem>
              <MenuItem value={"Delay"}>{t("延遲")}</MenuItem>
            </Select>
          </FormControl>
          {endTimeType === "ActualTime" ? (
            <TimePicker
              label={t("結束時間")}
              onChange={(value) => onInputFieldChange("endTime", value)}
              slotProps={{
                textField: {
                  error: checkIsFieldError("endTime"),
                  helperText: getErrorFieldMessage("endTime"),
                },
              }}
              value={endTimeVar}
            />
          ) : (
            <div>
              <FormControl sx={formSelectBoxContainerStyles} variant="standard">
                <Select
                  value={endTimeHour}
                  error={checkIsFieldError("endTimeHour")}
                  onChange={(event) =>
                    onInputFieldChange("endTimeHour", event.target.value)
                  }
                >
                  {hourOptions.map((item) => (
                    <MenuItem value={item.value}>{item[t("textCh")]}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={formSelectBoxContainerStyles} variant="standard">
                <Select
                  value={endTimeMinute}
                  error={checkIsFieldError("endTimeMinute")}
                  onChange={(event) =>
                    onInputFieldChange("endTimeMinute", event.target.value)
                  }
                >
                  {minuteOptions.map((item) => (
                    <MenuItem value={item.value}>{item[t("textCh")]}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </Grid>
      </Grid>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("午飯時間")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid className={classes.formSwitchContainer} item xs={12}>
          <Typography align={"left"} variant="body1">
            {t("開始時間")}
          </Typography>
        </Grid>
        <Grid className={classes.formSwitchContainer} item xs={12}>
          <FormControl sx={formSelectBoxContainerStyles} variant="standard">
            <Select
              value={lunchTimeType}
              onChange={(event) =>
                onInputFieldChange("lunchTimeType", event.target.value)
              }
            >
              <MenuItem value={"ActualTime"}>{t("確實時間")}</MenuItem>
              <MenuItem value={"Early"}>{t("提早")}</MenuItem>
              <MenuItem value={"Delay"}>{t("延遲")}</MenuItem>
            </Select>
          </FormControl>
          {lunchTimeType === "ActualTime" ? (
            <TimePicker
              label={t("開始時間")}
              onChange={(value) => onInputFieldChange("lunchTime", value)}
              slotProps={{
                textField: {
                  error: checkIsFieldError("lunchTime"),
                  helperText: getErrorFieldMessage("lunchTime"),
                },
              }}
              value={lunchTimeVar}
            />
          ) : (
            <div>
              <FormControl sx={formSelectBoxContainerStyles} variant="standard">
                <Select
                  value={lunchTimeHour}
                  error={checkIsFieldError("lunchTimeHour")}
                  onChange={(event) =>
                    onInputFieldChange("lunchTimeHour", event.target.value)
                  }
                >
                  {hourOptions.map((item) => (
                    <MenuItem value={item.value}>{item[t("textCh")]}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={formSelectBoxContainerStyles} variant="standard">
                <Select
                  value={lunchTimeMinute}
                  error={checkIsFieldError("lunchTimeMinute")}
                  onChange={(event) =>
                    onInputFieldChange("lunchTimeMinute", event.target.value)
                  }
                >
                  {minuteOptions.map((item) => (
                    <MenuItem value={item.value}>{item[t("textCh")]}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </Grid>
        <Grid className={classes.formSwitchContainer} item xs={12}>
          <Typography align={"left"} variant="body1">
            {t("結束時間")}
          </Typography>
        </Grid>
        <Grid className={classes.formSwitchContainer} item xs={12}>
          <FormControl sx={formSelectBoxContainerStyles} variant="standard">
            <Select
              value={afterLunchTimeType}
              onChange={(event) =>
                onInputFieldChange("afterLunchTimeType", event.target.value)
              }
            >
              <MenuItem value={"ActualTime"}>{t("確實時間")}</MenuItem>
              <MenuItem value={"Early"}>{t("提早")}</MenuItem>
              <MenuItem value={"Delay"}>{t("延遲")}</MenuItem>
            </Select>
          </FormControl>
          {afterLunchTimeType === "ActualTime" ? (
            <TimePicker
              label={t("結束時間")}
              onChange={(value) => onInputFieldChange("afterLunchTime", value)}
              slotProps={{
                textField: {
                  error: checkIsFieldError("afterLunchTime"),
                  helperText: getErrorFieldMessage("afterLunchTime"),
                },
              }}
              value={afterLunchTimeVar}
            />
          ) : (
            <div>
              <FormControl sx={formSelectBoxContainerStyles} variant="standard">
                <Select
                  value={afterLunchTimeHour}
                  error={checkIsFieldError("afterLunchTimeHour")}
                  onChange={(event) =>
                    onInputFieldChange("afterLunchTimeHour", event.target.value)
                  }
                >
                  {hourOptions.map((item) => (
                    <MenuItem value={item.value}>{item[t("textCh")]}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={formSelectBoxContainerStyles} variant="standard">
                <Select
                  value={afterLunchTimeMinute}
                  error={checkIsFieldError("afterLunchTimeMinute")}
                  onChange={(event) =>
                    onInputFieldChange(
                      "afterLunchTimeMinute",
                      event.target.value
                    )
                  }
                >
                  {minuteOptions.map((item) => (
                    <MenuItem value={item.value}>{item[t("textCh")]}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </Grid>
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <Button
            onClick={onSubmitBtnClicked}
            variant="contained"
            sx={formSubmitBtnStyles}
          >
            {t("確定")}
          </Button>
        </Grid>
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default ArrangementActionModalContent;
