// Components
// Boxes
import CaptionBox from "./captionBox";

// Custom Hooks
import { useLanguage } from "../../../../customHooks/getLanguage";

// Material UI
// Components
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

function QuestionBoxContentText(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // Events
    onTextInputFieldChange,
    // States
    groupIndex,
    index,
    item,
  } = props;

  return (
    <Grid item xs={12}>
      <TextField
        error={item.isError}
        fullWidth
        helperText={item.errorText}
        label={item[t("edited_question_text_ch")]}
        margin="dense"
        multiline
        name={item[t("edited_question_text_ch")]}
        onChange={(event) =>
          onTextInputFieldChange(groupIndex, index, event.target.value)
        }
        value={item.answerValue ? item.answerValue : ""}
        variant="standard"
      />
      {item[t("captionStrCh")] && (
        <CaptionBox
          // States
          captionStr={item[t("captionStrCh")]}
        />
      )}
    </Grid>
  );
}

export default QuestionBoxContentText;
