// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  createWiFiFetch,
  editWiFiFetch,
  getWiFiDetailsByIdFetch,
} from "../../../fetches/wiFiFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ShopWiFiActionModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
    // States
    wiFiId,
  } = props;

  // Redux Store
  const shopId = useSelector((state) => state.deptsAndShopsPage.shopId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [shopWiFiActionDialogText, setShopWiFiActionDialogText] = useState("");
  const [shopWiFiActionDialogType, setShopWiFiActionDialogType] =
    useState(null);
  const [showShopWiFiActionDialog, setShowShopWiFiActionDialog] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Text Fields
  const [ipAddress, setIpAddress] = useState("");

  // Events
  // Events - Dialogs
  const onShopWiFiActionDialogCanceled = () => {
    // Set States
    setShowShopWiFiActionDialog(false);
  };

  const onShopWiFiActionDialogConfirmed = () => {
    switch (shopWiFiActionDialogType) {
      case "CreateShopWiFi":
        createWiFi();
        break;
      case "EditShopWiFi":
        editWiFi();
        break;
      default:
        break;
    }

    // Set States
    setShowShopWiFiActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "ipAddress":
        setIpAddress(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!ipAddress) {
      addToErrorFields("ipAddress", t("請先填寫 IP 地址"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayShopWiFiActionDialog(wiFiId ? "EditShopWiFi" : "CreateShopWiFi");
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displayShopWiFiActionDialog = (shopWiFiActionType) => {
    // Set States
    setShopWiFiActionDialogType(shopWiFiActionType);

    switch (shopWiFiActionType) {
      case "CreateShopWiFi":
        setShopWiFiActionDialogText(t("確認要新增 Wi-Fi 嗎？"));
        break;
      case "EditShopWiFi":
        setShopWiFiActionDialogText(t("確認要編輯 Wi-Fi 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowShopWiFiActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createWiFi = async () => {
    const results = await createWiFiFetch(token, ipAddress, shopId, "Shops");

    if (results.success) {
      onModalClosed();
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editWiFi = async () => {
    const results = await editWiFiFetch(token, wiFiId, ipAddress);

    if (results.success) {
      onModalClosed();
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  // Functions = Queries
  const getWiFiDetailsById = async () => {
    const results = await getWiFiDetailsByIdFetch(token, wiFiId);

    if (results.wiFiDetails) {
      const { ip_address } = results.wiFiDetails;

      // Set States
      setIpAddress(ip_address ? ip_address : "");
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    if (wiFiId) {
      getWiFiDetailsById();
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [wiFiId]);

  return (
    <div className={classes.modalContainerSmall}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onShopWiFiActionDialogCanceled}
        onDialogConfirmed={onShopWiFiActionDialogConfirmed}
        // States
        dialogText={shopWiFiActionDialogText}
        showDialog={showShopWiFiActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {wiFiId ? t("編輯 Wi-Fi") : t("新增 Wi-Fi")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <TextField
              autoFocus
              error={checkIsFieldError("ipAddress")}
              fullWidth
              helperText={getErrorFieldMessage("ipAddress")}
              label={t("IP 地址")}
              margin="dense"
              name="ipAddress"
              onChange={(event) =>
                onInputFieldChange("ipAddress", event.target.value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              value={ipAddress}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              {t("確定")}
            </Button>
          </Grid>
          {/* Alert */}
          {shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default ShopWiFiActionModal;
