// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/applicationsPage/applicationsPageActions";

// Components
// Boxes
import QuestionGroupBox from "../../boxes/applicationsPage/applicationDetailForm/questionGroupBox.js";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Modals
import FileViewModal from "../../modals/applicationPage/fileViewModal";
import MapViewModal from "../../modals/applicationPage/mapViewModal";
import ModalContainer from "../../modals/modalContainer";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  approveApplicationFetch,
  editApplicationFetch,
  cancelApplicationFetch,
  rejectApplicationFetch,
} from "../../../fetches/applicationFetches";
import { getApplicationCaptionsFetch } from "../../../fetches/applicationCaptionFetches";
import { getAllApplicationQuestionsByApplicationIdOrApplicationTypeIdFetch } from "../../../fetches/applicationQuestionFetches";

// Helper Functions
import {
  hanleChangeInputField,
  handleSubmit,
} from "../../../helperFunctions/applicationQuestions";
import { constructQuestionText } from "../../../helperFunctions/questionText";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ApplicationDetailForm() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const applicationId = useSelector(
    (state) => state.applicationsPage.applicationId
  );
  const applicationOrApprovalTypeValue = useSelector(
    (state) => state.applicationsPage.conditionApplicationOrApprovalTypeValue
  );
  const applicationStatusTypeId = useSelector(
    (state) => state.applicationsPage.conditionApplicationStatusTypeId
  );
  const applicationTypeId = useSelector(
    (state) => state.applicationsPage.applicationTypeId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [isCancelable, setIsCancelable] = useState(false);
  const [questions, setQuestions] = useState(null);
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [applicationActionDialogText, setApplicationActionDialogText] =
    useState("");
  const [applicationActionDialogType, setApplicationActionDialogType] =
    useState(null);
  const [showApplicationActionDialog, setShowApplicationActionDialog] =
    useState(false);
  // Modals
  const [fileIdsArr, setFileIdsArr] = useState([]);
  const [locationId, setLocationId] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [showFileViewModal, setShowFileViewModal] = useState(false);
  const [showMapViewModal, setShowMapViewModal] = useState(false);

  // Handle States
  const isEditable = applicationStatusTypeId && applicationStatusTypeId === 1;
  const itemsArr = questions;

  // Events
  // Events - Dialogs
  const onApplicationActionDialogCanceled = () => {
    // Set States
    setShowApplicationActionDialog(false);
  };

  const onApplicationActionDialogConfirmed = () => {
    switch (applicationActionDialogType) {
      case "ApproveApplication":
        approveApplication();
        break;
      case "CancelApplication":
        cancelApplication();
        break;
      case "EditApplication":
        editApplication();
        break;
      case "RejectApplication":
        rejectApplication();
        break;
      default:
        break;
    }

    // Set States
    setShowApplicationActionDialog(false);
  };

  // Events - Fields
  const onDateTimeInputFieldChange = (groupIndex, index, value) => {
    // Check Is Editable
    if (!checkIsEditable(groupIndex, index)) {
      return;
    }

    changeInputField(groupIndex, index, value);
  };

  const onFileFieldClicked = (questionText, value) => {
    // Set States
    setFileIdsArr(value);
    setModalTitle(questionText);

    displayFileViewModal();
  };

  const onInputFieldKeyPressed = (key) => {
    if (!isEditable) {
      return;
    }

    if (key === "Enter") {
      onSubmitBtnClicked("EditApplication");
    }
  };

  const onLocationFieldClicked = (value) => {
    displayMapViewModal(value);
  };

  const onOptionInputFieldChange = (groupIndex, index, optionField) => {
    // Check Is Editable
    if (!checkIsEditable(groupIndex, index)) {
      return;
    }

    if (!optionField) {
      changeInputField(groupIndex, index, null);
      return;
    }

    const value = Array.isArray(optionField)
      ? optionField.map((item) => item.id)
      : optionField.id;

    changeInputField(groupIndex, index, value);
  };

  const onTextInputFieldChange = (groupIndex, index, value) => {
    // Check Is Editable
    if (!checkIsEditable(groupIndex, index)) {
      return;
    }

    changeInputField(groupIndex, index, value);
  };

  // Events - Forms
  const onSubmitBtnClicked = (submitType) => {
    const handleSubmitResults = handleSubmit(questions);

    if (!handleSubmitResults) {
      showFormAlert("error", t("提交錯誤"));
      return;
    }

    const { tempQuestions, isAnyQuestionError } = handleSubmitResults;

    if (isAnyQuestionError) {
      // Set States
      setQuestions(tempQuestions);

      return;
    }

    displayApplicationActionDialog(submitType);
  };

  // Events - Modal
  const onFileViewModalClosed = () => {
    // Set States
    setShowFileViewModal(false);
  };

  const onMapViewModalClosed = () => {
    // Set States
    setShowMapViewModal(false);
  };

  // Functions
  // Functions - Normal
  const changeInputField = async (groupIndex, index, value) => {
    // Set States
    setShouldShowFormAlert(false);

    const tempQuestions = [...questions];
    tempQuestions[groupIndex].questions[index].answerValue = value;
    tempQuestions[groupIndex].questions[index].isError = false;
    tempQuestions[groupIndex].questions[index].errorText = null;

    const { id, is_caption } = tempQuestions[groupIndex].questions[index];

    // Update Caption
    if (is_caption) {
      if (value) {
        const captionResults = await getApplicationCaptions(id, value);

        if (captionResults.applicationCaptionStrCh) {
          tempQuestions[groupIndex].questions[index].captionStrCh =
            captionResults.applicationCaptionStrCh;
        }

        if (captionResults.applicationCaptionStrEn) {
          tempQuestions[groupIndex].questions[index].captionStrEn =
            captionResults.applicationCaptionStrEn;
        }
      } else {
        tempQuestions[groupIndex].questions[index].captionStrCh = null;
        tempQuestions[groupIndex].questions[index].captionStrEn = null;
      }
    }

    tempQuestions[groupIndex].questions = hanleChangeInputField(
      tempQuestions[groupIndex].questions,
      index,
      value
    );

    // Set States
    setQuestions(tempQuestions);
  };

  const checkIsEditable = (groupIndex, index) => {
    const isEditable = questions[groupIndex].questions[index].is_editable;

    if (!isEditable) {
      showFormAlert("warning", t("不能更改此項目"));
      return false;
    } else {
      return true;
    }
  };

  const clearForm = () => {
    // Set States
    setQuestions(null);
  };

  const displayApplicationActionDialog = (applicationActionType) => {
    // Set States
    setApplicationActionDialogType(applicationActionType);

    switch (applicationActionType) {
      case "ApproveApplication":
        setApplicationActionDialogText(t("確認要批准 申請 嗎？"));
        break;
      case "CancelApplication":
        setApplicationActionDialogText(t("確認要取消 申請 嗎？"));
        break;
      case "EditApplication":
        setApplicationActionDialogText(t("確認要修改 申請 嗎？"));
        break;
      case "RejectApplication":
        setApplicationActionDialogText(t("確認要駁回 申請 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowApplicationActionDialog(true);
  };

  const displayFileViewModal = () => {
    // Set States
    setShowFileViewModal(true);
  };

  const displayMapViewModal = (locationIdVar) => {
    // Set States
    setLocationId(locationIdVar);
    setShowMapViewModal(true);
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const approveApplication = async () => {
    const results = await approveApplicationFetch(token, applicationId);

    if (results.success) {
      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());
    }
  };

  const cancelApplication = async () => {
    const results = await cancelApplicationFetch(token, applicationId);

    if (results.success) {
      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());
    }
  };

  const editApplication = async () => {
    let answers = [];

    for (let questionGroupItem of questions) {
      for (let questionItem of questionGroupItem.questions) {
        const questionId = questionItem.id;
        const answerValue =
          questionItem.application_question_type_id === 1
            ? Array.isArray(questionItem.answerValue)
              ? questionItem.answerValue.map((answerItem) => answerItem)
              : questionItem.answerValue
            : questionItem.answerValue;

        answers.push({ questionId, answerValue });
      }
    }

    const results = await editApplicationFetch(token, applicationId, answers);

    if (results.success) {
      showFormAlert("success", t("成功提交"));

      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());
    } else if (results.validatetionFailed) {
      showFormAlert("warning", results[t("messageCh")]);
    }
  };

  const rejectApplication = async () => {
    const results = await rejectApplicationFetch(token, applicationId);

    if (results.success) {
      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());
    }
  };

  // Functions - Queries
  const getAllApplicationQuestionsByApplicationIdOrApplicationTypeId =
    async () => {
      if (applicationTypeId) {
        const results =
          await getAllApplicationQuestionsByApplicationIdOrApplicationTypeIdFetch(
            token,
            applicationId,
            applicationTypeId
          );

        if (results.applicationQuestions) {
          for (let groupItem of results.applicationQuestions) {
            for (let questionItem of groupItem.questions) {
              questionItem.edited_question_text_ch = constructQuestionText(
                "zh-hk",
                questionItem.question_text_ch,
                questionItem.application_question_type_id,
                questionItem.is_required,
                questionItem.max_num_of_answer
              );

              questionItem.edited_question_text_en = constructQuestionText(
                "en-us",
                questionItem.question_text_en,
                questionItem.application_question_type_id,
                questionItem.is_required,
                questionItem.max_num_of_answer
              );
            }
          }
        }

        // Set States
        setIsCancelable(results.isCancelable);
        setQuestions(
          results.applicationQuestions ? results.applicationQuestions : null
        );
      }
    };

  const getApplicationCaptions = async (applicationQuestionId, answerValue) => {
    const results = await getApplicationCaptionsFetch(
      token,
      applicationId,
      applicationQuestionId,
      answerValue
    );

    return results;
  };

  // Life Cycle
  useEffect(() => {
    if (applicationId) {
      getAllApplicationQuestionsByApplicationIdOrApplicationTypeId();
    } else {
      clearForm();
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [applicationId]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onApplicationActionDialogCanceled}
        onDialogConfirmed={onApplicationActionDialogConfirmed}
        // States
        dialogText={applicationActionDialogText}
        showDialog={showApplicationActionDialog}
      />
      {/* Modals */}
      <ModalContainer
        // Events
        onModalClosed={onFileViewModalClosed}
        // States
        showModal={showFileViewModal}
      >
        <FileViewModal
          // States
          fileIdsArr={fileIdsArr}
          modalTitle={modalTitle}
        />
      </ModalContainer>
      <ModalContainer
        // Events
        onModalClosed={onMapViewModalClosed}
        // States
        showModal={showMapViewModal}
      >
        <MapViewModal
          // States
          locationId={locationId}
        />
      </ModalContainer>
      {/* Form */}
      {itemsArr ? (
        itemsArr.map((item, index) => (
          <QuestionGroupBox
            key={item.id}
            // Events
            onDateTimeInputFieldChange={onDateTimeInputFieldChange}
            onFileFieldClicked={onFileFieldClicked}
            onInputFieldKeyPressed={onInputFieldKeyPressed}
            onLocationFieldClicked={onLocationFieldClicked}
            onOptionInputFieldChange={onOptionInputFieldChange}
            onSubmitBtnClicked={onSubmitBtnClicked}
            onTextInputFieldChange={onTextInputFieldChange}
            // Render
            isEditable={isEditable}
            isLastItem={itemsArr.length - 1 === index}
            // States
            applicationOrApprovalTypeValue={applicationOrApprovalTypeValue}
            formAlertText={formAlertText}
            formAlertType={formAlertType}
            groupIndex={index}
            isCancelable={isCancelable}
            item={item}
            shouldShowFormAlert={shouldShowFormAlert}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有申請內容")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default ApplicationDetailForm;
