// Configs
import apisConfig from "../configs/apisConfig";

export const createDayOffTypeFetch = async (
  token,
  dayOffTypeRuleTypeId,
  dayOffTypeRequestTypeId,
  dayOffTypeCountTypeId,
  dayOffTypePayoutTypeId,
  dayOffTypePaidTypeId,
  dayOffTypeNameCh,
  dayOffTypeNameEnFull,
  dayOffTypeNameEnShort,
  isApplicable,
  isAvailableInProbation,
  isAccumulative,
  maxAccumulativeNum,
  dayOffTypeSettleTypeId,
  monthSettle,
  daySettle,
  isActive,
  divisionIdsArr
) => {
  try {
    const queryRoute = "/dayOffType/createDayOffType";

    const reqBody = {
      dayOffTypeRuleTypeId,
      dayOffTypeRequestTypeId,
      dayOffTypeCountTypeId,
      dayOffTypePayoutTypeId,
      dayOffTypePaidTypeId,
      dayOffTypeNameCh,
      dayOffTypeNameEnFull,
      dayOffTypeNameEnShort,
      isApplicable,
      isAvailableInProbation,
      isAccumulative,
      maxAccumulativeNum,
      dayOffTypeSettleTypeId,
      monthSettle,
      daySettle,
      isActive,
      divisionIdsArr,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteOrRestoreDayOffTypeFetch = async (
  token,
  dayOffTypeId,
  typeOfDayOffTypes
) => {
  try {
    const queryRoute = `/dayOffType/deleteOrRestoreDayOffType/${dayOffTypeId}`;

    const reqBody = { typeOfDayOffTypes };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editDayOffTypeFetch = async (
  token,
  dayOffTypeId,
  dayOffTypeRuleTypeId,
  dayOffTypeRequestTypeId,
  dayOffTypeCountTypeId,
  dayOffTypePayoutTypeId,
  dayOffTypePaidTypeId,
  dayOffTypeNameCh,
  dayOffTypeNameEnFull,
  dayOffTypeNameEnShort,
  isApplicable,
  isAvailableInProbation,
  isAccumulative,
  maxAccumulativeNum,
  dayOffTypeSettleTypeId,
  monthSettle,
  daySettle,
  isActive,
  divisionIdsArr
) => {
  try {
    const queryRoute = `/dayOffType/editDayOffType/${dayOffTypeId}`;

    const reqBody = {
      dayOffTypeRuleTypeId,
      dayOffTypeRequestTypeId,
      dayOffTypeCountTypeId,
      dayOffTypePayoutTypeId,
      dayOffTypePaidTypeId,
      dayOffTypeNameCh,
      dayOffTypeNameEnFull,
      dayOffTypeNameEnShort,
      isApplicable,
      isAvailableInProbation,
      isAccumulative,
      maxAccumulativeNum,
      dayOffTypeSettleTypeId,
      monthSettle,
      daySettle,
      isActive,
      divisionIdsArr,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllDayOffTypesFetch = async (token, typeOfDayOffTypes) => {
  try {
    const queryRoute = "/dayOffType/getAllDayOffTypes";

    const reqBody = {
      typeOfDayOffTypes,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getDayOffTypeDetailsByIdFetch = async (token, dayOffTypeId) => {
  try {
    const queryRoute = `/dayOffType/getDayOffTypeDetailsById/${dayOffTypeId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
