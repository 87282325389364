const today = new Date();
const currentMonth = new Date(today.getFullYear(), today.getMonth(), 1);

const initialState = {
  brandId: null,
  brandItem: null,
  departmentId: null,
  divisionId: null,
  divisionType: null,
  formSubmitCount: 0,
  month: currentMonth.toString(),
  shopId: null,
  shopItem: null,
  typeOfContent: "WorkingPeriods",
  typeOfWorkingPeriodsAndShifts: "Current",
};

export const rostersPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_ROSTERS_PAGE_BRAND_CHANGE":
      const { brandId } = action;
      return {
        ...state,
        brandId,
      };
    case "ON_ROSTERS_PAGE_BRAND_ITEM_CHANGE":
      const { brandItem } = action;
      return {
        ...state,
        brandItem,
      };
    case "ON_ROSTERS_PAGE_DEPARTMENT_CHANGE":
      const { departmentId } = action;
      return {
        ...state,
        departmentId,
      };
    case "ON_ROSTERS_PAGE_DIVISION_CHANGE":
      const { divisionId, divisionType } = action;
      return {
        ...state,
        divisionId,
        divisionType,
      };
    case "ON_ROSTERS_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_ROSTERS_PAGE_MONTH_CHANGE":
      const { month } = action;
      return {
        ...state,
        month: month.toString(),
      };
    case "ON_ROSTERS_PAGE_SHOP_CHANGE":
      const { shopId } = action;
      return {
        ...state,
        shopId,
      };
    case "ON_ROSTERS_PAGE_SHOP_ITEM_CHANGE":
      const { shopItem } = action;
      return {
        ...state,
        shopItem,
      };
    case "ON_ROSTERS_PAGE_TYPE_OF_CONTENT_CHANGE":
      const { typeOfContent } = action;
      return {
        ...state,
        typeOfContent,
      };
    case "ON_ROSTERS_PAGE_TYPE_OF_WORKING_PERIODS_AND_SHIFTS_CHANGE":
      const { typeOfWorkingPeriodsAndShifts } = action;
      return {
        ...state,
        typeOfWorkingPeriodsAndShifts,
      };
    default:
      return state;
  }
};
