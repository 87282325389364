// Configs
import apisConfig from "../configs/apisConfig";

export const createCompanyFetch = async (
  token,
  companyNameCh,
  companyNameEnFull,
  companyNameEnShort,
  isActive
) => {
  try {
    const queryRoute = "/company/createCompany";

    const reqBody = {
      companyNameCh,
      companyNameEnFull,
      companyNameEnShort,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteOrRestoreCompanyFetch = async (
  token,
  companyId,
  typeOfComps
) => {
  try {
    const queryRoute = `/company/deleteOrRestoreCompany/${companyId}`;

    const reqBody = { typeOfComps };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editCompanyFetch = async (
  token,
  companyId,
  companyNameCh,
  companyNameEnFull,
  companyNameEnShort,
  isActive
) => {
  try {
    const queryRoute = `/company/editCompany/${companyId}`;

    const reqBody = {
      companyNameCh,
      companyNameEnFull,
      companyNameEnShort,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllCompaniesFetch = async (token, typeOfComps) => {
  try {
    const queryRoute = "/company/getAllCompanies";

    const reqBody = { typeOfComps };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getCompanyDetailsByIdFetch = async (token, companyId) => {
  try {
    const queryRoute = `/company/getCompanyDetailsById/${companyId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
