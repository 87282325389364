// Configs
import apisConfig from "../configs/apisConfig";

export const createContactFetch = async (
  token,
  shopId,
  contactTypeId,
  contactNum,
  isBackUp
) => {
  try {
    const queryRoute = "/contact/createContact";

    const reqBody = {
      shopId,
      contactTypeId,
      contactNum,
      isBackUp,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteContactFetch = async (token, contactId) => {
  try {
    const queryRoute = `/contact/deleteContact/${contactId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editContactFetch = async (
  token,
  contactId,
  contactTypeId,
  contactNum,
  isBackUp
) => {
  try {
    const queryRoute = `/contact/editContact/${contactId}`;

    const reqBody = {
      contactTypeId,
      contactNum,
      isBackUp,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllContactsByShopIdFetch = async (token, shopId) => {
  try {
    const queryRoute = `/contact/getAllContactsByShopId/${shopId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getContactDetailsByIdFetch = async (token, contactId) => {
  try {
    const queryRoute = `/contact/getContactDetailsById/${contactId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
