// Components
// Boxes
import ArrangementBox from "../../boxes/rostersPage/arrangementListModal/arrangementBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import ArrangementActionMenu from "../../menus/rostersPage/arrangementListModal/arrangementActionMenu";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteArrangementFetch,
  getAllArrangementsByDayIdFetch,
} from "../../../fetches/arrangementFetches";

// Helper Functions
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";
import { minutesToHoursAndMinutes } from "../../../helperFunctions/minutesToHoursAndMinutes";

// Material UI
// Components
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ArrangementListModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Functions
    displayArrangementActionModal,
    // Set States
    setArrangementId,
    // States
    arrangementId,
    arrangementFormSubmitCount,
    dayId,
  } = props;

  // Redux Store
  const divisionType = useSelector((state) => state.rostersPage.divisionType);
  const language = useSelector((state) => state.language.language);
  const shopId = useSelector((state) => state.rostersPage.shopId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [arrangements, setArrangements] = useState(null);
  // Dialog
  const [arrangementActionDialogText, setArrangementActionDialogText] =
    useState("");
  const [arrangementActionDialogType, setArrangementActionDialogType] =
    useState(null);
  const [showArrangementActionDialog, setShowArrangementActionDialog] =
    useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showArrangementActionMenu, setShowArrangementActionMenu] =
    useState(null);

  // Handle States
  const itemsArr = arrangements;

  // Events
  // Events - Boxes
  const onItemClicked = (itemId) => {
    // Set States
    setArrangementId(itemId);
    displayArrangementActionModal();
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    // Set States
    setArrangementId(itemId);
    setShowArrangementActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    // Set States
    setArrangementId(itemId);
    setShowArrangementActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onArrangementActionDialogCanceled = () => {
    // Set States
    setShowArrangementActionDialog(false);
  };

  const onArrangementActionDialogConfirmed = () => {
    switch (arrangementActionDialogType) {
      case "DeleteArrangement":
        deleteArrangement();
        break;
      default:
        break;
    }

    // Set States
    setShowArrangementActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Set States
    setArrangementId(null);
    displayArrangementActionModal();
  };

  // Functions
  // Functions - Normal
  const displayArrangementActionDialog = (arrangementActionType) => {
    // Set States
    setArrangementActionDialogType(arrangementActionType);

    switch (arrangementActionType) {
      case "DeleteArrangement":
        setArrangementActionDialogText(t("確認要刪除 安排 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowArrangementActionDialog(true);
  };

  // Functions - Mutations
  const deleteArrangement = async () => {
    const results = await deleteArrangementFetch(token, arrangementId);

    if (results.success) {
      getAllArrangementsByDayId();
    }
  };

  // Functions - Queries
  const getAllArrangementsByDayId = async () => {
    const results = await getAllArrangementsByDayIdFetch(
      token,
      dayId,
      divisionType,
      shopId
    );

    if (results.arrangements) {
      for (let item of results.arrangements) {
        item.start_time = item.start_time
          ? moment(item.start_time).format(t("a h:mm"))
          : null;
        item.lunch_time = item.lunch_time
          ? moment(item.lunch_time).format(t("a h:mm"))
          : null;
        item.after_lunch_time = item.after_lunch_time
          ? moment(item.after_lunch_time).format(t("a h:mm"))
          : null;
        item.end_time = item.end_time
          ? moment(item.end_time).format(t("a h:mm"))
          : null;
        item.start_time_difference = item.start_time_difference
          ? `${item.start_time_difference >= 0 ? t("延遲") : t("提早")} ${
              minutesToHoursAndMinutes(item.start_time_difference)[
                t("timeStrCh")
              ]
            }`
          : null;
        item.lunch_time_difference = item.lunch_time_difference
          ? `${item.lunch_time_difference >= 0 ? t("延遲") : t("提早")} ${
              minutesToHoursAndMinutes(item.lunch_time_difference)[
                t("timeStrCh")
              ]
            }`
          : null;
        item.after_lunch_time_difference = item.after_lunch_time_difference
          ? `${item.after_lunch_time_difference >= 0 ? t("延遲") : t("提早")} ${
              minutesToHoursAndMinutes(item.after_lunch_time_difference)[
                t("timeStrCh")
              ]
            }`
          : null;
        item.end_time_difference = item.end_time_difference
          ? `${item.end_time_difference >= 0 ? t("延遲") : t("提早")} ${
              minutesToHoursAndMinutes(item.end_time_difference)[t("timeStrCh")]
            }`
          : null;
      }
    }

    // Set States
    setArrangements(results.arrangements ? results.arrangements : null);
  };

  // Life Cycle
  useEffect(() => {
    getAllArrangementsByDayId();
  }, [arrangementFormSubmitCount, dayId, divisionType, shopId, language]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onArrangementActionDialogCanceled}
        onDialogConfirmed={onArrangementActionDialogConfirmed}
        // States
        dialogText={arrangementActionDialogText}
        showDialog={showArrangementActionDialog}
      />
      {/* Menu */}
      <ArrangementActionMenu
        // States
        showArrangementActionMenu={showArrangementActionMenu}
        // Set States
        setShowArrangementActionMenu={setShowArrangementActionMenu}
        // Fucntions
        displayArrangementActionDialog={displayArrangementActionDialog}
        displayArrangementActionModal={displayArrangementActionModal}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("特別安排")}
          </Typography>
          <Tooltip placement="right" title={t("新增安排")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid item xs={12}>
            {/* List */}
            {itemsArr ? (
              itemsArr.map((item) => (
                <ArrangementBox
                  key={item.id}
                  // States
                  divisionType={divisionType}
                  hoveredItemId={hoveredItemId}
                  item={item}
                  // Events
                  onItemClicked={onItemClicked}
                  onItemRightClicked={onItemRightClicked}
                  onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
                  onMouseEnterItem={onMouseEnterItem}
                  onMouseLeaveItem={onMouseLeaveItem}
                />
              ))
            ) : (
              <div className={classes.emptyDataContainer}>
                <Typography align={"center"} color={stylesConfig.greyTextColor}>
                  {t("未有相關資料")}
                </Typography>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default ArrangementListModal;
