// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Consts
import { monthOptions } from "../../../consts/staffProbationActionModalConsts";

// Fetches
import {
  editStaffProbationLengthFetch,
  getStaffBasicInfoByIdFetch,
} from "../../../fetches/staffFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function StaffProbationActionModal(props) {
  // Hooks
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
    // States
    staffId,
  } = props;

  // Redux Store
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [staffProbationActionDialogText, setStaffProbationActionDialogText] =
    useState("");
  const [staffProbationActionDialogType, setStaffProbationActionDialogType] =
    useState(null);
  const [showStaffProbationActionDialog, setShowStaffProbationActionDialog] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [probationLengthField, setProbationLengthField] = useState(null);

  // Events
  // Events - Dialogs
  const onStaffProbationActionDialogCanceled = () => {
    // Set States
    setShowStaffProbationActionDialog(false);
  };

  const onStaffProbationActionDialogConfirmed = () => {
    switch (staffProbationActionDialogType) {
      case "EditStaffProbation":
        editStaffProbationLength();
        break;
      default:
        break;
    }

    // Set States
    setShowStaffProbationActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "probationLengthField":
        setProbationLengthField(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!probationLengthField) {
      addToErrorFields("probationLengthField", "請先填寫 試用期");
      isError = true;
    }

    if (isError) {
      return;
    }

    displayStaffProbationActionDialog("EditStaffProbation");
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displayStaffProbationActionDialog = (staffProbationActionType) => {
    // Set States
    setStaffProbationActionDialogType(staffProbationActionType);

    switch (staffProbationActionType) {
      case "EditStaffProbation":
        setStaffProbationActionDialogText("確認要編輯 試用期 嗎？");
        break;
      default:
        break;
    }

    // Set States
    setShowStaffProbationActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const editStaffProbationLength = async () => {
    const results = await editStaffProbationLengthFetch(
      token,
      staffId,
      probationLengthField ? probationLengthField.value : null
    );

    if (results.success) {
      onModalClosed();
    } else {
      showFormAlert("error", "未能提交");
    }
  };

  // Functions = Queries
  const getStaffBasicInfoById = async () => {
    const results = await getStaffBasicInfoByIdFetch(token, staffId);

    if (results.staffBasicInfo) {
      const { probation_length } = results.staffBasicInfo;

      // Set States
      setProbationLengthField(
        monthOptions.find((item) => item.value === probation_length)
      );
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    if (staffId) {
      getStaffBasicInfoById();
    }
  }, [staffId]);

  return (
    <div className={classes.modalContainerSmall}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onStaffProbationActionDialogCanceled}
        onDialogConfirmed={onStaffProbationActionDialogConfirmed}
        // States
        dialogText={staffProbationActionDialogText}
        showDialog={showStaffProbationActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            編輯試用期
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid className={classes.formAutoCompleteContainer} item xs={12}>
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option.text}
              onChange={(event, value) =>
                onInputFieldChange("probationLengthField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={monthOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("probationLengthField")}
                  helperText={getErrorFieldMessage("probationLengthField")}
                  label="試用期"
                  variant="standard"
                />
              )}
              value={probationLengthField}
            />
          </Grid>
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              確定
            </Button>
          </Grid>
          {/* Alert */}
          {shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default StaffProbationActionModal;
