// Configs
import apisConfig from "../configs/apisConfig";

export const createWiFiFetch = async (
  token,
  ipAddress,
  shopOrWorkingPlaceId,
  typeOfData
) => {
  try {
    const queryRoute = "/wiFi/createWiFi";

    const reqBody = {
      ipAddress,
      shopOrWorkingPlaceId,
      typeOfData,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteWiFiFetch = async (token, wiFiId) => {
  try {
    const queryRoute = `/wiFi/deleteWiFi/${wiFiId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editWiFiFetch = async (token, wiFiId, ipAddress) => {
  try {
    const queryRoute = `/wiFi/editWiFi/${wiFiId}`;

    const reqBody = {
      ipAddress,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllWiFisByShopOrWorkingPlaceIdFetch = async (
  token,
  shopOrWorkingPlaceId,
  typeOfData
) => {
  try {
    const queryRoute = `/wiFi/getAllWiFisByShopOrWorkingPlaceId/${shopOrWorkingPlaceId}`;

    const reqBody = { typeOfData };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getWiFiDetailsByIdFetch = async (token, wiFiId) => {
  try {
    const queryRoute = `/wiFi/getWiFiDetailsById/${wiFiId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
