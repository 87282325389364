// Actions
import { onStaffChange } from "../../../redux/pages/staffProfilePage/staffProfilePageActions";

// Components
// Boxes
import RuleAttendanceStaffBox from "./ruleAttendanceStaffBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Modals
import ModalContainer from "../../modals/modalContainer";
import SelectStaffModal from "../../modals/search/selectStaffModal";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getAllStaffsWhereAttendanceNotRequiredWithPaginationFetch } from "../../../fetches/staffFetches";
import { editStaffIsAttendanceRequiredFetch } from "../../../fetches/staffFetches";

// Material UI
// Components
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import {
  containerSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNum;

function RuleAttendanceStaffBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.attendancesPage.formSubmitCount
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [checkedItemIdsArr, setCheckedItemIdsArr] = useState([]);
  const [checkedItemsArr, setCheckedItemsArr] = useState([]);
  const [staffs, setStaffs] = useState(null);
  // Dialog
  const [staffActionDialogText, setStaffActionDialogText] = useState("");
  const [staffActionDialogType, setStaffActionDialogType] = useState("");
  const [showStaffActionDialog, setShowStaffActionDialog] = useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Modals
  const [selectedModalItemIdsArr, setSelectedModalItemIdsArr] = useState(null);
  const [showSelectStaffModal, setShowSelectStaffModal] = useState(false);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  // Render
  const [shouldShowCheckedItemsOnly, setShouldShowCheckedItemsOnly] =
    useState(false);

  // Handle States
  const itemsArr = !shouldShowCheckedItemsOnly ? staffs : checkedItemsArr;

  // Events
  // Events - Box
  const onItemChecked = (item) => {
    const itemId = item.id;

    // Set States
    if (!checkedItemIdsArr.includes(itemId)) {
      setCheckedItemIdsArr((currentState) => [...currentState, itemId]);
      setCheckedItemsArr((currentState) => [...currentState, item]);
    } else {
      setCheckedItemIdsArr((currentState) =>
        currentState.filter((id) => id !== itemId)
      );
      setCheckedItemsArr((currentState) =>
        currentState.filter((item) => item.id !== itemId)
      );
    }
  };

  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onStaffChange(itemId));
    // Navigate
    navigate("/staffProfile");
  };

  // Events - Buttons
  const onSubmitBtnClicked = () => {
    displayStaffActionDialog("DeleteStaff");
  };

  // Events - Dialogs
  const onStaffActionDialogCanceled = () => {
    // Set States
    setShowStaffActionDialog(false);
  };

  const onStaffActionDialogConfirmed = () => {
    switch (staffActionDialogType) {
      case "AddStaff":
        addStaffToAttendanceNotRequiredList(selectedModalItemIdsArr);
        break;
      case "DeleteStaff":
        removeStaffFromAttendanceNotRequiredList();
        break;
      default:
        break;
    }

    // Set States
    setShowStaffActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onSelectStaffModalClosed = () => {
    // Set States
    setShowSelectStaffModal(false);
  };

  const onSelectStaffModalSubmitBtnClicked = (itemIdsArr) => {
    // Set States
    setSelectedModalItemIdsArr(itemIdsArr);
    displayStaffActionDialog("AddStaff");
  };

  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Events - Title
  const onAddStaffBtnClicked = () => {
    displaySelectStaffModal();
  };

  const onShowCheckedItemsOnlyBtnClicked = () => {
    // Set States
    setShouldShowCheckedItemsOnly(!shouldShowCheckedItemsOnly);
  };

  // Functions
  const displaySelectStaffModal = () => {
    setShowSelectStaffModal(true);
  };

  const displayStaffActionDialog = (staffActionType) => {
    // Set States
    setStaffActionDialogType(staffActionType);

    switch (staffActionType) {
      case "AddStaff":
        setStaffActionDialogText(t("確認要從新增 員工 到 無需簽到名單 嗎？"));
        break;
      case "DeleteStaff":
        setStaffActionDialogText(t("確認要從 無需簽到名單 刪除 員工 嗎？"));
        break;
      default:
        break;
    }

    setShowStaffActionDialog(true);
  };

  const resetList = () => {
    // Set States
    setCheckedItemIdsArr([]);
    setCheckedItemsArr([]);
  };

  const showAllItems = () => {
    // Set States
    setShouldShowCheckedItemsOnly(false);
  };

  // Functions - Mutations
  const addStaffToAttendanceNotRequiredList = async (staffIdsArr) => {
    const results = await editStaffIsAttendanceRequiredFetch(
      token,
      staffIdsArr,
      false
    );

    if (results.success) {
      getAllStaffsWhereAttendanceNotRequired();

      onSelectStaffModalClosed();
    }
  };

  const removeStaffFromAttendanceNotRequiredList = async () => {
    const results = await editStaffIsAttendanceRequiredFetch(
      token,
      checkedItemIdsArr,
      true
    );

    if (results.success) {
      resetList();
      getAllStaffsWhereAttendanceNotRequired();
    }
  };

  // Functions - Queries
  const getAllStaffsWhereAttendanceNotRequired = async () => {
    const results =
      await getAllStaffsWhereAttendanceNotRequiredWithPaginationFetch(
        token,
        pageNum,
        limitNum
      );

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setStaffs(results.staffs ? results.staffs : null);

    if (results.pageCount && !results.staffs) {
      setPageNum(results.pageCount);
    }
  };

  // Life Cycle
  useEffect(() => {
    resetList();
  }, [formSubmitCount]);

  useEffect(() => {
    getAllStaffsWhereAttendanceNotRequired();
  }, [formSubmitCount, pageNum]);

  useEffect(() => {
    if (!checkedItemIdsArr[0]) {
      showAllItems();
    }
  }, [checkedItemIdsArr]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onStaffActionDialogCanceled}
        onDialogConfirmed={onStaffActionDialogConfirmed}
        // States
        dialogText={staffActionDialogText}
        showDialog={showStaffActionDialog}
      />
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onSelectStaffModalClosed}
        // States
        showModal={showSelectStaffModal}
      >
        <SelectStaffModal
          // Events
          onModalSubmitBtnClicked={onSelectStaffModalSubmitBtnClicked}
          // States
          paramsArr={["AttendanceRule"]}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("無需簽到員工")}
          </Typography>
          <Tooltip placement="right" title={t("新增員工A")}>
            <IconButton color={"primary"} onClick={onAddStaffBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
        {checkedItemIdsArr[0] && (
          <Button
            endIcon={<KeyboardArrowDownRoundedIcon />}
            onClick={onShowCheckedItemsOnlyBtnClicked}
            variant="text"
          >
            {`${t("顯示")}${
              shouldShowCheckedItemsOnly ? t("所有") : t("已選擇")
            }${t(" 員工s")}`}
          </Button>
        )}
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item) => (
            <RuleAttendanceStaffBox
              key={item.id}
              // States
              checkedItemIdsArr={checkedItemIdsArr}
              hoveredItemId={hoveredItemId}
              item={item}
              // Events
              onItemChecked={onItemChecked}
              onItemClicked={onItemClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
          {checkedItemIdsArr[0] && (
            <div className={classes.containerSubmitBtnBox}>
              <Button
                onClick={onSubmitBtnClicked}
                variant="contained"
                sx={containerSubmitBtnStyles}
              >
                {t("移除")}
              </Button>
            </div>
          )}
          {!shouldShowCheckedItemsOnly && (
            <Stack className={classes.paginationStack} spacing={2}>
              <Pagination
                color={"primary"}
                count={pageCount}
                onChange={(event, page) => onPageChange(page)}
                page={pageNum}
              />
            </Stack>
          )}
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有員工資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default RuleAttendanceStaffBoxesContainer;
