// Components
import StyledMenu from "../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import PushPinRoundedIcon from "@mui/icons-material/PushPinRounded";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";

// React-Redux
import { useSelector } from "react-redux";

function WorkingPlaceActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // States
    selectedWorkingPlace,
    showWorkingPlaceActionMenu,
    // Set States
    setSelectedWorkingPlace,
    setShowWorkingPlaceActionMenu,
    setWorkingPlaceActionDialogType,
    // Functions
    displayWorkingPlaceActionDialog,
  } = props;

  // Redux Store
  const typeOfWorkingPeriodsAndPlaces = useSelector(
    (state) => state.workingPeriodsAndPlacesPage.typeOfWorkingPeriodsAndPlaces
  );

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowWorkingPlaceActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      // 刪除
      case "Delete":
        // Set States
        setWorkingPlaceActionDialogType("DeleteWorkingPlace");
        setSelectedWorkingPlace(selectedWorkingPlace);

        displayWorkingPlaceActionDialog("DeleteWorkingPlace");
        break;
      // 設為一般地點
      case "RemoveDefault":
        // Set States
        setWorkingPlaceActionDialogType("RemoveWorkingPlaceDefault");
        setSelectedWorkingPlace(selectedWorkingPlace);

        displayWorkingPlaceActionDialog("RemoveWorkingPlaceDefault");
        break;
      // 還原
      case "Restore":
        // Set States
        setWorkingPlaceActionDialogType("RestoreWorkingPlace");
        setSelectedWorkingPlace(selectedWorkingPlace);

        displayWorkingPlaceActionDialog("RestoreWorkingPlace");
        break;
      // 設為預設地點
      case "SetDefault":
        // Set States
        setWorkingPlaceActionDialogType("SetWorkingPlaceDefault");
        setSelectedWorkingPlace(selectedWorkingPlace);

        displayWorkingPlaceActionDialog("SetWorkingPlaceDefault");
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showWorkingPlaceActionMenu}
      open={Boolean(showWorkingPlaceActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      {typeOfWorkingPeriodsAndPlaces === "Current" &&
        selectedWorkingPlace &&
        (selectedWorkingPlace.is_default ? (
          <MenuItem
            disableRipple
            onClick={() => onMenuItemClicked("RemoveDefault")}
          >
            <ReplayRoundedIcon />
            {t("設為一般地點")}
          </MenuItem>
        ) : (
          <MenuItem
            disableRipple
            onClick={() => onMenuItemClicked("SetDefault")}
          >
            <PushPinRoundedIcon />
            {t("設為預設地點")}
          </MenuItem>
        ))}
      {typeOfWorkingPeriodsAndPlaces === "Current" ? (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Delete")}>
          <DeleteRoundedIcon />
          {t("刪除")}
        </MenuItem>
      ) : (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Restore")}>
          <RestoreRoundedIcon />
          {t("還原")}
        </MenuItem>
      )}
    </StyledMenu>
  );
}

export default WorkingPlaceActionMenu;
