// Components
// Boxes
import WorkingHourBox from "./workingHourBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useHorizontalItemBoxStyles } from "../../../styles/componentStyles/boxStyles/horizontalItemBoxStyles";

function WorkingPeriodAndShiftAndDayOffTypeBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useHorizontalItemBoxStyles();

  // Props
  const {
    // States
    hoveredDayOffTypeId,
    hoveredItemId,
    hoveredShiftId,
    hoveredWorkingPeriodId,
    item,
    typeOfContent,
    // Events
    onItemClicked,
    onItemRightClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isDayOffTypeHovered =
    typeOfContent === "DayOffTypes" && hoveredDayOffTypeId === item.id;
  const isHovered = hoveredItemId && hoveredItemId === item.id;
  const isShiftHovered =
    typeOfContent === "Shifts" && hoveredShiftId === item.id;
  const isWorkingPeriodHovered =
    typeOfContent === "WorkingPeriods" && hoveredWorkingPeriodId === item.id;

  return (
    <div
      className={
        isWorkingPeriodHovered || isShiftHovered || isDayOffTypeHovered
          ? classes.selectedItemContainer
          : isHovered
          ? classes.hoveredItemContainer
          : classes.itemContainer
      }
      onMouseEnter={() => onMouseEnterItem(item.id)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div
        className={classes.itemContentContainer}
        onClick={() => onItemClicked(item.id)}
        onContextMenu={(event) => {
          event.preventDefault();
          onItemRightClicked(item.id, event.currentTarget);
        }}
      >
        <div className={classes.itemContentSubContainer}>
          <Typography
            align={"left"}
            color={
              isWorkingPeriodHovered || isShiftHovered || isDayOffTypeHovered
                ? stylesConfig.whiteTextColor
                : isHovered
                ? stylesConfig.mainTextColor
                : null
            }
            variant="body1"
          >
            {typeOfContent === "WorkingPeriods"
              ? item.working_period_code
              : typeOfContent === "Shifts"
              ? item.shift_code
              : item.day_off_type_name_en_short}
          </Typography>
          {typeOfContent === "WorkingPeriods" &&
            item.workingHours &&
            item.workingHours[0] &&
            item.workingHours.map((workingHourItem) => (
              <WorkingHourBox
                key={workingHourItem.id}
                // States
                hoveredItemId={hoveredItemId}
                hoveredWorkingPeriodId={hoveredWorkingPeriodId}
                item={workingHourItem}
                typeOfContent={typeOfContent}
                workingHourId={item.id}
              />
            ))}
          {["Shifts", "DayOffTypes"].includes(typeOfContent) && (
            <Typography
              align={"left"}
              color={
                isShiftHovered || isDayOffTypeHovered
                  ? stylesConfig.whiteTextColor
                  : stylesConfig.greyTextColor
              }
              variant="body2"
            >
              {typeOfContent === "Shifts"
                ? `${item.default_start_time} ${t("至")} ${
                    item.default_end_time
                  }`
                : item[t("day_off_type_name_ch")]}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}

export default WorkingPeriodAndShiftAndDayOffTypeBox;
