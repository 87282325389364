// Components
// Boxes
import ApplicationBoxContentApplicant from "./applicationBoxContentApplicant";
import ApplicationBoxContentReceiver from "./applicationBoxContentReceiver";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
// Icons
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function ApplicationBox(props) {
  // Hooks
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    boxType,
    currentItemId,
    hoveredItemId,
    isMenuAvailable,
    item,
    // Events
    onItemClicked,
    onItemRightClicked,
    onItemShowMenuBtnClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isHovered = hoveredItemId && hoveredItemId === item.id;
  const isSelected = currentItemId && currentItemId === item.id;

  return (
    <div
      className={
        isSelected
          ? classes.selectedItemContainer
          : isHovered
          ? classes.hoveredItemContainer
          : classes.itemContainer
      }
      onClick={() => onItemClicked(item.id, item.application_type_id)}
      onContextMenu={(event) => {
        if (isMenuAvailable) {
          event.preventDefault();
          onItemRightClicked(
            item.id,
            item.application_type_id,
            event.currentTarget
          );
        }
      }}
      onMouseEnter={() => onMouseEnterItem(item.id)}
      onMouseLeave={onMouseLeaveItem}
    >
      {boxType === "Applications" ? (
        <ApplicationBoxContentApplicant
          // States
          isHovered={isHovered}
          isSelected={isSelected}
          item={item}
        />
      ) : (
        <ApplicationBoxContentReceiver
          // States
          isHovered={isHovered}
          isSelected={isSelected}
          item={item}
        />
      )}
      <Stack direction="row" spacing={1}>
        <IconButton
          disabled={!isMenuAvailable}
          onClick={(event) => {
            onItemShowMenuBtnClicked(
              item.id,
              item.application_type_id,
              event.currentTarget
            );
          }}
          sx={isSelected ? { color: stylesConfig.whiteTextColor } : null}
        >
          <MenuRoundedIcon />
        </IconButton>
      </Stack>
    </div>
  );
}

export default ApplicationBox;
