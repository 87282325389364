// Actions
import {
  onFormSubmitCountIncrease,
  onRelocationChange,
} from "../../../redux/pages/staffProfilePage/staffProfilePageActions";

// Components
// Boxes
import StaffRelocationBox from "./staffRelocationBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteRelocationFetch,
  getAllRelocationsByStaffIdFetch,
} from "../../../fetches/relocationFetches";

// Helper Functions
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function StaffRelocationBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();

  // Redux Store
  const tabItem = useSelector((state) => state.staffProfilePage.tabItem);
  const formSubmitCount = useSelector(
    (state) => state.staffProfilePage.formSubmitCount
  );
  const language = useSelector((state) => state.language.language);
  const relocationId = useSelector(
    (state) => state.staffProfilePage.relocationId
  );
  const staffId = useSelector((state) => state.staffProfilePage.staffId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [staffRelocations, setStaffRelocations] = useState(null);
  // Dialog
  const [staffRelocationActionDialogText, setStaffRelocationActionDialogText] =
    useState("");
  const [staffRelocationActionDialogType, setStaffRelocationActionDialogType] =
    useState(null);
  const [showStaffRelocationActionDialog, setShowStaffRelocationActionDialog] =
    useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);

  // Handle States
  const currentItemId = relocationId;
  const itemsArr = staffRelocations;

  // Events
  // Events - Box
  const onDeleteItemBtnClicked = (itemId, itemIsOnlyRelocation) => {
    onItemClicked(itemId);
    displayStaffRelocationActionDialog(
      itemIsOnlyRelocation
        ? "CancelRelocationAndDeleteStaff"
        : "CancelRelocation"
    );
  };

  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onRelocationChange(itemId));
  };

  // Events - Dialogs
  const onStaffRelocationActionDialogCanceled = () => {
    // Set States
    setShowStaffRelocationActionDialog(false);
  };

  const onStaffRelocationActionDialogConfirmed = () => {
    switch (staffRelocationActionDialogType) {
      case "CancelRelocation":
        deleteRelocation();
        break;
      case "CancelRelocationAndDeleteStaff":
        deleteRelocation();
        break;
      default:
        break;
    }

    // Set States
    setShowStaffRelocationActionDialog(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Update Redux Store
    dispatch(onRelocationChange(null));
  };

  // Functions
  // Functions - Normal
  const displayStaffRelocationActionDialog = (staffRelocationActionType) => {
    // Set States
    setStaffRelocationActionDialogType(staffRelocationActionType);

    switch (staffRelocationActionType) {
      case "CancelRelocation":
        setStaffRelocationActionDialogText(t("確認要取消 調動 嗎？"));
        break;
      case "CancelRelocationAndDeleteStaff":
        setStaffRelocationActionDialogText(
          t("確認要取消 調動 並刪除 員工 嗎？")
        );
        break;
      default:
        break;
    }

    // Set States
    setShowStaffRelocationActionDialog(true);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  // Functions - Mutations
  const deleteRelocation = async () => {
    if (currentItemId) {
      const results = await deleteRelocationFetch(token, currentItemId);

      if (results.success) {
        getAllRelocationsByStaffId(true);
      }
    }
  };

  // Functions = Queries
  const getAllRelocationsByStaffId = async (
    shoulddUpdateCurrentRelocationId
  ) => {
    const results = await getAllRelocationsByStaffIdFetch(token, staffId);

    // Format Date
    if (results.relocations) {
      for (let item of results.relocations) {
        item.relocation_date = moment(item.relocation_date).format(
          t("YYYY年 MM月 DD日")
        );
      }
    }

    // Set States
    setStaffRelocations(results.relocations ? results.relocations : null);

    // Update Redux Store
    if (results.relocations) {
      if (
        shoulddUpdateCurrentRelocationId ||
        (currentItemId &&
          !results.relocations.some((item) => item.id === currentItemId))
      ) {
        dispatch(onRelocationChange(results.relocations[0].id));
      }
    } else {
      dispatch(onRelocationChange(null));
      // Navigate
      navigate("/deptsAndShops");
    }
  };

  // Life Cycle
  useEffect(() => {
    if (tabItem === "Relocations" && staffId) {
      getAllRelocationsByStaffId(currentItemId ? false : true);
    }
  }, [tabItem, formSubmitCount, staffId]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onStaffRelocationActionDialogCanceled}
        onDialogConfirmed={onStaffRelocationActionDialogConfirmed}
        // States
        dialogText={staffRelocationActionDialogText}
        showDialog={showStaffRelocationActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("調動紀錄s")}
          </Typography>
          <Tooltip placement="right" title={t("新增調動")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <StaffRelocationBox
            key={item.id}
            // States
            currentItemId={currentItemId}
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onDeleteItemBtnClicked={onDeleteItemBtnClicked}
            onItemClicked={onItemClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有調動紀錄")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default StaffRelocationBoxesContainer;
