// Actions
import { onNumOfStaffChartActiveIndexChange } from "../../../redux/pages/dashboardPage/dashboardPageActions";

// Components
// Boxes
import NumOfStaffBoxesContainer from "./numOfStaffBoxesContainer";
// Charts
import NumOfStaffChartContainer from "../../charts/dahsboardPage/numOfStaffChartContainer";
// Spacing Boxes
import SpacingBox from "../spacing/spacingBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Fetches
import { getNumOfStaffGroupedFetch } from "../../../fetches/staffStatisticsFetches";

// Material UI
// Components
import Grid from "@mui/material/Grid";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function ContentContainer() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const statisticsTypeValue = useSelector(
    (state) => state.dashboardPage.conditionStatisticsTypeValue
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [numOfStaffs, setNumOfStaff] = useState(null);

  // Handle States
  const itemsArr = numOfStaffs;

  // Functions
  // Functions = Queries
  const getNumOfStaffGrouped = async () => {
    const results = await getNumOfStaffGroupedFetch(token, statisticsTypeValue);

    // Set States
    setNumOfStaff(results.numOfStaffs ? results.numOfStaffs : null);

    // Update Redux Store
    dispatch(onNumOfStaffChartActiveIndexChange(0));
  };

  // Life Cycle
  useEffect(() => {
    if (statisticsTypeValue) {
      getNumOfStaffGrouped();
    }
  }, [statisticsTypeValue]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={8}>
        <NumOfStaffChartContainer itemsArr={itemsArr} />
        <SpacingBox
          // Render
          height={stylesConfig.spacingBoxMarginBottom}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <NumOfStaffBoxesContainer itemsArr={itemsArr} />
        <SpacingBox
          // Render
          height={stylesConfig.spacingBoxMarginBottom}
        />
      </Grid>
    </Grid>
  );
}

export default ContentContainer;
