// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import TitleBoxesContainer from "../components/boxes/titlesPage/titleBoxesContainer";
// Forms
import TitleDetailForm from "../components/forms/titlesPage/titleDetailForm";
// Headers
import TitlesPageHeader from "../components/headers/titlesPage/titlesPageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch } from "react-redux";

function TitlesPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("Titles"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <TitlesPageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          <TitleBoxesContainer />
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <TitleDetailForm />
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default TitlesPage;
