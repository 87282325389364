// Actions
import {
  onProcedureApplicationProcedureChange,
  onProcedureApplicationProcedureItemChange,
  onProcedureApplicationTypeChange,
  onProcedureApplicationTypeItemChange,
} from "../../../redux/pages/applicationsPage/applicationsPageActions";

// Components
// Boxes
import ApplicationProcedureBox from "./applicationProcedureBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import ApplicationProcedureActionMenu from "../../menus/applicationsPage/applicationProcedureActionMenu";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteApplicationProcedureFetch,
  getAllApplicationProceduresByApplicationTypeIdFetch,
} from "../../../fetches/applicationProcedureFetches";
import { getAllApplicationTypesFetch } from "../../../fetches/applicationTypeFetches";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ApplicationProcedureBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const applicationProcedureId = useSelector(
    (state) => state.applicationsPage.procedureApplicationProcedureId
  );
  const applicationTypeId = useSelector(
    (state) => state.applicationsPage.procedureApplicationTypeId
  );
  const applicationTypeItem = useSelector(
    (state) => state.applicationsPage.procedureApplicationTypeItem
  );
  const formSubmitCount = useSelector(
    (state) => state.applicationsPage.formSubmitCount
  );
  const language = useSelector((state) => state.language.language);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [applicationProcedures, setApplicationProcedures] = useState(null);
  const [applicationTypes, setApplicationTypes] = useState(null);
  // Dialog
  const [
    applicationProcedureActionDialogText,
    setApplicationProcedureActionDialogText,
  ] = useState("");
  const [
    applicationProcedureActionDialogType,
    setApplicationProcedureActionDialogType,
  ] = useState(null);
  const [
    showApplicationProcedureActionDialog,
    setShowApplicationProcedureActionDialog,
  ] = useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [
    showApplicationProcedureActionMenu,
    setShowApplicationProcedureActionMenu,
  ] = useState(null);

  // Handle States
  const currentItemId = applicationProcedureId;
  const itemsArr = applicationProcedures;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onProcedureApplicationProcedureChange(itemId));
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowApplicationProcedureActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowApplicationProcedureActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onApplicationProcedureActionDialogCanceled = () => {
    // Set States
    setShowApplicationProcedureActionDialog(false);
  };

  const onApplicationProcedureActionDialogConfirmed = () => {
    switch (applicationProcedureActionDialogType) {
      case "DeleteApplicationProcedure":
        deleteApplicationProcedure();
        break;
      default:
        break;
    }

    // Set States
    setShowApplicationProcedureActionDialog(false);
  };

  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "applicationType":
        dispatch(onProcedureApplicationTypeChange(value.id));
        break;
      default:
        break;
    }
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Update Redux Store
    dispatch(onProcedureApplicationProcedureChange(null));
  };

  // Functions
  // Functions - Normal
  const displayApplicationProcedureActionDialog = (
    applicationProcedureActionType
  ) => {
    // Set States
    setApplicationProcedureActionDialogType(applicationProcedureActionType);

    switch (applicationProcedureActionType) {
      case "DeleteApplicationProcedure":
        setApplicationProcedureActionDialogText(t("確認要刪除 程序 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowApplicationProcedureActionDialog(true);
  };

  // Functions - Mutations
  const deleteApplicationProcedure = async () => {
    const results = await deleteApplicationProcedureFetch(token, currentItemId);

    if (results.success) {
      getAllApplicationProcedures(true);
    }
  };

  // Functions = Queries
  const getAllApplicationProcedures = async (
    shoulddUpdateCurrentApplicationProcedureId
  ) => {
    const results = await getAllApplicationProceduresByApplicationTypeIdFetch(
      token,
      applicationTypeId
    );

    // Set States
    setApplicationProcedures(
      results.applicationProcedures ? results.applicationProcedures : null
    );

    // Update Redux Store
    if (
      results.applicationProcedures &&
      (shoulddUpdateCurrentApplicationProcedureId ||
        !results.applicationProcedures.some(
          (item) => item.id === applicationProcedureId
        ))
    ) {
      dispatch(
        onProcedureApplicationProcedureChange(
          results.applicationProcedures[0].id
        )
      );
    }
  };

  const getAllApplicationTypes = async () => {
    const results = await getAllApplicationTypesFetch(token);

    // Set States
    setApplicationTypes(
      results.applicationTypes ? results.applicationTypes : null
    );

    // Update Redux Store
    if (results.applicationTypes && !applicationTypeId) {
      dispatch(
        onProcedureApplicationTypeChange(results.applicationTypes[0].id)
      );
    }
  };

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    if (applicationTypes && applicationTypeId) {
      dispatch(
        onProcedureApplicationTypeItemChange(
          applicationTypes.find((item) => item.id === applicationTypeId)
        )
      );
    }
  }, [applicationTypes, applicationTypeId]);

  useEffect(() => {
    getAllApplicationTypes();
  }, [formSubmitCount]);

  useEffect(() => {
    // Update Redux Store
    if (applicationProcedures && applicationProcedureId) {
      dispatch(
        onProcedureApplicationProcedureItemChange(
          applicationProcedures.find(
            (item) => item.id === applicationProcedureId
          )
        )
      );
    }
  }, [applicationProcedures, applicationProcedureId]);

  useEffect(() => {
    if (applicationTypeId) {
      getAllApplicationProcedures(applicationProcedureId ? false : true);
    }
  }, [applicationTypeId, formSubmitCount]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onApplicationProcedureActionDialogCanceled}
        onDialogConfirmed={onApplicationProcedureActionDialogConfirmed}
        // States
        dialogText={applicationProcedureActionDialogText}
        showDialog={showApplicationProcedureActionDialog}
      />
      {/* Menu */}
      <ApplicationProcedureActionMenu
        // States
        showApplicationProcedureActionMenu={showApplicationProcedureActionMenu}
        // Set States
        setShowApplicationProcedureActionMenu={
          setShowApplicationProcedureActionMenu
        }
        // Fucntions
        displayApplicationProcedureActionDialog={
          displayApplicationProcedureActionDialog
        }
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("申請類別")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      {applicationTypes && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) => option[t("application_type_name_ch")]}
            onChange={(event, value) =>
              onInputFieldChange("applicationType", value)
            }
            options={applicationTypes}
            renderInput={(params) => (
              <TextField {...params} label={t("申請類別")} variant="outlined" />
            )}
            value={applicationTypeItem}
          />
        </div>
      )}
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("程序s")}
          </Typography>
          <Tooltip placement="right" title={t("新增程序")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <ApplicationProcedureBox
            key={item.id}
            // States
            currentItemId={currentItemId}
            hoveredItemId={hoveredItemId}
            language={language}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有程序資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default ApplicationProcedureBoxesContainer;
