// Configs
import apisConfig from "../configs/apisConfig";

export const createEducationFetch = async (
  token,
  candidateId,
  instituteName,
  degreeId,
  degreeName,
  startMonth,
  endMonth
) => {
  try {
    const queryRoute = "/education/createEducation";

    const reqBody = {
      candidateId,
      instituteName,
      degreeId,
      degreeName,
      startMonth,
      endMonth,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteEducationFetch = async (token, educationId) => {
  try {
    const queryRoute = `/education/deleteEducation/${educationId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editEducationFetch = async (
  token,
  educationId,
  instituteName,
  degreeId,
  degreeName,
  startMonth,
  endMonth
) => {
  try {
    const queryRoute = `/education/editEducation/${educationId}`;

    const reqBody = {
      instituteName,
      degreeId,
      degreeName,
      startMonth,
      endMonth,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllEducationsByCandidateIdFetch = async (
  token,
  candidateId
) => {
  try {
    const queryRoute = `/education/getAllEducationsByCandidateId/${candidateId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getEducationDetailsByIdFetch = async (token, educationId) => {
  try {
    const queryRoute = `/education/getEducationDetailsById/${educationId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
