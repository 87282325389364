export const monthsToYearsAndMonths = (language, timeInMonths) => {
  const years = Math.floor(Math.abs(timeInMonths) / 12);
  const months = Math.abs(timeInMonths) % 12;

  let yearDisplayStr, monthDisplayStr;

  switch (language) {
    case "en-us":
      yearDisplayStr =
        years > 0
          ? (yearDisplayStr = years > 1 ? `${years} Years` : `${years} Year`)
          : "";
      monthDisplayStr =
        months > 0
          ? years > 0
            ? (monthDisplayStr =
                months > 1 ? `& ${months} Months` : `& ${months} Month`)
            : (monthDisplayStr =
                months > 1 ? `${months} Months` : `${months} Month`)
          : "";
      break;
    case "zh-hk":
      yearDisplayStr = years > 0 ? `${years} 年` : "";
      monthDisplayStr = months > 0 ? `${months} 個月` : "";
      break;
    default:
      break;
  }

  const spacing = years > 0 && months > 0 ? " " : "";

  return yearDisplayStr + spacing + monthDisplayStr;
};
