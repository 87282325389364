// Actions
import { onRuleAttendanceTypeChange } from "../../../redux/pages/attendancesPage/attendancesPageActions";

// Components
// Boxes
import RuleAttendanceTypeBox from "./ruleAttendanceTypeBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getAllAttendanceTypesFetch } from "../../../fetches/attendanceTypeFetches";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function RuleAttendanceTypeBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.attendancesPage.formSubmitCount
  );
  const ruleAttendanceTypeId = useSelector(
    (state) => state.attendancesPage.ruleAttendanceTypeId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [attendanceTypes, setAttendanceTypes] = useState(null);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);

  // Handle States
  const currentItemId = ruleAttendanceTypeId;
  const itemsArr = attendanceTypes;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onRuleAttendanceTypeChange(itemId));
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Functions
  // Functions = Queries
  const getAllAttendanceTypes = async (
    shoulddUpdateCurrentAttendanceTypeId
  ) => {
    const results = await getAllAttendanceTypesFetch(token);

    // Set States
    setAttendanceTypes(
      results.attendanceTypes ? results.attendanceTypes : null
    );

    // Update Redux Store
    if (shoulddUpdateCurrentAttendanceTypeId) {
      dispatch(
        onRuleAttendanceTypeChange(
          results.attendanceTypes ? results.attendanceTypes[0].id : null
        )
      );
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllAttendanceTypes(currentItemId ? false : true);
  }, [formSubmitCount]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("簽到類別")}
          </Typography>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <RuleAttendanceTypeBox
            key={item.id}
            // States
            currentItemId={currentItemId}
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有簽到規則資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default RuleAttendanceTypeBoxesContainer;
