export function onBrandChange(brandId) {
  return {
    type: "ON_DEPTS_AND_SHOPS_PAGE_BRAND_CHANGE",
    brandId,
  };
}

export function onBrandItemChange(brandItem) {
  return {
    type: "ON_DEPTS_AND_SHOPS_PAGE_BRAND_ITEM_CHANGE",
    brandItem,
  };
}

export function onDepartmentChange(departmentId) {
  return {
    type: "ON_DEPTS_AND_SHOPS_PAGE_DEPARTMENT_CHANGE",
    departmentId,
  };
}

export function onDivisionChange(divisionId, divisionType) {
  return {
    type: "ON_DEPTS_AND_SHOPS_PAGE_DIVISION_CHANGE",
    divisionId,
    divisionType,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_DEPTS_AND_SHOPS_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onShopChange(shopId) {
  return {
    type: "ON_DEPTS_AND_SHOPS_PAGE_SHOP_CHANGE",
    shopId,
  };
}

export function onTypeOfContentChange(typeOfContent) {
  return {
    type: "ON_DEPTS_AND_SHOPS_PAGE_TYPE_OF_CONTENT_CHANGE",
    typeOfContent,
  };
}

export function onTypeOfDeptsAndShopsChange(typeOfDeptsAndShops) {
  return {
    type: "ON_DEPTS_AND_SHOPS_PAGE_TYPE_OF_DEPTS_AND_SHOPS_CHANGE",
    typeOfDeptsAndShops,
  };
}

export function onTypeOfStaffsChange(typeOfStaffs) {
  return {
    type: "ON_DEPTS_AND_SHOPS_PAGE_TYPE_OF_STAFFS_CHANGE",
    typeOfStaffs,
  };
}
