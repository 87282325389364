export function onFormSubmitCountIncrease() {
  return {
    type: "ON_WORKING_PERIODS_AND_PLACES_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_WORKING_PERIODS_AND_PLACES_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}

export function onTypeOfWorkingPeriodsAndPlacesChange(
  typeOfWorkingPeriodsAndPlaces
) {
  return {
    type: "ON_WORKING_PERIODS_AND_PLACES_PAGE_TYPE_OF_WORKING_PERIODS_CHANGE",
    typeOfWorkingPeriodsAndPlaces,
  };
}

export function onWorkingPeriodChange(workingPeriodId) {
  return {
    type: "ON_WORKING_PERIODS_AND_PLACES_PAGE_WORKING_PERIOD_CHANGE",
    workingPeriodId,
  };
}

export function onWorkingPeriodItemChange(workingPeriodItem) {
  return {
    type: "ON_WORKING_PERIODS_AND_PLACES_PAGE_WORKING_PERIOD_ITEM_CHANGE",
    workingPeriodItem,
  };
}

export function onWorkingPlaceChange(workingPlaceId) {
  return {
    type: "ON_WORKING_PERIODS_AND_PLACES_PAGE_WORKING_PLACE_CHANGE",
    workingPlaceId,
  };
}

export function onWorkingPlaceItemChange(workingPlaceItem) {
  return {
    type: "ON_WORKING_PERIODS_AND_PLACES_PAGE_WORKING_PLACE_ITEM_CHANGE",
    workingPlaceItem,
  };
}

export function onWorkingPlaceTypeChange(workingPlaceTypeId) {
  return {
    type: "ON_WORKING_PERIODS_AND_PLACES_PAGE_WORKING_PLACE_TYPE_CHANGE",
    workingPlaceTypeId,
  };
}

export function onWorkingPlaceTypeItemChange(workingPlaceTypeItem) {
  return {
    type: "ON_WORKING_PERIODS_AND_PLACES_PAGE_WORKING_PLACE_TYPE_ITEM_CHANGE",
    workingPlaceTypeItem,
  };
}
