export function onConditionStatisticsTypeChange(conditionStatisticsTypeValue) {
  return {
    type: "ON_DASHBOARD_PAGE_CONDITION_STATISTICS_TYPE_CHANGE",
    conditionStatisticsTypeValue,
  };
}

export function onConditionStatisticsTypeItemChange(
  conditionStatisticsTypeItem
) {
  return {
    type: "ON_DASHBOARD_PAGE_CONDITION_STATISTICS_TYPE_ITEM_CHANGE",
    conditionStatisticsTypeItem,
  };
}

export function onNumOfStaffChartActiveIndexChange(numOfStaffChartActiveIndex) {
  return {
    type: "ON_DASHBOARD_PAGE_NUM_OF_STAFF_CHART_ACTIVE_INDEX_CHANGE",
    numOfStaffChartActiveIndex,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_DASHBOARD_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}
