// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/candidateProfilePage/candidateProfilePageActions";

// Components
// Boxes
import CandidateSkillBox from "./candidateSkillBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import CandidateSkillActionMenu from "../../menus/candidateProfilePage/candidateSkillActionMenu";
// Modals
import CandidateSkillActionModal from "../../modals/candidateProfilePage/candidateSkillActionModal";
import FileViewModal from "../../modals/candidateProfilePage/fileViewModal";
import ModalContainer from "../../modals/modalContainer";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteSkillFetch,
  getAllSkillsByCandidateIdFetch,
} from "../../../fetches/skillFetches";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function CandidateSkillBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const candidateId = useSelector(
    (state) => state.candidateProfilePage.candidateId
  );
  const formSubmitCount = useSelector(
    (state) => state.candidateProfilePage.formSubmitCount
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [candidateSkills, setCandidateSkills] = useState(null);
  // Dialog
  const [candidateSkillActionDialogText, setCandidateSkillActionDialogText] =
    useState("");
  const [candidateSkillActionDialogType, setCandidateSkillActionDialogType] =
    useState(null);
  const [showCandidateSkillActionDialog, setShowCandidateSkillActionDialog] =
    useState(false);
  // Hover
  const [currentItemId, setCurrentItemId] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showCandidateSkillActionMenu, setShowCandidateSkillActionMenu] =
    useState(null);
  // Modals
  const [showCandidateSkillActionModal, setShowCandidateSkillActionModal] =
    useState(false);
  const [showFileViewModal, setShowFileViewModal] = useState(false);

  // Handle States
  const itemsArr = candidateSkills;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Set States
    setCurrentItemId(itemId);
    displayCandidateSkillActionModal();
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setShowCandidateSkillActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setShowCandidateSkillActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onCandidateSkillActionDialogCanceled = () => {
    // Set States
    setShowCandidateSkillActionDialog(false);
  };

  const onCandidateSkillActionDialogConfirmed = () => {
    switch (candidateSkillActionDialogType) {
      case "DeleteCandidateSkill":
        deleteSkill();
        break;
      default:
        break;
    }

    // Set States
    setShowCandidateSkillActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onCandidateSkillActionModalClosed = () => {
    // Set States
    setShowCandidateSkillActionModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  const onFileViewModalClosed = () => {
    // Set States
    setShowFileViewModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Set States
    setCurrentItemId(null);
    displayCandidateSkillActionModal();
  };

  // Functions
  // Functions - Normal
  const displayCandidateSkillActionDialog = (candidateSkillActionType) => {
    // Set States
    setCandidateSkillActionDialogType(candidateSkillActionType);

    switch (candidateSkillActionType) {
      case "DeleteCandidateSkill":
        setCandidateSkillActionDialogText(t("確認要刪除 技能 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowCandidateSkillActionDialog(true);
  };

  const displayCandidateSkillActionModal = () => {
    setShowCandidateSkillActionModal(true);
  };

  const displayFileViewModal = () => {
    setShowFileViewModal(true);
  };

  // Functions - Mutations
  const deleteSkill = async () => {
    const results = await deleteSkillFetch(token, currentItemId);

    if (results.success) {
      getAllSkillsByCandidateId();
    }
  };

  // Functions - Queries
  const getAllSkillsByCandidateId = async () => {
    const results = await getAllSkillsByCandidateIdFetch(token, candidateId);

    // Set States
    setCandidateSkills(results.skills ? results.skills : null);
  };

  // Life Cycle
  useEffect(() => {
    if (candidateId) {
      getAllSkillsByCandidateId();
    }
  }, [formSubmitCount, , candidateId]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onCandidateSkillActionDialogCanceled}
        onDialogConfirmed={onCandidateSkillActionDialogConfirmed}
        // States
        dialogText={candidateSkillActionDialogText}
        showDialog={showCandidateSkillActionDialog}
      />
      {/* Menu */}
      <CandidateSkillActionMenu
        // States
        showCandidateSkillActionMenu={showCandidateSkillActionMenu}
        // Set States
        setShowCandidateSkillActionMenu={setShowCandidateSkillActionMenu}
        // Functions
        displayCandidateSkillActionDialog={displayCandidateSkillActionDialog}
        displayCandidateSkillActionModal={displayCandidateSkillActionModal}
      />
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onCandidateSkillActionModalClosed}
        // States
        showModal={showCandidateSkillActionModal}
      >
        <CandidateSkillActionModal
          // Events
          onModalClosed={onCandidateSkillActionModalClosed}
          // Functions
          displayFileViewModal={displayFileViewModal}
          // States
          skillId={currentItemId}
        />
      </ModalContainer>
      <ModalContainer
        // Events
        onModalClosed={onFileViewModalClosed}
        // States
        showModal={showFileViewModal}
      >
        <FileViewModal
          // States
          fieldId={currentItemId}
          fieldType="Skill"
          modalTitle={t("技能")}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("技能s")}
          </Typography>
          <Tooltip placement="right" title={t("新增技能")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <CandidateSkillBox
            key={item.id}
            // States
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有技能資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default CandidateSkillBoxesContainer;
