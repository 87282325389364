// Configs
import apisConfig from "../configs/apisConfig";

export const getNumOfStaffGroupedFetch = async (token, statisticsType) => {
  try {
    const queryRoute = "/staffStatistics/getNumOfStaffGrouped";

    const reqBody = { statisticsType };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getStaffStatisticsFetch = async (token, statisticsType) => {
  try {
    const queryRoute = "/staffStatistics/getStaffStatistics";

    const reqBody = { statisticsType };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
