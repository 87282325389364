const initialState = {
  contractId: null,
  formSubmitCount: 0,
  relocationId: null,
  staffId: null,
  tabItem: "BasicInfo",
};

export const staffProfilePageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_STAFF_PROFILE_PAGE_CONTRACT_CHANGE":
      const { contractId } = action;
      return {
        ...state,
        contractId,
      };
    case "ON_STAFF_PROFILE_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_STAFF_PROFILE_PAGE_RELOCATION_CHANGE":
      const { relocationId } = action;
      return {
        ...state,
        relocationId,
      };
    case "ON_STAFF_PROFILE_PAGE_STAFF_CHANGE":
      const { staffId } = action;
      return {
        ...state,
        staffId,
      };
    case "ON_STAFF_PROFILE_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    default:
      return state;
  }
};
