// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
// Icons
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import DoNotTouchRoundedIcon from "@mui/icons-material/DoNotTouchRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import HandshakeRoundedIcon from "@mui/icons-material/HandshakeRounded";
import PendingActionsRoundedIcon from "@mui/icons-material/PendingActionsRounded";

// Styles
import {
  formSubmitBtnGroupStyles,
  formSubmitBtnGroupBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function CandidateInfoFormApplicationDetailsContent(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // States
    candidateStatusTypeId,
    dateAvailable,
    expectedSalary,
    formAlertText,
    formAlertType,
    shouldShowFormAlert,
    titleApplied,
    // Events
    onInputFieldChange,
    onInputFieldKeyPressed,
    onSubmitBtnClicked,
    // Functions
    checkIsFieldError,
    getErrorFieldMessage,
  } = props;

  // Handle States
  const dateAvailableVar = dateAvailable ? new Date(dateAvailable) : null;

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("申請詳情")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={12} lg={6}>
          <TextField
            error={checkIsFieldError("titleApplied")}
            fullWidth
            helperText={getErrorFieldMessage("titleApplied")}
            label={t("申請職位")}
            margin="dense"
            name="titleApplied"
            onChange={(event) =>
              onInputFieldChange("titleApplied", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={titleApplied}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            error={checkIsFieldError("expectedSalary")}
            fullWidth
            helperText={getErrorFieldMessage("expectedSalary")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            label={t("期望工資")}
            margin="dense"
            name="expectedSalary"
            onChange={(event) =>
              onInputFieldChange("expectedSalary", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={expectedSalary}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} lg={6} className={classes.formDatePickerContainer}>
          <DatePicker
            format="dd/MM/yyyy"
            label={t("最快可入職日期 (日/月/年)")}
            onChange={(value) => onInputFieldChange("dateAvailable", value)}
            slotProps={{
              textField: {
                error: checkIsFieldError("dateAvailable"),
                helperText: getErrorFieldMessage("dateAvailable"),
                variant: "standard",
              },
            }}
            value={dateAvailableVar}
            views={["year", "month", "day"]}
          />
        </Grid>
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <ButtonGroup sx={formSubmitBtnGroupStyles}>
            <Button
              onClick={() => onSubmitBtnClicked("EditCandidate")}
              endIcon={<EditRoundedIcon />}
              variant="contained"
              sx={formSubmitBtnGroupBtnStyles}
            >
              {t("修改")}
            </Button>
            {/* !不獲錄用 */}
            {candidateStatusTypeId && candidateStatusTypeId !== 4 && (
              <Button
                onClick={() => onSubmitBtnClicked("RejectCandidate")}
                color="error"
                endIcon={<ClearRoundedIcon />}
                variant="outlined"
                sx={formSubmitBtnGroupBtnStyles}
              >
                {t("駁回")}
              </Button>
            )}
            {/* !候補 */}
            {candidateStatusTypeId && candidateStatusTypeId !== 3 && (
              <Button
                onClick={() => onSubmitBtnClicked("PutCandidateToWaitingList")}
                color="warning"
                endIcon={<PendingActionsRoundedIcon />}
                variant="outlined"
                sx={formSubmitBtnGroupBtnStyles}
              >
                {t("設為候補")}
              </Button>
            )}
            {/* !已發出錄取通知 || 應徵者已婉拒 || 已正式録取 */}
            {candidateStatusTypeId &&
              ![2, 5, 6].includes(candidateStatusTypeId) && (
                <Button
                  onClick={() => onSubmitBtnClicked("OfferCandidate")}
                  color="success"
                  endIcon={<DoneRoundedIcon />}
                  variant="outlined"
                  sx={formSubmitBtnGroupBtnStyles}
                >
                  {t("錄取")}
                </Button>
              )}
          </ButtonGroup>
        </Grid>
        {/* 已發出錄取通知 || 已正式録取 || 應徵者已婉拒 */}
        {candidateStatusTypeId && [2, 5, 6].includes(candidateStatusTypeId) && (
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <ButtonGroup sx={formSubmitBtnGroupStyles}>
              {/* 已發出錄取通知 || 已正式録取 */}
              {candidateStatusTypeId &&
                [2, 5].includes(candidateStatusTypeId) && (
                  <Button
                    onClick={() => onSubmitBtnClicked("CandidateTurnDownOffer")}
                    color="error"
                    endIcon={<DoNotTouchRoundedIcon />}
                    variant="text"
                    sx={formSubmitBtnGroupBtnStyles}
                  >
                    {t("應徵者婉拒")}
                  </Button>
                )}
              {/* 已發出錄取通知 || 應徵者已婉拒 */}
              {candidateStatusTypeId &&
                [2, 6].includes(candidateStatusTypeId) && (
                  <Button
                    onClick={() =>
                      onSubmitBtnClicked("OfficallyAdmitCandidate")
                    }
                    color="success"
                    endIcon={<HandshakeRoundedIcon />}
                    variant="text"
                    sx={formSubmitBtnGroupBtnStyles}
                  >
                    {t("正式録取")}
                  </Button>
                )}
            </ButtonGroup>
          </Grid>
        )}
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default CandidateInfoFormApplicationDetailsContent;
