const initialState = {
  conditionStatisticsTypeValue: null,
  conditionStatisticsTypeItem: null,
  numOfStaffChartActiveIndex: 0,
  tabItem: "Summary",
};

export const dashboardPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_DASHBOARD_PAGE_CONDITION_STATISTICS_TYPE_CHANGE":
      const { conditionStatisticsTypeValue } = action;
      return {
        ...state,
        conditionStatisticsTypeValue,
      };
    case "ON_DASHBOARD_PAGE_CONDITION_STATISTICS_TYPE_ITEM_CHANGE":
      const { conditionStatisticsTypeItem } = action;
      return {
        ...state,
        conditionStatisticsTypeItem,
      };
    case "ON_DASHBOARD_PAGE_NUM_OF_STAFF_CHART_ACTIVE_INDEX_CHANGE":
      const { numOfStaffChartActiveIndex } = action;
      return {
        ...state,
        numOfStaffChartActiveIndex,
      };
    case "ON_DASHBOARD_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    default:
      return state;
  }
};
