const today = new Date();
const currentMonth = new Date(today.getFullYear(), today.getMonth(), 1);

const initialState = {
  brandId: null,
  brandItem: null,
  departmentId: null,
  divisionId: null,
  divisionType: null,
  formSubmitCount: 0,
  month: currentMonth.toString(),
  settingTypeValue: null,
  settingTypeItem: null,
  shopId: null,
  tabItem: "Salaries",
};

export const salariesPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_SALARIES_PAGE_BRAND_CHANGE":
      const { brandId } = action;
      return {
        ...state,
        brandId,
      };
    case "ON_SALARIES_PAGE_BRAND_ITEM_CHANGE":
      const { brandItem } = action;
      return {
        ...state,
        brandItem,
      };
    case "ON_SALARIES_PAGE_DEPARTMENT_CHANGE":
      const { departmentId } = action;
      return {
        ...state,
        departmentId,
      };
    case "ON_SALARIES_PAGE_DIVISION_CHANGE":
      const { divisionId, divisionType } = action;
      return {
        ...state,
        divisionId,
        divisionType,
      };
    case "ON_SALARIES_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_SALARIES_PAGE_MONTH_CHANGE":
      const { month } = action;
      return {
        ...state,
        month: month.toString(),
      };
    case "ON_SALARIES_PAGE_SETTING_TYPE_CHANGE":
      const { settingTypeValue } = action;
      return {
        ...state,
        settingTypeValue,
      };
    case "ON_SALARIES_PAGE_SETTING_TYPE_ITEM_CHANGE":
      const { settingTypeItem } = action;
      return {
        ...state,
        settingTypeItem,
      };
    case "ON_SALARIES_PAGE_SHOP_CHANGE":
      const { shopId } = action;
      return {
        ...state,
        shopId,
      };
    case "ON_SALARIES_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    default:
      return state;
  }
};
