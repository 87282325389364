// Actions
import {
  onFormSubmitCountIncrease,
  onWorkingPlaceChange,
} from "../../../redux/pages/workingPeriodsAndPlacesPage/workingPeriodsAndPlacesPageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getWorkingPlaceDetailFormOptionsFetch } from "../../../fetches/formOptionFetches";
import {
  createWorkingPlaceFetch,
  editWorkingPlaceFetch,
  getWorkingPlaceDetailsByIdFetch,
} from "../../../fetches/workingPlaceFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function WorkingPlaceDetailForm() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const token = useSelector((state) => state.staff.token);
  const workingPlaceId = useSelector(
    (state) => state.workingPeriodsAndPlacesPage.workingPlaceId
  );
  const workingPlaceTypeId = useSelector(
    (state) => state.workingPeriodsAndPlacesPage.workingPlaceTypeId
  );

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [
    workingPlaceDetailActionDialogText,
    setWorkingPlaceDetailActionDialogText,
  ] = useState("");
  const [
    workingPlaceDetailActionDialogType,
    setWorkingPlaceDetailActionDialogType,
  ] = useState(null);
  const [
    showWorkingPlaceDetailActionDialog,
    setShowWorkingPlaceDetailActionDialog,
  ] = useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [areaField, setAreaField] = useState(null);
  const [districtField, setDistrictField] = useState(null);
  const [workingPlaceTypeField, setWorkingPlaceTypeField] = useState(null);
  // Options
  const [areaOptions, setAreaOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [workingPlaceTypeOptions, setWorkingPlaceTypeOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Switches
  const [isItemActive, setIsItemActive] = useState(true);
  const [isItemDefault, setIsItemDefault] = useState(false);
  // Text Fields
  const [addressCh, setAddressCh] = useState("");
  const [addressEn, setAddressEn] = useState("");
  const [nameCh, setNameCh] = useState("");
  const [nameEnFull, setNameEnFull] = useState("");
  const [nameEnShort, setNameEnShort] = useState("");

  // Events
  // Events - Dialogs
  const onWorkingPlaceDetailActionDialogCanceled = () => {
    // Set States
    setShowWorkingPlaceDetailActionDialog(false);
  };

  const onWorkingPlaceDetailActionDialogConfirmed = () => {
    switch (workingPlaceDetailActionDialogType) {
      case "CreateWorkingPlace":
        createWorkingPlace();
        break;
      case "EditWorkingPlace":
        editWorkingPlace();
        break;
      default:
        break;
    }

    // Set States
    setShowWorkingPlaceDetailActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "addressCh":
        setAddressCh(value);
        break;
      case "addressEn":
        setAddressEn(value);
        break;
      case "areaField":
        setAreaField(value);
        setDistrictField(
          value
            ? districtOptions.find((item) => item.area_id === value.id)
            : null
        );
        removeErrorField("districtField");

        break;
      case "districtField":
        setDistrictField(value);
        break;
      case "isItemActive":
        setIsItemActive(value);
        break;
      case "isItemDefault":
        setIsItemDefault(value);
        break;
      case "nameCh":
        setNameCh(value);
        break;
      case "nameEnFull":
        setNameEnFull(value);
        break;
      case "nameEnShort":
        setNameEnShort(value);
        break;
      case "workingPlaceTypeField":
        setWorkingPlaceTypeField(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!nameCh) {
      addToErrorFields("nameCh", t("請先填寫 中文名稱"));
      isError = true;
    }

    if (!nameEnShort) {
      addToErrorFields("nameEnShort", t("請先填寫 英文簡稱"));
      isError = true;
    }

    if (!workingPlaceTypeField) {
      addToErrorFields("workingPlaceTypeField", t("請先填寫 工作地點類別"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayWorkingPlaceDetailActionDialog(
      workingPlaceId ? "EditWorkingPlace" : "CreateWorkingPlace"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const clearForm = () => {
    // Set States
    setWorkingPlaceTypeField(
      workingPlaceTypeId
        ? workingPlaceTypeOptions.find((item) => item.id === workingPlaceTypeId)
        : null
    );
    setNameCh("");
    setNameEnFull("");
    setNameEnShort("");
    setAddressCh("");
    setAddressEn("");
    setIsItemDefault(false);
    setIsItemActive(true);
    setDistrictField(null);
    setAreaField(null);

    clearErrorFields();
  };

  const displayWorkingPlaceDetailActionDialog = (
    workingPlaceDetailActionType
  ) => {
    // Set States
    setWorkingPlaceDetailActionDialogType(workingPlaceDetailActionType);

    switch (workingPlaceDetailActionType) {
      case "CreateWorkingPlace":
        setWorkingPlaceDetailActionDialogText(t("確認要新增 工作地點 嗎？"));
        break;
      case "EditWorkingPlace":
        setWorkingPlaceDetailActionDialogText(
          t("確認要編輯 工作地點資料 嗎？")
        );
        break;
      default:
        break;
    }

    // Set States
    setShowWorkingPlaceDetailActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createWorkingPlace = async () => {
    const results = await createWorkingPlaceFetch(
      token,
      workingPlaceTypeField ? workingPlaceTypeField.id : null,
      nameCh,
      nameEnFull,
      nameEnShort,
      districtField ? districtField.id : null,
      addressCh,
      addressEn,
      isItemDefault,
      isItemActive
    );

    if (results.success) {
      showFormAlert("success", t("成功提交"));

      // Update Redux Store
      dispatch(onWorkingPlaceChange(results.workingPlaceId));
      dispatch(onFormSubmitCountIncrease());
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 工作地點 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editWorkingPlace = async () => {
    if (workingPlaceId) {
      const results = await editWorkingPlaceFetch(
        token,
        workingPlaceId,
        workingPlaceTypeField ? workingPlaceTypeField.id : null,
        nameCh,
        nameEnFull,
        nameEnShort,
        districtField ? districtField.id : null,
        addressCh,
        addressEn,
        isItemDefault,
        isItemActive
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else if (results.isDuplicate) {
        showFormAlert("warning", t("此 工作地點 已存在"));
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getWorkingPlaceDetailFormOptions = async () => {
    const results = await getWorkingPlaceDetailFormOptionsFetch(token);

    // Set States
    setAreaOptions(results.areas ? results.areas : []);
    setDistrictOptions(results.districts ? results.districts : []);
    setWorkingPlaceTypeOptions(
      results.workingPlaceTypes ? results.workingPlaceTypes : []
    );
  };

  const getWorkingPlaceDetailsById = async () => {
    const results = await getWorkingPlaceDetailsByIdFetch(
      token,
      workingPlaceId
    );

    if (results.workingPlaceDetails) {
      const {
        working_place_type_id,
        working_place_name_ch,
        working_place_name_en_full,
        working_place_name_en_short,
        address_ch,
        address_en,
        is_default,
        is_active,
        district_id,
        area_id,
      } = results.workingPlaceDetails;

      // Set States
      setWorkingPlaceTypeField(
        working_place_type_id
          ? workingPlaceTypeOptions.find(
              (item) => item.id === working_place_type_id
            )
          : null
      );
      setNameCh(working_place_name_ch ? working_place_name_ch : "");
      setNameEnFull(
        working_place_name_en_full ? working_place_name_en_full : ""
      );
      setNameEnShort(
        working_place_name_en_short ? working_place_name_en_short : ""
      );
      setDistrictField(
        district_id
          ? districtOptions.find((item) => item.id === district_id)
          : null
      );
      setAddressCh(address_ch ? address_ch : "");
      setAddressEn(address_en ? address_en : "");
      setIsItemDefault(is_default);
      setIsItemActive(is_active);
      setAreaField(
        area_id ? areaOptions.find((item) => item.id === area_id) : null
      );
    } else {
      // Set States
      setWorkingPlaceTypeField(null);
      setNameCh("");
      setNameEnFull("");
      setNameEnShort("");
      setAddressCh("");
      setAddressEn("");
      setIsItemDefault(false);
      setIsItemActive(true);
      setDistrictField(null);
      setAreaField(null);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    getWorkingPlaceDetailFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && areaOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [areaOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (workingPlaceId) {
        getWorkingPlaceDetailsById();
      } else {
        clearForm();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, workingPlaceId]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onWorkingPlaceDetailActionDialogCanceled}
        onDialogConfirmed={onWorkingPlaceDetailActionDialogConfirmed}
        // States
        dialogText={workingPlaceDetailActionDialogText}
        showDialog={showWorkingPlaceDetailActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("工作地點資料")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid className={classes.formAutoCompleteContainer} item xs={12}>
          {workingPlaceTypeOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) =>
                option[t("working_place_type_name_ch")]
              }
              onChange={(event, value) =>
                onInputFieldChange("workingPlaceTypeField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={workingPlaceTypeOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("workingPlaceTypeField")}
                  helperText={getErrorFieldMessage("workingPlaceTypeField")}
                  label={t("工作地點類別")}
                  variant="standard"
                />
              )}
              value={workingPlaceTypeField}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("nameCh")}
            fullWidth
            helperText={getErrorFieldMessage("nameCh")}
            label={t("中文名稱")}
            margin="dense"
            name="nameCh"
            onChange={(event) =>
              onInputFieldChange("nameCh", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={nameCh}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("nameEnShort")}
            fullWidth
            helperText={getErrorFieldMessage("nameEnShort")}
            label={t("英文簡稱")}
            margin="dense"
            name="nameEnShort"
            onChange={(event) =>
              onInputFieldChange("nameEnShort", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={nameEnShort}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={checkIsFieldError("nameEnFull")}
            fullWidth
            helperText={getErrorFieldMessage("nameEnFull")}
            label={t("英文全名")}
            margin="dense"
            name="nameEnFull"
            onChange={(event) =>
              onInputFieldChange("nameEnFull", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            required
            value={nameEnFull}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6} className={classes.formSwitchContainer}>
          <Typography align={"left"} variant="body1">
            {t("有效")}
          </Typography>
          <Switch
            checked={isItemActive}
            onChange={() => onInputFieldChange("isItemActive", !isItemActive)}
          />
        </Grid>
        <Grid item xs={6} className={classes.formSwitchContainer}>
          <Typography align={"left"} variant="body1">
            {t("預設地點")}
          </Typography>
          <Switch
            checked={isItemDefault}
            onChange={() => onInputFieldChange("isItemDefault", !isItemDefault)}
          />
        </Grid>
      </Grid>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("地址")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {areaOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option[t("area_name_ch")]}
              onChange={(event, value) =>
                onInputFieldChange("areaField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={areaOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("areaField")}
                  helperText={getErrorFieldMessage("areaField")}
                  label={t("區域")}
                  variant="standard"
                />
              )}
              value={areaField}
            />
          )}
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {areaOptions &&
            (areaField && districtOptions ? (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option[t("district_name_ch")]}
                onChange={(event, value) =>
                  onInputFieldChange("districtField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={districtOptions.filter(
                  (option) => option.area_id === areaField.id
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("districtField")}
                    helperText={getErrorFieldMessage("districtField")}
                    label={t("地區")}
                    variant="standard"
                  />
                )}
                value={districtField}
              />
            ) : (
              <Autocomplete
                disabled
                disablePortal
                options={[]}
                renderInput={(params) => (
                  <TextField {...params} label={t("地區")} variant="standard" />
                )}
                value={null}
              />
            ))}
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={checkIsFieldError("addressCh")}
            fullWidth
            helperText={getErrorFieldMessage("addressCh")}
            label={t("中文地址")}
            margin="dense"
            name="addressCh"
            onChange={(event) =>
              onInputFieldChange("addressCh", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={addressCh}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={checkIsFieldError("addressEn")}
            fullWidth
            helperText={getErrorFieldMessage("addressEn")}
            label={t("英文地址")}
            margin="dense"
            name="addressEn"
            onChange={(event) =>
              onInputFieldChange("addressEn", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={addressEn}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <Button
            onClick={onSubmitBtnClicked}
            variant="contained"
            sx={formSubmitBtnStyles}
          >
            {workingPlaceId ? t("修改") : t("新增")}
          </Button>
        </Grid>
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default WorkingPlaceDetailForm;
