// Components
import StyledMenu from "../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DoDisturbAltRoundedIcon from "@mui/icons-material/DoDisturbAltRounded";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import PersonAddDisabledRoundedIcon from "@mui/icons-material/PersonAddDisabledRounded";
import RestoreFromTrashRoundedIcon from "@mui/icons-material/RestoreFromTrashRounded";
import RestoreIcon from "@mui/icons-material/Restore";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";
import UpdateIcon from "@mui/icons-material/Update";

function StaffActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // States
    selectedStaff,
    showStaffActionMenu,
    // Set States
    setShowStaffActionMenu,
    // Functions
    displayStaffActionDialog,
    displayStaffActionModal,
  } = props;

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowStaffActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "CancelStaffOnboard":
        displayStaffActionDialog("CancelStaffOnboard");
        break;
      case "CancelStaffResign":
        displayStaffActionDialog("CancelStaffResign");
        break;
      case "RemoveHeadStaff":
        displayStaffActionDialog("RemoveHeadStaff");
        break;
      case "SetHeadStaff":
        displayStaffActionDialog("SetHeadStaff");
        break;
      case "StaffRejoin":
        displayStaffActionModal("StaffRejoin");
        break;
      case "StaffResign":
        displayStaffActionModal("StaffResign");
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showStaffActionMenu}
      open={Boolean(showStaffActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      {/* 移除/設為主管 */}
      {selectedStaff.isTeamHead ? (
        <MenuItem
          disableRipple
          onClick={() => onMenuItemClicked("RemoveHeadStaff")}
        >
          <PersonAddDisabledRoundedIcon />
          {t("移除主管")}
        </MenuItem>
      ) : (
        <MenuItem
          disableRipple
          onClick={() => onMenuItemClicked("SetHeadStaff")}
        >
          <PersonAddRoundedIcon />
          {t("設為主管")}
        </MenuItem>
      )}
      {[1, 5].includes(selectedStaff.staff_status_type_id) && (
        <MenuItem
          disableRipple
          onClick={() => onMenuItemClicked("StaffResign")}
        >
          <DeleteRoundedIcon />
          {t("設為離職")}
        </MenuItem>
      )}
      {/* 已離職 */}
      {selectedStaff.staff_status_type_id === 2 && (
        <MenuItem
          disableRipple
          onClick={() => onMenuItemClicked("StaffRejoin")}
        >
          <RestoreRoundedIcon />
          {t("重新入職")}
        </MenuItem>
      )}
      {/* 準備入職 */}
      {selectedStaff.staff_status_type_id === 3 && (
        <MenuItem
          disableRipple
          onClick={() => onMenuItemClicked("CancelStaffOnboard")}
        >
          <DoDisturbAltRoundedIcon />
          {t("取消入職")}
        </MenuItem>
      )}
      {/* 準備離職 */}
      {selectedStaff.staff_status_type_id === 4 && (
        <MenuItem
          disableRipple
          onClick={() => onMenuItemClicked("CancelStaffResign")}
        >
          <RestoreFromTrashRoundedIcon />
          {t("取消離職")}
        </MenuItem>
      )}
    </StyledMenu>
  );
}

export default StaffActionMenu;
