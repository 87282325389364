// Components
import StyledMenu from "../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import DoNotTouchRoundedIcon from "@mui/icons-material/DoNotTouchRounded";
import HandshakeRoundedIcon from "@mui/icons-material/HandshakeRounded";
import PendingActionsRoundedIcon from "@mui/icons-material/PendingActionsRounded";

function CandidateActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // States
    selectedCandidate,
    showCandidateActionMenu,
    // Set States
    setShowCandidateActionMenu,
    // Functions
    displayCandidateActionDialog,
    displayCandidateOfferActionModal,
    navigateToCreateStaffPage,
  } = props;

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowCandidateActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "CandidateTurnDownOffer":
        displayCandidateActionDialog("CandidateTurnDownOffer");
        break;
      case "OfferCandidate":
        displayCandidateOfferActionModal();
        break;
      case "OfficallyAdmitCandidate":
        navigateToCreateStaffPage();
        break;
      case "PutCandidateToWaitingList":
        displayCandidateActionDialog("PutCandidateToWaitingList");
        break;
      case "RejectCandidate":
        displayCandidateActionDialog("RejectCandidate");
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showCandidateActionMenu}
      open={Boolean(showCandidateActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      {/* !已發出錄取通知 || 應徵者已婉拒 || 已正式録取 */}
      {selectedCandidate &&
        ![2, 5, 6].includes(selectedCandidate.candidate_status_type_id) && (
          <MenuItem
            disableRipple
            onClick={() => onMenuItemClicked("OfferCandidate")}
          >
            <DoneRoundedIcon />
            {t("錄取")}
          </MenuItem>
        )}
      {/* !候補 */}
      {selectedCandidate &&
        selectedCandidate.candidate_status_type_id !== 3 && (
          <MenuItem
            disableRipple
            onClick={() => onMenuItemClicked("PutCandidateToWaitingList")}
          >
            <PendingActionsRoundedIcon />
            {t("設為候補")}
          </MenuItem>
        )}
      {/* !不獲錄用 */}
      {selectedCandidate &&
        selectedCandidate.candidate_status_type_id !== 4 && (
          <MenuItem
            disableRipple
            onClick={() => onMenuItemClicked("RejectCandidate")}
          >
            <ClearRoundedIcon />
            {t("駁回")}
          </MenuItem>
        )}
      {/* 已發出錄取通知 || 已正式録取 */}
      {selectedCandidate &&
        [2, 5].includes(selectedCandidate.candidate_status_type_id) && (
          <MenuItem
            disableRipple
            onClick={() => onMenuItemClicked("CandidateTurnDownOffer")}
          >
            <DoNotTouchRoundedIcon />
            {t("應徵者婉拒")}
          </MenuItem>
        )}
      {/* 已發出錄取通知 || 應徵者已婉拒 */}
      {selectedCandidate &&
        [2, 6].includes(selectedCandidate.candidate_status_type_id) && (
          <MenuItem
            disableRipple
            onClick={() => onMenuItemClicked("OfficallyAdmitCandidate")}
          >
            <HandshakeRoundedIcon />
            {t("正式録取")}
          </MenuItem>
        )}
    </StyledMenu>
  );
}

export default CandidateActionMenu;
