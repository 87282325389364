// Actions
import {
  onBrandChange,
  onBrandItemChange,
  onDepartmentChange,
  onDivisionChange,
  onShopChange,
} from "../../../redux/pages/dayOffTypesPage/dayOffTypesPageActions";

// Components
// Boxes
import DeptAndShopBox from "./deptAndShopBox";
// Menus
import DeptAndShopActionMenu from "../../menus/dayOffTypesPage/deptAndShopActionMenu";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getAllBrandsFetch } from "../../../fetches/brandFetches";
import { getAllDepartmentsByDivisionIdFetch } from "../../../fetches/departmentFetches";
import { getAllDivisionsFetch } from "../../../fetches/divisionFetches";
import { getAllShopsByBrandIdFetch } from "../../../fetches/shopFetches";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
// Icons
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function DeptAndShopBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const brandId = useSelector((state) => state.dayOffTypesPage.brandId);
  const brandItem = useSelector((state) => state.dayOffTypesPage.brandItem);
  const departmentId = useSelector(
    (state) => state.dayOffTypesPage.departmentId
  );
  const divisionId = useSelector((state) => state.dayOffTypesPage.divisionId);
  const divisionType = useSelector(
    (state) => state.dayOffTypesPage.divisionType
  );
  const shopId = useSelector((state) => state.dayOffTypesPage.shopId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [brands, setBrands] = useState(null);
  const [departments, setDepartments] = useState(null);
  const [divisions, setDivisions] = useState(null);
  const [nextDivision, setNextDivision] = useState(null);
  const [shops, setShops] = useState(null);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showDeptAndShopActionMenu, setShowDeptAndShopActionMenu] =
    useState(null);

  // Handle States
  const currentItemId = divisionType === "Departments" ? departmentId : shopId;
  const itemsArr = divisionType === "Departments" ? departments : shops;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    if (divisionType === "Departments") {
      dispatch(onDepartmentChange(itemId));
    } else {
      dispatch(onShopChange(itemId));
    }
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowDeptAndShopActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowDeptAndShopActionMenu(currentTarget);
  };

  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "brand":
        dispatch(onBrandChange(value.id));
        break;
      default:
        break;
    }
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Title
  const onChangeDivisionTypeBtnClicked = () => {
    // Update Redux Store
    dispatch(
      onDivisionChange(nextDivision.id, nextDivision.division_type_name_en)
    );
  };

  // Functions
  // Functions - Queries
  const getAllBrands = async () => {
    const results = await getAllBrandsFetch(token, "Current");

    // Set States
    setBrands(results.brands ? results.brands : null);

    // Update Redux Store
    if (results.brands && !brandId) {
      dispatch(onBrandChange(results.brands[0].id));
    }
  };

  const getAllDepartments = async (shoulddUpdateCurrentDepartmentId) => {
    const results = await getAllDepartmentsByDivisionIdFetch(
      token,
      divisionId,
      "Current"
    );

    // Set States
    setDepartments(results.departments ? results.departments : null);

    // Update Redux Store
    if (shoulddUpdateCurrentDepartmentId) {
      dispatch(
        onDepartmentChange(
          results.departments ? results.departments[0].id : null
        )
      );
    }
  };

  const getAllDivisions = async () => {
    const results = await getAllDivisionsFetch(token);

    // Set States
    setDivisions(results.divisions ? results.divisions : null);

    // Update Redux Store
    if (results.divisions && !divisionId) {
      dispatch(
        onDivisionChange(
          results.divisions[0].id,
          results.divisions[0].division_type_name_en
        )
      );
    }
  };

  const getAllShops = async (shoulddUpdateCurrentShopId) => {
    const results = await getAllShopsByBrandIdFetch(token, brandId, "Current");

    // Set States
    setShops(results.shops ? results.shops : null);

    // Update Redux Store
    if (
      results.shops &&
      (shoulddUpdateCurrentShopId ||
        !results.shops.some((item) => item.id === shopId))
    ) {
      dispatch(onShopChange(results.shops[0].id));
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllDivisions();
  }, []);

  useEffect(() => {
    if (divisionId) {
      if (divisionType === "Departments") {
        getAllDepartments(departmentId ? false : true);
      } else {
        getAllBrands();
      }
    }
  }, [divisionId]);

  useEffect(() => {
    if (divisionId && divisions) {
      // Set States
      setNextDivision(
        divisions[divisions.findIndex((item) => item.id === divisionId) + 1]
          ? divisions[divisions.findIndex((item) => item.id === divisionId) + 1]
          : divisions[0]
      );
    }
  }, [divisionId, divisions]);

  useEffect(() => {
    // Update Redux Store
    if (brands && brandId) {
      dispatch(onBrandItemChange(brands.find((item) => item.id === brandId)));
    }
  }, [brands, brandId]);

  useEffect(() => {
    if (divisionType === "Shops") {
      if (brandId) {
        getAllShops(shopId ? false : true);
      }
    }
  }, [brandId, divisionId]);

  return (
    <div className={classes.contentBox}>
      {/* Menu */}
      <DeptAndShopActionMenu
        // States
        showDeptAndShopActionMenu={showDeptAndShopActionMenu}
        // Set States
        setShowDeptAndShopActionMenu={setShowDeptAndShopActionMenu}
      />
      {/* Title */}
      {divisionType === "Shops" && (
        <div className={classes.titleContainer}>
          <div className={classes.titleTextContainer}>
            <Typography variant="h6" align={"left"}>
              {t("品牌")}
            </Typography>
          </div>
        </div>
      )}
      {/* Select Box */}
      {divisionType === "Shops" && brands && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) => option.brand_name_en_full}
            onChange={(event, value) => onInputFieldChange("brand", value)}
            options={brands}
            renderInput={(params) => (
              <TextField {...params} label={t("品牌")} variant="outlined" />
            )}
            value={brandItem}
          />
        </div>
      )}
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {divisionType === "Departments" ? t("部門s") : t("店鋪s")}
          </Typography>
        </div>
        {nextDivision && (
          <Button
            endIcon={<ChevronRightRoundedIcon />}
            onClick={onChangeDivisionTypeBtnClicked}
            variant="text"
          >{`${t("顯示 ")}${nextDivision[t("division_name_ch")]}`}</Button>
        )}
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <DeptAndShopBox
            key={item.id}
            // States
            currentItemId={currentItemId}
            hoveredItemId={hoveredItemId}
            item={item}
            divisionType={divisionType}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {`${t("未有")}${
              divisionType === "Departments" ? t(" 部門") : t(" 店鋪")
            }${t(" 資料")}`}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default DeptAndShopBoxesContainer;
