// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/attendancesPage/attendancesPageActions";

// Components
// Boxes
import AbnormalAttendanceBox from "./abnormalAttendanceBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  editAttendanceSetIsExceptionFetch,
  getAllAbnormalAttendancesByConditionsWithPaginationFetch,
} from "../../../fetches/attendanceFetches";

// Helper Functions
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";

// Material UI
// Components
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// Icons
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  containerSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNum;

function AbnormalAttendanceBoxesContainer(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // States
    typeOfAttendances,
  } = props;

  // Redux Store
  const abnormalDate = useSelector(
    (state) => state.attendancesPage.abnormalDate
  );
  const attendanceTypeId = useSelector(
    (state) => state.attendancesPage.conditionAttendanceTypeId
  );
  const formSubmitCount = useSelector(
    (state) => state.attendancesPage.formSubmitCount
  );
  const language = useSelector((state) => state.language.language);
  const locationTypeValue = useSelector(
    (state) => state.attendancesPage.conditionLocationTypeValue
  );
  const shopId = useSelector((state) => state.attendancesPage.conditionShopId);
  const token = useSelector((state) => state.staff.token);
  const workingPlaceId = useSelector(
    (state) => state.attendancesPage.conditionWorkingPlaceId
  );

  // States
  // Data
  const [attendances, setAttendances] = useState(null);
  const [checkedItemIdsArr, setCheckedItemIdsArr] = useState([]);
  const [checkedItemsArr, setCheckedItemsArr] = useState([]);
  // Dialog
  const [attendanceActionDialogText, setAttendanceActionDialogText] =
    useState("");
  const [attendanceActionDialogType, setAttendanceActionDialogType] =
    useState("");
  const [showAttendanceActionDialog, setShowAttendanceActionDialog] =
    useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  // Render
  const [shouldShowCheckedItemsOnly, setShouldShowCheckedItemsOnly] =
    useState(false);

  // Handle States
  const abnormalDateVar = new Date(abnormalDate);
  const itemsArr = !shouldShowCheckedItemsOnly ? attendances : checkedItemsArr;

  // Events
  // Events - Box
  const onItemChecked = (item) => {
    const itemId = item.id;

    // Set States
    if (!checkedItemIdsArr.includes(itemId)) {
      setCheckedItemIdsArr((currentState) => [...currentState, itemId]);
      setCheckedItemsArr((currentState) => [...currentState, item]);
    } else {
      setCheckedItemIdsArr((currentState) =>
        currentState.filter((id) => id !== itemId)
      );
      setCheckedItemsArr((currentState) =>
        currentState.filter((item) => item.id !== itemId)
      );
    }
  };

  // Events - Buttons
  const onSubmitBtnClicked = () => {
    displayAttendanceActionDialog(
      typeOfAttendances === "Abnormal" ? "SetException" : "RemoveException"
    );
  };

  // Events - Dialogs
  const onAttendanceActionDialogCanceled = () => {
    // Set States
    setShowAttendanceActionDialog(false);
  };

  const onAttendanceActionDialogConfirmed = () => {
    switch (attendanceActionDialogType) {
      case "RemoveException":
        editAttendanceSetIsException();
        break;
      case "SetException":
        editAttendanceSetIsException();
        break;
      default:
        break;
    }

    // Set States
    setShowAttendanceActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Events - Title
  const onShowCheckedItemsOnlyBtnClicked = () => {
    // Set States
    setShouldShowCheckedItemsOnly(!shouldShowCheckedItemsOnly);
  };

  // Functions
  // Functions - Normal
  const displayAttendanceActionDialog = (attendanceActionType) => {
    // Set States
    setAttendanceActionDialogType(attendanceActionType);

    switch (attendanceActionType) {
      case "RemoveException":
        setAttendanceActionDialogText(t("確認要取消豁免 簽到紀錄 嗎？"));
        break;
      case "SetException":
        setAttendanceActionDialogText(t("確認要豁免 簽到紀錄 嗎？"));
        break;
      default:
        break;
    }

    setShowAttendanceActionDialog(true);
  };

  const resetList = () => {
    // Set States
    setCheckedItemIdsArr([]);
    setCheckedItemsArr([]);
  };

  const showAllItems = () => {
    // Set States
    setShouldShowCheckedItemsOnly(false);
  };

  // Functions - Mutations
  const editAttendanceSetIsException = async () => {
    const results = await editAttendanceSetIsExceptionFetch(
      token,
      checkedItemIdsArr,
      typeOfAttendances
    );

    if (results.success) {
      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());
    }
  };

  // Functions - Queries
  const getAllAbnormalAttendancesByConditionsWithPagination = async () => {
    const isException = typeOfAttendances === "Abnormal" ? false : true;

    const results =
      await getAllAbnormalAttendancesByConditionsWithPaginationFetch(
        token,
        attendanceTypeId !== "dummy" ? attendanceTypeId : null,
        locationTypeValue,
        shopId !== "dummy" ? shopId : null,
        workingPlaceId !== "dummy" ? workingPlaceId : null,
        isException,
        abnormalDateVar,
        pageNum,
        limitNum
      );

    if (results.attendances) {
      for (let item of results.attendances) {
        item.time_attend = item.time_attend
          ? moment(item.time_attend).format("H:mm")
          : null;

        if (item.rosterDetails) {
          item.rosterDetails.roster_time = item.rosterDetails.roster_time
            ? moment(item.rosterDetails.roster_time).format("H:mm")
            : null;
        }
      }
    }

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setAttendances(results.attendances ? results.attendances : null);

    if (results.pageCount && !results.attendances) {
      setPageNum(results.pageCount);
    }
  };

  // Life Cycle
  useEffect(() => {
    resetList();
  }, [formSubmitCount]);

  useEffect(() => {
    getAllAbnormalAttendancesByConditionsWithPagination();
  }, [
    abnormalDate,
    attendanceTypeId,
    formSubmitCount,
    locationTypeValue,
    pageNum,
    shopId,
    workingPlaceId,
  ]);

  useEffect(() => {
    if (!checkedItemIdsArr[0]) {
      showAllItems();
    }
  }, [checkedItemIdsArr]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onAttendanceActionDialogCanceled}
        onDialogConfirmed={onAttendanceActionDialogConfirmed}
        // States
        dialogText={attendanceActionDialogText}
        showDialog={showAttendanceActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {typeOfAttendances === "Abnormal" ? t("異常紀錄") : t("已豁免紀錄")}
          </Typography>
        </div>
        {checkedItemIdsArr[0] && (
          <Button
            endIcon={<KeyboardArrowDownRoundedIcon />}
            onClick={onShowCheckedItemsOnlyBtnClicked}
            variant="text"
          >
            {`${t("顯示 ")}${
              shouldShowCheckedItemsOnly ? t("所有") : t("已選擇")
            }${t(" 紀錄")}`}
          </Button>
        )}
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item) => (
            <AbnormalAttendanceBox
              key={item.id}
              // States
              checkedItemIdsArr={checkedItemIdsArr}
              hoveredItemId={hoveredItemId}
              item={item}
              // Events
              onItemChecked={onItemChecked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
          {checkedItemIdsArr[0] && (
            <div className={classes.containerSubmitBtnBox}>
              <Button
                onClick={onSubmitBtnClicked}
                variant="contained"
                sx={containerSubmitBtnStyles}
              >
                {typeOfAttendances === "Abnormal" ? t("豁免") : t("取消豁免")}
              </Button>
            </div>
          )}
          {!shouldShowCheckedItemsOnly && (
            <Stack className={classes.paginationStack} spacing={2}>
              <Pagination
                color={"primary"}
                count={pageCount}
                onChange={(event, page) => onPageChange(page)}
                page={pageNum}
              />
            </Stack>
          )}
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有簽到紀錄資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default AbnormalAttendanceBoxesContainer;
