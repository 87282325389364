// Actions
import { onNumOfStaffChartActiveIndexChange } from "../../../redux/pages/dashboardPage/dashboardPageActions";

// Components
// Boxes
import NumOfStaffBox from "./numOfStaffBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// React
import { useState } from "react";

// React-Redux
import { useDispatch } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function NumOfStaffBoxesContainer(props) {
  // Hooks
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Props
  const { itemsArr } = props;

  // States
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);

  // Events - Hover
  const onMouseEnterItem = (itemId, itemIndex) => {
    // Set States
    setHoveredItemId(itemId);

    // Update Redux Store
    dispatch(onNumOfStaffChartActiveIndexChange(itemIndex));
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            統計資料
          </Typography>
        </div>
      </div>
      {/* Content */}
      {itemsArr ? (
        itemsArr.map((item, index) => (
          <NumOfStaffBox
            key={item.group_id}
            // States
            hoveredItemId={hoveredItemId}
            index={index}
            item={item}
            // Events
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            未有統計資料
          </Typography>
        </div>
      )}
    </div>
  );
}

export default NumOfStaffBoxesContainer;
