// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// Icons
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function AttendanceRuleBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    hoveredItemId,
    item,
    // Events
    onItemClicked,
    onItemRightClicked,
    onItemShowMenuBtnClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isHovered = hoveredItemId && hoveredItemId === item.id;

  return (
    <div
      className={
        isHovered ? classes.hoveredItemContainer : classes.itemContainer
      }
      onMouseEnter={() => onMouseEnterItem(item.id)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div
        className={classes.itemContentContainer}
        onClick={() => onItemClicked(item.id)}
        onContextMenu={(event) => {
          event.preventDefault();
          onItemRightClicked(item.id, event.currentTarget);
        }}
      >
        <div className={classes.itemContentSubContainer}>
          <Typography
            align={"left"}
            color={isHovered ? stylesConfig.mainTextColor : null}
            variant="body1"
          >
            {item.attendance_rule_type_name_en ===
              "Consecutive Number of Times" &&
              `${t("連續 ")} ${item.num_of_time} ${t("次sC")} - `}
            {item.attendance_rule_type_name_en === "Total Amount of Time" &&
              `${t("總時數")} - `}
            {item.attendance_rule_type_name_en === "Total Number of Times" &&
              `${t("總次數")} ${item.num_of_time} ${t("次")} - `}
            {item.should_be_earlier ? t("遲到") : t("早退")}
            {` ${item.amount_of_time} ${t("分鐘s")}`}
          </Typography>
          <Typography
            align={"left"}
            color={stylesConfig.greyTextColor}
            variant="body2"
          >
            {item.divisions
              ? item.divisions.map((divisionItem, index) =>
                  index === 0
                    ? divisionItem[t("division_name_ch")]
                    : ` / ${divisionItem[t("division_name_ch")]}`
                )
              : ""}
          </Typography>
        </div>
      </div>
      <Stack direction="row" spacing={1}>
        <IconButton
          onClick={(event) =>
            onItemShowMenuBtnClicked(item.id, event.currentTarget)
          }
        >
          <MenuRoundedIcon />
        </IconButton>
      </Stack>
    </div>
  );
}

export default AttendanceRuleBox;
