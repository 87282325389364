// Actions
import {
  onFormSubmitCountIncrease,
  onMonthChange,
} from "../../../redux/pages/rostersPage/rostersPageActions";

// Components
// Backdrop
import LoadingBackdrop from "../../backdrop/loadingBackdrop";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Modals
import ModalContainer from "../../modals/modalContainer";
import SearchActionModal from "../../modals/search/searchActionModal";
// Tables
import RosterTable from "./rosterTable";

// Configs
import stylesConfig from "../../../configs/stylesConfig";
import timeoutConfig from "../../../configs/timeoutConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Debounce
import debounce from "lodash.debounce";

// Fetches
import { getAllDayOffTypesFetch } from "../../../fetches/dayOffTypeFetches";
import {
  getAllRostersByDepartmentOrShopIdFetch,
  quickCreateRostersFetch,
} from "../../../fetches/rosterFetches";
import { getAllShiftsByShopIdFetch } from "../../../fetches/shiftFetches";
import { getAllWorkingPeriodsWithWorkingHoursFetch } from "../../../fetches/workingPeriodFetches";

// Helper Functions
import {
  getMonthEndDate,
  getMonthStartDate,
} from "../../../helperFunctions/getDate";
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";

// Material UI
// Components
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// Icons
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import EventRepeatRoundedIcon from "@mui/icons-material/EventRepeatRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const today = new Date();
const currentMonth = new Date(today.getFullYear(), today.getMonth());

function RosterTableContainer(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // States
    editedStaffToDays,
    selectedStaffToDays,
    // Set States
    setEditedStaffToDays,
    setHoveredDayOffTypeId,
    setHoveredShiftId,
    setHoveredWorkingPeriodId,
    setSelectedStaffToDays,
    // Functions
    createOrEditRoster,
  } = props;

  // Redux Store
  const departmentId = useSelector((state) => state.rostersPage.departmentId);
  const divisionId = useSelector((state) => state.rostersPage.divisionId);
  const divisionType = useSelector((state) => state.rostersPage.divisionType);
  const formSubmitCount = useSelector(
    (state) => state.rostersPage.formSubmitCount
  );
  const language = useSelector((state) => state.language.language);
  const month = useSelector((state) => state.rostersPage.month);
  const shopId = useSelector((state) => state.rostersPage.shopId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [additionalStaffIds, setAdditionalStaffIds] = useState([]);
  const [days, setDays] = useState(null);
  const [dayOffTypes, setDayOffTypes] = useState(null);
  const [exceptionStaffIdsArr, setExceptionStaffIdsArr] = useState([]);
  const [shifts, setShifts] = useState(null);
  const [staffWithRosters, setStaffWithRosters] = useState(null);
  const [workingPeriods, setWorkingPeriods] = useState(null);
  // Dialog
  const [rosterActionDialogText, setRosterActionDialogText] = useState("");
  const [rosterActionDialogType, setRosterActionDialogType] = useState(null);
  const [showRosterActionDialog, setShowRosterActionDialog] = useState(false);
  // Hover
  const [hoveredDayId, setHoveredDayId] = useState(null);
  const [hoveredStaffId, setHoveredStaffId] = useState(null);
  // Modals
  const [showSearchActionModal, setShowSearchActionModal] = useState(false);
  // Redner
  const [isLoading, setIsLoading] = useState(false);
  // Select Mode
  const [isSelectMode, setIsSelectMode] = useState(false);

  // Handle States
  const monthVar = month ? new Date(month) : null;

  const isMonthVarEqualsCurrentMonth =
    monthVar.getFullYear() === currentMonth.getFullYear() &&
    monthVar.getMonth() === currentMonth.getMonth();

  // Events
  // Events - Box
  const onMouseUp = () => {
    setIsSelectMode(false);
  };

  // Events - Dialogs
  const onRosterActionDialogCanceled = () => {
    // Set States
    setShowRosterActionDialog(false);
  };

  const onRosterActionDialogConfirmed = () => {
    switch (rosterActionDialogType) {
      case "QuickCreateRoster":
        quickCreateRosters();
        break;
      default:
        break;
    }

    // Set States
    setShowRosterActionDialog(false);
  };

  // Events - Fields
  const onMonthBackAndForwardBtnClicked = (actionType) => {
    const newMonthVar =
      actionType === "back"
        ? new Date(monthVar.getFullYear(), monthVar.getMonth() - 1, 1)
        : new Date(monthVar.getFullYear(), monthVar.getMonth() + 1, 1);

    // Update Redux Store
    dispatch(onMonthChange(newMonthVar.toString()));
  };

  const onRosterMonthChange = (value) => {
    // Update Redux Store
    dispatch(onMonthChange(value.toString()));
  };

  // Events - Modal
  const onSearchActionModalClosed = () => {
    // Set States
    setShowSearchActionModal(false);
  };

  const onSearchActionModalItemClicked = (
    itemId,
    itemType,
    metaDataCategoryId,
    metaDataId,
    metaDataType
  ) => {
    switch (itemType) {
      case "Staff":
        addStaffToAdditionalStaffIds(itemId);
        break;
      default:
        break;
    }

    onSearchActionModalClosed();
  };

  // Events - Title
  const onAddStaffBtnClicked = () => {
    let tempExceptionStaffIdsArr = [];

    for (let item of staffWithRosters) {
      tempExceptionStaffIdsArr.push(item.id);
    }

    // Set States
    setExceptionStaffIdsArr(tempExceptionStaffIdsArr);

    displaySearchActionModal();
  };

  const onClearSelectedStaffToDaysBtnClicked = () => {
    clearSelectedStaffToDays();
  };

  const onQuickCreateRostersBtnClicked = () => {
    displayRosterActionDialog("QuickCreateRoster");
  };

  const onReturnToCurrentMonthBtnClicked = () => {
    // Update Redux Store
    dispatch(onMonthChange(currentMonth.toString()));
  };

  // Functions
  // Functions - Normal
  const addStaffToAdditionalStaffIds = (staffId) => {
    setAdditionalStaffIds((currentState) => [...currentState, staffId]);
  };

  const clearAdditionalStaffIds = () => {
    // Set States
    setAdditionalStaffIds([]);
  };

  const clearAll = () => {
    clearAdditionalStaffIds();
    clearEditedStaffToDays();
    clearSelectedStaffToDays();
  };

  const clearEditedStaffToDays = () => {
    // Set States
    setEditedStaffToDays([]);
  };

  const clearSelectedStaffToDays = () => {
    // Set States
    setIsSelectMode(false);
    setSelectedStaffToDays([]);
  };

  const displayRosterActionDialog = (applicationActionType) => {
    // Set States
    setRosterActionDialogType(applicationActionType);

    switch (applicationActionType) {
      case "QuickCreateFailed":
        setRosterActionDialogText(t("未能編更, 編更錯誤"));
        break;
      case "QuickCreateRoster":
        setRosterActionDialogText(
          t("確認要 快速編更 嗎？ 快速編更 將覆蓋現有更表。")
        );
        break;
      default:
        break;
    }

    // Set States
    setShowRosterActionDialog(true);
  };

  const displaySearchActionModal = () => {
    // Set States
    setShowSearchActionModal(true);
  };

  const getAllRostersDebounce = debounce(
    (divisionTypeStr, departmentIdVar, shopIdVar, additionalStaffIdsVar) => {
      getAllRostersByDepartmentOrShopId(
        divisionTypeStr,
        departmentIdVar,
        shopIdVar,
        additionalStaffIdsVar
      );
    },
    timeoutConfig.debouceTime
  );

  // Functions - Mutations
  const quickCreateRosters = async () => {
    const startDate = getMonthStartDate(monthVar);
    const endDate = getMonthEndDate(monthVar);

    // Set States
    setIsLoading(true);

    const results = await quickCreateRostersFetch(
      token,
      shopId,
      startDate,
      endDate
    );

    if (!results.success) {
      displayRosterActionDialog("QuickCreateFailed");
    }

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  // Functions - Queries
  const getAllDayOffTypes = async () => {
    const results = await getAllDayOffTypesFetch(token, "Current");

    // Set States
    setDayOffTypes(results.dayOffTypes ? results.dayOffTypes : null);
  };

  const getAllRostersByDepartmentOrShopId = async (
    divisionTypeStr,
    departmentIdVar,
    shopIdVar,
    additionalStaffIdsVar
  ) => {
    const startDate = getMonthStartDate(monthVar);
    const endDate = getMonthEndDate(monthVar);

    const results = await getAllRostersByDepartmentOrShopIdFetch(
      token,
      divisionTypeStr === "Departments" ? departmentIdVar : shopIdVar,
      divisionTypeStr,
      startDate,
      endDate,
      additionalStaffIdsVar
    );

    // Date Handling
    if (results.days) {
      for (let item of results.days) {
        item.date = moment(item.date).format("D");
      }
    }

    if (results.staffWithRosters) {
      for (let staffItem of results.staffWithRosters) {
        for (let staffToDayItem of staffItem.staffToDays) {
          staffToDayItem.actual_start_time = staffToDayItem.actual_start_time
            ? moment(staffToDayItem.actual_start_time).format(t("a h:mm"))
            : null;

          staffToDayItem.actual_end_time = staffToDayItem.actual_end_time
            ? moment(staffToDayItem.actual_end_time).format(t("a h:mm"))
            : null;
        }
      }
    }

    // Set States
    setDays(results.days ? results.days : null);
    setStaffWithRosters(
      results.staffWithRosters ? results.staffWithRosters : null
    );

    setIsLoading(false);
  };

  const getAllShifts = async () => {
    const results = await getAllShiftsByShopIdFetch(token, shopId, "Current");

    if (results.shifts) {
      for (let item of results.shifts) {
        item.default_start_time = item.default_start_time
          ? moment(item.default_start_time).format(t("a h:mm"))
          : null;
        item.default_end_time = item.default_end_time
          ? moment(item.default_end_time).format(t("a h:mm"))
          : null;
      }
    }

    // Set States
    setShifts(results.shifts ? results.shifts : null);
  };

  const getAllWorkingPeriodsWithWorkingHours = async () => {
    const results = await getAllWorkingPeriodsWithWorkingHoursFetch(
      token,
      "Current"
    );

    if (results.workingPeriods) {
      for (let workingPeriodItem of results.workingPeriods) {
        if (workingPeriodItem.workingHours) {
          for (let workingHourItem of workingPeriodItem.workingHours) {
            workingHourItem.start_time = workingHourItem.start_time
              ? moment(workingHourItem.start_time).format(t("a h:mm"))
              : null;
            workingHourItem.end_time = workingHourItem.end_time
              ? moment(workingHourItem.end_time).format(t("a h:mm"))
              : null;
          }
        }
      }
    }

    // Set States
    setWorkingPeriods(results.workingPeriods ? results.workingPeriods : null);
  };

  // Life Cycle
  useEffect(() => {
    getAllDayOffTypes();
    getAllWorkingPeriodsWithWorkingHours();
  }, []);

  useEffect(() => {
    if (shopId) {
      getAllShifts();
    }
  }, [shopId]);

  useEffect(() => {
    clearAll();
  }, [departmentId, divisionId, shopId, month]);

  useEffect(() => {
    if (
      month &&
      ((divisionType === "Departments" && departmentId) ||
        (divisionType === "Shops" && shopId))
    ) {
      // Set States
      setIsLoading(true);

      getAllRostersDebounce(
        divisionType,
        departmentId,
        shopId,
        additionalStaffIds
      );
    }

    clearSelectedStaffToDays();
  }, [
    additionalStaffIds,
    departmentId,
    divisionId,
    formSubmitCount,
    shopId,
    month,
    language,
  ]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBoxWithMaxWidth} onMouseUp={onMouseUp}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onRosterActionDialogCanceled}
        onDialogConfirmed={onRosterActionDialogConfirmed}
        // States
        dialogText={rosterActionDialogText}
        showDialog={showRosterActionDialog}
      />
      {/* Backdrop */}
      <LoadingBackdrop
        // States
        showBackdrop={isLoading}
      />
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onSearchActionModalClosed}
        // States
        showModal={showSearchActionModal}
      >
        <SearchActionModal
          // Events
          onItemClicked={onSearchActionModalItemClicked}
          // States
          exceptionIdsArr={exceptionStaffIdsArr}
          paramsArr={["Roster"]}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("更表s")}
          </Typography>
          <div className={classes.formTwinIconBtnContainerRight}>
            {divisionType === "Shops" && (
              <Button
                endIcon={<EventRepeatRoundedIcon />}
                onClick={onQuickCreateRostersBtnClicked}
                variant="text"
              >
                {t("快速編更")}
              </Button>
            )}
            {selectedStaffToDays[0] && (
              <Button
                endIcon={<ClearRoundedIcon />}
                onClick={onClearSelectedStaffToDaysBtnClicked}
                variant="text"
              >
                {t("清除已選擇紀錄")}
              </Button>
            )}
          </div>
        </div>
        {/* Date Picker */}
        <div className={classes.titleTextContainer}>
          {!isMonthVarEqualsCurrentMonth && (
            <div className={classes.formTwinIconBtnContainerLeft}>
              <Button
                onClick={onReturnToCurrentMonthBtnClicked}
                startIcon={<RestoreRoundedIcon />}
                variant="text"
              >
                {t("返回本月")}
              </Button>
            </div>
          )}
          <div className={classes.formDatePickerContainer}>
            <DatePicker
              format="MM/yyyy"
              label={t("月份 (月/年)")}
              onChange={onRosterMonthChange}
              slotProps={{ textField: { variant: "standard" } }}
              value={monthVar}
              views={["year", "month"]}
            />
          </div>
          <div className={classes.formTwinIconBtnContainerRight}>
            <IconButton onClick={() => onMonthBackAndForwardBtnClicked("back")}>
              <ArrowBackIosRoundedIcon />
            </IconButton>
            <IconButton
              onClick={() => onMonthBackAndForwardBtnClicked("forward")}
            >
              <ArrowForwardIosRoundedIcon />
            </IconButton>
          </div>
        </div>
      </div>
      {days && staffWithRosters ? (
        // Table
        <RosterTable
          // States
          dayOffTypes={dayOffTypes}
          days={days}
          editedStaffToDays={editedStaffToDays}
          hoveredDayId={hoveredDayId}
          hoveredStaffId={hoveredStaffId}
          isSelectMode={isSelectMode}
          selectedStaffToDays={selectedStaffToDays}
          shifts={shifts}
          staffWithRosters={staffWithRosters}
          workingPeriods={workingPeriods}
          // Set States
          setHoveredDayId={setHoveredDayId}
          setHoveredDayOffTypeId={setHoveredDayOffTypeId}
          setHoveredShiftId={setHoveredShiftId}
          setHoveredStaffId={setHoveredStaffId}
          setHoveredWorkingPeriodId={setHoveredWorkingPeriodId}
          setIsSelectMode={setIsSelectMode}
          setSelectedStaffToDays={setSelectedStaffToDays}
          // Events
          onAddStaffBtnClicked={onAddStaffBtnClicked}
          // Functions
          createOrEditRoster={createOrEditRoster}
        />
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有更表資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default RosterTableContainer;
