export function onBrandChange(brandId) {
  return {
    type: "ON_COMPS_AND_BRANDS_PAGE_BRAND_CHANGE",
    brandId,
  };
}

export function onCompanyChange(companyId) {
  return {
    type: "ON_COMPS_AND_BRANDS_PAGE_COMPANY_CHANGE",
    companyId,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_COMPS_AND_BRANDS_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_COMPS_AND_BRANDS_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}

export function onTypeOfCompsAndBrandsChange(typeOfCompsAndBrands) {
  return {
    type: "ON_COMPS_AND_BRANDS_PAGE_TYPE_OF_COMPS_AND_BRANDS_CHANGE",
    typeOfCompsAndBrands,
  };
}
