// Configs
import apisConfig from "../configs/apisConfig";

export const editAttendanceSetIsExceptionFetch = async (
  token,
  attendanceIdsArr,
  typeOfAttendances
) => {
  try {
    const queryRoute = "/attendance/editAttendanceSetIsException";

    const reqBody = { attendanceIdsArr, typeOfAttendances };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editAttendanceSetIsForCountFetch = async (token, attendanceId) => {
  try {
    const queryRoute = `/attendance/editAttendanceSetIsForCount/${attendanceId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllAbnormalAttendancesByConditionsWithPaginationFetch = async (
  token,
  attendanceTypeId,
  locationType,
  shopId,
  workingPlaceId,
  isException,
  date,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute =
      "/attendance/getAllAbnormalAttendancesByConditionsWithPagination";

    const reqBody = {
      attendanceTypeId,
      locationType,
      shopId,
      workingPlaceId,
      isException,
      date,
      pageNum,
      limitNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllAttendancesByDepartmentOrShopIdFetch = async (
  token,
  departmentOrShopId,
  typeOfData,
  startDate,
  endDate,
  attendanceTypeId
) => {
  try {
    const queryRoute = `/attendance/getAllAttendancesByDepartmentOrShopId/${departmentOrShopId}`;

    const reqBody = { typeOfData, startDate, endDate, attendanceTypeId };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllAttendancesByStaffIdFetch = async (
  token,
  staffId,
  dayId,
  attendanceTypeId
) => {
  try {
    const queryRoute = `/attendance/getAllAttendancesByStaffId/${staffId}`;

    const reqBody = { dayId, attendanceTypeId };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllAttendanceSummariesByDepartmentOrShopIdFetch = async (
  token,
  departmentOrShopId,
  typeOfData,
  startDate,
  endDate
) => {
  try {
    const queryRoute = `/attendance/getAllAttendanceSummariesByDepartmentOrShopId/${departmentOrShopId}`;

    const reqBody = { typeOfData, startDate, endDate };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
