// Components
// Boxes
import CaptionBox from "./captionBox";

// Custom Hooks
import { useLanguage } from "../../../../customHooks/getLanguage";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function QuestionBoxContentOption(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onInputFieldKeyPressed,
    onOptionInputFieldChange,
    // States
    groupIndex,
    index,
    item,
  } = props;

  // Handle States
  const isMultiuple = item.max_num_of_answer > 1;
  const optionField =
    item.options && item.answerValue
      ? isMultiuple
        ? item.answerValue.map((answerItem) =>
            item.options.find((optionItem) => optionItem.id === answerItem)
          )
        : item.options.find((optionItem) => optionItem.id === item.answerValue)
      : isMultiuple
      ? []
      : null;

  return (
    <Grid className={classes.formAutoCompleteContainer} item xs={12}>
      {item.options && (
        <Autocomplete
          disablePortal
          getOptionLabel={(option) => option[t("option_text_ch")]}
          multiple={isMultiuple}
          onChange={(event, value) =>
            onOptionInputFieldChange(groupIndex, index, value)
          }
          onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
          options={item.options}
          readOnly={!item.is_editable}
          renderInput={(params) => (
            <TextField
              {...params}
              error={item.isError}
              helperText={item.errorText}
              label={item[t("edited_question_text_ch")]}
              variant="standard"
            />
          )}
          value={optionField}
        />
      )}
      {item[t("captionStrCh")] && (
        <CaptionBox
          // States
          captionStr={item[t("captionStrCh")]}
        />
      )}
    </Grid>
  );
}

export default QuestionBoxContentOption;
