export const monthOptions = [
  { value: 1, textCh: "1個月", textEn: "1 Month" },
  { value: 2, textCh: "2個月", textEn: "2 Months" },
  { value: 3, textCh: "3個月", textEn: "3 Months" },
  { value: 4, textCh: "4個月", textEn: "4 Months" },
  { value: 5, textCh: "5個月", textEn: "5 Months" },
  { value: 6, textCh: "6個月", textEn: "6 Months" },
  { value: 7, textCh: "7個月", textEn: "7 Months" },
  { value: 8, textCh: "8個月", textEn: "8 Months" },
  { value: 9, textCh: "9個月", textEn: "9 Months" },
  { value: 10, textCh: "10個月", textEn: "10 Months" },
  { value: 11, textCh: "11個月", textEn: "11 Months" },
  { value: 12, textCh: "12個月", textEn: "12 Months" },
];
