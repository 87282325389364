// Components
// Boxes
import SuggestionBox from "../../boxes/searchModal/suggestionBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";
import timeoutConfig from "../../../configs/timeoutConfig";

// Consts
import { searchOptions } from "../../../consts/searchModalConsts";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Debounce
import debounce from "lodash.debounce";

// Fetches
import {
  getSearchSuggestionByKeywordFetch,
  getStaffSuggestionForRosterByKeywordFetch,
} from "../../../fetches/searchFetches";

// Material UI
// Components
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  selectBoxContainerStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function SearchActionModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onItemClicked,
    // States
    exceptionIdsArr,
    paramsArr,
  } = props;

  // Redux Store
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [suggestions, setSuggestions] = useState(null);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [hoveredItemType, setHoveredItemType] = useState(null);
  // Select Fields
  const [searchField, setSearchField] = useState(searchOptions[0]);
  // Text Fields
  const [keyword, setKeyword] = useState("");

  // Handle States
  const itemsArr = suggestions;

  // Events
  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    switch (field) {
      case "keyword":
        setKeyword(value);
        break;
      case "searchField":
        setSearchField(searchOptions.find((item) => item.value === value));
        break;
      default:
        break;
    }
  };

  // Events - Hover
  const onMouseEnterItem = (itemId, itemType) => {
    // Set States
    setHoveredItemId(itemId);
    setHoveredItemType(itemType);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
    setHoveredItemType(null);
  };

  // Functions
  // Functions - Normal
  const getSuggestions = async (keywordStr) => {
    switch (paramsArr[0]) {
      case "Roster":
        getStaffSuggestionForRosterByKeyword(keywordStr);
        break;
      default:
        getSearchSuggestionByKeyword(keywordStr);
        break;
    }
  };

  const getSuggestionsDebounce = debounce((keywordStr) => {
    getSuggestions(keywordStr);
  }, timeoutConfig.debouceTime);

  // Functions - Queries
  const getSearchSuggestionByKeyword = async (keywordStr) => {
    const paramsArrVar =
      paramsArr && paramsArr[0] && paramsArr[0] === "All"
        ? [searchField.field]
        : paramsArr;

    const results = await getSearchSuggestionByKeywordFetch(
      token,
      paramsArrVar,
      keywordStr
    );

    // Set States
    setSuggestions(results.suggestions ? results.suggestions : null);
  };

  const getStaffSuggestionForRosterByKeyword = async (keywordStr) => {
    const results = await getStaffSuggestionForRosterByKeywordFetch(
      token,
      keywordStr,
      exceptionIdsArr
    );

    // Set States
    setSuggestions(results.suggestions ? results.suggestions : null);
  };

  // Life Cycle
  useEffect(() => {
    if (keyword) {
      getSuggestionsDebounce(keyword);
    }
  }, [keyword, searchField]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid
            className={classes.formAutoCompleteStickyContainer}
            item
            xs={12}
          >
            {paramsArr[0] === "All" && (
              <div>
                <FormControl variant="standard" sx={selectBoxContainerStyles}>
                  <InputLabel shrink htmlFor="select-multiple-native">
                    {t("搜尋範圍")}
                  </InputLabel>
                  <Select
                    label={t("搜尋範圍")}
                    onChange={(event) =>
                      onInputFieldChange("searchField", event.target.value)
                    }
                    value={searchField.value}
                  >
                    {searchOptions.map((item) => (
                      <MenuItem key={item.field} value={item.value}>
                        <ListItemText primary={t(`${item.value}s`)} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
            <TextField
              autoFocus
              fullWidth
              label={t("關鍵字")}
              margin="dense"
              name="keyword"
              onChange={(event) =>
                onInputFieldChange("keyword", event.target.value)
              }
              required
              value={keyword}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            {/* List */}
            {itemsArr ? (
              itemsArr.map((item) => (
                <SuggestionBox
                  key={`${item.type_en}-${item.id}`}
                  // States
                  hoveredItemId={hoveredItemId}
                  hoveredItemType={hoveredItemType}
                  item={item}
                  // Events
                  onItemClicked={onItemClicked}
                  onMouseEnterItem={onMouseEnterItem}
                  onMouseLeaveItem={onMouseLeaveItem}
                />
              ))
            ) : (
              <div className={classes.emptyDataContainer}>
                <Typography align={"center"} color={stylesConfig.greyTextColor}>
                  {t("未有相關資料")}
                </Typography>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default SearchActionModal;
