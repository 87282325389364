// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import WorkingHourBoxesContainer from "../components/boxes/workingPeriodsAndPlacesPage/workingHourBoxesContainer";
import WorkingPeriodBoxesContainer from "../components/boxes/workingPeriodsAndPlacesPage/workingPeriodBoxesContainer";
import WorkingPeriodStaffBoxesContainer from "../components/boxes/workingPeriodsAndPlacesPage/workingPeriodStaffBoxesContainer";
import WorkingPlaceBoxesContainer from "../components/boxes/workingPeriodsAndPlacesPage/workingPlaceBoxesContainer";
import WorkingPlaceStaffBoxesContainer from "../components/boxes/workingPeriodsAndPlacesPage/workingPlaceStaffBoxesContainer";
import WorkingPlaceWiFiBoxesContainer from "../components/boxes/workingPeriodsAndPlacesPage/workingPlaceWiFiBoxesContainer";
// Forms
import WorkingPeriodDetailForm from "../components/forms/workingPeriodsAndPlacesPage/workingPeriodDetailForm";
import WorkingPlaceDetailForm from "../components/forms/workingPeriodsAndPlacesPage/workingPlaceDetailForm";
// Headers
import WorkingPeriodsPageHeader from "../components/headers/workingPeriodsAndPlacesPage/workingPeriodsAndPlacesPageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function WorkingPeriodsAndPlacesPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const tabItem = useSelector(
    (state) => state.workingPeriodsAndPlacesPage.tabItem
  );

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("WorkingPeriodsAndPlaces"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <WorkingPeriodsPageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          {tabItem === "WorkingPeriods" && <WorkingPeriodBoxesContainer />}
          {tabItem === "WorkingPlaces" && <WorkingPlaceBoxesContainer />}
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          {tabItem === "WorkingPeriods" && (
            <Grid container spacing={4}>
              <Grid item xs={12} lg={8}>
                <WorkingPeriodDetailForm />
                <SpacingBox
                  // Render
                  height={stylesConfig.spacingBoxMarginBottom}
                />
                <WorkingHourBoxesContainer />
                <SpacingBox
                  // Render
                  height={stylesConfig.spacingBoxMarginBottom}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <WorkingPeriodStaffBoxesContainer />
                <SpacingBox
                  // Render
                  height={stylesConfig.spacingBoxMarginBottom}
                />
              </Grid>
            </Grid>
          )}
          {tabItem === "WorkingPlaces" && (
            <Grid container spacing={4}>
              <Grid item xs={12} lg={8}>
                <WorkingPlaceDetailForm />
                <SpacingBox
                  // Render
                  height={stylesConfig.spacingBoxMarginBottom}
                />

                <WorkingPlaceWiFiBoxesContainer />
                <SpacingBox
                  // Render
                  height={stylesConfig.spacingBoxMarginBottom}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <WorkingPlaceStaffBoxesContainer />
                <SpacingBox
                  // Render
                  height={stylesConfig.spacingBoxMarginBottom}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default WorkingPeriodsAndPlacesPage;
