// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/salariesPage/salariesPageActions";

// Components
// Boxes
import SalaryBox from "../../boxes/salaryModal/salaryBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  createOrEditCommissionsFetch,
  getAllCommissionByDepartmentOrShopIdFetch,
} from "../../../fetches/commissionFetches";

// Helper Functions
import {
  getMonthEndDate,
  getMonthStartDate,
} from "../../../helperFunctions/getDate";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function SalaryActionModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
    // States
    language,
    salaryActionModelTitleCh,
    salaryActionModelTitleEn,
    salaryActionModelType,
  } = props;

  // Redux Store
  const departmentId = useSelector((state) => state.salariesPage.departmentId);
  const divisionType = useSelector((state) => state.salariesPage.divisionType);
  const month = useSelector((state) => state.salariesPage.month);
  const shopId = useSelector((state) => state.salariesPage.shopId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Data
  const [staffWithSalaries, setStaffWithSalaries] = useState(null);
  // Dialog
  const [salaryActionDialogText, setSalaryActionDialogText] = useState("");
  const [salaryActionDialogType, setSalaryActionDialogType] = useState(null);
  const [showSalaryActionDialog, setShowSalaryActionDialog] = useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);

  // Handle States
  const itemsArr = staffWithSalaries;
  const monthVar = new Date(month);
  const salaryActionModelTitle =
    language === "zh-hk" ? salaryActionModelTitleCh : salaryActionModelTitleEn;

  // Events
  // Events - Dialogs
  const onSalaryActionDialogCanceled = () => {
    // Set States
    setShowSalaryActionDialog(false);
  };

  const onSalaryActionConfirmed = () => {
    switch (salaryActionDialogType) {
      case "EditSalary":
        editSalaries();
        break;
      default:
        break;
    }

    // Set States
    setShowSalaryActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChanged = (itemId, value) => {
    // Set States
    setShouldShowFormAlert(false);

    const itemIndex = staffWithSalaries.findIndex((item) => item.id === itemId);

    const newItemsArr = [...staffWithSalaries];

    newItemsArr[itemIndex].editedCommission = value;

    // Set States
    setStaffWithSalaries(newItemsArr);
  };

  const onInputFieldFocused = (itemId) => {
    const itemIndex = staffWithSalaries.findIndex((item) => item.id === itemId);

    if (staffWithSalaries[itemIndex].editedCommission !== 0) {
      return;
    }

    const newItemsArr = [...staffWithSalaries];

    newItemsArr[itemIndex].editedCommission = "";

    // Set States
    setStaffWithSalaries(newItemsArr);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  const onInputFieldUnfocused = (itemId) => {
    const itemIndex = staffWithSalaries.findIndex((item) => item.id === itemId);

    if (staffWithSalaries[itemIndex].editedCommission) {
      return;
    }

    const newItemsArr = [...staffWithSalaries];

    newItemsArr[itemIndex].editedCommission = 0;

    // Set States
    setStaffWithSalaries(newItemsArr);
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    for (let item of staffWithSalaries) {
      if (isNaN(item.editedCommission)) {
        showFormAlert("error", `${salaryActionModelTitle} ${t("必須為數字")}`);
        return;
      }
    }

    displaySalaryActionDialog("EditSalary");
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Functions - Normal
  const displaySalaryActionDialog = (salaryActionType) => {
    // Set States
    setSalaryActionDialogType(salaryActionType);
    switch (salaryActionType) {
      case "EditSalary":
        setSalaryActionDialogText(
          `${t("確認要修改 員工 ")}${salaryActionModelTitle} ${t("嗎？")}`
        );
        break;
      default:
        break;
    }

    // Set States
    setShowSalaryActionDialog(true);
  };

  const getAllStaffSalaries = () => {
    switch (salaryActionModelType) {
      case "Commission":
        getAllCommissionByDepartmentOrShopId();
        break;
      default:
        break;
    }
  };

  const editSalaries = () => {
    switch (salaryActionModelType) {
      case "Commission":
        createOrEditCommissions();
        break;
      default:
        break;
    }
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createOrEditCommissions = async () => {
    let staffWithCommissions = [];

    for (let item of staffWithSalaries) {
      const staffItem = {
        staffId: item.id,
        commissionValue: item.editedCommission,
      };

      staffWithCommissions.push(staffItem);
    }

    if (!staffWithCommissions[0]) {
      return;
    }

    const results = await createOrEditCommissionsFetch(
      token,
      monthVar,
      staffWithCommissions
    );

    if (results.success) {
      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());

      onModalClosed();
    } else {
      showFormAlert("error", "未能提交");
    }
  };

  // Functions - Queries
  const getAllCommissionByDepartmentOrShopId = async () => {
    const startDate = getMonthStartDate(monthVar);
    const endDate = getMonthEndDate(monthVar);

    const results = await getAllCommissionByDepartmentOrShopIdFetch(
      token,
      divisionType === "Departments" ? departmentId : shopId,
      divisionType,
      startDate,
      endDate
    );

    if (results.staffWithCommissions) {
      for (let item of results.staffWithCommissions) {
        item.editedCommission = item.commission;
      }
    }

    // Set States
    setStaffWithSalaries(
      results.staffWithCommissions ? results.staffWithCommissions : null
    );
  };

  // Life Cycle
  useEffect(() => {
    if (
      month &&
      ((divisionType === "Departments" && departmentId) ||
        (divisionType === "Shops" && shopId))
    ) {
      getAllStaffSalaries();
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [departmentId, divisionType, shopId, month]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onSalaryActionDialogCanceled}
        onDialogConfirmed={onSalaryActionConfirmed}
        // States
        dialogText={salaryActionDialogText}
        showDialog={showSalaryActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {salaryActionModelTitle}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid item xs={12}>
            {/* List */}
            {itemsArr ? (
              itemsArr.map((item) => (
                <SalaryBox
                  key={item.id}
                  // States
                  hoveredItemId={hoveredItemId}
                  item={item}
                  // Events
                  onInputFieldChange={onInputFieldChanged}
                  onInputFieldFocused={onInputFieldFocused}
                  onInputFieldKeyPressed={onInputFieldKeyPressed}
                  onInputFieldUnfocused={onInputFieldUnfocused}
                  onMouseEnterItem={onMouseEnterItem}
                  onMouseLeaveItem={onMouseLeaveItem}
                />
              ))
            ) : (
              <div className={classes.emptyDataContainer}>
                <Typography align={"center"} color={stylesConfig.greyTextColor}>
                  {`${t("未有相關員工")}${salaryActionModelTitle}`}
                </Typography>
              </div>
            )}
          </Grid>
          {itemsArr && (
            <Grid item xs={12} className={classes.formSubmitBtnContainer}>
              <Button
                onClick={onSubmitBtnClicked}
                variant="contained"
                sx={formSubmitBtnStyles}
              >
                {t("修改")}
              </Button>
            </Grid>
          )}
          {/* Alert */}
          {itemsArr && shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default SalaryActionModal;
