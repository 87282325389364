// Actions
import {
  onFormSubmitCountIncrease,
  onTitleChange,
} from "../../../redux/pages/titlesPage/titlesPageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getTitleDetailFormOptionsFetch } from "../../../fetches/formOptionFetches";
import {
  createTitleFetch,
  editTitleFetch,
  getTitleDetailsByIdFetch,
} from "../../../fetches/titleFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function TitleDetailForm() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const titleId = useSelector((state) => state.titlesPage.titleId);
  const divisionId = useSelector((state) => state.titlesPage.divisionId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [titleDetailActionDialogText, setTitleDetailActionDialogText] =
    useState("");
  const [titleDetailActionDialogType, setTitleDetailActionDialogType] =
    useState(null);
  const [showTitleDetailActionDialog, setShowTitleDetailActionDialog] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [divisionField, setDivisionField] = useState(null);
  // Options
  const [divisionOptions, setDivisionOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Switches
  const [isItemActive, setIsItemActive] = useState(true);
  // Text Fields
  const [nameCh, setNameCh] = useState("");
  const [nameEnShort, setNameEnShort] = useState("");
  const [nameEnFull, setNameEnFull] = useState("");

  // Events
  // Events - Dialogs
  const onTitleDetailActionDialogCanceled = () => {
    // Set States
    setShowTitleDetailActionDialog(false);
  };

  const onTitleDetailActionDialogConfirmed = () => {
    switch (titleDetailActionDialogType) {
      case "CreateTitle":
        createTitle();
        break;
      case "EditTitle":
        editTitle();
        break;
      default:
        break;
    }

    // Set States
    setShowTitleDetailActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "isItemActive":
        setIsItemActive(value);
        break;
      case "divisionField":
        setDivisionField(value);
        break;
      case "nameCh":
        setNameCh(value);
        break;
      case "nameEnShort":
        setNameEnShort(value);
        break;
      case "nameEnFull":
        setNameEnFull(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!divisionField) {
      addToErrorFields("divisionField", t("請先填寫 類別"));
      isError = true;
    }

    if (!nameCh) {
      addToErrorFields("nameCh", t("請先填寫 中文名稱"));
      isError = true;
    }

    if (!nameEnFull) {
      addToErrorFields("nameEnFull", t("請先填寫 英文全名"));
      isError = true;
    }

    if (!nameEnShort) {
      addToErrorFields("nameEnShort", t("請先填寫 英文簡稱"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayTitleDetailActionDialog(titleId ? "EditTitle" : "CreateTitle");
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const clearForm = () => {
    // Set States
    setNameCh("");
    setNameEnFull("");
    setNameEnShort("");
    setIsItemActive(true);
    setDivisionField(
      divisionId ? divisionOptions.find((item) => item.id === divisionId) : null
    );

    clearErrorFields();
  };

  const displayTitleDetailActionDialog = (titleDetailActionType) => {
    // Set States
    setTitleDetailActionDialogType(titleDetailActionType);

    switch (titleDetailActionType) {
      case "CreateTitle":
        setTitleDetailActionDialogText(t("確認要新增 職位 嗎？"));
        break;
      case "EditTitle":
        setTitleDetailActionDialogText(t("確認要編輯 職位資料 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowTitleDetailActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createTitle = async () => {
    const results = await createTitleFetch(
      token,
      nameCh,
      nameEnFull,
      nameEnShort,
      isItemActive,
      divisionField ? divisionField.id : null
    );

    if (results.success) {
      showFormAlert("success", t("成功提交"));

      // Update Redux Store
      dispatch(onTitleChange(results.titleId));
      dispatch(onFormSubmitCountIncrease());
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 職位 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editTitle = async () => {
    if (titleId) {
      const results = await editTitleFetch(
        token,
        titleId,
        nameCh,
        nameEnFull,
        nameEnShort,
        isItemActive,
        divisionField ? divisionField.id : null
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else if (results.isDuplicate) {
        showFormAlert("warning", t("此 職位 已存在"));
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getTitleDetailFormOptions = async () => {
    const results = await getTitleDetailFormOptionsFetch(token);

    // Set States
    setDivisionOptions(results.divisions ? results.divisions : []);
  };

  const getTitleDetailsById = async () => {
    const results = await getTitleDetailsByIdFetch(token, titleId);

    if (results.titleDetails) {
      const {
        title_name_ch,
        title_name_en_full,
        title_name_en_short,
        is_active,
        division_id,
      } = results.titleDetails;

      // Set States
      setNameCh(title_name_ch ? title_name_ch : "");
      setNameEnFull(title_name_en_full ? title_name_en_full : null);
      setNameEnShort(title_name_en_short ? title_name_en_short : null);
      setIsItemActive(is_active);
      setDivisionField(
        division_id
          ? divisionOptions.find((item) => item.id === division_id)
          : null
      );
    } else {
      // Set States
      setNameCh("");
      setNameEnFull("");
      setNameEnShort("");
      setIsItemActive(true);
      setDivisionField(null);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    getTitleDetailFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && divisionOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [divisionOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (titleId) {
        getTitleDetailsById();
      } else {
        clearForm();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, titleId]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onTitleDetailActionDialogCanceled}
        onDialogConfirmed={onTitleDetailActionDialogConfirmed}
        // States
        dialogText={titleDetailActionDialogText}
        showDialog={showTitleDetailActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("職位資料")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("nameCh")}
            fullWidth
            helperText={getErrorFieldMessage("nameCh")}
            label={t("中文名稱")}
            margin="dense"
            name="nameCh"
            onChange={(event) =>
              onInputFieldChange("nameCh", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={nameCh}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("nameEnShort")}
            fullWidth
            helperText={getErrorFieldMessage("nameEnShort")}
            label={t("英文簡稱")}
            margin="dense"
            name="nameEnShort"
            onChange={(event) =>
              onInputFieldChange("nameEnShort", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            required
            value={nameEnShort}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={checkIsFieldError("nameEnFull")}
            fullWidth
            helperText={getErrorFieldMessage("nameEnFull")}
            label={t("英文全名")}
            margin="dense"
            name="nameEnFull"
            onChange={(event) =>
              onInputFieldChange("nameEnFull", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={nameEnFull}
            variant="standard"
          />
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {divisionOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option[t("division_name_ch")]}
              onChange={(event, value) =>
                onInputFieldChange("divisionField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={divisionOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("divisionField")}
                  helperText={getErrorFieldMessage("divisionField")}
                  label={t("類別")}
                  variant="standard"
                />
              )}
              value={divisionField}
            />
          )}
        </Grid>
        <Grid item xs={6} className={classes.formSwitchContainer}>
          <Typography align={"left"} variant="body1">
            {t("有效")}
          </Typography>
          <Switch
            checked={isItemActive}
            onChange={() => onInputFieldChange("isItemActive", !isItemActive)}
          />
        </Grid>
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <Button
            onClick={onSubmitBtnClicked}
            variant="contained"
            sx={formSubmitBtnStyles}
          >
            {titleId ? t("修改") : t("新增")}
          </Button>
        </Grid>
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default TitleDetailForm;
