// Components
// Boxes
import AttendanceBox from "../../boxes/attendancesPage/attendanceListModal/attendanceBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import AttendanceActionMenu from "../../menus/attendancesPage/attendanceListModal/attendanceActionMenu";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  editAttendanceSetIsExceptionFetch,
  editAttendanceSetIsForCountFetch,
  getAllAttendancesByStaffIdFetch,
} from "../../../fetches/attendanceFetches";

// Helper Functions
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";

// Material UI
// Components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function AttendanceListModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // States
    dayId,
    staffId,
  } = props;

  // Redux Store
  const attendanceTypeId = useSelector(
    (state) => state.attendancesPage.attendanceTypeId
  );
  const language = useSelector((state) => state.language.language);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [attendances, setAttendances] = useState(null);
  const [currentAttendanceId, setCurrentAttendanceId] = useState(null);
  const [currentAttendanceItem, setCurrentAttendanceItem] = useState(false);
  const [rosterDetails, setRosterDetails] = useState(null);
  // Dialog
  const [attendanceActionDialogText, setAttendanceActionDialogText] =
    useState("");
  const [attendanceActionDialogType, setAttendanceActionDialogType] =
    useState(null);
  const [showAttendanceActionDialog, setShowAttendanceActionDialog] =
    useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showAttendanceActionMenu, setShowAttendanceActionMenu] =
    useState(null);

  // Handle States
  const currentItem = currentAttendanceItem;
  const currentItemId = currentAttendanceId;
  const itemsArr = attendances;

  // Events
  // Events - Boxes
  const onItemClicked = (itemId, item, currentTarget) => {
    if (checkShouldShowMenu(item)) {
      // Set States
      setCurrentAttendanceId(itemId);
      setCurrentAttendanceItem(item);
      setShowAttendanceActionMenu(currentTarget);
    }
  };

  const onItemShowMenuBtnClicked = (itemId, item, currentTarget) => {
    if (checkShouldShowMenu(item)) {
      // Set States
      setCurrentAttendanceId(itemId);
      setCurrentAttendanceItem(item);
      setShowAttendanceActionMenu(currentTarget);
    }
  };

  // Events - Dialogs
  const onAttendanceActionDialogCanceled = () => {
    // Set States
    setShowAttendanceActionDialog(false);
  };

  const onAttendanceActionDialogConfirmed = () => {
    switch (attendanceActionDialogType) {
      case "SetException":
        editAttendanceSetIsException();
        break;
      case "SetForCount":
        editAttendanceSetIsForCount();
        break;
      default:
        break;
    }

    // Set States
    setShowAttendanceActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Functions
  // Functions - Normal
  const checkShouldShowMenu = (item) => {
    if (
      item &&
      (!item.is_for_count ||
        (!item.is_exception && (!item.is_on_time || !item.is_location_correct)))
    ) {
      return true;
    } else {
      return false;
    }
  };
  const displayAttendanceActionDialog = (attendanceActionType) => {
    // Set States
    setAttendanceActionDialogType(attendanceActionType);

    switch (attendanceActionType) {
      case "SetException":
        setAttendanceActionDialogText(t("確認要豁免此 簽到紀錄？"));
        break;
      case "SetForCount":
        setAttendanceActionDialogText(t("確認要將此 簽到紀錄 用作考勤？"));
        break;
      default:
        break;
    }

    // Set States
    setShowAttendanceActionDialog(true);
  };

  // Functions - Mutations
  const editAttendanceSetIsException = async () => {
    const results = await editAttendanceSetIsExceptionFetch(
      token,
      [currentItemId],
      "Abnormal"
    );

    if (results.success) {
      getAllAttendancesByStaffId();
    }
  };

  const editAttendanceSetIsForCount = async () => {
    const results = await editAttendanceSetIsForCountFetch(
      token,
      currentItemId
    );

    if (results.success) {
      getAllAttendancesByStaffId();
    }
  };

  // Functions - Queries
  const getAllAttendancesByStaffId = async () => {
    const results = await getAllAttendancesByStaffIdFetch(
      token,
      staffId,
      dayId,
      attendanceTypeId
    );

    if (results.attendances) {
      for (let item of results.attendances) {
        item.time_attend = item.time_attend
          ? moment(item.time_attend).format("H:mm")
          : null;
      }
    }

    if (results.rosterDetails) {
      results.rosterDetails.roster_time = results.rosterDetails.roster_time
        ? moment(results.rosterDetails.roster_time).format("H:mm")
        : null;
    }

    // Set States
    setAttendances(results.attendances ? results.attendances : null);
    setRosterDetails(results.rosterDetails ? results.rosterDetails : null);
  };

  // Life Cycle
  useEffect(() => {
    getAllAttendancesByStaffId();
  }, [dayId, staffId, attendanceTypeId]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onAttendanceActionDialogCanceled}
        onDialogConfirmed={onAttendanceActionDialogConfirmed}
        // States
        dialogText={attendanceActionDialogText}
        showDialog={showAttendanceActionDialog}
      />
      {/* Menu */}
      <AttendanceActionMenu
        // States
        currentItem={currentItem}
        showAttendanceActionMenu={showAttendanceActionMenu}
        // Set States
        setShowAttendanceActionMenu={setShowAttendanceActionMenu}
        // Fucntions
        displayAttendanceActionDialog={displayAttendanceActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("詳細簽到紀錄")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid item xs={12}>
            {/* List */}
            {itemsArr ? (
              itemsArr.map((item) => (
                <AttendanceBox
                  key={item.id}
                  // States
                  hoveredItemId={hoveredItemId}
                  item={item}
                  rosterDetails={rosterDetails}
                  // Events
                  onItemClicked={onItemClicked}
                  onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
                  onMouseEnterItem={onMouseEnterItem}
                  onMouseLeaveItem={onMouseLeaveItem}
                />
              ))
            ) : (
              <div className={classes.emptyDataContainer}>
                <Typography align={"center"} color={stylesConfig.greyTextColor}>
                  {t("未有簽到紀錄資料")}
                </Typography>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default AttendanceListModal;
