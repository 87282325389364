// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getSalaryCountSettingFormOptionsFetch } from "../../../fetches/formOptionFetches";
import {
  editSalaryCountSettingsFetch,
  getSalaryCountSettingsFetch,
} from "../../../fetches/salaryCountSettingFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function SalaryCountSettingForm() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [
    salaryCountSettingActionDialogText,
    setSalaryCountSettingActionDialogText,
  ] = useState("");
  const [
    salaryCountSettingActionDialogType,
    setSalaryCountSettingActionDialogType,
  ] = useState(null);
  const [
    showSalaryCountSettingActionDialog,
    setShowSalaryCountSettingActionDialog,
  ] = useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [monthlySalaryCountTypeField, setMonthlySalaryCountTypeField] =
    useState(null);
  // Options
  const [monthlySalaryCountTypeOptions, setMonthlySalaryCountTypeOptions] =
    useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Switches
  const [isPublicHolidaysPaid, setIsPublicHolidaysPaid] = useState(true);

  // Events
  // Events - Dialogs
  const onSalaryCountSettingActionDialogCanceled = () => {
    // Set States
    setShowSalaryCountSettingActionDialog(false);
  };

  const onSalaryCountSettingActionDialogConfirmed = () => {
    switch (salaryCountSettingActionDialogType) {
      case "EditSalaryCountSettings":
        editSalaryCountSettings();
        break;
      default:
        break;
    }

    // Set States
    setShowSalaryCountSettingActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "isPublicHolidaysPaid":
        setIsPublicHolidaysPaid(value);
        break;
      case "monthlySalaryCountTypeField":
        setMonthlySalaryCountTypeField(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!monthlySalaryCountTypeField) {
      addToErrorFields(
        "monthlySalaryCountTypeField",
        t("請先填寫 月薪計算方式")
      );
      isError = true;
    }

    if (isError) {
      return;
    }

    displaySalaryCountSettingActionDialog("EditSalaryCountSettings");
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displaySalaryCountSettingActionDialog = (
    salaryCountSettingActionType
  ) => {
    // Set States
    setSalaryCountSettingActionDialogType(salaryCountSettingActionType);

    switch (salaryCountSettingActionType) {
      case "EditSalaryCountSettings":
        setSalaryCountSettingActionDialogText(
          t("確認要編輯 薪金計算設定 嗎？")
        );
        break;
      default:
        break;
    }

    // Set States
    setShowSalaryCountSettingActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const editSalaryCountSettings = async () => {
    const results = await editSalaryCountSettingsFetch(
      token,
      monthlySalaryCountTypeField ? monthlySalaryCountTypeField.id : null,
      isPublicHolidaysPaid
    );

    if (results.success) {
      showFormAlert("success", t("成功提交"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  // Functions - Queries
  const getSalaryCountSettingFormOptions = async () => {
    const results = await getSalaryCountSettingFormOptionsFetch(token);

    // Set States
    setMonthlySalaryCountTypeOptions(
      results.monthlySalaryCountTypes ? results.monthlySalaryCountTypes : []
    );
  };

  const getSalaryCountSettingsById = async () => {
    const results = await getSalaryCountSettingsFetch(token);

    if (results.salaryCountSettings) {
      const { monthly_salary_count_type_id, is_public_holidays_paid } =
        results.salaryCountSettings;

      // Set States
      setIsPublicHolidaysPaid(is_public_holidays_paid);
      setMonthlySalaryCountTypeField(
        monthly_salary_count_type_id
          ? monthlySalaryCountTypeOptions.find(
              (item) => item.id === monthly_salary_count_type_id
            )
          : null
      );
    } else {
      // Set States
      setIsPublicHolidaysPaid(false);
      setMonthlySalaryCountTypeField(null);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    getSalaryCountSettingFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && monthlySalaryCountTypeOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [monthlySalaryCountTypeOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      getSalaryCountSettingsById();
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onSalaryCountSettingActionDialogCanceled}
        onDialogConfirmed={onSalaryCountSettingActionDialogConfirmed}
        // States
        dialogText={salaryCountSettingActionDialogText}
        showDialog={showSalaryCountSettingActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("薪金計算設定s")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {monthlySalaryCountTypeOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) =>
                `${option.monthly_salary_count_type_name_en} - ${option.monthly_salary_count_type_name_ch}`
              }
              onChange={(event, value) =>
                onInputFieldChange("monthlySalaryCountTypeField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={monthlySalaryCountTypeOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("monthlySalaryCountTypeField")}
                  helperText={getErrorFieldMessage(
                    "monthlySalaryCountTypeField"
                  )}
                  label={t("月薪計算方式")}
                  variant="standard"
                />
              )}
              value={monthlySalaryCountTypeField}
            />
          )}
        </Grid>
        <Grid item xs={6} className={classes.formSwitchContainer}>
          <Typography align={"left"} variant="body1">
            {t("試用期內，公眾假期有薪")}
          </Typography>
          <Switch
            checked={isPublicHolidaysPaid}
            onChange={() =>
              onInputFieldChange("isPublicHolidaysPaid", !isPublicHolidaysPaid)
            }
          />
        </Grid>
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <Button
            onClick={onSubmitBtnClicked}
            variant="contained"
            sx={formSubmitBtnStyles}
          >
            {t("修改")}
          </Button>
        </Grid>
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default SalaryCountSettingForm;
