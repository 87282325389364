// Actions
import { onStaffChange } from "../../../redux/pages/staffProfilePage/staffProfilePageActions";

// Components
// Boxes
import StaffBox from "./staffBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import StaffActionMenu from "../../menus/deptsAndShopsPage/staffActionMenu";
// Modals
import StaffActionModal from "../../modals/deptsAndShopsPage/staffActionModal/staffActionModal";
import ModalContainer from "../../modals/modalContainer";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteRelocationFetch,
  getRelocationIdByStaffIdAndRelocationTypeIdsArrFetch,
} from "../../../fetches/relocationFetches";
import {
  createStaffToDepartmentFetch,
  deleteStaffToDepartmentFetch,
} from "../../../fetches/staffToDepartmentFetches";
import {
  createStaffToShopFetch,
  deleteStaffToShopFetch,
} from "../../../fetches/staffToShopFetches";
import { getAllStaffsByDepartmentOrShopIdFetch } from "../../../fetches/staffFetches";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function StaffBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const departmentId = useSelector(
    (state) => state.deptsAndShopsPage.departmentId
  );
  const divisionId = useSelector((state) => state.deptsAndShopsPage.divisionId);
  const divisionType = useSelector(
    (state) => state.deptsAndShopsPage.divisionType
  );
  const shopId = useSelector((state) => state.deptsAndShopsPage.shopId);
  const token = useSelector((state) => state.staff.token);
  const typeOfContent = useSelector(
    (state) => state.deptsAndShopsPage.typeOfContent
  );
  const typeOfStaffs = useSelector(
    (state) => state.deptsAndShopsPage.typeOfStaffs
  );

  // States
  // Data
  const [staffs, setStaffs] = useState(null);
  // Dialog
  const [staffActionDialogText, setStaffActionDialogText] = useState("");
  const [staffActionDialogType, setStaffActionDialogType] = useState(null);
  const [showStaffActionDialog, setShowStaffActionDialog] = useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Modals
  const [showStaffActionModal, setShowStaffActionModal] = useState(false);
  const [staffActionModalType, setStaffActionModalType] = useState("");
  // Menu
  const [showStaffActionMenu, setShowStaffActionMenu] = useState(null);
  // Render
  const [selectedStaff, setSelectedStaff] = useState(null);

  // Handle States
  const itemsArr = staffs;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onStaffChange(itemId));
    // Navigate
    navigate("/staffProfile");
  };

  const onItemRightClicked = (item, currentTarget) => {
    // Set States
    setSelectedStaff(item);
    setShowStaffActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (item, currentTarget) => {
    // Set States
    setSelectedStaff(item);
    setShowStaffActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onStaffActionDialogCanceled = () => {
    // Set States
    setShowStaffActionDialog(false);
  };

  const onStaffActionDialogConfirmed = async () => {
    let targetRelocationIdResults = null;

    switch (staffActionDialogType) {
      // 取消入職
      case "CancelStaffOnboard":
        // Get Target Relocation Id
        targetRelocationIdResults =
          await getRelocationIdByStaffIdAndRelocationTypeIdsArr([1, 3]);

        if (targetRelocationIdResults) {
          const targetRelocationId = targetRelocationIdResults;

          // Delete Relocation
          await deleteRelocation(targetRelocationId);
        }

        break;
      // 取消離職
      case "CancelStaffResign":
        // Get Target Relocation Id
        targetRelocationIdResults =
          await getRelocationIdByStaffIdAndRelocationTypeIdsArr([2]);

        if (targetRelocationIdResults) {
          const targetRelocationId = targetRelocationIdResults;

          // Delete Relocation
          await deleteRelocation(targetRelocationId);
        }

        break;
      // 移除主管
      case "RemoveHeadStaff":
        if (divisionType === "Departments") {
          await deleteStaffToDepartment();
        } else {
          await deleteStaffToShop();
        }

        break;
      // 設為主管
      case "SetHeadStaff":
        if (divisionType === "Departments") {
          await createStaffToDepartment();
        } else {
          await createStaffToShop();
        }

        break;
      default:
        break;
    }

    // Set States
    setShowStaffActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onStaffActionModalClosed = () => {
    getAllStaffsByDepartmentOrShopId();
    // Set States
    setShowStaffActionModal(false);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Navigate
    navigate("/createStaff", {
      state: { departmentId, divisionId, divisionType, shopId },
    });
  };

  // Functions
  // Functions - Normal
  const clearData = () => {
    // Set States
    setStaffs(null);
  };

  const displayStaffActionDialog = (staffActionType) => {
    // Set States
    setStaffActionDialogType(staffActionType);

    switch (staffActionType) {
      // 取消入職
      case "CancelStaffOnboard":
        setStaffActionDialogText(t("確認要取消 入職 並 刪除 員工嗎？"));
        break;
      // 取消離職
      case "CancelStaffResign":
        setStaffActionDialogText(t("確認要取消 離職 嗎？"));
        break;
      // 移除主管
      case "RemoveHeadStaff":
        setStaffActionDialogText(t("確認要 移除 主管嗎？"));
        break;
      // 設為主管
      case "SetHeadStaff":
        setStaffActionDialogText(t("確認要 設為 主管嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowStaffActionDialog(true);
  };

  const displayStaffActionModal = (typeOfModal) => {
    // Set States
    setStaffActionModalType(typeOfModal);
    setShowStaffActionModal(true);
  };

  // Functions - Queries
  const getAllStaffsByDepartmentOrShopId = async () => {
    const results = await getAllStaffsByDepartmentOrShopIdFetch(
      token,
      divisionType === "Departments" ? departmentId : shopId,
      divisionType,
      typeOfStaffs
    );

    // Set States
    setStaffs(results.staffs ? results.staffs : null);
  };

  const getRelocationIdByStaffIdAndRelocationTypeIdsArr = async (
    relocationTypeId
  ) => {
    const results = await getRelocationIdByStaffIdAndRelocationTypeIdsArrFetch(
      token,
      selectedStaff.id,
      relocationTypeId
    );

    if (results.relocationId) {
      return results.relocationId;
    } else {
      return null;
    }
  };

  // Functions - Mutations
  const createStaffToDepartment = async () => {
    if (selectedStaff) {
      const results = await createStaffToDepartmentFetch(
        token,
        departmentId,
        selectedStaff.id
      );

      if (results.success) {
        getAllStaffsByDepartmentOrShopId();
      }
    }
  };

  const createStaffToShop = async () => {
    if (selectedStaff) {
      const results = await createStaffToShopFetch(
        token,
        shopId,
        selectedStaff.id
      );

      if (results.success) {
        getAllStaffsByDepartmentOrShopId();
      }
    }
  };

  const deleteRelocation = async (relocationId) => {
    if (relocationId) {
      const results = await deleteRelocationFetch(token, relocationId);

      if (results.success) {
        getAllStaffsByDepartmentOrShopId();
      }
    }
  };

  const deleteStaffToDepartment = async () => {
    if (selectedStaff) {
      const results = await deleteStaffToDepartmentFetch(
        token,
        departmentId,
        selectedStaff.id
      );

      if (results.success) {
        getAllStaffsByDepartmentOrShopId();
      }
    }
  };

  const deleteStaffToShop = async () => {
    if (selectedStaff) {
      const results = await deleteStaffToShopFetch(
        token,
        shopId,
        selectedStaff.id
      );

      if (results.success) {
        getAllStaffsByDepartmentOrShopId();
      }
    }
  };

  // Life Cycle
  useEffect(() => {
    if (divisionType === "Departments") {
      if (departmentId) {
        if (typeOfContent === "StaffList") {
          getAllStaffsByDepartmentOrShopId();
        }
      } else {
        clearData();
      }
    } else {
      if (shopId) {
        if (typeOfContent === "StaffList") {
          getAllStaffsByDepartmentOrShopId();
        }
      } else {
        clearData();
      }
    }
  }, [departmentId, divisionId, shopId, typeOfContent, typeOfStaffs]);

  return (
    <div className={classes.contentBox}>
      {/* Dialogs */}
      <AlertDialog
        // Events
        onDialogClosed={onStaffActionDialogCanceled}
        onDialogConfirmed={onStaffActionDialogConfirmed}
        // States
        dialogText={staffActionDialogText}
        showDialog={showStaffActionDialog}
      />
      {/* Menu */}
      {selectedStaff && (
        <StaffActionMenu
          // States
          selectedStaff={selectedStaff}
          showStaffActionMenu={showStaffActionMenu}
          // Set States
          setShowStaffActionMenu={setShowStaffActionMenu}
          // Functions
          displayStaffActionDialog={displayStaffActionDialog}
          displayStaffActionModal={displayStaffActionModal}
        />
      )}
      {/* Modal */}
      {selectedStaff && (
        <ModalContainer
          // Events
          onModalClosed={onStaffActionModalClosed}
          // States
          showModal={showStaffActionModal}
        >
          <StaffActionModal
            // Events
            onModalClosed={onStaffActionModalClosed}
            // States
            staffId={selectedStaff.id}
            staffActionModalType={staffActionModalType}
          />
        </ModalContainer>
      )}
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("員工列表")}
          </Typography>
          <Tooltip placement="right" title={t("新增員工")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <StaffBox
            key={item.id}
            // States
            divisionType={divisionType}
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有員工資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default StaffBoxesContainer;
