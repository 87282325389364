// Components
// Boxes
import WorkingHourBox from "./workingHourBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import WorkingHourActionMenu from "../../menus/workingPeriodsAndPlacesPage/workingHourActionMenu";
// Modals
import ModalContainer from "../../modals/modalContainer";
import WorkingHourActionModal from "../../modals/workingPeriodsAndPlacesPage/workingHourActionModal";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteWorkingHourFetch,
  getAllWorkingHoursByWorkingPeriodIdFetch,
} from "../../../fetches/workingHourFetches";

// Helper Functions
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function WorkingHourBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const language = useSelector((state) => state.language.language);
  const token = useSelector((state) => state.staff.token);
  const workingPeriodId = useSelector(
    (state) => state.workingPeriodsAndPlacesPage.workingPeriodId
  );

  // States
  // Data
  const [currentItemId, setCurrentItemId] = useState(null);
  const [workingHours, setWorkingHours] = useState(null);
  // Dialog
  const [workingHourActionDialogText, setWorkingHourActionDialogText] =
    useState("");
  const [workingHourActionDialogType, setWorkingHourActionDialogType] =
    useState(null);
  const [showWorkingHourActionDialog, setShowWorkingHourActionDialog] =
    useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showWorkingHourActionMenu, setShowWorkingHourActionMenu] =
    useState(null);
  // Modals
  const [showWorkingHourActionModal, setShowWorkingHourActionModal] =
    useState(false);

  // Handle States
  const itemsArr = workingHours;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Set States
    setCurrentItemId(itemId);
    displayWorkingHourActionModal();
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setShowWorkingHourActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setShowWorkingHourActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onWorkingHourActionDialogCanceled = () => {
    // Set States
    setShowWorkingHourActionDialog(false);
  };

  const onWorkingHourActionDialogConfirmed = () => {
    switch (workingHourActionDialogType) {
      case "DeleteWorkingHour":
        deleteWorkingHour();
        break;
      default:
        break;
    }

    // Set States
    setShowWorkingHourActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onWorkingHourActionModalClosed = () => {
    getAllWorkingHoursByWorkingPeriodId();
    // Set States
    setShowWorkingHourActionModal(false);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Set States
    setCurrentItemId(null);
    displayWorkingHourActionModal();
  };

  // Functions
  // Functions - Normal
  const clearData = () => {
    // Set States
    setWorkingHours(null);
  };

  const displayWorkingHourActionDialog = (workingHourActionType) => {
    // Set States
    setWorkingHourActionDialogType(workingHourActionType);

    switch (workingHourActionType) {
      case "DeleteWorkingHour":
        setWorkingHourActionDialogText(t("確認要刪除 工作時間 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowWorkingHourActionDialog(true);
  };

  const displayWorkingHourActionModal = () => {
    setShowWorkingHourActionModal(true);
  };

  // Functions - Mutations
  const deleteWorkingHour = async () => {
    const results = await deleteWorkingHourFetch(token, currentItemId);

    if (results.success) {
      getAllWorkingHoursByWorkingPeriodId();
    }
  };

  // Functions - Queries
  const getAllWorkingHoursByWorkingPeriodId = async () => {
    const results = await getAllWorkingHoursByWorkingPeriodIdFetch(
      token,
      workingPeriodId
    );

    // Format Time
    if (results.workingHours) {
      for (let item of results.workingHours) {
        item.start_time = item.start_time
          ? moment(item.start_time).format(t("a h:mm"))
          : null;
        item.end_time = item.end_time
          ? moment(item.end_time).format(t("a h:mm"))
          : null;
      }
    }

    // Set States
    setWorkingHours(results.workingHours ? results.workingHours : null);
  };

  // Life Cycle
  useEffect(() => {
    if (workingPeriodId) {
      getAllWorkingHoursByWorkingPeriodId();
    } else {
      clearData();
    }
  }, [workingPeriodId, language]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onWorkingHourActionDialogCanceled}
        onDialogConfirmed={onWorkingHourActionDialogConfirmed}
        // States
        dialogText={workingHourActionDialogText}
        showDialog={showWorkingHourActionDialog}
      />
      {/* Menu */}
      <WorkingHourActionMenu
        // States
        showWorkingHourActionMenu={showWorkingHourActionMenu}
        // Set States
        setShowWorkingHourActionMenu={setShowWorkingHourActionMenu}
        // Functions
        displayWorkingHourActionDialog={displayWorkingHourActionDialog}
        displayWorkingHourActionModal={displayWorkingHourActionModal}
      />
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onWorkingHourActionModalClosed}
        // States
        showModal={showWorkingHourActionModal}
      >
        <WorkingHourActionModal
          // Events
          onModalClosed={onWorkingHourActionModalClosed}
          // States
          workingHourId={currentItemId}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("工作時間s")}
          </Typography>
          <Tooltip placement="right" title={t("新增工作時間")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <WorkingHourBox
            key={item.id}
            // States
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有工作時間資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default WorkingHourBoxesContainer;
