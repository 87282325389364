// Actions
import {
  onWorkingPeriodChange,
  onWorkingPeriodItemChange,
} from "../../../redux/pages/workingPeriodsAndPlacesPage/workingPeriodsAndPlacesPageActions";

// Components
// Boxes
import WorkingPeriodBox from "./workingPeriodBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import WorkingPeriodActionMenu from "../../menus/workingPeriodsAndPlacesPage/workingPeriodActionMenu";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteOrRestoreWorkingPeriodFetch,
  editWorkingPeriodSetIsDefaultFetch,
  getAllWorkingPeriodsFetch,
} from "../../../fetches/workingPeriodFetches";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function WorkingPeriodBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.workingPeriodsAndPlacesPage.formSubmitCount
  );
  const token = useSelector((state) => state.staff.token);
  const typeOfWorkingPeriods = useSelector(
    (state) => state.workingPeriodsAndPlacesPage.typeOfWorkingPeriodsAndPlaces
  );
  const workingPeriodId = useSelector(
    (state) => state.workingPeriodsAndPlacesPage.workingPeriodId
  );

  // States
  // Data
  const [workingPeriods, setWorkingPeriods] = useState(null);
  // Dialog
  const [workingPeriodActionDialogText, setWorkingPeriodActionDialogText] =
    useState("");
  const [workingPeriodActionDialogType, setWorkingPeriodActionDialogType] =
    useState(null);
  const [showWorkingPeriodActionDialog, setShowWorkingPeriodActionDialog] =
    useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showWorkingPeriodActionMenu, setShowWorkingPeriodActionMenu] =
    useState(null);
  // Render
  const [selectedWorkingPeriod, setSelectedWorkingPeriod] = useState(null);

  // Handle States
  const currentItemId = workingPeriodId;
  const itemsArr = workingPeriods;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onWorkingPeriodChange(itemId));
  };

  const onItemRightClicked = (item, currentTarget) => {
    onItemClicked(item.id);
    // Set States
    setSelectedWorkingPeriod(item);
    setShowWorkingPeriodActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (item, currentTarget) => {
    onItemClicked(item.id);
    // Set States
    setSelectedWorkingPeriod(item);
    setShowWorkingPeriodActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onWorkingPeriodActionDialogCanceled = () => {
    // Set States
    setShowWorkingPeriodActionDialog(false);
  };

  const onWorkingPeriodActionDialogConfirmed = () => {
    switch (workingPeriodActionDialogType) {
      // 刪除
      case "DeleteWorkingPeriod":
        deleteOrRestoreWorkingPeriod();
        break;
      // 設為一般時段
      case "RemoveWorkingPeriodDefault":
        editWorkingPeriodSetIsDefault();
        break;
      // 還原
      case "RestoreWorkingPeriod":
        deleteOrRestoreWorkingPeriod();
        break;
      // 設為預設時段
      case "SetWorkingPeriodDefault":
        editWorkingPeriodSetIsDefault();
        break;
      default:
        break;
    }

    // Set States
    setShowWorkingPeriodActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Update Redux Store
    dispatch(onWorkingPeriodChange(null));
  };

  // Functions
  // Functions - Normal
  const displayWorkingPeriodActionDialog = (workingPeriodActionType) => {
    const workingPeriodCode = selectedWorkingPeriod.working_period_code;

    // Set States
    setWorkingPeriodActionDialogType(workingPeriodActionType);

    switch (workingPeriodActionType) {
      // 刪除
      case "DeleteWorkingPeriod":
        setWorkingPeriodActionDialogText(t("確認要刪除 工作時段 嗎？"));
        break;
      // 設為一般時段
      case "RemoveWorkingPeriodDefault":
        setWorkingPeriodActionDialogText(
          `${t("確認要將")} ${workingPeriodCode} ${t("設為一般時段嗎？")}`
        );
        break;
      // 還原
      case "RestoreWorkingPeriod":
        setWorkingPeriodActionDialogText(t("確認要還原 工作時段 嗎？"));
        break;
      // 設為預設時段
      case "SetWorkingPeriodDefault":
        setWorkingPeriodActionDialogText(
          `${t("確認要將")} ${workingPeriodCode} ${t("設為預設時段嗎？")}`
        );
        break;
      default:
        break;
    }

    // Set States
    setShowWorkingPeriodActionDialog(true);
  };

  // Functions - Mutations
  const deleteOrRestoreWorkingPeriod = async () => {
    const results = await deleteOrRestoreWorkingPeriodFetch(
      token,
      currentItemId,
      typeOfWorkingPeriods
    );

    if (results.success) {
      getAllWorkingPeriods(true);
    }
  };

  const editWorkingPeriodSetIsDefault = async () => {
    const results = await editWorkingPeriodSetIsDefaultFetch(
      token,
      currentItemId,
      !selectedWorkingPeriod.is_default
    );

    if (results.success) {
      getAllWorkingPeriods(false);
    }
  };

  // Functions - Queries
  const getAllWorkingPeriods = async (shoulddUpdateCurrentWorkingPeriodId) => {
    const results = await getAllWorkingPeriodsFetch(
      token,
      typeOfWorkingPeriods
    );

    // Set States
    setWorkingPeriods(results.workingPeriods ? results.workingPeriods : null);

    // Update Redux Store
    if (shoulddUpdateCurrentWorkingPeriodId) {
      dispatch(
        onWorkingPeriodChange(
          results.workingPeriods ? results.workingPeriods[0].id : null
        )
      );
    }
  };

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    if (workingPeriods && workingPeriodId) {
      dispatch(
        onWorkingPeriodItemChange(
          workingPeriods.find((item) => item.id === workingPeriodId)
        )
      );
    }
  }, [workingPeriods, workingPeriodId]);

  useEffect(() => {
    getAllWorkingPeriods(workingPeriodId ? false : true);
  }, [formSubmitCount, typeOfWorkingPeriods]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onWorkingPeriodActionDialogCanceled}
        onDialogConfirmed={onWorkingPeriodActionDialogConfirmed}
        // States
        dialogText={workingPeriodActionDialogText}
        showDialog={showWorkingPeriodActionDialog}
      />
      {/* Menu */}
      <WorkingPeriodActionMenu
        // States
        selectedWorkingPeriod={selectedWorkingPeriod}
        showWorkingPeriodActionMenu={showWorkingPeriodActionMenu}
        // Set States
        setSelectedWorkingPeriod={setSelectedWorkingPeriod}
        setShowWorkingPeriodActionMenu={setShowWorkingPeriodActionMenu}
        setWorkingPeriodActionDialogType={setWorkingPeriodActionDialogType}
        // Functions
        displayWorkingPeriodActionDialog={displayWorkingPeriodActionDialog}
      />
      {/* WorkingPeriod */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("工作時段s")}
          </Typography>
          <Tooltip placement="right" title={t("新增工作時段")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <WorkingPeriodBox
            key={item.id}
            // States
            currentItemId={currentItemId}
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有工作時段資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default WorkingPeriodBoxesContainer;
