// Actions
import {
  onBrandChange,
  onCompanyChange,
} from "../../../redux/pages/compsAndBrandsPage/compsAndBrandsPageActions";

// Components
// Boxes
import CompAndBrandBox from "./compAndBrandBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import CompAndBrandActionMenu from "../../menus/compsAndBrandPage/compAndBrandActionMenu";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteOrRestoreBrandFetch,
  getAllBrandsFetch,
} from "../../../fetches/brandFetches";
import {
  deleteOrRestoreCompanyFetch,
  getAllCompaniesFetch,
} from "../../../fetches/companyFetches";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function CompAndBrandBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const brandId = useSelector((state) => state.compsAndBrandsPage.brandId);
  const companyId = useSelector((state) => state.compsAndBrandsPage.companyId);
  const tabItem = useSelector((state) => state.compsAndBrandsPage.tabItem);
  const formSubmitCount = useSelector(
    (state) => state.compsAndBrandsPage.formSubmitCount
  );
  const token = useSelector((state) => state.staff.token);
  const typeOfCompsAndBrands = useSelector(
    (state) => state.compsAndBrandsPage.typeOfCompsAndBrands
  );

  // States
  // Data
  const [brands, setBrands] = useState(null);
  const [companies, setCompanies] = useState(null);
  // Dialog
  const [compAndBrandActionDialogText, setCompAndBrandActionDialogText] =
    useState("");
  const [compAndBrandActionDialogType, setCompAndBrandActionDialogType] =
    useState(null);
  const [showCompAndBrandActionDialog, setShowCompAndBrandActionDialog] =
    useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showCompAndBrandActionMenu, setShowCompAndBrandActionMenu] =
    useState(null);

  // Handle States
  const currentItemId = tabItem === "Companies" ? companyId : brandId;
  const itemsArr = tabItem === "Companies" ? companies : brands;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    if (tabItem === "Companies") {
      dispatch(onCompanyChange(itemId));
    } else {
      dispatch(onBrandChange(itemId));
    }
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowCompAndBrandActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowCompAndBrandActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onCompAndBrandActionDialogCanceled = () => {
    // Set States
    setShowCompAndBrandActionDialog(false);
  };

  const onCompAndBrandActionDialogConfirmed = () => {
    switch (compAndBrandActionDialogType) {
      case "DeleteBrand":
        deleteOrRestoreBrand();
        break;
      case "DeleteCompany":
        deleteOrRestoreCompany();
        break;
      case "RestoreBrand":
        deleteOrRestoreBrand();
        break;
      case "RestoreCompany":
        deleteOrRestoreCompany();
        break;
      default:
        break;
    }

    // Set States
    setShowCompAndBrandActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Update Redux Store
    if (tabItem === "Companies") {
      dispatch(onCompanyChange(null));
    } else {
      dispatch(onBrandChange(null));
    }
  };

  // Functions
  // Functions - Normal
  const displayCompAndBrandActionDialog = (compAndBrandActionType) => {
    // Set States
    setCompAndBrandActionDialogType(compAndBrandActionType);

    switch (compAndBrandActionType) {
      case "DeleteBrand":
        setCompAndBrandActionDialogText(t("確認要刪除 品牌 嗎？"));
        break;
      case "DeleteCompany":
        setCompAndBrandActionDialogText(t("確認要刪除 公司 嗎？"));
        break;
      case "RestoreBrand":
        setCompAndBrandActionDialogText(t("確認要還原 品牌 嗎？"));
        break;
      case "RestoreCompany":
        setCompAndBrandActionDialogText(t("確認要還原 公司 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowCompAndBrandActionDialog(true);
  };

  // Functions - Mutations
  const deleteOrRestoreBrand = async () => {
    const results = await deleteOrRestoreBrandFetch(
      token,
      currentItemId,
      typeOfCompsAndBrands
    );

    if (results.success) {
      getAllBrands(true);
    }
  };

  const deleteOrRestoreCompany = async () => {
    const results = await deleteOrRestoreCompanyFetch(
      token,
      currentItemId,
      typeOfCompsAndBrands
    );

    if (results.success) {
      getAllCompanies(true);
    }
  };

  // Functions - Queries
  const getAllBrands = async (shoulddUpdateCurrentBrandId) => {
    const results = await getAllBrandsFetch(token, typeOfCompsAndBrands);

    // Set States
    setBrands(results.brands ? results.brands : null);

    // Update Redux Store
    if (shoulddUpdateCurrentBrandId) {
      dispatch(onBrandChange(results.brands ? results.brands[0].id : null));
    }
  };

  const getAllCompanies = async (shoulddUpdateCurrentCompanyId) => {
    const results = await getAllCompaniesFetch(token, typeOfCompsAndBrands);

    // Set States
    setCompanies(results.companies ? results.companies : null);

    // Update Redux Store
    if (shoulddUpdateCurrentCompanyId) {
      dispatch(
        onCompanyChange(results.companies ? results.companies[0].id : null)
      );
    }
  };

  // Life Cycle
  useEffect(() => {
    if (tabItem === "Companies") {
      getAllCompanies(companyId ? false : true);
    } else {
      getAllBrands(brandId ? false : true);
    }
  }, [tabItem, formSubmitCount, typeOfCompsAndBrands]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onCompAndBrandActionDialogCanceled}
        onDialogConfirmed={onCompAndBrandActionDialogConfirmed}
        // States
        dialogText={compAndBrandActionDialogText}
        showDialog={showCompAndBrandActionDialog}
      />
      {/* Menu */}
      <CompAndBrandActionMenu
        // States
        showCompAndBrandActionMenu={showCompAndBrandActionMenu}
        // Set States
        setShowCompAndBrandActionMenu={setShowCompAndBrandActionMenu}
        // Fucntions
        displayCompAndBrandActionDialog={displayCompAndBrandActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {tabItem === "Companies" ? t("公司s") : t("品牌s")}
          </Typography>
          <Tooltip
            placement="right"
            title={`${t("新增")}${tabItem === "Companies" ? "公司" : "品牌"}`}
          >
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {itemsArr ? (
        itemsArr.map((item) => (
          <CompAndBrandBox
            key={item.id}
            // States
            currentItemId={currentItemId}
            hoveredItemId={hoveredItemId}
            item={item}
            tabItem={tabItem}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {`${t("未有 ")}${
              tabItem === "Companies" ? t("公司") : t("品牌")
            }${t(" 資料")}`}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default CompAndBrandBoxesContainer;
