export function onApplicationChange(applicationId, applicationTypeId) {
  return {
    type: "ON_APPLICATIONS_PAGE_APPLICATION_CHANGE",
    applicationId,
    applicationTypeId,
  };
}

export function onConditionApplicationOrApprovalTypeChange(
  conditionApplicationOrApprovalTypeValue
) {
  return {
    type: "ON_APPLICATIONS_PAGE_CONDITION_APPLICATION_OR_APPROVAL_TYPE_CHANGE",
    conditionApplicationOrApprovalTypeValue,
  };
}

export function onConditionApplicationOrApprovalTypeItemChange(
  conditionApplicationOrApprovalTypeItem
) {
  return {
    type: "ON_APPLICATIONS_PAGE_CONDITION_APPLICATION_OR_APPROVAL_TYPE_ITEM_CHANGE",
    conditionApplicationOrApprovalTypeItem,
  };
}

export function onConditionApplicationStatusTypeChange(
  conditionApplicationStatusTypeId
) {
  return {
    type: "ON_APPLICATIONS_PAGE_CONDITION_APPLICATION_STATUS_TYPE_CHANGE",
    conditionApplicationStatusTypeId,
  };
}

export function onConditionApplicationStatusTypeItemChange(
  conditionApplicationStatusTypeItem
) {
  return {
    type: "ON_APPLICATIONS_PAGE_CONDITION_APPLICATION_STATUS_TYPE_ITEM_CHANGE",
    conditionApplicationStatusTypeItem,
  };
}

export function onConditionCandidateStatusTypeChange(
  conditionCandidateStatusTypeId
) {
  return {
    type: "ON_APPLICATIONS_PAGE_CONDITION_CANDIDATE_STATUS_TYPE_CHANGE",
    conditionCandidateStatusTypeId,
  };
}

export function onConditionCandidateStatusTypeItemChange(
  conditionCandidateStatusTypeItem
) {
  return {
    type: "ON_APPLICATIONS_PAGE_CONDITION_CANDIDATE_STATUS_TYPE_ITEM_CHANGE",
    conditionCandidateStatusTypeItem,
  };
}

export function onConditionApplicationTypeChange(conditionApplicationTypeId) {
  return {
    type: "ON_APPLICATIONS_PAGE_CONDITION_APPLICATION_TYPE_CHANGE",
    conditionApplicationTypeId,
  };
}

export function onConditionApplicationTypeItemChange(
  conditionApplicationTypeItem
) {
  return {
    type: "ON_APPLICATIONS_PAGE_CONDITION_APPLICATION_TYPE_ITEM_CHANGE",
    conditionApplicationTypeItem,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_APPLICATIONS_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onProcedureApplicationProcedureChange(
  procedureApplicationProcedureId
) {
  return {
    type: "ON_APPLICATIONS_PAGE_PROCEDURE_APPLICATION_PROCEDURE_CHANGE",
    procedureApplicationProcedureId,
  };
}

export function onProcedureApplicationProcedureItemChange(
  procedureApplicationProcedureItem
) {
  return {
    type: "ON_APPLICATIONS_PAGE_PROCEDURE_APPLICATION_PROCEDURE_ITEM_CHANGE",
    procedureApplicationProcedureItem,
  };
}

export function onProcedureApplicationTypeChange(procedureApplicationTypeId) {
  return {
    type: "ON_APPLICATIONS_PAGE_PROCEDURE_APPLICATION_TYPE_CHANGE",
    procedureApplicationTypeId,
  };
}

export function onProcedureApplicationTypeItemChange(
  procedureApplicationTypeItem
) {
  return {
    type: "ON_APPLICATIONS_PAGE_PROCEDURE_APPLICATION_TYPE_ITEM_CHANGE",
    procedureApplicationTypeItem,
  };
}

export function onRecordMonthChange(recordMonth) {
  return {
    type: "ON_APPLICATIONS_PAGE_RECORD_MONTH_CHANGE",
    recordMonth,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_APPLICATIONS_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}
