// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/candidateProfilePage/candidateProfilePageActions";

// Components
// Boxes
import CandidateWorkExperienceBox from "./candidateWorkExperienceBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import CandidateWorkExperienceActionMenu from "../../menus/candidateProfilePage/candidateWorkExperienceActionMenu";
// Modals
import CandidateWorkExperienceActionModal from "../../modals/candidateProfilePage/candidateWorkExperienceActionModal";
import FileViewModal from "../../modals/candidateProfilePage/fileViewModal";
import ModalContainer from "../../modals/modalContainer";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteWorkExperienceFetch,
  getAllWorkExperiencesByCandidateIdFetch,
} from "../../../fetches/workExperienceFetches";

// Helper Functions
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";
import { monthsToYearsAndMonths } from "../../../helperFunctions/monthsToYearsAndMonths";
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function CandidateWorkExperienceBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const candidateId = useSelector(
    (state) => state.candidateProfilePage.candidateId
  );
  const formSubmitCount = useSelector(
    (state) => state.candidateProfilePage.formSubmitCount
  );
  const language = useSelector((state) => state.language.language);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [candidateWorkExperiences, setCandidateWorkExperiences] =
    useState(null);
  // Dialog
  const [
    candidateWorkExperienceActionDialogText,
    setCandidateWorkExperienceActionDialogText,
  ] = useState("");
  const [
    candidateWorkExperienceActionDialogType,
    setCandidateWorkExperienceActionDialogType,
  ] = useState(null);
  const [
    showCandidateWorkExperienceActionDialog,
    setShowCandidateWorkExperienceActionDialog,
  ] = useState(false);
  // Hover
  const [currentItemId, setCurrentItemId] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [
    showCandidateWorkExperienceActionMenu,
    setShowCandidateWorkExperienceActionMenu,
  ] = useState(null);
  // Modals
  const [
    showCandidateWorkExperienceActionModal,
    setShowCandidateWorkExperienceActionModal,
  ] = useState(false);
  const [showFileViewModal, setShowFileViewModal] = useState(false);

  // Handle States
  const itemsArr = candidateWorkExperiences;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Set States
    setCurrentItemId(itemId);
    displayCandidateWorkExperienceActionModal();
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setShowCandidateWorkExperienceActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setShowCandidateWorkExperienceActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onCandidateWorkExperienceActionDialogCanceled = () => {
    // Set States
    setShowCandidateWorkExperienceActionDialog(false);
  };

  const onCandidateWorkExperienceActionDialogConfirmed = () => {
    switch (candidateWorkExperienceActionDialogType) {
      case "DeleteCandidateWorkExperience":
        deleteWorkExperience();
        break;
      default:
        break;
    }

    // Set States
    setShowCandidateWorkExperienceActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onCandidateWorkExperienceActionModalClosed = () => {
    // Set States
    setShowCandidateWorkExperienceActionModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  const onFileViewModalClosed = () => {
    // Set States
    setShowFileViewModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Set States
    setCurrentItemId(null);
    displayCandidateWorkExperienceActionModal();
  };

  // Functions
  // Functions - Normal
  const displayCandidateWorkExperienceActionDialog = (
    candidateWorkExperienceActionType
  ) => {
    // Set States
    setCandidateWorkExperienceActionDialogType(
      candidateWorkExperienceActionType
    );

    switch (candidateWorkExperienceActionType) {
      case "DeleteCandidateWorkExperience":
        setCandidateWorkExperienceActionDialogText(
          t("確認要刪除 工作經驗 嗎？")
        );
        break;
      default:
        break;
    }

    // Set States
    setShowCandidateWorkExperienceActionDialog(true);
  };

  const displayCandidateWorkExperienceActionModal = () => {
    setShowCandidateWorkExperienceActionModal(true);
  };

  const displayFileViewModal = () => {
    setShowFileViewModal(true);
  };

  // Functions - Mutations
  const deleteWorkExperience = async () => {
    const results = await deleteWorkExperienceFetch(token, currentItemId);

    if (results.success) {
      getAllWorkExperiencesByCandidateId();
    }
  };

  // Functions - Queries
  const getAllWorkExperiencesByCandidateId = async () => {
    const results = await getAllWorkExperiencesByCandidateIdFetch(
      token,
      candidateId
    );

    if (results.workExperiences) {
      for (let item of results.workExperiences) {
        item.start_month = item.start_month
          ? moment(item.start_month).format(t("YYYY年 MM月"))
          : null;

        item.end_month = item.end_month
          ? moment(item.end_month).format(t("YYYY年 MM月"))
          : null;

        item.duration = item.duration
          ? monthsToYearsAndMonths(language, item.duration)
          : "";

        item.salary = item.salary
          ? `$ ${separateComma(Number(item.salary).toFixed())}`
          : null;
      }
    }

    // Set States
    setCandidateWorkExperiences(
      results.workExperiences ? results.workExperiences : null
    );
  };

  // Life Cycle
  useEffect(() => {
    if (candidateId) {
      getAllWorkExperiencesByCandidateId();
    }
  }, [formSubmitCount, candidateId, language]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onCandidateWorkExperienceActionDialogCanceled}
        onDialogConfirmed={onCandidateWorkExperienceActionDialogConfirmed}
        // States
        dialogText={candidateWorkExperienceActionDialogText}
        showDialog={showCandidateWorkExperienceActionDialog}
      />
      {/* Menu */}
      <CandidateWorkExperienceActionMenu
        // States
        showCandidateWorkExperienceActionMenu={
          showCandidateWorkExperienceActionMenu
        }
        // Set States
        setShowCandidateWorkExperienceActionMenu={
          setShowCandidateWorkExperienceActionMenu
        }
        // Functions
        displayCandidateWorkExperienceActionDialog={
          displayCandidateWorkExperienceActionDialog
        }
        displayCandidateWorkExperienceActionModal={
          displayCandidateWorkExperienceActionModal
        }
      />
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onCandidateWorkExperienceActionModalClosed}
        // States
        showModal={showCandidateWorkExperienceActionModal}
      >
        <CandidateWorkExperienceActionModal
          // Events
          onModalClosed={onCandidateWorkExperienceActionModalClosed}
          // Functions
          displayFileViewModal={displayFileViewModal}
          // States
          workExperienceId={currentItemId}
        />
      </ModalContainer>
      <ModalContainer
        // Events
        onModalClosed={onFileViewModalClosed}
        // States
        showModal={showFileViewModal}
      >
        <FileViewModal
          // States
          fieldId={currentItemId}
          fieldType="WorkExperience"
          modalTitle={t("工作經驗")}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("工作經驗s")}
          </Typography>
          <Tooltip placement="right" title={t("新增工作經驗")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <CandidateWorkExperienceBox
            key={item.id}
            // States
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有工作經驗資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default CandidateWorkExperienceBoxesContainer;
