// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// Icons
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function ApplicationProcedureBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    currentItemId,
    hoveredItemId,
    language,
    item,
    // Events
    onItemClicked,
    onItemRightClicked,
    onItemShowMenuBtnClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isHovered = hoveredItemId && hoveredItemId === item.id;
  const isSelected = currentItemId && currentItemId === item.id;

  return (
    <div
      className={
        isSelected
          ? classes.selectedItemContainer
          : isHovered
          ? classes.hoveredItemContainer
          : classes.itemContainer
      }
      onMouseEnter={() => onMouseEnterItem(item.id)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div
        className={classes.itemContentContainer}
        onClick={() => onItemClicked(item.id)}
        onContextMenu={(event) => {
          event.preventDefault();
          onItemRightClicked(item.id, event.currentTarget);
        }}
      >
        <div className={classes.itemContentSubContainer}>
          <Typography
            align={"left"}
            color={
              isSelected
                ? stylesConfig.whiteTextColor
                : isHovered
                ? stylesConfig.mainTextColor
                : null
            }
            variant="body1"
          >
            {item[t("application_procedure_name_ch")]}
          </Typography>
          <Typography
            align={"left"}
            color={
              isSelected
                ? stylesConfig.whiteTextColor
                : stylesConfig.greyTextColor
            }
            variant="body2"
          >
            {[1, 2].includes(item.application_dept_type_id)
              ? item[t("application_dept_type_name_ch")]
              : item.application_dept_type_id === 3
              ? item.dependencies.departments && item.dependencies.shops
                ? `${item.dependencies.departments
                    .map((deptItem) => deptItem[t("department_name_ch")])
                    .join(" / ")} / ${item.dependencies.shops
                    .map((shopItem) => shopItem.shop_code)
                    .join(" / ")}`
                : item.dependencies.departments
                ? item.dependencies.departments
                    .map((deptItem) => deptItem[t("department_name_ch")])
                    .join(" / ")
                : item.dependencies.shops
                    .map((shopItem) => shopItem.shop_code)
                    .join(" / ")
              : item.application_dept_type_id === 4
              ? item.dependencies.titles
                  .map((titleItem) => titleItem[t("title_name_ch")])
                  .join(" / ")
              : item.application_dept_type_id === 5
              ? item.dependencies.staffs
                ? item.dependencies.staffs
                    .map((staffItem) => staffItem[t("full_name_en")])
                    .join(" / ")
                : t("指定員工s")
              : ""}
            {item.application_staff_type_id && " - "}
            {item.application_staff_type_id === 1
              ? item[t("application_staff_type_name_ch")]
              : item.application_staff_type_id === 2
              ? language === "zh-hk"
                ? item[t("application_staff_type_name_ch")].slice(-2)
                : item[t("application_staff_type_name_ch")]
              : item.application_staff_type_id === 3
              ? item.dependencies.titles
                  .map((titleItem) => titleItem[t("full_name_en")])
                  .join(" / ")
              : ""}
          </Typography>
        </div>
      </div>
      <Stack direction="row" spacing={1}>
        <IconButton
          onClick={(event) =>
            onItemShowMenuBtnClicked(item.id, event.currentTarget)
          }
          sx={isSelected ? { color: stylesConfig.whiteTextColor } : null}
        >
          <MenuRoundedIcon />
        </IconButton>
      </Stack>
    </div>
  );
}

export default ApplicationProcedureBox;
