// Components
import StyledMenu from "../../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

function AttendanceActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // States
    currentItem,
    showAttendanceActionMenu,
    // Set States
    setShowAttendanceActionMenu,
    // Functions
    displayAttendanceActionDialog,
  } = props;

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowAttendanceActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "SetException":
        displayAttendanceActionDialog("SetException");
        break;
      case "SetForCount":
        displayAttendanceActionDialog("SetForCount");
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showAttendanceActionMenu}
      open={Boolean(showAttendanceActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      {currentItem && !currentItem.is_for_count && (
        <MenuItem
          disableRipple
          onClick={() => onMenuItemClicked("SetForCount")}
        >
          <EditRoundedIcon />
          {t("用作考勤")}
        </MenuItem>
      )}
      {currentItem &&
        !currentItem.is_exception &&
        (!currentItem.is_on_time || !currentItem.is_location_correct) && (
          <MenuItem
            disableRipple
            onClick={() => onMenuItemClicked("SetException")}
          >
            <CheckRoundedIcon />
            {t("豁免")}
          </MenuItem>
        )}
    </StyledMenu>
  );
}

export default AttendanceActionMenu;
