// Configs
import apisConfig from "../configs/apisConfig";

export const getApplicationCaptionsFetch = async (
  token,
  applicationId,
  applicationQuestionId,
  answerValue
) => {
  try {
    const queryRoute = "/applicationCaption/getApplicationCaptions";

    const reqBody = {
      applicationId,
      applicationQuestionId,
      answerValue,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
