// Configs
import apisConfig from "../configs/apisConfig";

export const createSkillFetch = async (
  token,
  candidateId,
  skillName,
  skillDescription
) => {
  try {
    const queryRoute = "/skill/createSkill";

    const reqBody = {
      candidateId,
      skillName,
      skillDescription,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteSkillFetch = async (token, skillId) => {
  try {
    const queryRoute = `/skill/deleteSkill/${skillId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editSkillFetch = async (
  token,
  skillId,
  skillName,
  skillDescription
) => {
  try {
    const queryRoute = `/skill/editSkill/${skillId}`;

    const reqBody = {
      skillName,
      skillDescription,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllSkillsByCandidateIdFetch = async (token, candidateId) => {
  try {
    const queryRoute = `/skill/getAllSkillsByCandidateId/${candidateId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getSkillDetailsByIdFetch = async (token, skillId) => {
  try {
    const queryRoute = `/skill/getSkillDetailsById/${skillId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
