// Components
// Boxes
import DayOffTypeRuleBox from "./dayOffTypeRuleBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import DayOffTypeRuleActionMenu from "../../menus/dayOffTypesPage/dayOffTypeRuleActionMenu";
// Modals
import ModalContainer from "../../modals/modalContainer";
import DayOffTypeRuleActionModal from "../../modals/dayOffTypesPage/dayOffTypeRuleActionModal";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteDayOffTypeRuleFetch,
  getAllDayOffTypeRulesByDayOffTypeIdFetch,
  getDayOffTypeRulesDetailByDayOffTypeIdFetch,
} from "../../../fetches/dayOffTypeRuleFetches";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function DayOffTypeRuleBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const dayOffTypeId = useSelector(
    (state) => state.dayOffTypesPage.dayOffTypeId
  );
  const formSubmitCount = useSelector(
    (state) => state.dayOffTypesPage.formSubmitCount
  );
  const language = useSelector((state) => state.language.language);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [dayOffTypeCountTypeNameCh, setDayOffTypeCountTypeNameCh] =
    useState("");
  const [dayOffTypeCountTypeNameEn, setDayOffTypeCountTypeNameEn] =
    useState("");
  const [dayOffTypeRuleTypeNameEn, setDayOffTypeRuleTypeNameEn] = useState("");
  const [dayOffTypeRules, setDayOffTypeRules] = useState(null);
  const [isRuleAddable, setIsRuleAddable] = useState(false);
  const [isRulesApplicable, setIsRulesApplicable] = useState(false);
  // Dialog
  const [dayOffTypeRuleActionDialogText, setDayOffTypeRuleActionDialogText] =
    useState("");
  const [dayOffTypeRuleActionDialogType, setDayOffTypeRuleActionDialogType] =
    useState(null);
  const [showDayOffTypeRuleActionDialog, setShowDayOffTypeRuleActionDialog] =
    useState(false);
  // Hover
  const [currentItemId, setCurrentItemId] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showDayOffTypeRuleActionMenu, setShowDayOffTypeRuleActionMenu] =
    useState(null);
  // Modals
  const [showDayOffTypeRuleActionModal, setShowDayOffTypeRuleActionModal] =
    useState(false);

  // Handle States
  const itemsArr = dayOffTypeRules;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Set States
    setCurrentItemId(itemId);
    displayDayOffTypeRuleActionModal();
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setShowDayOffTypeRuleActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setShowDayOffTypeRuleActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onDayOffTypeRuleActionDialogCanceled = () => {
    // Set States
    setShowDayOffTypeRuleActionDialog(false);
  };

  const onDayOffTypeRuleActionDialogConfirmed = () => {
    switch (dayOffTypeRuleActionDialogType) {
      case "DeleteDayOffTypeRule":
        deleteDayOffTypeRule();
        break;
      default:
        break;
    }

    // Set States
    setShowDayOffTypeRuleActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onDayOffTypeRuleActionModalClosed = () => {
    getAllDayOffTypeRules();
    // Set States
    setShowDayOffTypeRuleActionModal(false);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Set States
    setCurrentItemId(null);
    displayDayOffTypeRuleActionModal();
  };

  // Functions
  // Functions - Normal
  const clearData = () => {
    // Set States
    setDayOffTypeRules(null);
  };

  const displayDayOffTypeRuleActionDialog = (dayOffTypeRuleActionType) => {
    // Set States
    setDayOffTypeRuleActionDialogType(dayOffTypeRuleActionType);

    switch (dayOffTypeRuleActionType) {
      case "DeleteDayOffTypeRule":
        setDayOffTypeRuleActionDialogText(t("確認要刪除 假期規則 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowDayOffTypeRuleActionDialog(true);
  };

  const displayDayOffTypeRuleActionModal = () => {
    setShowDayOffTypeRuleActionModal(true);
  };

  const getAllDayOffTypeRules = () => {
    getAllDayOffTypeRulesByDayOffTypeId();
    getDayOffTypeRulesDetailByDayOffTypeId();
  };

  // Functions - Mutations
  const deleteDayOffTypeRule = async () => {
    const results = await deleteDayOffTypeRuleFetch(token, currentItemId);

    if (results.success) {
      getAllDayOffTypeRules();
    }
  };

  // Functions - Queries
  const getAllDayOffTypeRulesByDayOffTypeId = async () => {
    const results = await getAllDayOffTypeRulesByDayOffTypeIdFetch(
      token,
      dayOffTypeId
    );

    // Set States
    setDayOffTypeRules(
      results.dayOffTypeRules ? results.dayOffTypeRules : null
    );
  };

  const getDayOffTypeRulesDetailByDayOffTypeId = async () => {
    const results = await getDayOffTypeRulesDetailByDayOffTypeIdFetch(
      token,
      dayOffTypeId
    );

    // Set States
    setDayOffTypeCountTypeNameCh(
      results.dayOffTypeCountTypeNameCh ? results.dayOffTypeCountTypeNameCh : ""
    );
    setDayOffTypeCountTypeNameEn(
      results.dayOffTypeCountTypeNameEn ? results.dayOffTypeCountTypeNameEn : ""
    );
    setDayOffTypeRuleTypeNameEn(
      results.dayOffTypeRuleTypeNameEn ? results.dayOffTypeRuleTypeNameEn : ""
    );
    setIsRuleAddable(results.isRuleAddable ? results.isRuleAddable : false);
    setIsRulesApplicable(
      results.isRulesApplicable ? results.isRulesApplicable : false
    );
  };

  // Life Cycle
  useEffect(() => {
    if (dayOffTypeId) {
      getAllDayOffTypeRules();
    } else {
      clearData();
    }
  }, [dayOffTypeId, formSubmitCount]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onDayOffTypeRuleActionDialogCanceled}
        onDialogConfirmed={onDayOffTypeRuleActionDialogConfirmed}
        // States
        dialogText={dayOffTypeRuleActionDialogText}
        showDialog={showDayOffTypeRuleActionDialog}
      />
      {/* Menu */}
      <DayOffTypeRuleActionMenu
        // States
        showDayOffTypeRuleActionMenu={showDayOffTypeRuleActionMenu}
        // Set States
        setShowDayOffTypeRuleActionMenu={setShowDayOffTypeRuleActionMenu}
        // Functions
        displayDayOffTypeRuleActionDialog={displayDayOffTypeRuleActionDialog}
        displayDayOffTypeRuleActionModal={displayDayOffTypeRuleActionModal}
      />
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onDayOffTypeRuleActionModalClosed}
        // States
        showModal={showDayOffTypeRuleActionModal}
      >
        <DayOffTypeRuleActionModal
          // Events
          onModalClosed={onDayOffTypeRuleActionModalClosed}
          // States
          dayOffTypeCountTypeNameCh={dayOffTypeCountTypeNameCh}
          dayOffTypeRuleId={currentItemId}
          dayOffTypeRuleTypeNameEn={dayOffTypeRuleTypeNameEn}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("假期規則s")}
          </Typography>
          {isRuleAddable && (
            <Tooltip placement="right" title={t("新增假期規則")}>
              <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
                <AddRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <DayOffTypeRuleBox
            key={item.id}
            // States
            dayOffTypeCountTypeNameCh={dayOffTypeCountTypeNameCh}
            dayOffTypeCountTypeNameEn={dayOffTypeCountTypeNameEn}
            dayOffTypeRuleTypeNameEn={dayOffTypeRuleTypeNameEn}
            hoveredItemId={hoveredItemId}
            item={item}
            language={language}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {isRulesApplicable ? t("未有假期規則資料") : t("不適用")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default DayOffTypeRuleBoxesContainer;
