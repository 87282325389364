// Configs
import apisConfig from "../configs/apisConfig";

export const createDayOffTypeRuleFetch = async (
  token,
  dayOffTypeId,
  ruleTypeNum,
  quotaNum
) => {
  try {
    const queryRoute = "/dayOffTypeRule/createDayOffTypeRule";

    const reqBody = {
      dayOffTypeId,
      ruleTypeNum,
      quotaNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteDayOffTypeRuleFetch = async (token, dayOffTypeRuleId) => {
  try {
    const queryRoute = `/dayOffTypeRule/deleteDayOffTypeRule/${dayOffTypeRuleId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editDayOffTypeRuleFetch = async (
  token,
  dayOffTypeRuleId,
  ruleTypeNum,
  quotaNum
) => {
  try {
    const queryRoute = `/dayOffTypeRule/editDayOffTypeRule/${dayOffTypeRuleId}`;

    const reqBody = {
      ruleTypeNum,
      quotaNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllDayOffTypeRulesByDayOffTypeIdFetch = async (
  token,
  dayOffTypeId
) => {
  try {
    const queryRoute = `/dayOffTypeRule/getAllDayOffTypeRulesByDayOffTypeId/${dayOffTypeId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getDayOffTypeRuleDetailsByIdFetch = async (
  token,
  dayOffTypeRuleId
) => {
  try {
    const queryRoute = `/dayOffTypeRule/getDayOffTypeRuleDetailsById/${dayOffTypeRuleId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getDayOffTypeRulesDetailByDayOffTypeIdFetch = async (
  token,
  dayOffTypeId
) => {
  try {
    const queryRoute = `/dayOffTypeRule/getDayOffTypeRulesDetailByDayOffTypeId/${dayOffTypeId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
