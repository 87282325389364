export function onBrandChange(brandId) {
  return {
    type: "ON_SALARIES_PAGE_BRAND_CHANGE",
    brandId,
  };
}

export function onBrandItemChange(brandItem) {
  return {
    type: "ON_SALARIES_PAGE_BRAND_ITEM_CHANGE",
    brandItem,
  };
}

export function onDepartmentChange(departmentId) {
  return {
    type: "ON_SALARIES_PAGE_DEPARTMENT_CHANGE",
    departmentId,
  };
}

export function onDivisionChange(divisionId, divisionType) {
  return {
    type: "ON_SALARIES_PAGE_DIVISION_CHANGE",
    divisionId,
    divisionType,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_SALARIES_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onMonthChange(month) {
  return {
    type: "ON_SALARIES_PAGE_MONTH_CHANGE",
    month,
  };
}

export function onSettingTypeChange(settingTypeValue) {
  return {
    type: "ON_SALARIES_PAGE_SETTING_TYPE_CHANGE",
    settingTypeValue,
  };
}

export function onSettingTypeItemChange(settingTypeItem) {
  return {
    type: "ON_SALARIES_PAGE_SETTING_TYPE_ITEM_CHANGE",
    settingTypeItem,
  };
}

export function onShopChange(shopId) {
  return {
    type: "ON_SALARIES_PAGE_SHOP_CHANGE",
    shopId,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_SALARIES_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}
