// Configs
import apisConfig from "../configs/apisConfig";

export const createOrEditRosterFetch = async (
  token,
  staffToDays,
  typeOfOption,
  optionId
) => {
  try {
    const queryRoute = "/roster/createOrEditRoster";

    const reqBody = {
      staffToDays,
      typeOfOption,
      optionId,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllRostersByDepartmentOrShopIdFetch = async (
  token,
  departmentOrShopId,
  typeOfData,
  startDate,
  endDate,
  additionalStaffIdsArr
) => {
  try {
    const queryRoute = "/roster/getAllRostersByDepartmentOrShopId";

    const reqBody = {
      departmentOrShopId,
      typeOfData,
      startDate,
      endDate,
      additionalStaffIdsArr,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const quickCreateRostersFetch = async (
  token,
  shopId,
  startDate,
  endDate
) => {
  try {
    const queryRoute = "/roster/quickCreateRosters";

    const reqBody = {
      shopId,
      startDate,
      endDate,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
