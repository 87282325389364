// Configs
import apisConfig from "../configs/apisConfig";

export const autoLoginFetch = async (token) => {
  try {
    const queryRoute = "/staff/autoLogin";

    const reqBody = {
      permissionName: "Sign In To Management Dashboard",
      isWeb: true,
      deviceToken: null,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const createStaffFetch = async (
  token,
  hkid,
  staffCode,
  genderTypeId,
  fullNameCh,
  fullNameEn,
  alias,
  dateJoin,
  staffStatusTypeId,
  probationLength,
  dateBirth,
  email,
  phoneNum,
  districtId,
  addressCh,
  addressEn,
  candidateId
) => {
  try {
    const queryRoute = "/staff/createStaff";

    const reqBody = {
      hkid,
      staffCode,
      genderTypeId,
      fullNameCh,
      fullNameEn,
      alias,
      dateJoin,
      staffStatusTypeId,
      probationLength,
      dateBirth,
      email,
      phoneNum,
      districtId,
      addressCh,
      addressEn,
      candidateId,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteStaffFetch = async (token, staffId) => {
  try {
    const queryRoute = `/staff/deleteStaff/${staffId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editStaffFetch = async (
  token,
  staffId,
  hkid,
  staffCode,
  genderTypeId,
  staffNameCh,
  staffNameEn,
  alias,
  dateBirth,
  email,
  phoneNum,
  districtId,
  addressCh,
  addressEn
) => {
  try {
    const queryRoute = `/staff/editStaff/${staffId}`;

    const reqBody = {
      hkid,
      staffCode,
      genderTypeId,
      staffNameCh,
      staffNameEn,
      alias,
      dateBirth,
      email,
      phoneNum,
      districtId,
      addressCh,
      addressEn,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editStaffIsAttendanceRequiredFetch = async (
  token,
  staffIdsArr,
  isAttendanceRequired
) => {
  try {
    const queryRoute = "/staff/editStaffIsAttendanceRequired";

    const reqBody = {
      staffIdsArr,
      isAttendanceRequired,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editStaffProbationLengthFetch = async (
  token,
  staffId,
  probationLength
) => {
  try {
    const queryRoute = `/staff/editStaffProbationLength/${staffId}`;

    const reqBody = {
      probationLength,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllStaffsBirthdayCurrentMonthWithPaginationFetch = async (
  token,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute = "/staff/getAllStaffsBirthdayCurrentMonthWithPagination";

    const reqBody = {
      pageNum,
      limitNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllStaffsByApplicationProcedureIdWithPaginationFetch = async (
  token,
  applicationProcedureId,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute = `/staff/getAllStaffsByApplicationProcedureIdWithPagination/${applicationProcedureId}`;

    const reqBody = {
      pageNum,
      limitNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllStaffsByDepartmentOrShopIdFetch = async (
  token,
  departmentOrShopId,
  typeOfData,
  typeOfStaffs
) => {
  try {
    const queryRoute = `/staff/getAllStaffsByDepartmentOrShopId/${departmentOrShopId}`;

    const reqBody = {
      typeOfData,
      typeOfStaffs,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllStaffsByPermissionGroupIdWithPaginationFetch = async (
  token,
  permissionGroupId,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute = `/staff/getAllStaffsByPermissionGroupIdWithPagination/${permissionGroupId}`;

    const reqBody = {
      pageNum,
      limitNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllStaffsByWorkingPeriodIdWithPaginationFetch = async (
  token,
  workingPeriodId,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute = `/staff/getAllStaffsByWorkingPeriodIdWithPagination/${workingPeriodId}`;

    const reqBody = {
      pageNum,
      limitNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllStaffsByWorkingPlaceIdWithPaginationFetch = async (
  token,
  workingPlaceId,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute = `/staff/getAllStaffsByWorkingPlaceIdWithPagination/${workingPlaceId}`;

    const reqBody = {
      pageNum,
      limitNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllStaffsPassingProbationWithPaginationFetch = async (
  token,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute = "/staff/getAllStaffsPassingProbationWithPagination";

    const reqBody = {
      pageNum,
      limitNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllStaffsWhereAttendanceNotRequiredWithPaginationFetch = async (
  token,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute =
      "/staff/getAllStaffsWhereAttendanceNotRequiredWithPagination";

    const reqBody = {
      pageNum,
      limitNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllStaffsWithRecentRelocationsWithPaginationFetch = async (
  token,
  typeOfRelocation,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute = "/staff/getAllStaffsWithRecentRelocationsWithPagination";

    const reqBody = {
      typeOfRelocation,
      pageNum,
      limitNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getStaffBasicInfoByIdFetch = async (token, staffId) => {
  try {
    const queryRoute = `/staff/getStaffBasicInfoById/${staffId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getStaffDetailsByIdFetch = async (token, staffId) => {
  try {
    const queryRoute = `/staff/getStaffDetailsById/${staffId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const loginFetch = async (fullNameOrStaffCode, password) => {
  try {
    const queryRoute = "/staff/login";

    const reqBody = {
      fullNameOrStaffCode,
      password,
      permissionName: "Sign In To Management Dashboard",
      isWeb: true,
      deviceToken: null,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
