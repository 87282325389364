// Components
// Boxes
import QuestionBoxContainer from "./questionBoxContainer";

// Configs
import stylesConfig from "../../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../../customHooks/getLanguage";

// Helper Functions
import { handleQuestions } from "../../../../helperFunctions/applicationQuestions";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// Icons
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

// Styles
import {
  formSubmitBtnStyles,
  formSubmitBtnGroupStyles,
  formSubmitBtnGroupBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function QuestionGroupBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onDateTimeInputFieldChange,
    onFileFieldClicked,
    onInputFieldKeyPressed,
    onLocationFieldClicked,
    onOptionInputFieldChange,
    onSubmitBtnClicked,
    onTextInputFieldChange,
    // Render
    isEditable,
    isLastItem,
    // States
    applicationOrApprovalTypeValue,
    formAlertText,
    formAlertType,
    groupIndex,
    isCancelable,
    item,
    shouldShowFormAlert,
  } = props;

  // Handle States
  const itemsArr = item.questions ? handleQuestions(item.questions) : null;

  return (
    <>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {item[t("group_title_text")]}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        {itemsArr ? (
          <>
            {itemsArr.map((questionItem, index) => (
              <QuestionBoxContainer
                key={questionItem.id}
                // Events
                onDateTimeInputFieldChange={onDateTimeInputFieldChange}
                onFileFieldClicked={onFileFieldClicked}
                onInputFieldKeyPressed={onInputFieldKeyPressed}
                onLocationFieldClicked={onLocationFieldClicked}
                onOptionInputFieldChange={onOptionInputFieldChange}
                onTextInputFieldChange={onTextInputFieldChange}
                // States
                groupIndex={groupIndex}
                index={index}
                item={questionItem}
              />
            ))}
          </>
        ) : (
          <Grid item xs={12}>
            <div className={classes.emptyDataContainer}>
              <Typography align={"start"} color={stylesConfig.greyTextColor}>
                {t("未有申請內容")}
              </Typography>
            </div>
          </Grid>
        )}
        {isLastItem && (
          <>
            <Grid item xs={12} className={classes.formSubmitBtnContainer}>
              {applicationOrApprovalTypeValue === "Applications" &&
                isCancelable && (
                  <Button
                    onClick={() => onSubmitBtnClicked("CancelApplication")}
                    endIcon={<DeleteRoundedIcon />}
                    variant="contained"
                    sx={formSubmitBtnStyles}
                  >
                    {t("取消")}
                  </Button>
                )}
              {applicationOrApprovalTypeValue === "Approvals" && isEditable && (
                <ButtonGroup sx={formSubmitBtnGroupStyles}>
                  <Button
                    onClick={() => onSubmitBtnClicked("EditApplication")}
                    endIcon={<EditRoundedIcon />}
                    variant="contained"
                    sx={formSubmitBtnGroupBtnStyles}
                  >
                    {t("修改")}
                  </Button>
                  <Button
                    onClick={() => onSubmitBtnClicked("RejectApplication")}
                    color="error"
                    endIcon={<ClearRoundedIcon />}
                    variant="outlined"
                    sx={formSubmitBtnGroupBtnStyles}
                  >
                    {t("駁回")}
                  </Button>
                  <Button
                    onClick={() => onSubmitBtnClicked("ApproveApplication")}
                    color="success"
                    endIcon={<DoneRoundedIcon />}
                    variant="outlined"
                    sx={formSubmitBtnGroupBtnStyles}
                  >
                    {t("批准")}
                  </Button>
                </ButtonGroup>
              )}
            </Grid>
            {/* Alert */}
            {shouldShowFormAlert && (
              <Grid item xs={12}>
                <Alert severity={formAlertType}>{formAlertText}</Alert>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  );
}

export default QuestionGroupBox;
