const today = new Date();
const currentMonth = new Date(today.getFullYear(), today.getMonth(), 1);

const initialState = {
  abnormalDate: today.toString(),
  attendanceRuleId: null,
  attendanceTypeId: null,
  brandId: null,
  brandItem: null,
  conditionAttendanceTypeId: null,
  conditionAttendanceTypeItem: null,
  conditionLocationTypeValue: null,
  conditionLocationTypeItem: null,
  conditionShopId: null,
  conditionShopItem: null,
  conditionWorkingPlaceId: null,
  conditionWorkingPlaceItem: null,
  departmentId: null,
  divisionId: null,
  divisionType: null,
  formSubmitCount: 0,
  month: currentMonth.toString(),
  ruleAttendanceTypeId: null,
  shopId: null,
  tabItem: "AttendanceRecords",
};

export const attendancesPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_ATTENDANCES_PAGE_ABNORMAL_DATE_CHANGE":
      const { abnormalDate } = action;
      return {
        ...state,
        abnormalDate: abnormalDate.toString(),
      };
    case "ON_ATTENDANCES_PAGE_ATTENDANCE_RULE_CHANGE":
      const { attendanceRuleId } = action;
      return {
        ...state,
        attendanceRuleId,
      };
    case "ON_ATTENDANCES_PAGE_ATTENDANCE_TYPE_CHANGE":
      const { attendanceTypeId } = action;
      return {
        ...state,
        attendanceTypeId,
      };
    case "ON_ATTENDANCES_PAGE_BRAND_CHANGE":
      const { brandId } = action;
      return {
        ...state,
        brandId,
      };
    case "ON_ATTENDANCES_PAGE_BRAND_ITEM_CHANGE":
      const { brandItem } = action;
      return {
        ...state,
        brandItem,
      };
    case "ON_ATTENDANCES_PAGE_CONDITION_ATTENDANCE_TYPE_CHANGE":
      const { conditionAttendanceTypeId } = action;
      return {
        ...state,
        conditionAttendanceTypeId,
      };
    case "ON_ATTENDANCES_PAGE_CONDITION_ATTENDANCE_TYPE_ITEM_CHANGE":
      const { conditionAttendanceTypeItem } = action;
      return {
        ...state,
        conditionAttendanceTypeItem,
      };
    case "ON_ATTENDANCES_PAGE_CONDITION_LOCATION_TYPE_CHANGE":
      const { conditionLocationTypeValue } = action;
      return {
        ...state,
        conditionLocationTypeValue,
      };
    case "ON_ATTENDANCES_PAGE_CONDITION_LOCATION_TYPE_ITEM_CHANGE":
      const { conditionLocationTypeItem } = action;
      return {
        ...state,
        conditionLocationTypeItem,
      };
    case "ON_ATTENDANCES_PAGE_CONDITION_SHOP_CHANGE":
      const { conditionShopId } = action;
      return {
        ...state,
        conditionShopId,
      };
    case "ON_ATTENDANCES_PAGE_CONDITION_SHOP_ITEM_CHANGE":
      const { conditionShopItem } = action;
      return {
        ...state,
        conditionShopItem,
      };
    case "ON_ATTENDANCES_PAGE_CONDITION_WORKING_PLACE_CHANGE":
      const { conditionWorkingPlaceId } = action;
      return {
        ...state,
        conditionWorkingPlaceId,
      };
    case "ON_ATTENDANCES_PAGE_CONDITION_WORKING_PLACE_ITEM_CHANGE":
      const { conditionWorkingPlaceItem } = action;
      return {
        ...state,
        conditionWorkingPlaceItem,
      };
    case "ON_ATTENDANCES_PAGE_DEPARTMENT_CHANGE":
      const { departmentId } = action;
      return {
        ...state,
        departmentId,
      };
    case "ON_ATTENDANCES_PAGE_DIVISION_CHANGE":
      const { divisionId, divisionType } = action;
      return {
        ...state,
        divisionId,
        divisionType,
      };
    case "ON_ATTENDANCES_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_ATTENDANCES_PAGE_MONTH_CHANGE":
      const { month } = action;
      return {
        ...state,
        month: month.toString(),
      };
    case "ON_ATTENDANCES_PAGE_RULE_ATTENDANCE_TYPE_CHANGE":
      const { ruleAttendanceTypeId } = action;
      return {
        ...state,
        ruleAttendanceTypeId,
      };
    case "ON_ATTENDANCES_PAGE_SHOP_CHANGE":
      const { shopId } = action;
      return {
        ...state,
        shopId,
      };
    case "ON_ATTENDANCES_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    default:
      return state;
  }
};
