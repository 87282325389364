// Actions
import {
  onTabItemChange,
  onTypeOfCompsAndBrandsChange,
} from "../../../redux/pages/compsAndBrandsPage/compsAndBrandsPageActions";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  selectBoxContainerStyles,
  useStandardListPageStyles,
} from "../../../styles/pageStyles/standardListPageStyles";

function CompsAndBrandsPageHeader() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardListPageStyles();

  // Redux Store
  const tabItem = useSelector((state) => state.compsAndBrandsPage.tabItem);
  const typeOfCompsAndBrands = useSelector(
    (state) => state.compsAndBrandsPage.typeOfCompsAndBrands
  );

  // Events
  // Events - Select Box
  const onTypeOfCompsAndBrandsSelectBoxValueChanged = (value) => {
    // Update Redux Store
    dispatch(onTypeOfCompsAndBrandsChange(value));
  };

  // Events - Tabs
  const onTabClicked = (event, value) => {
    // Update Redux Store
    dispatch(onTabItemChange(value));
  };

  return (
    <Box className={classes.divisionsContainer}>
      {/* Tabs */}
      <div className={classes.tabsContainer}>
        <Tabs
          scrollButtons={false}
          value={tabItem}
          variant="scrollable"
          onChange={onTabClicked}
        >
          <Tab
            disableRipple
            key={"Companies"}
            label={
              <Typography variant="h6" align={"center"} gutterBottom>
                {t("公司s")}
              </Typography>
            }
            value={"Companies"}
          />
          <Tab
            disableRipple
            key={"Brands"}
            label={
              <Typography variant="h6" align={"center"} gutterBottom>
                {t("品牌s")}
              </Typography>
            }
            value={"Brands"}
          />
        </Tabs>
      </div>
      {/* Select Boxes */}
      <div>
        <FormControl variant="standard" sx={selectBoxContainerStyles}>
          <Select
            value={typeOfCompsAndBrands}
            onChange={(event) =>
              onTypeOfCompsAndBrandsSelectBoxValueChanged(event.target.value)
            }
          >
            <MenuItem value={"Current"}>{`${t("顯示有效")}${
              tabItem === "Companies" ? t(" 公司s") : t(" 品牌s")
            }`}</MenuItem>
            <MenuItem value={"Deleted"}>{`${t("顯示已刪除")}${
              tabItem === "Companies" ? t(" 公司s") : t(" 品牌s")
            }`}</MenuItem>
          </Select>
        </FormControl>
      </div>
    </Box>
  );
}

export default CompsAndBrandsPageHeader;
