// Configs
import apisConfig from "../configs/apisConfig";

export const createDepartmentFetch = async (
  token,
  departmentNameCh,
  departmentNameEnFull,
  departmentNameEnShort,
  isActive,
  divisionId
) => {
  try {
    const queryRoute = "/department/createDepartment";

    const reqBody = {
      departmentNameCh,
      departmentNameEnFull,
      departmentNameEnShort,
      isActive,
      divisionId,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteOrRestoreDepartmentFetch = async (
  token,
  departmentId,
  typeOfDepts
) => {
  try {
    const queryRoute = `/department/deleteOrRestoreDepartment/${departmentId}`;

    const reqBody = { typeOfDepts };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editDepartmentFetch = async (
  token,
  departmentId,
  departmentNameCh,
  departmentNameEnFull,
  departmentNameEnShort,
  headStaffIdsArr,
  isActive
) => {
  try {
    const queryRoute = `/department/editDepartment/${departmentId}`;

    const reqBody = {
      departmentNameCh,
      departmentNameEnFull,
      departmentNameEnShort,
      headStaffIdsArr,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllDepartmentsByDivisionIdFetch = async (
  token,
  divisionId,
  typeOfDepts
) => {
  try {
    const queryRoute = `/department/getAllDepartmentsByDivisionId/${divisionId}`;

    const reqBody = {
      typeOfDepts,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getDepartmentDetailsByIdFetch = async (token, departmentId) => {
  try {
    const queryRoute = `/department/getDepartmentDetailsById/${departmentId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
