const initialState = {
  formSubmitCount: 0,
  tabItem: "WorkingPeriods",
  typeOfWorkingPeriodsAndPlaces: "Current",
  workingPeriodId: null,
  workingPeriodItem: null,
  workingPlaceId: null,
  workingPlaceItem: null,
  workingPlaceTypeId: null,
  workingPlaceTypeItem: null,
};

export const workingPeriodsAndPlacesPageReducers = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "ON_WORKING_PERIODS_AND_PLACES_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_WORKING_PERIODS_AND_PLACES_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    case "ON_WORKING_PERIODS_AND_PLACES_PAGE_TYPE_OF_WORKING_PERIODS_CHANGE":
      const { typeOfWorkingPeriodsAndPlaces } = action;
      return {
        ...state,
        typeOfWorkingPeriodsAndPlaces,
      };
    case "ON_WORKING_PERIODS_AND_PLACES_PAGE_WORKING_PERIOD_CHANGE":
      const { workingPeriodId } = action;
      return {
        ...state,
        workingPeriodId,
      };
    case "ON_WORKING_PERIODS_AND_PLACES_PAGE_WORKING_PERIOD_ITEM_CHANGE":
      const { workingPeriodItem } = action;
      return {
        ...state,
        workingPeriodItem,
      };
    case "ON_WORKING_PERIODS_AND_PLACES_PAGE_WORKING_PLACE_CHANGE":
      const { workingPlaceId } = action;
      return {
        ...state,
        workingPlaceId,
      };
    case "ON_WORKING_PERIODS_AND_PLACES_PAGE_WORKING_PLACE_ITEM_CHANGE":
      const { workingPlaceItem } = action;
      return {
        ...state,
        workingPlaceItem,
      };
    case "ON_WORKING_PERIODS_AND_PLACES_PAGE_WORKING_PLACE_TYPE_CHANGE":
      const { workingPlaceTypeId } = action;
      return {
        ...state,
        workingPlaceTypeId,
      };
    case "ON_WORKING_PERIODS_AND_PLACES_PAGE_WORKING_PLACE_TYPE_ITEM_CHANGE":
      const { workingPlaceTypeItem } = action;
      return {
        ...state,
        workingPlaceTypeItem,
      };
    default:
      return state;
  }
};
