// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardTableStyles } from "../../../styles/componentStyles/tableStyles/standardTableStyles";

function AttendanceFieldBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardTableStyles();

  // Props
  const {
    // States
    hoveredAttendanceFieldId,
    item,
  } = props;

  return (
    <div>
      <Typography
        align={"center"}
        className={
          hoveredAttendanceFieldId === item.id
            ? classes.selectedCellBoxLarge
            : classes.cellBoxLargeWithoutPointer
        }
        color={
          hoveredAttendanceFieldId === item.id
            ? stylesConfig.whiteTextColor
            : null
        }
        variant="body1"
      >
        {t(`${item.text}s`)}
      </Typography>
      <Typography
        align={"center"}
        className={classes.cellBoxLargeWithoutPointer}
        color={stylesConfig.greyTextColor}
        variant="body2"
      >
        {t(item.subText)}
      </Typography>
    </div>
  );
}

export default AttendanceFieldBox;
