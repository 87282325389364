const initialState = {
  divisionId: null,
  formSubmitCount: 0,
  titleId: null,
  typeOfTitles: "Current",
};

export const titlesPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_TITLES_PAGE_DIVISION_CHANGE":
      const { divisionId } = action;
      return {
        ...state,
        divisionId,
      };
    case "ON_TITLES_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_TITLES_PAGE_TITLE_CHANGE":
      const { titleId } = action;
      return {
        ...state,
        titleId,
      };
    case "ON_TITLES_PAGE_TYPE_OF_TITLES_CHANGE":
      const { typeOfTitles } = action;
      return {
        ...state,
        typeOfTitles,
      };
    default:
      return state;
  }
};
