// Components
// Boxes
import WorkingPlaceWiFiBox from "./workingPlaceWiFiBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import WorkingPlaceWiFiActionMenu from "../../menus/workingPeriodsAndPlacesPage/workingPlaceWiFiActionMenu";
// Modals
import ModalContainer from "../../modals/modalContainer";
import WorkingPlaceWiFiActionModal from "../../modals/workingPeriodsAndPlacesPage/workingPlaceWiFiActionModal";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteWiFiFetch,
  getAllWiFisByShopOrWorkingPlaceIdFetch,
} from "../../../fetches/wiFiFetches";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function WorkingPlaceWiFiBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const workingPlaceId = useSelector(
    (state) => state.workingPeriodsAndPlacesPage.workingPlaceId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [workingPlaceWiFis, setWorkingPlaceWiFis] = useState(null);
  // Dialog
  const [
    workingPlaceWiFiActionDialogText,
    setWorkingPlaceWiFiActionDialogText,
  ] = useState("");
  const [
    workingPlaceWiFiActionDialogType,
    setWorkingPlaceWiFiActionDialogType,
  ] = useState(null);
  const [
    showWorkingPlaceWiFiActionDialog,
    setShowWorkingPlaceWiFiActionDialog,
  ] = useState(false);
  // Hover
  const [currentItemId, setCurrentItemId] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showWorkingPlaceWiFiActionMenu, setShowWorkingPlaceWiFiActionMenu] =
    useState(null);
  // Modals
  const [showWorkingPlaceWiFiActionModal, setShowWorkingPlaceWiFiActionModal] =
    useState(false);

  // Handle States
  const itemsArr = workingPlaceWiFis;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Set States
    setCurrentItemId(itemId);
    displayWorkingPlaceWiFiActionModal();
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setShowWorkingPlaceWiFiActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setShowWorkingPlaceWiFiActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onWorkingPlaceWiFiActionDialogCanceled = () => {
    // Set States
    setShowWorkingPlaceWiFiActionDialog(false);
  };

  const onWorkingPlaceWiFiActionDialogConfirmed = () => {
    switch (workingPlaceWiFiActionDialogType) {
      case "DeleteWorkingPlaceWiFi":
        deleteWiFi();
        break;
      default:
        break;
    }

    // Set States
    setShowWorkingPlaceWiFiActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onWorkingPlaceWiFiActionModalClosed = () => {
    getAllWiFisByShopOrWorkingPlaceId();
    // Set States
    setShowWorkingPlaceWiFiActionModal(false);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Set States
    setCurrentItemId(null);
    displayWorkingPlaceWiFiActionModal();
  };

  // Functions
  // Functions - Normal
  const clearData = () => {
    // Set States
    setWorkingPlaceWiFis(null);
  };

  const displayWorkingPlaceWiFiActionDialog = (workingPlaceWiFiActionType) => {
    // Set States
    setWorkingPlaceWiFiActionDialogType(workingPlaceWiFiActionType);

    switch (workingPlaceWiFiActionType) {
      case "DeleteWorkingPlaceWiFi":
        setWorkingPlaceWiFiActionDialogText(t("確認要刪除 Wi-Fi 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowWorkingPlaceWiFiActionDialog(true);
  };

  const displayWorkingPlaceWiFiActionModal = () => {
    setShowWorkingPlaceWiFiActionModal(true);
  };

  // Functions - Mutations
  const deleteWiFi = async () => {
    const results = await deleteWiFiFetch(token, currentItemId);

    if (results.success) {
      getAllWiFisByShopOrWorkingPlaceId();
    }
  };

  // Functions - Queries
  const getAllWiFisByShopOrWorkingPlaceId = async () => {
    const results = await getAllWiFisByShopOrWorkingPlaceIdFetch(
      token,
      workingPlaceId,
      "WorkingPlaces"
    );

    // Set States
    setWorkingPlaceWiFis(results.wiFis ? results.wiFis : null);
  };

  // Life Cycle
  useEffect(() => {
    if (workingPlaceId) {
      getAllWiFisByShopOrWorkingPlaceId();
    } else {
      clearData();
    }
  }, [workingPlaceId]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onWorkingPlaceWiFiActionDialogCanceled}
        onDialogConfirmed={onWorkingPlaceWiFiActionDialogConfirmed}
        // States
        dialogText={workingPlaceWiFiActionDialogText}
        showDialog={showWorkingPlaceWiFiActionDialog}
      />
      {/* Menu */}
      <WorkingPlaceWiFiActionMenu
        // States
        showWorkingPlaceWiFiActionMenu={showWorkingPlaceWiFiActionMenu}
        // Set States
        setShowWorkingPlaceWiFiActionMenu={setShowWorkingPlaceWiFiActionMenu}
        // Functions
        displayWorkingPlaceWiFiActionDialog={
          displayWorkingPlaceWiFiActionDialog
        }
        displayWorkingPlaceWiFiActionModal={displayWorkingPlaceWiFiActionModal}
      />
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onWorkingPlaceWiFiActionModalClosed}
        // States
        showModal={showWorkingPlaceWiFiActionModal}
      >
        <WorkingPlaceWiFiActionModal
          // Events
          onModalClosed={onWorkingPlaceWiFiActionModalClosed}
          // States
          wiFiId={currentItemId}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            Wi-Fis
          </Typography>
          <Tooltip placement="right" title={t("新增 Wi-Fi")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item, index) => (
          <WorkingPlaceWiFiBox
            key={item.id}
            // States
            hoveredItemId={hoveredItemId}
            index={index}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有 Wi-Fi 資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default WorkingPlaceWiFiBoxesContainer;
