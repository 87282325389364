// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { offerCandidateFetch } from "../../../fetches/candidateFetches";
import {
  editOfferFetch,
  getOfferDetailsByIdFetch,
  getOfferSuggestionsByCandidateIdFetch,
} from "../../../fetches/offerFetches";
import { getCandidateOfferFormOptionsFetch } from "../../../fetches/formOptionFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function CandidateOfferActionModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
    // States
    candidateId,
    offerId,
  } = props;

  // Redux Store
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Date Fields
  const [onboardDate, setOnboardDate] = useState(null);
  // Dialog
  const [candidateOfferActionDialogText, setCandidateOfferActionDialogText] =
    useState("");
  const [candidateOfferActionDialogType, setCandidateOfferActionDialogType] =
    useState(null);
  const [showCandidateOfferActionDialog, setShowCandidateOfferActionDialog] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [salaryCountTypeField, setSalaryCountTypeField] = useState(null);
  const [staffContractTypeField, setStaffContractTypeField] = useState(null);
  const [titleField, setTitleField] = useState(null);
  // Options
  const [salaryCountTypeOptions, setSalaryCountTypeOptions] = useState([]);
  const [staffContractTypeOptions, setStaffContractTypeOptions] = useState([]);
  const [titleOptions, setTitleOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Switches
  const [isCommission, setIsCommission] = useState(true);
  const [isLunchIncluded, setIsLunchIncluded] = useState(true);
  // Text Fields
  const [fixedSalary, setFixedSalary] = useState("");

  // Handle States
  const onboardDateVar = onboardDate ? new Date(onboardDate) : null;

  // Events
  // Events - Dialogs
  const onCandidateOfferActionDialogCanceled = () => {
    // Set States
    setShowCandidateOfferActionDialog(false);
  };

  const onCandidateOfferActionDialogConfirmed = () => {
    switch (candidateOfferActionDialogType) {
      case "EditCandidateOffer":
        editOffer();
        break;
      case "OfferCandidate":
        offerCandidate();
        break;
      default:
        break;
    }

    // Set States
    setShowCandidateOfferActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "fixedSalary":
        setFixedSalary(value);
        break;
      case "isCommission":
        setIsCommission(value);
        break;
      case "isLunchIncluded":
        setIsLunchIncluded(value);
        break;
      case "onboardDate":
        setOnboardDate(value);
        break;
      case "salaryCountTypeField":
        setSalaryCountTypeField(value);
        break;
      case "staffContractTypeField":
        setStaffContractTypeField(value);
        break;
      case "titleField":
        setTitleField(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!fixedSalary) {
      addToErrorFields("fixedSalary", t("請先填寫 固定工資"));
      isError = true;
    } else {
      if (isNaN(fixedSalary)) {
        addToErrorFields("fixedSalary", t("固定工資 必須為數字"));
        isError = true;
      }
    }

    if (!onboardDate) {
      addToErrorFields("onboardDate", t("請先填寫 入職日期"));
      isError = true;
    }

    if (!salaryCountTypeField) {
      addToErrorFields("salaryCountTypeField", t("請先填寫 工資單位"));
      isError = true;
    }

    if (!staffContractTypeField) {
      addToErrorFields("staffContractTypeField", t("請先填寫 合約類型"));
      isError = true;
    }

    if (!titleField) {
      addToErrorFields("titleField", t("請先填寫 職位"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayCandidateOfferActionDialog(
      offerId ? "EditCandidateOffer" : "OfferCandidate"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displayCandidateOfferActionDialog = (candidateOfferActionType) => {
    // Set States
    setCandidateOfferActionDialogType(candidateOfferActionType);

    switch (candidateOfferActionType) {
      case "EditCandidateOffer":
        setCandidateOfferActionDialogText(t("確認要編輯 薪資待遇 嗎？"));
        break;
      case "OfferCandidate":
        setCandidateOfferActionDialogText(t("確認要 錄取 應徵者嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowCandidateOfferActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const offerCandidate = async () => {
    const results = await offerCandidateFetch(
      token,
      candidateId,
      staffContractTypeField ? staffContractTypeField.id : null,
      salaryCountTypeField ? salaryCountTypeField.id : null,
      titleField ? titleField.id : null,
      fixedSalary,
      onboardDate,
      isCommission,
      isLunchIncluded
    );

    if (results.success) {
      onModalClosed();
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editOffer = async () => {
    const results = await editOfferFetch(
      token,
      offerId,
      staffContractTypeField ? staffContractTypeField.id : null,
      salaryCountTypeField ? salaryCountTypeField.id : null,
      titleField ? titleField.id : null,
      fixedSalary,
      onboardDate,
      isCommission,
      isLunchIncluded
    );

    if (results.success) {
      onModalClosed();
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  // Functions = Queries
  const getCandidateOfferFormOptions = async () => {
    const results = await getCandidateOfferFormOptionsFetch(token);

    // Set States
    setSalaryCountTypeOptions(
      results.salaryCountTypes ? results.salaryCountTypes : []
    );
    setStaffContractTypeOptions(
      results.staffContractTypes ? results.staffContractTypes : []
    );
    setTitleOptions(results.titles ? results.titles : []);
  };

  const getOfferDetailsById = async () => {
    const results = await getOfferDetailsByIdFetch(token, offerId);

    if (results.offerDetails) {
      const {
        fixed_salary,
        onboard_date,
        is_commission,
        is_lunch_included,
        staff_contract_type_id,
        salary_count_type_id,
        title_id,
      } = results.offerDetails;

      // Set States
      setFixedSalary(fixed_salary ? fixed_salary : "");
      setOnboardDate(onboard_date ? onboard_date : null);
      setIsCommission(is_commission);
      setIsLunchIncluded(is_lunch_included);
      setStaffContractTypeField(
        staff_contract_type_id
          ? staffContractTypeOptions.find(
              (item) => item.id === staff_contract_type_id
            )
          : null
      );
      setSalaryCountTypeField(
        salary_count_type_id
          ? salaryCountTypeOptions.find(
              (item) => item.id === salary_count_type_id
            )
          : null
      );
      setTitleField(
        title_id ? titleOptions.find((item) => item.id === title_id) : null
      );
    }

    clearErrorFields();
  };

  const getOfferSuggestionsByCandidateId = async () => {
    const results = await getOfferSuggestionsByCandidateIdFetch(
      token,
      candidateId
    );

    if (results.suggestions) {
      const {
        staff_contract_type_id,
        salary_count_type_id,
        title_id,
        fixed_salary,
        onboard_date,
        is_commission,
        is_lunch_included,
      } = results.suggestions;

      // Set States
      setFixedSalary(fixed_salary ? fixed_salary : "");
      setOnboardDate(onboard_date ? onboard_date : null);
      setIsCommission(is_commission);
      setIsLunchIncluded(is_lunch_included);
      setStaffContractTypeField(
        staff_contract_type_id
          ? staffContractTypeOptions.find(
              (item) => item.id === staff_contract_type_id
            )
          : null
      );
      setSalaryCountTypeField(
        salary_count_type_id
          ? salaryCountTypeOptions.find(
              (item) => item.id === salary_count_type_id
            )
          : null
      );
      setTitleField(
        title_id ? titleOptions.find((item) => item.id === title_id) : null
      );
    }
  };

  // Life Cycle
  useEffect(() => {
    getCandidateOfferFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && staffContractTypeOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [staffContractTypeOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (offerId) {
        getOfferDetailsById();
      } else {
        getOfferSuggestionsByCandidateId();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, offerId]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onCandidateOfferActionDialogCanceled}
        onDialogConfirmed={onCandidateOfferActionDialogConfirmed}
        // States
        dialogText={candidateOfferActionDialogText}
        showDialog={showCandidateOfferActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {offerId ? t("編輯薪資待遇") : t("新增薪資待遇")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid className={classes.formAutoCompleteContainer} item xs={12}>
            {titleOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) =>
                  option[t("title_name_ch")]
                    ? `${option.title_name_en_full} - ${
                        option[t("title_name_ch")]
                      }`
                    : option.title_name_en_full
                }
                onChange={(event, value) =>
                  onInputFieldChange("titleField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={titleOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("titleField")}
                    helperText={getErrorFieldMessage("titleField")}
                    label={t("職位")}
                    variant="standard"
                  />
                )}
                value={titleField}
              />
            )}
          </Grid>
          <Grid className={classes.formAutoCompleteContainer} item xs={12}>
            {staffContractTypeOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) =>
                  option[t("staff_contract_type_name_ch")]
                }
                onChange={(event, value) =>
                  onInputFieldChange("staffContractTypeField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={staffContractTypeOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("staffContractTypeField")}
                    helperText={getErrorFieldMessage("staffContractTypeField")}
                    label={t("合約類型")}
                    variant="standard"
                  />
                )}
                value={staffContractTypeField}
              />
            )}
          </Grid>
          <Grid className={classes.formAutoCompleteContainer} item xs={12}>
            {salaryCountTypeOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) =>
                  option[t("salary_count_type_name_ch")]
                }
                onChange={(event, value) =>
                  onInputFieldChange("salaryCountTypeField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={salaryCountTypeOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("salaryCountTypeField")}
                    helperText={getErrorFieldMessage("salaryCountTypeField")}
                    label={t("工資單位")}
                    variant="standard"
                  />
                )}
                value={salaryCountTypeField}
              />
            )}
            <Grid item xs={12}>
              <TextField
                autoFocus
                error={checkIsFieldError("fixedSalary")}
                fullWidth
                helperText={getErrorFieldMessage("fixedSalary")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                label={t("固定工資")}
                margin="dense"
                name="fixedSalary"
                onChange={(event) =>
                  onInputFieldChange("fixedSalary", event.target.value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                value={fixedSalary}
                variant="standard"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.formSwitchContainer}>
            <Typography align={"left"} variant="body1">
              {t("有佣金")}
            </Typography>
            <Switch
              checked={isCommission}
              onChange={() => onInputFieldChange("isCommission", !isCommission)}
            />
          </Grid>
          <Grid item xs={12} className={classes.formSwitchContainer}>
            <Typography align={"left"} variant="body1">
              {t("午飯計入工時")}
            </Typography>
            <Switch
              checked={isLunchIncluded}
              onChange={() =>
                onInputFieldChange("isLunchIncluded", !isLunchIncluded)
              }
            />
          </Grid>
          <Grid item xs={12} className={classes.formDatePickerContainer}>
            <DatePicker
              format="dd/MM/yyyy"
              label={t("入職日期 (日/月/年)")}
              onChange={(value) => onInputFieldChange("onboardDate", value)}
              slotProps={{
                textField: {
                  error: checkIsFieldError("onboardDate"),
                  helperText: getErrorFieldMessage("onboardDate"),
                  variant: "standard",
                },
              }}
              value={onboardDateVar}
              views={["year", "month", "day"]}
            />
          </Grid>
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              {t("確定")}
            </Button>
          </Grid>
          {/* Alert */}
          {shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default CandidateOfferActionModal;
