// Configs
import apisConfig from "../configs/apisConfig";

export const getApplicationProcedureDetailFormOptionsFetch = async (token) => {
  try {
    const queryRoute = "/formOption/getApplicationProcedureDetailFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAttendanceRuleDetailFormOptionsFetch = async (token) => {
  try {
    const queryRoute = "/formOption/getAttendanceRuleDetailFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAttendanceTypeDetailFormOptionsFetch = async (token) => {
  try {
    const queryRoute = "/formOption/getAttendanceTypeDetailFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getCandidateDetailFormOptionsFetch = async (token) => {
  try {
    const queryRoute = "/formOption/getCandidateDetailFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getCandidateStatusTypeFormOptionsFetch = async (token) => {
  try {
    const queryRoute = "/formOption/getCandidateStatusTypeFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getCandidateEducationFormOptionsFetch = async (token) => {
  try {
    const queryRoute = "/formOption/getCandidateEducationFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getCandidateOfferFormOptionsFetch = async (token) => {
  try {
    const queryRoute = "/formOption/getCandidateOfferFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getCandidateWorkExperienceFormOptionsFetch = async (token) => {
  try {
    const queryRoute = "/formOption/getCandidateWorkExperienceFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getCreateStaffFormOptionsFetch = async (token) => {
  try {
    const queryRoute = "/formOption/getCreateStaffFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getDayOffTypeDetailFormOptionsFetch = async (token) => {
  try {
    const queryRoute = "/formOption/getDayOffTypeDetailFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getDayOffTypeRuleDetailFormOptionsFetch = async (token) => {
  try {
    const queryRoute = "/formOption/getDayOffTypeRuleDetailFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getPermissionGroupDetailFormOptionsFetch = async (token) => {
  try {
    const queryRoute = "/formOption/getPermissionGroupDetailFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getRelocationDetailFormOptionsFetch = async (
  token,
  staffId,
  relocationId
) => {
  try {
    const reqBody = {
      staffId,
      relocationId,
    };

    const queryRoute = "/formOption/getRelocationDetailFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getShopBusinessHourFormOptionsFetch = async (token) => {
  try {
    const queryRoute = "/formOption/getShopBusinessHourFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getSalaryCountSettingFormOptionsFetch = async (token) => {
  try {
    const queryRoute = "/formOption/getSalaryCountSettingFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getShopContactFormOptionsFetch = async (token) => {
  try {
    const queryRoute = "/formOption/getShopContactFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getShopDetailFormOptionsFetch = async (token) => {
  try {
    const queryRoute = "/formOption/getShopDetailFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getStaffDetailFormOptionsFetch = async (token) => {
  try {
    const queryRoute = "/formOption/getStaffDetailFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getStaffContractDetailFormOptionsFetch = async (token) => {
  try {
    const queryRoute = "/formOption/getStaffContractDetailFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getTitleDetailFormOptionsFetch = async (token) => {
  try {
    const queryRoute = "/formOption/getTitleDetailFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getWorkingHourFormOptionsFetch = async (token) => {
  try {
    const queryRoute = "/formOption/getWorkingHourFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getWorkingPlaceDetailFormOptionsFetch = async (token) => {
  try {
    const queryRoute = "/formOption/getWorkingPlaceDetailFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
