// Configs
import apisConfig from "../configs/apisConfig";

export const createShiftFetch = async (
  token,
  shopId,
  shiftCode,
  defaultStartTime,
  defaultLunchTime,
  defaultAfterLunchTime,
  defaultEndTime,
  shouldApplyToAllShops
) => {
  try {
    const queryRoute = "/shift/createShift";

    const reqBody = {
      shopId,
      shiftCode,
      defaultStartTime,
      defaultLunchTime,
      defaultAfterLunchTime,
      defaultEndTime,
      shouldApplyToAllShops,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteOrRestoreShiftFetch = async (
  token,
  shiftId,
  typeOfShifts,
  shouldApplyToAllShops
) => {
  try {
    const queryRoute = `/shift/deleteOrRestoreShift/${shiftId}`;

    const reqBody = { typeOfShifts, shouldApplyToAllShops };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editShiftFetch = async (
  token,
  shiftId,
  shiftCode,
  defaultStartTime,
  defaultLunchTime,
  defaultAfterLunchTime,
  defaultEndTime,
  shouldApplyToAllShops
) => {
  try {
    const queryRoute = `/shift/editShift/${shiftId}`;

    const reqBody = {
      shiftCode,
      defaultStartTime,
      defaultLunchTime,
      defaultAfterLunchTime,
      defaultEndTime,
      shouldApplyToAllShops,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllShiftsByShopIdFetch = async (
  token,
  shopId,
  typeOfShifts
) => {
  try {
    const queryRoute = `/shift/getAllShiftsByShopId/${shopId}`;

    const reqBody = {
      typeOfShifts,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getShiftDetailsByIdFetch = async (token, shiftId) => {
  try {
    const queryRoute = `/shift/getShiftDetailsById/${shiftId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
