// Components
import StyledMenu from "../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";

// React-Redux
import { useSelector } from "react-redux";

function ShiftActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // States
    showShiftActionMenu,
    // Set States
    setShowShiftActionMenu,
    // Functions
    displayShiftAndDayOffTypeActionDialog,
    displayShiftActionModal,
  } = props;

  // Redux Store
  const typeOfWorkingPeriodsAndShifts = useSelector(
    (state) => state.rostersPage.typeOfWorkingPeriodsAndShifts
  );

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowShiftActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "Delete":
        displayShiftAndDayOffTypeActionDialog("DeleteShift");
        break;
      case "Edit":
        displayShiftActionModal();
        break;
      case "Restore":
        displayShiftAndDayOffTypeActionDialog("RestoreShift");
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showShiftActionMenu}
      open={Boolean(showShiftActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Edit")}>
        <EditRoundedIcon />
        {t("編輯")}
      </MenuItem>
      {typeOfWorkingPeriodsAndShifts === "Current" ? (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Delete")}>
          <DeleteRoundedIcon />
          {t("刪除")}
        </MenuItem>
      ) : (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Restore")}>
          <RestoreRoundedIcon />
          {t("還原")}
        </MenuItem>
      )}
    </StyledMenu>
  );
}

export default ShiftActionMenu;
