// Actions
import {
  onTabItemChange as onDayOffTypesPageTabItemChange,
  onDayOffTypeChange as onDayOffTypesPageDayOffTypeChange,
  onTypeOfDayOffTypesChange as onDayOffTypesPageTypeOfDayOffTypesChange,
} from "../../../redux/pages/dayOffTypesPage/dayOffTypesPageActions";
import { onFormSubmitCountIncrease } from "../../../redux/pages/rostersPage/rostersPageActions";
import {
  onTabItemChange as onWorkingPeriodsAndPlacesPageTabItemChange,
  onTypeOfWorkingPeriodsAndPlacesChange as onWorkingPeriodsAndPlacesPageTypeOfWorkingPeriodsAndPlacesChange,
  onWorkingPeriodChange as onWorkingPeriodsAndPlacesPageWorkingPeriodChange,
} from "../../../redux/pages/workingPeriodsAndPlacesPage/workingPeriodsAndPlacesPageActions";

// Components
// Boxes
import WorkingPeriodAndShiftAndDayOffTypeBox from "./workingPeriodAndShiftAndDayOffTypeBox";
// Dialogs
import ConfirmDialog from "../../dialogs/confirmDialog";
// Menus
import ShiftActionMenu from "../../menus/rostersPage/shiftActionMenu";
// Modals
import ShiftActionModal from "../../modals/rostersPage/shiftActionModal";
import ModalContainer from "../../modals/modalContainer";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getAllDayOffTypesFetch } from "../../../fetches/dayOffTypeFetches";
import {
  deleteOrRestoreShiftFetch,
  getAllShiftsByShopIdFetch,
} from "../../../fetches/shiftFetches";
import { getAllWorkingPeriodsWithWorkingHoursFetch } from "../../../fetches/workingPeriodFetches";

// Helper Functions
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import { useHorizontalItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/horizontalItemBoxesContainerStyles";

function WorkingPeriodAndShiftAndDayOffTypeBoxesContainer(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useHorizontalItemBoxesContainerStyles();

  // Props
  const {
    // States
    hoveredDayOffTypeId,
    hoveredShiftId,
    hoveredWorkingPeriodId,
    selectedStaffToDays,
    // Functions
    createOrEditRoster,
  } = props;

  // Redux Store
  const language = useSelector((state) => state.language.language);
  const shopId = useSelector((state) => state.rostersPage.shopId);
  const token = useSelector((state) => state.staff.token);
  const typeOfContent = useSelector((state) => state.rostersPage.typeOfContent);
  const typeOfWorkingPeriodsAndShifts = useSelector(
    (state) => state.rostersPage.typeOfWorkingPeriodsAndShifts
  );

  // States
  // Data
  const [currentItemId, setCurrentItemId] = useState(null);
  const [dayOffTypes, setDayOffTypes] = useState(null);
  const [shifts, setShifts] = useState(null);
  const [workingPeriods, setWorkingPeriods] = useState(null);
  // Dialog
  const [
    shiftAndDayOffTypeActionDialogOptionValue,
    setShiftAndDayOffTypeActionDialogOptionValue,
  ] = useState(null);
  const [
    shiftAndDayOffTypeActionDialogTitle,
    setShiftAndDayOffTypeActionDialogTitle,
  ] = useState("");
  const [
    shiftAndDayOffTypeActionDialogType,
    setShiftAndDayOffTypeActionDialogType,
  ] = useState(null);
  const [
    showShiftAndDayOffTypeActionDialog,
    setShowShiftAndDayOffTypeActionDialog,
  ] = useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showShiftActionMenu, setShowShiftActionMenu] = useState(null);
  // Modals
  const [showShiftActionModal, setShowShiftActionModal] = useState(false);

  // Handle States
  const itemsArr =
    typeOfContent === "Shifts"
      ? shifts
      : typeOfContent === "WorkingPeriods"
      ? workingPeriods
      : dayOffTypes;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    if (selectedStaffToDays[0]) {
      createOrEditRoster(typeOfContent, itemId);
    } else {
      switch (typeOfContent) {
        case "DayOffTypes":
          // Update Redux Store
          dispatch(onDayOffTypesPageTabItemChange("DayOffTypes"));
          dispatch(onDayOffTypesPageDayOffTypeChange(itemId));
          dispatch(onDayOffTypesPageTypeOfDayOffTypesChange("Current"));

          // Navigate
          navigate("/dayOffTypes");

          break;
        case "Shifts":
          // Set States
          setCurrentItemId(itemId);
          displayShiftActionModal();

          break;
        case "WorkingPeriods":
          // Update Redux Store
          dispatch(
            onWorkingPeriodsAndPlacesPageTabItemChange("WorkingPeriods")
          );
          dispatch(onWorkingPeriodsAndPlacesPageWorkingPeriodChange(itemId));
          dispatch(
            onWorkingPeriodsAndPlacesPageTypeOfWorkingPeriodsAndPlacesChange(
              "Current"
            )
          );

          // Navigate
          navigate("/workingPeriodsAndPlaces");

          break;
        default:
          break;
      }
    }
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    if (typeOfContent === "Shifts") {
      // Set States
      setCurrentItemId(itemId);
      setShowShiftActionMenu(currentTarget);
    }
  };

  // Events - Dialogs
  const onShiftAndDayOffTypeActionDialogCanceled = () => {
    // Set States
    setShowShiftAndDayOffTypeActionDialog(false);
  };

  const onShiftAndDayOffTypeActionDialogConfirmed = () => {
    if (shiftAndDayOffTypeActionDialogOptionValue) {
      switch (shiftAndDayOffTypeActionDialogType) {
        case "DeleteShift":
          deleteOrRestoreShift(
            shiftAndDayOffTypeActionDialogOptionValue === t("所有店舖")
          );
          break;
        case "RestoreShift":
          deleteOrRestoreShift(
            shiftAndDayOffTypeActionDialogOptionValue === t("所有店舖")
          );
          break;
        default:
          break;
      }

      // Set States
      setShowShiftAndDayOffTypeActionDialog(false);
    }
  };

  const onShiftAndDayOffTypeActionDialogOptionChanged = (value) => {
    // Set States
    setShiftAndDayOffTypeActionDialogOptionValue(value);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onShiftActionModalClosed = () => {
    getAllShifts();

    // Set States
    setShowShiftActionModal(false);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Set States
    setCurrentItemId(null);
    displayShiftActionModal();
  };

  // Functions
  // Functions - Normal
  const displayShiftAndDayOffTypeActionDialog = (
    shiftAndDayOffTypeActionType
  ) => {
    // Set States
    setShiftAndDayOffTypeActionDialogType(shiftAndDayOffTypeActionType);

    switch (shiftAndDayOffTypeActionType) {
      case "DeleteShift":
        setShiftAndDayOffTypeActionDialogTitle(t("確認要刪除 值更 嗎？"));
        break;
      case "RestoreShift":
        setShiftAndDayOffTypeActionDialogTitle(t("確認要還原 值更 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowShiftAndDayOffTypeActionDialog(true);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  const displayShiftActionModal = () => {
    setShowShiftActionModal(true);
  };

  // Functions - Mutations
  const deleteOrRestoreShift = async (shouldApplyToAllShops) => {
    const results = await deleteOrRestoreShiftFetch(
      token,
      currentItemId,
      typeOfWorkingPeriodsAndShifts,
      shouldApplyToAllShops
    );

    if (results.success) {
      getAllShifts();
    }
  };

  // Functions - Queries
  const getAllDayOffTypes = async () => {
    const results = await getAllDayOffTypesFetch(token, "Current");

    // Set States
    setDayOffTypes(results.dayOffTypes ? results.dayOffTypes : null);
  };

  const getAllShifts = async () => {
    const results = await getAllShiftsByShopIdFetch(
      token,
      shopId,
      typeOfWorkingPeriodsAndShifts
    );

    if (results.shifts) {
      for (let item of results.shifts) {
        item.default_start_time = item.default_start_time
          ? moment(item.default_start_time).format(t("a h:mm"))
          : null;
        item.default_end_time = item.default_end_time
          ? moment(item.default_end_time).format(t("a h:mm"))
          : null;
      }
    }

    // Set States
    setShifts(results.shifts ? results.shifts : null);
  };

  const getAllWorkingPeriodsWithWorkingHours = async () => {
    const results = await getAllWorkingPeriodsWithWorkingHoursFetch(
      token,
      typeOfWorkingPeriodsAndShifts
    );

    if (results.workingPeriods) {
      for (let workingPeriodItem of results.workingPeriods) {
        if (workingPeriodItem.workingHours) {
          for (let workingHourItem of workingPeriodItem.workingHours) {
            workingHourItem.start_time = workingHourItem.start_time
              ? moment(workingHourItem.start_time).format(t("a h:mm"))
              : null;
            workingHourItem.end_time = workingHourItem.end_time
              ? moment(workingHourItem.end_time).format(t("a h:mm"))
              : null;
          }
        }
      }
    }

    // Set States
    setWorkingPeriods(results.workingPeriods ? results.workingPeriods : null);
  };

  // Life Cycle
  useEffect(() => {
    getAllDayOffTypes();
  }, []);

  useEffect(() => {
    getAllWorkingPeriodsWithWorkingHours();
  }, [typeOfWorkingPeriodsAndShifts, language]);

  useEffect(() => {
    if (shopId) {
      getAllShifts();
    }
  }, [shopId, typeOfWorkingPeriodsAndShifts, language]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBoxWithMaxWidth}>
      {/* Dialog */}
      <ConfirmDialog
        // Events
        onDialogClosed={onShiftAndDayOffTypeActionDialogCanceled}
        onDialogConfirmed={onShiftAndDayOffTypeActionDialogConfirmed}
        onOptionChange={onShiftAndDayOffTypeActionDialogOptionChanged}
        // States
        dialogTitle={shiftAndDayOffTypeActionDialogTitle}
        options={[t("所有店舖"), t("只限此店舖")]}
        optionValue={shiftAndDayOffTypeActionDialogOptionValue}
        showDialog={showShiftAndDayOffTypeActionDialog}
      />
      {/* Menu */}
      <ShiftActionMenu
        // States
        showShiftActionMenu={showShiftActionMenu}
        // Set States
        setShowShiftActionMenu={setShowShiftActionMenu}
        // Fucntions
        displayShiftAndDayOffTypeActionDialog={
          displayShiftAndDayOffTypeActionDialog
        }
        displayShiftActionModal={displayShiftActionModal}
      />
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onShiftActionModalClosed}
        // States
        showModal={showShiftActionModal}
      >
        <ShiftActionModal
          // States
          shiftId={currentItemId}
          // Events
          onModalClosed={onShiftActionModalClosed}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {typeOfContent === "Shifts"
              ? t("值更s")
              : typeOfContent === "WorkingPeriods"
              ? t("工作時段s")
              : t("假期種類s")}
          </Typography>
          {typeOfContent === "Shifts" && (
            <Tooltip placement="right" title={t("新增值更")}>
              <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
                <AddRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      {itemsArr && itemsArr[0] ? (
        <div className={classes.itemBoxesContainer}>
          {itemsArr.map((item) => (
            <WorkingPeriodAndShiftAndDayOffTypeBox
              key={item.id}
              // States
              hoveredDayOffTypeId={hoveredDayOffTypeId}
              hoveredItemId={hoveredItemId}
              hoveredShiftId={hoveredShiftId}
              hoveredWorkingPeriodId={hoveredWorkingPeriodId}
              item={item}
              typeOfContent={typeOfContent}
              // Events
              onItemClicked={onItemClicked}
              onItemRightClicked={onItemRightClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
        </div>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {`${t("未有 ")}${
              typeOfContent === "Shifts"
                ? t("值更")
                : typeOfContent === "WorkingPeriods"
                ? t("工作時段")
                : t("假期種類")
            }${t(" 資料")}`}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default WorkingPeriodAndShiftAndDayOffTypeBoxesContainer;
