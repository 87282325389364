// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function AbnormalAttendanceBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    checkedItemIdsArr,
    hoveredItemId,
    item,
    // Events
    onItemChecked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isChecked = checkedItemIdsArr.includes(item.id);
  const isHovered = hoveredItemId && hoveredItemId === item.id;

  return (
    <div
      className={
        isHovered ? classes.hoveredItemContainer : classes.itemContainer
      }
      onClick={() => onItemChecked(item)}
      onMouseEnter={() => onMouseEnterItem(item.id)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div className={classes.itemContentContainer}>
        <div className={classes.itemContentSubContainerLarge}>
          <Typography
            align={"left"}
            color={isHovered ? stylesConfig.mainTextColor : null}
            variant="body1"
          >
            {`${item.staff_code} - ${item.full_name_en}`}
          </Typography>
          <Typography
            align={"left"}
            color={stylesConfig.greyTextColor}
            variant="body2"
          >
            {`${item.dept_name_display} - `}
            {item[t("title_name_ch")]}
          </Typography>
        </div>
        <div className={classes.itemContentSubContainer}>
          <Typography
            align={"left"}
            color={isHovered ? stylesConfig.mainTextColor : null}
            variant="body1"
          >
            {item.shop_code
              ? `${item.shop_code} - `
              : item.working_place_name_en_short
              ? `${item.working_place_name_en_short} - `
              : `${item.address} - `}
            {item.time_attend}
          </Typography>
          <Typography
            align={"left"}
            color={stylesConfig.greyTextColor}
            variant="body2"
          >
            {"("}
            {item.rosterDetails &&
            item.rosterDetails.roster_time &&
            (item.rosterDetails.shop_code ||
              item.rosterDetails.working_place_name_en_short ||
              item.rosterDetails.address)
              ? item.rosterDetails.shop_code
                ? `${item.rosterDetails.shop_code} - `
                : item.rosterDetails.working_place_name_en_short
                ? `${item.rosterDetails.working_place_name_en_short} - `
                : `${item.rosterDetails.address} - `
              : null}
            {item.rosterDetails && !item.rosterDetails.is_attendance_required
              ? t("無需簽到")
              : item.rosterDetails.roster_time
              ? `${item.rosterDetails.roster_time})`
              : `${t("不適用")})`}
          </Typography>
        </div>
        <div className={classes.itemContentSubContainer}>
          <Typography
            align={"left"}
            color={
              item.is_exception
                ? isHovered
                  ? stylesConfig.mainTextColor
                  : null
                : stylesConfig.redTextColor
            }
            variant="body1"
          >
            {!item.is_on_time &&
              `${item.time_difference > 0 ? t("遲到") : t("早退")} ${
                item.time_difference ? Math.abs(item.time_difference) : 0
              } ${t("分鐘s")}`}
            {!item.is_on_time && !item.is_location_correct && " / "}
            {!item.is_location_correct && t("地點錯誤")}
          </Typography>
        </div>
      </div>
      <Stack direction="row" spacing={1}>
        <Checkbox checked={isChecked} onChange={() => onItemChecked(item)} />
      </Stack>
    </div>
  );
}

export default AbnormalAttendanceBox;
