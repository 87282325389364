// Components
// Charts
import NumOfStaffChart from "./numOfStaffChart";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function NumOfStaffChartContainer(props) {
  // Hooks
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const { itemsArr } = props;

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            統計圖表
          </Typography>
        </div>
      </div>
      {/* Content */}
      {itemsArr ? (
        <NumOfStaffChart itemsArr={itemsArr} />
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            未有統計圖表
          </Typography>
        </div>
      )}
    </div>
  );
}

export default NumOfStaffChartContainer;
