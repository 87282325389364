export function onBadgeChange(applicationBadgeNum) {
  return {
    type: "ON_BADGE_CHANGE",
    applicationBadgeNum,
  };
}

export function onBadgeUpdateCountIncrease() {
  return {
    type: "ON_BADGE_UPDATE_COUNT_INCREASE",
  };
}
