// Actions
import { onBadgeChange } from "../../../redux/shared/badge/badgeActions";
import { onLanguageChange } from "../../../redux/shared/language/languageActions";
import { onThemeChange } from "../../../redux/shared/theme/themeActions";

// Configs
import keyConfig from "../../../configs/keyConfig";

// Fetches
import { getAllBadgesFetch } from "../../../fetches/badgeFetches";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function AppWrapper({ children }) {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const badgeUpdateCount = useSelector((state) => state.badge.badgeUpdateCount);
  const token = useSelector((state) => state.staff.token);

  // Functions
  // Functions - Normal
  const getLanguageFromStorage = () => {
    // Local Storage
    const languageVar = localStorage.getItem(keyConfig.languageKey);

    if (languageVar) {
      // Update Redux Store
      dispatch(onLanguageChange(languageVar));
    }
  };

  const getThemeModeFromStorage = () => {
    // Local Storage
    const themeModeVar = localStorage.getItem(keyConfig.themeKey);

    if (themeModeVar) {
      // Update Redux Store
      dispatch(onThemeChange(themeModeVar));
    }
  };

  // Functions - Queries
  const getAllBadges = async () => {
    const results = await getAllBadgesFetch(token);

    if (results.badges) {
      const { applicationBadgeNum } = results.badges;

      // Update Redux Store
      dispatch(onBadgeChange(applicationBadgeNum));
    }
  };

  // Life Cycle
  useEffect(() => {
    getLanguageFromStorage();
    getThemeModeFromStorage();
  }, []);

  useEffect(() => {
    if (token) {
      getAllBadges();
    }
  }, [token, badgeUpdateCount]);

  return <>{children}</>;
}

export default AppWrapper;
