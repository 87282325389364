// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// Icons
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function StaffBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    divisionType,
    hoveredItemId,
    item,
    // Events
    onItemClicked,
    onItemRightClicked,
    onItemShowMenuBtnClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isHovered = hoveredItemId && hoveredItemId === item.id;

  return (
    <div
      className={
        isHovered ? classes.hoveredItemContainer : classes.itemContainer
      }
      onMouseEnter={() => onMouseEnterItem(item.id)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div
        className={classes.itemContentContainer}
        onClick={() => onItemClicked(item.id)}
      >
        <div
          className={classes.itemContentSubContainer}
          onContextMenu={(event) => {
            event.preventDefault();
            onItemRightClicked(item, event.currentTarget);
          }}
        >
          <Typography
            align={"left"}
            color={isHovered ? stylesConfig.mainTextColor : null}
            variant="body1"
          >
            {`${item.staff_code} - ${item.full_name_en}`}
            {item.alias && ` (${item.alias})`}
          </Typography>
          <Typography
            align={"left"}
            color={stylesConfig.greyTextColor}
            variant="body2"
          >
            {item[t("title_name_ch")]}
            {item.staff_contract_type_name_ch &&
              item.staff_contract_type_name_ch !== "全職" &&
              ` (${t("item.staff_contract_type_name_ch")})`}
          </Typography>
        </div>
        <div
          className={classes.itemContentSubContainer}
          onContextMenu={(event) => {
            event.preventDefault();
            onItemRightClicked(item, event.currentTarget);
          }}
        >
          <Typography align={"left"} variant="body1">
            {item.staff_status_type_name_ch
              ? item[t("staff_status_type_name_ch")]
              : null}
          </Typography>
          {item.isTeamHead && (
            <Typography
              align={"left"}
              color={stylesConfig.greyTextColor}
              variant="body2"
            >
              {divisionType === "Departments" ? t("部門主管") : t("店鋪主管")}
            </Typography>
          )}
        </div>
      </div>
      <Stack direction="row" spacing={1}>
        <IconButton
          onClick={(event) =>
            onItemShowMenuBtnClicked(item, event.currentTarget)
          }
        >
          <MenuRoundedIcon />
        </IconButton>
      </Stack>
    </div>
  );
}

export default StaffBox;
