// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  createContactFetch,
  editContactFetch,
  getContactDetailsByIdFetch,
} from "../../../fetches/contactFetches";
import { getShopContactFormOptionsFetch } from "../../../fetches/formOptionFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ShopContactActionModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
    // States
    contactId,
  } = props;

  // Redux Store
  const shopId = useSelector((state) => state.deptsAndShopsPage.shopId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [shopContactActionDialogText, setShopContactActionDialogText] =
    useState("");
  const [shopContactActionDialogType, setShopContactActionDialogType] =
    useState(null);
  const [showShopContactActionDialog, setShowShopContactActionDialog] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [contactTypeField, setContactTypeField] = useState(null);
  // Options
  const [contactTypeOptions, setContactTypeOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Switches
  const [isBackup, setIsBackup] = useState(false);
  // Text Fields
  const [contactNum, setContactNum] = useState("");

  // Events
  // Events - Dialogs
  const onShopContactActionDialogCanceled = () => {
    // Set States
    setShowShopContactActionDialog(false);
  };

  const onShopContactActionDialogConfirmed = () => {
    switch (shopContactActionDialogType) {
      case "CreateShopContact":
        createContact();
        break;
      case "EditShopContact":
        editContact();
        break;
      default:
        break;
    }

    // Set States
    setShowShopContactActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "contactNum":
        setContactNum(value);
        break;
      case "contactField":
        setContactTypeField(value);
        break;
      case "isBackup":
        setIsBackup(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!contactNum) {
      addToErrorFields("contactNum", t("請先填寫 聯絡號碼"));
      isError = true;
    }

    if (!contactTypeField) {
      addToErrorFields("contactTypeField", t("請先填寫 聯絡類別"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayShopContactActionDialog(
      contactId ? "EditShopContact" : "CreateShopContact"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displayShopContactActionDialog = (shopContactActionType) => {
    // Set States
    setShopContactActionDialogType(shopContactActionType);

    switch (shopContactActionType) {
      case "CreateShopContact":
        setShopContactActionDialogText(t("確認要新增 聯絡方式 嗎？"));
        break;
      case "EditShopContact":
        setShopContactActionDialogText(t("確認要編輯 聯絡方式 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowShopContactActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createContact = async () => {
    const results = await createContactFetch(
      token,
      shopId,
      contactTypeField ? contactTypeField.id : null,
      contactNum,
      isBackup
    );

    if (results.success) {
      onModalClosed();
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editContact = async () => {
    const results = await editContactFetch(
      token,
      contactId,
      contactTypeField ? contactTypeField.id : null,
      contactNum,
      isBackup
    );

    if (results.success) {
      onModalClosed();
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  // Functions = Queries
  const getContactDetailsById = async () => {
    const results = await getContactDetailsByIdFetch(token, contactId);

    if (results.contactDetails) {
      const { contact_num, is_backup, contact_type_id } =
        results.contactDetails;

      // Set States
      setContactNum(contact_num ? contact_num : "");
      setIsBackup(is_backup);
      setContactTypeField(
        contact_type_id
          ? contactTypeOptions.find((item) => item.id === contact_type_id)
          : null
      );
    }

    clearErrorFields();
  };

  const getShopContactFormOptions = async () => {
    const results = await getShopContactFormOptionsFetch(token);

    // Set States
    setContactTypeOptions(results.contactTypes ? results.contactTypes : []);
  };

  // Life Cycle
  useEffect(() => {
    getShopContactFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && contactTypeOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [contactTypeOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (contactId) {
        getContactDetailsById();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, contactId]);

  return (
    <div className={classes.modalContainerSmall}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onShopContactActionDialogCanceled}
        onDialogConfirmed={onShopContactActionDialogConfirmed}
        // States
        dialogText={shopContactActionDialogText}
        showDialog={showShopContactActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {contactId ? t("編輯聯絡方式") : t("新增聯絡方式")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <TextField
              autoFocus
              error={checkIsFieldError("contactNum")}
              fullWidth
              helperText={getErrorFieldMessage("contactNum")}
              label={t("聯絡號碼")}
              margin="dense"
              name="contactNum"
              onChange={(event) =>
                onInputFieldChange("contactNum", event.target.value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              value={contactNum}
              variant="standard"
            />
          </Grid>
          <Grid className={classes.formAutoCompleteContainer} item xs={12}>
            {contactTypeOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option.contact_type_name}
                onChange={(event, value) =>
                  onInputFieldChange("contactField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={contactTypeOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("contactField")}
                    helperText={getErrorFieldMessage("contactField")}
                    label={t("聯絡類別")}
                    variant="standard"
                  />
                )}
                value={contactTypeField}
              />
            )}
          </Grid>
          <Grid item xs={12} className={classes.formSwitchContainer}>
            <Typography align={"left"} variant="body1">
              {t("後備")}
            </Typography>
            <Switch
              checked={isBackup}
              onChange={() => onInputFieldChange("isBackup", !isBackup)}
            />
          </Grid>
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              {t("確定")}
            </Button>
          </Grid>
          {/* Alert */}
          {shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default ShopContactActionModal;
