// Configs
import stylesConfig from "../../../../configs/stylesConfig";

// Material UI
// Components
import Typography from "@mui/material/Typography";

function CaptionBox(props) {
  // Props
  const {
    // States
    captionStr,
  } = props;

  return (
    <Typography
      align={"left"}
      color={stylesConfig.greyTextColor}
      variant="body2"
    >
      {captionStr}
    </Typography>
  );
}

export default CaptionBox;
