// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Consts
import {
  dayOptions,
  hourOptions,
  seniorityOptions,
} from "../../../consts/dayOffTypeRuleActionModalConsts";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  createDayOffTypeRuleFetch,
  editDayOffTypeRuleFetch,
  getDayOffTypeRuleDetailsByIdFetch,
} from "../../../fetches/dayOffTypeRuleFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function DayOffTypeRuleActionModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
    // States
    dayOffTypeCountTypeNameCh,
    dayOffTypeCountTypeNameEn,
    dayOffTypeRuleId,
    dayOffTypeRuleTypeNameEn,
    language,
  } = props;

  // Redux Store
  const dayOffTypeId = useSelector(
    (state) => state.dayOffTypesPage.dayOffTypeId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [dayOffTypeRuleActionDialogText, setDayOffTypeRuleActionDialogText] =
    useState("");
  const [dayOffTypeRuleActionDialogType, setDayOffTypeRuleActionDialogType] =
    useState(null);
  const [showDayOffTypeRuleActionDialog, setShowDayOffTypeRuleActionDialog] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [quotaNumField, setQuotaNumField] = useState(null);
  const [ruleTypeNumField, setRuleTypeNumField] = useState(null);

  // Events
  // Events - Dialogs
  const onDayOffTypeRuleActionDialogCanceled = () => {
    // Set States
    setShowDayOffTypeRuleActionDialog(false);
  };

  const onDayOffTypeRuleActionDialogConfirmed = () => {
    switch (dayOffTypeRuleActionDialogType) {
      case "CreateDayOffTypeRule":
        createDayOffTypeRule();
        break;
      case "EditDayOffTypeRule":
        editDayOffTypeRule();
        break;
      default:
        break;
    }

    // Set States
    setShowDayOffTypeRuleActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "quotaNumField":
        setQuotaNumField(value);
        break;
      case "ruleTypeNumField":
        setRuleTypeNumField(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (dayOffTypeRuleTypeNameEn === "Seniority" && !ruleTypeNumField) {
      addToErrorFields("ruleTypeNumField", t("請先填寫 年資"));
      isError = true;
    }

    if (!quotaNumField) {
      addToErrorFields(
        "quotaNumField",
        dayOffTypeRuleTypeNameEn === "From Overtime"
          ? t("請先填寫 小時")
          : t("請先填寫 假期數量")
      );
      isError = true;
    }

    if (isError) {
      return;
    }

    displayDayOffTypeRuleActionDialog(
      dayOffTypeRuleId ? "EditDayOffTypeRule" : "CreateDayOffTypeRule"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displayDayOffTypeRuleActionDialog = (dayOffTypeRuleActionType) => {
    // Set States
    setDayOffTypeRuleActionDialogType(dayOffTypeRuleActionType);

    switch (dayOffTypeRuleActionType) {
      case "CreateDayOffTypeRule":
        setDayOffTypeRuleActionDialogText(t("確認要新增 假期規則 嗎？"));
        break;
      case "EditDayOffTypeRule":
        setDayOffTypeRuleActionDialogText(t("確認要編輯 假期規則 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowDayOffTypeRuleActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createDayOffTypeRule = async () => {
    const results = await createDayOffTypeRuleFetch(
      token,
      dayOffTypeId,
      ruleTypeNumField ? ruleTypeNumField.value : null,
      quotaNumField ? quotaNumField.value : null
    );

    if (results.success) {
      onModalClosed();
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("已有相關 假期規則"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editDayOffTypeRule = async () => {
    const results = await editDayOffTypeRuleFetch(
      token,
      dayOffTypeRuleId,
      ruleTypeNumField ? ruleTypeNumField.value : null,
      quotaNumField ? quotaNumField.value : null
    );

    if (results.success) {
      onModalClosed();
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("已有相關 假期規則"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  // Functions = Queries
  const getDayOffTypeRuleDetailsById = async () => {
    const results = await getDayOffTypeRuleDetailsByIdFetch(
      token,
      dayOffTypeRuleId
    );

    if (results.dayOffTypeRuleDetails) {
      const { rule_type_num, quota_num } = results.dayOffTypeRuleDetails;

      // Set States
      setRuleTypeNumField(
        rule_type_num !== undefined
          ? seniorityOptions.find((item) => item.value === rule_type_num)
          : null
      );
      setQuotaNumField(
        dayOffTypeRuleTypeNameEn === "From Overtime"
          ? hourOptions.find((item) => item.value === quota_num)
          : dayOptions.find((item) => item.value === quota_num)
      );
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    if (dayOffTypeRuleId) {
      getDayOffTypeRuleDetailsById();
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [dayOffTypeRuleId]);

  return (
    <div className={classes.modalContainerSmall}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onDayOffTypeRuleActionDialogCanceled}
        onDialogConfirmed={onDayOffTypeRuleActionDialogConfirmed}
        // States
        dialogText={dayOffTypeRuleActionDialogText}
        showDialog={showDayOffTypeRuleActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {dayOffTypeRuleId ? t("編輯假期規則") : t("新增假期規則")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          {dayOffTypeRuleTypeNameEn === "Seniority" && (
            <Grid className={classes.formAutoCompleteContainer} item xs={12}>
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option[t("textCh")]}
                onChange={(event, value) =>
                  onInputFieldChange("ruleTypeNumField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={seniorityOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("ruleTypeNumField")}
                    helperText={getErrorFieldMessage("ruleTypeNumField")}
                    label={t("年資")}
                    variant="standard"
                  />
                )}
                value={ruleTypeNumField}
              />
            </Grid>
          )}
          <Grid className={classes.formAutoCompleteContainer} item xs={12}>
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option[t("textCh")]}
              onChange={(event, value) =>
                onInputFieldChange("quotaNumField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={
                dayOffTypeRuleTypeNameEn === "From Overtime"
                  ? hourOptions
                  : dayOptions
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("quotaNumField")}
                  helperText={getErrorFieldMessage("quotaNumField")}
                  label={
                    dayOffTypeRuleTypeNameEn === "From Overtime"
                      ? t("小時 (每特定小時補上 1 日)")
                      : `${t("假期數量")} (${
                          language === "zh-hk"
                            ? dayOffTypeCountTypeNameCh
                            : dayOffTypeCountTypeNameEn
                        })`
                  }
                  variant="standard"
                />
              )}
              value={quotaNumField}
            />
          </Grid>
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              {t("確定")}
            </Button>
          </Grid>
          {/* Alert */}
          {shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default DayOffTypeRuleActionModal;
