// Actions
import { onMonthChange } from "../../../redux/pages/attendancesPage/attendancesPageActions";

// Components
// Backdrop
import LoadingBackdrop from "../../backdrop/loadingBackdrop";
// Tables
import AttendanceSummaryTable from "./attendanceSummaryTable";

// Configs
import stylesConfig from "../../../configs/stylesConfig";
import timeoutConfig from "../../../configs/timeoutConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Debounce
import debounce from "lodash.debounce";

// Fetches
import { getAllAttendanceSummariesByDepartmentOrShopIdFetch } from "../../../fetches/attendanceFetches";

// Helper Functions
import {
  getMonthEndDate,
  getMonthStartDate,
} from "../../../helperFunctions/getDate";

// Material UI
// Components
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// Icons
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const today = new Date();
const currentMonth = new Date(today.getFullYear(), today.getMonth());

function AttendanceSummaryTableContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const departmentId = useSelector(
    (state) => state.attendancesPage.departmentId
  );
  const divisionId = useSelector((state) => state.attendancesPage.divisionId);
  const divisionType = useSelector(
    (state) => state.attendancesPage.divisionType
  );
  const formSubmitCount = useSelector(
    (state) => state.attendancesPage.formSubmitCount
  );
  const month = useSelector((state) => state.attendancesPage.month);
  const shopId = useSelector((state) => state.attendancesPage.shopId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [attendanceSummaryFields, setAttendanceSummaryFields] = useState(null);
  const [staffWithAttendanceSummaries, setStaffWithAttendanceSummaries] =
    useState(null);
  // Redner
  const [isLoading, setIsLoading] = useState(false);

  // Handle States
  const monthVar = month ? new Date(month) : null;

  const isMonthVarEqualsCurrentMonth =
    monthVar.getFullYear() === currentMonth.getFullYear() &&
    monthVar.getMonth() === currentMonth.getMonth();

  // Events
  // Events - Fields
  const onMonthBackAndForwardBtnClicked = (actionType) => {
    const newMonthVar =
      actionType === "back"
        ? new Date(monthVar.getFullYear(), monthVar.getMonth() - 1, 1)
        : new Date(monthVar.getFullYear(), monthVar.getMonth() + 1, 1);

    // Update Redux Store
    dispatch(onMonthChange(newMonthVar.toString()));
  };

  const onAttendanceMonthChange = (value) => {
    // Update Redux Store
    dispatch(onMonthChange(value.toString()));
  };

  // Events - Title
  const onReturnToCurrentMonthBtnClicked = () => {
    // Update Redux Store
    dispatch(onMonthChange(currentMonth.toString()));
  };

  // Functions
  // Functions - Normal
  const getAllAttendanceSummariesByDepartmentOrShopIdDebounce = debounce(
    (divisionTypeStr, departmentIdVar, shopIdVar) => {
      getAllAttendanceSummariesByDepartmentOrShopId(
        divisionTypeStr,
        departmentIdVar,
        shopIdVar
      );
    },
    timeoutConfig.debouceTime
  );

  // Functions - Queries
  const getAllAttendanceSummariesByDepartmentOrShopId = async (
    divisionTypeStr,
    departmentIdVar,
    shopIdVar
  ) => {
    const startDate = getMonthStartDate(monthVar);
    const endDate = getMonthEndDate(monthVar);

    const results = await getAllAttendanceSummariesByDepartmentOrShopIdFetch(
      token,
      divisionTypeStr === "Departments" ? departmentIdVar : shopIdVar,
      divisionTypeStr,
      startDate,
      endDate
    );

    // Set States
    setAttendanceSummaryFields(
      results.attendanceSummaryFields ? results.attendanceSummaryFields : null
    );
    setStaffWithAttendanceSummaries(
      results.staffWithAttendanceSummaries
        ? results.staffWithAttendanceSummaries
        : null
    );

    setIsLoading(false);
  };

  // Life Cycle
  useEffect(() => {
    if (
      month &&
      ((divisionType === "Departments" && departmentId) ||
        (divisionType === "Shops" && shopId))
    ) {
      // Set States
      setIsLoading(true);

      getAllAttendanceSummariesByDepartmentOrShopIdDebounce(
        divisionType,
        departmentId,
        shopId
      );
    }
  }, [departmentId, divisionId, formSubmitCount, shopId, month]);

  return (
    <div className={classes.contentBoxWithMaxWidth}>
      {/* Backdrop */}
      <LoadingBackdrop
        // States
        showBackdrop={isLoading}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("概要s")}
          </Typography>
        </div>
        {/* Date Picker */}
        <div className={classes.titleTextContainer}>
          {!isMonthVarEqualsCurrentMonth && (
            <div className={classes.formTwinIconBtnContainerLeft}>
              <Button
                onClick={onReturnToCurrentMonthBtnClicked}
                startIcon={<RestoreRoundedIcon />}
                variant="text"
              >
                {t("返回本月")}
              </Button>
            </div>
          )}
          <div className={classes.formDatePickerContainer}>
            <DatePicker
              format="MM/yyyy"
              label={t("月份 (月/年)")}
              onChange={onAttendanceMonthChange}
              slotProps={{ textField: { variant: "standard" } }}
              value={monthVar}
              views={["year", "month"]}
            />
          </div>
          <div className={classes.formTwinIconBtnContainerRight}>
            <IconButton onClick={() => onMonthBackAndForwardBtnClicked("back")}>
              <ArrowBackIosRoundedIcon />
            </IconButton>
            <IconButton
              onClick={() => onMonthBackAndForwardBtnClicked("forward")}
            >
              <ArrowForwardIosRoundedIcon />
            </IconButton>
          </div>
        </div>
      </div>
      {attendanceSummaryFields && staffWithAttendanceSummaries ? (
        // Table
        <AttendanceSummaryTable
          // States
          attendanceSummaryFields={attendanceSummaryFields}
          staffWithAttendanceSummaries={staffWithAttendanceSummaries}
        />
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有概要資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default AttendanceSummaryTableContainer;
