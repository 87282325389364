const initialState = {
  brandId: null,
  companyId: null,
  formSubmitCount: 0,
  tabItem: "Companies",
  typeOfCompsAndBrands: "Current",
};

export const compsAndBrandsPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_COMPS_AND_BRANDS_PAGE_BRAND_CHANGE":
      const { brandId } = action;
      return {
        ...state,
        brandId,
      };
    case "ON_COMPS_AND_BRANDS_PAGE_COMPANY_CHANGE":
      const { companyId } = action;
      return {
        ...state,
        companyId,
      };
    case "ON_COMPS_AND_BRANDS_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_COMPS_AND_BRANDS_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    case "ON_COMPS_AND_BRANDS_PAGE_TYPE_OF_COMPS_AND_BRANDS_CHANGE":
      const { typeOfCompsAndBrands } = action;
      return {
        ...state,
        typeOfCompsAndBrands,
      };
    default:
      return state;
  }
};
