// Configs
import apisConfig from "../configs/apisConfig";

export const createApplicationProcedureFetch = async (
  token,
  applicationTypeId,
  applicationProcedureNameCh,
  applicationProcedureNameEn,
  applicationDeptTypeId,
  applicationStaffTypeId,
  departmentIdsArr,
  divisionIdsArr,
  shopIdsArr,
  titleIdsArr
) => {
  try {
    const queryRoute = "/applicationProcedure/createApplicationProcedure";

    const reqBody = {
      applicationTypeId,
      applicationProcedureNameCh,
      applicationProcedureNameEn,
      applicationDeptTypeId,
      applicationStaffTypeId,
      departmentIdsArr,
      divisionIdsArr,
      shopIdsArr,
      titleIdsArr,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteApplicationProcedureFetch = async (
  token,
  applicationProcedureId
) => {
  try {
    const queryRoute = `/applicationProcedure/deleteApplicationProcedure/${applicationProcedureId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editApplicationProcedureFetch = async (
  token,
  applicationProcedureId,
  applicationTypeId,
  applicationProcedureNameCh,
  applicationProcedureNameEn,
  applicationDeptTypeId,
  applicationStaffTypeId,
  departmentIdsArr,
  divisionIdsArr,
  shopIdsArr,
  titleIdsArr
) => {
  try {
    const queryRoute = `/applicationProcedure/editApplicationProcedure/${applicationProcedureId}`;

    const reqBody = {
      applicationTypeId,
      applicationProcedureNameCh,
      applicationProcedureNameEn,
      applicationDeptTypeId,
      applicationStaffTypeId,
      departmentIdsArr,
      divisionIdsArr,
      shopIdsArr,
      titleIdsArr,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllApplicationProceduresByApplicationTypeIdFetch = async (
  token,
  applicationTypeId
) => {
  try {
    const queryRoute = `/applicationProcedure/getAllApplicationProceduresByApplicationTypeId/${applicationTypeId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getApplicationProcedureDetailsByIdFetch = async (
  token,
  applicationProcedureId
) => {
  try {
    const queryRoute = `/applicationProcedure/getApplicationProcedureDetailsById/${applicationProcedureId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
