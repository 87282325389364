// Components
// Boxes
import CaptionBox from "./captionBox";

// Custom Hooks
import { useLanguage } from "../../../../customHooks/getLanguage";

// Material UI
// Components
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Grid";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function QuestionBoxContentDate(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onDateTimeInputFieldChange,
    onInputFieldKeyPressed,
    // States
    groupIndex,
    index,
    item,
  } = props;

  // Handle States
  const answerValueVar = item.answerValue ? new Date(item.answerValue) : null;

  return (
    <Grid className={classes.formDatePickerContainer} item xs={12}>
      <DatePicker
        format="dd/MM/yyyy"
        label={`${item[t("edited_question_text_ch")]} ${t("(日/月/年)")}`}
        onChange={(value) =>
          onDateTimeInputFieldChange(groupIndex, index, value)
        }
        onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
        readOnly={!item.is_editable}
        slotProps={{
          textField: {
            error: item.isError,
            helperText: item.errorText,
            variant: "standard",
          },
        }}
        value={answerValueVar}
        views={["year", "month", "day"]}
      />
      {item[t("captionStrCh")] && (
        <CaptionBox
          // States
          captionStr={item[t("captionStrCh")]}
        />
      )}
    </Grid>
  );
}

export default QuestionBoxContentDate;
