// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material-UI
import { createStyles, makeStyles } from "@mui/styles";

// sx Styles
export const formSubmitBtnStyles = { marginTop: "10px", width: "40%" };

export const useHorizontalItemBoxesContainerStyles = makeStyles((theme) =>
  createStyles({
    // Box
    contentBox: {
      alignItems: "flex-start",
      backgroundColor:
        theme.palette.mode === "light"
          ? stylesConfig.contentBackgroundColorLight
          : stylesConfig.contentBackgroundColorDark,
      borderRadius: stylesConfig.borderRadiusSmall,
      boxShadow:
        theme.palette.mode === "light"
          ? stylesConfig.shadowLargeLight
          : stylesConfig.shadowLargeDark,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      padding: "20px 20px",
      position: "relative",
    },
    contentBoxWithMaxWidth: {
      alignItems: "flex-start",
      backgroundColor:
        theme.palette.mode === "light"
          ? stylesConfig.contentBackgroundColorLight
          : stylesConfig.contentBackgroundColorDark,
      borderRadius: stylesConfig.borderRadiusSmall,
      boxShadow:
        theme.palette.mode === "light"
          ? stylesConfig.shadowLargeLight
          : stylesConfig.shadowLargeDark,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      padding: "20px 20px",
      position: "relative",
      [theme.breakpoints.up("md")]: {
        maxWidth: `calc(100vw - ${stylesConfig.leftDrawerWidth + 40}px)`,
      },
    },
    emptyDataContainer: {
      margin: "10px 0px",
    },
    itemBoxesContainer: {
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "flex-start",
    },
    titleContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: "5px",
      width: "100%",
    },
    titleTextContainer: {
      alignItems: "center",
      color: theme.palette.mode === "light" ? stylesConfig.mainTextColor : null,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
    },
  })
);
