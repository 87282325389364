// Actions
import {
  onSettingTypeChange,
  onSettingTypeItemChange,
} from "../../../redux/pages/salariesPage/salariesPageActions";

// Consts
import { salariesPageSettingTypeOptions } from "../../../consts/salariesPageSettingBoxContainerConsts";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function SettingTypeBoxContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const settingTypeValue = useSelector(
    (state) => state.salariesPage.settingTypeValue
  );
  const settingTypeItem = useSelector(
    (state) => state.salariesPage.settingTypeItem
  );

  // Events
  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "settingType":
        dispatch(onSettingTypeChange(value.value));
        break;
      default:
        break;
    }
  };

  // Functions
  // Functions - Normal
  const setInitialStates = () => {
    // Update Redux Store
    if (!settingTypeValue) {
      dispatch(onSettingTypeChange(salariesPageSettingTypeOptions[0].value));
    }
  };

  // Life Cycle
  useEffect(() => {
    setInitialStates();
  }, []);

  useEffect(() => {
    // Update Redux Store
    if (settingTypeValue) {
      dispatch(
        onSettingTypeItemChange(
          salariesPageSettingTypeOptions.find(
            (item) => item.value === settingTypeValue
          )
        )
      );
    }
  }, [settingTypeValue]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("設定類別")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      <div className={classes.autoCompleteContainer}>
        <Autocomplete
          disablePortal
          fullWidth
          getOptionLabel={(option) => option[t("textCh")]}
          onChange={(event, value) => onInputFieldChange("settingType", value)}
          options={salariesPageSettingTypeOptions}
          renderInput={(params) => (
            <TextField {...params} label={t("設定類別")} variant="outlined" />
          )}
          value={settingTypeItem}
        />
      </div>
    </div>
  );
}

export default SettingTypeBoxContainer;
