// Actions
import {
  onConditionApplicationOrApprovalTypeChange,
  onConditionApplicationOrApprovalTypeItemChange,
  onConditionApplicationStatusTypeChange,
  onConditionApplicationStatusTypeItemChange,
  onConditionApplicationTypeChange,
  onConditionApplicationTypeItemChange,
} from "../../../redux/pages/applicationsPage/applicationsPageActions";

// Consts
import { applicationOrApprovalTypeOptions } from "../../../consts/applicationConditionBoxesContainerConsts";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Dummy Datum
import { applicationTypeDummyData } from "../../../dummyDatum/applicationTypeDummyData";

// Fetches
import { getAllApplicationTypesFetch } from "../../../fetches/applicationTypeFetches";
import { getAllApplicationStatusTypesFetch } from "../../../fetches/applicationStatusTypeFetches";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ApplicationConditionBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const applicationOrApprovalTypeValue = useSelector(
    (state) => state.applicationsPage.conditionApplicationOrApprovalTypeValue
  );
  const applicationOrApprovalTypeItem = useSelector(
    (state) => state.applicationsPage.conditionApplicationOrApprovalTypeItem
  );
  const applicationStatusTypeId = useSelector(
    (state) => state.applicationsPage.conditionApplicationStatusTypeId
  );
  const applicationStatusTypeItem = useSelector(
    (state) => state.applicationsPage.conditionApplicationStatusTypeItem
  );
  const applicationTypeId = useSelector(
    (state) => state.applicationsPage.conditionApplicationTypeId
  );
  const applicationTypeItem = useSelector(
    (state) => state.applicationsPage.conditionApplicationTypeItem
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [applicationStatusTypes, setApplicationStatusTypes] = useState(null);
  const [applicationTypes, setApplicationTypes] = useState(null);

  // Events
  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "applicationOrApprovalType":
        dispatch(onConditionApplicationOrApprovalTypeChange(value.value));
        break;
      case "applicationStatusType":
        dispatch(onConditionApplicationStatusTypeChange(value.id));
        break;
      case "applicationType":
        dispatch(onConditionApplicationTypeChange(value.id));
        break;
      default:
        break;
    }
  };

  // Functions
  // Functions - Normal
  const getAllConditions = () => {
    getAllApplicationStatusTypes();
    getAllApplicationTypes();
  };

  const setInitialStates = () => {
    // Update Redux Store
    if (!applicationOrApprovalTypeValue) {
      dispatch(
        onConditionApplicationOrApprovalTypeChange(
          applicationOrApprovalTypeOptions[0].value
        )
      );
    }
  };

  // Functions - Queries
  const getAllApplicationStatusTypes = async () => {
    const results = await getAllApplicationStatusTypesFetch(token);

    // Set States
    setApplicationStatusTypes(
      results.applicationStatusTypes ? results.applicationStatusTypes : null
    );

    // Update Redux Store
    if (results.applicationStatusTypes && !applicationStatusTypeId) {
      dispatch(
        onConditionApplicationStatusTypeChange(
          results.applicationStatusTypes[0].id
        )
      );
    }
  };

  const getAllApplicationTypes = async () => {
    const results = await getAllApplicationTypesFetch(token);

    // Set States
    setApplicationTypes(
      results.applicationTypes
        ? [applicationTypeDummyData, ...results.applicationTypes]
        : null
    );

    // Update Redux Store
    if (results.applicationTypes && !applicationTypeId) {
      dispatch(onConditionApplicationTypeChange(applicationTypeDummyData.id));
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllConditions();
    setInitialStates();
  }, []);

  useEffect(() => {
    // Update Redux Store
    if (applicationStatusTypes && applicationStatusTypeId) {
      dispatch(
        onConditionApplicationStatusTypeItemChange(
          applicationStatusTypes.find(
            (item) => item.id === applicationStatusTypeId
          )
        )
      );
    }
  }, [applicationStatusTypes, applicationStatusTypeId]);

  useEffect(() => {
    // Update Redux Store
    if (applicationTypes && applicationTypeId) {
      dispatch(
        onConditionApplicationTypeItemChange(
          applicationTypes.find((item) => item.id === applicationTypeId)
        )
      );
    }
  }, [applicationTypes, applicationTypeId]);

  useEffect(() => {
    // Update Redux Store
    if (applicationOrApprovalTypeValue) {
      dispatch(
        onConditionApplicationOrApprovalTypeItemChange(
          applicationOrApprovalTypeOptions.find(
            (item) => item.value === applicationOrApprovalTypeValue
          )
        )
      );
    }
  }, [applicationOrApprovalTypeValue]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("申請 / 審批s")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      <div className={classes.autoCompleteContainer}>
        <Autocomplete
          disablePortal
          fullWidth
          getOptionLabel={(option) => option[t("textCh")]}
          onChange={(event, value) =>
            onInputFieldChange("applicationOrApprovalType", value)
          }
          options={applicationOrApprovalTypeOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("申請 / 審批s")}
              variant="outlined"
            />
          )}
          value={applicationOrApprovalTypeItem}
        />
      </div>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("申請類別")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      {applicationTypes && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) => option[t("application_type_name_ch")]}
            onChange={(event, value) =>
              onInputFieldChange("applicationType", value)
            }
            options={applicationTypes}
            renderInput={(params) => (
              <TextField {...params} label={t("申請類別")} variant="outlined" />
            )}
            value={applicationTypeItem}
          />
        </div>
      )}
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("申請狀態")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      {applicationStatusTypes && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) =>
              option[t("application_status_type_name_ch")]
            }
            onChange={(event, value) =>
              onInputFieldChange("applicationStatusType", value)
            }
            options={applicationStatusTypes}
            renderInput={(params) => (
              <TextField {...params} label={t("申請狀態")} variant="outlined" />
            )}
            value={applicationStatusTypeItem}
          />
        </div>
      )}
    </div>
  );
}

export default ApplicationConditionBoxesContainer;
