// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/attendancesPage/attendancesPageActions";
import { onStaffChange } from "../../../redux/pages/staffProfilePage/staffProfilePageActions";

// Components
// Modals
import AttendanceListModal from "../../modals/attendancesPage/attendanceListModal";
import ModalContainer from "../../modals/modalContainer";
// Tables
import AttendanceRecordBox from "./attendanceRecordBox";
import DayBox from "./dayBox";
import StaffBox from "./staffBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// React
import { useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import {
  tableContainerStyles,
  useStandardTableStyles,
} from "../../../styles/componentStyles/tableStyles/standardTableStyles";

function AttendanceRecordTable(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useStandardTableStyles();

  // Props
  const {
    // States
    days,
    staffWithAttendances,
  } = props;

  // Redux Store
  const divisionType = useSelector((state) => state.rostersPage.divisionType);
  const shopItem = useSelector((state) => state.rostersPage.shopItem);
  const themeMode = useSelector((state) => state.theme.themeMode);

  // States
  // Hover
  const [hoveredDayId, setHoveredDayId] = useState(null);
  const [hoveredStaffId, setHoveredStaffId] = useState(null);
  const [hoveredStaffItemId, setHoveredStaffItemId] = useState(null);
  // Modals
  const [showAttendanceListModal, setShowAttendanceListModal] = useState(false);
  // Select
  const [selectedDayId, setSelectedDayId] = useState(null);
  const [selectedStaffId, setSelectedStaffId] = useState(null);

  // Events
  // Events - Box
  const onStaffItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onStaffChange(itemId));
    // Navigate
    navigate("/staffProfile");
  };

  const onItemClicked = (staffId, dayId) => {
    // Set States
    setSelectedDayId(dayId);
    setSelectedStaffId(staffId);

    displayAttendanceListModal();
  };

  // Events - Hover
  const onMouseEnterItem = (staffId, dayId) => {
    // Set States
    setHoveredDayId(dayId);
    setHoveredStaffId(staffId);
  };

  const onMouseEnterStaffItem = (itemId) => {
    // Set States
    setHoveredStaffItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredDayId(null);
    setHoveredStaffId(null);
  };

  const onMouseLeaveStaffItem = () => {
    // Set States
    setHoveredStaffItemId(null);
  };

  // Events - Modal
  const onAttendanceListModalClosed = () => {
    // Set States
    setShowAttendanceListModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  // Functions
  // Functions - Normal
  const displayAttendanceListModal = () => {
    // Set States
    setShowAttendanceListModal(true);
  };

  return (
    <TableContainer sx={tableContainerStyles}>
      {/* Modals */}
      <ModalContainer
        // Events
        onModalClosed={onAttendanceListModalClosed}
        // States
        showModal={showAttendanceListModal}
      >
        <AttendanceListModal
          // States
          dayId={selectedDayId}
          staffId={selectedStaffId}
        />
      </ModalContainer>
      <Table stickyHeader>
        <TableHead>
          {days && (
            <TableRow>
              <TableCell className={classes.stickyColumnHeader}></TableCell>
              {days.map((dayItem) => (
                <TableCell
                  align="center"
                  key={dayItem.id}
                  sx={{
                    backgroundColor:
                      themeMode === "light"
                        ? stylesConfig.contentBackgroundColorLight
                        : stylesConfig.contentBackgroundColorDark,
                  }}
                >
                  <DayBox
                    // States
                    hoveredDayId={hoveredDayId}
                    item={dayItem}
                  />
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        {staffWithAttendances && (
          <TableBody>
            {staffWithAttendances.map((staffItem) => (
              <TableRow key={staffItem.id}>
                <TableCell className={classes.stickyColumn} scope="row">
                  <StaffBox
                    // States
                    divisionType={divisionType}
                    hoveredStaffId={hoveredStaffId}
                    hoveredStaffItemId={hoveredStaffItemId}
                    item={staffItem}
                    shopItem={shopItem}
                    // Events
                    onItemClicked={onStaffItemClicked}
                    onMouseEnterItem={onMouseEnterStaffItem}
                    onMouseLeaveItem={onMouseLeaveStaffItem}
                  />
                </TableCell>
                {staffItem.attendances.map((attendanceitem) => (
                  <TableCell
                    align="center"
                    key={attendanceitem.day_id}
                    scope="row"
                  >
                    <AttendanceRecordBox
                      // States
                      item={attendanceitem}
                      staffId={staffItem.id}
                      // Events
                      onItemClicked={onItemClicked}
                      onMouseEnterItem={onMouseEnterItem}
                      onMouseLeaveItem={onMouseLeaveItem}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}

export default AttendanceRecordTable;
