// Configs
import apisConfig from "../configs/apisConfig";

export const createTitleFetch = async (
  token,
  titleNameCh,
  titleNameEnFull,
  titleNameEnShort,
  isActive,
  divisionId
) => {
  try {
    const queryRoute = "/title/createTitle";

    const reqBody = {
      titleNameCh,
      titleNameEnFull,
      titleNameEnShort,
      isActive,
      divisionId,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteOrRestoreTitleFetch = async (
  token,
  titleId,
  typeOfTitles
) => {
  try {
    const queryRoute = `/title/deleteOrRestoreTitle/${titleId}`;

    const reqBody = { typeOfTitles };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editTitleFetch = async (
  token,
  titleId,
  titleNameCh,
  titleNameEnFull,
  titleNameEnShort,
  isActive,
  divisionId
) => {
  try {
    const queryRoute = `/title/editTitle/${titleId}`;

    const reqBody = {
      titleNameCh,
      titleNameEnFull,
      titleNameEnShort,
      isActive,
      divisionId,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllTitlesByDivisionIdFetch = async (
  token,
  divisionId,
  typeOfTitles
) => {
  try {
    const queryRoute = `/title/getAllTitlesByDivisionId/${divisionId}`;

    const reqBody = {
      typeOfTitles,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getTitleDetailsByIdFetch = async (token, titleId) => {
  try {
    const queryRoute = `/title/getTitleDetailsById/${titleId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
