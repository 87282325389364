const initialState = {
  candidateId: null,
  formSubmitCount: 0,
  tabItem: "BasicInfo",
};

export const candidateProfilePageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_CANDIDATE_PROFILE_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_CANDIDATE_PROFILE_PAGE_CANDIDATE_CHANGE":
      const { candidateId } = action;
      return {
        ...state,
        candidateId,
      };
    case "ON_CANDIDATE_PROFILE_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    default:
      return state;
  }
};
