// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ApplicationProcedureDetailFormContent(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // States
    applicationDeptTypeField,
    applicationDeptTypeOptions,
    applicationProcedureId,
    applicationStaffTypeField,
    applicationStaffTypeOptions,
    departmentField,
    departmentOptions,
    divisionField,
    divisionOptions,
    formAlertText,
    formAlertType,
    nameCh,
    nameEn,
    shopField,
    shopOptions,
    shouldShowFormAlert,
    titleField,
    titleOptions,
    // Events
    onInputFieldChange,
    onInputFieldKeyPressed,
    onSubmitBtnClicked,
    // Functions
    checkIsFieldError,
    getErrorFieldMessage,
  } = props;

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("程序資料")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("nameCh")}
            fullWidth
            helperText={getErrorFieldMessage("nameCh")}
            label={t("中文名稱")}
            margin="dense"
            name="nameCh"
            onChange={(event) =>
              onInputFieldChange("nameCh", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={nameCh}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("nameEn")}
            fullWidth
            helperText={getErrorFieldMessage("nameEn")}
            label={t("英文名稱")}
            margin="dense"
            name="nameEn"
            onChange={(event) =>
              onInputFieldChange("nameEn", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={nameEn}
            variant="standard"
          />
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {applicationDeptTypeOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) =>
                option[t("application_dept_type_name_ch")]
              }
              onChange={(event, value) =>
                onInputFieldChange("applicationDeptTypeField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={applicationDeptTypeOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("applicationDeptTypeField")}
                  helperText={getErrorFieldMessage("applicationDeptTypeField")}
                  label={t("部門 / 店鋪類型")}
                  variant="standard"
                />
              )}
              value={applicationDeptTypeField}
            />
          )}
        </Grid>
        {applicationDeptTypeField &&
          ["All", "Affiliated", "Applied Dept", "Specific Dept"].includes(
            applicationDeptTypeField.application_dept_type_name_en
          ) && (
            <Grid className={classes.formAutoCompleteContainer} item xs={6}>
              {applicationStaffTypeOptions && (
                <Autocomplete
                  disablePortal
                  getOptionLabel={(option) =>
                    option[t("application_staff_type_name_ch")]
                  }
                  onChange={(event, value) =>
                    onInputFieldChange("applicationStaffTypeField", value)
                  }
                  onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                  options={applicationStaffTypeOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={checkIsFieldError("applicationStaffTypeField")}
                      helperText={getErrorFieldMessage(
                        "applicationStaffTypeField"
                      )}
                      label={t("員工類型")}
                      variant="standard"
                    />
                  )}
                  value={applicationStaffTypeField}
                />
              )}
            </Grid>
          )}
        <Grid className={classes.formAutoCompleteContainer} item xs={12}>
          {divisionField && divisionOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option[t("division_name_ch")]}
              multiple
              noOptionsText={t("未有相關類別")}
              onChange={(event, value) =>
                onInputFieldChange("divisionField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={divisionOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("divisionField")}
                  helperText={getErrorFieldMessage("divisionField")}
                  label={t("套用到")}
                  variant="standard"
                />
              )}
              value={divisionField}
            />
          )}
        </Grid>
        {applicationDeptTypeField &&
          applicationDeptTypeField.application_dept_type_name_en ===
            "Specific Dept" && (
            <Grid className={classes.formAutoCompleteContainer} item xs={12}>
              {departmentField && departmentOptions && (
                <Autocomplete
                  disablePortal
                  getOptionLabel={(option) =>
                    option[t("department_name_ch")]
                      ? `${option.department_name_en_short} - ${
                          option[t("department_name_ch")]
                        }`
                      : option.department_name_en_short
                  }
                  multiple
                  noOptionsText={t("未有相關部門")}
                  onChange={(event, value) =>
                    onInputFieldChange("departmentField", value)
                  }
                  onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                  options={departmentOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={checkIsFieldError("departmentField")}
                      helperText={getErrorFieldMessage("departmentField")}
                      label={t("部門")}
                      variant="standard"
                    />
                  )}
                  value={departmentField}
                />
              )}
            </Grid>
          )}
        {applicationDeptTypeField &&
          applicationDeptTypeField.application_dept_type_name_en ===
            "Specific Dept" && (
            <Grid className={classes.formAutoCompleteContainer} item xs={12}>
              {shopField && shopOptions && (
                <Autocomplete
                  disablePortal
                  getOptionLabel={(option) => option.shop_code}
                  multiple
                  noOptionsText={t("未有相關店鋪")}
                  onChange={(event, value) =>
                    onInputFieldChange("shopField", value)
                  }
                  onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                  options={shopOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={checkIsFieldError("shopField")}
                      helperText={getErrorFieldMessage("shopField")}
                      label={t("店鋪")}
                      variant="standard"
                    />
                  )}
                  value={shopField}
                />
              )}
            </Grid>
          )}
        {((applicationDeptTypeField &&
          applicationDeptTypeField.application_dept_type_name_en ===
            "Specific Title") ||
          (applicationStaffTypeField &&
            applicationStaffTypeField.application_staff_type_name_en ===
              "Specific Title")) && (
          <Grid className={classes.formAutoCompleteContainer} item xs={12}>
            {titleField && titleOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) =>
                  option[t("title_name_ch")]
                    ? `${option.title_name_en_full} - ${
                        option[t("title_name_ch")]
                      }`
                    : option.title_name_en_full
                }
                multiple
                noOptionsText={t("未有相關職位")}
                onChange={(event, value) =>
                  onInputFieldChange("titleField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={titleOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("titleField")}
                    helperText={getErrorFieldMessage("titleField")}
                    label={t("職位")}
                    variant="standard"
                  />
                )}
                value={titleField}
              />
            )}
          </Grid>
        )}
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <Button
            onClick={onSubmitBtnClicked}
            variant="contained"
            sx={formSubmitBtnStyles}
          >
            {applicationProcedureId ? t("修改") : t("新增")}
          </Button>
        </Grid>
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default ApplicationProcedureDetailFormContent;
