// Actions
import { onStaffChange } from "../../../redux/pages/staffProfilePage/staffProfilePageActions";

// Components
// Tables
import DayOffTypeBox from "./dayOffTypeBox";
import StaffBox from "./staffBox";
import StaffToDayOffTypeBox from "./staffToDayOffTypeBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// React
import { useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import {
  tableContainerStyles,
  useStandardTableStyles,
} from "../../../styles/componentStyles/tableStyles/standardTableStyles";

function DayOffQuotaTable(props) {
  // Hooks
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useStandardTableStyles();

  // Props
  const {
    // States
    dayOffTypes,
    staffWithDayOffQuotas,
  } = props;

  // Redux Store
  const themeMode = useSelector((state) => state.theme.themeMode);

  // States
  // Hover
  const [hoveredDayOffTypeId, setHoveredDayOffTypeId] = useState(null);
  const [hoveredStaffId, setHoveredStaffId] = useState(null);
  const [hoveredStaffItemId, setHoveredStaffItemId] = useState(null);

  // Events
  // Events - Box
  const onStaffItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onStaffChange(itemId));
    // Navigate
    navigate("/staffProfile");
  };

  // Events - Hover
  const onMouseEnterItem = (staffId, dayOffTypeId) => {
    // Set States
    setHoveredDayOffTypeId(dayOffTypeId ? dayOffTypeId : null);
    setHoveredStaffId(staffId);
  };

  const onMouseEnterStaffItem = (itemId) => {
    // Set States
    setHoveredStaffItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredDayOffTypeId(null);
    setHoveredStaffId(null);
  };

  const onMouseLeaveStaffItem = () => {
    // Set States
    setHoveredStaffItemId(null);
  };

  return (
    <TableContainer sx={tableContainerStyles}>
      <Table stickyHeader>
        <TableHead>
          {dayOffTypes && (
            <TableRow>
              <TableCell className={classes.stickyColumnHeader}></TableCell>
              {dayOffTypes.map((dayOffTypeItem) => (
                <TableCell
                  align="center"
                  key={dayOffTypeItem.id}
                  sx={{
                    backgroundColor:
                      themeMode === "light"
                        ? stylesConfig.contentBackgroundColorLight
                        : stylesConfig.contentBackgroundColorDark,
                  }}
                >
                  <DayOffTypeBox
                    // States
                    hoveredDayOffTypeId={hoveredDayOffTypeId}
                    item={dayOffTypeItem}
                  />
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        {staffWithDayOffQuotas && (
          <TableBody>
            {staffWithDayOffQuotas.map((staffItem) => (
              <TableRow key={staffItem.id}>
                <TableCell className={classes.stickyColumn} scope="row">
                  <StaffBox
                    // States
                    hoveredStaffId={hoveredStaffId}
                    hoveredStaffItemId={hoveredStaffItemId}
                    item={staffItem}
                    // Events
                    onItemClicked={onStaffItemClicked}
                    onMouseEnterItem={onMouseEnterStaffItem}
                    onMouseLeaveItem={onMouseLeaveStaffItem}
                  />
                </TableCell>
                {staffItem.staffToDayOffTypes.map((staffToDayOffTypeitem) => (
                  <TableCell
                    align="center"
                    key={staffToDayOffTypeitem.day_off_type_id}
                    scope="row"
                  >
                    <StaffToDayOffTypeBox
                      // States
                      item={staffToDayOffTypeitem}
                      staffId={staffItem.id}
                      // Events
                      onMouseEnterItem={onMouseEnterItem}
                      onMouseLeaveItem={onMouseLeaveItem}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}

export default DayOffQuotaTable;
