// Components
import StyledMenu from "../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

function CandidateWorkExperienceActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // States
    showCandidateWorkExperienceActionMenu,
    // Set States
    setShowCandidateWorkExperienceActionMenu,
    // Functions
    displayCandidateWorkExperienceActionDialog,
    displayCandidateWorkExperienceActionModal,
  } = props;

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowCandidateWorkExperienceActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "Delete":
        displayCandidateWorkExperienceActionDialog(
          "DeleteCandidateWorkExperience"
        );
        break;
      case "Edit":
        displayCandidateWorkExperienceActionModal();
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showCandidateWorkExperienceActionMenu}
      open={Boolean(showCandidateWorkExperienceActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Edit")}>
        <EditRoundedIcon />
        {t("編輯")}
      </MenuItem>
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Delete")}>
        <DeleteRoundedIcon />
        {t("刪除")}
      </MenuItem>
    </StyledMenu>
  );
}

export default CandidateWorkExperienceActionMenu;
