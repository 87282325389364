// Configs
import stylesConfig from "../../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../../customHooks/getLanguage";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardItemBoxStyles } from "../../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function StaffActionModalInfo(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    item,
  } = props;

  return (
    <div className={classes.itemContainer}>
      <div>
        <div>
          <Typography align={"left"} variant="body1">
            {`${item.staff_code} - ${item.full_name_en}`}
            {item.alias && ` (${item.alias})`}
          </Typography>
        </div>
        <Typography
          align={"left"}
          color={stylesConfig.greyTextColor}
          variant="body2"
        >
          {`${item.dept_name_display} - `}
          {item[t("title_name_ch")]}
        </Typography>
      </div>
    </div>
  );
}

export default StaffActionModalInfo;
