// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function StaffBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    hoveredItemId,
    item,
    typeOfStaffs,
    // Events
    onItemClicked,
    onItemRightClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isHovered = hoveredItemId && hoveredItemId === item.id;

  let dateDisplayed;

  switch (typeOfStaffs) {
    case "Birthday":
      dateDisplayed = item.date_birth;
      break;
    case "Onboarding":
      dateDisplayed = item.relocation_date;
      break;
    case "PassingProbation":
      dateDisplayed = item.probation_date;
      break;
    case "Resignation":
      dateDisplayed = item.relocation_date;
      break;
    default:
      break;
  }

  return (
    <div
      className={
        isHovered ? classes.hoveredItemContainer : classes.itemContainer
      }
      onClick={() => onItemClicked(item.id)}
      onContextMenu={(event) => {
        event.preventDefault();
        onItemRightClicked(item, event.currentTarget);
      }}
      onMouseEnter={() => onMouseEnterItem(item.id)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div>
        <Typography
          align={"left"}
          color={isHovered ? stylesConfig.mainTextColor : null}
          variant="body1"
        >
          {`${item.staff_code} - ${item.full_name_en}`}
        </Typography>
        <Typography
          align={"left"}
          color={stylesConfig.greyTextColor}
          variant="body2"
        >
          {`${item.dept_name_display} - `}
          {item[t("title_name_ch")]}
        </Typography>
      </div>
      <div>
        <Typography align={"left"} variant="body1">
          {dateDisplayed}
        </Typography>
      </div>
    </div>
  );
}

export default StaffBox;
