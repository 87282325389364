// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import ApplicationBoxesContainer from "../components/boxes/applicationsPage/applicationBoxesContainer";
import ApplicationConditionBoxesContainer from "../components/boxes/applicationsPage/applicationConditionBoxesContainer";
import ApplicationProcedureBoxesContainer from "../components/boxes/applicationsPage/applicationProcedureBoxesContainer";
import ApplicationProcedureStaffBoxesContainer from "../components/boxes/applicationsPage/applicationProcedureStaffBoxesContainer";
import CandidateBoxesContainer from "../components/boxes/applicationsPage/candidateBoxesContainer";
import CandidateConditionBoxesContainer from "../components/boxes/applicationsPage/candidateConditionBoxesContainer";
// Forms
import ApplicationDetailForm from "../components/forms/applicationsPage/applicationDetailForm";
import ApplicationProcedureDetailFormContainer from "../components/forms/applicationsPage/applicationProcedureDetailFormContainer";
// Headers
import ApplicationsPageHeader from "../components/headers/applicationsPage/applicationsPageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function ApplicationsPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const tabItem = useSelector((state) => state.applicationsPage.tabItem);

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("Applications"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <ApplicationsPageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          {tabItem === "ApplicationProcedures" && (
            <ApplicationProcedureBoxesContainer />
          )}
          {tabItem === "ApplicationRecords" && (
            <ApplicationConditionBoxesContainer />
          )}
          {tabItem === "JobApplications" && (
            <CandidateConditionBoxesContainer />
          )}
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          {tabItem === "ApplicationProcedures" && (
            <>
              <ApplicationProcedureDetailFormContainer />
              <SpacingBox
                // Render
                height={stylesConfig.spacingBoxMarginBottom}
              />
              <ApplicationProcedureStaffBoxesContainer />
              <SpacingBox
                // Render
                height={stylesConfig.spacingBoxMarginBottom}
              />
            </>
          )}
          {tabItem === "ApplicationRecords" && (
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <ApplicationBoxesContainer />
                <SpacingBox
                  // Render
                  height={stylesConfig.spacingBoxMarginBottom}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <ApplicationDetailForm />
                <SpacingBox
                  // Render
                  height={stylesConfig.spacingBoxMarginBottom}
                />
              </Grid>
            </Grid>
          )}
          {tabItem === "JobApplications" && (
            <>
              <CandidateBoxesContainer />
              <SpacingBox
                // Render
                height={stylesConfig.spacingBoxMarginBottom}
              />
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default ApplicationsPage;
