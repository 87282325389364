// Actions
import {
  onCandidateChange,
  onTabItemChange,
} from "../../../redux/pages/candidateProfilePage/candidateProfilePageActions";

// Components
// Boxes
import StaffIntroBasicInfoBox from "./staffIntroBasicInfoBox";
import StaffIntroProbationInfoBox from "./staffIntroProbationInfoBox";
// Modals
import ModalContainer from "../../modals/modalContainer";
import StaffProbationActionModal from "../../modals/staffProfilePage/staffProbationActionModal";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getStaffBasicInfoByIdFetch } from "../../../fetches/staffFetches";

// Helper Functions
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function StaffIntroBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.staffProfilePage.formSubmitCount
  );
  const language = useSelector((state) => state.language.language);
  const staffId = useSelector((state) => state.staffProfilePage.staffId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [candidateId, setCandidateId] = useState(null);
  const [staffBasicInfo, setStaffBasicInfo] = useState(null);
  // Modals
  const [showStaffProbationActionModal, setShowStaffProbationActionModal] =
    useState(false);

  // Handle States
  const item = staffBasicInfo;

  // Events
  // Events - Box
  const onStaffProbationItemClicked = () => {
    displayStaffProbationActionModal();
  };

  // Events - Modal
  const onStaffProbationActionModalClosed = () => {
    getStaffBasicInfoById();

    // Set States
    setShowStaffProbationActionModal(false);
  };

  // Events - Title
  const onViewCandidateBtnClicked = () => {
    if (candidateId) {
      // Update Redux Store
      dispatch(onCandidateChange(candidateId));
      dispatch(onTabItemChange("BasicInfo"));

      // Navigate
      navigate("/candidateProfile");
    }
  };

  // Functions
  // Functions - Normal
  const displayStaffProbationActionModal = () => {
    setShowStaffProbationActionModal(true);
  };

  // Functions = Queries
  const getStaffBasicInfoById = async () => {
    const results = await getStaffBasicInfoByIdFetch(token, staffId);

    if (results.staffBasicInfo) {
      results.staffBasicInfo.probation_date = moment(
        results.staffBasicInfo.probation_date
      ).format(t("YYYY年 MM月 DD日"));
    }

    // Set States
    setCandidateId(results.candidateId ? results.candidateId : null);
    setStaffBasicInfo(results.staffBasicInfo ? results.staffBasicInfo : null);
  };

  // Life Cycle
  useEffect(() => {
    if (staffId) {
      getStaffBasicInfoById();
    }
  }, [formSubmitCount, staffId, language]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onStaffProbationActionModalClosed}
        // States
        showModal={showStaffProbationActionModal}
      >
        <StaffProbationActionModal
          // States
          staffId={staffId}
          // Events
          onModalClosed={onStaffProbationActionModalClosed}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("員工")}
          </Typography>
          {candidateId && (
            <Tooltip placement="right" title={t("查看應徵資料")}>
              <IconButton color={"primary"} onClick={onViewCandidateBtnClicked}>
                <AssignmentIndRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      {/* Content */}
      {item ? (
        <>
          <StaffIntroBasicInfoBox
            // States
            item={item}
          />
          <StaffIntroProbationInfoBox
            // States
            item={item}
            // Events
            onItemClicked={onStaffProbationItemClicked}
          />
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有員工資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default StaffIntroBoxesContainer;
