// Actions
import { onTabItemChange } from "../../../redux/pages/salariesPage/salariesPageActions";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardListPageStyles } from "../../../styles/pageStyles/standardListPageStyles";

function SalariesPageHeader() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardListPageStyles();

  // Redux Store
  const tabItem = useSelector((state) => state.salariesPage.tabItem);

  // Events
  // Events - Tabs
  const onTabClicked = (event, value) => {
    // Update Redux Store
    dispatch(onTabItemChange(value));
  };

  return (
    <Box className={classes.divisionsContainer}>
      {/* Tabs */}
      <div className={classes.tabsContainer}>
        {tabItem && (
          <Tabs
            scrollButtons={false}
            value={tabItem}
            variant="scrollable"
            onChange={onTabClicked}
          >
            <Tab
              disableRipple
              key={"Salaries"}
              label={
                <Typography variant="h6" align={"center"} gutterBottom>
                  {t("薪酬s")}
                </Typography>
              }
              value={"Salaries"}
            />
            <Tab
              disableRipple
              key={"Settings"}
              label={
                <Typography variant="h6" align={"center"} gutterBottom>
                  {t("設定s")}
                </Typography>
              }
              value={"Settings"}
            />
          </Tabs>
        )}
      </div>
    </Box>
  );
}

export default SalariesPageHeader;
