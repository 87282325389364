export const hanleChangeInputField = (questions, index, value) => {
  if (!questions) {
    return null;
  }

  if (!value) {
    return questions;
  }

  const valueVar = Array.isArray(value) ? value[0] : value;

  for (let questionItem of questions) {
    if (!questionItem.dependent_question_id) {
      continue;
    }

    if (questionItem.dependent_question_id === questions[index].id) {
      if (questionItem.dependent_option_id !== valueVar) {
        questionItem.answerValue = null;
        questionItem.answerText = null;
        questionItem.isError = false;
      }
    }
  }

  return questions;
};

export const handleQuestions = (questions) => {
  if (!questions) {
    return null;
  }

  for (let item of questions) {
    if (item.dependent_question_id) {
      const dependentQuestion = questions.find(
        (dependentQuestionItem) =>
          dependentQuestionItem.id === item.dependent_question_id
      );

      if (!dependentQuestion) {
        item.shouldDisplay = true;
        continue;
      }

      if (!dependentQuestion.answerValue) {
        item.shouldDisplay = false;
        continue;
      }

      const answerValueVar = Array.isArray(dependentQuestion.answerValue)
        ? dependentQuestion.answerValue[0]
        : dependentQuestion.answerValue;

      item.shouldDisplay = answerValueVar === item.dependent_option_id;
    } else {
      item.shouldDisplay = true;
    }
  }

  return questions;
};

export const handleSubmit = (questions) => {
  if (!questions) {
    return null;
  }

  let tempQuestions = [...questions];
  let isAnyQuestionError = false;

  for (let questionGroupItem of questions) {
    for (let questionItem of questionGroupItem.questions) {
      let {
        application_question_type_id,
        is_required,
        min_num_of_answer,
        answerValue,
      } = questionItem;

      if (questionItem.dependent_question_id) {
        const dependentQuestion = questionGroupItem.questions.find(
          (dependentQuestionItem) =>
            dependentQuestionItem.id === questionItem.dependent_question_id
        );

        if (dependentQuestion && dependentQuestion.answerValue) {
          const answerValueVar = Array.isArray(dependentQuestion.answerValue)
            ? dependentQuestion.answerValue[0]
            : dependentQuestion.answerValue;

          is_required = answerValueVar === questionItem.dependent_option_id;
        }
      }

      if (!is_required) {
        continue;
      }

      const answerValueVar = Array.isArray(answerValue)
        ? answerValue[0]
        : answerValue;

      if (!answerValueVar) {
        questionItem.isError = true;
        isAnyQuestionError = true;
        continue;
      }

      if (application_question_type_id === 1) {
        if (
          Array.isArray(answerValue) &&
          answerValue.length < min_num_of_answer
        ) {
          questionItem.isError = true;
          questionItem.errorText = `請先填寫 ${questionItem.question_text}`;
          isAnyQuestionError = true;
          continue;
        }
      }
    }
  }

  return { tempQuestions, isAnyQuestionError };
};
