// Consts
import { monthOptions } from "../../../consts/relocationDetailFormContentConsts";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect } from "react";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function CreateStaffFormRelocationContent(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // States
    availableRelocationTypeIdsArr,
    companyField,
    companyOptions,
    departmentField,
    departmentOptions,
    divisionField,
    divisionOptions,
    probationLengthField,
    relocationDate,
    relocationTypeField,
    relocationTypeOptions,
    shopField,
    shopOptions,
    titleField,
    titleOptions,
    // Set States
    setProbationLengthField,
    // Events
    onInputFieldChange,
    onInputFieldKeyPressed,
    onNextStepBtnClicked,
    onStepBackBtnClicked,
    // Functions
    checkIsFieldError,
    getErrorFieldMessage,
  } = props;

  // Handle States
  const relocationDateVar = relocationDate ? new Date(relocationDate) : null;

  // Life Cycles
  useEffect(() => {
    if (setProbationLengthField) {
      setProbationLengthField(monthOptions.find((item) => item.value === 3));
    }
  }, []);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("新增調動資料")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {relocationTypeOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option[t("relocation_type_name_ch")]}
              onChange={(event, value) =>
                onInputFieldChange("relocationTypeField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={
                availableRelocationTypeIdsArr
                  ? relocationTypeOptions.filter((item) =>
                      availableRelocationTypeIdsArr.includes(item.id)
                    )
                  : relocationTypeOptions
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("relocationTypeField")}
                  helperText={getErrorFieldMessage("relocationTypeField")}
                  label={t("調動類型")}
                  variant="standard"
                />
              )}
              value={relocationTypeField}
            />
          )}
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {companyOptions && (
            <Autocomplete
              disabled={relocationTypeField && relocationTypeField.id === 2}
              disablePortal
              getOptionLabel={(option) =>
                option[t("company_name_ch")]
                  ? `${option.company_name_en_full} - ${
                      option[t("company_name_ch")]
                    }`
                  : option.company_name_en_full
              }
              onChange={(event, value) =>
                onInputFieldChange("companyField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={companyOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("companyField")}
                  helperText={getErrorFieldMessage("companyField")}
                  label={t("公司")}
                  variant="standard"
                />
              )}
              value={companyField}
            />
          )}
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {divisionOptions && (
            <Autocomplete
              disabled={relocationTypeField && relocationTypeField.id === 2}
              disablePortal
              getOptionLabel={(option) => option[t("division_name_ch")]}
              onChange={(event, value) =>
                onInputFieldChange("divisionField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={divisionOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("divisionField")}
                  helperText={getErrorFieldMessage("divisionField")}
                  label={t("類別")}
                  variant="standard"
                />
              )}
              value={divisionField}
            />
          )}
        </Grid>
        {divisionField &&
          divisionField.division_type_name_en === "Departments" && (
            <Grid className={classes.formAutoCompleteContainer} item xs={6}>
              {departmentOptions && (
                <Autocomplete
                  disabled={relocationTypeField && relocationTypeField.id === 2}
                  disablePortal
                  getOptionLabel={(option) =>
                    option[t("department_name_ch")]
                      ? `${option.department_name_en_short} - ${
                          option[t("department_name_ch")]
                        }`
                      : option.department_name_en_short
                  }
                  onChange={(event, value) =>
                    onInputFieldChange("departmentField", value)
                  }
                  onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                  options={
                    divisionField
                      ? departmentOptions.filter(
                          (option) => option.division_id === divisionField.id
                        )
                      : departmentOptions
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={checkIsFieldError("departmentField")}
                      helperText={getErrorFieldMessage("departmentField")}
                      label={t("部門")}
                      variant="standard"
                    />
                  )}
                  value={departmentField}
                />
              )}
            </Grid>
          )}
        {divisionField && divisionField.division_type_name_en === "Shops" && (
          <Grid className={classes.formAutoCompleteContainer} item xs={12}>
            {shopField && shopOptions && (
              <Autocomplete
                disabled={relocationTypeField && relocationTypeField.id === 2}
                disablePortal
                getOptionLabel={(option) => option.shop_code}
                multiple
                onChange={(event, value) =>
                  onInputFieldChange("shopField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={shopOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("shopField")}
                    helperText={getErrorFieldMessage("shopField")}
                    label={t("店舖")}
                    variant="standard"
                  />
                )}
                value={shopField}
              />
            )}
          </Grid>
        )}
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {titleOptions && (
            <Autocomplete
              disabled={
                !divisionField ||
                (relocationTypeField && relocationTypeField.id === 2)
              }
              disablePortal
              getOptionLabel={(option) =>
                option[t("title_name_ch")]
                  ? `${option.title_name_en_full} - ${
                      option[t("title_name_ch")]
                    }`
                  : option.title_name_en_full
              }
              onChange={(event, value) =>
                onInputFieldChange("titleField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={
                divisionField
                  ? titleOptions.filter(
                      (option) => option.division_id === divisionField.id
                    )
                  : titleOptions
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("titleField")}
                  helperText={getErrorFieldMessage("titleField")}
                  label={t("職位")}
                  variant="standard"
                />
              )}
              value={titleField}
            />
          )}
        </Grid>
        <Grid item xs={6} className={classes.formDatePickerContainer}>
          <DatePicker
            format="dd/MM/yyyy"
            label={t("生效日期 (日/月/年)")}
            onChange={(value) => onInputFieldChange("relocationDate", value)}
            slotProps={{
              textField: {
                error: checkIsFieldError("relocationDate"),
                helperText: getErrorFieldMessage("relocationDate"),
                variant: "standard",
              },
            }}
            value={relocationDateVar}
            views={["year", "month", "day"]}
          />
        </Grid>
        <Grid item xs={6} className={classes.formAutoCompleteContainer}>
          <Autocomplete
            disablePortal
            getOptionLabel={(option) => option[t("textCh")]}
            onChange={(event, value) =>
              onInputFieldChange("probationLengthField", value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            options={monthOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                error={checkIsFieldError("probationLengthField")}
                helperText={getErrorFieldMessage("probationLengthField")}
                label={t("試用期")}
                variant="standard"
              />
            )}
            value={probationLengthField}
          />
        </Grid>
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <Button
            onClick={onNextStepBtnClicked}
            variant="contained"
            sx={formSubmitBtnStyles}
          >
            {t("下一步")}
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <Button onClick={onStepBackBtnClicked} sx={formSubmitBtnStyles}>
            {t("上一步")}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default CreateStaffFormRelocationContent;
