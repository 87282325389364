// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function StaffRelocationBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    currentItemId,
    hoveredItemId,
    item,
    // Events
    onDeleteItemBtnClicked,
    onItemClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isHovered = hoveredItemId && hoveredItemId === item.id;
  const isSelected = currentItemId && currentItemId === item.id;

  return (
    <div
      className={
        isSelected
          ? classes.selectedItemContainer
          : isHovered
          ? classes.hoveredItemContainer
          : classes.itemContainer
      }
      onMouseEnter={() => onMouseEnterItem(item.id)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div
        className={classes.itemContentContainer}
        onClick={() => onItemClicked(item.id)}
      >
        <div className={classes.itemContentSubContainer}>
          <Typography
            align={"left"}
            color={
              isSelected
                ? stylesConfig.whiteTextColor
                : isHovered
                ? stylesConfig.mainTextColor
                : null
            }
            variant="body1"
          >
            {`${item.relocation_date} - ${item[t("relocation_type_name_ch")]}`}
          </Typography>
          <Typography
            align={"left"}
            color={
              isSelected
                ? stylesConfig.whiteTextColor
                : stylesConfig.greyTextColor
            }
            variant="body2"
          >
            {item.dept_name_display ? item.dept_name_display : null}
            {item.dept_name_display && item.dependencies.titles ? " - " : null}
            {item.dependencies.titles
              ? item.dependencies.titles[0][t("title_name_ch")]
              : null}
          </Typography>
        </div>
      </div>
      <Stack direction="row" spacing={1}>
        {item.isEditable && (
          <Tooltip title={t("刪除")}>
            <IconButton
              onClick={() =>
                onDeleteItemBtnClicked(item.id, item.isOnlyRelocation)
              }
              sx={isSelected ? { color: stylesConfig.whiteTextColor } : null}
            >
              <DeleteRoundedIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </div>
  );
}

export default StaffRelocationBox;
