// Configs
import apisConfig from "../configs/apisConfig";

export const createRelocationFetch = async (
  token,
  relocationTypeId,
  staffId,
  relocationDate,
  companyId,
  departmentId,
  divisionId,
  shopIdsArr,
  titleId
) => {
  try {
    const queryRoute = "/relocation/createRelocation";

    const reqBody = {
      relocationTypeId,
      staffId,
      relocationDate,
      companyId,
      departmentId,
      divisionId,
      shopIdsArr,
      titleId,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteRelocationFetch = async (token, relocationId) => {
  try {
    const queryRoute = `/relocation/deleteRelocation/${relocationId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editRelocationFetch = async (
  token,
  relocationId,
  relocationTypeId,
  relocationDate,
  companyId,
  departmentId,
  divisionId,
  shopIdsArr,
  titleId
) => {
  try {
    const queryRoute = `/relocation/editRelocation/${relocationId}`;

    const reqBody = {
      relocationTypeId,
      relocationDate,
      companyId,
      departmentId,
      divisionId,
      shopIdsArr,
      titleId,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllRelocationsByStaffIdFetch = async (token, staffId) => {
  try {
    const queryRoute = `/relocation/getAllRelocationsByStaffId/${staffId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getRelocationDetailsByIdFetch = async (token, relocationId) => {
  try {
    const queryRoute = `/relocation/getRelocationDetailsById/${relocationId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getRelocationIdByStaffIdAndRelocationTypeIdsArrFetch = async (
  token,
  staffId,
  relocationTypeIdsArr
) => {
  try {
    const queryRoute =
      "/relocation/getRelocationIdByStaffIdAndRelocationTypeIdsArr";

    const reqBody = {
      staffId,
      relocationTypeIdsArr,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getStaffCurrentRelocationDetailsByStaffIdFetch = async (
  token,
  staffId
) => {
  try {
    const queryRoute = `/relocation/getStaffCurrentRelocationDetailsByStaffId/${staffId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
