// Actions
import { onTitleChange } from "../../../redux/pages/titlesPage/titlesPageActions";

// Components
// Boxes
import TitleBox from "./titleBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import TitleActionMenu from "../../menus/titlesPage/titleActionMenu";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteOrRestoreTitleFetch,
  getAllTitlesByDivisionIdFetch,
} from "../../../fetches/titleFetches";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function TitleBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const titleId = useSelector((state) => state.titlesPage.titleId);
  const divisionId = useSelector((state) => state.titlesPage.divisionId);
  const formSubmitCount = useSelector(
    (state) => state.titlesPage.formSubmitCount
  );
  const token = useSelector((state) => state.staff.token);
  const typeOfTitles = useSelector((state) => state.titlesPage.typeOfTitles);

  // States
  // Data
  const [titles, setTitles] = useState(null);
  // Dialog
  const [titleActionDialogText, setTitleActionDialogText] = useState("");
  const [titleActionDialogType, setTitleActionDialogType] = useState(null);
  const [showTitleActionDialog, setShowTitleActionDialog] = useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showTitleActionMenu, setShowTitleActionMenu] = useState(null);

  // Handle States
  const currentItemId = titleId;
  const itemsArr = titles;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onTitleChange(itemId));
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowTitleActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowTitleActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onTitleActionDialogCanceled = () => {
    // Set States
    setShowTitleActionDialog(false);
  };

  const onTitleActionDialogConfirmed = () => {
    switch (titleActionDialogType) {
      case "DeleteTitle":
        deleteOrRestoreTitle();
        break;
      case "RestoreTitle":
        deleteOrRestoreTitle();
        break;
      default:
        break;
    }

    // Set States
    setShowTitleActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Update Redux Store
    dispatch(onTitleChange(null));
  };

  // Functions
  // Functions - Normal
  const displayTitleActionDialog = (titleActionType) => {
    // Set States
    setTitleActionDialogType(titleActionType);

    switch (titleActionType) {
      case "DeleteTitle":
        setTitleActionDialogText(t("確認要刪除 職位 嗎？"));
        break;
      case "RestoreTitle":
        setTitleActionDialogText(t("確認要還原 職位 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowTitleActionDialog(true);
  };

  // Functions - Mutations
  const deleteOrRestoreTitle = async () => {
    const results = await deleteOrRestoreTitleFetch(
      token,
      currentItemId,
      typeOfTitles
    );

    if (results.success) {
      getAllTitles(true);
    }
  };

  // Functions - Queries
  const getAllTitles = async (shoulddUpdateCurrentTitleId) => {
    const results = await getAllTitlesByDivisionIdFetch(
      token,
      divisionId,
      typeOfTitles
    );

    // Set States
    setTitles(results.titles ? results.titles : null);

    // Update Redux Store
    if (shoulddUpdateCurrentTitleId) {
      dispatch(onTitleChange(results.titles ? results.titles[0].id : null));
    }
  };

  // Life Cycle
  useEffect(() => {
    if (divisionId) {
      getAllTitles(true);
    }
  }, [divisionId]);

  useEffect(() => {
    if (divisionId) {
      getAllTitles(currentItemId ? false : true);
    }
  }, [formSubmitCount, typeOfTitles]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onTitleActionDialogCanceled}
        onDialogConfirmed={onTitleActionDialogConfirmed}
        // States
        dialogText={titleActionDialogText}
        showDialog={showTitleActionDialog}
      />
      {/* Menu */}
      <TitleActionMenu
        // States
        showTitleActionMenu={showTitleActionMenu}
        // Set States
        setShowTitleActionMenu={setShowTitleActionMenu}
        // Fucntions
        displayTitleActionDialog={displayTitleActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("職位s")}
          </Typography>
          <Tooltip placement="right" title={t("新增職位")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <TitleBox
            key={item.id}
            // States
            currentItemId={currentItemId}
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有職位資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default TitleBoxesContainer;
