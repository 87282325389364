// Actions
import {
  onConditionStatisticsTypeChange,
  onConditionStatisticsTypeItemChange,
} from "../../../redux/pages/dashboardPage/dashboardPageActions";

// Consts
import { statisticsTypeOptions } from "../../../consts/staffStatisticsConditionBoxesContainerConsts";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ConditionBoxesContainer() {
  // Hooks
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const statisticsTypeValue = useSelector(
    (state) => state.dashboardPage.conditionStatisticsTypeValue
  );
  const statisticsTypeItem = useSelector(
    (state) => state.dashboardPage.conditionStatisticsTypeItem
  );

  // Events
  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "statisticsType":
        dispatch(onConditionStatisticsTypeChange(value.value));
        break;
      default:
        break;
    }
  };

  // Functions
  // Functions - Normal
  const setInitialStates = () => {
    // Update Redux Store
    if (!statisticsTypeValue) {
      dispatch(onConditionStatisticsTypeChange(statisticsTypeOptions[0].value));
    }
  };

  // Life Cycle
  useEffect(() => {
    setInitialStates();
  }, []);

  useEffect(() => {
    // Update Redux Store
    if (statisticsTypeValue) {
      dispatch(
        onConditionStatisticsTypeItemChange(
          statisticsTypeOptions.find(
            (item) => item.value === statisticsTypeValue
          )
        )
      );
    }
  }, [statisticsTypeValue]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            統計類型
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      <div className={classes.autoCompleteContainer}>
        <Autocomplete
          disablePortal
          fullWidth
          getOptionLabel={(option) => option.text}
          onChange={(event, value) =>
            onInputFieldChange("statisticsType", value)
          }
          options={statisticsTypeOptions}
          renderInput={(params) => (
            <TextField {...params} label="統計類型" variant="outlined" />
          )}
          value={statisticsTypeItem}
        />
      </div>
    </div>
  );
}

export default ConditionBoxesContainer;
