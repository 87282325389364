// Components
// Dialogs
import AlertDialog from "../../../dialogs/alertDialog";
// Modals
import StaffActionModalInfo from "./staffActionModalInfo";

// Configs
import stylesConfig from "../../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../../customHooks/getLanguage";

// Fetches
import { createRelocationFetch } from "../../../../fetches/relocationFetches";
import { getStaffBasicInfoByIdFetch } from "../../../../fetches/staffFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function StaffActionModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
    // States
    staffId,
    staffActionModalType,
  } = props;

  // Redux Store
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Data
  const [staffBasicInfo, setStaffBasicInfo] = useState(null);
  // Date Fields
  const [relocationDate, setRelocationDate] = useState(new Date());
  // Dialog
  const [staffActionDialogText, setStaffActionDialogText] = useState("");
  const [staffActionDialogType, setStaffActionDialogType] = useState(null);
  const [showStaffActionDialog, setShowStaffActionDialog] = useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);

  // Handle States
  const relocationDateVar = relocationDate ? new Date(relocationDate) : null;
  const staffActionTitle =
    staffActionModalType === "StaffResign" ? t("離職") : t("重新入職");
  // Events
  // Events - Dialogs
  const onStaffActionDialogCanceled = () => {
    // Set States
    setShowStaffActionDialog(false);
  };

  const onStaffActionDialogConfirmed = () => {
    switch (staffActionDialogType) {
      case "CreateRelocation":
        createRelocation();
        break;
      default:
        break;
    }

    // Set States
    setShowStaffActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "relocationDate":
        setRelocationDate(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!relocationDate) {
      addToErrorFields(
        "relocationDate",
        `${t("請先填寫")} ${staffActionTitle}${t(" 日期")}`
      );
      isError = true;
    }

    if (isError) {
      return;
    }

    displayStaffActionDialog("CreateRelocation");
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const displayStaffActionDialog = (staffActionType) => {
    const fullNameEn = staffBasicInfo.full_name_en;
    const staffCode = staffBasicInfo.staff_code;

    // Set States
    setStaffActionDialogType(staffActionType);

    switch (staffActionType) {
      case "CreateRelocation":
        setStaffActionDialogText(
          `${t("確認要將")} ${staffCode} - ${fullNameEn} ${t(
            "設為"
          )}${staffActionTitle}${t("嗎？")}`
        );
        break;
      default:
        break;
    }

    // Set States
    setShowStaffActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createRelocation = async () => {
    if (staffId) {
      const results = await createRelocationFetch(
        token,
        staffActionModalType === "StaffResign"
          ? 2
          : staffActionModalType === "StaffRejoin"
          ? 3
          : 5,
        staffId,
        relocationDate,
        null,
        null,
        null,
        null,
        null
      );

      if (results.success) {
        onModalClosed();
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions = Queries
  const getStaffBasicInfoById = async () => {
    const results = await getStaffBasicInfoByIdFetch(token, staffId);

    // Set States
    setStaffBasicInfo(results.staffBasicInfo ? results.staffBasicInfo : null);
  };

  // Life Cycle
  useEffect(() => {
    if (staffId) {
      getStaffBasicInfoById();
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [staffId]);

  return (
    <div className={classes.modalContainerSmall}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onStaffActionDialogCanceled}
        onDialogConfirmed={onStaffActionDialogConfirmed}
        // States
        dialogText={staffActionDialogText}
        showDialog={showStaffActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {staffActionTitle}
          </Typography>
        </div>
      </div>
      {/* Content */}
      {staffBasicInfo ? (
        <div>
          <StaffActionModalInfo
            // States
            item={staffBasicInfo}
          />
          <Grid
            className={classes.formContainer}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            container
            rowSpacing={1}
          >
            <Grid item xs={12} className={classes.formDatePickerContainer}>
              <DatePicker
                format="dd/MM/yyyy"
                label={t("生效日期 (日/月/年)")}
                onChange={(value) =>
                  onInputFieldChange("relocationDate", value)
                }
                slotProps={{
                  textField: {
                    error: checkIsFieldError("relocationDate"),
                    helperText: getErrorFieldMessage("relocationDate"),
                    variant: "outlined",
                  },
                }}
                value={relocationDateVar}
                views={["year", "month", "day"]}
              />
            </Grid>
            <Grid item xs={12} className={classes.formSubmitBtnContainer}>
              <Button
                onClick={onSubmitBtnClicked}
                variant="contained"
                sx={formSubmitBtnStyles}
              >
                {t("確定")}
              </Button>
            </Grid>
            {/* Alert */}
            {shouldShowFormAlert && (
              <Grid item xs={12}>
                <Alert severity={formAlertType}>{formAlertText}</Alert>
              </Grid>
            )}
          </Grid>
        </div>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有員工資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default StaffActionModal;
