export function onFormSubmitCountIncrease() {
  return {
    type: "ON_PERMISSIONS_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onPermissionChange(permissionId) {
  return {
    type: "ON_PERMISSIONS_PAGE_PERMISSION_CHANGE",
    permissionId,
  };
}

export function onPermissionItemChange(permissionItem) {
  return {
    type: "ON_PERMISSIONS_PAGE_PERMISSION_ITEM_CHANGE",
    permissionItem,
  };
}

export function onPermissionGroupChange(permissionGroupId) {
  return {
    type: "ON_PERMISSIONS_PAGE_PERMISSION_GROUP_CHANGE",
    permissionGroupId,
  };
}

export function onPermissionGroupItemChange(permissionGroupItem) {
  return {
    type: "ON_PERMISSIONS_PAGE_PERMISSION_GROUP_ITEM_CHANGE",
    permissionGroupItem,
  };
}

export function onPermissionTypeChange(permissionTypeId) {
  return {
    type: "ON_PERMISSIONS_PAGE_PERMISSION_TYPE_CHANGE",
    permissionTypeId,
  };
}

export function onPermissionTypeItemChange(permissionTypeItem) {
  return {
    type: "ON_PERMISSIONS_PAGE_PERMISSION_TYPE_ITEM_CHANGE",
    permissionTypeItem,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_PERMISSIONS_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}
