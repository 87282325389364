const initialState = {
  applicationBadgeNum: 0,
  badgeUpdateCount: 0,
};

export const badgeReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_BADGE_CHANGE":
      const { applicationBadgeNum } = action;
      return {
        ...state,
        applicationBadgeNum,
      };
    case "ON_BADGE_UPDATE_COUNT_INCREASE":
      return {
        ...state,
        badgeUpdateCount: state.badgeUpdateCount + 1,
      };
    default:
      return state;
  }
};
