// Components
// Boxes
import ShopContactBox from "./shopContactBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import ShopContactActionMenu from "../../menus/deptsAndShopsPage/shopContactActionMenu";
// Modals
import ModalContainer from "../../modals/modalContainer";
import ShopContactActionModal from "../../modals/deptsAndShopsPage/shopContactActionModal";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteContactFetch,
  getAllContactsByShopIdFetch,
} from "../../../fetches/contactFetches";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ShopContactBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const shopId = useSelector((state) => state.deptsAndShopsPage.shopId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [shopContacts, setShopContacts] = useState(null);
  // Dialog
  const [shopContactActionDialogText, setShopContactActionDialogText] =
    useState("");
  const [shopContactActionDialogType, setShopContactActionDialogType] =
    useState(null);
  const [showShopContactActionDialog, setShowShopContactActionDialog] =
    useState(false);
  // Hover
  const [currentItemId, setCurrentItemId] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showShopContactActionMenu, setShowShopContactActionMenu] =
    useState(null);
  // Modals
  const [showShopContactActionModal, setShowShopContactActionModal] =
    useState(false);

  // Handle States
  const itemsArr = shopContacts;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Set States
    setCurrentItemId(itemId);
    displayShopContactActionModal();
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setShowShopContactActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setShowShopContactActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onShopContactActionDialogCanceled = () => {
    // Set States
    setShowShopContactActionDialog(false);
  };

  const onShopContactActionDialogConfirmed = () => {
    switch (shopContactActionDialogType) {
      case "DeleteShopContact":
        deleteContact();
        break;
      default:
        break;
    }

    // Set States
    setShowShopContactActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onShopContactActionModalClosed = () => {
    getAllContactsByShopId();
    // Set States
    setShowShopContactActionModal(false);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Set States
    setCurrentItemId(null);
    displayShopContactActionModal();
  };

  // Functions
  // Functions - Normal
  const clearData = () => {
    // Set States
    setShopContacts(null);
  };

  const displayShopContactActionDialog = (shopContactActionType) => {
    // Set States
    setShopContactActionDialogType(shopContactActionType);

    switch (shopContactActionType) {
      case "DeleteShopContact":
        setShopContactActionDialogText(t("確認要刪除 聯絡方式 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowShopContactActionDialog(true);
  };

  const displayShopContactActionModal = () => {
    setShowShopContactActionModal(true);
  };

  // Functions - Mutations
  const deleteContact = async () => {
    const results = await deleteContactFetch(token, currentItemId);

    if (results.success) {
      getAllContactsByShopId();
    }
  };

  // Functions - Queries
  const getAllContactsByShopId = async () => {
    const results = await getAllContactsByShopIdFetch(token, shopId);

    // Set States
    setShopContacts(results.contacts ? results.contacts : null);
  };

  // Life Cycle
  useEffect(() => {
    if (shopId) {
      getAllContactsByShopId();
    } else {
      clearData();
    }
  }, [shopId]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onShopContactActionDialogCanceled}
        onDialogConfirmed={onShopContactActionDialogConfirmed}
        // States
        dialogText={shopContactActionDialogText}
        showDialog={showShopContactActionDialog}
      />
      {/* Menu */}
      <ShopContactActionMenu
        // States
        showShopContactActionMenu={showShopContactActionMenu}
        // Set States
        setShowShopContactActionMenu={setShowShopContactActionMenu}
        // Functions
        displayShopContactActionDialog={displayShopContactActionDialog}
        displayShopContactActionModal={displayShopContactActionModal}
      />
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onShopContactActionModalClosed}
        // States
        showModal={showShopContactActionModal}
      >
        <ShopContactActionModal
          // Events
          onModalClosed={onShopContactActionModalClosed}
          // States
          contactId={currentItemId}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("聯絡方式s")}
          </Typography>
          <Tooltip placement="right" title={t("新增聯絡方式")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <ShopContactBox
            key={item.id}
            // States
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有聯絡方式資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default ShopContactBoxesContainer;
