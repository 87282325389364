// Components
import StyledMenu from "../styledMenu";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
// Icons
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

// React
import { useEffect, useState } from "react";

// Styles
import {
  useStandardTableStyles,
  staffToDayActionMenuItemLeftText,
} from "../../../styles/componentStyles/tableStyles/standardTableStyles";

function StaffToDayActionMenu(props) {
  // Hooks
  // Styles
  const classes = useStandardTableStyles();

  // Props
  const {
    // States
    divisionType,
    dayOffTypes,
    shifts,
    showStaffToDayActionMenu,
    workingPeriods,
    // Set States
    setShowStaffToDayActionMenu,
    // Functions
    createOrEditRoster,
  } = props;

  // States
  const [menuItemType, setMenuItemType] = useState("WorkingPeriods");

  // Events
  const onChangeMenuItemTypeBtnClicked = () => {
    setMenuItemType(
      menuItemType === "DayOffTypes"
        ? divisionType === "Departments"
          ? "WorkingPeriods"
          : "Shifts"
        : "DayOffTypes"
    );
  };

  const onMenuClosed = () => {
    // Set States
    setShowStaffToDayActionMenu(null);
  };

  const onMenuItemClicked = (itemId) => {
    onMenuClosed();

    createOrEditRoster(menuItemType, itemId);
  };

  useEffect(() => {
    setMenuItemType(
      divisionType === "Departments" ? "WorkingPeriods" : "Shifts"
    );
  }, [divisionType]);

  return (
    <StyledMenu
      // States
      anchorEl={showStaffToDayActionMenu}
      open={Boolean(showStaffToDayActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      <MenuItem disableRipple onClick={onChangeMenuItemTypeBtnClicked}>
        <div className={classes.menuItem}>
          {["WorkingPeriods", "Shifts"].includes(menuItemType) ? (
            <ArrowForwardIosRoundedIcon />
          ) : (
            <ArrowBackIosRoundedIcon />
          )}
          <Typography
            align={"center"}
            color={stylesConfig.greyTextColor}
            variant="body2"
          >
            選擇
            {menuItemType === "DayOffTypes"
              ? divisionType === "Departments"
                ? "工作時段"
                : "值更"
              : "假期"}
          </Typography>
        </div>
      </MenuItem>
      {menuItemType === "WorkingPeriods"
        ? workingPeriods &&
          workingPeriods.map((item) => (
            <MenuItem
              disableRipple
              key={item.id}
              onClick={() => onMenuItemClicked(item.id)}
            >
              <div className={classes.menuItem}>
                <Typography
                  align={"center"}
                  variant="body1"
                  sx={staffToDayActionMenuItemLeftText}
                >
                  {item.working_period_code}
                </Typography>
                <Typography
                  align={"center"}
                  color={stylesConfig.greyTextColor}
                  variant="body2"
                >
                  {item.is_default ? "預設" : "一般"}
                </Typography>
              </div>
            </MenuItem>
          ))
        : menuItemType === "Shifts"
        ? shifts &&
          shifts.map((item) => (
            <MenuItem
              disableRipple
              key={item.id}
              onClick={() => onMenuItemClicked(item.id)}
            >
              <div className={classes.menuItem}>
                <Typography
                  align={"center"}
                  variant="body1"
                  sx={staffToDayActionMenuItemLeftText}
                >
                  {item.shift_code}
                </Typography>
                <Typography
                  align={"center"}
                  color={stylesConfig.greyTextColor}
                  variant="body2"
                >
                  {item.default_start_time} - {item.default_end_time}
                </Typography>
              </div>
            </MenuItem>
          ))
        : dayOffTypes &&
          dayOffTypes.map((item) => (
            <MenuItem
              disableRipple
              key={item.id}
              onClick={() => onMenuItemClicked(item.id)}
            >
              <div className={classes.menuItem}>
                <Typography
                  align={"center"}
                  variant="body1"
                  sx={staffToDayActionMenuItemLeftText}
                >
                  {item.day_off_type_name_en_short}
                </Typography>
                <Typography
                  align={"center"}
                  color={stylesConfig.greyTextColor}
                  variant="body2"
                >
                  {item.day_off_type_name_ch}
                </Typography>
              </div>
            </MenuItem>
          ))}
      <MenuItem disableRipple onClick={() => onMenuItemClicked(null)}>
        <div className={classes.menuItem}>
          <DeleteRoundedIcon />
          <Typography
            align={"center"}
            color={stylesConfig.greyTextColor}
            variant="body2"
          >
            刪除紀錄
          </Typography>
        </div>
      </MenuItem>
    </StyledMenu>
  );
}

export default StaffToDayActionMenu;
